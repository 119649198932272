
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"

@Options({
    components: {
        AlertBadge,
        IconLock,
        UserIcon,
    },
})
export default class RoomMembersMenuTr extends Vue {
    // data:

    // ループなどでまわしている表示するルーム
    @Prop()
    room: Room

    @Prop()
    roomindex: number

    rm = RoomManager
    badgeCount: number = 0
    attendees: RoomMember[] = null

    mounted() {
        this.updateRoom()
    }

    @Watch("room", { deep: true })
    updateRoom() {
        // let this.room.owner
        // this.attendees = this.room.attendees
        if (!this.rm.userInfo) return
        if (!this.room) return
        let atts = Util.isPresent(this.room.attendees) ? [...this.room.attendees] : []
        // atts = atts.filter(att => att.user_group_id != this.rm.userInfo.user_group_id)
        atts = atts.filter(att => att.user_id != this.rm.userInfo.user_id)
        this.attendees = atts
    }

    @Watch("rm.userInfo")
    updateUser() {
        this.updateRoom()
    }

    @Emit("selectedRoom")
    selectMessageRoom(selectedRoom: Room) {
        Logger(`未読を0にして、roomを切り替えます.`)
        selectedRoom.unread_num = 0
        return selectedRoom
    }
}
