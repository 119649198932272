
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import { DateTime } from "luxon"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import OneMagSmallContent from "packs/pages/link/parts/available_schedule/OneMagSmallContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import ScheduleSlideContent from "packs/pages/link/ScheduleSlideContent.vue"
import SearchCalendarsContent from "packs/pages/link/parts/available_schedule/SearchCalendarsContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import MemberDailyLinePerCalendarTag from "packs/pages/link/parts/available_schedule/MemberDailyLinePerCalendarTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"
import Appointment from "packs/models/Appointment"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesConditions from "packs/models/PossibleDatesConditions"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        OneMagSmallContent,
        CheckboxTag,
        ScheduleSlideContent,
        SearchCalendarsContent,
        MdTextfieldTag,
        MemberDailyLinePerCalendarTag,
    },
})
export default class MeetingAttendeesGroupAssignContent extends Vue {
    room: Room = null
    appo: Appointment = null
    mags: MeetingAttendeesGroup[] = null
    astag: AvailableScheduleTag = null

    // 選択しているMAG
    selectedMag: MeetingAttendeesGroup = null

    // もともと割り当てられていたMAG
    beforeSelectedMag: MeetingAttendeesGroup = null

    // 外部カレンダーもしくはメールアドレスから割り当てた場合にこちらにセットして送ります.
    tmpMagFromCals: MeetingAttendeesGroup = null
    tmpMagFromMail: MeetingAttendeesGroup = null

    // data
    rm = RoomManager
    selectedTabName = `astag` // astag, ctags, emails
    magDisplayType = null

    pdm = PossibleDatesManager
    pdc = PossibleDatesConditions
    cm = CalendarManager
    gettingPds = false
    step = 0
    members = []
    currentMember = null
    searchedMems = []
    searchText = ``
    currentPd: PossibleDate = null

    loading = false

    Util = Util
    CalendarUtil = CalendarUtil
    truncate = truncate

    locationName = null
    currentOnlineAccounts = null

    dailyEvents = null
    fixStartTime = null
    fixEndTime = null

    @Ref()
    SearchCalendarsContent

    // currentRoom: Room = null

    mounted() {
        this.resetTmpMags()
        this.updateMags()
        this.updateAstag()
        this.getUserList()
        // this.updateCurrentPd()
    }

    resetTmpMags() {
        this.tmpMagFromCals = MeetingAttendeesGroup.createDefault()
        this.tmpMagFromMail = MeetingAttendeesGroup.createDefault()
        this.tmpMagFromCals.purpose_type = `temporary`
        this.tmpMagFromMail.purpose_type = `temporary`
    }

    updateMags() {
        if (!this.rm.currentRoom) return
        let room = { ...this.rm.currentRoom }
        this.room = room
        this.appo = this.room.current_appointment
        this.magDisplayType = this.room.room_setting.mag_display_type
        if (this.appo.status != 10) return
        let fixStartTime = DateTime.fromSeconds(this.appo.start_time)
        let fixEndTime = fixStartTime.plus({ minutes: this.appo.duration })
        this.fixEndTime = fixEndTime
        this.fixStartTime = fixStartTime

        let selectedMag = this.appo.selected_mag_dic[this.room.owner.user_id]
        Logger(`ChangeMeetingAttendeesGroupModal#updateMags ${selectedMag ? selectedMag.name : null}`)
        if (Util.isBlank(selectedMag)) return

        this.beforeSelectedMag = selectedMag
    }

    // @Watch(`pdm.currentPd`, { deep: true })
    // updateCurrentPd() {
    //     if (Util.isBlank(this.pdm)) return
    //     if (Util.isBlank(this.pdm.currentPd)) return
    //     let _pd = this.pdm.currentPd
    //     Logger(`${funcName()} _pd:${Util.output(_pd)}`)

    //     this.getPossibles()
    // }

    @Watch(`rm.astag`)
    updateAstag() {
        if (!this.rm.astag) return

        this.rm.getAstagDetail(this.room.owner_avaialble_schedule_tag_id).then(res => {
            if (!res) return

            let astag = res
            if (res.id == this.rm.astag.id) {
                this.rm.astag = res
            }
            this.astag = res
            this.mags = astag?.meeting_attendees_groups || []

            this.currentPd = this.pdm.currentPd
            Logger(`${funcName()} pdm.currentPd:${Util.output(this.pdm.currentPd)}`)

            this.pdc.setNew(this.room, this.rm.userInfo, this.appo)
            this.pdc.hideMemberName = true
            let rs = this.room.room_setting
            let newAstag = AvailableScheduleTag.createDefaultAstag()
            if (this.rm.didConnectCal) {
                newAstag.can_connect_calendar = true
            }

            if (Util.isBlank(this.mags)) {
                if (this.rm.didConnectCal) {
                    this.selectedTabName = `ctags`
                } else {
                    this.selectedTabName = `emails`
                }
            }

            this.cm.setNew(this.rm.userInfo, this.rm.user_setting, newAstag, rs, true, this.appo)
            this.cm.createType = `vote`

            // cmをsetNewする際に、pdmをクリアしてしまうため、その後に作成し直します.
            if (this.pdm && Util.isBlank(this.pdm.userInfo)) {
                this.pdm.setNew(this.room, this.rm.userInfo, this.appo)
            }
            if (this.currentPd) {
                this.pdm.selectCurrentPd(this.currentPd)
            }

            this.cm.getCalList().then(list => {
                Logger(`listを取得しました: list:${list}`)
                this.cm.editStart(this.cm.mags[0])
                this.cm.ctList = list
                this.getPossibles()
            })
        })
    }

    getUserList() {
        RoomMember.getUserList().then(list => {
            this.members = list
            if (Util.isBlank(this.searchText)) {
                this.searchedMems = list
            } else {
                this.inputEmails(this.searchText)
            }
        })
    }

    /**
     * 同一UG（オーナー側）だったら、確定している当日の予定を取得してきます.
     */
    getPossibles() {
        let room = this.rm.currentRoom
        let appo = room.current_appointment
        // if (!Appointment.isFix(room, appo)) return
        Logger(`${funcName()} appo:${Util.output(appo)}`)
        if (Util.isBlank(appo)) return

        let st = null

        // 判定する当日の予定を取得します.
        // 上ケース: すでに確定済みの予定の担当者変更のため
        // 下ケース: 投票などで今後確定する場合に当日の予定を確認.
        if (Appointment.isFix(room, appo) && appo.start_time) {
            st = appo.start_time
        } else {
            // 確定していない場合は指定の日程で取得します.
            let currentPd = this.currentPd
            Logger(`${funcName()} currentPd:${Util.output(currentPd)}`)
            if (Util.isBlank(currentPd)) return

            st = currentPd.start_time
        }
        if (Util.isBlank(st)) return

        let startTime = DateTime.fromSeconds(st)
        let startDate = startTime.toISODate()

        if (this.gettingPds) return
        this.gettingPds = true
        this.rm.startProgress()

        Logger(`${funcName()} 取得してきます. startDate:${startDate}`)
        let week = CalendarUtil.toWeekSpan(startTime)

        this.pdm
            .getPossibleDates(this.room, false, false, null, this.rm.astag, null, this.rm.guestKey, startDate, startDate, week)
            .then(posDates => {
                const avMonths = this.pdm.available_months
                let months = this.pdm.months
                let gettableMonths = Util.arraySabun(avMonths, months)
                this.gettingPds = false
                let _posDates = this.pdm.possibleDates
                Logger(
                    `${funcName()} 可能日:${(_posDates || []).length} gettableMonths:${Util.output(
                        gettableMonths
                    )}, beforeSelectedMag:${Util.output(this.beforeSelectedMag)}`
                )

                if (this.astag && this.astag.is_shared) {
                    Logger(`${funcName()} 共有されたastagのため、オーナーのctagsを入れます.`)
                    this.cm.sharedCalendarTagsDic = this.pdm.sharedCalendarTagsDic
                    this.cm.sharedAstagId = this.astag.id
                } else {
                    this.cm.sharedAstagId = null
                }

                let currentPd = PossibleDate.createFromStartTime(st, appo.duration)
                this.pdm.currentPd = currentPd

                if (this.beforeSelectedMag) {
                    sleep(200).then(_ => {
                        this.selectedMag = this.beforeSelectedMag
                        this.pdc.selectedMag = this.beforeSelectedMag
                    })
                } else {
                    if (Util.isPresent(this.mags)) {
                        let mag = this.mags[0]
                        sleep(200).then(_ => {
                            this.selectedMag = mag
                            this.pdc.selectedMag = mag
                        })
                    }
                }

                // let currentPd = _posDates.find(pd => pd.start_time == appo.start_time)
                // if (currentPd) {
                //     this.pdm.currentPd = currentPd
                // } else {
                //     this.pdm.currentPd = _posDates[0]
                // }

                // this.currentPossibleDates = _posDates || []
                if ((_posDates || []).length == 0) {
                    this.pdm.possibleDates = []
                }

                // Logger(`***********this pdm: ${Util.output(this.pdm)}`);
                this.rm.endProgress()
            })
    }

    selectCategoryTab(tabName: string) {
        this.selectedTabName = tabName
        if (tabName == `ctags`) {
            sleep(200).then(_ => {
                this.SearchCalendarsContent.clickSearchCalendarField()
            })
        }
        this.$emit(`selectCategoryTab`, tabName)
    }

    selectMag(mag: MeetingAttendeesGroup) {
        this.selectedMag = mag
        this.pdc.selectedMag = mag
        this.$emit(`selectMag`, mag)
    }

    deleteSelectCtag(ctag: CalendarTag) {
        Logger(`${funcName()}`)
        this.cm.deleteSelectedCtag(ctag)
    }

    get didSelectWritableAttendees() {
        if (!this.tmpMagFromCals) return false
        if (Util.isBlank(this.tmpMagFromCals.required_attendees)) return false
        let atts = this.tmpMagFromCals.required_attendees
        let writable = atts.find(att => att.writable)
        if (writable) return true
        return false
    }

    @Watch(`cm.edittingAttendees`, { deep: true })
    updateAttendees() {
        Logger(`${funcName()} ${this.cm.edittingAttendees ? this.cm.edittingAttendees.length : null}`)
        this.tmpMagFromCals.required_attendees = this.cm.edittingAttendees
        this.$emit(`updateTmpMag`, this.tmpMagFromCals)
    }

    autoLoadWeeklyCalendar() {}

    public inputEmails(e) {
        Logger(`inputEmails: ${e}`)
        // this.tmpMagFromMail.emails = e

        Logger(`searchCalnedarValue ${e}`)
        let searchedCtags = []
        let selectedEmails: string[] = this.tmpMagFromMail.registrable_attendees.map(c => c.email) || []
        let list = [...(this.members || [])]
        Logger(`selectedEmails:${selectedEmails}`)
        if (Util.isBlank(e)) {
            searchedCtags = list.filter(ctag => {
                if (!selectedEmails.includes(ctag.email)) {
                    return ctag
                }
            })
        } else {
            searchedCtags = list.filter(ctag => CalendarTag.includesNameOrEmail(ctag, e) && !selectedEmails.includes(ctag.email))
        }
        // this.selectedEmails = e
        this.searchedMems = searchedCtags
        this.searchText = e

        Logger(`検索後のカレンダー: ${Util.output(this.searchedMems)}`)
        // if (Util.isPresent(searchedCtags)) {
        //     let _ct = searchedCtags[0]
        //     this.selectingCtag = _ct
        //     Logger(`選択中のli:${_ct.name}`)
        // }
    }

    selectMember(member: CalendarTag) {
        Logger(`${funcName()} member:${Util.output(member)}`)
        let atts = this.tmpMagFromMail.registrable_attendees || []
        let _mem = atts.find(m => m.email == member.email)

        if (Util.isBlank(_mem)) {
            atts.push(member)
            this.tmpMagFromMail.registrable_attendees = atts

            this.searchText = ``
            this.inputEmails(this.searchText)
            this.$emit(`updateTmpMag`, this.tmpMagFromMail)
        } else {
        }
    }

    deleteSelectedMember(member: CalendarTag) {
        let atts = this.tmpMagFromMail.registrable_attendees || []
        atts = atts.filter(m => m.email != member.email)
        this.tmpMagFromMail.registrable_attendees = atts
        this.inputEmails(this.searchText)
        this.$emit(`updateTmpMag`, this.tmpMagFromMail)
    }

    get showAssignFromEmails() {
        if (Util.isBlank(this.rm.userInfo)) return
        const uf = this.rm.userInfo
        if ([100, 1].includes(uf.user_group_role)) return true

        const cate = `user_settings`
        const cate2 = `rooms`
        if (
            UserPermissionsOption.permitDisplay(uf, cate, `user_settings_address_book`) ||
            UserPermissionsOption.permitDisplay(uf, cate2, `rooms_all_tab`)
        ) {
            return true
        }

        return false
    }

    checkEventsInTmpMag() {
        Logger(`${funcName()}`)
        if (this.loading) return
        this.loading = true
        let st = DateTime.fromSeconds(this.appo.start_time).startOf(`day`)
        let et = st.endOf(`day`)
        let mag = this.cm.astag.meeting_attendees_groups[0]
        mag.name = `A`
        mag.required_attendees = this.cm.edittingAttendees
        this.dailyEvents = null

        this.cm.getMagEvents(st, `A`, et, true).then(res => {
            if (res) {
                // let startTime = DateTime.fromSeconds(st)
                let eventsMagDic = res[`A`]
                // let events = eventsMagDic[st.toFormat(`MMdd`)]

                this.$nextTick(() => {
                    this.dailyEvents = (eventsMagDic?.daily_events || {})[st.toFormat(`MMdd`)] || []
                })
                // this.dailyEvents = (eventsMagDic?.daily_events || {})[st.toFormat(`MMdd`)] || []

                // this.dailyEvents = res[startTime.toFormat(`MMdd`)]
            }
            this.loading = false
        })
    }
}
