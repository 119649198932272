
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import OneRoomHistoryContent from "packs/pages/link/parts/OneRoomHistoryContent.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import VotedRoomManager from "packs/models/VotedRoomManager"
import FormField from "packs/models/FormField"

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class QuestionsBoxModal extends Vue {
    // data
    rm = RoomManager
    loading = false
    isSP = isMobile(window.navigator).phone

    vrm = VotedRoomManager

    member: RoomMember = null
    fields: FormField[] = null
    displayParamKeys = null

    // currentRoom: Room = null

    updateFields() {
        if (!this.vrm) return
        this.displayParamKeys = this.vrm.displayFields.map(f => f.param_key)
        let pub = this.vrm.publicRoom
        if (pub) this.fields = pub.form_fields
    }

    public hideFixScheduleModal() {
        this.$vfm.close("QuestionsBoxModal")
    }

    selectField(field: FormField) {
        if (this.displayParamKeys.includes(field.param_key)) {
            let dispFields = this.vrm.displayFields.filter(f => f.param_key != field.param_key)
            this.vrm.updateDisplayFields(dispFields, this.vrm.publicRoom)
            this.displayParamKeys = this.displayParamKeys.filter(k => k != field.param_key)
        } else {
            this.vrm.displayFields.push(field)
            this.vrm.updateDisplayFields(this.vrm.displayFields, this.vrm.publicRoom)
            this.displayParamKeys.push(field.param_key)
        }
    }

    updateQuestions() {
        this.$emit(`updateQuestions`)
    }
}
