
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import Const from "packs/utils/Const"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import { ref, watch } from "vue"

// コンポーネントを読込.
import PossibleDateLi from "packs/pages/link/parts/PossibleDateLi.vue"
import CalendarTable from "packs/components/calendar/CalendarTable.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import TimezoneSelectContent from "packs/components/calendar/TimezoneSelectContent.vue"
import WeeklyCalendarTable from "packs/components/calendar/WeeklyCalendarTable.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: { PossibleDateLi, CalendarTable, ScheduleViewLoader, TimezoneSelectContent, WeeklyCalendarTable },
})
export default class PossibleDatesWithCalendarTable extends Vue {
    // @Prop() currentPossibleDates: PossibleDate[];
    @Prop()
    parentId: string

    // top: ScheduleViewトップページ, asview: 調整カレンダー, sel: 選択モーダル
    @Prop()
    idprefix: string
    @Prop()
    useSelectbox: boolean
    @Prop()
    showConfirmButton: boolean

    @Prop()
    astag: AvailableScheduleTag

    @Prop() // func
    getPossiblesWithMonth

    @Prop() // func
    getPossiblesWithWeek

    @Prop()
    loading: boolean

    @Ref()
    CalendarTable

    @Prop() // monthly/weekly
    calendarShowType: string

    // data
    rm = RoomManager
    pdm = PossibleDatesManager

    // subm = SubscriptionManager;
    // showAds = false;
    // currentPd?: PossibleDate = null;
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone
    isSeminarType = this.rm.designType() != `normal`

    pds = null
    selectedPossibleDates: PossibleDate[] = []
    PossibleDate = PossibleDate

    Util = Util

    userType = ``
    // weekDics = Util.weekDics
    monthlyPossibleDates = [] // [[23,24,25], [10,11,12]] 今月と来月の
    currentDateId = null // 0629
    systemUpdatedAt = Util.getSec()
    windowWidth = 0
    DateTime = DateTime
    pdslength = 0

    isObserver = false

    weekArray = null

    created() {
        this.weekArray = [
            this.$t(`calendar.sun`),
            this.$t(`calendar.mon`),
            this.$t(`calendar.tue`),
            this.$t(`calendar.wed`),
            this.$t(`calendar.thu`),
            this.$t(`calendar.fri`),
            this.$t(`calendar.sat`),
            this.$t(`calendar.sun`),
        ]
    }

    get displayNarrow() {
        if (this.isSP || this.isSeminarType) {
            return true
        }
        return false
    }

    public mounted() {
        this.updateCurrentDate()
        this.updatePds()
        this.updateSharedMembers()

        this.calculateWindowWidth()
        window.addEventListener("resize", this.calculateWindowWidth)
    }

    titleDate() {
        let dt = DateTime.fromSeconds(this.pdm.currentCalendarDate.start_time)
        if (this.pdm.currentTZDic) {
            let offsetStr = PossibleDate.toTimezoneUTCFormat(this.pdm.currentTZDic.diff)
            dt = dt.setZone(`UTC${offsetStr}`)
        }
        let format = this.$t(`calendar.format_date`, {
            val1: this.weekArray[dt.weekday],
        }) as string
        Logger(`${funcName()} format:${dt.toFormat(format)}`)

        if (this.pdslength != (this.pdm.possibleDates || []).length) {
            Logger(`${funcName()} pdslength:${this.pdslength}  pdm.length:${(this.pdm.possibleDates || []).length}`)
            this.updatePds()
        }

        return dt.toFormat(format)
    }

    @Watch(`pdm.currentDate`)
    updateCurrentDate() {
        this.updatePds()
    }

    @Watch(`pdm.sharedMembers`, { deep: true })
    updateSharedMembers() {
        this.updatePds()
    }

    @Watch(`pdm.selectedMag`)
    updateSelectMag() {
        this.updatePds()
    }

    @Watch("pdm.possibleDates", { deep: true })
    updatePds() {
        Logger(`${funcName()}`)
        if (!this.pdm) return
        if (Util.isPresent(this.pdm.room) && this.rm.userInfo) {
            let mem = this.pdm.room.members.find(_m => _m.user_id == this.rm.userInfo.user_id)
            if (mem && mem.role == -10) {
                this.isObserver = true
            }
        }
        let now = Util.getSec()

        if (Util.isPublic() || Util.isBlank(this.rm.astag) || this.rm.astag.id == `newId`) {
            const _pds = this.pdm.getPdsAfterRemoved()
            if (Util.isBlank(_pds)) return
            Logger(`${funcName()} _pdsが更新されてる？ _pds.length:${_pds.length}`)

            let selectedPds = [...(this.pdm.selectedPds || [])]
            // let selectedPds = [...(this.selectedPossibleDates || [])]
            this.updateMonthlyPossibleDatesFromPds(_pds)
            if (this.pdm.currentDate) {
                // if (this.currentDateId) this.pdm.selectDate(this.currentDateId)
                Logger(
                    `${funcName()} 日付が選択中です。${this.pdm.currentDate} this.pdm.displayPds: ${Util.output(
                        this.pdm.displayPds
                    )}, selectedPds: ${selectedPds}`
                )

                this.pds = (this.pdm.displayPds || []).filter(_pd => _pd.start_time >= now)
                this.selectedPossibleDates = Util.isPresent(selectedPds) ? selectedPds : _pds.filter(_pd => _pd.selected)
                this.pdm.updateSelectedPds(this.selectedPossibleDates)
                this.pdslength = (this.pdm.possibleDates || []).length
                return
            }

            // 日付の選択がない場合、初日を選択
            let pd = _pds[0]
            let _format = pd.date_format
            if (this.pdm.currentTZDic) {
                _format = pd.tz_date_format
            }
            this.pdm.selectDate(_format)
            this.pdslength = (this.pdm.possibleDates || []).length

            return
        }
        Logger(
            `${funcName()} 提案ケース. rm.astag:${Util.output(this.rm.astag)} optional_schedules:${Util.output(
                this.rm.astag.optional_schedules
            )}`
        )

        // const _pds = this.pdm.possibleDates
        const _pds = this.pdm.getPdsAfterRemoved()
        if (!_pds) return

        this.updateMonthlyPossibleDatesFromPds(_pds)

        let selectedPds = this.pdm.getSelectedPossibleDates(this.rm.astag)
        if (Util.isBlank(this.pdm.currentDate)) {
            let pd = _pds[0]
            this.pdm.selectDate(pd.date_format)
        }

        this.pds = (this.pdm.displayPds || []).filter(_pd => _pd.start_time >= now)
        this.selectedPossibleDates = selectedPds
        this.pdslength = (this.pdm.possibleDates || []).length
    }

    @Watch(`pdm.selectedPds`)
    updateSelectedPds() {
        Logger(`${funcName()} pdm.selectedPdsが更新されました。`)
        this.pds = this.pdm.displayPds
        this.selectedPossibleDates = this.pdm.selectedPds
    }

    public selectDateFromCal(idName: string) {
        // this.currentDateId = idName
    }

    /**
     * 候補日時を表示する幅を指定します.
     */
    get pdWidth() {
        if (this.idprefix == `asview`) {
            return 310
        }
        return 290
    }

    @Watch("astag", { deep: true })
    updateOptionalSchedules() {
        let _ops = this.astag.optional_schedules
        Logger(`${funcName()} : astagに変更がありました. ${Util.output(_ops)}`)
        if (!_ops) return
        let ops = (Object.values(_ops) as any).flat() as PossibleDate[]
        if (!ops || ops.length == 0) return
        let _pds = this.pdm.getPdsAfterRemoved()
        if (!_pds || _pds.length == 0) return
        Logger(`${funcName()} pdsの更新2 ${_pds.length}件表示.`)
        // this.pds = _pds
        if (this.pdm.currentDate) {
            this.pdm.selectDate(null)
            Logger(`${funcName()} 日付が選択中です2。${this.pdm.currentDate} ${this.currentDateId}`)
            this.pds = this.pdm.displayPds
            // this.selectedPossibleDates = selectedPds
        }
    }

    public updateMonthlyPossibleDatesFromPds(pds: PossibleDate[]) {
        let cannotFindFormat = false
        let datekeys
        ;[this.monthlyPossibleDates, cannotFindFormat, datekeys] = PossibleDate.getAvailableCalendarsFromPds(
            pds,
            this.pdm.currentTZDic
        )
        // if (cannotFindFormat) {
        //     this.pdm.currentTZDic = null
        // }

        Logger(`${funcName()} cannotFindFormat:${cannotFindFormat} ${Util.output(this.monthlyPossibleDates)}`)
    }

    public clickPossibleDateCell(pd: PossibleDate) {
        if (!this.useSelectbox) return
        Logger(`${funcName()} pd:${Util.output(pd)}`)
        if (Util.isPreview() || Util.isRoomSettingsPreview()) {
            Notice.message = "調整ページ編集画面のため、選択できません。"
            return
        }
        if (this.isObserver) {
            Logger(`${funcName()} オブザーバーのため、選択できません。`)
            return
        }

        // すべての日程を取得.
        const _pds = this.pdm.getPdsAfterRemoved()
        if (!_pds) return

        let selectedPds = [...(this.selectedPossibleDates || [])]
        let currentPd = selectedPds.find(p => PossibleDate.isSame(pd, p))
        if (currentPd) {
            if (selectedPds.length == 1) {
                window.confirm(`最低1件は選択してください。`)
                return
            }
            // 既に選択済み.
            currentPd.selected = false
            pd.selected = false
            // selectedPds = selectedPds.filter(e => e.selected)
            selectedPds = selectedPds.filter(e => !PossibleDate.isSame(pd, e))
        } else {
            pd.selected = true
            selectedPds.push(pd)
        }
        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
        Logger(`${funcName()} 選択中のpds2: ${this.selectedPossibleDates.length} ${Util.output(this.selectedPossibleDates)}`)
    }

    public confirmFixSchedule(pd: PossibleDate) {
        this.$emit("confirmFixSchedule", pd)
    }

    public removeDate(pd) {
        // this.pds = this.pds.filter(_pd => !PossibleDate.isSame(_pd, pd))
        this.$emit(`removeDate`, pd)
    }

    calculateWindowWidth() {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < Const.spWidth) {
            this.isSP = true
        } else {
            this.isSP = isMobile(window.navigator).phone
        }
    }

    /**
     * @param calId [string] "202106"
     */
    clickPrevMonth(calId: string) {
        if (!this.pdm) return
        if (this.pdm.available_months.includes(calId) && !(this.pdm.months || []).includes(calId)) {
            Logger(`${funcName()} 未取得のためリモートから取得します.: ${calId} , months: ${this.pdm.months}`)
            this.getPossiblesWithMonth(calId)
        }
    }

    /**
     * @param calId [string] "202106"
     */
    clickNextMonth(calId: string) {
        if (!this.pdm) return
        Logger(`${funcName()} calId:${calId}, pdm.months:${this.pdm.months}`)
        if (this.pdm.available_months.includes(calId) && !(this.pdm.months || []).includes(calId)) {
            Logger(`${funcName()} 未取得のためリモートから取得します.: ${calId} , months: ${this.pdm.months}`)
            this.getPossiblesWithMonth(calId)
        }
    }

    /**
     * @param calId [string] 年+週番号 "202118"
     */
    clickPrevWeek(calId: string) {
        if (!this.pdm) return
        if (this.pdm.available_weeks.includes(calId) && !(this.pdm.weeks || []).includes(calId)) {
            Logger(`${funcName()} 未取得のためリモートから取得します.: ${calId} , weeks: ${this.pdm.weeks}`)
            this.getPossiblesWithWeek(calId)
        }
    }

    /**
     * @param calId [string] 年+週番号 "202118"
     */
    clickNextWeek(calId: string) {
        if (!this.pdm) return
        Logger(`${funcName()} calId:${calId}, pdm.weeks:${this.pdm.weeks}`)
        if (this.pdm.available_weeks.includes(calId) && !(this.pdm.weeks || []).includes(calId)) {
            Logger(`${funcName()} 未取得のためリモートから取得します.: ${calId} , weeks: ${this.pdm.weeks}`)
            this.getPossiblesWithWeek(calId)
        }
    }

    selectTimezone(tzDic: any) {
        Logger(`${funcName()} tzDic:${Util.output(tzDic)}`)
        this.pdm.changeTimezone(tzDic)
        this.pdm.selectDate(this.currentDateId)
    }

    showChangeTimezone() {
        if (!this.pdm.room) return

        let rs = this.pdm.room.room_setting
        if (!rs) return

        Logger(`showChangeTimezone: rs.can_change_timezone:${rs.can_change_timezone}`)
        return rs.can_change_timezone
    }

    allDaySelect() {
        Logger(`${funcName()}`)
        if (Util.isPreview() || Util.isRoomSettingsPreview()) {
            Notice.message = "調整ページ編集画面のため、選択できません。"
            return
        }
        if (this.isObserver) {
            Logger(`${funcName()} オブザーバーのため、選択できません。`)
            return
        }
        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            if (!pd.selected) {
                pd.selected = true
                selectedPds.push(pd)
            }
        }

        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }

    allDayDeselect() {
        if (Util.isPreview() || Util.isRoomSettingsPreview()) {
            Notice.message = "調整ページ編集画面のため、選択できません。"
            return
        }
        if (this.isObserver) {
            Logger(`${funcName()} オブザーバーのため、選択できません。`)
            return
        }
        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            let currentPd = selectedPds.find(p => PossibleDate.isSame(pd, p))
            if (currentPd) {
                currentPd.selected = false
                selectedPds = selectedPds.filter(e => !PossibleDate.isSame(pd, e))
            }

            pd.selected = false
        }
        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }
}
