
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref, Emit } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, getLang, Logger } from "packs/common"
import { marked } from "marked"
import debounce from "lodash/debounce"
import { Quill } from "@vueup/vue-quill"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import IconGear from "packs/components/icons/IconGear.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import UserFile from "packs/models/UserFile"
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import TemplateUtil from "packs/utils/TemplateUtil"
import { DateTime } from "luxon"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        ModalTemplates,
        IconGear,
    },
})
export default class AppealContentSettings extends Vue {
    // data:
    isSP = isMobile(window.navigator).phone

    // @Prop()
    // room: Room

    // @Prop()
    // appo: Appointment

    // 今回利用するテンプレ.

    // テンプレ更新ボタンの表示
    showUpdateTmpButton = false
    marked = marked

    rm = RoomManager
    rcm = RoomCreateManager
    loading = false
    isDrag: string = null
    fileForm: any = null

    hoverImage = false

    TemplateUtil = TemplateUtil
    deltaHtml = ``

    mounted() {
        // this.currentAppo = roomStorage.fetchAppointment()
        // this.updateAppo()
        // this.createDeltaHtml()
        this.updateDeltaContent()
    }

    get hasImage(): boolean {
        if (Util.isBlank(this.rcm.room)) return false
        if (Util.isBlank(this.rcm.appeal_content)) return false
        return this.rcm.appeal_content.image_url ? true : false
    }

    get hasContent(): boolean {
        if (Util.isBlank(this.rcm.room)) return false
        if (Util.isBlank(this.rcm.appeal_content)) return false
        return this.rcm.appeal_content.content || this.rcm.appeal_content.delta_content ? true : false
    }

    get hasTitle(): boolean {
        if (Util.isBlank(this.rcm.room)) return false
        if (Util.isBlank(this.rcm.appeal_content)) return false
        return this.rcm.appeal_content.title ? true : false
    }

    addImage() {
        this.$emit(`editHeaderImage`)
        // $(".leftNavPanel").addClass("open")
    }

    deleteImage() {
        this.$emit(`deleteHeaderImage`)
    }

    addHeaderContent() {
        this.$emit(`editHeaderContent`)
    }

    // 文中にあるURLを分割して抽出します。
    get autoLink(): string[] {
        if (Util.isBlank(this.rcm.room.appeal_content)) return []
        if (Util.isBlank(this.rcm.room.appeal_content.content)) return []
        Logger(`autoLink:${Util.output(this.rcm.room.appeal_content.content)}`)

        return TemplateUtil.processText(this.rcm.room.appeal_content.content)
    }

    @Watch(`rcm.room.appeal_content`, { deep: true })
    updateDeltaContent = debounce(() => {
        Logger(`${funcName()}`)
        this.createDeltaHtml()
    }, 1000)

    createDeltaHtml() {
        let apc = this.rcm.room.appeal_content
        Logger(`createDeltaHtml contentType:${apc?.content_type}`)
        if (!apc || apc.content_type != `delta`) return

        const quill = new Quill(document.createElement("div"))
        quill.setContents(apc.delta_content)
        const html = quill.root.innerHTML
        this.deltaHtml = html
    }

    get hasOpenPeriod(): boolean {
        if (Util.isBlank(this.rcm.room)) return false
        if (Util.isBlank(this.rcm.room_setting)) return false
        let rs = this.rcm.room_setting
        Logger(`${funcName()} open_period_type:${rs.open_period_type}`)
        return [`start_time_only`, `end_time_only`, `start_and_end_time`].includes(rs.open_period_type)
    }

    get openPeriod() {
        if (Util.isBlank(this.rcm.room)) return null
        if (Util.isBlank(this.rcm.room_setting)) return null
        let rs = this.rcm.room_setting
        let type = this.rcm.room_setting.open_period_type
        if (type == `none`) return null

        let st = null
        let et = null

        if (type == `start_time_only`) {
            st = DateTime.fromSeconds(rs.open_period_start_at)

            // text = `${st.toFormat(`yyyy年MM月dd日 HH:mm`)} 〜`
        } else if (type == `end_time_only`) {
            et = DateTime.fromSeconds(rs.open_period_end_at)
            // text = `〜 ${et.toFormat(`yyyy年MM月dd日 HH:mm`)}`
        } else if (type == `start_and_end_time`) {
            st = DateTime.fromSeconds(rs.open_period_start_at)
            et = DateTime.fromSeconds(rs.open_period_end_at)
            // text = `${st.toFormat(`yyyy年MM月dd日 HH:mm`)} 〜 ${et.toFormat(`yyyy年MM月dd日 HH:mm`)}`
        }
        return this.getLocalDateStr(st, et)
    }

    /**
     * @param st [DateTime]
     * @param et [DateTime]
     */
    getLocalDateStr(st, et) {
        let weekArray = [
            this.$t(`calendar.sun`),
            this.$t(`calendar.mon`),
            this.$t(`calendar.tue`),
            this.$t(`calendar.wed`),
            this.$t(`calendar.thu`),
            this.$t(`calendar.fri`),
            this.$t(`calendar.sat`),
            this.$t(`calendar.sun`),
        ]
        let lang = getLang()
        let stfmt = ``
        if (st) {
            stfmt = this.$t(`calendar.format_date`, { val1: weekArray[st.weekday] }) as string
        }
        let etfmt = ``
        if (et) {
            etfmt = this.$t(`calendar.format_date`, { val1: weekArray[et.weekday] }) as string
        }

        let dateStr = ``
        if (lang.includes(`ja`)) {
            let startTimeStr = st ? st.toFormat(`${stfmt}HH:mm`) : null
            let endTimeStr = et ? et.toFormat(`${etfmt}HH:mm`) : null

            if (st && et) {
                dateStr = `${startTimeStr} 〜 ${endTimeStr}`
            } else if (st) {
                dateStr = `${startTimeStr} 〜`
            } else if (et) {
                dateStr = `〜 ${endTimeStr}`
            }

            return dateStr
        } else {
            let startTimeStr = st ? st.toFormat(`HH:mm, ${stfmt}`) : null
            let endTimeStr = et ? et.toFormat(`HH:mm, ${etfmt}`) : null

            if (st && et) {
                dateStr = `${startTimeStr} - ${endTimeStr}`
            } else if (st) {
                dateStr = `${startTimeStr} -`
            } else if (et) {
                dateStr = `- ${endTimeStr}`
            }
            // let dateStr = st.toFormat(`HH:mm, ${stfmt} - ${endTimeStr}, ${etfmt}`)
        }
        Logger(`${funcName()} dateStr:${dateStr}`)
        return dateStr
    }
}
