
import { Options, Prop, Vue } from "vue-property-decorator"
// import "jquery";
import { Logger } from "packs/common"

export default class LblueMiddleButton extends Vue {
    @Prop() private msg!: string

    @Prop()
    value: string

    @Prop()
    name: string

    @Prop()
    buttonText: string

    @Prop()
    didSelect: boolean

    mounted() {}

    public select(e) {
        Logger(`lbluebutton`)
        this.$emit("select", e.target.value)
    }

    public get defaultSelect() {
        if (this.didSelect) {
            return "selected"
        } else {
            return ""
        }
    }
}
