
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import { DateTime } from "luxon"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import ScheduleSlideContent from "packs/pages/link/ScheduleSlideContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import CalendarManager from "packs/models/CalendarManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import VotedRoomManager from "packs/models/VotedRoomManager"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        CheckboxTag,
        ScheduleSlideContent,
        MdTextfieldTag,
    },
})
export default class FixAppoInfoContent extends Vue {
    @Prop() // 変更前の出席者情報
    attendees: RoomMember[]

    @Prop() // 変更後の出席者情報
    fAppoDic: any

    appo: Appointment = null

    // data
    rm = RoomManager
    cm = CalendarManager
    vrm = VotedRoomManager
    members = []

    Util = Util
    truncate = truncate

    fixedAppoDic = { f_appo: null, f_members: [], update_member_ids: [] }

    mounted() {
        this.updateFappo()
    }

    @Watch(`fAppoDic`)
    updateFappo() {
        if (!this.attendees) return
        if (this.fixedAppoDic.f_appo) return

        this.fixedAppoDic.f_members = this.fAppoDic.f_members.map(m => RoomMember.copy(m))
        this.fixedAppoDic.f_appo = { ...this.fAppoDic.f_appo }
        // let room = this.vrm.publicRoom
        // this.fixedAppoDic.f_appo = room.current_appointment
    }

    inputAppoName(value) {
        Logger(`${funcName()} f_appo.name:${value}`)
        this.fixedAppoDic.f_appo.name = value
        this.updateFappoDic()
    }

    updateFappoDic() {
        this.$emit(`updateFixedAppo`, this.fixedAppoDic)
    }

    inputMemberName(e, member_id) {
        Logger(`${funcName()} e:${e}, member_id:${member_id}`)
        let mem = this.fixedAppoDic.f_members.find(m => m.id == member_id)
        if (mem) {
            mem.name = e
            let updateMemerIds = this.fixedAppoDic.update_member_ids || []
            if (!updateMemerIds.includes(member_id)) {
                updateMemerIds.push(member_id)
            }
            this.updateFappoDic()
        }
    }
}
