
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import CustomNotificationContent from "packs/pages/link/settings/CustomNotificationContent.vue"
import ZapierNotificationContent from "packs/pages/link/settings/ZapierNotificationContent.vue"
import HubspotNotificationContent from "packs/pages/link/settings/HubspotNotificationContent.vue"
import SalesforceNotificationContent from "packs/pages/link/settings/SalesforceNotificationContent.vue"
import GoogleSpreadSheetNotificationContent from "packs/pages/link/settings/GoogleSpreadSheetNotificationContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import CustomNotification from "packs/models/CustomNotification"
import ZapierNotification from "packs/models/ZapierNotification"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        CheckboxTag,
        CustomNotificationContent,
        ZapierNotificationContent,
        HubspotNotificationContent,
        SalesforceNotificationContent,
        GoogleSpreadSheetNotificationContent,
    },
})
export default class UserSettingsCustomNotifications extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    util = Util
    selectedTab = `notifications`

    isSP = isMobile(window.navigator).phone
    timings = Util.timings()
    additionalTimings = Util.additionalTimings()
    // users: RoomMember[] = [];

    loading = false
    invitedUsers: RoomMember[] = null
    userInfo = null
    myUser: RoomMember = null //コンタクトリストにある情報を使うため.

    notifications: { [key: string]: any } = null
    webhookDic: CustomNotification = null
    teamsDic: CustomNotification = null
    slackDic: CustomNotification = null
    zapierDic: ZapierNotification = null
    hubspotDic: CustomNotification = null
    salesforceDic: CustomNotification = null
    availableEvents: any[] = null // {type: string, name: string}
    googleSpreadSheetDic: CustomNotification = null

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkPermission()
                let userInfo = this.rm.userInfo
                this.userInfo = userInfo
                this.getNotifications()
            })
        } else {
            this.checkPermission()
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            this.getNotifications()
        }
    }

    public getNotifications() {
        this.rm.getNotifications().then(dic => {
            this.notifications = dic
            this.webhookDic = this.notifications.webhook || CustomNotification.createDefault(`user`, `webhook`)
            this.slackDic = this.notifications.slack || CustomNotification.createDefault(`user`, `slack`)
            this.teamsDic = this.notifications.teams || CustomNotification.createDefault(`user`, `teams`)
            this.zapierDic = this.notifications.zapier || ZapierNotification.createDefault(`user`)
            this.hubspotDic = this.notifications.hubspot || CustomNotification.createDefault(`user`, `hubspot`)
            this.salesforceDic = this.notifications.salesforce || CustomNotification.createDefault(`user`, `salesforce`)
            this.googleSpreadSheetDic =
                this.notifications.google_spread_sheet || CustomNotification.createDefault(`user`, `google_spread_sheet`)
            this.availableEvents = this.rm.noti_available_events
        })
    }

    mounted() {
        gtagPage("#/settings/user")
    }

    public updateNotification(noti: any, provider: string) {
        if (provider == `webhook`) {
            this.webhookDic = noti
        } else if (provider == `slack`) {
            this.slackDic = noti
        } else if (provider == `teams`) {
            this.teamsDic = noti
        } else if (provider == `zapier`) {
            this.zapierDic = noti
        } else if (provider == `hubspot`) {
            this.hubspotDic = noti
        } else if (provider == `google_spread_sheet`) {
            this.googleSpreadSheetDic = noti
        }
    }

    checkPermission() {
        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `user_settings`, `user_settings_notifications`)) {
            this.$router.push("/settings/user")
        }
    }
}
