
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import HeaderControl from "packs/utils/HeaderControl"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.

// モデルを読込.

@Options({
    components: {},
})
export default class InstantRoomAllSwitcher extends Vue {
    // data:

    switchAll(status: boolean) {
        this.$emit(`switchAll`, status)
    }
}
