
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LocationIcon from "packs/components/icons/LocationIcon.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import FormFieldConverter from "packs/models/FormFieldConverter"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import FormCreateManager from "packs/models/FormCreateManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
// const roomStorage = new RoomStorage();

@Options({
    components: { CheckboxTag, MdTextfieldTag, SelectTag, RoomsLoader },
})
export default class FieldConvertersList extends Vue {
    @Prop()
    canEdit: boolean

    // data:
    notice = Notice

    rm = RoomManager
    fcm = FormCreateManager
    rcm = RoomCreateManager
    util = Util
    selectedTab = `locations`

    isSP = isMobile(window.navigator).phone
    failedKeyword = false
    loading = false

    myUser: RoomMember = null //コンタクトリストにある情報を使うため.

    newconv: FormFieldConverter = FormFieldConverter.createDefault()
    convs: FormFieldConverter[] = []
    failedConvBeforeKeyword = false
    failedConvAfterKeyword = false

    Util = Util
    SelectUtil = SelectUtil

    mounted() {
        this.getFieldConverters()
    }

    getFieldConverters() {
        if (Util.isPresent(this.convs)) return
        if (this.loading) return
        this.loading = true

        let form_id = this.fcm?.aform ? this.fcm.aform.form_id : this.rcm.room.form_id
        let fields = this.fcm?.fields ? this.fcm.fields : this.rcm.fields

        FormFieldConverter.getConverters(form_id).then(res => {
            this.loading = false
            if (res) {
                for (let conv of res) {
                    let f = fields.find(f => f.public_id == conv.form_field_public_id)
                    if (f) {
                        conv.form_field_name = f.field_name
                    }
                }
                this.convs = res

                this.updateConverters()
            }
        })
    }

    inputConvExtName(e) {
        this.newconv.ext_name = e
    }

    saveConv() {
        Logger(`${funcName()} conv:${Util.output(this.newconv)}`)
        if (Util.isBlank(this.newconv.ext_name)) {
            this.notice.message = `外部nameを入力してください。`
            return
        }
        if (Util.isBlank(this.newconv.form_field_name)) {
            this.notice.message = `フォームの質問を選択してください。`
            return
        }

        this.newconv.custom_form_id = this.fcm?.aform ? this.fcm.aform.form_id : this.rcm.room.form_id
        this.convs.push(this.newconv)
        this.newconv = FormFieldConverter.createDefault()
        this.updateConverters()
    }

    selectFormFieldName(fieldName: string) {
        Logger(`${funcName()} field:${Util.output(fieldName)}`)
        let fields = this.fcm?.fields ? this.fcm.fields : this.rcm.fields
        let field = fields.find(f => f.field_name == fieldName)
        if (Util.isBlank(field)) return
        Logger(`${funcName()} field:${Util.output(field)}`)

        this.newconv.form_field_name = field.field_name
        this.newconv.form_field_public_id = field.public_id
    }

    updateConverters() {
        if (this.fcm?.aform) {
            this.fcm.aform.form_field_converters = this.convs
            this.fcm.aform.has_detail_converters = true
        }

        if (this.rcm.room) {
            this.rcm.room.form_field_converters = this.convs
            this.rcm.room.has_detail_converters = true
            this.rcm.updateRoom(this.rcm.room)
        }
    }

    removeConv(conv: FormFieldConverter) {
        Logger(`${funcName()}`)
        this.convs = this.convs.filter(c => !FormFieldConverter.isSame(c, conv))
        this.updateConverters()
    }
}
