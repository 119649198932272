
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import RoomLightOverViewContent from "packs/pages/link/parts/RoomLightOverViewContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Appointment from "packs/models/Appointment"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import FormField from "packs/models/FormField"
import CalendarManager from "packs/models/CalendarManager"
import RoomMember from "packs/models/RoomMember"
import ChangeableDateTimeContent from "packs/components/calendar/ChangeableDateTimeContent.vue"
import AddCommentToMail from "packs/pages/link/parts/room_settings/AddCommentToMail.vue"
import MessageTemplate from "packs/models/MessageTemplate"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        RadioButtonTag,
        RoomLightOverViewContent,
        VueFinalModal,
        MdProgressBar,
        ChangeableDateTimeContent,
        AddCommentToMail,
        CheckboxTag,
    },
})
export default class RemindModal extends Vue {
    @Prop()
    isDev

    @Prop()
    selectedRooms: Room[]

    @Prop()
    selectedUsers: RoomMember[]

    @Prop()
    appo: Appointment

    @Prop() // 主にリスケボタンが押されたのかどうかを確認.
    appoStatus: string

    @Prop() // rooms, schedule_view
    from: string

    // data:

    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    typedSubject = ""
    tmp: MessageTemplate = null
    text = ``

    // 選択中のラジオボタンのこと
    action = `reschedule`
    loading = false
    Appointment = Appointment
    Room = Room
    Util = Util

    changeableEv: PossibleDate = null
    showPossibleDates = false

    openModal() {
        gtagPage(`#/リマインドモーダル`)
        Logger(`appoStatus: ${this.appoStatus}`)
    }

    public alreadyFinished(): boolean {
        if (!this.appo) return false
        if (this.appo.status != 10) return false

        let endTime = this.appo.start_time + this.appo.duration * 60
        const now = Util.getSec()
        if (endTime < now) {
            return true
        }
        return false
    }

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("CancelAppointmentModal")
    }

    send() {
        if (this.loading) return
        gtagClick(`sendRemind`, `${this.typedMessage}`)

        this.loading = true
        this.rm.startProgress()

        RoomMember.sendRemind(
            this.selectedRooms.map(r => r.id),
            this.selectedUsers.map(u => u.id),
            this.typedSubject,
            this.typedMessage,
            this.showPossibleDates
        ).then(e => {
            this.rm.endProgress()
            this.loading = false
        })
    }

    inputSubject(e) {
        this.typedSubject = e
    }

    public inputMessage(e) {
        this.typedMessage = e
    }

    clickShowPossibleDates() {
        if (this.showPossibleDates) {
            this.showPossibleDates = false
        } else {
            this.showPossibleDates = true
        }
    }
}
