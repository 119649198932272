
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import ListLoader from "packs/components/loader/ListLoader.vue"

// モデルを読込.
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        TextfieldTag,
        UserIcon,
        ListLoader,
    },
})
export default class SearchCalendarsContent extends Vue {
    @Prop() // func
    autoLoadWeeklyCalendar

    cm = CalendarManager
    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    ctList: CalendarTag[] // すべてのCtags
    searchedCtags: CalendarTag[] = [] // 検索結果に表示するCtags

    showSuggests = false
    searchText = ``

    selectingCtag: CalendarTag = null
    truncate = truncate

    public mounted() {
        this.updateCtags()
        this.updateOtherCtags()
        this.updateSharedCalendarTagsDic()

        // window.addEventListener("keyup", ev => {
        //     Logger(`code: ${String.fromCharCode(ev.keyCode)}`)
        //     // let lis = $(`.SuggestsCalendarListContent ul li`)
        //     // if (Util.isBlank(lis)) return

        //     // lis.removeClass(`selected`)
        //     if (ev.keyCode == 13) {
        //         // enter
        //         if (!this.selectingCtag) return
        //         this.selectCtag(this.selectingCtag)
        //     } else if ([38, 40].includes(ev.keyCode)) {
        //         if (Util.isBlank(this.searchedCtags)) return

        //         let keys = this.searchedCtags.map(ct => ct.search_key)
        //         let index = 0
        //         if (this.selectingCtag) {
        //             index = keys.indexOf(this.selectingCtag.search_key)
        //         }

        //         if (ev.keyCode == 38) {
        //             // ue
        //             index -= 1
        //             if (index < 0) {
        //                 index = 0
        //             }
        //         } else if (ev.keyCode == 40) {
        //             // shita
        //             index += 1
        //             if (index >= this.searchedCtags.length) {
        //                 index = this.searchedCtags.length - 1
        //             }
        //         }
        //         this.selectingCtag = this.searchedCtags[index]
        //     }
        // })
    }

    /***
     * 検索した際に表示するカレンダー絞り込みを行います。
     */
    public searchCalnedarValue(e) {
        if (!this.cm.edittingAttendees) return

        Logger(`${funcName()} searchText:${e}`)
        let searchedCtags = []
        let selectedCtagEmails: string[] = this.cm.edittingAttendees.map(c => c.email) || []
        let list = [...(this.ctList || [])]
        Logger(`${funcName()} selectedCtagEmails:${selectedCtagEmails}`)
        if (Util.isBlank(e)) {
            searchedCtags = list.filter(ctag => {
                if (!selectedCtagEmails.includes(ctag.email)) {
                    return ctag
                }
            })
        } else {
            searchedCtags = list.filter(
                ctag => CalendarTag.includesNameOrEmail(ctag, e) && !selectedCtagEmails.includes(ctag.email)
            )
        }
        this.searchText = e
        this.searchedCtags = searchedCtags

        Logger(`検索後のカレンダー: ${(this.searchedCtags || []).length}`)
        if (Util.isPresent(searchedCtags)) {
            let _ct = searchedCtags[0]
            this.selectingCtag = _ct
            Logger(`選択中のli:${_ct.name}`)
        }
    }

    /**
     * カレンダー候補をすべて取得してきた際に、一度だけ更新します。
     */
    @Watch("cm.ctList", { deep: true })
    public updateCtags() {
        Logger(`${funcName()} cm.astag.name:${this.cm.astag?.name}`)
        if (!this.cm.astag) return

        this.ctList = this.cm.findCurrrentCtList()
        this.resetSearchField()
    }

    @Watch(`cm.sharedAstagId`)
    public updateOtherCtags() {
        Logger(`${funcName()}`)
        this.updateCtags()
    }

    @Watch(`cm.sharedCalendarTagsDic`, { deep: true })
    updateSharedCalendarTagsDic() {
        Logger(`${funcName()}`)
        this.updateCtags()
    }

    /**
     * CalListの候補の中からカレンダーを選択します。
     */
    public selectCtag(ctag) {
        Logger(`${funcName()} 受け取りました calid:${ctag}`)
        let selectedCtags = [...(this.cm.edittingAttendees || [])]
        // selectedCtags.push(ctag)
        if (this.cm.createType == `vote`) {
            ctag.needVote = true
            this.cm.calendarRefreshText = `カレンダーを更新`
        }

        let ctagIds = selectedCtags.map(c => c.email)
        if (!ctagIds.includes(ctag.email)) {
            if (!this.cm.edittingAttendees) this.cm.edittingAttendees = []
            this.cm.edittingAttendees.push(ctag)
            this.autoLoadWeeklyCalendar()
        } else {
            // update
            let ctags = this.cm.edittingAttendees.filter(e => e.email != ctag.email)
            ctags.push(ctag)
        }
        this.resetSearchField()
    }

    /**
     * 検索フィールドを空にします。
     */
    public resetSearchField() {
        Logger(`AttendeesGroupField::resetSearchField`)
        this.searchText = ``
        $(".CalendarListSearchField").val(``)
        this.searchCalnedarValue(this.searchText)
        this.selectingCtag = null
    }

    public clickSearchCalendarField() {
        Logger(`clickSearchCalendarField: `)
        this.searchCalnedarValue(this.searchText)
        this.showSuggests = true
    }

    public blurSearchCalendarField() {
        Logger(`blurSearchCalendarField: `)
        // this.showSuggests = false;
    }
}
