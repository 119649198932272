
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"
import Const from "packs/utils/Const"
import Notice from "packs/models/Notice"
import TemplateUtil from "packs/utils/TemplateUtil"
import draggable from "vuedraggable"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIconSendMenu from "packs/components/icons/UserIconSendMenu.vue"
import SelectAttendeesWithAddressbook from "packs/pages/link/parts/available_schedule/SelectAttendeesWithAddressbook.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import MessageTemplate from "packs/models/MessageTemplate"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMember from "packs/models/RoomMember"
import VotedRoomManager from "packs/models/VotedRoomManager"
import LocationTag from "packs/models/LocationTag"
import CalendarTag from "packs/models/CalendarTag"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextareaTag,
        SelectTag,
        UserIcon,
        draggable,
        CheckboxTag,
        AddressBookContent,
        UserIconSendMenu,
        SelectAttendeesWithAddressbook,
        VueFinalModal,
        MdProgressBar,
        Dropdown,
    },
})
export default class ImportFromCalendarModal extends Vue {
    @Prop()
    room: Room

    // data:

    rm = RoomManager
    pdm = PossibleDatesManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    // メールに付随する内容
    typedMessage = ""

    // 調整ページ内のメモに入力しておく内容
    memo = ""

    SelectUtil = SelectUtil

    tmp: MessageTemplate = null
    currentPd?: PossibleDate = null
    Const = Const

    attendees: RoomMember[] = null
    notAttendees: RoomMember[] = null
    drag: boolean = false
    systemUpdatedAt: number = Util.getSec()

    // メモとメールを同じ内容のものを入れるか
    sameMailAndMemo = false

    // 場所名.（LocationTagではない.）
    location: string = null
    currentOnlines: CalendarTag[] = null
    defaultZoomEmail: string = null
    astag: AvailableScheduleTag = null
    forceType: string = null // filled: 既に埋まっているが強制確定させるか.
    rcm = RoomCreateManager

    openCustomizeMailContent = false
    viewType = `appo` // appo 確定するための情報を入力 / attendees 出席者をアドレス帳から選択

    // spanType = `period` //period（相対）,fixed
    startDate = DateTime.fromSeconds(
        this.rcm && this.rcm.room_setting
            ? this.rcm.room_setting.available_period_start_at || DateTime.local().toSeconds()
            : DateTime.local().toSeconds()
    ).toFormat("yyyy/MM/dd")
    endDate = DateTime.fromSeconds(
        this.rcm && this.rcm.room_setting
            ? this.rcm.room_setting.available_period_end_at || DateTime.local().toSeconds()
            : DateTime.local().plus({ days: 7 }).toSeconds()
    ).toFormat("yyyy/MM/dd")

    public openModal() {
        Logger(`${funcName()} 時間が更新されたため、pdをアップデートします.`)
        gtagPage(`#/投票作成モーダル`)
        this.updateAstag()
    }

    updateAstag() {
        let room = this.vrm.publicRoom
        let astagId = room.owner_avaialble_schedule_tag_id
        let _astag = (this.rm.astags || []).find(a => a.id == astagId)
        if (_astag) {
            this.astag = _astag
        }
    }

    public clickOK() {
        this.currentPd = this.pdm.currentPd
        Logger(
            `clickOK: pd: ${Util.output(this.currentPd)}, voteType: ${this.vrm.fixVoteType}, text: ${this.typedMessage}, memo: ${
                this.memo
            }, location: ${this.location}`
        )
    }

    public clickCancel() {
        this.$emit(`clickCancel`)
    }

    public selectVoteFixType(e) {
        let typeDic = SelectUtil.votedRoomFixTypes.find(t => t.name == e)
        if (typeDic) {
            this.vrm.fixVoteType = typeDic.type
        }
    }

    defaultVotedOption(): string {
        Logger(`fixVoteType: ${this.vrm.fixVoteType}`)
        if (this.vrm.fixVoteType) {
            let typeDic = SelectUtil.votedRoomFixTypes.find(t => t.type == this.vrm.fixVoteType)
            if (typeDic) {
                return typeDic.name
            }
        }
        return SelectUtil.votedRoomFixTypes[0].name
    }

    clickPreStep() {
        this.viewType = `appo`
        this.attendees = this.vrm.attendees
        this.notAttendees = this.vrm.notAttendees
    }

    @Watch(`startDate`, { deep: true })
    updateStartDate() {
        if (!this.rcm || !this.rcm.room_setting) return
        let sec = DateTime.fromFormat(this.startDate, `yyyy/MM/dd`).toSeconds()
        this.rcm.room_setting.available_period_start_at = sec
    }

    @Watch(`endDate`, { deep: true })
    updateEndDate() {
        if (!this.rcm || !this.rcm.room_setting) return
        let sec = DateTime.fromFormat(this.endDate, `yyyy/MM/dd`).toSeconds()
        this.rcm.room_setting.available_period_end_at = sec
    }
}
