
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import FileBoardModal from "packs/pages/link/modals/FileBoardModal.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import FormField from "packs/models/FormField"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"
import UserFile from "packs/models/UserFile"

@Options({
    components: {
        FileBoardModal,
        UserFileImage,
        IconLock,
        CheckboxTag,
    },
})
export default class PublicSettingsAttachmentFilesContent extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    // @Prop()
    // appo: Appointment

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting
    ruleText = null

    selectedTabName = `public` // public / schedule
    cm = CalendarManager

    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    timeArray = SelectUtil.timeArray
    notice = Notice
    currentFiles = []

    availableScheduleRuls = []

    fixAttachmentFiles = []
    inquireAttachmentFiles = []
    fileBoardType = null

    // showDetail = false

    mounted() {
        this.updateAttachmentFiles()
    }

    @Watch(`rcm.room_setting.attachment_files`, { deep: true })
    updateAttachmentFiles() {
        Logger(`${funcName()}`)
        let files = this.rcm.room_setting.attachment_files
        if (!files) return
        this.fixAttachmentFiles = files.filter(f => f.timing_type == `fixed_appointment`)
        this.inquireAttachmentFiles = files.filter(f => f.timing_type == `inquire_on_public_room`)
    }

    showImageBoardModal(type: string) {
        Logger(`${funcName()}`)
        this.fileBoardType = type
        this.$vfm.open(`FileBoardModal`)
    }

    selectFile(file: UserFile) {
        Logger(`${funcName()}: ${Util.output(file)}`)
        let rs = this.rcm.room_setting
        if (this.fileBoardType == `fix`) {
            file.timing_type = `fixed_appointment`
            this.fixAttachmentFiles = [file]
        } else {
            file.timing_type = `inquire_on_public_room`
            this.inquireAttachmentFiles = [file]
        }
        rs.attachment_files = [...this.fixAttachmentFiles, ...this.inquireAttachmentFiles]
        // rs.attachment_files = [file]
        // this.currentFiles = [file]
        this.rcm.updateRoomSetting(rs)
        this.$vfm.close(`FileBoardModal`)
    }

    deleteFiles(uf: UserFile, type: string) {
        Logger(`${funcName()}`)
        let rs = this.rcm.room_setting
        if (type == `fix`) {
            this.fixAttachmentFiles = []
        } else {
            this.inquireAttachmentFiles = []
        }
        rs.attachment_files = [...this.fixAttachmentFiles, ...this.inquireAttachmentFiles]
        this.rcm.updateRoomSetting(rs)
    }

    changeAttachment(type: string) {
        Logger(`${funcName()} type:${type}`)
        let rs = this.rcm.room_setting
        this.currentFiles = type == `fix` ? this.fixAttachmentFiles : this.inquireAttachmentFiles
        this.fileBoardType = type
        this.$vfm.open(`FileBoardModal`)
    }

    clickAutoDownloadFiles() {
        let rs = this.rcm.room_setting
        if (rs.can_auto_download) {
            rs.can_auto_download = false
        } else {
            rs.can_auto_download = true
        }
        this.rcm.updateRoomSetting(rs)
    }
}
