
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import axios from "axios"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import SelectableImage from "packs/components/images/SelectableImage.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import UserFile from "packs/models/UserFile"

@Options({
    components: { SelectableImage, VueFinalModal, MdProgressBar },
})
export default class FileBoardModal extends Vue {
    @Prop()
    title: string

    @Prop()
    currentFiles: UserFile[]

    @Prop() // 画像 + アップロードしている画像を表示
    showWithFiles: boolean

    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    loading = false
    isDrag = null
    // astags: AvailableScheduleTag[] = null

    beforeId: string = null

    fileId: string = null
    beforeFiles: UserFile[] = []
    selectedFile: UserFile = null // 選択中の画像ファイル

    // 公開で保存している画像.（UGごと）
    userFiles: UserFile[] = null

    validImage = true

    public dismissModal() {
        this.$vfm.close("FileBoardModal")
    }

    cancelInput() {
        this.dismissModal()
    }

    openModal() {
        this.updateBeforeFiles()
        if (this.rm.userInfo) {
            this.updateFiles()
        }
    }

    updateFiles() {
        if (this.rm.userFiles) {
            this.userFiles = this.rm.userFiles
        } else {
            axios.get(`${Util.prefixUrl}/file_uploaders/index_public_images?show_with_files=true`).then(e => {
                let _images = e.data.images
                if (_images) {
                    let images = UserFile.fetchFromJson(_images)
                    this.userFiles = images
                    this.rm.userFiles = images

                    return this.userFiles
                }
            })
        }
    }

    @Watch(`currentFiles`)
    updateBeforeFiles() {
        if (Util.isPresent(this.currentFiles)) {
            this.beforeFiles = this.currentFiles
            let file = this.beforeFiles[0]
            if (Util.isPresent(file)) {
                this.beforeId = file.file_id
            }
        } else {
            this.beforeFiles = []
            this.beforeId = null
        }
        Logger(`${funcName()} beforeId:${this.beforeId}`)
    }

    public create() {
        if (!this.selectedFile) {
            Notice.message = `ファイルを選択してください。`
            return
        }
        this.$emit(`select`, this.selectedFile)
    }

    closeModal() {
        this.$emit("saveProfile", null)
    }

    public changeFile(file: UserFile) {
        Logger(`${funcName()} ${file.image_url}`)
        this.fileId = file.file_id
        this.beforeFiles = [file]
        this.selectedFile = file
        this.beforeId = file.file_id
    }

    public mouseoverFile(file: UserFile) {
        this.fileId = file.file_id
    }

    public mouseleaveFile() {
        this.fileId = this.beforeFiles.map(m => m.file_id).join()
    }

    public deleteImage(file: UserFile) {
        if (confirm(`削除してよろしいですか？この操作は元に戻せません。`)) {
            UserFile.delete(file.file_id).then(e => {
                this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
                this.rm.userFiles = this.userFiles
                if (this.beforeId == file.file_id) {
                    this.beforeId = null
                    this.beforeFiles = []
                }
                let selectedFile = UserFile.findFileById(file.file_id, this.currentFiles)
                if (selectedFile) {
                    this.$emit(`deleteFiles`)
                }
            })
        }
    }

    checkDrag(event, key, status) {
        this.isDrag = status ? key : null
        Logger(`isDrag: ${this.isDrag}`)
    }

    onDrop(event, key = "", image = {}) {
        Logger(`ドロップしました。`)
        if (this.loading) {
            Notice.message = `アップロード中です。`
            return false
        }
        this.isDrag = null

        let params = UserFile.createFileParams(event, `public_image`)[0]
        if (!params) return

        params.append(`update_self`, `${false}`)

        this.loading = true
        this.rm.startProgress()

        UserFile.uploadFile(params, `group`).then(data => {
            event.target.value = null
            event = null
            this.loading = false
            this.rm.endProgress()
            if (data.result == `ok`) {
                Logger(`${funcName()} イメージを置き換えます : ${Util.output(data)}`)
                // this.rm.userInfo.image_url = data.image_url
                let uf = UserFile.fetchFromJson([data.file])[0]
                if (Util.isPresent(this.userFiles)) {
                    this.userFiles.push(uf)
                } else {
                    this.userFiles = [uf]
                }
                this.rm.userFiles = this.userFiles
                this.changeFile(uf)
                this.$emit("uploaded", data.file)
                // this.$emit("uploadedFile")
            }
        })
    }
}
