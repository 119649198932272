
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import PPTosContent from "packs/components/forms/PPTosContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import RoomMessage from "packs/models/RoomMessage"
import FormField from "packs/models/FormField"

const roomStorage = new RoomStorage()

@Options({
    components: { MdTextfieldTag, PPTosContent, VueFinalModal, MdProgressBar },
})
export default class EmailNameFieldsModal extends Vue {
    @Prop()
    typedMessage: string

    @Prop()
    room: Room

    isPreview: boolean = Util.isPreview()

    // data
    rm = RoomManager
    loading = false
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone

    contentHeight = 320
    validEmail = true
    validName = true
    validCompanyName = true

    saving = false
    notice = Notice
    userInfo: RoomMember = RoomMember.createDefaultClient()
    Util = Util
    companyField: FormField = null

    openModal() {
        gtagPage(`#/チャット送信名前入力モーダル`)
        this.checkCompanyField()
        this.checkAndFilledFields()
    }

    public checkAndFilledFields() {
        if (Util.isBlank(this.rm.userInfo)) return

        if (this.rm.userInfo.email) {
            this.userInfo.email = this.rm.userInfo.email
        }
        if (this.rm.userInfo.name) {
            this.userInfo.name = this.rm.userInfo.name
        }
        if (this.rm.userInfo.company_name) {
            this.userInfo.company_name = this.rm.userInfo.company_name
        }
    }

    checkCompanyField() {
        if (!this.room) return
        let ffs = [...(this.room.form_fields || [])]
        if (ffs.length == 0) return
        let companyff = ffs.find(ff => ff.param_key == `attendee1_company_name`)
        if (!companyff) return
        this.companyField = companyff
    }

    public inputEmail(e) {
        this.userInfo.email = e
    }

    public checkEmail() {
        this.validEmail = Util.isPresent(this.userInfo.email) && Util.validateEmail(this.userInfo.email)
    }

    public inputName(e) {
        this.userInfo.name = e
    }

    public checkName() {
        this.validName = Util.isPresent(this.userInfo.name)
    }

    public checkCompanyName() {
        if (this.companyField && this.companyField.is_required) {
            this.validCompanyName = Util.isPresent(this.userInfo.company_name)
        }
    }

    public inputCompanyName(e) {
        this.userInfo.company_name = e
    }

    public closeModal() {
        this.$vfm.close(`EmailNameFieldsModal`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    public send() {
        this.checkName()
        this.checkEmail()
        this.checkCompanyName()
        if (!this.validName) {
            this.notice.message = `お名前を正しく入力してください`
            return
        }

        if (!this.validEmail) {
            this.notice.message = `メールアドレスを正しく入力してください`
            return
        }

        if (!this.validCompanyName) {
            this.notice.message = `${this.companyField.field_name}を正しく入力してください`
            return
        }

        // 場所情報を変更する処理.
        if (this.loading) return
        this.loading = true

        // let appo: Appointment = Appointment.copy(this.appo)
        RoomMessage.startChat(this.userInfo, this.room.id, this.typedMessage).then(dic => {
            this.loading = false
            if (Util.isPresent(dic)) {
                this.$emit(`sentEmailName`, dic)
            }
        })
    }
}
