
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, funcName, Logger, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AccountHeader from "./AccountHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import MdRadioButtonTag from "packs/components/forms/MdRadioButtonTag.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import NotificationControl from "packs/utils/NotificationControl"
import CustomNotification from "packs/models/CustomNotification"
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomTab from "packs/models/RoomTab"
import FileUtil from "packs/utils/FileUtil"

const roomStorage = new RoomStorage()

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
        FormFieldContent,
        MdRadioButtonTag,
        FlashNotice,
        MdProgressSpinner,
        Dropdown,
    },
})
export default class DownloadConditionsContent extends Vue {
    @Prop()
    pubRoom: Room

    @Prop()
    publicIds: string[]

    // data:

    rm = RoomManager
    Util = Util

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: CustomNotification = null
    showTip = false
    downloading = false

    statuses = Appointment.DEFAULT_DOWNLOAD_STATUSES

    fields: FormField[] = null
    startDate = DateTime.local().minus({ days: 7 }).toFormat("yyyy/MM/dd")
    endDate = DateTime.local().toFormat("yyyy/MM/dd")
    fileType = "csv"
    sortType = `created_at`

    errMsgSpan: string = null
    errMsgAppoStatus: string = null
    errMsgProgressStatus: string = null

    onlyUpcomingFixed = false

    progressStatus = [
        { name: `go`, title: `日程調整表示`, checked: true },
        { name: `stay`, title: `日程調整停止中`, checked: true },
    ]

    mounted() {
        let _start = FormFieldCreator.serviceStart()
        let _end = FormFieldCreator.serviceStart()
        _end.index_num = 1
        this.fields = [_start, _end]
    }

    updateFromChild(field: FormField) {
        Logger(`update field val: ${field.value_attr}`)
        let _fields = Util.isPresent(this.fields) ? [...this.fields] : []
        let _f = _fields.find(_f => _f.index_num == field.index_num)
        _f = Object.assign(_f, field)
        this.fields = _fields
    }

    clickAppoStatus(dic: any) {
        Logger(`${funcName()} dic:${Util.output(dic)}`)
        let name = dic.value
        let _status = this.statuses.find(s => s.name == name)
        _status.checked = dic.checked ? true : false

        let isSelectNotOnlyFixed = false
        for (let dic of this.statuses) {
            if (dic.name == "fixed") continue
            if (dic.checked) {
                isSelectNotOnlyFixed = true
                break
            }
        }
        if (isSelectNotOnlyFixed) {
            this.onlyUpcomingFixed = false
        }
    }

    clickProgressStatus(dic: any) {
        Logger(`${funcName()} dic:${Util.output(dic)}`)
        let name = dic.value
        let _status = this.progressStatus.find(s => s.name == name)
        _status.checked = dic.checked
    }

    public selectFileType(choice) {
        Logger(`choice: ${choice}`)
        this.fileType = choice
    }

    startDownload() {
        // フォームバリデート.
        if (Util.isBlank(this.startDate) || Util.isBlank(this.endDate)) {
            let msg = `期間を正しく入力してください。`
            Notice.message = msg
            this.errMsgSpan = msg
        } else {
            this.errMsgSpan = null
        }

        let checkedAppoStatus = this.statuses.filter(s => s.checked == true)
        let checkedProgressStatus = this.progressStatus.filter(s => s.checked == true)
        if (checkedAppoStatus.length == 0) {
            let msg = `調整ステータスは少なくとも1つ選択してください。`
            Notice.message = msg
            this.errMsgAppoStatus = msg
        } else {
            this.errMsgAppoStatus = null
        }
        if (checkedProgressStatus.length == 0) {
            let msg = `日程提示ステータスは少なくとも1つ選択してください。`
            Notice.message = msg
            this.errMsgProgressStatus = msg
        } else {
            this.errMsgProgressStatus = null
        }

        if (
            Util.isPresent(this.errMsgSpan) ||
            Util.isPresent(this.errMsgAppoStatus) ||
            Util.isPresent(this.errMsgProgressStatus)
        ) {
            return
        }
        Logger(
            `${this.startDate} ~ ${this.endDate}, ${checkedAppoStatus.map(s => s.num)} ${checkedProgressStatus.map(s => s.name)}`
        )
        if (this.downloading) {
            Notice.message = `現在ダウンロードリクエスト中です。`
            return
        }
        this.downloading = true
        // 二度押さないように上までスクロール.
        let area = document.getElementById("downloadableContent")
        if (area) area.scrollTop = 0

        FileUtil.downloadRoomsFile(
            this.startDate,
            this.endDate,
            checkedAppoStatus.map(a => a.num),
            checkedProgressStatus.map(s => s.name),
            this.pubRoom ? this.pubRoom.id : null,
            this.fileType,
            this.publicIds,
            this.onlyUpcomingFixed,
            this.sortType
        ).then(res => {
            Logger(`${funcName()} ${Util.output(res)}`)
            this.downloading = false
            if (res) {
                // Notice.message = `ダウンロード可能な調整ページが0件でした`
                let files = this.rm.downloadableFiles || []
                files.unshift(res.file)
                this.rm.downloadableFiles = files
            }
        })
    }

    clickUpcomingFixed() {
        if (this.onlyUpcomingFixed) {
            this.onlyUpcomingFixed = false
        } else {
            this.onlyUpcomingFixed = true
            for (let dic of this.statuses) {
                if (dic.name == "fixed") dic.checked = true
                else dic.checked = false
            }
        }
    }

    switchSortType() {
        Logger(`${funcName()}`)
        this.sortType = this.sortType == `created_at` ? `updated_at` : `created_at`
    }
}
