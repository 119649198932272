
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import AstagsTab from "packs/models/fetcher/AstagsTab"

@Options({
    components: {
        CalendarIcon,
        FlashNotice,
        MdProgressSpinner,
        RoomsLoader,
    },
})
export default class AstagLists extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    @Prop()
    canDelete: boolean

    @Prop() // quick_vote
    from: string

    @Prop()
    hideSharedAstags: boolean

    @Prop() // default(自分が作成)/shared（共有を受けたもの）/all(すべて)
    selectedCategoryTabName: string

    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    cm = CalendarManager
    astags: AvailableScheduleTag[] = null
    astagsTab: AstagsTab = null

    loading = false
    systemUpdatedAt = Util.getSec()

    mounted() {
        Logger(
            `${funcName()} from:${this.from}, canDelete:${this.canDelete}, selectedCategoryTabName:${
                this.selectedCategoryTabName
            }, this.rm.astags:${this.rm.astags?.length}`
        )
        this.updateAstagsTab()
        this.updateSearchedAstags()
        // let astags = this.getAllAstags()
        // if (this.from == `quick_vote`) {
        //     // is_only_use_optional_schedulesのもののみを利用します.
        //     astags = astags.filter(ast => ast.is_only_use_optional_schedules)
        // }
        // this.astags = astags
    }

    @Watch(`selectedCategoryTabName`)
    updateAstagsTab() {
        if (!this.rm.astags) return []
        this.astagsTab = this.rm.astagsTab
        Logger(`${funcName()} selectedCategoryTabName:${this.selectedCategoryTabName}, astags:${this.astagsTab?.astags?.length}`)

        if (this.selectedCategoryTabName == `default`) {
            // this.astags = this.rm.astags.filter(ast => ast.type == 2)
            let astags = this.getAllAstags()
            this.astags = astags
        } else if (this.selectedCategoryTabName == `shared`) {
            if (this.astagsTab.shared_page == 0 && this.astagsTab.shared_has_next) {
                // 取得します.
                this.astags = null
                this.loading = true
                AstagsTab.getNextAstags(this.astagsTab, `shared`).then(res => {
                    this.loading = false
                    if (res) {
                        this.rm.updateAstagsInfoWithTab(res.tab)
                        this.updateAstagsTab()
                    }
                    this.astags = this.rm.shared_astags
                })
            } else {
                this.astags = this.rm.shared_astags
            }
        } else if (this.selectedCategoryTabName == `search`) {
            this.astags = this.astagsTab.searched_astags
        } else {
            this.astags = this.getAllAstags()
        }
        this.systemUpdatedAt = Util.getSec()
    }

    @Watch(`rm.astagsTab.searched_astags`, { deep: true })
    updateSearchedAstags() {
        Logger(
            `${funcName()} selectedCategoryTabName:${this.selectedCategoryTabName}, rm.searched_astags:${
                this.rm.astagsTab?.searched_astags?.length
            }`
        )
        if (!this.rm.astagsTab) return
        if (this.selectedCategoryTabName != `search`) return

        this.astags = this.rm.astagsTab.searched_astags
    }

    getAllAstags() {
        if (!this.rm.astags) return []
        let astags = this.rm.getAllAstags(this.cm.astag)
        if (this.hideSharedAstags) {
            astags = astags.filter(ast => !ast.is_shared)
        }
        // return this.rm.astags.filter(a => [1, 2, 50].includes(a.type))
        return astags
    }

    public selectAstag(astag: AvailableScheduleTag) {
        Logger(`astagを選択`)
        this.rm.astag = astag
        this.cm.astag = astag
        this.selectEnd()
    }

    public deleteAstag(astag: AvailableScheduleTag) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        if (confirm(`削除した場合、元に戻せません。※作成済みの調整ページに影響はありません。`)) {
            if (astag.id == `newId`) {
                Logger(`削除したいastag: ${Util.output(astag)}`)
                this.astags = this.astags.filter(a => a.id != `newId` && a.name != astag.name)
                this.rm.endProgress()
                this.rm.astags = this.rm.astags.filter(a => a.id != `newId` && a.name != astag.name)
                this.loading = false
                return
            }
            AvailableScheduleTag.delete(astag).then(res => {
                this.rm.endProgress()
                this.loading = false
                if (res) {
                    this.astags = this.astags.filter(ast => ast.id != res.id)
                    this.systemUpdatedAt = Util.getSec()
                    // 最終astagsを保存.
                    let { astag, chukaiAstag, defaultAstag, astags } = AvailableScheduleTag.setAstags(this.astags)
                    this.rm.astag = astag
                    this.rm.defaultAstag = defaultAstag
                    this.rm.astags = this.rm.astags.filter(a => a.id != res.id)
                }
            })
        }
    }

    public changeDefault(astag: AvailableScheduleTag) {
        AvailableScheduleTag.changeDefault(astag).then(e => {
            if (e) {
                this.rm.changeDefaultAstag(astag)
                this.astags = this.getAllAstags()
            }
        })
    }

    createVotedRoom(astag: AvailableScheduleTag) {
        this.$emit(`createVotedRoom`, astag)
    }

    /**
     * コピーしたastagに初期情報をセットします.
     * @param _astag
     */
    setInitializeCopyAstag(_astag: AvailableScheduleTag) {
        _astag.id = `newId`
        _astag.name = `${_astag.name}のコピー`
        _astag.color = Util.getRandomColor()
        _astag.type = 1
        if (Util.isPresent(_astag.meeting_attendees_groups)) {
            let mags = _astag.meeting_attendees_groups
            for (let mag of mags) {
                mag.id = null
            }
        }
    }

    public clickCopy(astag: AvailableScheduleTag) {
        Logger(`${funcName()} astag:${astag?.id}, ${astag?.name} has_detail:${astag?.has_detail}`)
        let _astag = { ...astag }
        if (astag.has_detail) {
            this.setInitializeCopyAstag(_astag)
            this.selectAstag(_astag)
        } else {
            AvailableScheduleTag.getAstagDetail(astag.id).then(res => {
                if (res) {
                    _astag = { ...res.astag }
                    this.setInitializeCopyAstag(_astag)
                    this.selectAstag(_astag)
                }
            })
        }
    }

    public selectEnd() {
        Logger(`選択しました。`)
        // const astag = this.astags.find(e => e.selected)
        // this.rm.astag = astag;
        // this.$vfm.close("SelectAstagsModal")
        this.$emit("selectEnd", this.rm.astag, `normal`)
    }

    public getNextPage() {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        let type = `default`
        if (this.astagsTab.tab_name == `shared`) {
            type = `shared`
        }

        AstagsTab.getNextAstags(this.astagsTab, type).then(res => {
            this.rm.endProgress()
            this.loading = false
            if (res) {
                this.rm.updateAstagsInfoWithTab(res.tab)
                this.updateAstagsTab()
            }
        })
    }
}
