
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick } from "packs/GoogleTagManager"
import { Logger, sleep } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import AttendeeForm from "packs/pages/link/parts/AttendeeForm.vue"
import ModalDeleteFromAddressBook from "packs/pages/link/modals/ModalDeleteFromAddressBook.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import AttendeeEditModal from "packs/pages/link/modals/AttendeeEditModal.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"
import ContactListGroup from "packs/models/ContactListGroup"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import AddressBook from "packs/models/AddressBook"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextfieldTag,
        AttendeeForm,
        ModalDeleteFromAddressBook,
        RoomsLoader,
        AttendeeEditModal,
        UserIcon,
        SelectTag,
    },
})
export default class AddressBookContent extends Vue {
    @Prop() // 調整ページ作成画面で、選択済みのメンバーを表示しないよう選択したメンバー.
    members: RoomMember[]

    @Prop()
    currentMember: RoomMember

    @Prop()
    editOrNewForm: string

    @Prop() // メンバーごとに編集/削除ボタンを表示するか.
    showEditButton: boolean

    @Prop() // 「新規」ボタンを表示するか.
    showNewButton: boolean

    @Prop() // グループを削除できるか.
    editGroup: boolean

    @Prop() // グループを隠すか.
    hideGroups: boolean

    @Prop()
    focusSearchField: boolean

    permitAddressBook: boolean = false

    // data:
    notice = Notice

    rm = RoomManager
    Util = Util
    truncate = truncate

    groupTitle = ""
    isSP = isMobile(window.navigator).phone

    // アドレス帳にあるメンバー.
    invitedUsers: RoomMember[] = null
    contactListGroups: ContactListGroup[] = null
    ugMembers: RoomMember[] = null

    isOpenForm: boolean = false

    room: Room = null
    action: string

    loading = false
    searching = false

    selectedMember: RoomMember = null
    selectedClg: ContactListGroup = null
    searchText = ``
    // 検索時に表示するメンバー.
    displayMembers: RoomMember[] = null
    // 検索時に表示するグループ.
    displayClgs: ContactListGroup[] = null

    systemUpdatedAt: number = Util.getSec()
    viewType = `all` // all, memberOnly, connectCal
    searchedCount = null

    mounted() {
        this.configureMembers()

        if (this.focusSearchField) {
            sleep(500).then(_ => {
                $("input.searchAddressbookField").eq(0).focus()
            })
        }
    }

    memberOnly() {
        if (this.viewType == `all`) {
            this.viewType = `memberOnly`
        } else {
            this.viewType = `all`
        }
    }

    // アドレス帳の情報を入れます.
    configureMembers() {
        this.permitAddressBook = UserPermissionsOption.permitEdit(this.rm.userInfo, `user_settings`, `user_settings_address_book`)

        if (Util.isBlank(this.invitedUsers)) {
            // アドレス帳を編集権限がない場合は、取得しません.
            if (!this.permitAddressBook) {
                this.invitedUsers = []
                this.contactListGroups = []
                this.displayClgs = []
                this.isOpenForm = true
                return
            }

            // 招待したのことのあるユーザーを取得.
            this.rm.getAddressBooks(false).then(cl => {
                Logger(`rm.getAddressBooks: ${Util.output(cl)}`)
                this.invitedUsers = cl
                this.contactListGroups = this.rm.address_book.contact_list_groups
                this.searchInAddressBook()
                this.ugMembers = this.rm.address_book.ug_members
                this.isOpenForm = false
            })
        }
    }

    getMoreAddressBook() {
        this.rm.getAddressBooks(true).then(cl => {
            Logger(`rm.getAddressBooks: ${Util.output(cl)}`)
            this.invitedUsers = cl
            this.contactListGroups = this.rm.address_book.contact_list_groups
            this.searchInAddressBook()
            this.ugMembers = this.rm.address_book.ug_members
            this.isOpenForm = false
        })
    }

    openForm() {
        Logger(`open: ${this.isOpenForm}`)
        // this.isOpenForm = !this.isOpenForm
        if (this.isOpenForm) {
            this.isOpenForm = false
        } else {
            this.isOpenForm = true
        }
        Logger(`isOpenForm: ${this.isOpenForm}`)
        // this.$emit(`openForm`)
    }

    // 既に選択済みのユーザー（this.members）は表示させない.
    // @param members [RoomMember[]] 選択済みのメンバー（出席者）
    public showMembers(members: RoomMember[]): RoomMember[] {
        if (!this.invitedUsers) return null
        let dispMems = []
        if (Util.isPresent(members)) {
            dispMems = members.filter(u => !this.members.some(m => u.email == m.email))
        } else {
            // return null
            dispMems = this.invitedUsers.filter(u => !this.members.some(m => u.email == m.email))
        }

        return dispMems
    }

    /**
     * 公開メモの入力。
     */
    // public inputMemo(e) {
    //     this.room.memo = e;
    // }

    // public openAttendeeModal() {
    //     this.currentMember = null;
    //     this.$vfm.open("AttendeeEditModal");
    // }

    public selectFromAddressBook(member) {
        this.$emit("addAttendee", member)
        gtagClick(`アドレス帳から出席者を選択`, `出席者:${this.members.length}`)
    }

    selectGroupFromAddressBook(clg: ContactListGroup) {
        this.$emit("selectContactListGroup", clg)
        gtagClick(`アドレス帳からCLGを選択`, `出席者:${this.members.length}`)
    }

    public addAttendee(member) {
        this.$emit("addAttendee", member)
        this.isOpenForm = false
    }

    public addAttendeeFromAddressBook(member: RoomMember) {
        let sendType = `post`
        if (this.editOrNewForm == `edit`) {
            sendType = `put`
        }
        this.rm.startProgress()
        if (this.loading) return
        this.loading = true
        RoomMember.saveAddressBook(member, sendType).then(savedUser => {
            if (savedUser) {
                let mem = this.displayMembers.find(m => m.user_id == member.user_id)
                if (mem) {
                    mem = savedUser
                } else {
                    this.displayMembers.push(savedUser)
                }
                this.addAttendee(savedUser)
            }
            this.loading = false
            this.rm.endProgress()
        })
    }

    public editAttendee(member: RoomMember) {
        this.loading = true
        RoomMember.saveAddressBook(member, "put").then(saved_user => {
            if (saved_user) {
                // let mem = this.displayMembers.find(m => m.email == member.email)
                let mem = this.displayMembers.find(m => m.user_id == member.user_id)
                if (mem) {
                    Object.assign(mem, saved_user)
                    this.systemUpdatedAt = Util.getSec()
                    this.notice.message = `アドレス帳を更新しました。`
                }
            }
            this.fadeOutAttendeesModal()
            this.loading = false
        })
    }

    public fadeOutAttendeesModal() {
        this.$vfm.close("AttendeeEditModal")
    }

    public showDeleteFromAddressBookModal(user) {
        this.selectedMember = user
        this.selectedClg = null
        this.$vfm.open("ModalDeleteFromAddressBook")
    }

    public editUser(user) {
        this.selectedMember = user
        this.$vfm.open("AttendeeEditModal")
    }

    public deleteUser(deleted: boolean) {
        if (deleted) {
            if (this.selectedMember) {
                this.$emit("deleteUser", this.selectedMember)
            } else if (this.selectedClg) {
                let clgs = [...this.rm.address_book.contact_list_groups]
                let _clgs = clgs.filter(clg => clg.id != this.selectedClg.id)
                this.contactListGroups = _clgs
                this.displayClgs = _clgs
                this.rm.address_book.contact_list_groups = _clgs
                this.selectedClg = null
            }
        }
    }

    public inputSearchText(text) {
        this.searchText = text
    }

    startSearch() {
        this.searchInAddressBook()
    }

    searchInAddressBook() {
        if (!this.invitedUsers) {
            return
        }

        if (this.searchText.length > 0 && this.invitedUsers && this.invitedUsers.length > 0) {
            if (this.searching) return
            this.searching = true

            const _text = this.searchText.toLowerCase()
            // let mems = this.showMembers(null)
            // Logger(`search text mems : ${mems.length}`)
            AddressBook.search(_text).then(res => {
                this.searching = false
                if (res) {
                    this.displayMembers = res
                    this.searchedCount = res.length
                } else {
                    this.displayMembers = []
                    this.searchedCount = 0
                }
                this.displayClgs = []
            })
            // let searchedMems = mems.filter(m => RoomMember.includeSomeWords(m, _text))
            // let clgs = [...(this.contactListGroups || [])]
            // let searchedClgs = clgs.filter(clg => ContactListGroup.isInclude(clg, searchedMems))
            // this.displayMembers = searchedMems
            // this.displayClgs = searchedClgs
        } else {
            this.displayMembers = this.showMembers(null)
            this.displayClgs = [...(this.contactListGroups || [])]
            this.searchedCount = null
        }
    }

    deleteGroupList(clg: ContactListGroup) {
        Logger(`delete clg: ${Util.output(clg)}`)
        this.selectedMember = null
        this.selectedClg = clg
        this.$vfm.open("ModalDeleteFromAddressBook")
    }

    selectCategory(catName: string) {
        Logger(`selectCategory catName: ${catName}`)
        if (catName == `カテゴリ検索`) {
            this.viewType = `all`
        } else if (catName == `グループ以外`) {
            this.viewType = `membersOnly`
            this.viewType = `connectCal`
            let mems = [...(this.invitedUsers || [])]
            let _mems = this.showMembers(mems)
            this.displayMembers = _mems
        } else if (catName == `カレンダー連携済`) {
            this.viewType = `connectCal`
            let mems = [...(this.invitedUsers || [])]
            let ugMems = [...(this.ugMembers || [])]
            Array.prototype.push.apply(mems, ugMems)
            let _mems = this.showMembers(mems)
            let connectCalMembers = _mems.filter(m => m.calendar_tag)

            this.displayMembers = connectCalMembers
        } else if (catName == `登録メンバー`) {
            this.viewType = `ugMembers`
            this.displayMembers = this.ugMembers
        }
    }

    resetSearchText() {
        this.searchText = ``
        this.searchedCount = null
        this.displayMembers = this.showMembers(null)
        this.displayClgs = [...(this.contactListGroups || [])]
    }
}
