
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { funcName, Logger, sleep } from "packs/common"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import Timezones from "packs/utils/Timezones"

// モデルを読込.
import CalendarTag from "packs/models/CalendarTag"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

@Options({
    components: { TextfieldTag },
})
export default class TimezoneSelectContent extends Vue {
    @Prop()
    defaultTimezone: string

    allTimezones = null
    timezonesArray = null

    selectedTzDic = null

    // data
    isSP = isMobile(window.navigator).phone
    Util = Util

    showList = false
    pdm = PossibleDatesManager
    selectedTimezone = this.pdm.jpTimezoneValue

    created() {
        Timezones.getTimezones().then(res => {
            if (res) {
                this.allTimezones = res
                this.timezonesArray = res
            }
            // Logger(`${funcName()} Timezones.getTimezones:${Util.output(res)}`)
            if (!this.selectedTzDic && this.defaultTimezone) {
                this.selectedTzDic = res.find(tz => tz.value == this.defaultTimezone)
                Logger(`${funcName()} selectedTzDic:${Util.output(this.selectedTzDic)}`)
            }
        })
        this.updateTimezone()
    }

    filterLocation(text: string) {
        Logger(`${funcName()} text:${text}`)

        if (Util.isBlank(text)) {
            this.timezonesArray = [...this.allTimezones]
        } else {
            let _text = text.toLowerCase()
            let timezonesArray = [...this.allTimezones]
            let _selectedTZs = (timezonesArray || []).filter(
                _tz => _tz.name.toLowerCase().includes(_text) || _tz.value.toLowerCase().includes(_text)
            )
            this.timezonesArray = _selectedTZs
        }
    }

    @Watch(`defaultTimezone`)
    updateDefaultTimezone() {
        this.updateTimezone()
    }

    @Watch(`pdm.currentTZDic`, { deep: true })
    updateTimezone() {
        Logger(
            `${funcName()} 111 pdm.currentTZDic:${Util.output(this.pdm.currentTZDic)} selectedTzDic:${Util.output(
                this.selectedTzDic
            )}, selectedTimezone:${this.selectedTimezone}`
        )
        if (this.pdm.currentTZDic) {
            this.selectedTzDic = { ...this.pdm.currentTZDic }
            this.selectedTimezone = this.selectedTzDic.name
        } else {
            if (!this.selectedTimezone) {
                this.selectedTimezone = this.defaultTimezone
            }

            let _selectedTZ = (this.allTimezones || []).find(_tz => this.defaultTimezone == _tz.value)
            this.selectedTzDic = _selectedTZ
        }
        Logger(
            `${funcName()} pdm.currentTZDic:${Util.output(this.pdm.currentTZDic)} selectedTzDic:${Util.output(
                this.selectedTzDic
            )}, selectedTimezone:${this.selectedTimezone}`
        )
    }

    public selectTimezone(tzName: any) {
        Logger(`${funcName()} tzName:${tzName}`)
        let _selectedTZ = (this.timezonesArray || []).find(_tz => tzName == _tz.name)
        Logger(`${funcName()} _selectedTZ:${_selectedTZ}`)
        if (_selectedTZ) {
            this.selectedTimezone = _selectedTZ.name
            this.selectedTzDic = _selectedTZ
            this.showList = false
            this.$emit(`selectTimezone`, _selectedTZ)
        }
    }

    clickTextfield() {
        Logger(`${funcName()}`)
        this.showList = true
        $(`input#tzList`).select()
    }

    blurField() {
        sleep(500).then(_ => {
            this.showList = false
        })
    }
}
