
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import { DateTime } from "luxon"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Event from "packs/models/Event"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"

@Options({
    components: { UserIcon, VueFinalModal, MdProgressBar },
})
export default class CalendarEventDetailModal extends Vue {
    // data
    cm = CalendarManager
    rm = RoomManager
    DateTime = DateTime

    loading = false

    ev: Event = null
    Util = Util
    regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

    openModal() {
        gtagPage(`週次カレンダー イベント詳細モーダル`)
        this.ev = this.cm.selectedEvent
        Logger(`ev: ${Util.output(this.ev)}`)
    }

    get calendarName() {
        if (!this.ev) return
        if (Util.isBlank(this.cm.calendar_name_dic)) return
        return this.cm.calendar_name_dic[this.ev.calendar_id]
    }

    // currentRoom: Room = null

    public hideFixScheduleModal() {
        this.$vfm.close("CalendarEventDetailModal")
    }

    clickOnlineMeeting() {
        gtagClick(`イベント詳細 オンラインミーティングURL`, `${this.ev.online_meeting_url}`)
    }

    goRoom() {
        gtagClick(`イベント詳細 調整ページへ`, `${this.ev.parent_room_public_id}`)
    }

    dateFormat() {
        if (!this.ev.start_time) return

        return CalendarUtil.toJpFormat(this.ev.start_time, this.ev.duration)
    }

    // 文中にあるURLを分割して抽出します。
    get autoLink(): string[] {
        if (!this.ev) return
        if (!this.ev.description) return

        let processText = this.ev.description

        let match
        let matchObj = {}
        let count = 0

        while ((match = this.regex.exec(processText)) != null) {
            count += 1
            matchObj[`[${count}]`] = match[0]
            processText = processText.replace(match[0], `[${count}]`)
        }

        const keys = Object.keys(matchObj)

        keys.forEach(key => {
            processText = processText.replace(key, `,${matchObj[key]},`)
        })
        Logger(`count: ${count}, processText: ${processText}`)
        return processText.split(",")
    }

    clickInnerDescription(text: string) {
        gtagClick(`イベント詳細 説明内リンク`, `${text}`)
    }
}
