
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import { DateTime, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {},
})
export default class MailPreviewPartsOnlineTable extends Vue {
    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    rcm = RoomCreateManager
    DateTime = DateTime

    Util = Util

    startTime() {
        let dt = DateTime.local().toFormat(`HH:mm`)
        return dt
    }

    endTime() {
        let dt = DateTime.local().plus({ minutes: this.rcm.appo.duration }).toFormat(`HH:mm`)
        return dt
    }
}
