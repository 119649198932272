
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagEvent, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import { DateTime } from "luxon"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import OneMagSmallContent from "packs/pages/link/parts/available_schedule/OneMagSmallContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import ScheduleSlideContent from "packs/pages/link/ScheduleSlideContent.vue"
import ConnectedCalendar from "packs/pages/link/parts/available_schedule/ConnectedCalendar.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SearchCalendarsContent from "packs/pages/link/parts/available_schedule/SearchCalendarsContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import LocationSelectContent from "packs/components/calendar/LocationSelectContent.vue"
import MeetingAttendeesGroupAssignContent from "packs/pages/schedule/parts/MeetingAttendeesGroupAssignContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"
import Appointment from "packs/models/Appointment"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesConditions from "packs/models/PossibleDatesConditions"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        UserFileImage,
        OneMagSmallContent,
        CheckboxTag,
        ScheduleSlideContent,
        ConnectedCalendar,
        TextareaTag,
        SearchCalendarsContent,
        MdTextfieldTag,
        LocationSelectContent,
        MeetingAttendeesGroupAssignContent,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class ChangeMeetingAttendeesGroupModal extends Vue {
    room: Room = null
    appo: Appointment = null
    mags: MeetingAttendeesGroup[] = null
    astag: AvailableScheduleTag = null

    // 選択しているMAG
    selectedMag: MeetingAttendeesGroup = null

    // もともと割り当てられていたMAG
    beforeSelectedMag: MeetingAttendeesGroup = null

    // 外部カレンダーもしくはメールアドレスから割り当てた場合にこちらにセットして送ります.
    tmpMagFromCals: MeetingAttendeesGroup = null
    tmpMagFromMail: MeetingAttendeesGroup = null

    // data
    rm = RoomManager

    selectedTabName = `astag` // astag, ctags, emails
    notifyMembers = true
    magDisplayType = null

    pdc = PossibleDatesConditions
    cm = CalendarManager
    gettingPds = false
    step = 0
    typedMessage = ""
    members = []
    currentMember = null
    searchedMems = []
    searchText = ``

    loading = false
    Util = Util

    locationName = null
    currentOnlineAccounts = null
    useCurrentUrl = false

    @Ref()
    SearchCalendarsContent

    // currentRoom: Room = null

    openModal() {
        gtagPage(`#/担当者グループ変更モーダル`)
        this.step = 0
        this.useCurrentUrl = false
        this.resetTmpMags()
        this.updateMags()
    }

    hide() {
        this.$vfm.close(`ChangeMeetingAttendeesGroupModal`)
    }

    resetTmpMags() {
        this.tmpMagFromCals = MeetingAttendeesGroup.createDefault()
        this.tmpMagFromMail = MeetingAttendeesGroup.createDefault()
        this.tmpMagFromCals.purpose_type = `temporary`
        this.tmpMagFromMail.purpose_type = `temporary`
    }

    updateMags() {
        if (!this.rm.currentRoom) return
        let room = { ...this.rm.currentRoom }
        this.room = room
        this.appo = this.room.current_appointment
        this.magDisplayType = this.room.room_setting.mag_display_type
        if (this.appo.status != 10) return
        let selectedMag = this.appo.selected_mag_dic[this.room.owner.user_id]
        Logger(`ChangeMeetingAttendeesGroupModal#updateMags ${selectedMag ? selectedMag.name : null}`)
        if (Util.isBlank(selectedMag)) return

        this.beforeSelectedMag = selectedMag
    }

    public clickNextStep() {
        this.step = 1
    }

    get canGoNextPage() {
        Logger(`canGoNextPage selectedTabName:${this.selectedTabName}`)
        if (this.selectedTabName == `astag`) {
            if (!this.selectedMag) return false
            if (this.beforeSelectedMag && this.beforeSelectedMag.id == this.selectedMag.id) return false
            return true
        } else if (this.selectedTabName == `ctags`) {
            let atts = this.tmpMagFromCals.required_attendees
            if (Util.isBlank(atts)) return false
            let writable = atts.find(att => att.writable)
            if (writable) return true
        } else if (this.selectedTabName == `emails`) {
            let atts = this.tmpMagFromMail.registrable_attendees
            if (Util.isPresent(atts)) return true
        }

        return false
    }

    clickPreStep() {
        this.step = 0
        this.selectedMag = null
        this.pdc.selectedMag = null
    }

    selectMag(mag: MeetingAttendeesGroup) {
        Logger(`${funcName()} mag.name:${mag ? mag.name : null}`)
        this.selectedMag = mag
    }

    selectCategoryTab(tabName: string) {
        this.selectedTabName = tabName
    }

    updateTmpMag(mag: MeetingAttendeesGroup) {
        Logger(`${funcName()} selectedTabName:${this.selectedTabName}`)
        if (this.selectedTabName == `emails`) {
            this.tmpMagFromMail = mag
        } else if (this.selectedTabName == `ctags`) {
            this.tmpMagFromCals.required_attendees = this.cm.edittingAttendees
        }
    }

    save() {
        if (this.loading) return
        this.loading = true
        let selectedMag = this.selectedMag
        if (this.selectedTabName == `ctags`) {
            selectedMag = this.tmpMagFromCals
        } else if (this.selectedTabName == `emails`) {
            selectedMag = this.tmpMagFromMail
        }

        MeetingAttendeesGroup.changeAssignedMag(
            this.room,
            selectedMag,
            this.notifyMembers,
            this.typedMessage,
            this.locationName,
            this.currentOnlineAccounts,
            this.useCurrentUrl
        ).then(dic => {
            if (dic) {
                let room = { ...this.room }
                let appo = dic.appo
                room.current_appointment = appo
                this.rm.updateCurrentInfo(room)
                gtagEvent(`担当者変更完了`, `tab:${this.selectedTabName}`, `${this.selectedTabName}`)
                this.hide()
            }
            this.loading = false
        })
    }

    cancel() {
        this.hide()
    }

    clickNotifyMembers() {
        if (this.notifyMembers) {
            this.notifyMembers = false
        } else {
            this.notifyMembers = true
        }
    }

    public inputMessage(e) {
        // Logger(e);
        let count = (e.match(/\n/g) || []).length
        // Logger(`改行数: ${count}`);
        this.typedMessage = e
    }

    get getLocationName() {
        if (!this.room) return ``

        let room = this.room
        let _appo = room.current_appointment
        if (Util.isBlank(_appo)) return ""

        if (Util.isPresent(this.locationName)) {
            return this.locationName
        }

        if (_appo.selected_location) {
            return _appo.selected_location.keyword
        }

        return ``
    }

    updateLocation(locationName: string, onlineAccs: CalendarTag[]) {
        Logger(`${funcName()} location:${location}, onlineAccs:${Util.output(onlineAccs)}`)
        this.locationName = locationName
        this.currentOnlineAccounts = onlineAccs
    }

    clickUseCurrentUrl() {
        if (this.useCurrentUrl) {
            this.useCurrentUrl = false
        } else {
            this.useCurrentUrl = true
        }
    }
}
