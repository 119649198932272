
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import HeaderControl from "packs/utils/HeaderControl"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import ModalRoomMembersLink from "packs/pages/link/modals/ModalRoomMembersLink.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import CreateFromDraftModal from "packs/pages/link/modals/CreateFromDraftModal.vue"
import GoAvailableScheduleModal from "packs/pages/link/modals/GoAvailableScheduleModal.vue"
import PublicLinkModal from "packs/pages/link/modals/PublicLinkModal.vue"
import ChooseRoomTypeModal from "packs/pages/link/parts/room_settings/ChooseRoomTypeModal.vue"
import ModalCancelAppointment from "packs/pages/link/modals/ModalCancelAppointment.vue"
import CreateFeedbackModal from "packs/pages/link/parts/room_summary/CreateFeedbackModal.vue"
import ConnectCalendarModal from "packs/pages/link/modals/ConnectCalendarModal.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AnsweredFormModal from "packs/pages/link/parts/room_summary/AnsweredFormModal.vue"
import PrivateMemoModal from "packs/pages/link/modals/PrivateMemoModal.vue"
import OneInstantRoom from "packs/pages/link/parts/instant_room_management/OneInstantRoom.vue"
import OneEventRoomContent from "packs/pages/link/parts/instant_room_management/OneEventRoomContent.vue"
import InstantRoomAllSwitcher from "packs/pages/link/parts/instant_room_management/InstantRoomAllSwitcher.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import SubscriptionManager from "packs/models/SubscriptionManager"
import Appointment from "packs/models/Appointment"
import Const from "packs/utils/Const"
import RoomTab from "packs/models/RoomTab"
import SearchedRoomsInfo from "packs/models/SearchedRoomsInfo"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarUtil from "packs/utils/CalendarUtil"
import InstantRoomAssignedUser from "packs/models/InstantRoomAssignedUser"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        RoomsLoader,
        ModalRoomMembersLink,
        TextfieldTag,
        AlertBadge,
        CreateFromDraftModal,
        GoAvailableScheduleModal,
        PublicLinkModal,
        ChooseRoomTypeModal,
        ModalCancelAppointment,
        CreateFeedbackModal,
        ConnectCalendarModal,
        SelectTag,
        AnsweredFormModal,
        PrivateMemoModal,
        OneInstantRoom,
        OneEventRoomContent,
        InstantRoomAllSwitcher,
        AlertModal,
    },
})
export default class Rooms extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util

    // 選択しているタブ情報
    userFilter: string = null // self / all
    selectedTabName: string = null // all, favorite, active, active_in_progress, fixed, closed, unread, draft

    selectedRoom: Room = null
    modalType: string = null

    // rooms = null //絞り込む前のすべてのルーム.
    currentTabRooms: Room[] = null

    subm = SubscriptionManager
    Room = Room
    RoomTab = RoomTab

    isRedirect = false
    redirectParentRoomId = null

    redirectAppo = null
    members_with_links: RoomMember[] = []
    searchText = ``
    showSearchField = false
    Const = Const

    loading = false
    blockingTabs = false
    systemUpdatedAt = Util.getSec()
    systemUpdatedAtForSchedule = Util.getSec()
    showDraftTab = false
    selectSearchField = false

    SearchedRoomsInfo = SearchedRoomsInfo
    searchCategory = `pubRoomName`

    // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: "", rooms: Room[]}
    searchedDic: SearchedRoomsInfo = null
    // フォーム確認時のメンバー
    formMemDetail: RoomMember = null
    upcomingFixRooms: Room[] = null

    alertDic = null

    getAssignedInstantRoom() {
        InstantRoomAssignedUser.getMyRooms().then(res => {
            let rooms = Room.fetchFromJson(res.rooms, this.rm.userInfo.user_id)
            this.currentTabRooms = rooms
            this.getRooms(`self`, `upcoming_fixed`)
            this.systemUpdatedAt = Util.getSec()
        })
    }

    public mounted() {
        gtagPage(`#/instant_rooms`)
        this.updateRoomTabs()

        if (this.rm.astag) {
            this.getAssignedInstantRoom()
        } else {
            this.rm.getAvailableSchedule().then(e => {
                Logger(`subm in rooms:::: ${Util.output(this.subm)}`)
                this.getAssignedInstantRoom()
            })
        }

        // if (this.rooms) this.updateTitleWithUnreadNum()
    }

    getTodaysEvents(rooms: Room[]) {
        if (!rooms) return []
        let today = DateTime.local().toISODate()
        let todaysRooms = []
        for (let room of rooms) {
            // let appo = room.current_appointment
            if (!Appointment.isFix(room)) continue

            let appo = room.current_appointment
            let et = DateTime.fromSeconds(appo.end_time)
            if (et.toISODate() == today) {
                todaysRooms.push(room)
            }
        }
        return todaysRooms
    }

    public getRooms(uf: string, tabname: string) {
        Logger(`${funcName()} uf:${uf}, tabname:${tabname}`)
        this.rm.startProgress()
        this.rm.getRooms(uf, tabname).then(val => {
            this.blockingTabs = false
            this.rm.endProgress()
            if (!val) {
                let tab = this.rm.roomTabs[`self`][`upcoming_fixed`]
                if (tab && tab.rooms) {
                    this.upcomingFixRooms = this.getTodaysEvents(tab.rooms)
                }
                return
            }

            let rooms = val.rooms as Room[]
            const tab = val.tab as RoomTab
            Logger(`${funcName()} はめます: ${rooms.length} ${tab.tab_name}`)

            rooms = RoomTab.sortRoomsWithTabName(rooms, tabname)

            this.upcomingFixRooms = this.getTodaysEvents(rooms)
        })
    }

    @Watch(`rm.roomTabs`, { deep: true })
    updateRoomTabs() {
        Logger(`${funcName()} in InstantRoomManagement.`)
        if (!this.rm.roomTabs) return
        let tab = this.rm.roomTabs[`self`][`upcoming_fixed`]
        if (!tab || Util.isBlank(tab.rooms)) return

        let rooms = RoomTab.sortRoomsWithTabName(tab.rooms, `upcoming_fixed`)
        this.upcomingFixRooms = this.getTodaysEvents(rooms)
        this.systemUpdatedAtForSchedule = Util.getSec()

        // スイッチャーオンオフも確認しにいきます.
        this.getAssignedInstantRoom()
    }

    public updateDataFromRails(data) {
        window.history.pushState({}, document.title, "/link#/")
        Logger(`railsから受信しました: ${Util.output(data)}`)
        if (!data) return
        if (data.parent && data.rid) {
            let type = data.type
            if (type == `fix`) {
                // 一発確定するためのアポを保存しておきます。
                let appo = Appointment.createDefault()
                appo.id = `fix`
                appo.start_time = data.start_time
                this.redirectAppo = appo
            }
            const parentRoomId = data.parent
            if (parentRoomId && parentRoomId.length > 0) {
                this.isRedirect = true
                this.redirectParentRoomId = parentRoomId
            }
        }
    }

    resetStorage() {
        Logger(`resetStorage::Rooms`)
        roomStorage.save(null)
        roomStorage.saveAction(null)
        roomStorage.saveAppointment(null)
        roomStorage.deleteRoomInfo()
    }

    public goSummary(room: Room) {
        this.rm.updateCurrentInfo(room)
        if (!room.is_owner_group) return
        roomStorage.save(room)
        roomStorage.saveAppointment(room.current_appointment)
        roomStorage.saveSelectedRoomKey(room.keyId)
        if (room.room_type == `public_room`) {
            this.$router.push("/pub_summary")
        } else {
            this.$router.push("/summary")
        }
    }

    public showMembersLinks(members, room) {
        this.members_with_links = []
        this.members_with_links = members
        this.selectedRoom = room
        this.$vfm.open("RoomMembersLinkModal")
    }

    public showPublicLink(room: Room) {
        this.selectedRoom = room
        this.$vfm.open("PublicLinkModal")
    }

    public goRoomSettings() {
        Logger(`goRoomSettings`)
        this.$router.push("/room_settings")
    }

    public createGroup() {
        Logger(`createGroup`)
        this.rm.resetCurrentInfo()
        if (this.rm.astag.id != `newId`) {
            gtagClick("調整ページ作成", `一覧 → 新規作成`)

            this.resetStorage()
            this.$vfm.open(`ChooseRoomTypeModal`)
        } else {
            // 先にastagを作成
            gtagClick("調整ページ作成 Astag未作成", `一覧 → AstagView`)
            // this.notice.message = `調整ページを作成するためには、調整カレンダーの作成を先にしてください。`
            // this.$router.push("/available_schedule")
            this.$vfm.open("GoAvailableScheduleModal")
            // roomStorage.saveAction("new")
            // this.goRoomSettings()
        }
    }

    /**
     * セルをクリックしたら調整ページに遷移.
     */
    public goRoomFromTr(room: Room) {
        Logger("ごールーム")
        if (room.room_type == `public_room`) {
            gtagClick(`公開ページへ`, `[${room.appeal_content ? room.appeal_content.title : ``}] pubid:${room.id}`)
            open(`${room.page_url}`, `_blank`)
        } else {
            gtagClick(`限定公開ページへ`, `${room.members.length}人 [${room.title}] rid:${room.room_id}`)
            this.goRoom(room)
        }

        // PCの場合はサマリーに遷移.
        // this.goSummary(room);
    }

    public clickSummary(room: Room, tabName: string = null) {
        Logger("ごーサマリー")
        gtagClick("セルから進捗確認", `進捗管理 ${room.members.length}人 ${room.room_type} rid:${room.id} ${tabName}`)
        if (room.room_type == `public_room`) {
            if (Util.isPresent(tabName)) {
                roomStorage.saveSummaryTab(tabName)
            } else {
                roomStorage.saveSummaryTab(`pv`)
            }
        }

        this.goSummary(room)
    }

    // メッセージページに遷移.
    public goRoom(room) {
        this.rm.updateCurrentInfo(room)
        roomStorage.saveSelectedRoomKey(room.keyId)
        roomStorage.saveSelectedParentRoomId(room.id)
        // fix用のアポがある場合保存しておきます。
        if (this.redirectAppo) {
            let appo: Appointment = { ...this.rm.currentAppointment }
            if (appo.status != 10) {
                appo.start_time = this.redirectAppo.start_time
                appo.localId = `fix`
            }
            this.rm.currentAppointment = appo
            this.rm.currentRoom.current_appointment = appo
            // roomStorage.saveAppointment(this.redirectAppo);
        }
        // this.$router.push("/messages");
        this.$router.push("/schedule")
    }

    /**
     * 調整ページを作成/削除/一括作成/一括削除するためのモーダルを表示します。
     * room [Room] コントロール対象のルーム.
     * type [string] create, delete, createAll, deleteAll
     */
    public showRoomModal(room: Room, type: string) {
        this.selectedRoom = room
        this.modalType = type
        this.$vfm.open("CreateFromDraftModal")
    }

    /**
     * 下書きモーダルを閉じる際に、成功していたら、現在のタブにその情報をはめます。
     */
    public closeDraftModal(success: boolean, room: Room) {
        if (!success) return
        Logger(`closeDraftModal: type: ${this.modalType} ${Util.output(room)}`)

        if (this.modalType == `create`) {
            // currentTabに登録
            this.rm.setNewRoom(room)
        } else if (this.modalType == `createAll`) {
            // すべて非表示.
            this.deleteFromDraftTab(true)
            this.systemUpdatedAt = Util.getSec()

            // this.draftTab.rooms = this.draftTab.rooms.filter(r => !r.is_valid)
        } else if (this.modalType == `delete`) {
            if (this.selectedRoom.room_type == `public_room`) {
                this.rm.deleteFromPublicTab(room)
            } else if (Room.isParentDraft(this.selectedRoom.status)) {
                // 下書き削除.
                // this.rm.deleteFromDraftTab(room)
                let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => r.id != room.id)
                let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => r.id != room.id)
            } else {
                // currentTabの当該ルームを終了に変更.
            }
            this.systemUpdatedAt = Util.getSec()
        } else if (this.modalType == `deleteAll`) {
            //すべて非表示
            this.deleteFromDraftTab(false)
        }
    }

    deleteFromDraftTab(onlyValid: boolean) {
        if (!onlyValid) {
            let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
            tab.rooms = []
            let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
            alltab.rooms = []
        } else {
            if (this.userFilter == `self`) {
                let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => !r.is_valid)
                let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => r.owner.user_id != this.rm.userInfo.user_id)
            } else {
                let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => !r.is_valid)
                let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => !r.is_valid)
            }
        }
    }

    createAllDrafts() {
        this.showRoomModal(null, `createAll`)
    }

    deleteAllDrafts() {
        this.showRoomModal(null, `deleteAll`)
    }

    cancelAppo(room: Room) {
        this.selectedRoom = room
        this.rm.updateCurrentInfo(room)
        this.$vfm.open(`CancelAppointmentModal`)
    }

    canceledRoom(_appo: Appointment) {
        if (!this.rm.currentRoom || Util.isBlank(_appo)) return
    }

    rescheduledRoom(appo: Appointment) {
        this.canceledRoom(appo)
    }

    cancelVoted(room: Room) {
        this.selectedRoom = room
        this.$vfm.open(`CancelAppointmentModal`)
    }

    changeActive(room: Room, is_active: boolean) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        InstantRoomAssignedUser.willChangeStatus(`each`, room.id, is_active).then(res => {
            this.rm.endProgress()
            Logger(`res:${Util.output(res)}`)
            this.loading = false
            let assigned_users = res.assigned_users || ([] as InstantRoomAssignedUser[])
            if (Util.isPresent(assigned_users)) {
                let rooms = [...(this.currentTabRooms || [])]
                for (let asu of assigned_users) {
                    let lau = InstantRoomAssignedUser.findFromRooms(rooms, asu.line_instant_room_assigned_user_id)
                    if (Util.isPresent(lau)) {
                        lau.is_active = asu.is_active
                    }
                }
                this.systemUpdatedAt = Util.getSec()
            }
        })
    }

    showAllContent() {
        if (Util.isBlank(this.rm.userInfo)) return
        if (UserPermissionsOption.permitDisplay(this.rm.userInfo, `rooms`, `rooms_all_tab`)) {
            return true
        } else {
            return false
        }
    }

    selectSearchType(type: string) {
        Logger(`${funcName()} type:${type}`)
        Object.keys(SearchedRoomsInfo.categoryDic).forEach(_key => {
            let val: string = SearchedRoomsInfo.categoryDic[_key]
            if (type == val) {
                this.searchCategory = _key
            }
        })
    }

    clickFormMember(mem: RoomMember, room: Room) {
        Logger(`${funcName()} `)
        this.formMemDetail = mem
        this.selectedRoom = room
        this.$nextTick(() => {
            this.$vfm.open(`AnsweredFormModal`)
        })
    }

    switchAll(status: boolean) {
        Logger(`${funcName()} status:${status}`)
        let dic = {
            title: `すべてを${status ? `ON` : `OFF`}にする`,
            text: `すべてのスグINボタンを変更します。`,
            okButtonName: `変更`,
            cancelButtonName: `キャンセル`,
            retValue: status,
        }
        this.alertDic = dic
        this.$vfm.open(`AlertModal`)
    }

    clickOK(switchStatus: boolean) {
        Logger(`${funcName()} switchStatus:${switchStatus ? `ONにします` : `OFFにします`}`)
        if (this.loading) return
        this.loading = true
        let switchType = `all`

        InstantRoomAssignedUser.willChangeStatus(switchType, null, switchStatus).then(res => {
            Logger(`res:${Util.output(res)}`)
            this.loading = false
            this.$vfm.close(`AlertModal`)
            this.alertDic = null
            let assigned_users = res.assigned_users || ([] as InstantRoomAssignedUser[])
            if (Util.isPresent(assigned_users)) {
                let rooms = [...(this.currentTabRooms || [])]
                for (let asu of assigned_users) {
                    let lau = InstantRoomAssignedUser.findFromRooms(rooms, asu.line_instant_room_assigned_user_id)
                    if (Util.isPresent(lau)) {
                        lau.is_active = asu.is_active
                    }
                }
                this.systemUpdatedAt = Util.getSec()
            }
        })
    }

    clickCancel() {
        Logger(`${funcName()}`)
        this.$vfm.close(`AlertModal`)
        this.alertDic = null
    }
}
