
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import SpanSlotContent from "packs/components/common/SpanSlotContent.vue"
import ZoomSelectContent from "packs/components/calendar/ZoomSelectContent.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import IconGear from "packs/components/icons/IconGear.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomManager from "packs/models/RoomManager"
import RoomSetting from "packs/models/RoomSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import SelectUtil from "packs/utils/SelectUtil"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: {
        MdTextfieldTag,
        CheckboxTag,
        SelectTag,
        SpanSlotContent,
        ZoomSelectContent,
        IconHelp,
        IconGear,
    },
})
export default class EditableZoomSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    @Prop() // public_room / parent_room
    from: string

    // data
    isSP = isMobile(window.navigator).phone

    loading = false
    rcm = RoomCreateManager
    rm = RoomManager
    cm = CalendarManager

    Util = Util
    CalendarUtil = CalendarUtil
    SelectUtil = SelectUtil

    showDetail = false
    selectedZooms: CalendarTag[] = null
    unselectedZooms: CalendarTag[] = null
    zoomLoctags: CalendarTag[] = null

    created() {}

    mounted() {
        this.cm.getOnlineLocations().then(_loctags => {
            this.zoomLoctags = _loctags
            this.updateZooms()
        })
    }

    get zoomSettingDefaultPasscodeType() {
        let rs = this.rcm.room_setting
        Logger(`${funcName()} zs:${Util.output(rs.zoom_setting)}`)

        if (!rs.zoom_setting) return `6桁の数字`

        if (rs.zoom_setting.passcode_type == `secure`) {
            return `セキュアな設定`
        }
        return `6桁の数字`
    }

    selectZoomSettingPasscodeType(e) {
        Logger(`${funcName()} select:${e}`)
        if (!this.rcm.room_setting.zoom_setting) {
            this.rcm.room_setting.zoom_setting = RoomSetting.createZoomSetting(null)
        }
        let dic = SelectUtil.zoomPasscodeTypes.find(dic => dic.name == e)
        if (dic) {
            this.rcm.room_setting.zoom_setting.passcode_type = dic.type
        }
    }

    get availableZoomSettingJoinBeforeHost() {
        let rs = this.rcm.room_setting
        Logger(`${funcName()} zs: ${Util.output(rs.zoom_setting)}`)

        if (!rs.zoom_setting) return `参加者の入室を許可する`

        if (rs.zoom_setting.join_before_host) {
            return `参加者の入室を許可する`
        }
        return `参加者の入室を許可しない`
    }

    selectAvailableZoomSettingJoinBeforeHost(e) {
        Logger(`${funcName()} select:${e}`)
        if (!this.rcm.room_setting.zoom_setting) {
            this.rcm.room_setting.zoom_setting = RoomSetting.createZoomSetting(null)
        }
        let dic = SelectUtil.availableZoomSettingJoinBeforeHost.find(span => span.name == e)
        if (dic) {
            this.rcm.room_setting.zoom_setting.join_before_host = dic.type == `permit`
        }
    }

    updateAvailableFix(mins: number) {
        Logger(`updateAvailableFix: mins: ${mins}`)
        if (mins == 0) mins = null

        this.rcm.room_setting.available_fix_min_before_appo_start_time = mins
    }

    updateZooms() {
        Logger(`updateZooms: zooms: ${this.zoomLoctags}`)

        let onlines = [...(this.rcm.room_setting.online_meeting_resources || [])]
        Logger(`onlines: ${Util.output(onlines)}`)
        let zoomSearchKeys = onlines.map(z => z.search_key)
        // let selectedKeys = zoomSearchKeys.map(z => z.search_k)

        let unzooms = []
        for (let _zoom of this.zoomLoctags) {
            if (zoomSearchKeys.includes(_zoom.search_key)) {
            } else {
                unzooms.push(_zoom)
            }
        }
        this.selectedZooms = onlines
        this.unselectedZooms = unzooms
    }

    updateZoomAccounts(selectedZooms: CalendarTag[], unselectedZooms: CalendarTag[]) {
        Logger(`ConnectZoomModal:updateZoomAccounts: selectedZooms: ${Util.output(selectedZooms)}`)
        this.selectedZooms = selectedZooms
        this.unselectedZooms = unselectedZooms
        this.rcm.room_setting.online_meeting_resources = selectedZooms
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    showDetailContent() {
        if (this.showDetail) {
            this.showDetail = false
        } else {
            this.showDetail = true
        }
    }
}
