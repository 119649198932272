
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import { Dropdown } from "uiv"

// モデルを読込.
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import Room from "packs/models/Room"

@Options({
    components: { Dropdown },
})
export default class PublicRoomStatusMenu extends Vue {
    @Prop()
    room: Room

    cm = CalendarManager
    isSP = isMobile(window.navigator).phone

    public mounted() {}

    public openRoom() {
        Logger(`公開: ${this.room.public_status}`)
        this.$emit(`changeStatus`, `publishing`)
    }

    public suspendRoom() {
        Logger(`公開: ${this.room.public_status}`)
        this.$emit(`changeStatus`, `suspended`)
    }

    public deleteRoom() {
        this.$emit(`changeStatus`, `delete`)
    }
}
