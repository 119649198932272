import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import Plan from "./Plan"
export default class Invoice {
    constructor(
        public paid: boolean,
        public required_change_payment_method: boolean,
        public total: number,
        public subtotal: number, // 税引き
        public tax: number,
        public hosted_invoice_url: string, //stripeにある請求書の発行URL
        public period_start: number,
        public period_end: number,
        public paid_at: number,
        public coupon_name: string,

        public paid_at_format: string,
        public format: string
    ) {}

    static fetchFromJson(invoicesJson: any): Invoice[] {
        let invoices = []
        // const arrOfDateTag = JSON.parse(invoicesJson)
        // Logger(`arrOfDateTag: ${invoicesJson}`)
        for (const inv of invoicesJson) {
            let _inv: Invoice = inv
            if (inv.paid_at) {
                _inv.paid_at_format = DateTime.fromSeconds(inv.paid_at).toFormat(`yyyy年MM月dd日`)
            }
            if (_inv.period_start && _inv.period_end) {
                _inv.format = Plan.setFormat(_inv.period_start, _inv.period_end)
            }

            invoices.push(_inv)
        }
        return invoices
    }
}
