
import { Options, Prop, Vue, Emit } from "vue-property-decorator"
import { Logger } from "packs/common"

@Options({
    components: {},
})
export default class RangefieldTag extends Vue {
    @Prop()
    options //{id: string, name: string}を指定.
    @Prop()
    defaultId: string
    @Prop()
    optionName: string

    @Emit()
    select(val) {}

    public selectId(e) {
        // Logger(`update value has called. ${e.target.value}`);
        const op = this.options[e.target.value]
        $(`label#${this.optionName}`).text(` ${op.name}`)
        this.select(op)
    }
}
