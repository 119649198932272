
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AccountHeader from "./AccountHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CustomNotificationContent from "packs/pages/link/settings/CustomNotificationContent.vue"
import ZapierNotificationContent from "packs/pages/link/settings/ZapierNotificationContent.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import WaaqApiKeysContent from "packs/pages/link/settings/WaaqApiKeysContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import NotificationControl from "packs/utils/NotificationControl"
import CustomNotification from "packs/models/CustomNotification"
import ZapierNotification from "packs/models/ZapierNotification"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        AccountHeader,
        MdTextfieldTag,
        CustomNotificationContent,
        ZapierNotificationContent,
        OptionModal,
        WaaqApiKeysContent,
    },
})
export default class AccountSettingsNotifications extends Vue {
    // data:
    sharedState = PropertyStore
    notice = Notice

    rm = RoomManager
    Util = Util
    selectedTab = "notifications"

    isSP = isMobile(window.navigator).phone
    users: RoomMember[] = []

    invitedUserEmail = ""
    invitedUserRole = 1
    subm = SubscriptionManager
    failedMail = false // Emailが正しいか.
    sending = false

    namespace = ``
    validName = true
    saving = false
    ngReasons: string[] = null
    loading = false

    notifications: { [key: string]: any } = null
    webhookDic: CustomNotification = null
    teamsDic: CustomNotification = null
    slackDic: CustomNotification = null
    zapierDic: ZapierNotification = null

    availableEvents: any[] = null // {type: string, name: string}
    currentUg = null
    beforeGAId = null
    optionEventName = ``
    canUseGA = false

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkAdmin()
                this.getNotifications()
                if (Util.isPresent(this.rm.user_groups)) {
                    let ug = this.rm.user_groups.find(ug => ug.id == this.rm.userInfo.user_group_id)
                    this.setCurrentUg(ug)
                }
                // this.subm.getSubscriptionData().then(e => {});
            })
        } else {
            // this.subm.getSubscriptionData().then(e => {});
            this.checkAdmin()
            this.getNotifications()
            if (Util.isPresent(this.rm.user_groups)) {
                let ug = this.rm.user_groups.find(ug => ug.id == this.rm.userInfo.user_group_id)
                this.setCurrentUg(ug)
            }
            // this.namespace = this.rm.namespace
        }
    }

    public setCurrentUg(ug) {
        if (!ug) return
        this.currentUg = ug
        this.beforeGAId = ug.google_analytics_id
    }

    public getNotifications() {
        if (Util.isPresent(this.notifications)) return
        CustomNotification.get(`user_group`).then(dic => {
            this.notifications = dic.notification_dic
            this.webhookDic = this.notifications.webhook || CustomNotification.createDefault(`user_group`, `webhook`)
            this.slackDic = this.notifications.slack || CustomNotification.createDefault(`user_group`, `slack`)
            this.teamsDic = this.notifications.teams || CustomNotification.createDefault(`user_group`, `teams`)
            this.zapierDic = this.notifications.zapier || ZapierNotification.createDefault(`user_group`)
            this.availableEvents = dic.available_events
        })
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/account/notifications")
    }

    clickGAId() {
        if (!this.rm.canUseOption(`management_google_analytics`, false)) {
            this.showOptionModal(`管理画面GoogleAnalytics 課金リクエスト`)
        }
    }

    inputGAId(e) {
        this.currentUg.user_group_setting.google_analytics_id = e
    }

    deleteGAId() {
        this.currentUg.user_group_setting.google_analytics_id = null
        if (Util.isPresent(this.beforeGAId)) {
            this.saveGAId(true)
        }
    }

    saveGAId(force = false) {
        if (Util.isBlank(this.currentUg.google_analytics_id) && !force) {
            Notice.message = `Googleアナリティクスに記載のIDを入力してください。`
            return
        }

        if (this.loading) return
        this.loading = true

        return axios
            .post(`${Util.prefixUrl}/user_groups/google_analytics`, {
                user_group: this.currentUg,
            })
            .then(res => {
                this.loading = false
                Logger(`saveGAId: ${Util.output(res)}`)
                Notice.message = res.data.message
                if (res.data.google_analytics_id) {
                    this.currentUg.google_analytics_id = res.data.google_analytics_id
                    this.beforeGAId = res.data.google_analytics_id
                } else {
                    this.beforeGAId = null
                }
            })
            .catch(err => {
                this.loading = false
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    public updateNotification(noti: any, provider: string) {
        if (provider == `webhook`) {
            this.webhookDic = noti
        } else if (provider == `slack`) {
            this.slackDic = noti
        } else if (provider == `teams`) {
            this.teamsDic = noti
        } else if (provider == `zapier`) {
            this.zapierDic = noti
        }
    }

    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (Util.isBlank(this.rm.userInfo)) return

        this.canUseGA = this.rm.canUseOption(`management_google_analytics`, false)

        const uf = this.rm.userInfo
        const cate = `account_settings`

        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`)) {
            // 企業設定に飛ばす
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`)) {
            // このページ.
            this.$router.push("/account_settings")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            // アカウント.
            this.$router.push("/account")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)) {
            // セキュリティに飛ばす
            this.$router.push("/account_settings/security")
            return
        }
        this.$router.push("/")
        // location.href = "https://google.com";
    }

    showOptionModal(opName: string) {
        this.optionEventName = opName
        this.$vfm.open(`OptionModal`)
    }

    clickOK() {
        gtagClick(`${this.optionEventName}`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.$vfm.close(`OptionModal`)
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.optionEventName} キャンセル`, ``)
        this.$vfm.close(`OptionModal`)
    }
}
