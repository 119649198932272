
// モジュールを読込.
import { Options, Prop, Vue, Provide, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import AvailableScheduleSettings from "packs/pages/link/parts/available_schedule/AvailableScheduleSettings.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import Notice from "packs/models/Notice"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import GroupTag from "packs/models/GroupTag"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        ChipButton,
        Switcher,
        AvailableScheduleSettings,
        IconHelp,
        VueFinalModal,
    },
})
export default class EditGroupTagModal extends Vue {
    // @Prop()
    // ctag: CalendarTag;

    @Prop() // func
    editGrtag: GroupTag

    grtag: GroupTag = null

    isSP = isMobile(window.navigator).phone
    cm = CalendarManager
    ctag: CalendarTag = null

    Const = Const
    Util = Util

    public openModal() {
        this.grtag = { ...this.editGrtag }
    }

    public closeModal() {
        this.dismissModal()
    }

    inputPinnedIndexNum() {}

    get getTitle() {
        return `グループタグ設定`
    }

    get getDescription() {
        return `利用するグループタグ(${this.grtag?.name})を細かく設定できます。`
    }

    get isActive() {
        if (this.cm.edittingAstag) {
            return true
        }

        if (!this.cm.edittingCtag) return true

        if (this.ctag.is_active_meeting_attendee_option) {
            return true
        }
        return false
    }

    public dismissModal() {
        this.$vfm.close("EditGroupTagModal")
    }

    public saveGrtag() {
        Logger(`${funcName()} 保存します: ${Util.output(this.grtag)}`)
        GroupTag.upsertGroupTag(this.grtag).then(grtag => {
            this.$vfm.close(`EditGroupTagModal`)
            this.$emit(`editEnd`, grtag)
        })
    }

    changeShowType() {
        if (this.grtag.show_type === `pinned_all`) {
            this.grtag.show_type = `normal`
        } else {
            this.grtag.show_type = `pinned_all`
            this.grtag.pinned_index_num = this.grtag.pinned_index_num || 1
        }
    }
}
