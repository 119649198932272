import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.num && _ctx.num > 0)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "redBg badges arial",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickBudge && _ctx.clickBudge(...args)))
      }, _toDisplayString(_ctx.getText()), 1))
    : _createCommentVNode("", true)
}