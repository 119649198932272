
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"

// モデルを読込.
import RoomMember from "packs/models/RoomMember"
import RoomMessage from "packs/models/RoomMessage"
import Util from "packs/utils/Util"
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import UserFile from "packs/models/UserFile"
// import RoomHistory from "packs/models/RoomHistory"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"

@Options({ components: { UserIcon, UserFileImage } })
export default class OneRoomHistoryContent extends Vue {
    // data:
    @Prop()
    hist: RoomHistoryDetail

    @Prop()
    room: Room

    Util = Util
    RoomHistoryDetail = RoomHistoryDetail

    readCount: number = 0

    createdTime: string = ""
    createdDate: string = ""
    rm = RoomManager
    isMe = false

    currentMem: RoomMember = null
    userFiles: UserFile[] = null

    // regex = /http(:\/\/[-_.!~*¥'()a-zA-Z0-9;\/?:¥@&=+¥$,%#]+)/;
    // regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    public created() {
        this.updateRoom()
    }

    public async mounted() {
        const sec = this.hist.created_at
        let format = `yyyy年MM月dd日 HH:mm`
        if (this.hist.is_system) {
            this.createdTime = Util.getTimeDateFormatFromSeconds(sec, format)
        } else {
            this.createdTime = Util.getTimeDateFormatFromSeconds(sec, format)
        }

        this.createdDate = Util.getTimeDateFormatFromSeconds(sec)
    }

    getMember(): RoomMember {
        if (!this.hist) return null
        if (!this.room) return null
        if (this.hist.is_system) return null

        return this.room.members.find(m => m.user_id == this.hist.user_id)
    }

    @Watch("room", { deep: true })
    updateRoom() {
        this.currentMem = this.getMember()
        // Logger(`Historyの中の: ${Util.output(this.currentMem)}`)
        if (this.rm.userInfo && this.currentMem && this.rm.userInfo.user_id == this.currentMem.user_id) {
            this.isMe = true
            return
        }
        this.isMe = false
    }

    get memberName() {
        // const mem = this.getMember();
        const mem = this.currentMem
        if (mem) return mem.name
        // return this.message.owner.name
        return ``
    }

    get memberCompanyName() {
        // const mem = this.getMember();
        const mem = this.currentMem
        if (mem) return mem.company_name
        return ``
    }

    public hasImage(): boolean {
        if (this.currentMem && this.currentMem.image_url) {
            return true
        }
        return false
    }

    get memberIconPath(): string {
        // const mem = this.getMember();
        const mem = this.currentMem
        const imageUrl = mem ? mem.image_url : null
        return imageUrl
        // const roleName = mem ? mem.role_name : this.message.owner.role_name;
        // return `/assets/icons/usertype_${roleName}`;
    }

    // deletedAttachment(file) {
    //     if (!file) return // 失敗した場合
    //     Logger(`添付ファイルを削除します: ${file.original_filename}`)
    //     this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
    // }

    // showPreview(file: UserFile) {
    //     this.$emit("showPreview", file)
    // }

    showCategoryType(hist: RoomHistoryDetail) {
        let opts = hist.options
        let cate = hist.medium_category
        if ([`integrations_error`].includes(cate)) {
            return true
        }
        return false
    }
}
