
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import SelectUtil from "packs/utils/SelectUtil"
import Const from "packs/utils/Const"
import Notice from "packs/models/Notice"
import TemplateUtil from "packs/utils/TemplateUtil"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import MessageTemplate from "packs/models/MessageTemplate"
import RoomMember from "packs/models/RoomMember"
import VotedRoomManager from "packs/models/VotedRoomManager"
import CalendarTag from "packs/models/CalendarTag"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: {
        TextareaTag,
        ModalTemplates,
        CheckboxTag,
    },
})
export default class FixVotedRoomsMailContent extends Vue {
    // data:

    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    // メールに付随する内容
    typedMessage = ""

    // 調整ページ内のメモに入力しておく内容
    memo = ""

    SelectUtil = SelectUtil

    tmp: MessageTemplate = null
    Const = Const
    fixVoteType = SelectUtil.votedRoomFixTypes[1].type

    attendees: RoomMember[] = null
    notAttendees: RoomMember[] = null
    drag: boolean = false
    systemUpdatedAt: number = Util.getSec()

    // メモとメールを同じ内容のものを入れるか
    sameMailAndMemo = false

    // 場所名.（LocationTagではない.）
    location: string = null
    currentZooms: CalendarTag[] = null
    defaultZoomEmail: string = null
    astag: AvailableScheduleTag = null
    forceType: string = null // filled: 既に埋まっているが強制確定させるか.

    openCustomizeMailContent = false

    public inputMessage(e) {
        this.typedMessage = e
        if (this.sameMailAndMemo) {
            this.memo = e
        }
        this.$emit(`inputMessage`, this.typedMessage, this.memo)
    }

    public inputMemo(e) {
        this.memo = e
        this.$emit(`inputMessage`, this.typedMessage, this.memo)
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        if (Const.started(this.rm)) {
            // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
            this.$vfm.open("ModalTemplates")
        } else {
            this.$vfm.open("AdRegistrationModal")
        }
    }

    public decideTemplate(tmp: MessageTemplate) {
        //
        this.tmp = tmp
        this.updateTmp()
    }

    public updateTmp() {
        // テンプレを最新のユーザー/アポの内容をはめて更新します。
        if (!this.tmp && !this.vrm.publicRoom) return
        let mems = this.vrm.publicRoom.members
        let owner = mems.find(m => m.role == 100) || this.rm.userInfo
        let atts = mems.filter(m => m.user_id != this.rm.userInfo.user_id)
        let str = TemplateUtil.insertTemplateText(
            this.tmp.content,
            atts,
            owner,
            this.vrm.publicRoom.current_appointment,
            this.rm.userInfo
        )
        this.typedMessage = str
        this.$vfm.close("ModalTemplates")
        this.$emit(`inputMessage`, this.typedMessage, this.memo)
    }

    clickSameWithMail() {
        if (this.sameMailAndMemo) {
            this.sameMailAndMemo = false
        } else {
            this.sameMailAndMemo = true
        }
    }

    toggleCustomizeMailContent() {
        if (this.openCustomizeMailContent) {
            this.openCustomizeMailContent = false
        } else {
            this.openCustomizeMailContent = true
        }
    }
}
