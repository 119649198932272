
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import VariableListsContent from "packs/pages/link/parts/room_settings/VariableListsContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomSetting from "packs/models/RoomSetting"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Notice from "packs/models/Notice"

@Options({
    components: {
        TextareaTag,
        TextfieldTag,
        SelectTag,
        LblueListContent,
        VariableListsContent,
    },
})
export default class InstantRoomTextEditContent extends Vue {
    // data:

    rm = RoomManager
    rcm = RoomCreateManager

    isSP = isMobile(window.navigator).phone

    Util = Util
    SelectUtil = SelectUtil
    RoomSetting = RoomSetting
    rs: RoomSetting = null

    public mounted() {
        this.rs = { ...this.rcm.room_setting }
    }

    inputButtonTitle(e) {
        Logger(`${funcName()} text:${e}`)
        if (e.length > 10) {
            Notice.message = `10文字以内で入力してください。`
            return
        }
        this.rs.instant_room_button_title = e
        this.rcm.updateRoomSetting(this.rs)
    }

    inputButtonSmallText(e) {
        Logger(`${funcName()} text:${e}`)
        if (e.length > 15) {
            Notice.message = `15文字以内で入力してください。`
            return
        }
        this.rs.instant_room_button_small_text = e
        this.rcm.updateRoomSetting(this.rs)
    }

    inputDescription(e) {
        Logger(`${funcName()} text:${e}`)
        if (e.length > 50) {
            Notice.message = `50文字以内で入力してください。`
            return
        }
        this.rs.instant_room_description = e
        this.rcm.updateRoomSetting(this.rs)
    }
}
