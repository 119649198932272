
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import ModalInnerAvailableTime from "packs/pages/link/modals/ModalInnerAvailableTime.vue"
import OptionalConnectedCalendar from "packs/pages/link/parts/available_schedule/OptionalConnectedCalendar.vue"
import MeetingSpanContent from "packs/pages/link/parts/available_schedule/MeetingSpanContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"

// const roomStorage = new RoomStorage();
// 管理用カレンダー未設定時の選択肢名
const UNSET_CAL_NAME = "未設定"

@Options({
    components: {
        SelectTag,
        ModalInnerAvailableTime,
        OptionalConnectedCalendar,
        MeetingSpanContent,
    },
})
export default class UserSettingsNotAttendCalendar extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    cm = CalendarManager
    selectedTab = `calendar`

    isSP = isMobile(window.navigator).phone
    showSaveButton = false
    astag: AvailableScheduleTag = null

    Const = Const
    Util = Util

    showNotAttendAstagSaveButton = false
    buttonName = ``
    saving = false

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.buildData()
            })
        } else {
            this.buildData()
        }
    }

    mounted() {
        gtagPage("#/settings/不参加カレンダー")
    }

    public buildData() {
        Logger(`${funcName()} astag:${Util.output(this.astag)}`)
        this.rm.startProgress()
        this.rm.getAstagDetail(`not_attend`).then(res => {
            this.rm.endProgress()
            if (res) {
                this.astag = res

                this.cm.astag = this.astag
                this.astag.can_connect_calendar = false

                if (this.astag && this.astag.id == `newId`) {
                    this.buttonName = `作成`
                } else {
                    this.buttonName = `更新`
                }

                if (!this.rm.astag || this.rm.astag.id == "newId") {
                    this.showNotAttendAstagSaveButton = false
                } else {
                    this.showNotAttendAstagSaveButton = true
                }

                if (this.astag && this.rm.didConnectCal) {
                    Logger(`${funcName()} リモートからカレンダーリストを取得します。`)
                    this.rm.startProgress()
                    this.cm.getCalList().then(res => {
                        this.rm.endProgress()
                    })
                }
            }
        })
    }

    /**
     * 作成した調整カレンダーをリモートに保存します。
     */
    public saveAvailableSchedule() {
        this.rm.startProgress()
        Logger(`will save astag id: ${this.astag.id}`)
        let sendType = "post"
        let parentId = ``
        if (this.astag.id == "newId") {
            sendType = "post"
        } else {
            Logger(`既に存在しているため、アップデートします。`)
            sendType = "put"
        }

        if (this.saving) return
        this.saving = true

        // this.astag.optional_add_schedules = this.optionalAddEvents
        // this.astag.optional_remove_schedules = this.optionalRemoveEvents
        this.astag.can_connect_calendar = false
        AvailableScheduleTag.saveAvailableSchedule(this.astag, sendType, parentId).then(res => {
            this.rm.endProgress()
            this.saving = false

            // 保存に成功したら、ローカルに保存した情報は削除しておきます。
            if (res) {
                // chukaiAstagのアップデートを加えると、テーマtypeが更新されてしまい、カスタム設定として表示されてしまうため、
                // いったんIDのみ更新.
                this.astag.id = res.id
                this.showSaveButton = false
                gtagClick(`不参加カレンダー作成 ${sendType}`, `astagid: ${this.astag.id}[${this.rm.userInfo.email}]`)
            }
        })
    }

    public goAvailableSchedule() {
        gtagClick(`不参加カレンダー→調整カレンダー作成`, ``)
        this.$router.push("/available_schedule")
    }

    public updateAstagFromChild(ast: AvailableScheduleTag) {
        Logger(`子コンポーネント側でデータ変更がありました。`)
        this.astag = ast
    }

    // 選択したカレンダーを設定します。
    public selectCalendar(e) {
        Logger(`管理用カレンダーを更新します。${e}`)
        if (!this.cm.astag) return
        if (e === UNSET_CAL_NAME) {
            this.cm.astag.optional_calendar_tag_search_key = null

            return
        }
        const selectedCal = this.cm.getWritableCalendars().find(cal => cal.name === e)
        if (!selectedCal) {
            this.cm.astag.optional_calendar_tag_search_key = null
        } else {
            this.cm.astag.optional_calendar_tag_search_key = selectedCal.search_key
        }
    }

    public defaultOption() {
        if (!this.cm.astag.optional_calendar_tag_search_key) return UNSET_CAL_NAME
        const selectedCal = this.cm
            .getWritableCalendars()
            .find(cal => cal.search_key === this.cm.astag.optional_calendar_tag_search_key)
        if (!selectedCal) return UNSET_CAL_NAME
        return selectedCal.name
    }

    @Watch("cm.ctList", { deep: true })
    public selectableCalendars() {
        if (Util.isBlank(this.cm.ctList)) return [UNSET_CAL_NAME]

        const selectable_cals = this.cm.getWritableCalendars().map(cal => cal.name)
        selectable_cals.unshift(UNSET_CAL_NAME)
        return selectable_cals
    }
}
