import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tooltip = _resolveComponent("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tooltip, {
      text: "コピーしました!",
      trigger: "manual",
      modelValue: _ctx.showUrlTip,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showUrlTip) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("label", {
          class: _normalizeClass(`${_ctx.styleNone ? `` : `circle_button blueAround`} fw600 fl centered fs11 `),
          style: _normalizeStyle({
                    width: `${_ctx.width || 100}px`,
                    padding: `4px 10px`,
                }),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyPageUrl && _ctx.copyPageUrl(...args)))
        }, _toDisplayString(_ctx.buttonName || _ctx.$t(`schedule.copy_link`)), 7)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}