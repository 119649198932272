
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import ZapierNotification from "packs/models/ZapierNotification"
import Notice from "packs/models/Notice"
import CustomNotification from "packs/models/CustomNotification"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
    },
})
export default class HubspotNotificationContent extends Vue {
    @Prop()
    noti: CustomNotification

    @Prop() // user, user_group, room_setting
    scope: string

    @Prop() // func
    showOptionModal

    // data:

    rm = RoomManager
    Util = Util
    notice = Notice

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: CustomNotification = null
    showTip = false
    optionEventName = `管理画面Zapier 課金リクエスト`
    connectableNotis: CustomNotification[] = null
    availableEvents = null
    notificationSettings = null
    selectingEventDic = null
    registerType = `hs_meetings`
    CustomNotification = CustomNotification
    defaultEventSettingDic = { register_type: this.registerType, pipeline_name: ``, dealstage_name: `` }
    showRegisterForm = true

    mounted() {
        Logger(`${funcName()} mounted`)
        this.updateNoti()
    }

    requestHubspot() {
        this.rm.showPlanOptionModalWithName = `管理画面Hubspot 課金リクエスト`
    }

    @Watch(`noti`, { deep: true })
    updateNoti() {
        if (!this.noti) return
        this.notiDic = { ...this.noti }
        // if (!this.connectableNotis) {
        //     CustomNotification.getIntegNotifications(`hubspot`).then(dic => {
        //         Logger(`updateNoti HUBSPOT NOTIS: ${Util.output(dic)}`)
        //         let notis = dic.notifications || []
        //         this.connectableNotis = notis
        //         this.availableEvents = dic.available_events
        //     })
        // }
    }

    goHubspot() {
        Logger(`${funcName()} goHubspot`)
        window.open(`/connect/hubspot`)
    }

    removeHubspot() {
        CustomNotification.delete(`hubspot`, `user`).then(e => {
            Logger(`AFTER DELETE HUBSPOT NOTI: ${Util.output(e)}`)
            this.notiDic = CustomNotification.createDefault(`user`, `hubspot`)
            this.$emit(`updateNotification`, this.notiDic, `hubspot`)
        })
    }

    selectHubspot(e) {
        Logger(`${funcName()} e: ${Util.output(e)}`)
        let noti = this.connectableNotis.find(n => n.settings.provider_account_id == e)
        if (noti) {
            this.notiDic = noti
            this.notificationSettings = { ...this.notiDic.settings }
            this.$emit(`updateNotification`, this.notiDic, `hubspot`)
        }
    }

    connectHubspot() {
        // 公開ページ紐づけ
        if (this.availableEvents) {
            if (this.connectableNotis && this.connectableNotis.length == 1) {
                this.notiDic = this.connectableNotis[0]
                this.notificationSettings = { ...this.notiDic.settings }
                this.$emit(`updateNotification`, this.notiDic, `hubspot`)
            }
        } else {
            CustomNotification.getIntegNotifications(`hubspot`).then(dic => {
                Logger(`HUBSPOT NOTIS: ${Util.output(dic)}`)
                let notis = dic.notifications || []
                this.connectableNotis = notis
                if (notis && notis.length == 1) {
                    this.notiDic = notis[0]
                    this.notificationSettings = { ...this.notiDic.settings }
                    this.$emit(`updateNotification`, this.notiDic, `hubspot`)
                }
                this.availableEvents = dic.available_events
            })
        }
    }

    disconnectHubspot() {
        // 公開ページ紐づけ
        this.notiDic = CustomNotification.createDefault(`room_setting`, `hubspot`)
        this.$emit(`updateNotification`, null, `hubspot`)
    }

    get defaultEvent() {
        let timing = `fixed_appointment`
        let evDic = this.availableEvents.find(evdic => evdic.timing == timing)

        return evDic.name
    }

    selectEvent(name) {
        let evDic = this.availableEvents.find(evdic => evdic.name == name)
        if (evDic) {
            this.selectingEventDic = evDic
            // this.notificationSettings = { ...this.notiDic.settings }
            // this.notificationSettings.event = evDic.timing
            // if (this.notiDic.events)
        }
    }

    addEvent() {
        if (!this.selectingEventDic) return
        let evDic = this.selectingEventDic
        let currentEvents = this.notiDic.events || []
        if (currentEvents.includes(evDic.timing)) {
            Notice.message = `すでに追加済みのイベントです。`
            return
        }

        // 作成するイベントに登録します.
        currentEvents.push(evDic.timing)
        this.notiDic.events = currentEvents

        // 通知時の設定を保存します.
        this.defaultEventSettingDic.register_type = this.registerType
        this.notificationSettings = this.notificationSettings || {}
        this.notificationSettings[evDic.timing] = { ...this.defaultEventSettingDic }
        this.notiDic.settings = { ...this.notificationSettings }
        this.$emit(`updateNotification`, this.notiDic, `hubspot`)

        // 通知時の設定を初期化します.
        this.resetSelecting()
        this.showRegisterForm = false
        sleep(200).then(_ => {
            this.showRegisterForm = true
        })
    }

    resetSelecting() {
        this.selectingEventDic = null
        this.registerType = `hs_meetings`
        this.defaultEventSettingDic = { register_type: this.registerType, pipeline_name: ``, dealstage_name: `` }
    }

    selectRegisterType(name: string) {
        Logger(`${funcName()} name:${name}`)
        let types = CustomNotification.hubspotRegisterTypes()
        let typeDic = types.find(t => t.name == name)
        if (typeDic) {
            this.registerType = typeDic.value
        }
    }

    inputDealstageName(e) {
        this.defaultEventSettingDic.dealstage_name = e
    }

    inputPipelineName(e) {
        this.defaultEventSettingDic.pipeline_name = e
    }

    deleteSettingDic(ev) {
        this.notiDic.events = this.notiDic.events.filter(e => e != ev)
        this.notiDic.settings[ev] = null
        this.$emit(`updateNotification`, this.notiDic, `hubspot`)
    }

    public hideTooltip(e) {
        this.showTip = false
    }
}
