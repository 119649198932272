
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import PPTosContent from "packs/components/forms/PPTosContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import RoomMessage from "packs/models/RoomMessage"
import FormField from "packs/models/FormField"
import LocationTag from "packs/models/LocationTag"
import UserSetting from "packs/models/UserSetting"
import { updateTypeLiteralNode } from "typescript"

const roomStorage = new RoomStorage()

@Options({
    components: { MdTextfieldTag, PPTosContent, VueFinalModal, MdProgressBar },
})
export default class LocationTagSettingModal extends Vue {
    @Prop()
    edittingLoctag: LocationTag

    @Prop() // func
    saveLoctag
    // saveLoctag: (loctag: LocationTag) => void

    isPreview: boolean = Util.isPreview()

    // data
    rm = RoomManager
    loading = false
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone

    contentHeight = 320
    validEmail = true
    validName = true
    validCompanyName = true

    saving = false
    notice = Notice
    userInfo: RoomMember = RoomMember.createDefaultClient()
    Util = Util
    companyField: FormField = null
    loctag: LocationTag = null
    zoomHostkeyErrorMessage = null

    openModal() {
        gtagPage(`#/場所情報変更モーダル`)
        if (this.edittingLoctag) {
            this.loctag = { ...this.edittingLoctag } as LocationTag
        }
    }

    // checkCompanyField() {
    //     if (!this.room) return
    //     let ffs = [...(this.room.form_fields || [])]
    //     if (ffs.length == 0) return
    //     let companyff = ffs.find(ff => ff.param_key == `attendee1_company_name`)
    //     if (!companyff) return
    //     this.companyField = companyff
    // }

    public inputName(e) {
        this.loctag.keyword = e
    }

    public checkName() {
        this.validName = Util.isPresent(this.loctag.keyword)
    }

    inputZoomHostKey(e) {
        this.loctag.zoom_host_key = e
        if (Util.isPresent(e) && !Util.isNumeric(e)) {
            this.zoomHostkeyErrorMessage = `ホストキーは数字のみです`
            return
        }

        this.zoomHostkeyErrorMessage = null
    }

    public closeModal() {
        this.$vfm.close(`LocationTagSettingModal`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    public send() {
        this.saveLoctag(this.loctag)
        // this.checkName()

        // if (!this.validName) {
        //     this.notice.message = `お名前を正しく入力してください`
        //     return
        // }

        // if (!this.validEmail) {
        //     this.notice.message = `メールアドレスを正しく入力してください`
        //     return
        // }

        // if (!this.validCompanyName) {
        //     this.notice.message = `${this.companyField.field_name}を正しく入力してください`
        //     return
        // }

        // // 場所情報を変更する処理.
        // if (this.loading) return
        // this.loading = true

        // // let appo: Appointment = Appointment.copy(this.appo)

        // RoomMessage.startChat(this.userInfo, this.room.id, this.typedMessage).then(dic => {
        //     this.loading = false
        //     if (Util.isPresent(dic)) {
        //         this.$emit(`sentEmailName`, dic)
        //     }
        // })
    }

    checkGoogleMap() {
        if (this.loctag.location_type == `online`) return

        // let url = `https://www.google.com/maps/search/?latitude=${this.loctag.lat}&longitude=${this.loctag.lng}&zoom=15`
        // let url = `https://www.google.com/maps/@${this.loctag.lat},${this.loctag.lng},15z`

        let url = `https://maps.google.com/maps?q=${this.loctag.lat},${this.loctag.lng}`
        window.open(url, `_blank`)
    }
}
