
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import axios from "axios"
import NotificationControl from "packs/utils/NotificationControl"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"

const roomStorage = new RoomStorage()

@Options({
    components: { MdTextfieldTag, VueFinalModal, MdProgressBar },
})
export default class InputGroupNamespaceModal extends Vue {
    @Prop()
    appo: Appointment

    // data
    rm = RoomManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 320
    validName = true

    namespace = ``
    saving = false
    notice = Notice
    ngReasons: string[] = null
    Util = Util

    public created() {}

    public mounted() {
        this.updateNamespace()
    }

    @Watch(`rm.namespace`)
    updateNamespace() {
        if (!this.rm.namespace) return
        this.namespace = this.rm.namespace
    }

    public inputNamespace(e) {
        this.namespace = e
        this.checkName()
    }

    public checkName() {
        this.validName = !Util.isBlank(this.namespace)
    }

    public closeModal() {
        this.$vfm.close(`InputGroupNamespaceModal`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    public createNamespace() {
        this.checkName()
        if (!this.validName) {
            this.notice.message = `企業URLを正しく入力してください`
            return
        }

        if (this.loading) return
        this.loading = true
        this.ngReasons = null

        RoomMember.checkNamespace(this.namespace).then(res => {
            this.loading = false

            const can_register = res.canRegister
            if (can_register) {
                this.loading = true
                return axios
                    .put(`${Util.prefixUrl}/user_groups/namespace?namespace=${this.namespace}`)
                    .then(res => {
                        let namespace = res.data.namespace
                        this.notice.message = res.data.message
                        this.loading = false
                        if (namespace) {
                            // this.rm.namespace = namespace
                            this.$emit(`updateNamespace`, namespace)
                        }
                    })
                    .catch(err => {
                        this.loading = false
                        Logger(`err: ${err.message} ${Util.output(err.response)}`)
                        NotificationControl.showErrorMessage(err)
                        return null
                    })
            } else {
                const ng_reasons = res.msgs
                if (ng_reasons) {
                    this.ngReasons = ng_reasons
                    this.notice.message = ng_reasons.join()
                    this.contentHeight = 380
                }
            }
        })

        // this.$emit(`saveNamespace`, )
    }
}
