
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.

// モデルを読込.

@Options({
    components: {},
})
export default class WeekdayButtons extends Vue {
    @Prop()
    dayOfTheWeek: number[]

    @Prop()
    canEditWeek: boolean

    @Prop()
    classes: string

    @Prop()
    showHoliday: boolean

    @Prop()
    useHoliday: boolean

    isSP = isMobile(window.navigator).phone

    // data
    week = []
    systemUpdatedAt = Util.getSec()

    public mounted() {
        this.resetDtag()
    }

    @Watch(`dayOfTheWeek`, { deep: true })
    public resetDtag() {
        this.week = Util.isPresent(this.dayOfTheWeek) ? [...this.dayOfTheWeek] : []
    }

    // 曜日のチェック処理.
    public selectCheckbox(e) {
        if (!this.canEditWeek) return
        const weekNum = e as number
        if (this.week.includes(weekNum)) {
            this.week = this.week.filter(w => w != weekNum)
        } else {
            this.week.push(weekNum)
        }
        this.systemUpdatedAt = Util.getSec()
        this.$emit(`update`, this.week)
    }

    selectCheckboxHoliday() {
        if (!this.canEditWeek) return
        this.$emit(`updateHoliday`, !this.useHoliday)
    }
}
