import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "fl" }
const _hoisted_3 = { class: "fl mt30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdTextfieldTag = _resolveComponent("MdTextfieldTag")!
  const _component_SelectTag = _resolveComponent("SelectTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MdTextfieldTag, {
          fieldStyle: "min-width:80px;width: 60px;max-width:60px;",
          style: {"height":"56px","margin-top":"5px"},
          class: "fl fs16 mr5",
          type: `number`,
          placeholder: `10`,
          value: _ctx.value,
          onInputValue: _ctx.inputValue,
          onClickTextfield: _ctx.clickValue,
          onBlur: _ctx.blurField,
          min: 0,
          required: ""
        }, null, 8, ["value", "onInputValue", "onClickTextfield", "onBlur"]),
        _createVNode(_component_SelectTag, {
          name: "span_slot_names",
          id: "select_span_slot_names",
          class: "fl fs14 roundForm mt15 mr5",
          style: {"min-width":"50px","height":"50px"},
          options: (_ctx.spanSlotArr || []).map(seldic => seldic.name),
          defaultOption: _ctx.defaultUnit,
          needUnselect: false,
          onSelectValue: _ctx.selectType
        }, null, 8, ["options", "defaultOption", "onSelectValue"]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.afterText || `まで可能`), 1)
      ])
    ])
  ]))
}