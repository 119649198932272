
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import { DateTime } from "luxon"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TemplateCell from "packs/pages/link/parts/TemplateCell.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        TemplateCell,
        VueFinalModal,
    },
})
export default class ModalInputMessage extends Vue {
    @Prop()
    isDev

    // data:
    messageText = ``

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    tmps: MessageTemplate[] = null
    showTmpCells = false

    public async mounted() {
        // 最新のメッセージが表示されます。
        // this.hold = "bottom";
        // this.scrollToBottom();
        Logger(`${funcName()} isGuest:${Util.isGuest()}`)
        if (!this.tmps && !Util.isGuest()) {
            this.rm.getTemplates().then(tmps => {
                this.tmps = tmps
            })
        }
    }

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("InputMessageModal")
    }

    public destroyed() {
        Logger(`未送信メッセージを削除します。`)
        roomStorage.deleteUnsentMessagesAll()
    }

    send() {
        this.$emit("sendFromModal", this.typedMessage)
        this.typedMessage = ``
    }

    // スマホでメッセージを入力する場合はモーダルを表示します。
    public inputStart() {}

    public inputMessage(e) {
        // Logger(e);
        let count = (e.match(/\n/g) || []).length
        // Logger(`改行数: ${count}`);
        this.typedMessage = e
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        if (this.showTmpCells) {
            this.showTmpCells = false
        } else {
            this.showTmpCells = true
        }

        // if (Const.started(this.rm)) {
        //     // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
        //     this.$vfm.open("ModalTemplates")
        // } else {
        //     this.$vfm.open("AdRegistrationModal")
        // }
    }

    public selectTmp(tmp: MessageTemplate) {
        Logger(`templateを選択`)
        // this.$emit("decideTemplate", tmp)
        // this.dismissModal()
        this.updateTmp(tmp)
        this.showTmpCells = false
    }

    public updateTmp(tmp: MessageTemplate) {
        // テンプレを最新のユーザー/アポの内容をはめて更新します。
        if (!tmp || !this.rm.currentMessageRoom) return
        let mems = this.rm.currentMessageRoom.members
        let owner = mems.find(m => m.role == 100) || this.rm.userInfo
        let atts = mems.filter(m => m.user_id != this.rm.userInfo.user_id)
        let str = TemplateUtil.insertTemplateText(
            tmp.content,
            atts,
            owner,
            this.rm.currentMessageRoom.current_appointment,
            this.rm.userInfo
        )
        this.typedMessage = str
        // this.$emit("inputMemo", str)
    }

    // 改行数によってtextareaの高さを変更します。
    // @Watch("typedMessage")
    // public changeHeight() {
    //     let breakCount = (this.typedMessage.match(/\n/g) || []).length;
    //     const defaultHeight = 32;
    //     let enteredHeight = defaultHeight + 26 * breakCount;
    //     // $(".responseArea").css({
    //     //     height: `${enteredHeight}px`,
    //     //     "min-height": `44px`
    //     // });
    //     // $("#messageText").css({
    //     //     height: `${enteredHeight - 12}px`,
    //     //     "min-height": `32px`
    //     // });
    // }

    /**
     * 入力途中のメッセージを保存します。
     */
    @Watch("typedMessage")
    public saveInput() {
        let message = RoomMessage.createUnsentMessage(this.rm.userInfo, this.typedMessage, this.rm.currentMessageRoom.room_id)
        // if (this.hasAttachment) message.file = this.userFiles[0]
        roomStorage.saveInputMessage(this.rm.currentMessageRoom.room_id, message)
        // roomStorage.saveInputMessage(this.rm.currentMessageRoom.room_id, this.typedMessage)
    }

    /**
     * RoomIdに紐づく入力途中のメッセージを取得してinput formに はめます。
     */
    @Watch("rm.currentMessageRoom", { deep: true })
    public fetchInput() {
        if (this.rm.currentMessageRoom) {
            let message = roomStorage.fetchInputMessage(this.rm.currentMessageRoom.room_id)
            this.typedMessage = message ? message.message : ``
            // this.typedMessage = roomStorage.fetchInputMessage(this.rm.currentMessageRoom.room_id) || ""
        } else {
            this.typedMessage = ""
        }
    }
}
