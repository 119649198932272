
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import FormField from "packs/models/FormField"

@Options({
    components: { FormFieldContent, VueFinalModal, MdProgressBar },
})
export default class ScheduleRuleModal extends Vue {
    @Prop()
    room: Room

    // data
    rm = RoomManager
    fields: FormField[] = null
    rule: FormField[] = null

    loading = false
    isMe = false
    Util = Util
    systemUpdatedAt = Util.getSec()
    ruleText = null

    public created() {}

    public updated() {
        // setTimeout(this.adjustHeight, 100)
        this.getRuleText()
    }

    hideModal() {
        this.$vfm.close(`ScheduleRuleModal`)
    }

    public mounted() {
        this.updateRoom()
    }

    updateRoom() {
        if (!this.room) return
        this.fields = this.room.form_fields ? this.room.form_fields.filter(f => [`select`, `radio`].includes(f.field_type)) : null
        this.rule = this.room.rule
    }

    updateFromChild(field: FormField) {
        Logger(`update field val: ${field.selected_values}`)
        let _fields = Util.isPresent(this.fields) ? [...this.fields] : []
        let _f = _fields.find(_f => _f.index_num == field.index_num)
        _f = Object.assign(_f, field)
        this.fields = _fields
        this.getRuleText()
    }

    blurField(field: FormField) {
        Logger(`blur isValid=${field.isValid}`)
        let _fields = Util.isPresent(this.fields) ? [...this.fields] : []
        let _f = _fields.find(_f => _f.index_num == field.index_num)
        let isValid = FormField.isValidField(_f)
        _f.isValid = isValid ? true : false
        this.fields = _fields
        this.getRuleText()
    }

    saveRule() {
        let _fs = [...this.fields]
        this.rule = _fs.filter(_f => Util.isPresent(_f.selected_values))
        Logger(`ルールを保存します. ${Util.output(this.rule)}`)
        this.$emit(`saveRule`, this.rule)
    }

    resetRule() {
        let _fs = [...this.fields]
        for (let _f of _fs) {
            _f.selected_values = null
        }
        this.fields = _fs
        let el = $("select option").removeAttr(`selected`)
        $("select option").eq(0).attr(`selected`, `selected`)
        this.systemUpdatedAt = Util.getSec()
        this.getRuleText()
    }

    cancelRule() {
        this.$vfm.close(`ScheduleRuleModal`)
    }

    getRuleText() {
        let text = ``
        let index = 0
        for (let _f of this.fields) {
            if (Util.isBlank(_f.selected_values)) continue

            if (index != 0) text += `、`
            text += `「${_f.field_name}」が「${_f.selected_values[0]}」`
            index += 1
        }
        this.ruleText = text
    }
}
