
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref, Emit } from "vue-property-decorator"
import axios from "axios"
import Util from "../../utils/Util"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import WhatStrictCal from "packs/components/calendar/WhatStrictCal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"

@Options({
    components: { WhatStrictCal },
})
export default class LinkHeader extends Vue {
    @Prop()
    pageTitle: string

    @Prop()
    buttonTitle: string

    @Prop()
    buttonEnabled: boolean

    @Prop()
    showChangeIcon: boolean

    @Prop()
    showWhatCal: boolean

    @Prop()
    showBackButton: boolean

    @Prop()
    isSuspend: boolean

    isSP = isMobile(window.navigator).phone
    href = ``
    rm = RoomManager
    Util = Util

    mounted() {
        this.href = location.href
    }

    clickBack() {
        this.$router.back()
    }

    @Emit("clickPinkButton")
    clickPinkButton() {
        return ""
    }

    @Emit("clickChange")
    clickChange() {
        return ""
    }

    @Emit("clickNew")
    clickNew() {
        return ""
    }

    showChangeAstagIcon(): boolean {
        if (!this.showChangeIcon) return false
        const astags = this.rm.astags
        if (astags && astags.length > 0) {
            if (astags.length == 1) {
                const astag = astags[0]
                if (astag.id != "newId") {
                    return true
                }
            } else {
                return true
            }
        }
        return false
    }
}
