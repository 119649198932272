
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"

const roomStorage = new RoomStorage()

@Options({
    components: { MdTextfieldTag, VueFinalModal, MdProgressBar },
})
export default class CalendarTagDetailModal extends Vue {
    // @Prop()
    // ctag: CalendarTag

    // ignore: イベント無視, available: 可能日時
    @Prop()
    type: string

    // data
    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    cm = CalendarManager

    contentHeight = 300
    validName = true
    loading = false
    ctag: CalendarTag = null

    public mounted() {
        this.updateCtag()
    }

    @Watch(`cm.edittingCtag`, { deep: true })
    updateCtag() {
        if (!this.cm.edittingCtag) return
        this.ctag = this.cm.edittingCtag
    }

    public closeModal() {
        this.$vfm.close(`CalendarTagDetailModal`)
    }

    get getTitle() {
        if (this.type == `available`) {
            return `可能日時に反転するキーワード`
        }
        return `特定のイベントを無視`
    }

    get getDescription() {
        if (!this.ctag) return
        if (this.type == `available`) {
            return `${this.ctag.name}にある特定のイベントを可能日時にするキーワードを設定します。※部分一致`
        }
        return `${this.ctag.name}にある特定のイベントを無視するキーワードを設定します。※部分一致`
    }

    tooltipDescription() {
        return `週次のイベント名を設定した場合、\n毎週可能日時になります。\nまた、キーワードを設定すると、\n可能時間幅の指定に関わらず\nその部分のみが提案候補になります。\nイベント名をつなぐ場合は、\n半角縦棒「|」をお使いください。`
    }

    get getValue() {
        if (!this.ctag) return ``
        if (this.type == `available`) {
            return this.ctag.available_event_keyword
        }
        return this.ctag.ignore_event_keyword
    }

    inputName(e) {
        Logger(e)
        let ctag = { ...this.ctag }
        if (this.type == `available`) {
            ctag.available_event_keyword = e
        } else {
            ctag.ignore_event_keyword = e
        }
        this.cm.edittingCtag = ctag
    }

    checkName() {}

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    // 詳細情報を更新します.
    public update() {
        // if (this.loading) return
        // this.loading = true
        // axios.get(`${Util.prefixUrl}/calendar_tags?refresh=true`).then(e => {
        //     this.loading = false
        //     this.$emit(`refreshedCalInfo`)
        // })
        let ctag = { ...this.ctag }
        if (Util.isBlank(ctag.ignore_event_keyword)) {
            ctag.can_use_ignore_event_keyword = false
        } else {
            ctag.can_use_ignore_event_keyword = true
            ctag.is_active_meeting_attendee_option = true
        }
        if (Util.isBlank(ctag.available_event_keyword)) {
            ctag.can_use_available_event_keyword = false
        } else {
            ctag.can_use_available_event_keyword = true
            ctag.is_active_meeting_attendee_option = true
        }
        this.cm.editEndCtag(ctag)
        this.closeModal()
        this.$emit(`updatedCtag`)
    }

    showDetailCtag() {
        this.closeModal()
        this.$vfm.open(`EditCalendarTagModal`)
    }
}
