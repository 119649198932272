
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Logger } from "packs/common"

// コンポーネントを読込.
import Switcher from "packs/components/forms/Switcher.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import LinkHeader from "packs/components/link/LinkHeader.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import FormStorage from "packs/models/FormStorage"
import Appointment from "packs/models/Appointment"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Const from "packs/utils/Const"
import AppointmentForm from "packs/models/AppointmentForm"
import NotificationControl from "packs/utils/NotificationControl"

const formStorage = new FormStorage()

@Options({
    components: {
        FlashNotice,
        LinkHeader,
        MdProgressSpinner,
    },
})
export default class GoogleFormSettings extends Vue {
    // data:

    $notify: any
    rm = RoomManager
    groupTitle = ""
    isSP = isMobile(window.navigator).phone

    form: AppointmentForm = null
    sentForm = false
    isPreview = true
    loading = false

    formValues = {}

    mounted() {
        gtagPage("#/form_settings")
        let form = formStorage.fetchForm()
        Logger(`form html: ${form.html}`)
        if (form) this.form = form
    }

    updated() {
        Logger(`Eventをリッスンします。`)
        const primaryForm = this.$refs["primaryForm"] as HTMLElement
        if (!primaryForm) return
        let len = primaryForm.children.length
        for (let i = 0; i < len; i++) {
            primaryForm.children[i].addEventListener("click", this.formClickEvent)
        }
        // for (let child of primaryForm.children ) {
        //     child.add
        // }appsMaterialWizToggleRadiogroupOffRadio
        // primaryForm.addEventListener("click", this.formClickEvent, true)
    }

    public formClickEvent(event) {
        Logger(event.target)
        Logger(`${typeof event.target}`)
        let target = event.target
        Logger(`${event.target.value}`)
        let targetClass = $(event.target).attr(`class`)
        Logger(`${targetClass}`)
        if (targetClass.includes("appsMaterialWizToggleRadiogroupOffRadio")) {
            // ラジオボタン押下時の処理.
            let radiosContainer = $(target).parents(`.freebirdFormviewerViewItemsRadioChoicesContainer`)
            let children = radiosContainer.children()
            for (let i = 0; i < children.length; i++) {
                $(children[i]).find(".appsMaterialWizToggleRadiogroupEl").removeClass("isChecked")
            }
            let radio = $(target)
                .parents(`.appsMaterialWizToggleRadiogroupElContainer`)
                .find(".appsMaterialWizToggleRadiogroupEl")
            radio.addClass(`isChecked`)

            let listItem = $(target).parents(`.freebirdFormviewerViewItemsItemItem`)
            // .find("input[type='hidden']")
            Logger(`listItem: ${listItem}`)
            // let fieldId = listItem.attr("data-item-id")
            let fieldId = (listItem.find("input[type='hidden']").attr("name") || ``).replace(".other_option_response", "")
            let currentVal = $(radio).attr(`aria-label`)
            if (currentVal.includes(`その他`)) currentVal = `__other_option__`

            this.formValues[`${fieldId}`] = currentVal

            // $(event.target).addClass("isChecked")
        }

        if (targetClass.includes("quantumWizTogglePapercheckboxInnerBox")) {
            // ✓していない場合、チェックボックス押下時の処理.
            let checkboxContainer = $(target).parents(`.quantumWizTogglePapercheckboxEl`)
            checkboxContainer.addClass("isChecked")
            $(checkboxContainer).attr("aria-checked", "true")
            let val = $(checkboxContainer).attr("aria-label")
            let listItem = $(target).parents(`.freebirdFormviewerViewItemsItemItem`)
            let fieldId = listItem.find("input[type='hidden']").attr("name").replace("_sentinel", "")
            // let elms = $(`input[type='hidden][name='entry.${fieldId}']`)

            let vals = this.formValues[fieldId] || []
            Logger(`select value: ${val} vals: ${vals} field_id: ${fieldId}`)
            if (val.includes(`その他`)) val = `__other_option__`
            vals.push(`${val}`)
            this.formValues[fieldId] = vals
            // for (let i = 0; i < elms.length; i++) {
            //     let val = $(elms[i]).attr("value")
            //     vals.push(val)
            // }
            // Object.entries(params).forEach(([key, value]) => {
            // })
        }
        if (targetClass.includes("quantumWizTogglePapercheckboxCheckMarkContainer")) {
            // ✓している場合のDOM名が異なる
            let checkboxContainer = $(target).parents(`.quantumWizTogglePapercheckboxEl`)
            checkboxContainer.removeClass("isChecked")
            $(checkboxContainer).attr("aria-checked", "false")
            let val = $(checkboxContainer).attr("aria-label")
            let listItem = $(target).parents(`.freebirdFormviewerViewItemsItemItem`)
            let fieldId = listItem.find("input[type='hidden']").attr("name").replace("_sentinel", "")
            this.formValues[`${fieldId}`] = this.formValues[`${fieldId}`].filter(v => v != val)
        }
        Logger(`formValues: ${Util.output(this.formValues)}`)
    }

    publishEmbedsUrl() {
        Logger(``)
    }

    sendForm() {
        Logger(`送信します。`)
        let params = {}
        let fieldindex = -1
        for (let field of this.form.fields) {
            fieldindex += 1
            if (!field.name) {
                // その他を入力した場合、次のinput fieldのvalに仕込んでおきます。
                let classNames = field.class.split(" ").join(".")
                let container = $(".freebirdFormviewerViewNumberedItemContainer")[field.index_num]
                let val = $(container).find(`.${classNames}`).val()
                Logger(`その他で入力された値を取得: ${val}`)
                if (val) {
                    let nextField = this.form.fields[fieldindex + 1]
                    $(`input[name='${nextField.name}']`).val(val)
                }
                continue
            }
            params[field.name] = $(`input[name='${field.name}']`).val()
            Logger(`引っこ抜いたkey: ${field.name}  val: ${params[field.name]}`)
            if (!params[field.name]) {
                params[field.name] = $(`textarea[name='${field.name}']`).val()
            }
            // 必須項目に未入力だったらアラートを表示.
            if (field.required && !params[field.name]) {
                let classNames = field.class.split(" ").join(".")
                $(`.${classNames}[name='${field.name}'][role='listitem']`).addClass("HasError")
                return
            }
        }
        // ラジオボタン / チェックボックスの入力確認
        Object.entries(this.formValues).forEach(([key, value]) => {
            Logger(`ラジオかチェックボックス ${key} : ${value}`)
            params[key] = value
        })

        Logger(`送信するparams: ${Util.output(params)}`)
        if (this.loading) return
        this.loading = true

        let formData = new FormData()
        Object.entries(params).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(v => {
                    const val = v as string
                    val.replace(`その他:`, `__other_option__`)

                    formData.append(key, val) // arrayデータを分割して入れ直す
                })
            } else {
                const val = (value as string) || ""
                val.replace(`その他:`, `__other_option__`)

                formData.append(key, val)
            }
        })
        const url = `${this.form.form_info[`action`]}?embedded=true`
        Logger(`送信先: ${url}`)
        axios
            .post(url, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then(e => {
                Logger(`result: ${Util.output(e)}`)
                this.sentForm = true
                this.loading = false
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                // 成功
                this.sentForm = true
                if (this.isPreview) {
                    // カレンダーを表示します。
                    Logger(`デフォルトのastagを利用します。`)
                } else {
                    // 組み込まれた本番環境の場合、どのastagを利用するかを取得してきます。
                }
                this.loading = false

                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
