
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, Logger } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import Switcher from "packs/components/forms/Switcher.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomStorage from "packs/models/RoomStorage"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import MailFlow from "packs/models/MailFlow"
import SelectUtil from "packs/utils/SelectUtil"
import DateTag from "packs/models/DateTag"

@Options({
    components: {
        Switcher,
        IconHelp,
    },
})
export default class CustomMailFlowList extends Vue {
    // data:

    @Prop()
    mf: MailFlow

    @Prop()
    mfindex: number

    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone

    // 今回利用するテンプレ.
    localTmp: MessageTemplate = null
    memo = ``
    selectedTabName = this.rcm.room && this.rcm.room.room_type == `parent_room` ? `create_room` : `inquire_on_public_room`

    // テンプレ更新ボタンの表示
    showUpdateTmpButton = false

    currentAppo: Appointment = null
    pubRoomsArray: any[] = []
    publics: string[] = null
    // ボタンに設定している公開ページ
    defaultPubName = null

    sendTimingArray = null
    showSwitchers = false

    SelectUtil = SelectUtil
    truncate = truncate

    // 設定したカスタム通知内容
    customMailFlows = []

    get timingText() {
        if (!this.mf) return
        if (!this.mf.timing_type) return ``
        let timingDic = SelectUtil.selectMailFlowTimingTypes.find(tt => tt.type == this.mf.timing_type)
        if (timingDic) {
            return timingDic.name
        }
        return
    }

    get timingMinWithUnit() {
        if (!this.mf) return ``
        let mins = this.mf.send_timing
        let [time, unit] = DateTag.calcurateMins(mins)
        return `${time || 0}${unit}`
    }

    get sendToText() {
        if (!this.mf) return ``
        if (this.mf.send_to.includes(`all`)) {
            return `オーナー,調整相手,担当者`
        } else {
            let arr = SelectUtil.selectMailFlowSendToTypes
            let sendTo = this.mf.send_to || []
            let sendNames = []
            for (let memStr of sendTo) {
                let dic = arr.find(sendtype => sendtype.type == memStr)
                if (dic) {
                    sendNames.push(dic.name)
                }
            }
            return sendNames.join(`, `)
        }
    }

    fetchActiveStatus(timingArray: any[]) {
        for (let _ti of timingArray) {
            let _mf = this.rcm.mail_flows.find(m => m.def_type == _ti.type)
            if (_mf) {
                _ti.is_active = _mf.is_active
            }
        }
        return timingArray
    }

    public changeActive(mf: MailFlow) {
        Logger(`changeActive: ${Util.output(mf)}`)
        let _mfs = this.rcm.mail_flows
        let _mf = _mfs.find(_mf => MailFlow.isSame(_mf, mf))
        if (_mf) {
            if (_mf.is_active) {
                _mf.is_active = false
            } else {
                _mf.is_active = true
            }
            this.rcm.updateMailFlow(_mf)
        }
    }

    public showPreview(timing) {
        Logger(`${funcName()} timing:${Util.output(timing)}`)

        let _mf = this.rcm.findCustomMailFlow(timing)

        if (_mf) {
            this.rcm.selectedMailFlow = _mf
        }
    }

    public editContent(timing) {
        Logger(`${funcName()} timing:${Util.output(timing)}`)
        this.$emit(`editContent`, timing)
    }

    removeCustomMf(mf) {
        this.$emit(`removeCustomMf`, mf)
    }
}
