
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserSetting from "packs/models/UserSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        FlashNotice,
        AdLimitFree,
    },
})
export default class AccountHeader extends Vue {
    // data:
    @Prop()
    selectedTab

    // sharedState = PropertyStore;
    // notice = Notice;

    // rm = RoomManager;
    // util = Util;
    // selectedTab = `member`;

    rm = RoomManager
    UserPermissionsOption = UserPermissionsOption

    isSP = isMobile(window.navigator).phone
    showPaymentModal = false
    showAds = false
    from: string = ``
    saving = false

    public clickPayment() {
        // フォームを表示.
        Logger(`お支払いフォームでお問い合わせします。`)
        // this.$vfm.open("PaymentRequestModal");
        this.showAdsModal(`Accountお支払いボタン`)
    }

    // 課金フォームを表示します。
    // 親のAccount.vueからも飛んでくる.
    public showAdsModal(from: string) {
        this.from = from
        this.showAds = true
        this.$vfm.open("PaymentRequestModal")
    }

    public hidePaymentRequestModal(didChange: boolean) {
        // this.doesChangeAppo = didChange;
        // this.$vfm.close("PaymentRequestModal");
        this.showAds = false
    }

    // public fadeOutPaymentRequestModal() {
    //     // this.showAds = false;
    //     // this.$vfm.close("PaymentRequestModal");
    //     this.showAds = false;
    // }

    public fadeOutAdModal() {
        this.showAds = false
    }

    public clickWaaqSupport() {
        let support = false
        if (!this.rm.support_waaq) {
            support = true
        }

        if (this.saving) return
        this.saving = true
        this.rm.startProgress()

        UserSetting.supportWaaq(support).then(res => {
            this.rm.support_waaq = support
            this.saving = false
            this.rm.endProgress()
        })
    }
}
