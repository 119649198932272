
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import ZapierNotification from "packs/models/ZapierNotification"
import Notice from "packs/models/Notice"
import CustomNotification from "packs/models/CustomNotification"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
    },
})
export default class GoogleSpreadSheetNotificationContent extends Vue {
    @Prop()
    noti: CustomNotification

    @Prop() // user, user_group, room_setting
    scope: string

    @Prop() // func
    showOptionModal

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Util = Util
    notice = Notice

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: CustomNotification = null
    showTip = false
    optionEventName = `管理画面Googleスプレッドシート 課金リクエスト`
    connectableNotis: CustomNotification[] = null
    availableEvents = [
        {
            timing: "fixed_appointment",
            name: "日程確定",
        },
        {
            timing: "inquire_on_public_room",
            name: "受付完了",
        },
        {
            timing: "cancel_appointment",
            name: "キャンセル",
        },
    ]
    notificationSettings = null
    CustomNotification = CustomNotification
    truncate = truncate

    mounted() {
        Logger(`${funcName()} mounted`)
        this.updateNoti()
    }

    requestGoogleSpreadSheet() {
        this.rm.showPlanOptionModalWithName = `管理画面Googleスプレッドシート 課金リクエスト`
    }

    @Watch(`noti`, { deep: true })
    updateNoti() {
        if (!this.noti) return
        this.notiDic = { ...this.noti }
        this.notificationSettings = { ...this.notiDic.settings }
    }

    goGoogleSpreadSheetOAuth() {
        Logger(`${funcName()} goGoogleSpreadSheetOAuth`)
        window.open(`/connect/google_spread_sheet`)
    }

    removeGoogleSpreadSheet() {
        CustomNotification.delete(`google_spread_sheet`, `user`).then(e => {
            Logger(`AFTER DELETE GOOGLE SPREAD SHEET NOTI: ${Util.output(e)}`)
            this.notiDic = CustomNotification.createDefault(`user`, `google_spread_sheet`)
            this.$emit(`updateNotification`, this.notiDic, `google_spread_sheet`)
        })
    }

    connectGoogleSpreadSheet() {
        Logger(`${funcName()}`)
        // 公開ページ紐づけ
        let noti = CustomNotification.createDefault(`user`, `google_spread_sheet`)

        this.rm.getNotifications().then(dic => {
            Logger(`connectGoogleSpreadSheet dic:${Util.output(dic?.google_spread_sheet)}`)
            noti.account_id = dic.google_spread_sheet.account_id
            let settings = {
                provider_account_id: ``,
                google_sheets_file_id: ``,
                google_sheets_sheet_name: ``,
                check_field_type: `email`,
                fixed_appointment: { content: `` },
                inquire_on_public_room: { content: `` },
                cancel_appointment: { content: `` },
            }
            noti.settings = settings
            this.notiDic = noti
            this.notificationSettings = settings

            this.$emit(`updateNotification`, this.notiDic, `google_spread_sheet`)

            this.selectGoogleSpreadSheetFile()
        })
    }

    disconnectGoogleSpreadSheet() {
        // 公開ページ紐づけ
        this.notiDic = CustomNotification.createDefault(`room_setting`, `google_spread_sheet`)
        this.$emit(`updateNotification`, null, `google_spread_sheet`)
    }

    get defaultEvent() {
        let timing = `fixed_appointment`
        let evDic = this.availableEvents.find(evdic => evdic.timing == timing)

        return evDic.name
    }

    public hideTooltip(e) {
        this.showTip = false
    }

    selectGoogleSpreadSheetFile() {
        this.rcm.showLeftPanelContentWithType = `google_spread_sheet_file_selector`
    }
}
