
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import UserSettingsNotAttendCalendar from "packs/pages/link/settings/UserSettingsNotAttendCalendar.vue"
import RefreshCalendarModal from "packs/pages/link/modals/RefreshCalendarModal.vue"
import AvailableStepSizeContent from "packs/pages/link/parts/available_schedule/AvailableStepSizeContent.vue"
import AvailableDaysOfWeeksTable from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTable.vue"
import MeetingTimeContent from "packs/pages/link/parts/room_settings/MeetingTimeContent.vue"
import AvailableDaysOfWeeksTableCell from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTableCell.vue"
import ModalInnerAvailableTime from "packs/pages/link/modals/ModalInnerAvailableTime.vue"
import TimezoneSelectContent from "packs/components/calendar/TimezoneSelectContent.vue"
import ChangeOwnerMyPublicRoomsModal from "packs/pages/link/modals/ChangeOwnerMyPublicRoomsModal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserSetting from "packs/models/UserSetting"
import Appointment from "packs/models/Appointment"
import RoomSetting from "packs/models/RoomSetting"
import DateTag from "packs/models/DateTag"
import CalendarManager from "packs/models/CalendarManager"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        SelectTag,
        MdTextfieldTag,
        UserSettingsNotAttendCalendar,
        RefreshCalendarModal,
        AvailableStepSizeContent,
        AvailableDaysOfWeeksTable,
        MeetingTimeContent,
        AvailableDaysOfWeeksTableCell,
        ModalInnerAvailableTime,
        TimezoneSelectContent,
        ChangeOwnerMyPublicRoomsModal,
    },
})
export default class UserSettingsSchedule extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    cm = CalendarManager
    Util = Util
    CalendarUtil = CalendarUtil
    SelectUtil = SelectUtil

    selectedTab = `schedule`
    from = `user_settings`

    isSP = isMobile(window.navigator).phone
    selectTimeThemes: DateTag[] = null
    displayAdows: DateTag[] = null
    // users: RoomMember[] = [];

    loading = false
    userInfo = null
    us: UserSetting = null
    defaultStepSizeTitle = ``
    appo = Appointment.createDefault()

    selectedCategory = `defaultSettings`
    timeArray = SelectUtil.timeArray
    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    customAvailableStartTimes = []
    memberEmail = null
    failedMail = false
    showChangeOwnerButton = true

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.getAstag()
        } else {
            this.checkPermission()
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            this.us = { ...this.rm.user_setting }

            this.customAvailableStartTimes = this.us.available_start_times
            Logger(`UserSettingsSchedule.created us: ${Util.output(this.us)}`)
        }
    }

    getAstag() {
        this.rm.getAvailableSchedule().then(res => {
            this.checkPermission()
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            this.us = { ...this.rm.user_setting }

            this.customAvailableStartTimes = this.us.available_start_times
            Logger(`UserSettingsSchedule.getAstag us: ${Util.output(this.us)}`)
        })
    }

    mounted() {
        gtagPage("#/settings/schedule")
        this.updateDisplayAdows()
    }

    checkPermission() {
        this.rm.redirectTopIfChukaiFreePlan(`UserSettingsInfo1`)
    }

    @Watch(`us`)
    updateDisplayAdows() {
        if (!this.us) return
        Logger(`displayAdows, adows: ${Util.output(this.us.available_days_of_weeks)}`)

        let adows = this.us.available_days_of_weeks
        let selectTimeThemes = DateTag.createSelectTimeThemes(adows)
        this.selectTimeThemes = selectTimeThemes
        let arr = adows.filter(av => av.type != `theme`)
        Logger(`displayAdows arr: ${Util.output(arr)}`)
        this.displayAdows = arr
        // return arr
    }

    public selectTheme(theme: DateTag) {
        Logger(`select theme::::`)
        gtagClick(`テーマ選択 ${theme.text}`, `${theme.select ? `✔あり→なし` : `✔なし→あり`}`)

        this.addAdow(theme)
        // this.addAdow(theme)
    }

    deleteAdow(adows: DateTag[]) {
        this.us.available_days_of_weeks = adows
        this.rm.user_setting = { ...this.us }

        this.updateUserSetting(`available_days_of_weeks`)
    }

    addAdow(dtag: DateTag) {
        Logger(`addAdow: ${Util.output(dtag)}`)
        if (dtag.select) {
            dtag.select = false
            this.us.available_days_of_weeks = DateTag.deleteFromArray(this.us.available_days_of_weeks, dtag)
        } else {
            dtag.select = true
            let hasAdow = false
            this.us.available_days_of_weeks.forEach(adow => {
                if (DateTag.isSameAdow(adow, dtag)) {
                    hasAdow = true
                }
            })
            // 同じdtagがなかったら登録.
            if (hasAdow) {
                // 既に登録済みです。
                Notice.message = `既に同じ設定の繰り返し予定が登録済みです。`
            } else {
                this.us.available_days_of_weeks.push(dtag)
                // this.cm.astag.systemUpdatedAt = DateTime.local().toMillis()
            }
        }
        this.updateUserSetting(`available_days_of_weeks`)
    }

    inputSubmitNum(e) {
        Logger(e)
        this.us.submit_lower_limit_num = e
        this.updateUserSetting(`lower_limit_num`)
    }

    selectDisplayPossibleDatesEmailNum(e) {
        Logger(e)

        this.us.email_suggests_num = e as number
        this.updateUserSetting(`email_suggests_num`)
    }

    selectCategory(name: string) {
        this.selectedCategory = name
    }

    disconnectCalendar() {
        gtagClick(`ユーザー設定`, `カレンダー解除`)
        if (
            confirm(
                `連携を解除しますが、よろしいですか?作成済みの調整カレンダーに外部カレンダーを連携している場合、自動的に外れます。`
            )
        ) {
            UserSetting.disconnectCalendar().then(e => {
                if (e) {
                    // astag取得から
                    this.getAstag()
                    if (this.cm) {
                        this.cm.resetAstagInfo()
                        if (this.cm.ctList) {
                            this.cm.ctList = null
                        }
                    }
                }
            })
        }
        Logger(`連携を解除しますが、よろしいですか?`)
    }

    refreshCalendar() {
        this.$vfm.open(`RefreshCalendarModal`)
    }

    refreshedCalInfo() {
        this.$vfm.close(`RefreshCalendarModal`)
        Notice.message = `最新の情報を取得してきました。`
    }

    selectAddEventType(e) {
        let typeDic = CalendarUtil.selectAddEventTypes.find(t => t.name == e)
        if (!typeDic) return

        this.us.add_event_type = typeDic.type
        this.updateUserSetting(`add_event_type`)
    }

    updateUserSetting(updateType: string) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        UserSetting.update(this.us, updateType).then(res => {
            this.rm.endProgress()
            this.loading = false

            if (res) {
                this.notice.message = res.mes
                this.us = res.us
                this.rm.user_setting = res.us
            }
        })
    }

    updateMeetingTime(appo: Appointment) {
        this.us.default_duration = appo.duration
        this.updateUserSetting(`default_duration`)
    }

    updateStepSize(rs: RoomSetting) {
        this.us.step_size_type = rs.step_size_type
        this.us.available_start_times = rs.available_start_times
        this.customAvailableStartTimes = rs.available_start_times
        this.updateUserSetting(`step_size`)
    }

    selectTimezone(tzDic: any) {
        Logger(`${funcName()} tzDic:${Util.output(tzDic)}`)
        this.us.timezone_value = tzDic.value
        this.updateUserSetting(`timezone`)
    }

    inputChangeOwnerEmail(e) {
        this.memberEmail = e
    }

    changeOwnerMyPublicRooms() {
        Logger(`${funcName()}`)

        if (!Util.validateEmail(this.memberEmail)) {
            Logger("emailではありません。")
            this.failedMail = true
            return false
        }
        this.failedMail = false
        this.$vfm.open(`ChangeOwnerMyPublicRoomsModal`)
    }

    requestedChangeOwner(isSuccess: boolean) {
        this.$vfm.close(`ChangeOwnerMyPublicRoomsModal`)
        if (isSuccess) {
            this.failedMail = false
            this.memberEmail = null
            this.showChangeOwnerButton = false
        }
    }
}
