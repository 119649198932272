
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import { gtagClick, gtagEvent } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.

// モデルを読込.
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import UserIcon from "packs/components/icons/UserIcon.vue"
import Appointment from "packs/models/Appointment"

@Options({
    components: {
        UserIcon,
    },
})
export default class OneMagSmallContent extends Vue {
    @Prop() // このmag情報
    mag: MeetingAttendeesGroup

    @Prop() // 選択中のmag
    selectedMag: MeetingAttendeesGroup
    // data:

    cm = CalendarManager
    rm = RoomManager
    Util = Util
    truncate = truncate

    selectMag(mag: MeetingAttendeesGroup) {
        this.$emit(`selectMag`, mag)
    }
}
