import { withKeys as _withKeys, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "name", "value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    name: _ctx.name,
    value: _ctx.value,
    placeholder: _ctx.placeholder,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    disabled: _ctx.isDisabled ? `true` : null,
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
    style: _normalizeStyle({
            background: _ctx.isDisabled ? `#fbfbfb` : ``,
        }),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitEnter && _ctx.emitEnter(...args)), ["enter"]))
  }, null, 44, _hoisted_1))
}