
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { funcName, Logger } from "packs/common"
import isMobile from "ismobilejs"
import { DateTime, Interval } from "luxon"
import Const from "packs/utils/Const"
import SelectUtil from "packs/utils/SelectUtil"
import VueResizable from "vue-resizable"
import { Dropdown } from "uiv"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import BlackBoxEvent from "packs/pages/link/parts/available_schedule/BlackBoxEvent.vue"
import OneEventContent from "packs/pages/link/parts/available_schedule/OneEventContent.vue"
import BlackBoxEventMenu from "packs/pages/link/parts/available_schedule/BlackBoxEventMenu.vue"

// モデルを読込.
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import CalendarUtil from "packs/utils/CalendarUtil"
import Notice from "packs/models/Notice"
import PlanOption from "packs/models/PlanOption"
import RoomCreateManager from "packs/models/RoomCreateManager"
import { gtagHover } from "packs/GoogleTagManager"

const ONE_HOUR_HEIGHT = 30

@Options({
    components: { BlackBoxEvent, OneEventContent, BlackBoxEventMenu, VueResizable, Dropdown },
})
export default class DailyLine extends Vue {
    // data:
    @Prop()
    date: DateTime

    @Prop()
    wday

    @Prop()
    avzones

    @Prop()
    id: string

    @Prop()
    didConnectCal: boolean

    @Prop() // top: ScheduleViewから、 asview: AstagViewのWeeklyScheduleから
    type: string

    // ScheduleViewから表示させた際に入る.
    @Prop()
    checkConflictEvents: Event[]

    CELL_HEIGHT = Const.CELL_HEIGHT

    isSP = isMobile(window.navigator).phone
    Util = Util
    SelectUtil = SelectUtil
    DateTime = DateTime
    cm = CalendarManager
    rm = RoomManager
    pdm = PossibleDatesManager
    rcm = RoomCreateManager
    truncate = truncate

    currentEvent: Event = null

    // この日付のイベントのみを保持.
    optionalEvents: Event[] = []
    optionalAddEvents: Event[] = []
    optionalRemoveEvents: Event[] = []

    events: Event[] = null // event, possible_eventを保持
    onlineEvents: Event[] = null // daily_online_meeting_eventsを保持.
    alldayEvents: Event[] = null
    possibleEvents = null // possible_eventを保持
    dragEnd = false
    alldayHeight = this.cm.alldayHeight

    selecting = false
    selectCells: number[] = []
    notCloseLi = []

    @Ref()
    dropdown

    // currentEventPosition = `` // "top", "bottom", "both" 上にavzoneがある、下にavzoneがある、上下両方にある

    mounted() {
        // DailyLineをクリック時にイベントを作成するイベント処理を追加します。
        // 他のDailyLineと区別するためにidを指定します。
        if (this.dropdown) {
            this.notCloseLi.push(this.dropdown.$el)
        }

        this.updateSchedules()
        this.updateDailyEvents()
        this.updatePossibleDates()
        this.updateCheckConflictEvents()
        if (this.isSelfMode) {
            this.addPossibleDatesFromOptionalSchedules()
        }
    }

    get isSelfMode() {
        if (this.cm.createType == `vote`) return true
        if (this.cm.astag.is_only_use_optional_schedules) return true
        return false
    }

    @Watch(`cm.schedules`, { deep: true })
    updateSchedules() {
        if (this.type == `top`) return
        if (Util.isBlank(this.cm.astag)) return
        const datestr = this.date.toFormat(`MMdd`)
        // 描画に負荷がかかるので、アップデートする必要があるかを確認します.
        // if (!this.cm.updatedEventDays.includes(datestr)) return

        let optionalEvents = this.cm.schedules[datestr] || []
        Logger(`DailyLine[${datestr}]:updateSchedules ${Util.output(optionalEvents)}`)

        this.optionalEvents = optionalEvents
        // let index = this.cm.updatedEventDays.findIndex(s => s == datestr)
        // this.cm.updatedEventDays.splice(index, 1)
        // Logger(`DailyLine::updateAstag[${datestr}] add: ${optionalEvents.length}`)
    }

    @Watch(`cm.eventsMagDic`, { deep: true })
    updateDailyEvents() {
        const datestr = this.date.toFormat(`MMdd`)
        // if (!this.didConnectCal || Util.isBlank(this.cm.eventsMagDic)) {
        //     this.alldayEvents = null
        //     this.events = null
        //     let magEvents = this.cm.eventsMagDic[this.cm.displayMagName]
        //     if (magEvents) {
        //         let onlineEvs: Event[] = magEvents.daily_online_meeting_events[datestr]
        //         this.onlineEvents = onlineEvs
        //     } else {
        //         this.onlineEvents = null
        //     }

        //     return
        // }

        Logger(`DailyLine[${datestr}]:updateDailyEvents`)
        let _displayMag = this.cm.eventsMagDic[this.cm.displayMagName]
        if (Util.isBlank(_displayMag)) return
        let evs: Event[] = _displayMag.daily_events[datestr] || []
        let onlineEvs: Event[] = this.cm.eventsMagDic[this.cm.displayMagName].daily_online_meeting_events[datestr] || []
        // Logger(`日毎のイベントを表示[${datestr}]: ${Util.output(evs)}`)
        let alldayEvents = evs.filter(ev => ev.allday)
        this.alldayEvents = alldayEvents
        let _mag = this.cm.mags.find(m => m.name == this.cm.displayMagName)

        // ctagがignore_alldayの場合はeventsから抜きます.
        if (_mag) {
            Logger(`${funcName()} _mag: ${_mag.name} ${_mag.team_name} astag: ${Util.output(this.cm.astag)}`)
            const ctags = _mag.required_attendees
            // 終日無視するイベント以外の表示するイベントを収集するArray
            let ignoredEvs = []
            for (let _ev of evs) {
                let _ct = ctags.find(ct => ct.email == _ev.calendar_id)
                // ctagの個別設定がアクティブの場合は、こちらを優先します.
                if (_ct && _ct.is_active_meeting_attendee_option) {
                    Logger(`${funcName()} _ctがアクティブです`)
                    if (_ct.ignore_allday && _ev.allday) {
                        // 終日無視設定で、終日イベントはdailyに表示しない.
                    } else {
                        ignoredEvs.push(_ev)
                    }
                }
                // astagの共通設定がアクティブの場合は、こちらを確認します.
                else {
                    Logger(`${funcName()} astagがアクティブです`)
                    if (this.cm.astag.ignore_allday && _ev.allday) {
                        // 終日無視設定で、終日イベントはdailyに表示しない.
                    } else {
                        ignoredEvs.push(_ev)
                    }
                }
            }
            evs = ignoredEvs
        }
        // 選択中のmagに含まれているctagsを絞り込んで、表示
        // this.events = evs.filter(ev => !ev.allday)
        // this.events = evs.filter(ev => !ev.is_match_ignore_keyword)
        this.events = evs
        this.onlineEvents = onlineEvs
    }

    @Watch(`checkConflictEvents`)
    updateCheckConflictEvents() {
        Logger(`${funcName()} `)
        if (Util.isBlank(this.checkConflictEvents)) return
        this.events = this.checkConflictEvents
        Logger(`${funcName()} this.events更新: ${Util.output(this.events)}`)
        this.cm.alldayHeight = 0
        this.alldayHeight = 0
    }

    @Watch(`pdm.mouseoverPd`)
    updateMouseoverPd() {
        if (this.type != `top`) return
        if (!this.pdm.mouseoverPd) return
        const ev = PossibleDate.toEvent(this.pdm.mouseoverPd)
        Logger(`${funcName()} ev:${Util.output(ev)}`)
        this.optionalAddEvents = [ev]
    }

    @Watch(`cm.displayMagName`)
    updateDisplayMagName() {
        if (!this.cm.displayMagName) return
        Logger(`${funcName()} DailyLine:updateDisplayMagName`)
        this.updateDailyEvents()
        this.updatePossibleDates()
    }

    @Watch(`cm.currentPossibleDates`, { deep: true })
    updatePossibleDates() {
        Logger(`${funcName()} cm.dailyPossibleDates:${this.cm.dailyPossibleDates}`)
        if (Util.isBlank(this.cm.dailyPossibleDates)) {
            this.possibleEvents = null
            return
        }
        const datestr = this.date.toFormat(`MMdd`)

        let evsDic = this.cm.dailyPossibleDates[datestr] || []
        Logger(`DailyLine[${datestr}]:dailyPossibleDates, evsDic:${Util.output(evsDic)}`)
        if (Util.isPresent(evsDic)) {
            const teamName = this.cm.displayMagName || `A`
            let _dic = null
            let userId = this.cm.getOwnerUserId()
            _dic = evsDic[userId].group_events_dic[teamName]

            if (_dic) {
                let _evs = _dic.possible_events || []
                Logger(`${funcName()} possibleEventsを更新します:[${_evs.length}] ${Util.output(_evs)}`)
                this.possibleEvents = _evs
            }

            // const name = this.cm.displayMagName || `A`
            // let _dic = evsDic[this.rm.userInfo.user_id].group_events_dic[name]
            // if (_dic) {
            //     let _evs = _dic.possible_events || []
            //     Logger(`${funcName()} possibleEventsを更新します:[${_evs.length}] ${Util.output(_evs)}`)
            //     this.possibleEvents = _evs
            // }
        }
    }

    /**
     * カレンダー連携していない場合は、プレビューをクリックできるようにします。
     */
    @Watch("didConnectCal")
    updateConnectCal() {
        Logger(`${funcName()} カレンダー外部連携がスイッチされました.イベントをリセットします.`)
        // カレンダー連携のONOFFを切り替えたときに、表示情報を変更します.
        // this.optionalEvents = null
        this.possibleEvents = null
        this.events = null
        this.alldayEvents = null
    }

    @Watch(`optionalEvents`, { deep: true })
    updateOptionalAddRemoveEvents() {
        Logger(`${funcName()} createType:${this.cm.createType}`)
        // this.optionalAddEvents = this.optionalEvents.filter(op => op.type == `optional_add`)
        // this.optionalRemoveEvents = this.optionalEvents.filter(op => op.type == `optional_remove`)

        this.optionalAddEvents = this.optionalEvents.filter(op => op.type == `optional_add`)
        this.optionalRemoveEvents = this.optionalEvents.filter(op => op.type == `optional_remove`)

        if (this.isSelfMode) {
            this.possibleEvents = this.optionalEvents
        }
    }

    // クイック投票時に既存の調整カレンダーを利用する場合に、既存のoptional_schedulesを分割して表示します.
    addPossibleDatesFromOptionalSchedules() {
        let schedules = this.optionalEvents.filter(op => op.type == `optional_add`)
        let stepSize = CalendarUtil.selectDisplayMeetingTimeArr.find(dic => dic.time == this.cm.rs.step_size_type)
        if (stepSize.number == -1) {
            // 奇数時間・偶数時間・カスタムのときに発生 いったん60をセットする
            stepSize.number = 60
        }
        let pds = []
        let meetingDuration = this.cm.previewDuration
        for (let os of schedules) {
            let newPds = Event.toSeparatePossibleDates(os, meetingDuration, stepSize.number)
            Array.prototype.push.apply(pds, newPds)
        }
        this.optionalAddEvents = pds
        this.optionalEvents = pds
        const datestr = this.date.toFormat(`MMdd`)
        // Logger(`VOTE DailyLine[${datestr}]:dailyPossibleDates -> ${Util.output(newPds)}`)
        this.cm.updatePossibleDatesForVote(pds, datestr)
    }

    /**
     * 追加したOptionalEventを削除します。
     *
     */
    public deleteOptionalEvent(ev: Event) {
        const datestr = this.date.toFormat(`MMdd`)
        Logger(`${funcName()}[${datestr}]: ev:${Util.output(ev)}`)

        let evs = []
        if (this.isSelfMode) {
            evs = [...(this.possibleEvents || [])]
        } else {
            evs = [...(this.optionalEvents || [])]
        }

        evs = evs.filter(e => !Event.isSame(e, ev, true))

        if (this.isSelfMode) {
            this.cm.updateSchedules(evs, `all`, this.date)
            let pd = PossibleDate.createFromStartTime(ev.start_time, ev.duration)
            this.cm.deletePossibleDates(pd)
            Logger(`${funcName()} vote output options: added: ${Util.output(this.optionalEvents)}`)
        } else {
            // normal ケース.
            this.optionalEvents = evs
            this.cm.updateSchedules(this.optionalEvents, `all`, this.date)
            Logger(`${funcName()} normal output options: added: ${Util.output(this.optionalEvents)}`)
        }
    }

    // 作成済みオプショナルイベントを編集（開始点）
    startDrag(ev: Event) {
        this.currentEvent = ev
        Logger(`${funcName()} dragStart:dragStart`)
    }

    // 作成済みオプショナルイベントを編集（終了点）
    endDrag(dic: any) {
        Logger(`${funcName()} endEditEvent: ev: ${Util.output(this.currentEvent)} dic: ${Util.output(dic)}`)

        // 下を伸ばしている場合、
        let ev = { ...this.currentEvent }
        let startCell: number = dic.start_cell
        const startHour = Math.floor(startCell / 4)
        const startMin = Math.floor(startCell % 4) * 15
        const startTime = this.date.plus({ hours: startHour, minutes: startMin })
        const duration = dic.duration * 15

        let opEvs = [...(this.optionalEvents || [])]

        let opEv = opEvs.find(_ev => _ev.systemUpdatedAt == ev.systemUpdatedAt && _ev.id == ev.id)
        if (opEv) {
            opEvs = this.resetSelectingEvents(opEvs)
            Logger(`${funcName()} opEvありました.`)

            let _ev = Event.createOptional(opEv.type, startTime, duration, opEv.is_force)
            if (ev.id) _ev.id = ev.id
            _ev.isSelecting = true
            Object.assign(opEv, _ev)
            this.cm.selectedEvent = opEv
            this.optionalEvents = opEvs
            Logger(`${funcName()} 更新されたopEv: ${Util.output(opEv)}`)
            // this.currentEvent = null
            this.cm.updateSchedules(this.optionalEvents, `all`, this.date)
        }
    }

    /**
     * avzoneの上に、OptionalScheduleのremoveを追加して、更新します。
     */
    deleteAvailableTime(avzone) {
        Logger(`${funcName()} removeEventsに1時間ごとに追加して黒イベントを表示します。`)
        // 1時間ごとのremoveEventsを作成し
        // let removes = [];
        const datestr = this.date.toFormat(`MMdd`)
        const removes = [...Array(avzone.duration / 60)].map((_, blackIndex) => {
            const startTime = this.date.plus({
                hours: avzone.start_hour + blackIndex,
            })
            return Event.createOptional("optional_remove", startTime, 60)
        })
        Logger(`${funcName()} removes: ${Util.output(removes)}`)
        this.$emit("addEvents", removes, "remove")
    }

    deleteAvailableTimeBox(avzone, index) {
        Logger(`remove one black box: ${Util.output(avzone)}, index: ${index}`)
    }

    get isDisabled(): string {
        // buttonDateは日曜日の00:00のため+1日する
        if (DateTime.local().minus({ days: 1 }) > this.date) {
            return "disabled no-drop"
        }
        return ""
    }

    // 新規オプショナルイベントを作成（開始点）
    startCell(num, id) {
        if (this.type == `top`) return
        this.selectCells = [num]
        this.selecting = true
        Logger(`clickしました: ${num}, ${id}`)
    }

    mouseoverCell(num, id) {
        if (this.selecting) {
            this.cm.mouseOverCell = null
            const start = this.selectCells[0]
            let abs = Math.abs(start - num) + 1
            let minimum = start > num ? num : start
            this.selectCells = Array(abs)
                .fill(0)
                .map((v, i) => i + minimum)
            Logger(`mouseover: ${num}, ${id}`)
        } else {
            this.cm.mouseOverCell = `${num}${id}`
        }
    }

    resetSelectingEvents(evs: Event[]) {
        if (!evs) return []
        for (let _ev of evs) {
            _ev.isSelecting = false
        }
        return evs
    }

    // 新規オプショナルイベントを作成（終了点）
    endCell(num, id) {
        Logger(`離しました: ${num}, ${id} date: ${this.date.toFormat("MM/dd HH:mm")}, isSelfMode:${this.isSelfMode}`)
        this.selecting = false

        if (Util.isBlank(this.selectCells)) return
        if (this.type == `top`) return

        // イベントを作成.
        const startCell = this.selectCells[0]
        const startHour = Math.floor(startCell / 4)
        const startMin = Math.floor(startCell % 4) * 15
        const startTime = this.date.plus({ hours: startHour, minutes: startMin })
        if (DateTime.local().toSeconds() > startTime.toSeconds()) {
            Notice.message = `過去の日程は選択できません。`
            return
        }

        let duration = this.selectCells.length * 15
        Logger(`${funcName()} duration:${duration}, previewDuration:${this.cm.previewDuration}, cm.rs:${Util.output(this.cm.rs)}`)
        if (this.cm.previewDuration && this.cm.previewDuration > duration) {
            duration = this.cm.previewDuration
        }
        Logger(`${funcName()} startTime: ${startTime.toFormat("MM/dd HH:mm")}, duration: ${duration}`)
        const ev = Event.createOptional(`optional_add`, startTime, duration)

        if (!this.optionalEvents) this.optionalEvents = []

        if (this.isSelfMode) {
            let stepSize =
                CalendarUtil.selectDisplayMeetingTimeArr.find(dic => dic.time == this.cm.rs.step_size_type) ||
                CalendarUtil.defaultMeetingTimeDic
            if (stepSize.number == -1) {
                // 奇数時間・偶数時間・カスタムのときに発生 いったん60をセットする
                stepSize.number = 60
            }
            let meetingDuration = this.cm.previewDuration
            let newPds = Event.toSeparatePossibleDates(ev, meetingDuration, stepSize.number)
            Array.prototype.push.apply(this.optionalEvents, newPds)

            const datestr = this.date.toFormat(`MMdd`)
            Logger(
                `VOTE DailyLine[${datestr}]:dailyPossibleDates newPds.length:${(newPds || []).length} -> ${Util.output(newPds)}`
            )
            // Logger(`DailyLine::updatePossibleDates[${datestr}] magName: ${this.cm.displayMagName}`)
            this.cm.updatePossibleDatesForVote(this.optionalEvents, datestr)
            this.cm.updateSchedules(this.optionalEvents, `all`, this.date)
        } else {
            // normalケース.
            let opEvs = [...(this.optionalEvents || [])]
            opEvs = this.resetSelectingEvents(opEvs)

            ev.isSelecting = true
            opEvs.push(ev)
            this.optionalEvents = opEvs
            this.cm.selectedEvent = ev
            Logger(`optionalEvents: ${Util.output(this.optionalEvents)}`)

            this.cm.updateSchedules(this.optionalEvents, `all`, this.date)

            this.checkAndExtendAvailablePeriod(ev)
        }
    }

    // 相対期間の期間外だったら、期間をのばします.
    checkAndExtendAvailablePeriod(ev: Event) {
        let rs = this.cm.rs
        if (Util.isBlank(rs)) return

        let endmin = rs.available_period_end_min
        let endTimeDay = endmin / (60 * 24)
        let endTime = DateTime.local().plus({ day: endTimeDay }).endOf(`day`)
        Logger(
            `${funcName()} endTime:${endTime.toSQLDate()} endTime.seconds:${endTime.toSeconds()} ev.start_time:${ev.start_time}`
        )

        if (rs.available_period_type == `relative` && ev.start_time > endTime.toSeconds()) {
            let dt = DateTime.fromSeconds(ev.start_time).endOf(`day`)
            let int = Interval.fromDateTimes(DateTime.local(), dt)
            let days = Math.ceil(int.length(`days`))
            const maxDays = PlanOption.canUseOption(this.rm.plan_option, `calendar_condition_max_period`)
            Logger(`${funcName()} 指定期間範囲外のため、期間を自動で${days}日までに延長します. `)
            if (days > maxDays) {
                Notice.message = `${maxDays}日以上先の日程を指定することはできません。`
                return
            }

            rs.available_period_end_min = days * 24 * 60
            this.cm.updateRoomSetting(rs)

            if (Util.isPresent(this.rcm) && Util.isPresent(this.rcm.room_setting)) {
                Logger(`${funcName()} rcm.room_settingを更新します.`)
                this.rcm.room_setting.available_period_end_min = days * 24 * 60
                this.cm.updateRoomSetting(this.rcm.room_setting)
            }
        }
    }

    addEvent(ev: Event, type: string) {
        if (this.type == `top`) return
        if (type == `cal`) {
            this.$emit(`addEvent`, ev, type)
        } else {
            let schedules = { ...(this.cm.schedules || {}) }

            let dateStr = this.date.toFormat(`MMdd`)
            if (type == `available`) {
                // 可能に変更
                let _ev = this.optionalEvents.find(e => Event.isSame(e, ev, true))
                if (_ev) {
                    _ev.type = `optional_add`
                    _ev.is_force = false
                }
            } else if (type == `availableForce`) {
                // 可能に変更
                let _ev = this.optionalEvents.find(e => Event.isSame(e, ev, true))
                if (_ev) {
                    _ev.type = `optional_add`
                    _ev.is_force = true
                }
            } else if (type == `notAvailable`) {
                // 不可に変更

                // let addevs = [...(addSchedules[dateStr] || [])]
                // let addevs = this.addedEvents.filter(_ev => !Event.isSame(_ev, ev))
                // addSchedules[dateStr] = addevs

                // let removeevs = [...(removeSchedules[dateStr] || [])]

                let _ev = this.optionalEvents.find(e => Event.isSame(e, ev, true))
                if (_ev) {
                    _ev.type = `optional_remove`
                    _ev.is_force = false
                }
                // this.removedEvents.push(ev)
                // removeSchedules[dateStr] = this.removedEvents
            }
            this.cm.updateSchedules(this.optionalEvents, `all`, this.date)
            // Logger(
            //     `WeeklyDetailSchedule.不可に変更: add: ${Util.output(this.addedEvents)} remove: ${Util.output(
            //         this.removedEvents
            //     )}`
            // )
            // this.cm.updateSchedules(addSchedules, `add`)
            // this.cm.updateSchedules(removeSchedules, `remove`)
        }
        //
    }

    changeAvailableEvent(ev: Event) {
        Logger(`DailyLine:changeAvailableEvent`)
        this.$emit(`changeAvailableEvent`, ev)
    }

    changeIgnoreEvent(ev: Event) {
        Logger(`DailyLine:changeIgnoreEvent`)
        this.$emit(`changeIgnoreEvent`, ev)
    }

    showAvailableZones() {
        if (!this.cm.astag) return

        // 個別選択モードの場合表示不要です.
        if (this.cm.astag.is_only_use_optional_schedules) return false

        // astag画面で投票でない場合avzoneの表示は不要です.
        if (this.cm.createType == `normal` && !Util.isRoomSettingsPreview()) return false
        // クイック投票でカレンダー未連携の場合表示は不要です。
        if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) return false

        let _mag = this.cm.mags.find(m => m.name == this.cm.displayMagName)
        if (!_mag) return true

        let ctags = _mag.required_attendees
        if (Util.isBlank(ctags)) return true
        let show = false
        let avCtags = []
        avCtags = ctags.filter(ct => ct.can_use_available_event_keyword)
        if (avCtags.length == ctags.length) return false
        return true
    }

    showEventDetail() {
        this.$emit(`showEventDetail`)
    }

    mouseoverAlert() {
        gtagHover(`調整カレンダー`, `alert BLOCK Allday`)
    }

    changeMaxBookingsNum(ev: Event) {
        Logger(`${funcName()} ev:${Util.output(ev)}`)
        let pd = this.optionalEvents.find(e => Event.isSame(e, ev, true))
        if (!pd) {
            // イベントを選択している場合、
            pd = this.optionalEvents.find(e => e.start_time == ev.start_time)
        }
        if (pd) {
            this.$emit(`changeMaxBookingsNum`, pd)
        }
    }
}
