
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger, funcName, sleep } from "packs/common"

// コンポーネントを読込.
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: { UserFileImage, MdTextfieldTag },
})
export default class AnsweredFormTable extends Vue {
    @Prop() // mem.answered_resultsの中身.
    results

    @Prop()
    room: Room

    @Prop()
    attendees: any[]

    @Prop()
    canEdit: boolean

    // data

    rm = RoomManager
    Util = Util
    editMode = false
    resultsDic = null
    editDicIndex = null
    edittingValue = null

    deletedAttachment() {}

    editStart(dicindex) {
        Logger(`${funcName()} dicindex:${dicindex}`)
        this.editDicIndex = dicindex
        this.resultsDic = { ...this.results }
        this.edittingValue = this.resultsDic[dicindex].value
        this.editMode = true
    }

    cancelEdit() {
        this.editMode = false
        this.editDicIndex = null
    }

    endEdit() {
        // this.resultsDic = { ...this.results }
        this.resultsDic[this.editDicIndex].value = this.edittingValue

        this.$emit(`updateAnsweredResults`, this.resultsDic, this.editDicIndex)
        this.editMode = false
        this.editDicIndex = null
    }

    inputValue(value) {
        this.edittingValue = value
    }
}
