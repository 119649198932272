
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Notice from "packs/models/Notice"
import Const from "packs/utils/Const"
import FlashNotice from "packs/components/common/FlashNotice.vue"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import WeekdayButtons from "packs/components/buttons/WeekdayButtons.vue"
import AvailableDaysOfWeeksTableCell from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTableCell.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Event from "packs/models/Event"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import CalendarManager from "packs/models/CalendarManager"
import UserSetting from "packs/models/UserSetting"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        FlashNotice,
        SelectTag,
        WeekdayButtons,
        AvailableDaysOfWeeksTableCell,
        IconHelp,
    },
})
export default class AvailableStepSizeContent extends Vue {
    @Prop() // 自動的にリモートに保存するか.
    autoSave: boolean

    @Prop() // room_settings, user_settings
    from: string

    @Prop()
    hideTitle: boolean

    // data:
    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    Const = Const
    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    Notice = Notice
    loading = false
    loadingCal = false // カレンダーを追加取得する場合に利用.

    // astag = AvailableScheduleTag.createDefaultAstag();
    hours = Util.selectHours
    today = DateTime.local().setZone("Asia/Tokyo")
    // sundayDate = this.getCurrentSunday()

    systemUpdatedAt = DateTime.local().toMillis()

    pdm = PossibleDatesManager

    cm = CalendarManager
    defaultStepSizeTitle = ``

    timeArray = SelectUtil.timeArray
    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    customAvailableStartTimes = []
    notice = Notice

    public mounted() {
        let meetingTimeArr = CalendarUtil.selectDisplayMeetingTimeArr

        let type = `hourly`
        let rs = this.getRs()

        this.customAvailableStartTimes = [...(rs.available_start_times || [])]
        type = rs.step_size_type || `hourly`
        this.defaultStepSizeTitle = meetingTimeArr.find(mt => mt.time == type).type
        Logger(
            `AvailableStepSizeContent.mounted from: ${this.from} customAvailableStartTimes: ${Util.output(
                rs.available_start_times
            )} rm.us.av_start_times: ${Util.output(this.rm.user_setting.available_start_times)} defaultStepSizeTitle: ${
                this.defaultStepSizeTitle
            }`
        )
    }

    /**
     * RoomSettingsから来ている場合はrs,
     * UserSettingsからきている場合は、usを詰め込みます.
     */
    getRs() {
        let rs = null
        if (this.from == `room_settings`) {
            rs = { ...this.rcm.room_setting }
            Logger(`rcm.room_settingを返します`)
        } else if (this.from == `user_settings`) {
            rs = { ...this.rm.user_setting }
            Logger(`rm.user_settingを返します`)
        }
        return rs
    }

    /**
     * 刻み幅を変更.
     */
    selectMeetingTime(e) {
        Logger(`selectMeetingTime: ${e}, from: ${this.from}`)
        let meetingTime = CalendarUtil.selectDisplayMeetingTimeArr.find(m => m.type == e)

        if (!meetingTime) return

        let rs = this.getRs()
        rs.step_size_type = meetingTime.time
        this.defaultStepSizeTitle = meetingTime.type
        this.updateSettings(rs)
    }

    selectWeek(wdays: number[]) {
        this.dayOfTheWeek = wdays
    }

    addAvailableStartTime() {
        Logger(`addAvailableStartTime`)
        if (this.dayOfTheWeek.length == 0) {
            this.notice.message = `曜日を最低１つ選択してください。`
            return
        }
        let timeArr = this.startTimeStr.split(`:`)
        const hour = +timeArr[0]
        const min = +timeArr[1]
        let dic = {
            wday: this.dayOfTheWeek,
            available_time: {},
        }

        let rs = this.getRs()

        dic.available_time[hour] = [min]

        if (!rs.available_start_times) rs.available_start_times = []
        rs.available_start_times.push(dic)
        this.customAvailableStartTimes = [...rs.available_start_times]
        Logger(`customAvailableStartTimes: ${Util.output(rs.available_start_times)}`)

        this.updateSettings(rs)
    }

    updateSettings(rs: RoomSetting) {
        if (this.from == `room_settings`) {
            if (this.rcm && this.rcm.room && this.rcm.room_setting) {
                this.rcm.updateRoomSetting(rs)
            }
            if (this.cm && this.cm.rs) {
                this.cm.updateRoomSetting(rs)
            }
        }

        this.$emit(`update`, rs)
    }

    deleteAdow(adowindex: number) {
        Logger(`AvailableStepSizeContent.deleteAdow from: ${this.from}`)

        this.customAvailableStartTimes.splice(adowindex, 1)
        let rs = this.getRs()
        rs.available_start_times = [...this.customAvailableStartTimes]

        this.updateSettings(rs)
    }

    public selectMeetingStartTime(e) {
        Logger(`selectMeetingStartTime: ${e}`)
        this.startTimeStr = e
    }
}
