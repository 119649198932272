
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { useProgress } from "@marcoschulte/vue3-progress"

// コンポーネントを読込.

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import UserFile from "packs/models/UserFile"
import Const from "packs/utils/Const"
import WebRTCManager from "packs/models/WebRTCManager"

// const roomStorage = new RoomStorage();

@Options({
    components: {},
})
export default class TalkInterface extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    wrm = WebRTCManager

    isSP = isMobile(window.navigator).phone

    Const = Const

    loading = false

    cable = null
    isCallActive = false
    barHeights: number[] = [0, 0, 0, 0, 0]
    audioContext: AudioContext | null = null
    analyser: AnalyserNode | null = null
    dataArray: Uint8Array | null = null

    startCall() {
        if (this.isCallActive) {
            this.endCall()
            return
        }
        let roomId = `66b5ac0eeec4377bcce063a0`
        Logger(`${funcName()} roomId:${roomId}`)
        if (!this.wrm.cable) {
            this.wrm.createNew(this.rm.cable, roomId)
        }
        this.wrm.startCall(true).then(success => {
            if (success) {
                console.log("Call started successfully")
                this.wrm.setupVAD(this.wrm.stream)
                this.isCallActive = true
                this.wrm.startTalk()
                this.setupAudioAnalyser()
            } else {
                console.error("Failed to start call")
            }
        })
    }

    endCall() {
        if (this.wrm) {
            this.wrm.endCall()

            this.isCallActive = false
            this.stopAudioAnalyser()
        }
    }

    handleWebRTCSignal(signal: any) {
        if (this.wrm) {
            this.wrm.handleIncomingSignal(signal)
        } else {
            this.wrm.createNew(this.rm.cable, this.rm.userInfo.user_id)
            this.wrm.startCall(false).then(success => {
                if (success) {
                    this.wrm!.handleIncomingSignal(signal)
                }
            })
        }
    }

    setupAudioAnalyser() {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                this.audioContext = new AudioContext()
                const source = this.audioContext.createMediaStreamSource(stream)
                this.analyser = this.audioContext.createAnalyser()
                this.analyser.fftSize = 32
                source.connect(this.analyser)

                this.dataArray = new Uint8Array(this.analyser.frequencyBinCount)
                this.updateBarHeights()
            })
            .catch(err => console.error("マイクへのアクセスエラー:", err))
    }

    stopAudioAnalyser() {
        if (this.audioContext) {
            this.audioContext.close()
            this.audioContext = null
            this.analyser = null
            this.dataArray = null
        }
    }

    updateBarHeights() {
        if (!this.analyser || !this.dataArray) return

        this.analyser.getByteFrequencyData(this.dataArray)
        for (let i = 0; i < 5; i++) {
            this.barHeights[i] = (this.dataArray[i] / 255) * 100
        }

        requestAnimationFrame(this.updateBarHeights)
    }
}
