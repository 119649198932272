
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomSetting from "packs/models/RoomSetting"
import LocationConverter from "packs/models/LocationConverter"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
    },
})
export default class ConsiderationTravelMode extends Vue {
    // data
    isSP = isMobile(window.navigator).phone
    notice = Notice
    cm = CalendarManager
    rcm = RoomCreateManager

    Util = Util
    openMeetingBufferContent = this.isSP ? false : true
    rs: RoomSetting = null
    AvailableScheduleTag = AvailableScheduleTag

    defaultModeOption: string = null
    separateMin = false
    useLocationConverters = false

    // 登録したコンバーター
    convs = null

    mounted() {
        this.updateAstagFromParent()
        this.useLocationConverters = this.rs.use_location_converters
        if (this.useLocationConverters) {
            this.convs = this.rs.location_converters
        }
    }

    addConvs() {
        this.openLeftPanelConvertersList()
    }

    openLeftPanelConvertersList() {
        this.rcm.showLeftPanelContentWithType = `location_converters`
    }

    @Watch("rcm.room_setting", { deep: true })
    public updateAstagFromParent() {
        this.rs = { ...this.rcm.room_setting }
        this.separateMin = !(this.getFixedTravelBeforeMin() == this.getFixedTravelAfterMin())
    }

    public updateToParent() {
        this.rcm.updateRoomSetting(this.rs)
        // this.$emit("updateAstagFromChild", this.rs)
    }

    @Watch("rs")
    public getDefaultOption() {
        if (!this.rs) {
            this.defaultModeOption = null
            return
        }
        this.defaultModeOption = RoomSetting.changeModeToText(this.rs)
        Logger(`defaultModeOption: ${this.defaultModeOption}`)
        // return AvailableScheduleTag.changeModeToText(this.rs);
    }

    public selectTravelMode(e) {
        if (!this.rs) return

        let options = AvailableScheduleTag.travelTimeModeOptions()
        const modeIndex = options.map(e => e.text).indexOf(e)
        const mode = options.map(e => e.mode)[modeIndex]
        Logger(`select mode: ${mode}`)
        this.rs.travel_time_mode = mode
        this.separateMin = !(this.getFixedTravelBeforeMin() == this.getFixedTravelAfterMin())
        this.updateToParent()
    }

    public getFixedTravelBeforeMin(): number {
        if (this.rcm.room_setting && this.rcm.room_setting.fixed_travel_before_min) {
            return this.rcm.room_setting.fixed_travel_before_min
        }
        return null
    }

    /**
     * 場所を設定する必要がある場合に表示します。
     */
    public showLocationField(): boolean {
        if (!this.rs) return false
        if ([`guess_or_none`, `guess_or_fixed`, `guess_or_minimum`].includes(this.rs.travel_time_mode)) {
            return true
        }
        return false
    }

    /**
     * 固定時間フィールドを表示します.
     */
    showFixBufferField() {
        if (!this.rs) return false
        if ([`fixed`, `guess_or_fixed`, `guess_or_minimum`].includes(this.rs.travel_time_mode)) {
            return true
        }
        return false
    }

    /**
     * バッファを設定する必要がある場合に表示します。
     */
    public showBufferField(): boolean {
        if (!this.rs) return false
        if ([`fixed`, `guess_or_fixed`, `guess_or_minimum`, `guess_or_none`].includes(this.rs.travel_time_mode)) {
            return true
        }
        return false
    }

    public getFixedTravelAfterMin(): number {
        if (this.rcm.room_setting && this.rcm.room_setting.fixed_travel_after_min) {
            return this.rcm.room_setting.fixed_travel_after_min
        }
        return null
    }

    public getLocation(): string {
        if (!this.rs || !this.rs.travel_time_mode_default_location_name) return ``
        return this.rs.travel_time_mode_default_location_name
    }

    public getTravelTimeMode(): string {
        if (!this.rs || !this.rs.travel_time_mode) return `none`
        return this.rs.travel_time_mode
    }

    public inputFixedTravelBeforeMin(raw: string) {
        this.rs.fixed_travel_before_min = +raw
        if (!this.separateMin) {
            this.rs.fixed_travel_after_min = this.rcm.room_setting.fixed_travel_before_min
        }
        this.updateToParent()
    }

    public inputFixedTravelAfterMin(raw: string) {
        this.rs.fixed_travel_after_min = +raw

        this.updateToParent()
    }

    public clickMeetingBufferContent() {
        if (this.isSP) this.openMeetingBufferContent = !this.openMeetingBufferContent
    }

    public inputLocation(raw) {
        this.rs.travel_time_mode_default_location_name = raw
        // 場所を入力した場合pdmを叩かないようrsの直接更新に留めます.
        this.rcm.room_setting.travel_time_mode_default_location_name = raw
    }

    public inputTravelTimeMode(raw) {
        this.rs.travel_time_mode = raw
        // this.saveAstagInLocal();
        this.updateToParent()
    }

    public inputAdditionalFixedTravelMin(e) {
        this.rs.additional_fixed_travel_min = +e
        this.updateToParent()
    }

    public blurAdditionalFixedTravelMin() {
        if (this.rs.additional_fixed_travel_min > 999) {
            this.rs.additional_fixed_travel_min = 999
        }
        if (this.rs.additional_fixed_travel_min < 0) {
            this.rs.additional_fixed_travel_min = 0
        }
        this.updateToParent()
    }

    separateTravelMin() {
        this.separateMin = true
    }

    clickRegisterCalTravelTime() {
        if (this.rs.can_sync_travel_time_to_calendar_event) {
            this.rs.can_sync_travel_time_to_calendar_event = false
        } else {
            this.rs.can_sync_travel_time_to_calendar_event = true
        }
        this.updateToParent()
    }

    inputTravelEventName(e) {
        this.rs.sync_travel_time_event_name = e
        this.updateToParent()
    }

    blurTravelEventName() {}

    clickUseLocationConverter() {
        if (this.useLocationConverters) {
            this.useLocationConverters = false
            this.rcm.room_setting.use_location_converters = false
        } else {
            this.useLocationConverters = true
            this.rcm.room_setting.use_location_converters = true
            // 登録したLocationConverterの候補を表示します.
            this.openLeftPanelConvertersList()
        }
    }

    removeConv(conv: LocationConverter) {
        Logger(`${funcName()} remove conv:${conv.location_converter_id}`)
        let convs = this.rcm.room_setting.location_converters.filter(e => e.location_converter_id != conv.location_converter_id)

        this.rcm.room_setting.location_converters = convs
        this.rcm.updateRoomSetting(this.rcm.room_setting)
        this.convs = convs
    }

    @Watch("rcm.room_setting")
    updateLocationConverters() {
        if (!this.rcm.room_setting) return
        let rs = this.rcm.room_setting
        Logger(
            `${funcName()} use_location_converters:${rs.use_location_converters}, location_converters:${
                (rs.location_converters || []).length
            }`
        )
        if (!rs.use_location_converters) return

        let convs = this.rcm.room_setting.location_converters || []

        if (convs.length > 0) {
            this.convs = convs
        } else {
        }
    }
}
