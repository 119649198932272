
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, Logger } from "packs/common"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import LblueMiddleButton from "packs/components/calendar/LblueMiddleButton.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        SelectTag,
        LblueMiddleButton,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class CustomPermissionModal extends Vue {
    @Prop()
    currentMember: RoomMember

    @Prop() // new, edit
    action: string

    po: UserPermissionsOption = null

    UserPermissionsOption = UserPermissionsOption
    optionNames = UserPermissionsOption.optionCategoryName()
    optionNameDic = UserPermissionsOption.optionNameDic()

    // data:
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    loading = false

    selectedMember: RoomMember = null
    systemUpdatedAt: number = Util.getSec()

    public openModal() {
        gtagPage(`#/カスタム権限設定`)
        Logger(`openModal poをアップデートしました: mem: ${Util.output(this.currentMember)}`)
        this.updateCurrentMember()
        if (!this.po) {
            this.po = UserPermissionsOption.createDefault()
        }
        Logger(`CustomPermissionModal::openModal ${Util.output(this.po)}`)
    }

    @Watch(`currentMember`, { deep: true })
    updateCurrentMember() {
        if (!this.currentMember) return

        this.po = { ...this.currentMember.user_permissions_option }
    }

    selectOption(e, selector: string) {
        let [category, name] = selector.split(`::`)
        Logger(`CustomPermissionModal::selectOption ${Util.output(e)} category: ${category}, name: ${name}`)
        let type = UserPermissionsOption.PERMISSION_NONE
        if (e == `閲覧` || e == `管理者のみに自動共有`) {
            type = UserPermissionsOption.PERMISSION_READABLE
        } else if (e == `編集可能` || e == `全員に自動共有`) {
            type = UserPermissionsOption.PERMISSION_WRITABLE
        }
        this.po[category][name] = type
    }

    changePermissions() {
        Logger(`changePermissions: ${Util.output(this.po)}`)
        this.$emit(`changePermissions`, this.po)
        // if (this.loading) return
        // this.loading = true
        // let role = -1
        // UserPermissionsOption.invite([this.currentMember.email], role, this.po, null).then(user => {
        //     this.loading = false
        //     this.$emit(`didChange`, user)
        // })
    }

    clickCancel() {
        this.$vfm.close("CustomPermissionModal")
    }

    clickAdminRoleTemplate() {
        this.po = UserPermissionsOption.createDefault(`admin`)
    }

    clickMemberRoleTemplate() {
        this.po = UserPermissionsOption.createDefault(`member`)
    }

    defaultOption(permissionType: number, types: string[]) {
        Logger(`${funcName()} permissionType:${permissionType}, types:${types}`)
        if (
            types.toString() == UserPermissionsOption.permissionTypes().toString() ||
            types.toString() == UserPermissionsOption.permission2Types().toString() ||
            types.toString() == UserPermissionsOption.permissionEditTypes().toString()
        ) {
            return permissionType == 100 ? `編集可能` : permissionType == 10 ? `閲覧` : `なし`
        } else if (types.toString() == UserPermissionsOption.permissionShareTypes().toString()) {
            Logger(`permissionShareTypesです.`)
            return permissionType == 100 ? `全員に自動共有` : permissionType == 10 ? `管理者のみに自動共有` : `共有しない`
        }
    }

    public save() {}
}
