
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"

// コンポーネントを読込.

// モデルを読込.

@Options({
    components: {},
})
export default class DataContent extends Vue {
    // data:

    created() {
        document.addEventListener("DOMContentLoaded", () => {
            const node = document.getElementById("dataContent")
            if (node) {
                const props = JSON.parse(node.getAttribute("data"))
                const data = props.data
                this.$emit("updateDataFromRails", data)
            }
        })
    }
}
