
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"
import Notice from "packs/models/Notice"
import RoomCreateManager from "packs/models/RoomCreateManager"
import VotedRoomManager from "packs/models/VotedRoomManager"
import UserGroupHistory from "packs/models/UserGroupHistory"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        TextareaTag,
        CheckboxTag,
        TextfieldTag,
        IconHelp,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class ShareAuthModal extends Vue {
    @Prop() // public_room
    room: Room

    // data
    rm = RoomManager
    vrm = VotedRoomManager
    loading = false
    truncate = truncate

    // 10 = member, 9 = optional
    attendeeType: number = null
    // fix, watch, none
    assignType = null

    Util = Util
    canShowSendFixMemberFlg = false
    sendMailFixMember = true
    message = ``
    member = null
    memberName = null
    memberEmail = null
    rcm = RoomCreateManager
    viewType = `addMember` // addMember / showFixMembers
    sharedMembers: RoomMember[] = null
    // currentRoom: Room = null

    openModal() {
        gtagPage(`#/編集権限の共有モーダル`)
        this.changeViewType(`addMember`)
        this.sharedMembers = null

        this.updateSharedMembers()
    }

    hide() {
        // this.$vfm.close(`ShareAuthModal`)
        this.$emit(`hideShareAuthModal`)
    }

    @Watch(`room`, { deep: true })
    updateSharedMembers() {
        if (!this.room) return
        if (this.sharedMembers) return
        if (!this.room.is_owner) return
        if (this.room.room_type == `parent_room`) return

        // share済みのメンバーを取得します.
        UserGroupHistory.getsharedMembers(this.room.id).then(data => {
            if (Util.isPresent(data)) {
                this.sharedMembers = data
            } else {
                this.sharedMembers = []
            }
        })
    }

    // deprecated.
    clickSendMailFixMember() {
        Logger(`clickSendMailFixMember`)
        if (this.sendMailFixMember) {
            this.sendMailFixMember = false
        } else {
            this.sendMailFixMember = true
        }
    }

    // deprecated.
    inputMessage(e) {
        this.message = e
    }

    send() {
        if (Util.isBlank(this.memberEmail)) {
            Notice.message = `メールアドレスは必須項目です。`
            return
        }
        let email = this.memberEmail.trim()
        if (!Util.validateEmail(email)) {
            Notice.message = `メールアドレスが不正です。`
            return
        }

        // 保存します.
        if (this.loading) return
        this.loading = true

        UserGroupHistory.shareMyRoom(this.room.id, email).then(data => {
            Logger(`${funcName()} user:${Util.output(data)}`)
            this.loading = false
            if (data) {
                this.sharedMembers.push(data)
                this.memberEmail = null
            }
        })
    }

    cancelInput() {
        // 保存せず閉じます.
        this.hide()
    }

    inputEmail(e) {
        this.memberEmail = e
    }

    changeViewType(viewType: string) {
        Logger(`ShareAuthModal#changeViewType viewType:${viewType}`)
        this.viewType = viewType
    }

    deleteSharedMember(mem: RoomMember) {
        Logger(`ShareAuthModal#deleteSharedMember mem:${Util.output(mem)}`)

        if (this.loading) return
        this.loading = true

        UserGroupHistory.deleteSharedMember(this.room.id, mem.user_id).then(mem => {
            this.loading = false
            if (mem) {
                Notice.message = `編集権限を削除しました`

                this.sharedMembers = this.sharedMembers.filter(m => m.user_id != mem.user_id)
            }
        })
    }
}
