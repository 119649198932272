
// モジュールを読込.
import { Options, Prop, Vue, Ref, Watch } from "vue-property-decorator"

// コンポーネントを読込.

// import "fullcalendar";
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, zeroPadding } from "packs/common"
import Event from "packs/models/Event"
import Const from "packs/utils/Const"
import CalendarManager from "packs/models/CalendarManager"
import Util from "packs/utils/Util"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomSetting from "packs/models/RoomSetting"
import RoomMember from "packs/models/RoomMember"

@Options({
    components: {},
})
export default class OneEventRoomContent extends Vue {
    // data:
    @Prop()
    room: Room

    @Prop() // 通常のイベントはnull, zoom
    provider: string

    @Prop() // top: ScheduleViewから、 asview: AstagViewのWeeklyScheduleから
    fromType: string

    CELL_HEIGHT = Const.CELL_HEIGHT
    notCloseLi = []

    cm = CalendarManager

    appo: Appointment = null
    rs: RoomSetting = null
    formMem: RoomMember = null

    mounted() {
        // this.notCloseLi.push(this.BlackBoxEventMenu.getDetailLi())
        this.updateRoom()
    }

    @Watch(`room`, { deep: true })
    updateRoom() {
        if (!this.room) return
        let appo = this.room.current_appointment
        this.appo = appo
        let mem = this.room.members.find(m => [`done`].includes(m.answered_status))
        if (mem) {
            this.formMem = mem
        }
        this.rs = this.room.room_setting
    }

    get title() {
        if (!this.room) return ``

        let apc = this.room.appeal_content
        if (apc && apc.title) return apc.title

        let appo = this.room.current_appointment
        return appo.name
    }

    get fixTime() {
        if (!this.appo) return ``
        // let fmt = this.appo.jp_format
        // return fmt.split(`）`)[1]
        return this.appo.jp_format
    }

    clickFormMember() {
        if (!this.formMem) return
        this.$emit(`clickFormMember`, this.formMem, this.room)
    }

    goRoom() {
        this.$emit(`goRoom`, this.room)
    }
}
