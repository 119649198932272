
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, Logger } from "packs/common"

// コンポーネントを読込.
import Switcher from "packs/components/forms/Switcher.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import CustomMailFlowList from "packs/pages/link/parts/room_settings/mail_parts/CustomMailFlowList.vue"
import MailEditDetailContent from "packs/pages/link/parts/room_settings/mail_parts/MailEditDetailContent.vue"
import IconLock from "packs/components/icons/IconLock.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomStorage from "packs/models/RoomStorage"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import MailFlow from "packs/models/MailFlow"
import SelectUtil from "packs/utils/SelectUtil"

const roomStorage = new RoomStorage()

@Options({
    components: {
        Switcher,
        IconHelp,
        CustomMailFlowList,
        MailEditDetailContent,
        IconLock,
    },
})
export default class MailEditContent extends Vue {
    // data:

    @Prop()
    tmp: MessageTemplate // templateのpublic_id

    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone

    // 今回利用するテンプレ.
    localTmp: MessageTemplate = null
    memo = ``
    selectedTabName = this.rcm.room && this.rcm.room.room_type == `parent_room` ? `create_room` : `inquire_on_public_room`

    // テンプレ更新ボタンの表示
    showUpdateTmpButton = false

    currentAppo: Appointment = null
    pubRoomsArray: any[] = []
    publics: string[] = null
    // ボタンに設定している公開ページ
    defaultPubName = null

    parentTimingArray = Util.timingsDicArray(`parent_room`)
    publicTimingArray = Util.timingsDicArray(`public_room`)
    sendTimingArray = null
    showSwitchers = false

    SelectUtil = SelectUtil

    // 設定したカスタム通知内容
    customMailFlows = []

    mounted() {
        // this.currentAppo = roomStorage.fetchAppointment()
        this.updateAppo()
        this.updateMembers()
        this.setPublicRooms()
        this.updateTabName()
        this.updateRoomSetting()
    }

    public updateTabName() {
        if (this.rcm.selectedMailFlow) {
            this.selectedTabName = this.rcm.selectedMailFlow.def_type
        } else {
            this.selectedTabName =
                this.rcm.room && this.rcm.room.room_type == `parent_room` ? `create_room` : `inquire_on_public_room`
        }
    }

    public inputMemo(e: string, tmpId: string) {
        Logger(`inputMemo from AddCommentToMail(${e})`)
        let _mf = this.rcm.selectedMailFlow

        if (_mf.template_public_id != tmpId) {
            _mf.template_public_id = tmpId
        }

        if (_mf) {
            _mf.content = e
        }

        this.rcm.updateMailFlow(_mf)

        // room_settingのmail_flowsの選択中のタブのコンテントに入れる.
    }

    inputSubject(e: string, tmp: MessageTemplate) {
        // room_settingのmail_flowsの選択中のタブのサブジェクトに入れる.
        let _mf = this.rcm.selectedMailFlow
        if (_mf) {
            // let _mf = this.rcm.selectedMailFlow
            _mf.subject = e
        }
        this.rcm.updateMailFlow(_mf)
    }

    @Watch("rcm.members", { deep: true })
    updateMembers() {
        if (Util.isBlank(this.rcm.members)) return
        Logger(`メンバーを更新するとテンプレも更新します`)
        this.showUpdateTmpButton = true
        this.updateTmpId()
    }

    @Watch("rcm.appo", { deep: true })
    updateAppo() {
        if (!this.rcm.appo) return
        // Logger(`updateAppo addCommentToMail. ${this.appo ? this.appo.memo : ``}`)
        this.showUpdateTmpButton = true
        this.memo = this.rcm.appo.memo
        if (this.rcm.room) {
            if (this.rcm.room.room_type == `public_room`) {
                let _arr = this.fetchActiveStatus([...this.publicTimingArray])
                this.sendTimingArray = _arr
            } else if (this.rcm.room.room_type == `parent_room`) {
                let _arr = this.fetchActiveStatus([...this.parentTimingArray])
                this.sendTimingArray = _arr
            }
            Logger(`sendTimingArray: ${Util.output(this.sendTimingArray)}`)
        }
    }

    @Watch("rcm.room_setting", { deep: true })
    updateRoomSetting() {
        if (!this.rcm.room_setting) return

        // カスタムメールを取得します.
        let rs = this.rcm.room_setting
        this.customMailFlows = rs.mail_flows.filter(mf => MailFlow.isCustom(mf))
    }

    fetchActiveStatus(timingArray: any[]) {
        for (let _ti of timingArray) {
            let _mf = this.rcm.mail_flows.find(m => m.def_type == _ti.type)
            if (_mf) {
                _ti.is_active = _mf.is_active
            }
        }
        return timingArray
    }

    @Watch(`tmp`, { deep: true })
    updateTmpId() {
        if (!this.tmp) return
        Logger(`tmpを更新しました addCommentToMail: ${this.tmp.public_id}`)

        if (Util.isBlank(this.localTmp) || this.tmp.public_id != this.localTmp.public_id) {
            this.localTmp = this.tmp
            this.updateTmp()
        }
    }

    @Watch(`rcm.selectedMailFlow`, { deep: true })
    updateSelectedMailFlow() {
        if (!this.rcm.selectedMailFlow) return
        let tabs = this.rm.roomTabs || {}
        let pubTab = (tabs[`all`] || {})[`public`] || []
        this.setPublicRoomSelect(pubTab.rooms)
    }

    setPublicRoomSelect(rooms: Room[]) {
        if (!rooms) return
        let _mf = this.rcm.selectedMailFlow
        Logger(`setPublicRoomSelect: ${_mf ? _mf.button_public_room_id : null}`)
        this.pubRoomsArray = rooms.map(_pub => {
            return {
                name: `[${_pub.public_id}]${
                    _pub.appeal_content.title ? _pub.appeal_content.title : _pub.current_appointment.name
                }`,
                type: _pub.id,
            }
        })
        Logger(`setPublicRoomSelectでのarray: ${Util.output(this.pubRoomsArray)}`)
        if (Util.isPresent(_mf) && Util.isPresent(_mf.button_public_room_id)) {
            let _dic = this.pubRoomsArray.find(e => e.type == _mf.button_public_room_id)
            Logger(`設定しているページ dic: ${Util.output(_dic)}`)
            if (_dic) {
                this.defaultPubName = _dic.name
            }
        }
        this.publics = this.pubRoomsArray.map(_pub => _pub.name)
    }

    @Watch("rm.userInfo")
    setPublicRooms() {
        if (!this.rm.userInfo) return
        if (this.publics) return

        let tabs = this.rm.roomTabs || {}
        let pubTab = (tabs[`all`] || {})[`public`] || []
        if (Util.isPresent(pubTab) && Util.isPresent(pubTab.rooms)) {
            this.setPublicRoomSelect(pubTab.rooms)
        } else {
            this.rm.getRooms(`all`, `public`).then(e => {
                this.setPublicRoomSelect(e.rooms)
            })
        }
        Logger(`this.publics: ${this.publics}`)
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
        this.$vfm.open("ModalTemplates")
    }

    /**
     * 使うテンプレを決定.
     *
     */
    public decideTemplate(tmp: MessageTemplate) {
        //
        this.localTmp = tmp
        this.updateTmp()
    }

    public updateTmp() {
        // テンプレを最新のユーザー/アポの内容をはめて更新します。
        if (!this.localTmp) return
        // let atts = this.rcm.members.filter(m => m.user_id != this.rm.userInfo.user_id)
        // let owner = this.rcm.members.find(m => m.role == 100) || this.rm.userInfo
        // let str = TemplateUtil.insertTemplateText(this.localTmp.content, atts, owner, this.rcm.appo, this.rm.userInfo)
        // let subj = TemplateUtil.insertTemplateText(this.localTmp.subject, atts, owner, this.rcm.appo, this.rm.userInfo)

        this.inputMemo(this.localTmp.content, this.localTmp.public_id)
        this.inputSubject(this.localTmp.subject, this.localTmp)
        // this.showUpdateTmpButton = false
    }

    public selectCategoryTab(tabName: string) {
        gtagClick(`mailTab`, `${tabName}`)
        this.selectedTabName = tabName
        if ([`create_room`, `inquire_on_public_room`, `fix`, `fixed_appointment`].includes(tabName)) {
            this.showSwitchers = false
        }
        if (tabName == `settings`) {
            this.showSwitchers = true
            return
        }

        let _mfs = this.rcm.mail_flows
        let _mf = _mfs.find(_mf => _mf.def_type == tabName)
        this.rcm.selectedMailFlow = _mf
    }

    public changeActive(timing) {
        Logger(`changeActive: ${Util.output(timing)}`)
        let _mfs = this.rcm.mail_flows
        let _mf = _mfs.find(_mf => _mf.def_type == timing.type)
        if (_mf) {
            if (_mf.is_active) {
                _mf.is_active = false
            } else {
                _mf.is_active = true
            }
            this.sendTimingArray = this.fetchActiveStatus([...this.sendTimingArray])
            this.rcm.updateMailFlow(_mf)
        }
    }

    public showPreview(timing) {
        Logger(`${funcName()} timing:${Util.output(timing)}`)
        let _mfs = this.rcm.mail_flows
        if (timing.def_type == `custom`) {
            let _mf = this.rcm.findCustomMailFlow(timing)

            if (_mf) {
                this.rcm.selectedMailFlow = _mf
            }
        } else {
            let _mf = _mfs.find(_mf => _mf.def_type == timing.type)
            this.rcm.selectedMailFlow = _mf
        }

        // this.selectCategoryTab(timing.type)
    }

    public editContent(timing) {
        Logger(`${funcName()} timing:${Util.output(timing)}`)
        this.showSwitchers = false
        if (timing.def_type == `custom`) {
            let _mf = this.rcm.findCustomMailFlow(timing)
            if (_mf) {
                this.rcm.selectedMailFlow = _mf
            }
        } else {
            this.selectCategoryTab(timing.type)
        }
    }

    addCustomMailFlow() {
        Logger(`${funcName()}`)
        const available = this.rm.canUseOption(`room_custom_mail_flows`, true)
        if (!available) {
            return
        }
        let mf = MailFlow.createDefault(`custom`)
        mf.is_active = true
        mf.timing_type = `after_start`
        mf.content = `<<出席者-1-所属名>>\n<<出席者-1-氏名>>様\n\nこちらはリマインドメールです。\n内容を自由に作成できます。`
        let mfs = this.rcm.mail_flows
        mfs.push(mf)
        this.rcm.mail_flows = mfs
        this.customMailFlows = mfs
        this.rcm.selectedMailFlow = mf
        this.showSwitchers = false
    }

    removeCustomMf(mf: MailFlow) {
        Logger(`${funcName()} ${Util.output(mf)}`)
        let mfs = this.rcm.removeCustomMailFlow(mf)

        this.customMailFlows = mfs.filter(mf => MailFlow.isCustom(mf))
    }
}
