
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import AttendeesGroupFieldMenu from "packs/pages/link/parts/available_schedule/AttendeesGroupFieldMenu.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SearchCalendarsContent from "packs/pages/link/parts/available_schedule/SearchCalendarsContent.vue"

// モデルを読込.
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"
import PlanOption from "packs/models/PlanOption"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextfieldTag,
        ChipButton,
        AttendeesGroupFieldMenu,
        UserIcon,
        CheckboxTag,
        SearchCalendarsContent,
    },
})
export default class AttendeesGroupField extends Vue {
    @Prop()
    mag: MeetingAttendeesGroup

    @Prop()
    magindex: number

    @Prop() // MeetingAttendeesGroupの何番目の情報を編集中か.
    editIndex: number

    @Prop()
    openModalEditCalendarTag

    @Prop() // func
    updateAttendees

    @Prop() // normal / instant_room
    purposeType: string

    cm = CalendarManager
    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    PlanOption = PlanOption

    showSaveButton = false

    ctList: CalendarTag[] // すべてのCtags
    searchedCtags: CalendarTag[] = [] // 検索結果に表示するCtags

    showSuggests = false
    isDev = false

    selectingCtag: CalendarTag = null
    loading = false

    get attendeesName() {
        if (this.purposeType == `instant_room`) {
            return `スグINボタンで担当者を設定しているため削除できません。スグINボタン設定から削除してください。`
        }
        return (this.mag.required_attendees || []).map(e => e.name).join(`, `)
    }

    /**
     * 終日無視などの編集画面（モーダル）を表示します。
     */
    public clickSelectCtag(ctag: CalendarTag) {
        Logger(`click ctag: ${Util.output(ctag)}`)
        this.openModalEditCalendarTag(ctag)
    }

    /**
     * 選択したctagを削除します。
     */
    public deleteSelectCtag(ctag: CalendarTag) {
        Logger(`${funcName()} ctag: ${Util.output(ctag.name)}`)
        this.cm.deleteSelectedCtag(ctag)
        this.autoLoadWeeklyCalendar()
        // this.searchCalnedarValue(this.searchText)
    }

    autoLoadWeeklyCalendar() {
        Logger(`${funcName()}`)
        this.updateAttendees(this.editIndex, false)
        // autoloadのためのindexを作成.
        this.cm.autoloadWithIndexName = this.cm.magsToIndexName()
    }

    public changeConnectCalendar() {
        Logger(`connected cal.`)
        this.$emit("changeConnectCal")
    }

    /**
     * 同一グループでのメンバーを確定し、確定UIに変更します。
     */
    public fixedAttendeesInGroup() {
        Logger(`決定`)
        // let mag = this.selectedCtags;
        this.showSuggests = false
        // this.cm.astag.meeting_attendees_groups[this.editIndex] = {
        //     required_attendees: mag
        // } as MeetingAttendeesGroup;
        this.$emit("fixedAttendees")
    }

    /**
     * 編集中に変更します。
     */
    public editGroup() {
        if (this.purposeType == `instant_room`) {
            // スグINボタンの場合削除・変更できない
            return
        }
        this.$emit("editGroup", this.magindex)
    }

    /**
     * 次の「または」のフィールドを追加します。
     */
    public addNextGroupField() {
        const magIndex = this.cm.astag.meeting_attendees_groups.length
        const maxSize = (PlanOption.canUseOption(this.rm.plan_option, `calendar_condition_max_or_size`) || 15) as number
        Logger(`OR制限を確認します: ${magIndex}, maxSize:${maxSize}`)
        if (magIndex >= maxSize) {
            // ORオプション解禁していません.モーダルを表示します.
            this.$emit(`showOptionModal`)
            return
        }
        this.$emit("addNextGroupField")
    }

    // Groupごと削除します。
    public deleteGroup(mag: MeetingAttendeesGroup) {
        Logger(`attendeesGroupField: ${Util.output(mag)}`)
        this.$emit("deleteGroup", this.mag)
    }

    // 公開用プロフィールを設定します。
    public addProfile(mag: MeetingAttendeesGroup) {
        this.$emit("addProfile", mag, this.magindex)
    }

    public addZoom(mag: MeetingAttendeesGroup) {
        this.$emit("addZoom", mag, this.magindex)
    }

    get hasProfile() {
        Logger(`hasProfile: ${Util.output(this.mag.profile_image_urls)}`)
        return (
            (this.mag.profile_image_urls && this.mag.profile_image_urls[0]) ||
            (this.mag.description && this.mag.description.length > 0)
        )
    }

    public editProfile(mag: MeetingAttendeesGroup) {
        this.$emit("addProfile", mag, this.magindex)
    }

    public clickChangeDisplayMag() {
        let magName = this.mag.name
        let weekSpan = this.cm.displayWeekSpan
        let date = CalendarUtil.toTimeFromWeekSpan(`${weekSpan}`)
        let _format = CalendarUtil.toCalId(date)
        if (!this.cm.isCachedMagEvents(date, magName, weekSpan)) {
            Logger(`${funcName()} 取得処理します. weekSpan:${weekSpan}, magName:${magName}, _format:${_format}`)
            this.getMagsEventsAndPossibleDates(false, _format, date)
        } else {
            Logger(`${funcName()} 既に取得済みか候補がありませんでした.`)
            this.cm.updateMagName(magName)
        }
    }

    /***
     *
     * @param needRefresh [boolean] リフレッシュが必要か.必要な場合は、イベント情報・pdmをリセットし、eventから取得しなおします.
     * @param period [string] "202103" でその月の情報を取得してきます.
     * @param date [DateTime] その日から1ヶ月後の終了までのMAGイベント情報を取得します.
     */
    getMagsEventsAndPossibleDates(needRefresh = false, period: string = null, date: DateTime = null) {
        if (this.loading) return
        this.loading = true

        let magName = this.mag.name
        Logger(`${funcName()} magName:${magName}, needRefresh:${needRefresh}, period:${period}, date:${date?.toISO()}`)
        // 表示を初期値に戻す.
        if (needRefresh || !this.cm.isCachedMagEvents(date, magName, this.cm.displayWeekSpan)) {
            // magごとにイベントを取得します.
            if (needRefresh) {
                magName = `A`
                this.cm.updateMagName(magName)
            }

            this.cm.getMagEvents(date, magName).then(e => {
                if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
                    this.cm.gettingEvents = false
                    this.loading = false
                    return
                }
                this.cm.updateMagName(magName)

                this.cm.getPossibleDatesEvents(needRefresh, period).then(e => {
                    this.loading = false
                })
            })
        } else {
            if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
                this.cm.gettingEvents = false
                this.loading = false
                return
            }
            this.cm.updateMagName(magName)
            this.cm.getPossibleDatesEvents(needRefresh, period).then(e => {
                Logger(`pdEventsの取得処理が終わりました`)
                this.loading = false
            })
        }
    }

    get noWraitable() {
        let atts = this.mag.required_attendees
        if (this.mag.purpose_type == `instant_room`) {
            return false
        }
        if (Util.isPresent(atts)) {
            Logger(`atts: ${atts.length}, ev_owner: ${Util.output(this.mag.event_owner)}`)
            let att = atts.find(att => att.writable)
            if (Util.isBlank(att) && (Util.isBlank(this.mag.event_owner) || this.mag.event_owner.id == `newId`)) {
                return true
            }
            return false
        }
        return true
    }
}
