
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import ProgressCell from "packs/pages/link/parts/ProgressCell.vue"
import RoomHistoryDetailModal from "packs/pages/link/modals/RoomHistoryDetailModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import SubscriptionManager from "packs/models/SubscriptionManager"
import Report from "packs/models/Report"
import Const from "packs/utils/Const"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        RoomsLoader,
        ProgressCell,
        SelectTag,
        AlertBadge,
        RoomHistoryDetailModal,
    },
})
export default class Progresses extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    mem = RoomMember
    isSP = isMobile(window.navigator).phone

    subm = SubscriptionManager
    hm = RoomHistoryManager
    historyRoom: Room = null

    rooms: Room[] = null
    groupMembers: RoomMember[] = null
    membersNameArray: string[] = null // selectTagに入れるものを作成
    filterNameArray: string[] = [`すべて`, `< メール開封済み`, `< 入室済み`, `入室済み ≦ 未確定`]
    defaultSelectFilterName: string = null

    Const = Const
    activeRooms: Room[] = null
    currentUserRooms: Room[] = null
    currentRooms: Room[] = null
    defaultSelectUserName: string = null
    Util = Util
    UserPermissionsOption = UserPermissionsOption

    loading = false

    public created() {
        let tabs = this.rm.roomTabs
        if (tabs) {
            this.checkPermission()
            if (!Const.paidOverBussiness(this.rm)) {
                this.$router.push(`/`)
                return
            }
            if (this.rm.userInfo.user_group_role == Const.UserGroupRole.member) {
                this.$router.push(`/`)
                return
            }
            // ルーム情報にある進捗状況を一覧にして表示します。
            this.activeRooms = tabs[`all`].active_in_progress.rooms
            this.getUserList()
            this.getRoomsLoop()
        } else {
            this.rm.getAvailableSchedule().then(e => {
                this.checkPermission()
                if (!Const.paidOverBussiness(this.rm)) {
                    this.$router.push(`/`)
                    return
                }
                if (this.rm.userInfo.user_group_role == Const.UserGroupRole.member) {
                    this.$router.push(`/`)
                    return
                }
                this.rm.getRooms(`all`, `active_in_progress`).then(e => {
                    // ルーム情報にある進捗状況を一覧にして表示します。
                    let tab = this.rm.roomTabs[`all`].active_in_progress
                    this.activeRooms = tab.rooms
                    this.getUserList()

                    // this.rooms = Room.removeSelf(this.rm.rooms, this.rm.userInfo)
                    this.getRoomsLoop()
                })
            })
        }
    }

    public getRoomsLoop() {
        this.rm.getRooms(`all`, `active_in_progress`).then(e => {
            if (e) {
                let tab = this.rm.roomTabs[`all`].active_in_progress
                this.activeRooms = tab.rooms
                this.getRoomsLoop()
            }
        })
    }

    public mounted() {
        gtagPage(`#/progresses`)
    }

    @Watch("activeRooms", { deep: true })
    updateActiveRooms() {
        if (!this.groupMembers) return
        this.defaultSelectUserName = roomStorage.fetchCurrentProgressUserName()
        this.defaultSelectFilterName = roomStorage.fetchCurrentProgressFilterName() || `すべて`
        this.selectMember(this.defaultSelectUserName || `すべて`)
    }

    /**
     * 同一UGのメンバーを取得してきます。
     */
    public getUserList() {
        let userName = roomStorage.fetchCurrentProgressUserName()
        this.defaultSelectUserName = userName
        // this.defaultSelectFilterName = `すべて`;
        this.defaultSelectFilterName = roomStorage.fetchCurrentProgressFilterName() || `すべて`

        RoomMember.getUserList().then(list => {
            this.groupMembers = list
            let arr = this.groupMembers.filter(m => {
                if (m && m.name && m.name.length > 0) {
                    return m.name
                }
            })
            let names = arr.map(m => m.name)
            names.unshift(`すべて`)
            this.membersNameArray = names
            this.selectMember(userName || `すべて`)
        })
    }

    /**
     * メンバーを選択して、表示を変更します。
     */
    selectMember(e) {
        Logger(`selectMember ${e}`)
        if (e == `すべて`) {
            this.currentUserRooms = this.activeRooms && this.activeRooms.length > 0 ? [...this.activeRooms] : []
            this.selectFilter(this.defaultSelectFilterName)
        } else {
            if (!e || e.length == 0) return this.selectMember(`すべて`)

            let mem = this.groupMembers.find(m => m.name == e)
            if (mem) {
                this.currentUserRooms = this.activeRooms.filter(r => r.owner.user_id == mem.user_id)
                this.selectFilter(this.defaultSelectFilterName)
            } else {
                this.selectMember(`すべて`)
            }
        }
        roomStorage.saveCurrentProgressUserName(e)
    }

    selectFilter(e) {
        Logger(`selectFilter ${e}`)
        if (e == `すべて`) {
            this.currentRooms = [...this.currentUserRooms]
        } else {
            if (!e || e.length == 0) return this.selectMember(`すべて`)

            /**
             * `すべて`,
                `< メール開封済み`,
                `< 入室済み`,
                `入室済み ≦ 未確定`,
             */

            if (e == `< メール開封済み`) {
                this.currentRooms = this.currentUserRooms.filter(r => {
                    if (r.status == 101) {
                        let histLarges = Object.values(r.progress_member_dic)
                        // const invs = r.invitations || []
                        if (
                            histLarges.some(
                                histLarge => histLarge.mail && histLarge.mail.create_room && !histLarge.mail.create_room.open
                            )
                        )
                            return r
                    }
                })
            } else if (e == `< 入室済み`) {
                this.currentRooms = this.currentUserRooms.filter(r => {
                    if (r.status == 101) {
                        let histLarges = Object.values(r.progress_member_dic)
                        // const invs = r.invitations || []
                        if (histLarges.some(histLarge => histLarge && histLarge.room && !histLarge.room.enter)) return r
                    }
                })
            } else if (e == `入室済み ≦ 未確定`) {
                this.currentRooms = this.currentUserRooms.filter(r => {
                    if (r.status == 101) {
                        let histLarges = Object.values(r.progress_member_dic)
                        // const invs = r.invitations || []
                        if (histLarges.some(histLarge => histLarge.room && histLarge.room.enter && !histLarge.schedule.share))
                            return r
                    }
                })
            } else {
                this.selectMember(`すべて`)
            }
        }
        this.defaultSelectFilterName = e
        roomStorage.saveCurrentProgressFilterName(e)
    }

    public showHistory(room: Room) {
        this.rm.startProgress()
        if (this.loading) return
        this.loading = true
        this.hm.resetData()
        this.hm.getHistory(room, false).then(hists => {
            this.historyRoom = room
            this.$vfm.open("RoomHistoryDetailModal")
            this.rm.endProgress()
            this.loading = false
        })
    }

    checkPermission() {
        if (UserPermissionsOption.permitDisplay(this.rm.userInfo, `reports`, `progresses`)) {
            return
        }
        if (UserPermissionsOption.permitDisplay(this.rm.userInfo, `reports`, `reports`)) {
            this.$router.push(`/reports`)
            return
        }

        this.$router.push(`/`)
    }
}
