
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, getLang, funcName } from "packs/common"
import { gtagClick, gtagEventOwner, gtagPage, gtagPageOwner } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded"

// コンポーネントを読込.
import SelectableGroups from "packs/pages/link/parts/SelectableGroups.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Const from "packs/utils/Const"
import HeaderControl from "packs/utils/HeaderControl"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import LocationTag from "packs/models/LocationTag"
import Timezones from "packs/utils/Timezones"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

@Options({
    components: {
        SelectableGroups,
        CopyButton,
    },
})
export default class ZoomMeetingContent extends Vue {
    // data:
    @Prop()
    appo: Appointment

    @Prop()
    room: Room

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    astag: AvailableScheduleTag = null
    href
    currentPossibleDates = null
    Util = Util
    Appointment = Appointment
    timezones = null
    pdm = PossibleDatesManager
    truncate = truncate

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.

    client = ZoomMtgEmbedded.createClient()
    authEndpoint = ""
    sdkKey = "711vwqfwVH4xyhuYGBV33wyM3gV5Bvpi"
    meetingNumber = "123456789"
    passWord = ""
    role = 0
    userName = "Vue.js"
    userEmail = ""
    registrantToken = ""
    zakToken = ""
    leaveUrl = "http://localhost:5173/"

    public mounted() {
        Logger(`${funcName()} started.`)
        this.getSignature()
    }

    getSignature() {
        fetch(this.authEndpoint, {
            method: "POST",
            body: JSON.stringify({
                meetingNumber: this.meetingNumber,
                role: this.role,
            }),
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data)
                this.startMeeting(data.signature)
            })
            .catch(error => {
                console.log(error)
            })
    }

    startMeeting(signature) {
        let meetingSDKElement = document.getElementById("meetingSDKElement")

        this.client
            .init({ zoomAppRoot: meetingSDKElement, language: "jp-JP", patchJsMedia: true })
            .then(() => {
                this.client
                    .join({
                        signature: signature,
                        sdkKey: this.sdkKey,
                        meetingNumber: this.meetingNumber,
                        password: this.passWord,
                        userName: this.userName,
                        userEmail: this.userEmail,
                        tk: this.registrantToken,
                        zak: this.zakToken,
                    })
                    .then(() => {
                        console.log("joined successfully")
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }
}
