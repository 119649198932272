
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import { DateTime, Interval } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel"

// モデルを読込.
import Util from "packs/utils/Util"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Const from "packs/utils/Const"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: { Carousel, Slide, Pagination, Navigation },
})
export default class CalendarTable extends Vue {
    @Prop()
    monthlyPossibleDates: string[] //[[22, 23],[]] 今月と翌月の候補がある日にち(number)が入っている.

    @Prop()
    idprefix: string //スクロールしてペアリングさせるためのIDを指定します。

    @Ref()
    CalendarSlider

    // data
    thisMonth = []
    // nextMonth = [] // 翌月
    // monthPlus2 = [] // 翌々月
    // monthPlus3 = [] // 翌翌々月
    // monthPlus4 = [] // 翌翌翌々月
    DateTime = DateTime
    isSP = isMobile(window.navigator).phone
    isNarrowType = false
    pdm = PossibleDatesManager
    rm = RoomManager
    today = DateTime.local().day as number
    startMonth = DateTime.local().month as number

    calendarCellWidth = this.isSP ? { width: `14%`, padding: `3px` } : { width: `52px` }
    displayCalSize = this.isSP
        ? { width: `100%`, height: `10vw`, lineHeight: `10vw`, maxWidth: `45px`, maxHeight: `40px` }
        : { width: `45px`, height: `43px`, lineHeight: `43px` }
    windowWidth = 0
    displayedCalendarDate = DateTime.local()
    displayedCalendarId = `${this.displayedCalendarDate.toFormat(`yyyyMM`)}`
    weekArray = null
    Util = Util
    isPreview = Util.isPreview()
    visibleMonths = []

    created() {
        this.weekArray = [
            this.$t(`calendar.sun`),
            this.$t(`calendar.mon`),
            this.$t(`calendar.tue`),
            this.$t(`calendar.wed`),
            this.$t(`calendar.thu`),
            this.$t(`calendar.fri`),
            this.$t(`calendar.sat`),
        ]
        // this.CalendarSlider.updateSlideWidth()
    }

    CalendarMonthTitle(monthindex) {
        this.calendarContentStyle()
        return DateTime.local()
            .plus({
                month: monthindex,
            })
            .toFormat(this.$t(`calendar.format_title`))
    }

    get calendarWidth() {
        let defaultWidth = `width: 350px;`
        if (Util.isEmbeds()) {
            if (this.windowWidth >= 1000) {
                return defaultWidth
            }

            let width = this.windowWidth - 100
            return `width: ${width}px;`
        }

        if (this.isSP) {
            return `width: 100%;`
        }

        return defaultWidth
    }

    calendarContentStyle() {
        let style = ``
        if (this.isSP) {
            if (Util.isEmbeds()) {
                let minWidth = ``
                let width = ``
                if (this.windowWidth - 100 <= 380) {
                    minWidth = `min-width:${this.windowWidth - 40}px;`
                }
                if (this.windowWidth < 1000) {
                    width = this.calendarWidth
                } else {
                    width = `width:${this.windowWidth - 50}px;`
                }

                style = `${width};${minWidth};display:inline-block;${this.windowWidth < 1000 ? `max-width:700px;` : ``}`
                // style = `${this.calendarWidth}display: inline-block;`
            } else {
                style = `width:${this.windowWidth - 40}px;display:inline-block;`
            }
        } else {
            let innerWidth = $(`.smart_candi_calendar`).innerWidth()
            Logger(`isNarrowType:${this.isNarrowType}, innerWidth:${innerWidth}px, windowWidth${this.windowWidth}px`)
            if (this.isNarrowType) {
                if (Util.isEmbeds()) {
                    let minWidth = ``
                    let width = ``
                    if (this.windowWidth - 100 <= 380) {
                        minWidth = `min-width:420px;`
                    }
                    if (this.windowWidth < 1000) {
                        if (innerWidth) {
                            // innerWidth=700, windowWidth=999
                            if (innerWidth - 100 >= this.windowWidth) {
                                width = this.calendarWidth
                            } else {
                                width = `width:${innerWidth}px;`
                            }
                        } else {
                            width = this.calendarWidth
                        }
                    } else {
                        width = `width:${this.windowWidth - 50}px;`
                    }

                    // width = this.calendarWidth

                    // style = `${width}display: inline-block;${minWidth}`
                    // style = `${width};${minWidth};display:inline-block;${this.windowWidth < 1000 ? `max-width:700px;` : ``}`
                } else {
                    style = `width:${
                        this.windowWidth < 1000 ? innerWidth : this.windowWidth - 50
                    }px;min-width:500px;display:inline-block;`
                }
                style += `--slides-to-show: 1;`
            } else {
                style = `${this.calendarWidth}`
                style += `--slides-to-show: 1;`
            }
        }

        return style
    }

    public mounted() {
        // 日付を当てはめます。
        let thisStartBefore = Array(DateTime.local().startOf("month").weekday).fill(-1)
        let nextStartBefore1 = Array(DateTime.local().plus({ month: 1 }).startOf("month").weekday).fill(-1)
        // let nextStartBefore2 = Array(DateTime.local().plus({ month: 2 }).startOf("month").weekday).fill(-1)
        // let nextStartBefore3 = Array(DateTime.local().plus({ month: 3 }).startOf("month").weekday).fill(-1)
        // let nextStartBefore4 = Array(DateTime.local().plus({ month: 4 }).startOf("month").weekday).fill(-1)

        Array.prototype.push.apply(thisStartBefore, Util.generateMonthArray(0))
        Array.prototype.push.apply(nextStartBefore1, Util.generateMonthArray(1))
        // Array.prototype.push.apply(nextStartBefore2, Util.generateMonthArray(2))
        // Array.prototype.push.apply(nextStartBefore3, Util.nextMonthArray3)
        // Array.prototype.push.apply(nextStartBefore4, Util.nextMonthArray4)

        this.thisMonth = thisStartBefore
        // this.nextMonth = nextStartBefore1
        // this.monthPlus2 = nextStartBefore2
        // this.monthPlus3 = nextStartBefore3
        // this.monthPlus4 = nextStartBefore4

        // this.visibleMonths = [thisStartBefore, nextStartBefore1, nextStartBefore2, nextStartBefore3, nextStartBefore4]
        this.visibleMonths = [thisStartBefore, nextStartBefore1]

        Logger(
            `${funcName()} calendar 初日: startMonth:${this.startMonth}, 開始月Array: ${this.thisMonth}, pdm.currentDateId:${
                this.pdm?.currentDateId
            }, pdm.currentDate:${this.pdm?.currentDate}, pdm.currentCalendarDate:${this.pdm?.currentCalendarDate?.date_format}`
        )
        // スライドできるカレンダーを作成します。
        // useSlickSlider(".calendarTimeSpan", 1);
        // if (this.pdm?.currentDateId) {
        //     this.clickFirstDay(this.pdm.currentDateId)
        // }
        // // else if (this.pdm?.currentDate) {
        // //     this.clickFirstDay(this.pdm?.currentDate?.date_format)
        // // }
        // else {
        //     this.clickFirstDay()
        // }
        this.updateCurrentDate()

        this.calculateWindowWidth()
        window.addEventListener("resize", this.calculateWindowWidth)
        this.updateMonthlyPossibleDates()
    }

    @Watch(`pdm.currentCalendarDate`, { deep: true })
    updateCurrentDate() {
        Logger(
            `${funcName()} pdm.currentDate:${this.pdm.currentDate}, pdm.currentCalendarDate:${
                this.pdm.currentCalendarDate?.date_format
            }`
        )
        if (!this.pdm.currentCalendarDate) return
        let _pd = this.pdm.displayPds[0] as PossibleDate
        if (!_pd) return

        let _date
        if (this.pdm.currentTZDic) {
            let offsetStr = PossibleDate.toTimezoneUTCFormat(this.pdm.currentTZDic.diff)
            _date = DateTime.fromSeconds(_pd.start_time).setZone(`UTC${offsetStr}`)
        } else {
            _date = DateTime.fromSeconds(_pd.start_time)
        }

        Logger(`${funcName()} _date:${_date.toFormat(`MM/dd HH:mm`)}, this.startMonth:${this.startMonth}, tz:${_date.zoneName}`)
        // let monthIndex = _date.month - this.startMonth
        // 確認できていないので、年末対応
        // monthIndex = monthIndex < 0 ? monthIndex + 12 : monthIndex
        if (this.pdm?.currentDateId) {
            this.clickFirstDay(this.pdm.currentDateId)
        }

        // this.changeSelectDay(monthIndex, _date.day)
    }

    public changeSelectDay(monthindex, day) {
        Logger(`${funcName()} monthindex:${monthindex}, day:${day}`)
        $(".displayCal").removeClass("selected")
        sleep(100).then(_ => {
            $(`.displayCal${monthindex}_${day} p.displayCal`).addClass("selected")
        })
    }

    // カレンダーの表示しているセルをクリックしたときにedgeクラスを付与して色を変えてわかりやすくします。
    public clickDisplayCal(monthindex: number, day) {
        if (!$(`.displayCal${monthindex}_${day} p`).hasClass(`hasDays`)) {
            return
        }
        // const className = e.target.className;
        Logger(`${funcName()} monthindex:${monthindex}, day:${day}`)
        this.changeSelectDay(monthindex, day)

        let month = DateTime.local().month + monthindex
        month = month > 12 ? month - 12 : month
        let monthStr = ("0" + month).slice(-2)
        let dayStr = ("0" + day).slice(-2)
        let idName = `${monthStr}${dayStr}`
        Logger(`${funcName()} idName:date${idName}, idprefix:${this.idprefix}`)
        if (this.monthlyPossibleDates && this.monthlyPossibleDates[monthindex].includes(day)) {
            this.pdm.selectDate(idName)
            this.$emit(`selectDateFromCal`, idName)
            if (this.isSP || this.rm.designType() == `seminar`) {
                // if (this.idprefix == `top`) {
                //     $("html,body").animate(
                //         {
                //             scrollTop: $("#CandidatesContentOverwrap").offset().top - 200,
                //         },
                //         `fast`
                //     )
                // } else {
                let idprefixName = `${this.idprefix}dateheader`
                document.getElementById(idprefixName).scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                })
                // }
            }
        }
    }

    /**
     * 可能日程の初日を選択し、そこまでスクロールします.
     * @param calendarDateId 指定がある場合、その日程まで移動.
     */
    clickFirstDay(calendarDateId: string = null) {
        Logger(`${funcName()} calendarDateId:${calendarDateId}, monthlyPossibleDates:${this.monthlyPossibleDates?.length}`)
        if (!this.monthlyPossibleDates) return

        this.startMonth
        let monthindex = 0
        let dateNum
        if (calendarDateId) {
            monthindex = Number(calendarDateId.slice(0, 2)) - this.startMonth
            if (monthindex < 0) {
                monthindex += 12
            }
            let willCreateMonthLength = monthindex - this.visibleMonths.length + 1
            if (willCreateMonthLength > 0) {
                for (let i = 0; i < willCreateMonthLength; i++) {
                    this.addNextMonth()
                }
            }
            dateNum = Number(calendarDateId.slice(2, 4))
        } else {
            const dateArr = this.monthlyPossibleDates[monthindex]
            if (Util.isPresent(dateArr)) {
                dateNum = dateArr[0]
            }
        }
        Logger(`${funcName()} monthindex:${monthindex}, dateNum:${dateNum}`)

        if (monthindex >= 0 && dateNum) {
            this.$nextTick(() => {
                this.CalendarSlider.slideTo(monthindex)
                this.changeSelectDay(monthindex, dateNum)
                this.displayedCalendarDate = DateTime.local().plus({ month: monthindex })
                this.displayedCalendarId = this.toCalId()
            })
        }
    }

    calculateWindowWidth() {
        this.windowWidth = window.innerWidth
        Logger(`${funcName()} ${this.windowWidth}`)
        if (this.windowWidth < Const.spWidth) {
            // this.isSP = true
            this.isNarrowType = true
        } else {
            this.isNarrowType = false
            // this.isSP = isMobile(window.navigator).phone
        }
        // this.CalendarSlider.updateSlideWidth()
        // $(`.carousel__track`).css({ width: `${this.windowWidth * 5}px` })

        // let slideDic = this.CalendarSlider.data
        // slideDic.slideWidth.value = this.windowWidth - 100
        // this.CalendarSlider.updateSlideWidth(350)
        // this.CalendarSlider.initDefaultConfigs()
        // this.CalendarSlider.updateSlideWidth((this.windowWidth - 100) * 5)
        // $(`.carousel__track`).css({ width: `${350 * 5}px !important` })

        // Logger(`${funcName()} slideDic:${Util.output(slideDic)}`)
    }

    showPrevButton() {
        const firstCalId = `${DateTime.local().toFormat(`yyyyMM`)}`
        if (this.displayedCalendarId == firstCalId) {
            return false
        }
        return true
    }

    showNextButton() {
        if (!this.pdm) return
        if (!this.pdm.available_months) return
        let avMonths = this.pdm.available_months as string[]
        Logger(`${funcName()} avMonths:${Util.output(avMonths)}, displayedCalendarId:${this.displayedCalendarId}`)

        if (+this.displayedCalendarId >= +avMonths[avMonths.length - 1]) {
            return false
        }
        return true
    }

    toCalId(): string {
        return this.displayedCalendarDate.toFormat(`yyyyMM`)
    }

    clickPrevMonth() {
        this.CalendarSlider.prev()
        let slideNum = this.CalendarSlider.data.currentSlide.value
        this.displayedCalendarDate = DateTime.local().plus({ month: slideNum })
        this.displayedCalendarId = this.toCalId()
        Logger(`${funcName()} 前の月へ: ${this.displayedCalendarId}, slideNum: ${slideNum}`)

        this.$emit(`clickPrevMonth`, this.displayedCalendarId)
    }

    clickNextMonth() {
        // this.CalendarSlider.next()
        let slideNum = this.CalendarSlider.data.currentSlide.value
        let nextSlideNum = slideNum + 1
        // this.displayedCalendarDate = DateTime.local().plus({ month: slideNum })
        // this.displayedCalendarId = this.toCalId()
        Logger(`${funcName()} 次の月へ: ${this.displayedCalendarId}, slideNum: ${slideNum}`)
        if (nextSlideNum >= this.visibleMonths.length) {
            this.addNextMonth()
        }

        this.$nextTick(() => {
            this.CalendarSlider.next()
            this.displayedCalendarDate = DateTime.local().plus({ month: nextSlideNum })
            this.displayedCalendarId = this.toCalId()
            Logger(`${funcName()} 次の月へ: ${this.displayedCalendarId}, slideNum: ${nextSlideNum}`)
            this.$emit(`clickNextMonth`, this.displayedCalendarId)
        })
        // this.$emit(`clickNextMonth`, this.displayedCalendarId)
    }

    addNextMonth() {
        const lastMonthIndex = this.visibleMonths.length - 1
        const nextMonthIndex = lastMonthIndex + 1
        Logger(`${funcName()} 次の月を追加します. lastMonthIndex:${lastMonthIndex}, nextMonthIndex:${nextMonthIndex}`)
        const nextMonth = this.generateMonthDays(nextMonthIndex)
        this.visibleMonths.push(nextMonth)
    }

    generateMonthDays(monthIndex) {
        const startBefore = Array(DateTime.local().plus({ month: monthIndex }).startOf("month").weekday).fill(-1)
        const monthArray = Util.generateMonthArray(monthIndex)
        Array.prototype.push.apply(startBefore, monthArray)
        return startBefore
    }

    @Watch(`monthlyPossibleDates`)
    updateMonthlyPossibleDates() {
        // Logger(`${funcName()} monthlyPossibleDatesに更新がありました.`)
        this.calculateWindowWidth()
    }

    dateFormat(monthindex, day) {
        let month = DateTime.local().month + monthindex
        month = month > 12 ? month - 12 : month
        let monthStr = ("0" + month).slice(-2)
        let dayStr = ("0" + day).slice(-2)
        let idName = `${monthStr}${dayStr}`
        return idName
    }
}
