
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep, round } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { GChart } from "vue-google-charts"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import { Dropdown } from "uiv"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import VotedRoomManager from "packs/models/VotedRoomManager"
import DailyStat from "packs/models/DailyStat"
import FileUtil from "packs/utils/FileUtil"

const roomStorage = new RoomStorage()

@Options({
    components: {
        GChart,
        Dropdown,
        RoomsLoader,
    },
})
export default class StatsContent extends Vue {
    // data:

    @Prop()
    room: Room

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    mem = RoomMember
    isSP = isMobile(window.navigator).phone

    loading = false
    loadingAccessKeywordsStats = false
    Util = Util

    pdm = PossibleDatesManager
    isPubPage = Util.isPubSummary()

    dailyStats: DailyStat = null
    accessKeywordsStats: DailyStat = null
    chartStats = null

    defaultStart = DateTime.local().minus({ days: 30 })
    defaultEnd = DateTime.local()

    startDate = DateTime.fromSeconds(this.defaultStart.toSeconds()).toFormat("yyyy/MM/dd")
    endDate = DateTime.fromSeconds(this.defaultEnd.toSeconds()).toFormat("yyyy/MM/dd")

    accessKeywordsStartDate = DateTime.fromSeconds(this.defaultStart.toSeconds()).toFormat("yyyy/MM/dd")
    accessKeywordsEndDate = DateTime.fromSeconds(this.defaultEnd.toSeconds()).toFormat("yyyy/MM/dd")
    showAccessKeywordsStats = false

    chartOptions = {
        chartArea: { height: `230` },
        curveType: "function",
        responsive: true,
        focusTarget: `category`,
        aggregationTarget: "category",
        vAxis: {
            viewWindowMode: "explicit",
            viewWindow: {
                min: 0,
            },
            format: `short`,
        },
        hAxis: { maxValue: 5 },
        fontSize: 12,
        colors: ["#ffcf91", "#fecfcf", "#2c96ff", "#69d54f"],
    }

    totalDeviceNum = 0
    totalFixedDeviceNum = 0
    round = round
    canDownloadButton = false

    public mounted() {
        // this.room = this.rm.currentRoom;
        if (this.isPubPage) this.updateRoom()
    }

    @Watch(`room`, { deep: true })
    updateRoom() {
        if (!this.room || Util.isBlank(this.room.access_stats)) return
        if (this.dailyStats) return

        let dailyStats = { ...this.room.access_stats }
        if (dailyStats.get_type == "total_pv") {
            this.checkAndRequest()
            return
        }

        this.dailyStats = dailyStats
        let dailyDic = this.dailyStats.daily_dic
        this.totalDeviceNum = (this.room.access_stats.total_pc_num || 0) + (this.room.access_stats.total_sp_num || 0)
        this.totalFixedDeviceNum =
            (this.room.access_stats.total_fixed_pc_num || 0) + (this.room.access_stats.total_fixed_sp_num || 0)

        if (this.isPubPage) this.createChart(dailyDic)
    }

    public createChart(dailyDic) {
        let days = [[`Date`, `PV`, `アクティブユーザー数`, `お問い合わせ数`, `確定数`]]
        Object.entries(dailyDic).forEach(([date, _dic]) => {
            let dic = _dic as any
            days.push([dic.jp_format, dic.page_view || 0, dic.access_user_num || 0, dic.start || 0, dic.fixed || 0])
        })
        Logger(`チャートを作成: ${Util.output(days)}`)
        this.chartStats = days
        this.canDownloadButton = true
    }

    get showDeviceRasioContent() {
        if (!this.room) return false
        if (this.room.access_stats && this.room.access_stats.total_pc_num) {
            return true
        }
        return false
    }

    changeTimeRange() {
        this.checkAndRequest()
    }

    changeTimeRangeAccessKeywords() {
        this.requestAccessKeywordsStats()
    }

    clickAccessKeywordsStats() {
        Logger(`${funcName()}`)
        if (!this.startDate || !this.endDate) return
        Logger(`${funcName()} startDate:${this.startDate}, endDate:${this.endDate}`)

        this.showAccessKeywordsStats = true
        this.requestAccessKeywordsStats()
    }

    requestAccessKeywordsStats() {
        Logger(`${funcName()}`)
        if (!this.accessKeywordsStartDate || !this.accessKeywordsEndDate) return
        Logger(`${funcName()} startDate:${this.accessKeywordsStartDate}, endDate:${this.accessKeywordsEndDate}`)
        let st = DateTime.fromFormat(this.accessKeywordsStartDate, `yyyy/MM/dd`).toSeconds()
        let et = DateTime.fromFormat(this.accessKeywordsEndDate, `yyyy/MM/dd`).toSeconds()
        this.loadingAccessKeywordsStats = true

        DailyStat.getAccessKeywordsStats([this.room.id], st, et).then(res => {
            Logger(`requestAccessKeywordsStats res:${Util.output(res)}`)
            this.accessKeywordsStats = res
            this.loadingAccessKeywordsStats = false
        })
    }

    checkAndRequest() {
        if (!this.startDate || !this.endDate) return
        Logger(`${funcName()} startDate:${this.startDate}, endDate:${this.endDate}`)
        let st = DateTime.fromFormat(this.startDate, `yyyy/MM/dd`).toSeconds()
        let et = DateTime.fromFormat(this.endDate, `yyyy/MM/dd`).toSeconds()

        if (st > et) {
            this.notice.message = `開始日が終了日よりも後になっています。`
            return
        }
        if (this.loading) return
        this.loading = true
        this.canDownloadButton = false

        DailyStat.getStats([this.room.id], st, et).then(res => {
            this.loading = false
            if (res && res[this.room.id]) {
                let stats = res[this.room.id]
                Logger(`stats:${Util.output(stats)}`)

                this.dailyStats = stats
                this.totalDeviceNum = (this.dailyStats.total_pc_num || 0) + (this.dailyStats.total_sp_num || 0)
                this.totalFixedDeviceNum = (this.dailyStats.total_fixed_pc_num || 0) + (this.dailyStats.total_fixed_sp_num || 0)
                this.chartStats = null
                this.createChart(this.dailyStats.daily_dic)
            }
        })
    }

    downloadStats() {
        Logger(`${funcName()}`)
        let csvHeader = [
            `日付`,
            `総アクセス数`,
            `総確定数`,
            `PCアクセス割合(%)`,
            `スマートフォンアクセス割合(%)`,
            `PC確定割合(%)`,
            `スマートフォン確定割合(%)`,
        ]

        let csvContent: any[] = [csvHeader]

        // 二次元マトリックスでシート上にそれぞれ入力していきます.（横軸： 日程、可能人数 + 投票者の数  縦軸: フォーム設問数）
        let csvForm = new Array(Object.entries(this.dailyStats.daily_dic).length)

        let ansIndex = 0

        Object.entries(this.dailyStats.daily_dic).forEach(([datestring, _statDic]) => {
            let statDic = _statDic as any

            let pc_num = statDic.pv_pc_num || 0
            let sp_num = statDic.pv_sp_num || 0
            let fixed_pc_num = statDic.fixed_pc_num || 0
            let fixed_sp_num = statDic.fixed_sp_num || 0
            let pv_rasio_deno = pc_num + sp_num
            let fixed_ratio_deno = fixed_pc_num + fixed_sp_num
            let pv_pc_rasio = pv_rasio_deno == 0 ? `-` : `${round((pc_num * 100) / pv_rasio_deno, 2)}%`
            let pv_sp_rasio = pv_rasio_deno == 0 ? `-` : `${round((sp_num * 100) / pv_rasio_deno, 2)}%`
            let fixed_pc_rasio = fixed_ratio_deno == 0 ? `-` : `${round((fixed_pc_num * 100) / fixed_ratio_deno, 2)}%`
            let fixed_sp_rasio = fixed_ratio_deno == 0 ? `-` : `${round((fixed_sp_num * 100) / fixed_ratio_deno, 2)}%`

            csvForm[ansIndex] = [
                statDic.jp_format,
                statDic.page_view,
                statDic.fixed,
                pv_pc_rasio,
                pv_sp_rasio,
                fixed_pc_rasio,
                fixed_sp_rasio,
            ]

            ansIndex++
        })

        Logger(`csvContent:${Util.output(csvContent)}, csvForm:${Util.output(csvForm)}`)

        Array.prototype.push.apply(csvContent, csvForm)

        let bom = new Uint8Array([0xef, 0xbb, 0xbf])
        let blobdata = csvContent.map(c => c.join(`,`)).join(`\r\n`)
        let blob = new Blob([bom, blobdata], { type: "text/csv" })
        FileUtil.download(blob, `waaqlink_${this.room.public_id}_${this.startDate}_${this.endDate}.csv`)
    }
}
