
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import { Logger, onlyUnique } from "packs/common"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"

@Options({
    components: {
        TextfieldTag,
        CheckboxTag,
        MdTextfieldTag,
    },
})
export default class AttendeeForm extends Vue {
    @Prop()
    action: string // addnewAppo, editAppo
    @Prop()
    currentMember: RoomMember
    @Prop()
    type: string // horizontal（横に並ぶように）, vertical（垂直型・デフォ）

    // data
    isSP = isMobile(window.navigator).phone
    Util = Util

    changedSomeStatus = false
    isActive = true

    rm = RoomManager
    Appointment
    openAddressBook = false
    // _room: Room = null;

    member: RoomMember = null
    failedMail = false // Emailが正しいか.

    @Emit()
    hide(didChange: boolean, appo: null) {}

    // @Emit("update")
    public addAttendee() {
        if (!this.member.email) {
            Notice.message = "メールアドレスを入力してください。"
            return false
        }
        if (!Util.validateEmail(this.member.email)) {
            Notice.message = `正しいメールアドレスを入力してください。`
            return false
        }
        if (!this.member.name) {
            Notice.message = "氏名を入力してください。"
            return false
        }
        this.$emit("update", this.member)
        this.member = RoomMember.createDefaultClient()
        // return this.member;
    }

    public created() {
        // this._room = Room.createDefaultRoom();
        this.updateMember()
        if (!this.isSP) {
            this.openAddressBook = true
        }
    }

    @Watch("currentMember", { deep: true })
    public updateMember() {
        if (this.currentMember) {
            this.member = { ...this.currentMember } as RoomMember
        } else {
            this.member = RoomMember.createDefaultClient()
        }
        Logger(`updateMemberしました。`)
    }

    public inputClientName(e) {
        this.member.name = e.trim()
    }

    public inputCompanyName(e) {
        this.member.company_name = e.trim()
    }

    public inputClientShortName(e) {
        this.member.short_name = e.trim()
    }

    public inputClientAddress(e) {
        this.member.email = e.trim()
        // const user = this.invitedUsers.find(user => user.email == e);
        // if (user) {
        //     this.member = { ...user };
        // }
    }

    public inputPrivateMemo(e) {
        this.member.private_memo = e.trim()
    }

    public checkEmailField() {
        let validate = Util.validateEmail(this.member.email)
        // Logger(`emailフィールドを検証します: ${this.member.email} ${validate}`);
        if (!validate) {
            this.failedMail = true
        } else {
            this.failedMail = false
        }
    }

    // public addAttendee() {
    //     this.update(this.member);
    // }

    // public isEditPage(): boolean {
    //     // 編集画面の場合、日程調整情報にデフォルト入力があります。
    //     if (this.action == "edit") {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // public hideAvailableTimeModal(dtag) {
    //     // this.$vfm.close("AvailableTimeModal", dtag);
    //     this.hideAvailableTimeModal(this.changedSomeStatus, dtag);
    //     // this.$emit("hideAvailableTimeModal", this.changedSomeStatus, dtag);
    // }
}
