
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Appointment from "packs/models/Appointment"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        RadioButtonTag,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class FixLoadingModal extends Vue {
    // data:

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    text = ``
    action = `reschedule`
    loading = true

    public created() {}

    public updated() {}

    public async mounted() {}
}
