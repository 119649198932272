
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import CalendarUtil from "packs/utils/CalendarUtil"
import UserSetting from "packs/models/UserSetting"
import AppointmentForm from "packs/models/AppointmentForm"
import FormStorage from "packs/models/FormStorage"

// const roomStorage = new RoomStorage();
const formStorage = new FormStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        CheckboxTag,
        SelectTag,
        MdTextfieldTag,
        ScheduleViewLoader,
    },
})
export default class Forms extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    util = Util
    CalendarUtil = CalendarUtil

    selectedTab = `forms`

    isSP = isMobile(window.navigator).phone
    // users: RoomMember[] = [];

    loading = false
    userInfo = null
    form: AppointmentForm = null

    newUrl = ``

    @Ref()
    header

    created() {
        let meetingTimeArr = CalendarUtil.selectDisplayMeetingTimeArr
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {})
        } else {
        }
    }

    mounted() {
        gtagPage("#/settings/schedule")
    }

    inputUrl(e) {
        this.newUrl = e
    }

    loadForm() {
        Logger(`formを取得してきます。 ${this.newUrl}`)

        if (this.loading) return
        this.loading = true
        AppointmentForm.getForm(this.newUrl).then(form => {
            Logger(``)
            // this.form = form
            formStorage.saveForm(form)
            this.$router.push("/form_settings")
            this.loading = false
        })
    }
}
