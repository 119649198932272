
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import ImageBoardModal from "packs/pages/link/modals/ImageBoardModal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomSetting from "packs/models/RoomSetting"
import UserFile from "packs/models/UserFile"

@Options({
    components: {
        TextareaTag,
        TextfieldTag,
        SelectTag,
        CheckboxTag,
        ImageBoardModal,
    },
})
export default class ThanksPageEditContent extends Vue {
    // data:

    @Prop()
    room: Room

    @Prop() // func
    updateRoomSetting

    rm = RoomManager

    isSP = isMobile(window.navigator).phone

    Util = Util
    SelectUtil = SelectUtil
    RoomSetting = RoomSetting
    rs: RoomSetting = null

    contentWidth = 350

    systemUpdatedAt = Util.getSec()

    currentType = null
    isOpenUrlLink = false

    public mounted() {
        this.updateRoom()
        this.currentType = RoomSetting.hasRedirectUrl(this.room) ? `redirect_url` : `complete`

        if (this.rs && this.rs.thanks_page) {
            let tp = this.rs.thanks_page
            if (Util.isPresent(tp.button_link) && Util.isPresent(tp.button_name)) {
                this.isOpenUrlLink = true
            }
        }
    }

    @Watch(`room`, { deep: true })
    updateRoom() {
        if (!this.room) return
        if (!this.room.room_setting) return
        this.rs = { ...RoomSetting.findOrCreateSetting(this.room) }
        Logger(`thanksPageEditContent:Setting:: ${Util.output(this.rs)}`)
        this.currentType = RoomSetting.hasRedirectUrl(this.room) ? `redirect_url` : `complete`
    }

    inputThanksPageTitle(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)
        Logger(`settingを見ます: ${Util.output(this.rs)}`)
        this.rs.thanks_page.title = e
        this.updateRoomSetting(this.rs)
    }

    inputThanksPageContent(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)
        this.rs.thanks_page.content = e
        this.updateRoomSetting(this.rs)
    }

    inputRedirectUrl(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)
        this.rs.thanks_page.redirect_url_after_send = e
        this.updateRoomSetting(this.rs)
    }

    inputThanksPageButtonName(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)
        this.rs.thanks_page.button_name = e
        this.updateRoomSetting(this.rs)
    }

    inputThanksPageButtonLink(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)
        this.rs.thanks_page.button_link = e
        this.updateRoomSetting(this.rs)
    }

    getDefaultThanksPageDisplayType() {
        if (Util.isBlank(this.currentType)) this.currentType = "complete"
        let typeDic = SelectUtil.thanksPageDisplayTypes.find(t => t.type == this.currentType)
        return typeDic.name
    }

    selectDisplayType(e) {
        let typeDic = SelectUtil.thanksPageDisplayTypes.find(t => t.name == e)
        this.currentType = typeDic.type
        if (this.currentType == `complete`) {
            this.rs.thanks_page.redirect_url_after_send = null
        }
    }

    clickAddUrlLink() {
        if (this.isOpenUrlLink) {
            this.isOpenUrlLink = false
        } else {
            this.isOpenUrlLink = true
        }
    }
}
