
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, Logger, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AccountHeader from "./AccountHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import NotificationControl from "packs/utils/NotificationControl"
import CustomNotification from "packs/models/CustomNotification"

const roomStorage = new RoomStorage()

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
        FlashNotice,
    },
})
export default class CustomNotificationContent extends Vue {
    @Prop()
    noti: CustomNotification

    @Prop()
    availableEvents: any[]

    @Prop() // webhook, slack, teams
    provider: string

    @Prop() // user, user_group, room_setting
    scope: string

    // data:
    Notice = Notice

    rm = RoomManager
    Util = Util

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: CustomNotification = null
    showTip = false

    created() {}

    updated() {}

    mounted() {}

    @Watch(`noti`, { deep: true })
    updateNoti() {
        if (!this.noti) return
        this.notiDic = { ...this.noti }
    }

    public inputWebhookUrl(e) {
        Logger(`inputWebhookUrl: ${e}`)
        this.notiDic.settings.webhook_url = e
    }

    /**
     * 通知イベント名のクリックイベントを受けて、外部通知の対応イベントを更新します。
     * dic [<string, any>] keys=checked,value
     */
    public clickTiming(dic) {
        const timing = dic.value
        if (dic.checked) {
            if (!this.notiDic.events.includes(timing)) {
                this.notiDic.events = [...this.notiDic.events, timing]
            }
        } else {
            this.notiDic.events = this.notiDic.events.filter(v => v !== timing)
        }
    }

    // カスタ通知設定を作成・更新します。
    public upsertCustomNotification() {
        if (this.loading) return
        this.loading = true

        CustomNotification.save(this.notiDic).then(noti => {
            if (noti) {
                this.notiDic = noti
            }
            this.$emit(`updateNotification`, this.notiDic, this.provider)
            this.loading = false
        })
    }

    // 検証用トークンを更新します。(provider= webhookのみ有効)
    public updateWebhookToken(e) {
        this.notiDic.settings.need_update_token = true
        this.upsertCustomNotification()
    }

    // カスタ通知設定を削除します。
    public deleteCustomNotification() {
        if (this.loading) return
        this.loading = true

        CustomNotification.delete(this.provider, this.scope).then(noti => {
            this.notiDic = CustomNotification.createDefault(this.scope, this.provider)
            this.$emit(`updateNotification`, this.notiDic, this.provider)
            this.loading = false
        })
    }

    // プロバイダー毎のWebhookUrlのプレースホルダー値を返却します。
    public placeholderWebhookUrl() {
        if (this.provider == "slack") return "https://hooks.slack.com/services/example"
        if (this.provider == "teams") return "https://outlook.office.com/webhook/example"
        return "https://example.com/waaq-webhook"
    }

    public copyToken() {
        $(this).select()
        copyToClipboard(this.notiDic.settings.token)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`Copy webhook token`, ``)
    }

    public hideTooltip(e) {
        this.showTip = false
    }
}
