
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
declare var gtag: any
import axios from "axios"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import UsageContent from "packs/pages/link/modals/UsageContent.vue"
import Util from "packs/utils/Util"

import { Logger } from "packs/common"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        RadioButtonTag,
        UsageContent,
    },
})
export default class YokosoAgreement extends Vue {
    @Prop()
    isDev

    @Prop()
    action // fix_scheduleページでのみ利用.

    // data
    isSP = isMobile(window.navigator).phone

    rm = RoomManager

    get agreeTosButtonTitle(): string {
        Logger(`agreeTosButtonTitle: ${this.action}`)
        if (!this.action) return `利用規約に同意して、利用を開始する`
        if (this.action == `fix`) {
            return `利用規約に同意して、日程を確定`
        } else if ([`showPd`, `confirm`].includes(this.action)) {
            return `利用規約に同意して、日程候補を確認`
        }
        return `利用規約に同意して、利用を開始する`
    }

    /**
     * 同意ボタン押下後、ユーザーの情報が間違っていないかチェックします。
     */
    public agreementAndConfirmInfo() {
        if (this.isDev) {
            this.$emit("agreed")
            return
        }

        RoomMember.sendAgreement().then(e => {
            if (e) {
                this.rm.tutorial_phase = e
                Logger(`phase UPDATED: ${this.rm.tutorial_phase}`)
                this.$emit("agreed")
            }
        })
    }
}
