import Util from "packs/utils/Util"
import RoomMember from "./RoomMember"
import UserFile from "./UserFile"
import lodash from "lodash"

export default class AppealContent {
    constructor(
        public id: string,
        public image_url: string,
        public title: string,
        public content: string,
        public delta_content: any,
        public content_type: string,
        public presetInfo: boolean // AppealContentの内容を編集するまではtrue,編集したらfalseになります.
    ) {}

    static copy(o: AppealContent): AppealContent {
        let newO = lodash.cloneDeep(o)
        return newO
    }

    static createDefault(): AppealContent {
        return new AppealContent(null, ``, ``, ``, null, `text`, true)
    }

    /**
     * 作成時に publicRoomに切り替えたときにはめます.
     * @param userInfo
     */
    static setRandomContent(userInfo: RoomMember = null, title: string = null, content: string = null) {
        let sampleImages = UserFile.createSampleFiles()
        let rand = Math.floor(Math.random() * (sampleImages.length - 1))
        let apc = this.createDefault()
        if (title) {
            apc.title = `${title}`
        } else if (Util.isPresent(userInfo)) {
            apc.title = `${userInfo.name}の予約受付ページ`
        } else {
            apc.title = `予約受付ページ`
        }
        if (content) {
            apc.content = `${content}`
        } else {
            apc.content = `予約ページはこちらです。会う日程を選択してください。`
        }

        let sampleImage = sampleImages[rand]
        apc.image_url = sampleImage.image_url
        return apc
    }

    /**
     * 作成時に ParentRoomに切り替えたときに変更がないときは情報を戻します.
     * PublicRoom ->
     */
    static removeRandomContent() {
        return this.createDefault()
    }
}
