import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "btn-group rad6 ofh roomsSelectButtons roundForm",
  role: "group",
  style: {"padding":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(`btn btn-owner ${_ctx.appo && _ctx.appo.fix_type == 1 ? `selected` : ``}`),
      style: {"height":"50px"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fixBy(`owner`)))
    }, " オーナーのみ ", 2),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(`btn btn-last ${_ctx.appo && _ctx.appo.fix_type == 99 ? `selected` : ``}`),
      style: {"height":"50px"},
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fixBy(`last`)))
    }, " オーナーか最後に調整するユーザー ", 2),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(`btn btn-any ${_ctx.appo && _ctx.appo.fix_type == 100 ? `selected` : ``}`),
      style: {"height":"50px"},
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fixBy(`any`)))
    }, " 誰でも ", 2)
  ]))
}