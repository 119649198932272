
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"
import { ContentLoader } from "vue-content-loader"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import AnsweredFormModal from "packs/pages/link/parts/room_summary/AnsweredFormModal.vue"
import SuggestedRoomsMembersHeader from "packs/pages/link/parts/room_summary/SuggestedRoomsMembersHeader.vue"
import SuggestedDateCell from "packs/pages/link/parts/room_summary/SuggestedDateCell.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import FileUtil from "packs/utils/FileUtil"
import VotedRoomManager from "packs/models/VotedRoomManager"

@Options({
    components: {
        UserIcon,
        AnsweredFormModal,
        SuggestedRoomsMembersHeader,
        SuggestedDateCell,
        ContentLoader,
    },
})
export default class SuggestedRoomsMembersTable extends Vue {
    // data:
    @Prop() // schedule_view, summary
    from: string

    @Prop() // 日程だけ表示するか.（公開ページで全員に表示する場合には確定権限はなしのためtrueになります.）
    onlyShowDates: boolean

    @Prop()
    showMyVoteView: boolean

    @Prop() // w30で指定. 左側
    columnWidthClass: string

    @Prop() // w70で指定. 右側
    cellWidthClass: string

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    loading = false
    Util = Util

    mouseoverIndex: number = -1
    pdm = PossibleDatesManager

    member: RoomMember = null
    parentRooms: Room[] = null

    now = Util.getSec()

    // startTimeのArray
    okDates = []

    mouseoverTr(pd: PossibleDate, pdindex: number) {
        if (this.onlyShowDates) {
            return
        }
        this.mouseoverIndex = pdindex
    }

    confirmDate(pd: PossibleDate) {
        if (this.onlyShowDates) {
            return
        }
        this.$emit(`confirmDate`, pd)
    }

    selectOK(pd: PossibleDate, pdindex: number) {
        Logger(`${funcName()} pd:${pd.start_time}, pdindex:${pdindex}`)
        if (this.okDates?.includes(pd.start_time)) {
            return
        }
        // this.okDates.push(pd.start_time)

        // pdmにある提案箇所を更新.
        let pds = [...(this.pdm.selectedPds || [])]
        let _pd = pds.find(_pd => PossibleDate.isSame(_pd, pd))
        Logger(`${funcName()} pds:${pds.length} _pd:${_pd?.start_time}`)
        if (_pd) return // すでに選択済みの場合は何もしない.
        pd.selected = true
        pds.push(pd)
        this.pdm.updateSelectedPds(pds)
    }

    selectNO(pd: PossibleDate, pdindex: number) {
        Logger(`${funcName()} pd:${pd.start_time}, pdindex:${pdindex}`)
        if (!this.okDates?.includes(pd.start_time)) {
            return
        }
        // this.okDates = this.okDates.filter(d => d != pd.start_time)

        // pdmにある提案箇所を更新.
        let pds = [...(this.pdm.selectedPds || [])]
        pds = pds.filter(_pd => _pd.start_time != pd.start_time)
        Logger(`${funcName()} pds:${pds.length}`)
        this.pdm.updateSelectedPds(pds)
    }

    @Watch(`pdm.selectedPds`)
    updateSelectedPds() {
        this.okDates = (this.pdm.selectedPds || []).map(pd => pd.start_time)
        Logger(`${funcName()} this.okDates:${this.okDates.length}`)
    }

    displayDates() {
        if (this.vrm.isFiltered) {
            return this.vrm.filteredDates
        }
        return this.vrm.suggestedDates
    }
}
