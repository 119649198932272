
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import Const from "packs/utils/Const"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import { ref, watch } from "vue"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import PossibleDateLi from "packs/pages/link/parts/PossibleDateLi.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import TimezoneSelectContent from "packs/components/calendar/TimezoneSelectContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: { PossibleDateLi, ScheduleViewLoader, TimezoneSelectContent },
})
export default class WeeklyCalendarTable extends Vue {
    @Prop()
    useSelectbox: boolean

    @Prop()
    showConfirmButton: boolean

    @Prop()
    idprefix: string

    @Prop()
    astag: AvailableScheduleTag

    @Prop() // monthly/weekly
    calendarShowType: string

    // data
    rm = RoomManager
    pdm = PossibleDatesManager

    // subm = SubscriptionManager;
    // showAds = false;
    // currentPd?: PossibleDate = null;

    isSP = isMobile(window.navigator).phone

    selectedPossibleDates: PossibleDate[] = []
    PossibleDate = PossibleDate

    Util = Util

    // weekDics = Util.weekDics
    monthlyPossibleDates = [] // [[23,24,25], [10,11,12]] 今月と来月の
    possibleDatekeys = null
    currentDateId = null // 0629
    systemUpdatedAt = Util.getSec()
    windowWidth = 0
    DateTime = DateTime
    pdslength = 0

    isObserver = false

    weekArray = null
    today = DateTime.local().toISODate()
    startOfWeek = DateTime.local().startOf("week").minus({ day: 1 }) // 日曜スタートに変更
    // startOfWeek = null
    pds = null
    selectedDate = null
    holidaysDic = {}
    holidays = []

    created() {
        this.weekArray = [
            this.$t(`calendar.sun`),
            this.$t(`calendar.mon`),
            this.$t(`calendar.tue`),
            this.$t(`calendar.wed`),
            this.$t(`calendar.thu`),
            this.$t(`calendar.fri`),
            this.$t(`calendar.sat`),
        ]
    }

    public mounted() {
        this.updateCurrentDate()
        this.updatePds()
        this.updateSharedMembers()
        this.updateHolidays()

        this.calculateWindowWidth()
        window.addEventListener("resize", this.calculateWindowWidth)
    }

    selectMorning() {
        Logger(`${funcName()}`)
        if (!this.pds) return

        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            if (pd.selected) continue

            let st = DateTime.fromSeconds(pd.start_time)
            let et = st.plus({ minutes: pd.duration })
            if (st.hour >= 9 && st.hour < 12 && et.hour <= 12) {
                pd.selected = true
                selectedPds.push(pd)
            }
        }

        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }

    selectAfternoon() {
        Logger(`${funcName()}`)
        if (!this.pds) return

        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            if (pd.selected) continue

            let st = DateTime.fromSeconds(pd.start_time)
            let et = st.plus({ minutes: pd.duration })
            if (st.hour >= 12 && st.hour < 17 && et.hour <= 17) {
                pd.selected = true
                selectedPds.push(pd)
            }
        }

        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }

    selectEvening() {
        Logger(`${funcName()}`)
        if (!this.pds) return

        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            if (pd.selected) continue

            let st = DateTime.fromSeconds(pd.start_time)
            let et = st.plus({ minutes: pd.duration })
            if (st.hour >= 17 && st.hour < 21 && et.hour <= 21) {
                pd.selected = true
                selectedPds.push(pd)
            }
        }

        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }

    updateHolidays() {
        CalendarUtil.getHolidays().then(holidays => {
            this.holidaysDic = holidays
            this.holidays = Object.keys(holidays).map(k => k as string)
        })
    }

    @Watch(`pdm.currentDate`)
    updateCurrentDate() {
        this.updatePds()
    }

    @Watch(`pdm.sharedMembers`, { deep: true })
    updateSharedMembers() {
        this.updatePds()
    }

    @Watch(`pdm.selectedMag`)
    updateSelectMag() {
        this.updatePds()
    }

    @Watch("pdm.possibleDates", { deep: true })
    updatePds() {
        Logger(`${funcName()}`)
        if (!this.pdm) return
        if (Util.isPresent(this.pdm.room) && this.rm.userInfo) {
            let mem = this.pdm.room.members.find(_m => _m.user_id == this.rm.userInfo.user_id)
            if (mem && mem.role == -10) {
                this.isObserver = true
            }
        }
        let now = Util.getSec()

        if (Util.isPublic() || Util.isBlank(this.rm.astag) || this.rm.astag.id == `newId`) {
            const _pds = this.pdm.getPdsAfterRemoved()
            if (Util.isBlank(_pds)) return
            Logger(`${funcName()} _pdsが更新されてる？ _pds.length:${_pds.length}`)

            let selectedPds = [...(this.pdm.selectedPds || [])]
            // let selectedPds = [...(this.selectedPossibleDates || [])]
            this.updateWeeklyPossibleDatesFromPds(_pds)
            if (this.pdm.currentDate) {
                // if (this.currentDateId) this.pdm.selectDate(this.currentDateId)
                Logger(
                    `${funcName()} 日付が選択中です。${this.pdm.currentDate} this.pdm.displayPds: ${Util.output(
                        this.pdm.displayPds
                    )}, selectedPds: ${selectedPds}`
                )

                this.pds = (this.pdm.displayPds || []).filter(_pd => _pd.start_time >= now)
                this.selectedPossibleDates = Util.isPresent(selectedPds) ? selectedPds : _pds.filter(_pd => _pd.selected)
                this.pdm.updateSelectedPds(this.selectedPossibleDates)
                this.pdslength = (this.pdm.possibleDates || []).length
                return
            }

            // 日付の選択がない場合、初日を選択
            let pd = _pds[0]
            let _format = pd.date_format
            if (this.pdm.currentTZDic) {
                _format = pd.tz_date_format
            }
            this.pdm.selectDate(_format)
            this.pdslength = (this.pdm.possibleDates || []).length

            return
        }
        Logger(
            `${funcName()} 提案ケース. rm.astag:${Util.output(this.rm.astag)} optional_schedules:${Util.output(
                this.rm.astag.optional_schedules
            )}`
        )

        // const _pds = this.pdm.possibleDates
        const _pds = this.pdm.getPdsAfterRemoved()
        if (!_pds) return

        this.updateWeeklyPossibleDatesFromPds(_pds)

        let selectedPds = this.pdm.getSelectedPossibleDates(this.rm.astag)
        if (Util.isBlank(this.pdm.currentDate)) {
            let pd = _pds[0]
            this.pdm.selectDate(pd.date_format)
        }

        this.pds = (this.pdm.displayPds || []).filter(_pd => _pd.start_time >= now)
        this.selectedPossibleDates = selectedPds
        this.pdslength = (this.pdm.possibleDates || []).length
    }

    @Watch(`pdm.selectedPds`)
    updateSelectedPds() {
        Logger(`${funcName()} pdm.selectedPdsが更新されました。`)
        this.pds = this.pdm.displayPds
        this.selectedPossibleDates = this.pdm.selectedPds
    }

    public selectDateFromCal(idName: string) {
        // this.currentDateId = idName
    }

    /**
     * 候補日時を表示する幅を指定します.
     */
    get pdWidth() {
        if (this.idprefix == `asview`) {
            return 310
        }
        return 290
    }

    @Watch("astag", { deep: true })
    updateOptionalSchedules() {
        let _ops = this.astag.optional_schedules
        Logger(`${funcName()} : astagに変更がありました. ${Util.output(_ops)}`)
        if (!_ops) return
        let ops = (Object.values(_ops) as any).flat() as PossibleDate[]
        if (!ops || ops.length == 0) return
        let _pds = this.pdm.getPdsAfterRemoved()
        if (!_pds || _pds.length == 0) return
        Logger(`${funcName()} pdsの更新2 ${_pds.length}件表示.`)
        // this.pds = _pds
        if (this.pdm.currentDate) {
            this.pdm.selectDate(null)
            Logger(`${funcName()} 日付が選択中です2。${this.pdm.currentDate} ${this.currentDateId}`)
            this.pds = this.pdm.displayPds
            // this.selectedPossibleDates = selectedPds
        }
    }

    public updateWeeklyPossibleDatesFromPds(pds: PossibleDate[]) {
        let cannotFindFormat = false
        let possibleDatekeys = []
        ;[this.monthlyPossibleDates, cannotFindFormat, possibleDatekeys] = PossibleDate.getAvailableCalendarsFromPds(
            pds,
            this.pdm.currentTZDic
        )
        this.possibleDatekeys = possibleDatekeys
        if (Util.isPresent(possibleDatekeys) && !this.selectedDate) {
            let currentPd = this.pdm.currentCalendarDate
            let firstDate = possibleDatekeys[0]
            if (currentPd || firstDate) {
                let startDate
                if (currentPd) {
                    startDate = DateTime.fromSeconds(currentPd.start_time)
                } else if (firstDate) {
                    startDate = DateTime.fromFormat(firstDate, `yyyyMMdd`)
                }
                this.selectDate(startDate)
                this.startOfWeek = startDate.startOf("week").minus({ day: 1 })
                let dt = this.startOfWeek.plus({ day: 6 })
                if (dt.month != startDate.month) {
                    sleep(200).then(_ => {
                        this.$emit(`clickNextWeek`, CalendarUtil.toWeekSpan(dt))
                    })
                }
            }

            // if (firstDate) this.pdm.selectDate(firstDate.slice(-4))
        }
        // if (cannotFindFormat) {
        //     this.pdm.currentTZDic = null
        // }

        Logger(`${funcName()} cannotFindFormat:${cannotFindFormat} ${Util.output(this.monthlyPossibleDates)}`)
    }

    public clickPossibleDateCell(pd: PossibleDate) {
        this.$emit(`clickCell`, pd)
    }

    public confirmFixSchedule(pd: PossibleDate) {
        this.$emit("confirmFixSchedule", pd)
    }

    public removeDate(pd) {
        // this.pds = this.pds.filter(_pd => !PossibleDate.isSame(_pd, pd))
        this.$emit(`removeDate`, pd)
    }

    calculateWindowWidth() {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < Const.spWidth) {
            this.isSP = true
        } else {
            this.isSP = isMobile(window.navigator).phone
        }
    }

    selectTimezone(tzDic: any) {
        Logger(`${funcName()} tzDic:${Util.output(tzDic)}`)
        this.pdm.changeTimezone(tzDic)
        this.pdm.selectDate(this.currentDateId)
    }

    showChangeTimezone() {
        if (!this.pdm.room) return

        let rs = this.pdm.room.room_setting
        if (!rs) return

        Logger(`showChangeTimezone: rs.can_change_timezone:${rs.can_change_timezone}`)
        return rs.can_change_timezone
    }

    allDaySelect() {
        Logger(`${funcName()}`)
        if (Util.isPreview() || Util.isRoomSettingsPreview()) {
            Notice.message = "調整ページ編集画面のため、選択できません。"
            return
        }
        if (this.isObserver) {
            Logger(`${funcName()} オブザーバーのため、選択できません。`)
            return
        }
        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            if (!pd.selected) {
                pd.selected = true
                selectedPds.push(pd)
            }
        }

        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }

    allDayDeselect() {
        if (Util.isPreview() || Util.isRoomSettingsPreview()) {
            Notice.message = "調整ページ編集画面のため、選択できません。"
            return
        }
        if (this.isObserver) {
            Logger(`${funcName()} オブザーバーのため、選択できません。`)
            return
        }
        let datePds = [...(this.pds || [])]
        let selectedPds = [...(this.selectedPossibleDates || [])]
        for (let pd of datePds) {
            let currentPd = selectedPds.find(p => PossibleDate.isSame(pd, p))
            if (currentPd) {
                currentPd.selected = false
                selectedPds = selectedPds.filter(e => !PossibleDate.isSame(pd, e))
            }

            pd.selected = false
        }
        this.selectedPossibleDates = selectedPds
        this.pdm.updateSelectedPds(selectedPds)
        this.systemUpdatedAt = Util.getSec()
    }

    prevWeek() {
        if (!this.startOfWeek) return

        this.startOfWeek = this.startOfWeek.minus({ week: 1 })
        this.$emit(`clickPrevWeek`, CalendarUtil.toWeekSpan(this.startOfWeek))
    }

    nextWeek() {
        if (!this.startOfWeek) return

        this.startOfWeek = this.startOfWeek.plus({ week: 1 })

        let dt = this.startOfWeek.plus({ day: 6 })
        this.$emit(`clickNextWeek`, CalendarUtil.toWeekSpan(dt))
    }

    /**
     *
     * @param date [DateTime]
     */
    selectDate(date) {
        Logger(`${funcName()} date:${date.toISODate()}`)
        if (!this.possibleDatekeys?.includes(date.toFormat(`yyyyMMdd`))) {
            return
        }
        this.selectedDate = date.toISODate()
        this.pdm.selectDate(date.toFormat(`MMdd`))
    }

    showPrevButton() {
        if (!this.startOfWeek) return
        // const firstCalId = `${DateTime.local().toFormat(`yyyyMM`)}`
        const firstCalId = DateTime.local().startOf("week").minus({ day: 1 })
        if (this.startOfWeek <= firstCalId) {
            return false
        }
        return true
    }

    showNextButton() {
        if (!this.pdm) return
        if (!this.pdm.available_weeks) return
        if (!this.startOfWeek) return

        let avWeeks = this.pdm.available_weeks as string[]
        let lastWeek = CalendarUtil.toTimeFromWeekSpan(`${avWeeks[avWeeks.length - 1]}`)
        Logger(`${funcName()} avWeeks:${Util.output(avWeeks)}, lastWeek:${lastWeek}, startOfWeek:${this.startOfWeek}`)

        if (this.startOfWeek >= lastWeek) {
            return false
        }
        return true
    }

    titleDate() {
        if (!this.pdm.currentCalendarDate) return

        let dt = DateTime.fromSeconds(this.pdm.currentCalendarDate.start_time)
        if (this.pdm.currentTZDic) {
            let offsetStr = PossibleDate.toTimezoneUTCFormat(this.pdm.currentTZDic.diff)
            dt = dt.setZone(`UTC${offsetStr}`)
        }
        let format = this.$t(`calendar.format_date`, {
            val1: this.weekArray[dt.weekday],
        }) as string
        Logger(`${funcName()} format:${dt.toFormat(format)}`)

        if (this.pdslength != (this.pdm.possibleDates || []).length) {
            Logger(`${funcName()} pdslength:${this.pdslength}  pdm.length:${(this.pdm.possibleDates || []).length}`)
            this.updatePds()
        }

        return dt.toFormat(format)
    }

    selectedCheck(weekindex) {
        if (!this.pdm.selectedPds) return ``
        let date = this.startOfWeek.plus({ day: weekindex })
        if (!date) return ``
        Logger(`selectedCheck date:${date.toFormat(`MMdd`)} possibleDatekeys:${Util.output(this.possibleDatekeys)}`)

        return this.pdm.selectedPds.find(pd => pd.date_format == date.toFormat(`MMdd`)) ? `suggested` : ``
    }

    @Watch(`pdm.dailyPossibleDates`, { deep: true })
    clickFirstDayAfterScroll() {
        if (!this.possibleDatekeys) return

        let date = DateTime.fromObject(this.startOfWeek.toObject())
        Logger(`${funcName()} date:${date.toFormat(`yyyyMMdd`)}`)
        for (let i = 0; i < 7; i++) {
            date = date.plus({ day: 1 })
            if (!this.possibleDatekeys?.includes(date.toFormat(`yyyyMMdd`))) {
                continue
            }
            this.selectDate(date)
            break
        }
    }
}
