
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import OneRoomHistoryContent from "packs/pages/link/parts/OneRoomHistoryContent.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"

@Options({
    components: { UserIcon, OneRoomHistoryContent, RoomsLoader, VueFinalModal, MdProgressBar },
})
export default class RoomHistoryDetailModal extends Vue {
    @Prop()
    room: Room

    // data
    hm = RoomHistoryManager
    rm = RoomManager

    loading = false
    isMe = false

    hists: RoomHistoryDetail[] = null
    // currentRoom: Room = null

    openModal() {
        this.hists = null
        this.hm.resetData()
        this.updateRoom()
    }

    updateRoom() {
        if (!this.room) return
        if (this.hists) return

        this.getLoopHistories()
    }

    getLoopHistories() {
        Logger(`RoomHistoryDetailModal.getLoopHistories`)
        if (this.room.room_type == `public_room`) {
            this.hists = []
            return
        }

        if (this.hm.has_next) {
            if (this.loading) return
            this.loading = true

            this.hm.getHistory(this.room, true).then(hists => {
                this.loading = false
                if (Util.isPresent(hists)) {
                    Logger(`連結します. ${this.hm.has_next} ${this.hm.histories.length}`)
                    // Array.prototype.push.apply(this.hists, hists)
                    this.hists = [...this.hm.histories]
                    this.getLoopHistories()
                } else {
                    this.hists = [...(this.hm.histories || [])]
                }
            })
        } else {
            Logger(`これ以上取得するhistoryはありません`)
        }
    }

    // beforeUnmount() {
    //     window.removeEventListener("resize", this.adjustHeight)
    // }

    @Watch("hm.histories", { deep: true })
    public update() {
        // this.currentRoom = this.room
        // Logger(`FixScheduleModal : created ${Util.output(this.pdm.currentPd)}`)
        // Logger(`FixScheduleModal: センタ中のmag ${Util.output(this.pdm.selectedMag)}`)
        // this.currentPd = this.pdm.currentPd
        // let pds = this.getCurrentPds()
        // const num = this.checkPdIndex(pds)
        // this.checkPdIndexType(pds, num)
        // this.adjustHeight()
    }

    // @Watch("room", { deep: true })
    // updateRoom() {
    //     this.currentRoom = this.room
    // }

    public hideFixScheduleModal() {
        this.$vfm.close("RoomHistoryDetailModal")
    }

    /**
     * スクロールを検知して、下までいったら履歴情報を取得します。
     */
    // public scroll() {
    //     // $(".roomTableContent").get(0).scrollHeight
    //     Logger(`scroll: ${$(".RoomHistoryDetailModalScroll").outerHeight()} ${window.innerHeight + window.scrollY}`)
    //     if ($(".RoomHistoryDetailModalScroll").outerHeight() < window.innerHeight + window.scrollY) {
    //         Logger("下端までスクロール")
    //         if (this.hm.has_next) {
    //             if (this.loading) return
    //             this.loading = true

    //             this.hm.getHistory(this.room, true).then(hists => {
    //                 this.loading = false
    //                 if (Util.isPresent(hists)) {
    //                     Logger(`連結します. ${this.hm.has_next} ${this.hm.histories.length}`)
    //                     // Array.prototype.push.apply(this.hists, hists)
    //                     this.hists = [...this.hm.histories]
    //                 } else {
    //                     this.hists = [...(this.hm.histories || [])]
    //                 }
    //             })
    //         } else {
    //             Logger(`これ以上取得するものはありません.`)
    //         }
    //         // this.selectCategoryTab(this.selectedTabName)
    //     }
    // }
}
