import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w100 with_white_bg" }
const _hoisted_2 = { class: "pad5 mt20" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { style: {"font-size":"13px","color":"#aaa"} }
const _hoisted_5 = { style: {"font-size":"16px","font-weight":"600","color":"#222222","line-height":"1.6","padding-bottom":"13px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (_f, findex) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "text-left",
          style: {"list-style":"none"},
          key: findex
        }, [
          (![`same_ug_only`, `same_ug_admin_only`].includes(_f.display_type))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_f.field_name), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_f.field_name), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}