
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, copyToClipboard } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import FormSettings from "packs/pages/link/parts/room_settings/FormSettings.vue"
import AssignFormCellEditMenu from "packs/pages/link/settings/AssignFormCellEditMenu.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomStorage from "packs/models/RoomStorage"
import AssignForm from "packs/models/AssignForm"

const roomStorage = new RoomStorage()

@Options({
    components: {
        RoomsLoader,
        FormSettings,
        AssignFormCellEditMenu,
        UserIcon,
    },
})
export default class AssignFormCell extends Vue {
    @Prop()
    aform: AssignForm

    @Prop()
    isLastCell: boolean

    @Prop()
    canDelete: boolean

    CalendarUtil = CalendarUtil
    showTip = false

    copyPage() {
        this.$emit(`copyPage`, this.aform)
    }

    editPage() {
        this.$emit(`editPage`, this.aform)
    }

    showCloseRoomModal() {
        this.$emit(`showCloseRoomModal`, this.aform)
    }

    clickEditMenu() {
        Logger(`${funcName()} isLastCell:${this.isLastCell}`)
        gtagClick(`roomCell`, `EditMenu: isLast:${this.isLastCell}`)
        if (this.isLastCell) {
            // 一番したのセルだったら場所をずらします.
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "-110px" })
        } else {
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "auto" })
        }
    }

    public copyPublicId() {
        $(this).select()
        copyToClipboard(this.aform.public_id)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`publicIDをコピー`, `afid:${this.aform.id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
    }
}
