import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "value"]
const _hoisted_2 = ["name", "value", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    id: `${_ctx.value}`,
    class: _normalizeClass(`circle_button fw600 fs11 fl ${_ctx.defaultSelect} mr10`),
    style: {"height":"26px","padding":"4px 13px"},
    value: _ctx.value,
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.select && _ctx.select(...args)), ["prevent"]))
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      name: _ctx.name,
      value: _ctx.value,
      id: `${_ctx.value}`,
      class: "nodisp"
    }, null, 8, _hoisted_2),
    _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
  ], 10, _hoisted_1))
}