
// モジュールを読込.
import { Options, Prop, Vue, Provide, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import AvailableScheduleSettings from "packs/pages/link/parts/available_schedule/AvailableScheduleSettings.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import Notice from "packs/models/Notice"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        ChipButton,
        Switcher,
        AvailableScheduleSettings,
        IconHelp,
        VueFinalModal,
    },
})
export default class ModalEditCalendarTag extends Vue {
    // @Prop()
    // ctag: CalendarTag;

    @Prop() // func
    autoLoadWeeklyCalendar

    isSP = isMobile(window.navigator).phone
    cm = CalendarManager

    Const = Const
    Util = Util

    ctagNum = 0
    loading = false

    // 今回編集するctag（全体設定の場合astagが入るのでanyで型指定しない）を CalendarManagerより受け取ります。
    ctag: any = null

    availableEvent = false
    ignoreEvent = false

    public openModal() {
        this.updateCtags()
    }

    public closeModal() {
        if (this.cm.edittingAstag) {
            this.cm.edittingAstag = null
        }
    }

    get getTitle() {
        if (this.cm.edittingAstag) {
            return `共通のカレンダー設定`
        }
        return `個別のカレンダー設定`
    }

    get getDescription() {
        if (this.cm.edittingAstag) {
            return `調整カレンダー内で利用するすべての個別のカレンダーに適用します。<br />個別のカレンダーごとに設定したい場合は、担当者グループを選択した後、ピンクの背景を押して個別に入力してください。`
        }
        return `利用するカレンダー(${this.ctag.name})を細かく設定できます。`
    }

    get isActive() {
        if (this.cm.edittingAstag) {
            return true
        }

        if (!this.cm.edittingCtag) return true

        if (this.ctag.is_active_meeting_attendee_option) {
            return true
        }
        return false
    }

    public dismissModal() {
        this.$vfm.close("EditCalendarTagModal")
    }

    public updateCtags() {
        if (!this.cm.edittingCtag && !this.cm.edittingAstag) return

        // astag全体の設定の編集中の場合
        if (this.cm.edittingAstag) {
            this.ctag = { ...this.cm.edittingAstag }
            Logger(`astagのupdateがありました${Util.output(this.cm.edittingCtag)}`)
        } else {
            this.ctag = { ...this.cm.edittingCtag } as CalendarTag
            Logger(`ctagのupdateがありました${Util.output(this.cm.edittingCtag)}`)
        }

        this.availableEvent = this.ctag.can_use_available_event_keyword
        this.ignoreEvent = this.ctag.can_use_ignore_event_keyword
    }

    public checkIsActive() {
        if (this.cm.edittingAstag) {
            return true
        }
        if (!this.ctag.is_active_meeting_attendee_option) {
            Notice.message = `設定が無効です`
            return false
        }
        return true
    }

    public changeCanRegisterCalendar() {
        if (this.ctag.can_register_calendar) {
            this.ctag.can_register_calendar = false
        } else {
            this.ctag.can_register_calendar = true
        }
        Logger(`changeCanRegister: ${this.ctag.can_register_calendar}`)
    }

    // ignoreAlldayを変更したので、可能日程を再取得します。
    public changeIgnoreAllday() {
        if (!this.checkIsActive()) return
        // this.getEvents();
        if (this.ctag.ignore_allday) {
            this.ctag.ignore_allday = false
        } else {
            this.ctag.ignore_allday = true
        }
        Logger(`changeIgnoreAllday: ${this.ctag.ignore_allday}`)
    }

    public changeAvailableEvent(e) {
        if (!this.checkIsActive()) return
        if (this.ctag.can_use_available_event_keyword) {
            this.ctag.can_use_available_event_keyword = false
            this.availableEvent = false
        } else {
            this.ctag.can_use_available_event_keyword = true
            this.availableEvent = true
        }
        this.ctag.systemUpdatedAt = Util.getSec()
        Logger(`change Available Event: ${this.ctag.can_use_available_event_keyword}`)
    }

    public changeIgnoreEvent(e) {
        if (!this.checkIsActive()) return
        if (this.ctag.can_use_ignore_event_keyword) {
            this.ctag.can_use_ignore_event_keyword = false
            this.ignoreEvent = false
        } else {
            this.ctag.can_use_ignore_event_keyword = true
            this.ignoreEvent = true
        }
        this.ctag.systemUpdatedAt = Util.getSec()
        Logger(`change Ignore Event: ${this.ctag.can_use_ignore_event_keyword}`)
    }

    public inputAvailableKeyword(e) {
        if (!this.checkIsActive()) return
        this.ctag.available_event_keyword = e
    }

    public inputTimeAlldayStart(e) {
        if (!this.checkIsActive()) return
        let val = $(".alldayStart").val() as string
        if (val) {
            let [startHour, startMin] = val.split(":")
            this.ctag.available_event_allday_start_hour = Number(startHour) || 0
            this.ctag.available_event_allday_start_min = Number(startMin) || 0
        }
        Logger(
            `inputTimeAlldayStart: ${this.ctag.available_event_allday_start_hour} ${this.ctag.available_event_allday_start_min}`
        )
    }

    public inputTimeAlldayEnd(e) {
        if (!this.checkIsActive()) return
        let val = $(".alldayEnd").val() as string
        if (val) {
            let [endHour, endMin] = val.split(":")
            this.ctag.available_event_allday_end_hour = Number(endHour) || 0
            this.ctag.available_event_allday_end_min = Number(endMin) || 0
        }
        Logger(`inputTimeAlldayEnd: ${this.ctag.available_event_allday_end_hour} ${this.ctag.available_event_allday_end_min}`)
    }

    public inputIgnoreKeyword(e) {
        if (!this.checkIsActive()) return
        this.ctag.ignore_event_keyword = e
    }

    public saveCtag() {
        Logger(`${funcName()} 保存します: ${Util.output(this.ctag)}`)
        // astag全体の設定の編集中の場合
        if (this.cm.edittingAstag) {
            this.$vfm.close(`EditCalendarTagModal`)
            this.cm.editEndAstag(this.ctag)
            Logger(`${funcName()} astagを保存しました`)
            this.autoLoadWeeklyCalendar()
        } else {
            this.cm.editEndCtag(this.ctag)
            Logger(`${funcName()} ctagを保存しました`)
            this.$emit("edit", this.ctag)
        }
    }

    public changeActive() {
        if (!this.cm.edittingCtag) return

        if (this.ctag.is_active_meeting_attendee_option) {
            this.ctag.is_active_meeting_attendee_option = false
        } else {
            this.ctag.is_active_meeting_attendee_option = true
        }
    }

    updateAstag(astag: AvailableScheduleTag) {
        this.ctag = astag
    }

    // OptionalSchedulesのみを日程提案候補としてみます.（astagのみ設定可能.）
    changeOnlyUseOptionalSchedules() {
        if (this.cm.edittingAstag) {
            if (this.ctag.is_only_use_optional_schedules) {
                this.ctag.is_only_use_optional_schedules = false
            } else {
                this.ctag.is_only_use_optional_schedules = true
            }
        }
    }
}
