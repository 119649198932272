
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import CustomNotification from "packs/models/CustomNotification"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
        RoomsLoader,
        CopyButton,
    },
})
export default class WaaqApiKeysContent extends Vue {
    @Prop() // user, user_group, room_setting
    scope: string

    @Prop() // func
    showOptionModal

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Util = Util
    notice = Notice
    DateTime = DateTime

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: CustomNotification = null
    showTip = false
    optionEventName = `管理画面Zapier 課金リクエスト`
    connectableNotis: CustomNotification[] = null
    availableEvents = null
    notificationSettings = null
    selectingEventDic = null
    registerType = `sf_events`
    CustomNotification = CustomNotification
    defaultEventSettingDic = { register_type: this.registerType, pipeline_name: ``, dealstage_name: `` }
    showRegisterForm = true
    truncate = truncate

    leadFieldDic = { name: ``, value: ``, sf_field_name: ``, sf_default_value: `` }
    apiKeys = null
    copingKeyId = null

    mounted() {
        Logger(`${funcName()} mounted`)
        this.updateNoti()
    }

    requestApiKey() {
        this.rm.showPlanOptionModalWithName = `管理画面Apiキー 課金リクエスト`
        this.showOptionModal(this.rm.showPlanOptionModalWithName)
    }

    updateNoti() {
        if (this.apiKeys) return

        CustomNotification.getIntegNotifications(`room_api_keys`).then(dic => {
            Logger(`updateNoti Salesforce NOTIS: ${Util.output(dic)}`)
            let notis = dic.notifications || []
            this.apiKeys = notis
        })
    }

    createApiKey() {
        let key = CustomNotification.createDefault(`user_group`, `waaq_api_keys`)

        CustomNotification.save(key).then(e => {
            Logger(`AFTER CREATE SALESFORCE NOTI: ${Util.output(e)}`)

            this.apiKeys = this.apiKeys || []
            this.apiKeys.push(e)

            // this.$emit(`updateNotification`, this.notiDic, `salesforce`)
        })
    }

    removeApiKey(keyId: string) {
        CustomNotification.delete(`waaq_api_keys`, `user_group`, keyId).then(e => {
            Logger(`AFTER DELETE API_KEY NOTI: ${Util.output(e)}`)
            this.apiKeys = this.apiKeys.filter(k => k.key_id != keyId)
        })
    }

    selectApiKey(e) {
        Logger(`${funcName()} e: ${Util.output(e)}`)
        let noti = this.connectableNotis.find(n => n.settings.provider_account_id == e)
        if (noti) {
            this.notiDic = noti
            this.notificationSettings = { ...this.notiDic.settings }

            this.$emit(`updateNotification`, this.notiDic, `salesforce`)
        }
    }

    get defaultEvent() {
        let timing = `fixed_appointment`
        let evDic = this.availableEvents.find(evdic => evdic.timing == timing)

        return evDic.name
    }

    public hideTooltip(e) {
        this.showTip = false
    }

    copyKeyId(keyId: string) {
        $(this).select()
        copyToClipboard(keyId)
        this.showTip = true
        this.copingKeyId = keyId
        setTimeout(this.hideTooltip, 2000)
    }
}
