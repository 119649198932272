
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.
import CalendarTag from "packs/models/CalendarTag"

@Options({
    components: { TextfieldTag },
})
export default class ZoomSelectContent extends Vue {
    @Prop() // すべてのZoomアカウント.
    zoomLoctags: CalendarTag[]

    @Prop() // 選択済みのzoomアカウント
    sZooms: CalendarTag[]

    @Prop() // 未選択のZoomアカウント
    unZooms: CalendarTag[]

    @Prop() // zoom, teams
    provider: string

    @Prop() // 最大選択可能数
    maxLength: number

    // data
    isSP = isMobile(window.navigator).phone
    Util = Util

    selectedZooms: CalendarTag[] = null
    unselectedZooms: CalendarTag[] = null

    currentZoomEmail = ``

    public mounted() {
        this.updateZooms()
    }

    @Watch(`unZooms`, { deep: true })
    public updateZooms() {
        Logger(`ZoomSelectContent:select: sZooms: ${Util.output(this.sZooms)}, unZooms: ${Util.output(this.unZooms)}`)
        if (!this.unZooms) return
        if (!this.sZooms) return

        this.selectedZooms = [...(this.sZooms || [])]
        this.unselectedZooms = [...(this.unZooms || [])]
    }

    public addZoom(e) {
        Logger(`select name: ${e}`)
        let _zoom = (this.unselectedZooms || []).find(z => z.email == e)
        if (_zoom) {
            this.unselectedZooms = this.unselectedZooms.filter(z => z.email != e)
            this.selectedZooms.push(_zoom)
            this.currentZoomEmail = null
            $(`#zoomAccounts`).val(``)
            this.updateZoomAccounts()
        }
    }

    public enterZoom(e) {
        Logger(`enterZoom: ${e}`)
    }

    public selectZoom(e: CalendarTag) {
        Logger(`zoom選択しました: ${Util.output(e)}`)
        let zooms = [...(this.selectedZooms || [])]
        let unzooms = [...(this.unselectedZooms || [])]
        zooms.push(e)
        unzooms = unzooms.filter(z => z.search_key != e.search_key)
        this.selectedZooms = zooms
        this.unselectedZooms = unzooms
        this.updateZoomAccounts()
    }

    public deselectZoom(e: CalendarTag) {
        Logger(`選択したzoomアカウントをはずします.`)
        let zooms = [...(this.selectedZooms || [])]
        let unzooms = [...(this.unselectedZooms || [])]
        unzooms.push(e)
        zooms = zooms.filter(z => z.search_key != e.search_key)
        this.selectedZooms = zooms
        this.unselectedZooms = unzooms
        this.updateZoomAccounts()
    }

    public goZoom() {
        if (confirm("調整カレンダーを保存せずZoom連携をする")) {
            gtagClick(`goZoom`, ``)
            this.$router.push("/settings/locations")
        }
    }

    public updateZoomAccounts() {
        this.$emit(`update`, this.selectedZooms, this.unselectedZooms)
    }
}
