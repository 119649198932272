
// モジュールを読込.
import { Vue, Options, Prop, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import CalendarManager from "packs/models/CalendarManager"
import AssignForms from "../settings/AssignForms.vue"

@Options({
    components: { MdTextfieldTag, VueFinalModal, MdProgressBar },
})
export default class InputAstagNameModal extends Vue {
    /**
     * Astagの新規作成、名前変更
     * クイック投票作成時の会議名の入力に利用します.
     */
    @Prop() // normal, vote, aform
    createType: string

    @Prop()
    willCreateNewAstag: boolean

    // data
    rm = RoomManager
    cm = CalendarManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validName = true

    astagName = ``
    saving = false
    notice = Notice
    type = ``

    @Ref()
    AstagInputField

    get getTitle() {
        if (this.type == `rename`) {
            return `調整カレンダー名変更`
        }
        return `新規作成`
    }

    openModal() {
        Logger(`InputAstagNameModal#openModal createNew?:${this.willCreateNewAstag}, createType: ${this.createType}`)
        if (this.cm.astag) {
            this.type = `rename`
            this.astagName = this.cm.astag.name
        }

        sleep(500).then(_ => {
            if (this.AstagInputField) {
                this.AstagInputField.focusInput()
            }
        })
    }

    public inputName(e) {
        this.astagName = e
        this.checkName()
    }

    public checkName() {
        this.validName = !Util.isBlank(this.astagName)
    }

    public closeModal() {
        this.$vfm.close(`InputAstagNameModal`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    createAform() {
        this.$emit(`createAform`, this.astagName)
    }

    public createAstag() {
        this.checkName()
        Logger(`InputAstagNameModal.createAstag type: ${this.type}`)
        if (!this.validName) {
            if (this.createType == `normal`) {
                this.notice.message = `調整カレンダー名を正しく入力してください`
            } else if (this.createType == `aform`) {
                this.notice.message = `フォーム名を正しく入力してください`
            } else {
                this.notice.message = `会議名を正しく入力してください`
            }

            return
        }
        if (this.createType == `aform`) {
            this.createAform()
            return
        }

        if (this.type == `rename`) {
            this.cm.astag.name = this.astagName
            this.$vfm.close("InputAstagNameModal")
            return
        }
        let newAstag = this.rm.addNewAstag()

        if (!this.willCreateNewAstag) {
            // 新規で作成しない
            this.rm.astag.name = this.astagName
            this.$emit(`createNew`)
            this.astagName = ``
            return
        }

        newAstag.name = this.astagName
        this.rm.astag = newAstag
        Logger(`createAstag: ${newAstag.name}`)
        this.$emit(`createNew`)
        this.astagName = ``
    }

    public enterName() {
        this.createAstag()
    }
}
