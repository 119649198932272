import axios from "axios"
import lodash from "lodash"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Util from "packs/utils/Util"

export default class AvailableScheduleRule {
    constructor(
        //
        public id: string,
        public manual: string,
        public available_schedule_tag_id: string,
        public astag_info: AvailableScheduleTag
    ) {}

    static copy(o: AvailableScheduleRule): AvailableScheduleRule {
        // let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        let newO = lodash.cloneDeep(o)
        return newO
    }

    static createDefault(astagId: string, astag: AvailableScheduleTag): AvailableScheduleRule {
        const _t = Util.getSec()
        const color = Util.getRandomColor()
        return new AvailableScheduleRule(`newId:${_t}${color}`, "", astagId, astag)
    }
}
