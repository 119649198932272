import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import AvailableScheduleTag from "./AvailableScheduleTag"
import RoomMember from "./RoomMember"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"

export default class MessageTemplate {
    constructor(
        public id: string,
        public title: string,
        public subject: string,
        public content: string,
        public owner_user_id: string,
        public created_at: number,
        public status: number,
        public public_id: string,
        public showTooltip: boolean
    ) {}

    static fetchFromJson(tmpsJson: any[]): MessageTemplate[] {
        let tmps = []
        for (let mes of tmpsJson || []) {
            let _mes: MessageTemplate = mes
            if (!_mes) continue
            _mes.showTooltip = false
            // Logger(`tmp: ${Util.output(_mes)}`)

            tmps.push(_mes)
        }
        return tmps
    }

    static createDefaultTemplate() {
        const now = Util.getSec()
        return new MessageTemplate("newId", "", "", "", ``, now, 1, ``, false)
    }

    static getTemplates(): any {
        return axios.get(`${Util.prefixUrl}/templates`).then(res => {
            let tmps = MessageTemplate.fetchFromJson(res.data.tmps)
            return tmps
        })
    }

    /**
     * テンプレートを 作成 / アップデートします。
     * @param tmp
     * @param sendType
     */
    static create(tmp: MessageTemplate, sendType): Promise<MessageTemplate> {
        sendType = "put"
        if (tmp.id == `newId`) {
            sendType = "post"
            delete tmp.id
        }
        return axios({
            method: sendType,
            url: `${Util.prefixUrl}/templates`,
            data: tmp,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                const tmp: MessageTemplate = res.data.tmp

                return tmp
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
