
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"

// コンポーネントを読込.
import WeekdayButtons from "packs/components/buttons/WeekdayButtons.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import { zeroPadding } from "packs/common"

@Options({
    components: { WeekdayButtons },
})
export default class AvailableDaysOfWeeksTableCell extends Vue {
    // adow または、 dayOfTheWeek && startHour:startMinを入力.
    @Prop()
    adow: DateTag

    @Prop()
    dayOfTheWeek: number[]

    @Prop()
    startHour: string

    @Prop()
    startMin: string

    @Prop() // optional
    endHour: string

    @Prop() // optional
    endMin: string

    @Prop()
    showDeleteButton: boolean

    zeroPadding = zeroPadding

    // data:

    mounted() {}

    deleteAdow(adow) {
        this.$emit("deleteAdow", adow)
    }

    updateHoliday() {
        let _adow = this.adow
        if (_adow) {
            if (_adow.use_holiday) {
                _adow.use_holiday = false
            } else {
                _adow.use_holiday = true
            }
        }
        this.$emit("updateHoliday", _adow)
    }
}
