
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { funcName, Logger } from "packs/common"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Dropdown } from "uiv"

// コンポーネントを読込.
import EventMenu from "packs/pages/link/parts/available_schedule/EventMenu.vue"

// モデルを読込.
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import SelectUtil from "packs/utils/SelectUtil"
import CalendarManager from "packs/models/CalendarManager"
import Const from "packs/utils/Const"

@Options({
    components: { EventMenu, Dropdown },
})
export default class OneEventContent extends Vue {
    // data:
    @Prop()
    date: DateTime

    @Prop()
    ev: Event

    @Prop()
    evindex: number

    @Prop()
    classes: string

    CELL_HEIGHT = Const.CELL_HEIGHT

    isSP = isMobile(window.navigator).phone
    Util = Util
    SelectUtil = SelectUtil
    DateTime = DateTime
    cm = CalendarManager

    currentEvent: Event = null

    events: Event[] = null // event, possible_eventを保持
    dragEnd = false
    alldayHeight = this.cm.alldayHeight

    selecting = false
    selectCells: number[] = []

    showTooltip = false
    menu = null

    addtionalClass = ``
    editEvDic = { start_cell: 0, duration: 0 }

    // currentEventPosition = `` // "top", "bottom", "both" 上にavzoneがある、下にavzoneがある、上下両方にある

    mounted() {
        // DailyLineをクリック時にイベントを作成するイベント処理を追加します。
        // 他のDailyLineと区別するためにidを指定します。
        this.updateBgColor()
    }

    updateBgColor() {
        let _class = ``
        _class += this.ev.type == `optional_add` ? `orangeBg ` : `blackbrownBg z1 `
        _class += this.ev.is_force ? `force z2 ` : ``
        _class += this.classes
        this.addtionalClass = _class
    }

    public deleteOptionalEvent(ev: Event) {
        // let type = ev.type == `optional_add` ? `add` : `remove`
        this.$emit(`deleteOptionalEvent`, ev)
    }

    // 編集中のイベント情報をリセットします.
    resetEvDic() {
        this.currentEvent = null
        this.editEvDic = { start_cell: 0, duration: 0 }
    }

    move(data) {
        if (!this.currentEvent) return
        let height = data.h

        // 現在の開始時間のセル番号
        // let start = this.currentEvent.start_hour * 4 + this.currentEvent.start_min / 15

        let startCell = Math.round((data.y - 40) / this.CELL_HEIGHT)
        // つかんでいる時間幅
        let durationCell = Math.round(height / this.CELL_HEIGHT)
        let editEvDic = { start_cell: startCell, duration: durationCell }

        this.editEvDic = editEvDic

        Logger(`data: height ${height}, top: ${data.y}`)
        Logger(`startCell: ${startCell}, duration: ${durationCell}`)
    }

    startDrag(ev: Event) {
        this.currentEvent = ev
        Logger(`ドラッグ開始: ${Util.output(this.currentEvent)}`)
        this.$emit(`startDrag`, this.currentEvent)
    }

    // 同じ日付で選択されているか.
    get isSelectingSameDate() {
        if (Util.isBlank(this.cm.selectedEvent)) return false
        let _ev = this.cm.selectedEvent
        if (_ev.short_format.includes(this.date.toFormat(`MMdd`))) {
            return true
        }
        return false
    }

    // 誤クリックみたいな初期状態のDicが送られてきた場合、変更を防ぎます.
    isEmptyEvDic(dic) {
        if (Util.isBlank(dic)) return true
        if (dic.start_cell == 0 && dic.duration == 0) return true
        return false
    }

    endDrag() {
        Logger(`ドラッグ終了: editEvDic:${Util.output(this.editEvDic)}`)
        if (!this.currentEvent) return
        this.dragEnd = true
        if (this.isEmptyEvDic(this.editEvDic)) return

        // let ev = { ...this.currentEvent }
        this.$emit(`endDrag`, this.editEvDic)
        this.resetEvDic()
    }

    get isSelectingEvent() {
        if (!this.ev) return false
        if (!this.cm.selectedEvent) return false
        if (this.ev.id == this.cm.selectedEvent.id && this.ev.systemUpdatedAt == this.cm.selectedEvent.systemUpdatedAt) {
            return true
        }
        return false
    }

    clickEv(ev: Event) {
        Logger(`eventを押しました: ${Util.output(ev)}`)
        this.cm.resetSelectingEventsDic(this.cm.schedules, null)
        ev.isSelecting = true

        this.cm.selectedEvent = ev
        this.cm.checkOpenEventMenu()
        // sameEv = Event.isSame(selectedEv, ev)
        // this.showTooltip = true
    }

    /**
     * イベントを追加/可能日時に変更/不可日時に変更
     * @param type [string] cal,available,notAvailable
     */
    addEvent(type: string) {
        Logger(`${funcName()} ev.name:${this.ev.title}, type:${type}`)
        this.$emit(`addEvent`, this.ev, type)
    }
}
