
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"

// コンポーネントを読込.
// import SelectPossibleDatesModal from "packs/pages/link/modals/SelectPossibleDatesModal.vue"
import ThanksPage from "packs/pages/schedule/ThanksPage.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Const from "packs/utils/Const"
import RoomSetting from "packs/models/RoomSetting"
import { Logger } from "packs/common"

@Options({
    components: {
        ThanksPage,
        UserIcon,
    },
})
export default class ScheduleUnderContent extends Vue {
    // data:

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    astag: AvailableScheduleTag = null
    Util = Util
    Appointment = Appointment

    showThanksPage = false
    outOfTimeMessage = ``

    public mounted() {
        this.updateShowThanksPage()
    }

    @Watch(`rm.currentRoom`, { deep: true })
    updateShowThanksPage() {
        let room = this.rm.currentRoom
        if (!room) return
        if (room.room_type == `public_room`) return
        if (!Util.isPresent(room.public_room_id)) return
        if (!this.rm.userInfo) return

        let _appo = room.current_appointment

        let _mem = room.members.find(m => m.role == 10)
        Logger(`answerd_status: ${_mem ? _mem.answered_status : `null`}`)
        if (_mem && _mem.answered_status == `done` && (_appo.status == 10 || room.progress_status == `stay`)) {
            this.showThanksPage = true
        } else {
            this.showThanksPage = false
        }
    }

    get showOutOfDateContent() {
        let [isOpen, msg] = Util.isOpenTime(this.rm.currentRoom)
        if (msg) {
            if (msg == `out_of_start_time`) {
                this.outOfTimeMessage = `公開期間になりましたら受付可能となりますので、しばらくお待ち下さい。`
            }
            if (msg == `out_of_end_time`) {
                this.outOfTimeMessage = `受付終了しました。`
            }
        }
        return !isOpen
    }
}
