
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import DailyLine from "packs/pages/link/parts/available_schedule/DailyLine.vue"
import CalendarEventDetailModal from "packs/pages/link/parts/available_schedule/CalendarEventDetailModal.vue"
import MessagesLoader from "packs/components/loader/MessagesLoader.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import Event from "packs/models/Event"
import HeaderControl from "packs/utils/HeaderControl"
import FormUtil from "packs/utils/FormUtil"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Const from "packs/utils/Const"
import Appointment from "packs/models/Appointment"

// declare var activeStatus: string; //focus=このページにきた, blur=はずれた
// var activeStatus = ``;

@Options({
    components: {
        UserIcon,
        CalendarIcon,
        TextfieldTag,
        DailyLine,
        CalendarEventDetailModal,
        MessagesLoader,
    },
})
export default class CalendarsMenu extends Vue {
    // data:

    @Prop()
    isPublic: boolean

    @Prop() // func
    getPossibles

    @Prop() // func
    resetPossibleDates

    rm = RoomManager
    cm = CalendarManager
    pdm = PossibleDatesManager

    isSP = isMobile(window.navigator).phone
    Util = Util
    astags: AvailableScheduleTag[] = null
    ctags: CalendarTag[] = null

    openMenu = false
    selectedTabName = `ctags` // astag, ctags
    selectedCtags: CalendarTag[] = []
    selectedCtagEmails: string[] = []
    resultsCtags: CalendarTag[] = []
    systemUpdatedAt = Util.getSec()
    CONTENT_WIDTH = `320px`

    searchText = ``
    searchedCtags: CalendarTag[] = [] // 検索結果に表示するCtags

    // カレンダー選択画面: select, カレンダーからその日程の予定を表示する画面: events
    pageType = `select`
    displayEvents = null
    alldayEvents = null
    conflictEventIds = []
    DateTime = DateTime
    hours = Util.selectHours
    Const = Const

    // selectedTab = `schedule` // スマホタブの場合:  appo / schedule

    mounted() {
        this.updateUserInfo()
        this.updateAstags()
    }

    @Watch(`pdm.room`)
    updateRoom() {
        this.updateUserInfo()
    }

    @Watch(`pdm.userInfo`, { deep: true })
    updateUserInfo() {
        if (!this.rm.userInfo) return
        if (!this.rm.didConnectCal) return
        if (this.rm.userInfo.group_status < 10000) return
        if (Util.isBlank(this.rm.currentRoom)) return
        // 有料契約の場合のみ、ctagsを取得.
        Logger(`CalendarsMenu: ctagsがありました? ${Util.output(this.cm.ctList)}`)

        if (Util.isPresent(this.cm.ctList)) {
            let _ctags = [...(this.cm.ctList.filter(ct => ct.writable) || [])]
            let ct = _ctags.find(ct => ct.is_provider_default)
            if (ct && !this.selectedCtagEmails.includes(ct.email)) {
                this.openMenu = true
                this.selectCtag(ct)
                this.confirmPossibles()
            }
            this.ctags = _ctags
        } else {
            this.cm.getCalList().then(ctags => {
                this.ctags = (ctags || []).filter(ct => ct.writable)
                let ct = this.ctags.find(ct => ct.is_provider_default)
                if (ct) {
                    this.openMenu = true
                    this.selectCtag(ct)
                    this.confirmPossibles()
                }
            })
        }
    }

    clickDisplayEvents() {
        this.pageType = `events`
        this.defaultPosition()
        sleep(60).then(_ => {
            this.updateMouseoverPd()
        })
    }

    @Watch(`rm.astags`, { deep: true })
    updateAstags() {
        if (!this.rm.userInfo) return
        if (this.rm.userInfo.group_status < 10000) return
        if (Util.isBlank(this.rm.astags)) return
        Logger(`CalendarsMenu:updateAstags[${this.rm.astags.length}] ${Util.output(this.rm.astags)}`)

        // 有料契約の場合のみ、astagsを取得.（外部カレンダー連携しているカレンダーのみを表示）
        this.astags = (this.rm.getAllAstags() || []).filter(ast => ast.can_connect_calendar)
    }

    showCalendars(): boolean {
        Logger(`showCalendars: ${this.pdm.displayType}, openMenu: ${this.openMenu}`)
        if (Util.isPreview()) return false
        if (this.pdm.currentStep != `calendar`) return false
        if (!this.pdm.appo) return false
        if (!this.pdm.userInfo) return false

        if (this.pdm.userInfo.group_status < 10000) return false
        if (!this.rm.didConnectCal) return false
        if (this.pdm.displayType == `suggested`) return false
        if ([10, -1].includes(this.pdm.appo.status)) return false
        if (this.pdm.room && this.pdm.room.progress_status == `stay`) return false

        if (this.pdm.displayType == `reschedule`) return true

        if (!this.pdm.currentDateId && this.pdm.possibleDates && this.pdm.possibleDates.length == 0) {
            this.displayEvents = []
            return
        }

        // if (this.pdm.displayType==`possibles`)
        return true

        // return (![10, -1].includes(this.pdm.appo.status) && !this.pdm.didShare()) || this.pdm.displayType == `reschedule`
    }

    openCalendarsMenu() {
        if (this.openMenu) {
            this.openMenu = false
        } else {
            this.openMenu = true
        }
    }

    selectCategoryTab(category: string) {
        Logger(`selectCategoryTab.category: ${category}`)
        this.selectedTabName = category
    }

    selectCtag(ctag: CalendarTag) {
        let _ct = this.selectedCtags.find(ct => ct.email == ctag.email)
        if (_ct) {
            let ctags: CalendarTag[] = []
            for (let _ctag of this.selectedCtags) {
                if (_ctag.email != ctag.email) {
                    ctags.push(_ctag)
                }
            }
            this.selectedCtags = ctags
        } else {
            this.selectedCtags.push(ctag)
        }

        this.selectedCtagEmails = this.selectedCtags.map(ct => ct.email)

        Logger(`selectedCtagEmails: ${Util.output(this.selectedCtagEmails)}`)
        this.cm.registrable_attendees = this.selectedCtags
    }

    selectAstag(astag: AvailableScheduleTag) {
        this.cm.astag = astag
        if (Util.isPresent(this.astags)) {
            // let _astag = this.astags.find(ast => ast.id==astag.id)
            let _astags = [...(this.astags || [])]
            for (let _astag of _astags) {
                if (_astag.id == astag.id) {
                    _astag.selected = true
                } else {
                    _astag.selected = false
                }
            }
            this.astags = _astags
        }
    }

    resetSelectAstag() {
        this.cm.astag = null
        let _astags = [...(this.astags || [])]
        for (let _astag of _astags) {
            _astag.selected = false
        }
        this.astags = _astags
    }

    checkCanSend(): boolean {
        if (this.selectedTabName == `astags`) {
            if (Util.isPresent(this.cm.astag)) {
                return true
            }
            return false
        } else if (this.selectedTabName == `ctags`) {
            if (Util.isPresent(this.selectedCtagEmails)) {
                return true
            }
            return false
        }
        return false
    }

    // 空いている日程を確認します.
    confirmPossibles() {
        if (!this.checkCanSend()) return
        Logger(`confirmPossibles: ${Util.output(this.selectedCtagEmails)}`)
        this.selectedCtags = []

        this.resetPossibleDates()
        this.displayEvents = null
        this.pdm.check_events_info = null
        let useCtags = []
        if (this.selectedTabName == `ctags`) {
            gtagClick(`空いている日程を確認 ctags`, `${this.selectedCtags.length}`)
            if (this.cm.astag) this.resetSelectAstag()
            let _ctags = this.cm.ctList.filter(ct => this.selectedCtagEmails.includes(ct.email))
            this.cm.registrable_attendees = _ctags
            this.selectedCtags = _ctags
            useCtags = _ctags

            this.getPossibles(true, null, this.cm.astag, _ctags)
        } else {
            if (Util.isPresent(this.selectedCtagEmails)) this.selectedCtagEmails = []
            gtagClick(`空いている日程を確認 astags`, `${this.cm.astag.id}`)
            if (Util.isPresent(this.cm.astag.meeting_attendees_groups[0])) {
                this.cm.registrable_attendees = this.cm.astag.meeting_attendees_groups[0].required_attendees
                useCtags = this.cm.astag.meeting_attendees_groups[0].required_attendees || []
            }

            this.getPossibles(true, null, this.cm.astag, this.cm.registrable_attendees)
        }
        this.resultsCtags = [...(useCtags || [])]
        this.pageType = `events`
    }

    closeMenu() {
        this.openMenu = false
    }

    /***
     * 検索した際に表示するカレンダー絞り込みを行います。
     */
    public searchCalnedarValue(e) {
        Logger(`searchCalnedarValue ${e}`)

        let searchedCtags = []
        let selectedCtagEmails: string[] = []
        if (Util.isPresent(this.selectedCtags)) {
            selectedCtagEmails = this.selectedCtags.map(c => c.email) || []
        } else if (this.cm.registrable_attendees) {
            selectedCtagEmails = this.cm.registrable_attendees.map(c => c.email) || []
        }

        let list = [...this.cm.ctList]
        Logger(`selectedCtagEmails ${selectedCtagEmails}`)
        if (Util.isBlank(e)) {
            searchedCtags = list
        } else {
            searchedCtags = list.filter(ctag => {
                if ((ctag.name.includes(e) || ctag.email.includes(e)) && !selectedCtagEmails.includes(ctag.email)) {
                    return ctag
                }
            })
        }
        this.searchText = e
        this.searchedCtags = searchedCtags
        if (Util.isPresent(this.searchedCtags)) {
            this.ctags = this.searchedCtags
        }

        Logger(`検索後のカレンダー: ${Util.output(this.searchedCtags)}`)
    }

    public clickSearchCalendarField() {
        Logger(`clickSearchCalendarField: `)
        this.searchCalnedarValue(this.searchText)
    }

    public blurSearchCalendarField() {
        Logger(`blurSearchCalendarField: `)
        // this.showSuggests = false;
    }

    @Watch(`pdm.check_events_info`, { deep: true })
    public changePossibleDates() {
        if (!this.pdm.checkConflicts) return
        const evInfo = this.pdm.check_events_info
        if (Util.isBlank(evInfo)) {
            if (this.pdm.currentDateId) {
                this.displayEvents = []
            }

            return
        }
        if (!this.pdm.currentDateId && this.pdm.possibleDates && this.pdm.possibleDates.length == 0) {
            this.displayEvents = []
            return
        }
        this.updateCurrentDateId()
    }

    @Watch(`pdm.currentDateId`)
    public updateCurrentDateId() {
        const dateId = this.pdm.currentDateId
        Logger(`日付を変更しました: イベント表示を変更します1: ${dateId}`)
        if (Util.isBlank(dateId)) return

        const evInfo = this.pdm.check_events_info // astag,ctags,daily_events
        if (Util.isBlank(evInfo)) {
            this.displayEvents = []
            return
        }

        let allDailyEvs = evInfo.daily_events
        if (Util.isBlank(allDailyEvs)) {
            this.displayEvents = []
            return
        }

        let evs: Event[] = allDailyEvs[dateId]
        this.displayEvents = evs || []
        let alldayEvents = (evs || []).filter(ev => ev.allday)
        this.alldayEvents = alldayEvents
        this.pageType = `events`
        Logger(`日付を変更しました${dateId}: イベント表示を変更します2: ${Util.output(this.displayEvents)}`)
        this.defaultPosition()
    }

    clickBack() {
        this.pageType = `select`
    }

    @Watch(`pdm.mouseoverPd`)
    public updateMouseoverPd() {
        Logger(`updateMouseoverPd1`)
        if (!this.pdm.checkConflicts) return
        if (Util.isBlank(this.displayEvents)) return
        if (!this.pdm.mouseoverPd) return
        Logger(`updateMouseoverPd2`)

        let _ele = document.getElementById(`timeindex${this.pdm.mouseoverPd.start_hour - 1}`)
        if (_ele) {
            _ele.scrollIntoView({
                behavior: "smooth",
                block: "start",
            })
        }
    }

    showEventDetail() {
        this.$vfm.open(`CalendarEventDetailModal`)
    }

    defaultPosition() {
        if (!this.pdm.currentDateId) return

        let pd = Util.isPresent(this.pdm.displayPds) ? this.pdm.displayPds.find(_pd => !_pd.is_conflict) || null : null
        Logger(`選択中の日付の最初のpd: ${Util.output(pd)}`)

        if (!pd) return

        sleep(50).then(_ => {
            this.pdm.mouseoverPd = pd
        })
    }
}
