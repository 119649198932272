
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {},
})
export default class MailPreviewPartsSelectableMags extends Vue {
    // 確定済みまたは、受付済みの場合は1つ目のみを表示します.
    @Prop()
    isFix: boolean
    // data
    isSP = isMobile(window.navigator).phone

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util

    created() {}

    mounted() {}
}
