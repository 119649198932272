import axios from "axios"
import { zeroPadding, Logger } from "../common"
import Notice from "./Notice"
import Util from "packs/utils/Util"
import NotificationControl from "packs/utils/NotificationControl"

export default class ZapierNotification {
    /**
     *
     * @param id
     * @param is_active
     * @param scope
     * @param api_key
     */
    constructor(public id: string, public is_active: boolean, public scope: string, public api_key: string) {}

    static fetchFromJson(groupsJson: any): ZapierNotification[] {
        let notifications = []
        if (Util.isBlank(groupsJson) || groupsJson.length == 0) return notifications
        for (const noti of groupsJson) {
            let _noti: ZapierNotification = noti
            notifications.push(_noti)
        }
        return notifications
    }

    static createDefault(scope: string = `user_group`): ZapierNotification {
        return new ZapierNotification(`new`, false, scope, null)
    }

    /**
     * Scopeに対応するエンドポイントを返却します。
     * @param scope [string] user/user_group
     */
    static endpoint(scope: string) {
        if (scope == "user_group") {
            return `${Util.prefixUrl}/user_groups/zapier`
        } else if (scope == "user") {
            return `${Util.prefixUrl}/user_settings/zapier`
        } else {
            Notice.message = `不正なリクエストです。(scope:${scope} is unknown.)`
            return
        }
    }

    /**
     * Zapierの有効化もしくは、API_KEYを更新します。
     * @param noti [ZapierNotification]
     * @param need_update [boolean]
     */
    static save(noti: ZapierNotification, need_update: boolean) {
        Logger(`ZapierNotification.save: ${Util.output(noti)}, need_update:${need_update}`)

        let dic = { need_update: need_update }
        return axios({
            method: `post`,
            url: ZapierNotification.endpoint(noti.scope),
            data: dic,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                let noti = ZapierNotification.fetchFromJson([res.data.notification])[0]
                return noti
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return
            })
    }
}
