
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import VotedRoomManager from "packs/models/VotedRoomManager"

@Options({
    components: {},
})
export default class SuggestedDateCell extends Vue {
    // data:

    @Prop()
    mem: RoomMember

    @Prop()
    pd: PossibleDate

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    loading = false
    Util = Util

    pdm = PossibleDatesManager

    now = Util.getSec()

    myPd = null

    mounted() {
        if (this.mem && this.pd) {
            let confInfoStartTimes = this.mem.available_schedule_tag_id
                ? this.vrm.astagFixTimeDic[this.mem.available_schedule_tag_id]
                : null
            let _pd = this.mem.suggested_dates.find(sd => sd.start_time == this.pd.start_time)
            if (_pd) {
                if (confInfoStartTimes && confInfoStartTimes.includes(_pd.start_time)) {
                    _pd.conflict_info = PossibleDate.createDefaultConflictInfo()
                } else {
                }
                this.myPd = _pd
            }
        }
    }
}
