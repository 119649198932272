
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import { Dropdown } from "uiv"

// モデルを読込.
import Room from "packs/models/Room"
import AssignForm from "packs/models/AssignForm"

@Options({
    components: { Dropdown },
})
export default class AssignFormEditMenu extends Vue {
    @Prop()
    aform: AssignForm

    @Prop()
    canDelete: boolean

    isSP = isMobile(window.navigator).phone
    Room = Room
    now = DateTime.local()

    public mounted() {}

    public copyPage() {
        this.$emit(`copyPage`)
    }

    public editPage() {
        this.$emit(`editPage`)
    }

    public clickEditMenu() {
        this.$emit(`clickEditMenu`)
    }

    get showEditColumn() {
        // if (this.room.is_owner || this.room.is_shared) return true
        return true
    }

    showCloseRoomModal() {
        this.$emit(`showCloseRoomModal`)
    }
}
