import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, onlyUnique } from "packs/common"

import Room from "./Room"
import Util from "packs/utils/Util"
import RoomMember from "./RoomMember"

export default class SearchedRoomsInfo {
    constructor(
        public rooms: Room[],
        public statuses: number[],
        public last_ids: string[], // 最後のparent_room_id
        public last_search_at: number,
        public has_next: boolean,
        public total: number,
        public next_page: number,
        public user_filter: string,
        public category: string,
        public categoryName: string,
        public text: string
    ) {}

    // 表示タブ情報
    static readonly CATEGORIES = [`roomId`, `email`, `mag`, `member`]

    // 簡易検索窓で検索するカテゴリーを選択します.
    static readonly getSelectableCategories = [`pubRoomName`, `roomId`, `email`]

    static getSelectableNames() {
        let names = []
        for (let name of this.getSelectableCategories) {
            names.push(this.getCategoryName(name))
        }
        return names
    }

    static readonly categoryDic = {
        roomId: `調整ページID`,
        email: `メールアドレス`,
        mag: `担当`,
        member: `メンバー`,
        pubRoomName: `公開ページ名`,
    }

    static getCategoryName(cat: string): string {
        return this.categoryDic[cat]
    }

    static copy(t: SearchedRoomsInfo): SearchedRoomsInfo {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(t)), t)
        return clone
    }

    static createSearchInfo(cat: string, rooms: Room[], text: string) {
        let info = new SearchedRoomsInfo(rooms, null, null, null, false, 20, null, null, cat, this.getCategoryName(cat), text)
        return info
    }
}
