
// モジュールを読込.
import { Options, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import PossibleDateLi from "packs/pages/link/parts/PossibleDateLi.vue"
import CalendarTable from "packs/components/calendar/CalendarTable.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import CalendarUtil from "packs/utils/CalendarUtil"

@Options({
    components: {
        PossibleDateLi,
        CalendarTable,
    },
})
export default class PossibleDatesContent extends Vue {
    // @Prop()
    // updateAttendees

    @Ref()
    CalendarTable

    cm = CalendarManager
    rm = RoomManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil

    // list ctagリストを表示
    // calendar CalendarTableを表示（）
    displayType = `list`
    isOpenDates = false

    dailyDic = null
    totalNum = 0

    monthlyPossibleDates = []
    currentDateId = null
    loading = false

    public mounted() {
        this.updatePossibleDates()
    }

    @Watch(`cm.currentPossibleDates`, { deep: true })
    updatePossibleDates() {
        if (!this.cm || Util.isBlank(this.cm.dailyPossibleDates)) return
        Logger(`PossibleDatesContent#updatePossibleDates ${Util.output(this.cm.currentPossibleDates)}`)

        this.dailyDic = this.cm.dailyPossibleDates
        this.totalNum = (this.cm.currentPossibleDates || []).length
        // let totalNum = 0
        // this.cm.pdm.possibleDates.length
        // for (let datedic of Object.values(this.dailyDic)) {
        //     let _dic = datedic[this.rm.userInfo.user_id].group_events_dic[`A`]
        //     totalNum += (_dic.possible_events || []).length
        // }
        // this.totalNum = totalNum
        let cannotFindFormat
        let datekeys
        ;[this.monthlyPossibleDates, cannotFindFormat, datekeys] = PossibleDate.getAvailableCalendarsFromPds(
            this.cm.currentPossibleDates
        )
        Logger(`this.pdm.possibleDates: ${Util.output(this.pdm.possibleDates)}`)
    }

    removeDate(pd: PossibleDate) {
        const datestr = DateTime.fromSeconds(pd.start_time).toFormat(`MMdd`)
        Logger(`ConnnectedCalendar#removeDate: pd: ${Util.output(pd)}`)
        this.cm.deletePossibleDates(pd)
        // let evsDic = (this.cm.dailyPossibleDates || {})[datestr] || {}
        // const name = this.cm.displayMagName || `A`
        // let _dic = { ...evsDic[this.rm.userInfo.user_id].group_events_dic[name] }
        // _dic.possibleDates = _dic.possible_events.filter(_pd => !PossibleDate.isSame(_pd, pd))
        // this.pdm.possibleDates = this.pdm.possibleDates.filter(_pd => !PossibleDate.isSame(_pd, pd))
        // this.cm.dailyPossibleDates[datestr][this.rm.userInfo.user_id].group_events_dic[name] = _dic
        // this.cm.currentPossibleDates = this.cm.currentPossibleDates.filter(_pd => !PossibleDate.isSame(_pd, pd))
    }

    openDatesContent() {
        if (this.isOpenDates) {
            this.isOpenDates = false
        } else {
            this.isOpenDates = true
        }
    }

    public selectDateFromCal(idName: string) {
        Logger(`PossibleDatesContent#selectDateFromCal idName: ${idName}`)
        // this.currentDateId = idName
        // その週まで週次の表示を移動させる
        this.pdm.gotoDateWithId(idName)
    }

    /**
     * @param calId [string] "202106"
     */
    clickPrevMonth(calId: string) {
        if (!this.pdm) return
        if (this.pdm.available_months.includes(calId) && !(this.pdm.months || []).includes(calId)) {
            Logger(`未取得のためリモートから取得します.: ${calId} , months: ${this.pdm.months}`)
            // this.getPossiblesWithMonth(calId)
        }
    }

    /**
     * @param calId [string] "202106"
     */
    clickNextMonth(calId: string) {
        if (!this.pdm) return
        if (this.pdm.available_months.includes(calId) && !(this.pdm.months || []).includes(calId)) {
            Logger(`未取得のためリモートから取得します.: ${calId} , months: ${this.pdm.months}`)
            // this.getPossiblesWithMonth(calId)
        }
    }
}
