
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import AttendeeForm from "packs/pages/link/parts/AttendeeForm.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"
import Appointment from "packs/models/Appointment"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import Notice from "packs/models/Notice"
import Refresher from "packs/models/Refresher"

@Options({
    components: {
        TextfieldTag,
        UserIcon,
        RoomsLoader,
        UserFileImage,
        AttendeeForm,
        CheckboxTag,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class EditAttendeesModal extends Vue {
    room: Room = null
    appo: Appointment = null
    mags: MeetingAttendeesGroup[] = null
    selectedMag: MeetingAttendeesGroup = null

    // data
    rm = RoomManager

    loading = false
    isMe = false

    results = null
    Util = Util
    attendees: RoomMember[] = []
    refresher = Refresher
    isSP = isMobile(window.navigator).phone

    // currentRoom: Room = null

    openModal() {
        gtagPage(`#/出席者変更モーダル`)
        this.updateAttendees()
    }

    hide() {
        this.$vfm.close(`EditAttendeesModal`)
    }

    updateAttendees() {
        this.attendees = this.rm.currentRoom.attendees
    }

    addAttendeeFromAddressBook(member: RoomMember) {
        let att = this.attendees.find(att => att.email == member.email)
        if (!att) {
            if (this.loading) return
            this.loading = true
            RoomMember.addRoomMember(this.rm.currentRoom, member, this.rm.userInfo).then(_room => {
                if (_room) {
                    this.attendees = _room.attendees
                    // this.attendees.push(_mem)
                    gtagClick(`出席者を追加`, ``)
                    this.rm.updateCurrentInfo(_room)
                    this.refresher.needRefresh.push(`room`)
                }
                this.loading = false
            })
        } else {
            Notice.message = `すでにメールアドレスは追加してあります。`
        }
    }

    deleteAttendee(att: RoomMember) {
        if (this.loading) return
        this.loading = true
        RoomMember.removeRoomMember(this.rm.currentRoom, att, this.rm.userInfo).then(_room => {
            if (_room) {
                this.attendees = _room.attendees
                gtagClick(`出席者を削除`, ``)

                this.rm.updateCurrentInfo(_room)
                this.refresher.needRefresh.push(`room`)
            }
            this.loading = false
        })
    }

    save() {
        if (this.loading) return
        this.loading = true

        // MeetingAttendeesGroup.changeAssignedMag(this.room, this.selectedMag, this.notifyMembers).then(dic => {
        //     if (dic) {
        //         let room = { ...this.room }
        //         let appo = dic.appo
        //         room.current_appointment = appo
        //         this.rm.updateCurrentInfo(room)
        //         this.hide()
        //     }
        //     this.loading = false
        // })
    }

    cancel() {
        this.hide()
    }
}
