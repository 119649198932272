
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import WeekdayButtons from "packs/components/buttons/WeekdayButtons.vue"
import AvailableDaysOfWeeksTableCell from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTableCell.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import AstagSearchBox from "packs/pages/link/parts/available_schedule/AstagSearchBox.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Room from "packs/models/Room"
import RoomSetting from "packs/models/RoomSetting"
import UserSetting from "packs/models/UserSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import CalendarManager from "packs/models/CalendarManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import AstagsTab from "packs/models/fetcher/AstagsTab"

@Options({
    components: {
        CalendarIcon,
        Switcher,
        MdTextfieldTag,
        SelectTag,
        AvailableDaysOfWeeksTableCell,
        WeekdayButtons,
        IconHelp,
        AstagSearchBox,
    },
})
export default class LeftNavPanelCalendarContent extends Vue {
    // data:
    @Prop() // rules_by_ai_astag / astag
    panelType: string

    rm = RoomManager
    rcm = RoomCreateManager
    cm = CalendarManager
    pdm = PossibleDatesManager

    isSP = isMobile(window.navigator).phone

    notice = Notice
    Util = Util
    CalendarUtil = CalendarUtil
    saving = false

    astags: AvailableScheduleTag[] = null
    notAttendAstag: AvailableScheduleTag = null

    systemUpdatedAt = Util.getSec()
    astagsTab: AstagsTab = null
    loading = false

    public mounted() {
        if (this.rm.astagsTab?.tab_name == `shared`) {
            this.rm.astagsTab.tab_name = `default`
        }
        this.updateAstags()
    }

    getAllAstags() {
        let astagsTab = this.rm.astagsTab
        if (!astagsTab) return []
        this.notAttendAstag = this.rm.astags.find(a => a.type == 10)

        return astagsTab.astags
    }

    @Watch("rcm.room", { deep: true })
    updateAstags(forceUpdate = false) {
        if (!forceUpdate && Util.isPresent(this.astags)) return
        if (!this.rm.astags) return
        Logger(`${funcName()} owner_astag:${this.rcm.room.owner_avaialble_schedule_tag_id}, rcm.astag:${this.rcm.astag?.id}`)
        // if (Util.isBlank(this.rcm.room)) return
        this.astagsTab = this.rm.astagsTab

        // 作成済みの調整カレンダーを取得.
        let astags = this.getAllAstags()

        this.astags = astags
        // let astagId = this.rcm.room.owner_avaialble_schedule_tag_id
        let astagId = this.rcm.astag.id
        if (Util.isBlank(astagId)) {
            astagId = this.rm.astag.id
        }

        let currentAstag = this.chooseAstag(astagId)
        this.systemUpdatedAt = Util.getSec()
        this.rcm.updateAstag(currentAstag)
    }

    @Watch(`rm.astagsTab.searched_astags`, { deep: true })
    updateSearchedAstags() {
        Logger(
            `${funcName()} selectedCategoryTabName:${this.rm.astagsTab?.tab_name}, rm.searched_astags:${
                this.rm.astagsTab?.searched_astags?.length
            }`
        )
        if (!this.rm.astagsTab) return
        if (this.rm.astagsTab.tab_name != `search`) return
        this.astagsTab = this.rm.astagsTab
        this.astags = this.rm.astagsTab.searched_astags
    }

    public createNewAstag() {
        Logger(`新規調整カレンダーを作成を押下しました.`)

        // テンポラリーなastagを作成し、最初から週次のビューに移動させます.

        let _astag = AvailableScheduleTag.createDefaultAstag()
        let _dt = DateTime.local()
        _astag.name = `${_dt.toFormat("yyyyMMdd HH:mm")}`

        this.cm.setNew(this.rm.userInfo, this.rm.user_setting, _astag, this.rcm.room_setting, true, this.rcm.appo)
        this.getMagsEventsAndPossibleDates()
        $(".leftNavPanel").removeClass("open")
        this.rcm.edittingAstag = _astag

        // this.$router.push("/available_schedule")
    }

    public chooseAstag(astag_id: string): AvailableScheduleTag {
        let astags = [...this.astags]
        let astag = null
        for (let _astag of astags) {
            if (_astag.id == astag_id) {
                _astag.selected = true
                astag = _astag
            } else {
                _astag.selected = false
            }
        }
        if (!astag) {
            let chukaiAstags = this.astagsTab.shared_chukai_astags
            let chukaiAstag = chukaiAstags.find(ast => ast.id == astag_id)
            if (chukaiAstag) {
                astag = chukaiAstag
            }
        }
        this.astags = astags
        return astag
    }

    public refreshPreviewSchedule() {
        if (!this.rcm.astag) return
        Logger(`スケジュール表示を更新 `)

        let _astag = { ...this.rcm.astag }
        _astag.systemUpdatedAt = Util.getSec()
        this.rcm.updateAstag(_astag)
    }

    public selectAstag(astag: AvailableScheduleTag) {
        Logger(`${funcName()} astagを選択:${astag.name}`)
        if (this.panelType == `rules_by_ai_astag`) {
            let rule = this.rcm.edittingRule
            rule.available_schedule_tag_id = astag.id
            rule.astag_info = astag
            this.rcm.updateEdittingRule(rule)
            this.closePanel()
            return
        }

        if (Util.isBlank(this.rcm.astag) || this.rcm.astag.id != astag.id) {
            this.rm.getAstagDetail(astag.id).then(res => {
                this.rm.endProgress()
                this.loading = false
                if (res) {
                    let astag = res
                    this.chooseAstag(astag.id)
                    this.systemUpdatedAt = Util.getSec()
                    this.resetAssingedUserIfIsInstantRoom()
                    this.closePanel()
                    this.rcm.room.is_shared_chukai = false
                    this.rcm.updateAstag(astag)
                }
            })
        }
    }

    // 公開ページがスグINボタンで、紐付いている場合は、リセットします.
    resetAssingedUserIfIsInstantRoom() {
        Logger(`${funcName()}`)
        if (!this.rcm.room_setting) return
        let rs = this.rcm.room_setting
        if (!rs.is_instant_room) return
        let room = this.rcm.room
        if (!room) return
        let laus = room.instant_room_assigned_users
        if (Util.isBlank(laus)) return
        Logger(`${funcName()} 調整カレンダーに紐付いていたl_assigned_usersをすべてリセットします.`)
        room.instant_room_assigned_users = []
        this.rcm.updateRoom(room)
    }

    public clickEditAstag(astag: AvailableScheduleTag) {
        if (this.panelType == `rules_by_ai_astag`) {
            let rule = this.rcm.edittingRule
            rule.available_schedule_tag_id = astag.id
            rule.astag_info = astag
            this.rcm.updateEdittingRule(rule)
            this.closePanel()
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        Logger(`astagを調整ページ編集中に編集`)
        this.rm.getAstagDetail(astag.id).then(res => {
            this.rm.endProgress()
            this.loading = false
            if (res) {
                let astag = res
                this.cm.setNew(this.rm.userInfo, this.rm.user_setting, astag, this.rcm.room_setting, true, this.rcm.appo)
                if (this.cm.astag) {
                    if (this.rm.didConnectCal) {
                        // カレンダーリストを取得.
                        Logger(`リモートからカレンダーリストを取得します。`)

                        this.cm.getCalList().then(res => {
                            // this.PreviewContent.getEvents()

                            this.getMagsEventsAndPossibleDates()
                        })
                    } else {
                        this.getMagsEventsAndPossibleDates()
                    }
                }
                $(".leftNavPanel").removeClass("open")
                this.rcm.edittingAstag = this.cm.astag
            }
        })
    }

    getMagsEventsAndPossibleDates() {
        // magごとにイベントを取得します.
        this.cm.updateMagName(`A`)
        // if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) {
        //     this.cm.pdm = PossibleDatesManager
        //     let today = DateTime.local()
        //     let nextMonth = today.plus({ month: 1 })
        //     this.cm.pdm.available_months = [today.toFormat(`MMdd`), nextMonth.toFormat(`MMdd`)]
        //     return
        // }
        let selectedMagName = this.cm.displayMagName
        // 表示を初期値に戻す.
        this.cm.getMagEvents(null, selectedMagName).then(e => {
            this.cm.getPossibleDatesEvents()
        })
    }

    public closePanel() {
        this.$emit(`closePanel`)
    }

    inputSubmitNum(e) {
        Logger(e)
        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.submit_lower_limit_num = e
        this.updateRoomSetting(setting)
    }

    updateRoomSetting(setting: RoomSetting) {
        this.rcm.updateRoomSetting(setting)
    }

    deleteAdow(index: number) {
        let rs = { ...this.rcm.room_setting }
        rs.available_start_times.splice(index, 1)
        this.rcm.updateRoomSetting(rs)
    }

    useNotAttendCalendar() {
        if (this.rcm.astag.type == 10) {
            // 適用をはずします.
            const _astag = this.astags[0]
            this.selectAstag(_astag)
        } else {
            // 不参加カレンダーを適用.
            this.rm.getAstagDetail(`not_attend`).then(res => {
                if (res) {
                    this.notAttendAstag = res
                    this.selectAstag(this.notAttendAstag)
                }
            })
        }
    }

    public getNextPage() {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        AstagsTab.getNextAstags(this.astagsTab, `default`).then(res => {
            this.rm.endProgress()
            this.loading = false
            if (res) {
                this.rm.updateAstagsInfoWithTab(res.tab)
                this.updateAstags(true)
            }
        })
    }

    changeCategoryTab(tabname) {
        this.rm.astagsTab.tab_name = tabname
        this.updateAstags(true)
    }
}
