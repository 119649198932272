import { zeroPadding, Logger } from "packs/common"
import Util from "packs/utils/Util"
import NotificationControl from "packs/utils/NotificationControl"

export default class PlanOption {
    constructor(
        public calendar_condition_max_or_size: string,
        public calendar_condition_max_period: number,

        public room_restriction_ip_addresses: string,
        public room_restriction_email_domains: number,
        public room_custom_colors: number,
        public room_custom_font_families: number,
        public room_email_domain: number,
        public room_subdomain: boolean,
        public room_upload_file_in_form: boolean,
        public room_hidden_field_in_form: boolean,
        public room_custom_mail_flows: boolean,
        public room_instant_room: boolean,
        public room_send_sms: boolean,
        public room_display_schedule_rules_by_ai: boolean,
        public room_contact_list_group_register_max_num: number,
        public room_chukai_menu: boolean,
        public room_assign_form: boolean,

        public management_control_panel_ip_addresses: number,
        public management_webhook: boolean,
        public management_zapier: boolean,
        public management_hubspot: boolean,
        public management_salesforce: boolean,
        public management_google_spread_sheet: boolean,
        public management_api_key: boolean,
        public management_progresses: number,
        public management_slack: any,
        public management_teams: any,
        public management_google_analytics: any,
        public management_ga_cross_domains: boolean,
        public management_custom_permissions: boolean,
        public management_sso: boolean,
        public management_delete_all_dependent: boolean
    ) {}

    static canUseOption(po: PlanOption, optionName: string) {
        if (!po) return
        return po[optionName]
    }

    static toDisplayName(optionName: string) {
        let name = ``
        switch (optionName) {
            case `calendar_condition_max_or_size`:
                name = `OR件数上限`
                break
            case `calendar_condition_max_period`:
                name = `最大期間日数`
                break
            case `room_restriction_ip_addresses`:
                name = `IPアドレス制限`
                break
            case `room_restriction_email_domains`:
                name = `メールアドレス制限`
                break
            case `room_custom_colors`:
                name = `色カスタム変更`
                break
            case `room_custom_font_families`:
                name = `フォントカスタム変更`
                break
            case `room_email_domain`:
                name = `独自メールアドレスドメイン設定`
                break
            case `room_subdomain`:
                name = `独自ドメイン設定`
                break
            case `room_upload_file_in_form`:
                name = `フォームファイルアップロード`
                break
            case `room_hidden_field_in_form`:
                name = `フォーム隠し質問設定`
                break
            case `room_custom_mail_flows`:
                name = `カスタム通知設定`
                break
            case `room_instant_room`:
                name = `スグINボタン`
                break
            case `room_send_sms`:
                name = `SMS送信可能設定`
                break
            case `room_display_schedule_rules_by_ai`:
                name = `調整カレンダーAI自動選択設定`
                break
            case `room_chukai_menu`:
                name = `仲介メニュー設定`
                break
            case `room_assign_form`:
                name = `振り分けフォーム設定`
                break

            case `management_control_panel_ip_addresses`:
                name = `管理画面IPアドレス制限`
                break
            case `management_webhook`:
                name = `Webhook利用`
                break
            case `management_zapier`:
                name = `Zapier利用`
                break
            case `management_progresses`:
                name = `進捗画面`
                break
            case `management_slack`:
                name = `Slack通知`
                break
            case `management_teams`:
                name = `Teams通知`
                break
            case `management_google_analytics`:
                name = `Google Analytics設定`
                break
            case `management_ga_cross_domains`:
                name = `GAクロスドメイン設定`
                break
            case `management_custom_permissions`:
                name = `カスタム権限可能`
                break
            case `management_sso`:
                name = `SSO設定`
                break
            case `management_delete_all_dependent`:
                name = `データ削除`
                break
        }
        return name
    }
}
