
// モジュールを読込.
import { Options, Prop, Vue, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import DownloadConditionsContent from "packs/pages/link/parts/room_summary/DownloadConditionsContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import NotificationControl from "packs/utils/NotificationControl"
import FileUtil from "packs/utils/FileUtil"
import UserFile from "packs/models/UserFile"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        MdTextfieldTag,
        IconHelp,
    },
})
export default class DownloadableFileCell extends Vue {
    // data:
    @Prop()
    file: UserFile

    notice = Notice

    rm = RoomManager
    selectedTab = `download`
    Util = Util

    isSP = isMobile(window.navigator).phone
    // users: RoomMember[] = [];

    // invitedUserEmail = "";
    // invitedUserRole = 1;
    // subm = SubscriptionManager;

    beforeAdow: DateTag = null // オプショナルスケジュールが入っている場合、確認してadow(dtag)に追加します。
    showCustoms = false
    showSaveButton = false
    astag: AvailableScheduleTag = null

    Const = Const

    showNotAttendAstagSaveButton = false
    buttonName = ``
    saving = false

    publicId = null

    downloadableFiles: UserFile[] = null
    uf: UserFile = null

    mounted() {
        this.uf = this.file
    }

    get createdTime() {
        return Util.getTimeDateFormatFromSeconds(this.uf.created_at, "yyyy-MM-dd HH:mm")
    }

    get fileSize() {
        if (this.uf.status == `pending`) {
            return `-`
        }
        return this.uf.file_size
    }

    get statusName() {
        if (this.uf.status == `pending`) {
            return `準備中`
        }
        return `ダウンロード可能`
    }

    downloadFile(file: UserFile) {
        Logger(`${funcName()}`)
        UserFile.downloadDownloadableFile(file).then(res => {
            Logger(`res1 ${Util.output(res)}`)
            if (res && res.message && res.message.includes(`404`)) {
                Notice.message = `ファイルが見つかりませんでした`

                let files = this.rm.downloadableFiles.filter(f => f.file_id != file.file_id)
                this.rm.downloadableFiles = files
            }
        })
    }

    deleteFile(file: UserFile) {
        Logger(`${funcName()} file_id:${file.file_id}, file_name:${file.original_filename}`)
        UserFile.deleteDownloadableFile(file.file_id).then(result => {
            if (result) {
                let files = this.rm.downloadableFiles.filter(f => f.file_id != file.file_id)
                this.rm.downloadableFiles = files
            }
        })
    }

    editName() {
        this.$emit(`editName`, this.uf)
    }
}
