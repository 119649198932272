
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {},
})
export default class UsageContent extends Vue {
    // data
    isSP = isMobile(window.navigator).phone

    public created() {}

    public mounted() {
        // if (this.isSP) {
        //     useDefaultSlider(".usageContent");
        // }
    }
}
