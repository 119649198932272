import axios from "axios"
import { DateTime } from "luxon"
import NotificationControl from "packs/utils/NotificationControl"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import PossibleDate from "./PossibleDate"

export default class DailyStat {
    constructor(
        // "optional_add", "optional_remove", "possible_event", "event"
        public id: string,
        public daily_dic: any,
        public page_view_total: number,
        public page_view_weekly: number,

        public total_fixed_num: number,
        public total_fixed_pc_num: number,
        public total_fixed_sp_num: number,
        public total_pc_num: number,
        public total_sp_num: number,

        // 最終更新日時（アップデートした際にセルを動的に更新するために利用）
        public systemUpdatedAt: number
    ) {}

    /**
     * 指定した期間のStatsを取得します.
     * @param roomId PublicRoomのID
     * @param startDate UnixTime
     * @param endDate UnixTime
     */
    static getStats(roomIds: string[], startDate: number, endDate: number) {
        let params = {
            pub_room_ids: roomIds,
            start_date: startDate,
            end_date: endDate,
        }
        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/rooms/daily_stats`,
            data: params,
        })
            .then(res => {
                Logger(`DailyStat.getStats res:${Util.output(res.data.daily_stats)}`)
                let statsDic = res.data.daily_stats

                return statsDic
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)

                return null
            })
    }

    /**
     * 指定した期間のStatsを取得します.
     * @param roomId PublicRoomのID
     * @param startDate UnixTime
     * @param endDate UnixTime
     */
    static getAccessKeywordsStats(roomIds: string[], startDate: number, endDate: number) {
        let params = {
            pub_room_ids: roomIds,
            start_date: startDate,
            end_date: endDate,
        }
        return axios({
            method: `post`,
            url: `${Util.prefixUrl}/rooms/access_keywords_stats`,
            data: params,
        })
            .then(res => {
                Logger(`DailyStat.getAccessKeywordsStats res:${Util.output(res.data.access_keywords_stats)}`)
                let accessKeywordsStats = res.data.access_keywords_stats

                return accessKeywordsStats
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)

                return null
            })
    }
}
