import { DateTime } from "luxon"
import AvailableScheduleTag from "./AvailableScheduleTag"
import Util from "packs/utils/Util"
import axios from "axios"
import { Logger } from "packs/common"
import Notice from "packs/models/Notice"
import RoomMember from "./RoomMember"
import MeetingAttendeesGroup from "./MeetingAttendeesGroup"

interface AssignedMagInfo {
    fixed_appo_num: number
    mag_info: MeetingAttendeesGroup
    name: string
}

export default class ReportStat {
    static readonly typeDisplay: number = 1

    constructor(
        public id: string, //MeetingGroup_idが入る可能性があるので、ctag_idをみたい場合は、calendar_tag_idを参照すること.

        public time_spans: number[],
        public time_spans_last: number[],
        public parents: string[], // public_idのみ
        public parents_last: string[], // public_idのみ
        public messages: string[], //created_atのみ
        public messages_last: string[],
        public user: RoomMember,

        // {astag_id: {mag_id: MagInfo} }
        public fixed_appo_per_astag: { [key: string]: { [key: string]: AssignedMagInfo } },
        public astags_dic: { [key: string]: AvailableScheduleTag }
    ) {}
}
