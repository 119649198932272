
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, Logger } from "packs/common"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import VariableListsContent from "packs/pages/link/parts/room_settings/VariableListsContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomStorage from "packs/models/RoomStorage"
import MailFlow from "packs/models/MailFlow"
import RoomCreateManager from "packs/models/RoomCreateManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        TextfieldTag,
        LblueListContent,
        VariableListsContent,
    },
})
export default class AddCommentToMail extends Vue {
    // data:
    isSP = isMobile(window.navigator).phone

    @Prop()
    tmp: MessageTemplate // templateのpublic_id

    @Prop()
    showSubject: boolean

    @Prop()
    hideButtons: boolean

    @Ref()
    TemplateTextarea

    // 今回利用するテンプレ.
    localTmp: MessageTemplate = null
    memo = ``
    subject = ``
    titles = TemplateUtil.getTitles()

    smsContent = ``

    // テンプレ更新ボタンの表示
    showUpdateTmpButton = false

    rm = RoomManager
    rcm = RoomCreateManager
    currentAppo: Appointment = null

    openDescriptionVariables = false
    openTitleVariables = false
    openSMSVariables = false

    mounted() {
        // this.currentAppo = roomStorage.fetchAppointment()
        this.updateAppo()
        this.updateMembers()
        this.updateMailFlow()
    }

    public inputMemo(e) {
        this.$emit(`inputMemo`, e, null)
    }

    public inputSubject(e) {
        this.$emit(`inputSubject`, e, null)
    }

    @Watch("rcm.members", { deep: true })
    updateMembers() {
        if (Util.isBlank(this.rcm.members)) return
        Logger(`メンバーを更新するとテンプレも更新します`)
        this.showUpdateTmpButton = true
        this.updateTmpId()
    }

    @Watch("rcm.appo", { deep: true })
    updateAppo() {
        if (!this.rcm.appo) return
        // Logger(`updateAppo addCommentToMail. ${this.appo ? this.appo.memo : ``}`)
        this.showUpdateTmpButton = true
    }

    @Watch("rcm.selectedMailFlow", { deep: true })
    updateMailFlow() {
        if (!this.rcm.selectedMailFlow) return
        this.showUpdateTmpButton = true
        this.memo = this.rcm.selectedMailFlow.content || ``
        this.smsContent = this.rcm.selectedMailFlow.sms_content || ``
        //  || MailFlow.getContent(this.rcm.selectedMailFlow.def_type)
        this.subject = this.rcm.selectedMailFlow.subject
    }

    @Watch(`tmp`, { deep: true })
    updateTmpId() {
        if (!this.tmp) return
        Logger(`tmpを更新しました addCommentToMail: ${this.tmp.public_id}`)

        if (Util.isBlank(this.localTmp) || this.tmp.public_id != this.localTmp.public_id) {
            this.localTmp = this.tmp
            this.$emit("inputMemo", this.tmp.content, this.tmp.public_id)
            // this.updateTmp()
        }
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
        this.$vfm.open("ModalTemplates")
    }

    /**
     * 使うテンプレを決定.
     *
     */
    public decideTemplate(tmp: MessageTemplate) {
        //
        this.localTmp = tmp
        // this.updateTmp()
        this.$emit("inputMemo", tmp.content, this.localTmp.public_id)
    }

    // public updateTmp() {
    //     // テンプレを最新のユーザー/アポの内容をはめて更新します。
    //     if (!this.localTmp) return
    //     let atts = this.rcm.members.filter(m => m.user_id != this.rm.userInfo.user_id)
    //     let owner = this.rcm.members.find(m => m.role == 100) || this.rm.userInfo
    //     let str = TemplateUtil.insertTemplateText(this.localTmp.content, atts, owner, this.rcm.appo, this.rm.userInfo)
    //     this.$emit("inputMemo", str, this.localTmp.public_id)
    //     // this.showUpdateTmpButton = false
    // }

    addVal(type) {
        if (type == `title`) {
            if (this.openTitleVariables) {
                this.openTitleVariables = false
            } else {
                this.openTitleVariables = true
            }
        } else if (type == `desc`) {
            if (this.openDescriptionVariables) {
                this.openDescriptionVariables = false
            } else {
                this.openDescriptionVariables = true
            }
        } else if (type == `sms`) {
            if (this.openSMSVariables) {
                this.openSMSVariables = false
            } else {
                this.openSMSVariables = true
            }
        }
    }

    inputSmsContent(e) {
        this.$emit(`inputSmsContent`, e)
    }

    /**
     * 選択した変数をカーソルがある位置に挿入します。
     * @param type [string] title, desc, sms
     */
    public selectVariable(e, type: string) {
        Logger(`${funcName()} memo:${e}, type:${type}`)

        if (type == `title`) {
            this.subject = `${this.subject || ""}${e}`
            this.$emit(`inputSubject`, this.subject, type)
        } else if (type == `desc`) {
            this.memo = `${this.memo || ""}${e}`
            this.$emit(`inputMemo`, this.memo, type)
        } else if (type == `sms`) {
            this.smsContent = `${this.smsContent || ""}${e}`
            this.$emit(`inputSmsContent`, this.smsContent)
        }
    }
}
