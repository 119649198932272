
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import AccountHeader from "packs/pages/link/settings/AccountHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import OneLogContent from "packs/pages/link/settings/OneLogContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import Organization from "packs/models/Organization"
import UserGroupHistory from "packs/models/UserGroupHistory"

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        AccountHeader,
        MdTextfieldTag,
        IconLock,
        OptionModal,
        AlertModal,
        OneLogContent,
    },
})
export default class AccountSettingsSecurity extends Vue {
    // data:

    notice = Notice

    rm = RoomManager
    Util = Util
    selectedTab = "security"

    isSP = isMobile(window.navigator).phone

    validName = true
    saving = false
    loading = false

    ipAddresses: string[] = null
    canUseIp = false
    canUseDeleteAllDependent = false
    optionEventName = `管理画面IP制限 課金リクエスト`
    // modalに引き渡す情報をセットします.
    // type: options, deleteDomain
    // {title, text, buttonName, type}
    alertDic = null
    selectedCategory = `settings`
    logs: UserGroupHistory[] = null // アクセスログ
    operationLogs: UserGroupHistory[] = null // 操作ログ
    users: RoomMember[] = null

    org: Organization = null
    ug: any = null

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkAdmin()
                this.canUseIp = this.rm.canUseOption(`management_control_panel_ip_addresses`, false)
                this.canUseDeleteAllDependent = this.rm.canUseOption(`management_delete_all_dependent`, false)
                this.getUserGroupSettings()
                // this.subm.getSubscriptionData().then(e => {});
            })
        } else if (this.rm.userInfo.user_group_role == 100) {
            // this.subm.getSubscriptionData().then(e => {});
            this.canUseIp = this.rm.canUseOption(`management_control_panel_ip_addresses`, false)
            this.canUseDeleteAllDependent = this.rm.canUseOption(`management_delete_all_dependent`, false)
            this.getUserGroupSettings()
            // this.namespace = this.rm.namespace
        } else {
            this.checkAdmin()
        }
    }

    public getUserGroupSettings() {
        this.org = this.rm.organization
        let ugs = this.org.user_groups
        if (Util.isPresent(ugs)) {
            this.ug = ugs[0]
        }

        RoomMember.getSecurity().then(e => {
            this.ipAddresses = e.ipAddresses
        })
        this.getAccesses()
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/account/security")
    }

    getAccesses() {
        Logger(`${funcName()}`)
        if (Util.isPresent(this.logs)) return

        if (Util.isBlank(this.users)) {
            RoomMember.getUserList().then(list => {
                this.users = list
                UserGroupHistory.getAccesses().then(res => {
                    if (res) {
                        this.logs = res.access_logs
                    }
                })
                this.getOperationLogs()
            })
        } else {
            UserGroupHistory.getAccesses().then(res => {
                if (res) {
                    this.logs = res.access_logs
                }
            })
            this.getOperationLogs()
        }
    }

    getOperationLogs() {
        Logger(`${funcName()}`)
        if (Util.isPresent(this.operationLogs)) return

        if (Util.isBlank(this.users)) {
            RoomMember.getUserList().then(list => {
                this.users = list
                UserGroupHistory.getOperationLogs().then(res => {
                    if (res) {
                        this.operationLogs = res.operation_logs
                    }
                })
            })
        } else {
            UserGroupHistory.getOperationLogs().then(res => {
                if (res) {
                    this.operationLogs = res.operation_logs
                }
            })
        }
    }

    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (Util.isBlank(this.rm.userInfo)) return

        const uf = this.rm.userInfo
        const cate = `account_settings`

        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)) {
            // セキュリティに飛ばす

            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings/notifications")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`)) {
            // このページ.
            this.$router.push("/account_settings")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            // アカウント.
            this.$router.push("/account")
            return
        }

        this.$router.push("/")
        // location.href = "https://google.com";
    }

    public inputIPWhitelist(e: string) {
        let list = (e || ``).split(`,`).map(l => l.trim())

        Logger(`inputIPWhitelist list: ${Util.output(list)}`)
        this.ipAddresses = list
    }

    selectCategory(name: string) {
        this.selectedCategory = name
    }

    saveIPAddresses() {
        gtagClick(`管理画面IP制限`, `${(this.ipAddresses || []).length}`)
        RoomMember.updateSecurity({ ip_addresses_whitelist: this.ipAddresses }).then(e => {
            // 保存完了
        })
    }

    clickTextfield() {
        if (!this.canUseIp) {
            // モーダルを表示します.
            this.$vfm.open(`OptionModal`)
        }
    }

    clickOK() {
        gtagClick(`${this.optionEventName}`, `${(this.ipAddresses || []).length}`)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.$vfm.close(`OptionModal`)
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.optionEventName} キャンセル`, `${(this.ipAddresses || []).length}`)
        this.$vfm.close(`OptionModal`)
        this.$vfm.close(`AlertModal`)
    }

    deleteAllDependent() {
        this.optionEventName = `管理画面 ユーザーデータ削除`
        if (!this.canUseDeleteAllDependent) {
            this.$vfm.open(`OptionModal`)
            return
        }
        this.alertDic = null
        // ドメインを削除するか確認モーダルを表示します.
        this.alertDic = {
            title: `データ削除`,
            text: `グループに紐づく過去の調整ページ、メッセージのやりとりやフォーム入力情報などすべてを削除します。この操作は取り戻せません。`,
            buttonName: `削除`,
            type: `clickDeleteData`,
        }
        this.$vfm.open(`AlertModal`)
    }

    clickDeleteData() {
        Logger(`${funcName()}`)
        if (this.loading) return
        this.loading = true
        Organization.deleteAllDependent().then(e => {
            this.loading = false
            this.$vfm.close(`AlertModal`)
        })
    }

    inputAdminEmails(e) {
        this.ug.user_group_setting.admin_emails = e
    }

    saveAdminEmails() {
        gtagClick(`管理者メールアドレス`, `${(this.ug.user_group_setting.admin_emails || []).length}`)
        this.rm.startProgress()
        Organization.updateUserGroupSettings(this.ug.user_group_setting).then(res => {
            this.rm.endProgress()
            this.loading = false
            if (res) {
                this.ug.user_group_setting = res
            }
        })
    }
}
