
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import OneMessageInnerContent from "./parts/OneMessageInnerContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

// モデルを読込.
import RoomMember from "packs/models/RoomMember"
import RoomMessage from "packs/models/RoomMessage"
import Util from "packs/utils/Util"
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import UserFile from "packs/models/UserFile"

@Options({ components: { OneMessageInnerContent, UserIcon, UserFileImage, MdProgressSpinner } })
export default class OneMessageContent extends Vue {
    // data:
    @Prop()
    iconType: { type: string; require: false }
    @Prop()
    name: { type: string; require: false }
    @Prop()
    text: { type: string; require: false }
    @Prop()
    message: RoomMessage
    @Prop()
    firstUnread: { type: boolean; require: false }
    @Prop()
    room: Room

    readCount: number = 0

    createdTime: string = ""
    createdDate: string = ""
    rm = RoomManager
    isMe = false

    currentMem: RoomMember
    userFiles: UserFile[] = null

    // regex = /http(:\/\/[-_.!~*¥'()a-zA-Z0-9;\/?:¥@&=+¥$,%#]+)/;
    // regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

    public resend(message: RoomMessage) {
        this.$emit(`resend`, message)
    }

    public created() {
        this.updateMessage()
    }

    public async mounted() {
        const sec = this.message.created_at
        let dt = DateTime.fromSeconds(sec)
        if (this.message.is_system) {
            let format = this.$t(`calendar.format_datetime`) as string
            this.createdTime = dt.toFormat(format)
        } else {
            this.createdTime = dt.toFormat("HH:mm")
        }

        this.createdDate = Util.getTimeDateFormatFromSeconds(sec, this.$t(`calendar.format_date_with_year`) as string)
        this.updateReadCount()
    }

    // @Watch(`rm.memberDicPerRoom`, { deep: true })
    @Watch(`room`, { deep: true })
    public updateReadCount(): number {
        if (!this.room) return
        if (!this.rm.userInfo) return
        // Logger(`通過 member: ${Util.output(this.room.members)}`)

        let count: number = 0
        // ルームメンバー毎の最終読み込み時刻を元にカウントします。
        for (let mem of this.room.members) {
            const lrmCreatedAt: number = mem.last_read_message_created_at
            if (lrmCreatedAt && lrmCreatedAt >= this.message.created_at) {
                // Logger(`+lrmCreatedAt`)
                count++
            } else if (mem.user_id == this.rm.userInfo.user_id) {
                // Logger(`+owner`)
                count++
            }
        }
        Logger(`count:: ${count}`)
        this.readCount = count
    }

    @Watch("rm.userInfo", { deep: true })
    updateUserInfo() {
        this.updateReadCount()
    }

    getMember(): RoomMember {
        if (!this.message || !this.message.owner) return null
        if (!this.room) return null
        if (!this.rm.userInfo) return null
        if (this.message.owner.user_id == this.rm.userInfo.user_id) return this.message.owner

        return this.room.members.find(m => m.user_id == this.message.owner.user_id)
    }

    @Watch("message", { deep: true })
    updateMessage() {
        const currentMem = this.getMember()
        this.currentMem = currentMem
        this.updateMessageId()
        this.userFiles = this.message.files
        if (this.rm.userInfo && currentMem && this.rm.userInfo.user_id == currentMem.user_id) {
            this.isMe = true
            return
        }
        this.isMe = false
    }

    get memberNum() {
        if (!this.room) return 0
        return this.room.members.length
    }

    get memberName() {
        // const mem = this.getMember();
        const mem = this.currentMem
        if (mem) return mem.name
        return this.message.owner.name
    }

    get memberCompanyName() {
        // const mem = this.getMember();
        const mem = this.currentMem
        if (mem) return mem.company_name
        return this.message.owner.company_name
    }

    public hasImage(): boolean {
        if (this.currentMem && (this.currentMem.image_url || this.message.owner.image_url)) {
            return true
        }
        return false
    }

    get memberIconPath(): string {
        // const mem = this.getMember();
        const mem = this.currentMem
        const imageUrl = mem ? mem.image_url : this.message.owner.image_url
        if (imageUrl) return imageUrl
        // const roleName = mem ? mem.role_name : this.message.owner.role_name;
        // return `/assets/icons/usertype_${roleName}`;
    }

    /**
     * sendingステータスで10秒経っても受信できない場合はunsentのステータスに変更します。
     */
    @Watch("message")
    public updateMessageId() {
        if (this.message.id == `sending`) {
            Logger(`sendingのため、ウォッチします。`)
            sleep(10000).then(() => {
                // 10秒反応がないため、unsentに変更
                this.$emit("changeUnsent", this.message)
            })
        }
    }

    deletedAttachment(file) {
        if (!file) return // 失敗した場合
        Logger(`添付ファイルを削除します: ${file.original_filename}`)
        this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
    }

    showPreview(file: UserFile) {
        this.$emit("showPreview", file)
    }
}
