
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { Logger, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.

@Options({
    components: {
        MdTextfieldTag,
        TextfieldTag,
    },
})
export default class OAuthButtons extends Vue {
    @Prop()
    showSSO: boolean = false

    // data:

    isSP = isMobile(window.navigator).phone

    clickSSO() {
        // this.useSSO = true
        this.$emit(`clickSSO`)
    }

    submitGoogleForm() {
        document.getElementById("google_oauth_form").submit()
    }
}
