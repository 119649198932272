
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger, funcName, sleep } from "packs/common"
import { DateTime } from "luxon"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import PossibleDatesWithCalendarTable from "packs/pages/link/parts/PossibleDatesWithCalendarTable.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Refresher from "packs/models/Refresher"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Notice from "packs/models/Notice"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import FormField from "packs/models/FormField"
import { nextTick } from "vue"
import RoomSetting from "packs/models/RoomSetting"

@Options({
    components: { PossibleDatesWithCalendarTable, LblueListContent },
})
export default class ModalInnerSelectPossibleDates extends Vue {
    @Prop()
    room: Room

    @Prop()
    parentId: string

    @Prop()
    isSuspend: boolean

    @Prop()
    isPreview: boolean

    @Prop()
    loading: boolean

    @Prop() // func
    getPossiblesWithMonth

    @Prop() // func
    getPossiblesWithWeek

    @Prop()
    appoStatus: string

    @Ref()
    PossibledatesWithCalendarTable

    // data
    rm = RoomManager
    pdm = PossibleDatesManager
    Util = Util
    DateTime = DateTime
    currentMem: RoomMember = null
    isObserver = false
    // showAds = false;
    // currentPd?: PossibleDate = null;
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone
    refresher = Refresher

    systemUpdatedAt = Util.getSec()
    pds = null
    selectedPossibleDates: PossibleDate[] = []
    astag: AvailableScheduleTag = null

    userType = ``
    weekDics = Util.weekDics
    dailyPossibleDates = [] // [[23,24,25], [10,11,12]] 今月と来月の
    saving = false // 二度送信防止.

    displayAll = false
    rs: RoomSetting = null

    mounted() {
        this.updateRoom()
    }

    @Watch(`pdm.room`, { deep: true })
    updateRoom() {
        if (Util.isBlank(this.pdm.room) || !this.rm.userInfo) return

        let mem = this.room.members.find(_m => _m.user_id == this.rm.userInfo.user_id)
        if (mem) {
            this.currentMem = mem
            if (mem.role == -10) {
                this.isObserver = true
            }
        }
        this.rs = this.room.room_setting
    }

    public hideSelectPossibleDatesModal() {
        this.$vfm.close("SelectPossibleDatesModal")
    }

    changeSavingStatus(saving: boolean) {
        this.saving = saving
    }

    /**
     * リモートに保存してastagを作成します。
     */
    public suggestDates() {
        Logger(`${funcName()} 提案を送信`)
        if (this.isPreview || Util.isRoomSettingsPreview()) {
            Notice.message = "プレビューのため送信できません。"
            return
        }
        // duration, start_time
        if (!this.astag) {
            this.astag = this.rm.astag
        }
        if (this.selectedPossibleDates.length == 0) {
            Notice.message = `候補が選択されていません。`
            return
        }
        if (this.isSuspend) {
            Notice.message = `オーナーが停止中のため、確定できません。`
            return
        }
        Logger(`オーナーが求めている最低提案数: ${this.pdm.owner_request_num}件, 選択数: ${this.selectedPossibleDates.length}`)
        if (this.pdm.owner_request_num != -1 && this.pdm.owner_request_num - this.selectedPossibleDates.length > 0) {
            if (this.pdm.owner_request_num * 2 < this.pdm.possibleDates.length) {
                // アラートを上げる.
                if (
                    confirm(
                        `オーナーが求めている最低提案数${this.pdm.owner_request_num}件に足りていませんが、この内容で提案しますか？`
                    )
                ) {
                    this.sendAstag()
                }
                return
            }
        }

        this.sendAstag()
    }

    checkAnsweredForm(): FormField[] {
        let ffs = (this.room.form_fields || []).filter(ff => !ff.did_answer && ff.is_required)
        return ffs
    }

    public sendAstag() {
        let notAnswered = this.checkAnsweredForm()
        Logger(`ModalInnerSelectPossibleDates#sendAstag: notAnswered: ${(notAnswered || []).length}件`)

        if (Util.isPublic() || Util.isPresent(notAnswered)) {
            // 提案する日程を持って、次のアクションにつなげます.
            this.$emit(`selectPossibleDates`, this.selectedPossibleDates)
            return
        }
        if (this.appoStatus == Appointment.typeReschedule) {
            this.$emit(`selectPossibleDatesFromReschedule`, this.selectedPossibleDates)
            return
        }

        // 二度送信防止.
        if (this.saving) return

        this.rm.startProgress()
        this.$emit("changeLoading", true)
        this.saving = true

        Logger(`提案を送信2: ${Util.output(this.astag)}`)

        // この画面から確定した場合、定期予定（adow）は選択していないので、キーを消しておきます。
        let sharedMems = this.pdm.sharedMembers
        let _shared = sharedMems.find(m => m.user_id == this.rm.userInfo.user_id)
        if (_shared) {
            this.astag = this.rm.astag
        } else {
            this.astag = AvailableScheduleTag.createDefaultAstag()
        }
        if (this.astag.id == `newId`) {
            delete this.astag.available_days_of_weeks
        }

        /**
         * 選択したPossibleDatesを送信するパラメータに合う形に成形します。
         */
        const addDates = PossibleDate.toOptionalDates(this.selectedPossibleDates)
        const ops = PossibleDate.toFormat(addDates)
        this.astag.optional_schedules = ops

        let sendType = ``
        let parentId = this.parentId || this.room.id

        Logger(`提案を送信4: ${Util.output(this.astag)} parentId: ${this.parentId}`)

        // このモーダルから提案した場合、全てcreate_and_shareとして保存します。
        sendType = `createAndShare`

        AvailableScheduleTag.saveAvailableSchedule(this.astag, sendType, parentId).then(res => {
            this.rm.endProgress()

            // 保存に成功したら、ローカルに保存した情報は削除しておきます。
            if (res) {
                this.astag = res as AvailableScheduleTag
                this.rm.astag = this.astag
                Notice.message = `日程調整のお相手に提案しました。確定まで今しばらくお待ち下さい。`
                // this.refresher.needRefresh.push(`pdm`)
                let shareMems = this.pdm.room.shared_schedule_member_ids || []
                shareMems.push(this.pdm.userInfo.user_id)
                this.hideSelectPossibleDatesModal()
                // this.showSaveButton = false;
                gtagClick(
                    `調整カレンダー候補提案 ${sendType}`,
                    `astagid: ${this.astag.id}[${this.rm.userInfo.email}] add: ${(this.selectedPossibleDates || []).length}こ`
                )
                this.pdm.updateDisplayType(`suggested`)

                this.$emit("changeLoading", false)
            }
            this.saving = false
        })
    }

    // 別のページから日程提案ボタンが押された際に同様の処理を行います。
    @Watch(`pdm.suggestNow`)
    updateSuggestDates() {
        Logger(`${funcName()}`)
        if (!this.pdm.suggestNow) return
        this.pdm.suggestNow = null
        this.suggestDates()
    }

    public goAvailableScheduleView() {
        gtagClick(`遷移: SelectPDModal → AstagView`, `pinkButton`)
        this.$router.push(`/available_schedule`)
    }

    @Watch(`pdm.selectedPds`)
    public updateSelectedPossibleDates() {
        let pds = [...(this.pdm.selectedPds || [])]
        Logger(
            `${funcName()} pdm.selectedPds:${pds.length}, pdm.displayType:${this.pdm.displayType}, is_owner:${
                this.room.is_owner
            }, didShare:${this.pdm.didShare()}, rm.astag:${this.rm.astag?.id}(${this.rm.astag?.type}):${this.rm.astag?.name}`
        )
        /**
         * オーナー以外で（一時的な）共有済みの場合、共有したastagを取得し、日程を表示します。
         */
        if (!this.room.is_owner && this.pdm.didShare() && !this.pdm.selectedPds) {
            pds = this.pdm.getSelectedPossibleDates(this.rm.astag) || []
        }
        pds = [...(pds || [])]

        this.selectedPossibleDates = pds
        Logger(`updateSelectedPossibleDates pdm.displayType:${this.pdm.displayType} pds:${pds.length}`)
        this.systemUpdatedAt = Util.getSec()
    }

    public returnDate(pd: PossibleDate, status) {
        Logger(`${pd.start_time}`)
        if (this.selectedPossibleDates.length == 1) {
            window.confirm(`最低1件は選択してください。`)
            return
        }
        this.PossibledatesWithCalendarTable.clickPossibleDateCell(pd)
        this.systemUpdatedAt = Util.getSec()
        // this.selectedPossibleDates = this.selectedPossibleDates.filter(_pd => !PossibleDate.isSame(_pd, pd))
    }

    calendarShowType() {
        let rs = this.room?.room_setting
        if (!rs) return `monthly`

        let calendarShowType = (rs.calendar_show_type || `monthly_monthly`).split(`_`)
        if (this.isSP) {
            return calendarShowType[1]
        }
        return calendarShowType[0]
    }
}
