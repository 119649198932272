
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Logger } from "packs/common"

// コンポーネントを読込.
import CalendarThumbnailContent from "packs/components/link/CalendarThumbnailContent.vue"
import AstagSearchBox from "packs/pages/link/parts/available_schedule/AstagSearchBox.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AstagsTab from "packs/models/fetcher/AstagsTab"

@Options({
    components: { CalendarThumbnailContent, AstagSearchBox },
})
export default class LinkHeaderAvailableSchedule extends Vue {
    @Prop()
    pageTitle: string

    @Prop()
    buttonTitle: string

    @Prop()
    showChangeIcon: boolean

    @Prop()
    showWhatCal: boolean

    @Prop()
    showBackButton: boolean

    @Prop()
    isSuspend: boolean

    @Prop()
    buttonEnabled: boolean

    @Prop() // func
    editEditableContent

    @Prop() // func
    clickChangeName

    @Prop() // func
    clickChangeAutoMode

    @Prop() // asview,
    from: string

    isSP = isMobile(window.navigator).phone
    href = ``
    cm = CalendarManager
    pdm = PossibleDatesManager
    rcm = RoomCreateManager
    rm = RoomManager

    Util = Util
    selectedTabName = `list`
    astagsTab: AstagsTab = null

    mounted() {
        this.href = location.href
        this.updateAstagsTab()
    }

    @Watch(`rm.astagsTab`)
    updateAstagsTab() {
        if (!this.rm.astagsTab) return
        this.astagsTab = this.rm.astagsTab
    }

    clickBack() {
        this.$router.back()
    }

    clickPinkButton() {
        this.$emit(`clickPinkButton`)
    }

    selectTab(tabName: string) {
        gtagClick(`astagView タブ選択`, `${tabName}`)
        this.selectedTabName = tabName
        this.$emit(`selectTab`, tabName)
    }

    editAstag() {
        this.cm.editStartAstag()
        this.$vfm.open(`EditCalendarTagModal`)
    }

    clickHowToSelect() {
        this.$vfm.open(`TutorialModal`)
    }

    changeCategoryTab(tabName: string) {
        this.astagsTab.tab_name = tabName
    }
}
