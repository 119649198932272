
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagEvent } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import ModalInnerPaymentRequest from "packs/pages/link/modals/ModalInnerPaymentRequest.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import SubscriptionManager from "packs/models/SubscriptionManager"

@Options({
    components: { ModalInnerPaymentRequest, VueFinalModal },
})
export default class AdPromBusinessModal extends Vue {
    @Prop()
    addFrom: string

    showForm = false //現在は課金フォーム

    // data:
    mounted() {
        if (this.showForm) {
            gtagEvent(`show`, `アップグレードモーダル`, `${this.addFrom}`)
        }
    }

    closeModal() {
        gtagClick(`アップグレードモーダル:閉じる`, this.addFrom)
    }

    // @Emit("fadeOutAdModal")
    fadeOutAdModal() {
        gtagClick(`アップグレードモーダル:閉じる`, this.addFrom)
        this.$vfm.close("AdPromBusinessModal")
    }

    showUpgradeForm() {
        Logger(`リクエストフォームを表示.`)
        gtagEvent(`show`, `アップグレードモーダル→リクエストフォーム`, `${this.addFrom}`)
        this.showForm = true
    }

    // public fadeOutPaymentRequestModal() {
    //     // this.showAds = false;
    //     gtagClick(`Trial Limitモーダル:閉じる`, this.addFrom);
    //     this.$vfm.close("PaymentRequestModal");
    // }

    // goPayment() {
    //     gtagClick(`Adモーダル:${this.addFrom}`, this.addFrom);
    //     this.$router.push("/payment");
    // }
}
