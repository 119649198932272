
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import LocationIcon from "packs/components/icons/LocationIcon.vue"
import InputLocationModal from "packs/pages/link/modals/InputLocationModal.vue"
import ChangeLocationModal from "packs/pages/link/modals/ChangeLocationModal.vue"
import Popper from "vue3-popper"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import LocationTag from "packs/models/LocationTag"

@Options({
    components: {
        LocationIcon,
        InputLocationModal,
        ChangeLocationModal,
        Popper,
    },
})
export default class SelectableLocations extends Vue {
    // data:
    @Prop()
    appo: Appointment

    notice = Notice

    rm = RoomManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone

    systemUpdatedAt = null
    loctags: LocationTag[] = null
    loading = false
    Util = Util
    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.

    willSelectLoctag: LocationTag = null // 選択してモーダルで確認

    public mounted() {
        this.updateLoctagsFromAppo()
    }

    get showLocations() {
        if (!this.appo) return false
        if (this.appo.status == 10 && this.pdm.displayType != `reschedule`) return false
        if (![`selectable_or_registrable`, `selectable`].includes(this.appo.location_select_type)) return false

        if (Util.isPresent(this.loctags)) return true
        return false
    }

    @Watch("appo")
    updateLoctagsFromAppo() {
        if (!this.appo) return

        Logger(
            `${funcName()} selectable_locations:${Util.output(this.appo.selectable_locations)}, appo:${Util.output(this.appo)}`
        )
        let appo = Appointment.copy(this.appo)
        if (Util.isPresent(appo.location_name)) {
            if (appo.location_tag_id == null) {
                this.setFreeLocation(appo)
            } else {
                // 選択肢の中から選んでいる場合、
                let loctag = (appo.selectable_locations || []).find(loc => loc.id == appo.location_tag_id)
                if (loctag) {
                    appo.selected_location = loctag
                }
            }
        }
        let _loctags = Appointment.adjustLocations(appo)

        this.loctags = _loctags
        if ((_loctags || []).length > 1 && appo.selected_location) {
            this.changeSelect(appo.selected_location)
        }

        Logger(`${funcName()} loctagsの数:${(this.loctags || []).length}`)
    }

    // 自由入力に入力した内容を入れます.
    // 事前にappo.location_nameを更新しておいてください。
    setFreeLocation(appo: Appointment) {
        Logger(`${funcName()} appo.location_name:${appo.location_name}`)
        let freeLoctag = LocationTag.createRegistrable()
        freeLoctag.keyword = appo.location_name
        appo.location_tag_id = null
        appo.selected_location = freeLoctag
    }

    public clickLocation(loctag: LocationTag) {
        if (this.isPreview) {
            this.notice.message = `プレビューのため、場所を選択できません。`
            return
        }
        gtagClick(`場所選択`, `${loctag.keyword}`)

        if (loctag.public_id == `free`) {
            // モーダルで場所を入力してもらいます。
            this.$vfm.open("InputLocationModal")
        } else {
            // 現在選択中の場合、モーダルを開かない.
            let _selectedLoctag = this.loctags.find(l => l.selected)
            // if (_selectedLoctag.id == loctag.id) return
            this.willSelectLoctag = loctag
            this.$vfm.open("ChangeLocationModal")
        }
    }

    // モーダルで変更を押した場合に、更新
    selectLocation(loctag: LocationTag) {
        if (Util.isPublic()) {
            // アップデートをかけずに、後で変更情報をsendで送る
            this.$vfm.close("ChangeLocationModal")
            this.changeSelect(loctag)
            this.$emit(`selectLoctag`, loctag)
            return
        }
        if (this.loading) return
        this.loading = true

        this.changeSelect(loctag)
        this.rm.startProgress()
        // 選択した場所をlocation_tag_idを入れて、appoを更新 -> pdを取得し直し.
        let appo: Appointment = Appointment.copy(this.appo)
        appo.location_name = null
        appo.location_tag_id = loctag.id
        Appointment.update(appo, "put", this.rm.currentRoom).then(dic => {
            // this.appo = appo;
            this.loading = false
            this.rm.endProgress()
            this.$vfm.close("ChangeLocationModal")
            let _newAppo = dic.appo
            if (_newAppo) {
                this.rm.setAppo(_newAppo)
                let newLoctag = null
                if (_newAppo.selected_location) {
                    newLoctag = _newAppo.selected_location
                } else {
                    newLoctag = loctag
                }
                Logger(`${funcName()} 場所情報を更新しました newLoctag: ${Util.output(newLoctag)}`)

                gtagClick(`FIX 会議場所変更`, `${appo.location_name}`)
                this.$emit(`selectLoctag`, newLoctag)
            }
        })
    }

    changeSelect(loctag: LocationTag) {
        Logger(`${funcName()} 選択中のloctag:${loctag.public_id}, loctag.keyword:${loctag.keyword}`)
        for (let _loctag of this.loctags) {
            if (_loctag.public_id == loctag.public_id) {
                _loctag.selected = true
            } else {
                _loctag.selected = false
            }
        }
        this.systemUpdatedAt = Util.getSec()
    }

    // モーダルのフリーフォームから入力されてアポが更新された場合に、親に通知します。
    public updatedLocation(appo: Appointment) {
        Logger(`${funcName()}`)
        this.setFreeLocation(appo)
        Logger(`${funcName()} appo: ${Util.output(appo)}`)

        let _loctags = Appointment.adjustLocations(appo)
        this.loctags = _loctags
        this.$vfm.close(`InputLocationModal`)
        this.$emit(`selectLoctag`, appo.selected_location)
        this.changeSelect(appo.selected_location)
    }
}
