
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Logger, copyToClipboard, funcName } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.

// モデルを読込.

@Options({
    components: {},
})
export default class CopyButton extends Vue {
    @Prop()
    linkUrl: string

    @Prop()
    buttonName: string

    @Prop()
    width: number

    @Prop()
    gtagCategory: string

    @Prop()
    gtagLabel: string

    @Prop()
    styleNone: boolean

    // data:

    showUrlTip = false
    Util = Util

    currentMemIndex = null
    // members_with_links: RoomMember[] = [];
    // selectedTabStatus = this.roomStatusesHash.categoryProgress;

    public copyPageUrl() {
        Logger(`${funcName()} linkUrl:${this.linkUrl}`)
        $(this).select()
        copyToClipboard(this.linkUrl)
        this.showUrlTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(this.gtagCategory || `URLをコピー`, this.gtagLabel || `${this.linkUrl}`)
    }

    public hideTooltip(e) {
        this.showUrlTip = false
    }
}
