
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import WeekdayButtons from "packs/components/buttons/WeekdayButtons.vue"
import SpanSlotContent from "packs/components/common/SpanSlotContent.vue"
import { Dropdown } from "uiv"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import PlanOption from "packs/models/PlanOption"
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomSetting from "packs/models/RoomSetting"
import Const from "packs/utils/Const"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        SelectTag,
        WeekdayButtons,
        SpanSlotContent,
        Dropdown,
        CheckboxTag,
    },
})
export default class MeetingSpanContent extends Vue {
    // data
    isSP = isMobile(window.navigator).phone

    Util = Util
    SelectUtil = SelectUtil
    Const = Const
    cm = CalendarManager
    rcm = RoomCreateManager
    rm = RoomManager

    showCustomWeekdays = false
    isCustomStartTime = false

    // spanType = `period` //period（相対）,fixed
    startDate = DateTime.fromSeconds(this.rcm.room_setting.available_period_start_at || DateTime.local().toSeconds()).toFormat(
        "yyyy/MM/dd"
    )
    endDate = DateTime.fromSeconds(
        this.rcm.room_setting.available_period_end_at || DateTime.local().plus({ days: 7 }).toSeconds()
    ).toFormat("yyyy/MM/dd")

    mounted() {
        this.updateRoomSettingFromParent()
    }

    @Watch("rcm.room_setting", { deep: true })
    public updateRoomSettingFromParent() {
        if (Util.isBlank(this.rcm.room_setting)) return

        if (this.rcm.room_setting.available_period_skip_type == `custom`) {
            this.showCustomWeekdays = true
        } else {
            this.showCustomWeekdays = false
        }
    }

    // 不参加カレンダー時に利用.
    public saveRoomSettingInLocal() {
        // this.rcm.room_setting = this.currentAstag
        // this.$emit("updateAstagFromChild", this.rcm.room_setting)
        this.rcm.updateRoomSetting(this.rcm.room_setting)
        if (this.cm && this.cm.rs) {
            this.cm.updateRoomSetting(this.rcm.room_setting)
        }
    }

    public getAvailablePeriodEndDays(): number {
        if (!this.rcm.room_setting) return 30
        if (!this.rcm.room_setting.available_period_end_min) {
            // デフォルトは30日後まで
            this.rcm.room_setting.available_period_end_min = 30 * 60 * 24
        }
        return this.rcm.room_setting.available_period_end_min / (60 * 24)
    }

    public getAvailablePeriodSkipType(): string {
        if (!this.rcm.room_setting) return `土日・祝日を除く`
        let _type = this.rcm.room_setting.available_period_skip_type
        if (!_type) return `土日・祝日を除く`
        let _typeDic = SelectUtil.availablePeriodSkipTypes.find(t => t.type == _type)
        if (!_typeDic) return `土日・祝日を除く`
        return _typeDic.name
    }

    public inputAvailablePeriodStartDays(raw) {
        const days = Util.parseNaturalNumber(raw) * 60 * 24
        this.rcm.room_setting.available_period_start_min = days
        if (days > this.rcm.room_setting.available_period_end_min) {
            this.rcm.room_setting.available_period_end_min = days
        }
        this.saveRoomSettingInLocal()
    }

    public inputAvailablePeriodEndDays(raw) {
        const days = Util.parseNaturalNumber(raw) * 60 * 24
        this.rcm.room_setting.available_period_end_min = days
        if (days < this.rcm.room_setting.available_period_start_min) {
            this.rcm.room_setting.available_period_end_min = days
        }
        this.saveRoomSettingInLocal()
    }

    public inputAvailablePeriodSkipType(e) {
        let _typeDic = SelectUtil.availablePeriodSkipTypes.find(t => t.name == e)
        if (_typeDic) {
            this.rcm.room_setting.available_period_skip_type = _typeDic.type
            if (_typeDic.type == `custom`) {
            }
        } else {
            this.rcm.room_setting.available_period_skip_type = `none`
        }
        this.saveRoomSettingInLocal()
    }

    public updateCustomWeek(week: number[]) {
        this.rcm.room_setting.available_period_skip_custom = week
        Logger(`updateされたweek: ${week}`)
        this.saveRoomSettingInLocal()
    }

    public changeSpanType(type: string) {
        let rs: RoomSetting = { ...this.rcm.room_setting }
        rs.available_period_type = type
        if (type == `relative`) {
            rs.available_period_start_at = null
            rs.available_period_end_at = null
        } else {
            // 固定に変更.
            rs.available_period_start_at = DateTime.local().toSeconds()
            rs.available_period_end_at = DateTime.local().plus({ days: 7 }).toSeconds()
        }

        this.rcm.room_setting = rs
        Logger(`spanを変更しました${type} rs: ${Util.output(rs)}`)
        this.saveRoomSettingInLocal()
    }

    @Watch(`startDate`, { deep: true })
    updateStartDate() {
        let sec = DateTime.fromFormat(this.startDate, `yyyy/MM/dd`).toSeconds()
        this.rcm.room_setting.available_period_start_at = sec
        this.saveRoomSettingInLocal()
    }

    @Watch(`endDate`, { deep: true })
    updateEndDate() {
        let sec = DateTime.fromFormat(this.endDate, `yyyy/MM/dd`).toSeconds()
        this.rcm.room_setting.available_period_end_at = sec
        this.saveRoomSettingInLocal()
    }

    canShowPeriod100Days() {
        const days = PlanOption.canUseOption(this.rm.plan_option, `calendar_condition_max_period`)
        return days == 100
    }

    clickCustomStartTime() {
        if (this.isCustomStartTime) {
            this.isCustomStartTime = false
        } else {
            this.isCustomStartTime = true
        }
    }

    updateCustomStartTime(mins: number) {
        Logger(`updateCustomStartTime: mins: ${mins}`)
        this.rcm.room_setting.available_period_start_min = mins
        this.saveRoomSettingInLocal()
    }

    clickChangeBusinessDay() {
        this.rcm.room_setting.use_business_day = !this.rcm.room_setting.use_business_day
        this.rcm.room_setting.countable_business_day_wdays = [1, 2, 3, 4, 5]

        this.saveRoomSettingInLocal()
    }

    selectBusinessDayCheckbox(typeindex: number) {
        let wdays = this.rcm.room_setting.countable_business_day_wdays || [1, 2, 3, 4, 5]
        if (wdays.includes(typeindex)) {
            this.rcm.room_setting.countable_business_day_wdays = wdays.filter(t => t != typeindex)
        } else {
            wdays.push(typeindex)
            this.rcm.room_setting.countable_business_day_wdays = wdays.sort((a, b) => a - b)
        }
        this.saveRoomSettingInLocal()
    }

    selectBusinessDayCheckboxHoliday() {
        if (this.rcm.room_setting.is_count_business_day_holiday) {
            this.rcm.room_setting.is_count_business_day_holiday = false
        } else {
            this.rcm.room_setting.is_count_business_day_holiday = true
        }
        this.saveRoomSettingInLocal()
    }
}
