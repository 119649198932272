
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { Logger } from "packs/common"
import CalendarUtil from "packs/utils/CalendarUtil"
import SelectUtil from "packs/utils/SelectUtil"
import { DateTime } from "luxon"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import Appointment from "packs/models/Appointment"
import Util from "packs/utils/Util"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import RoomSetting from "packs/models/RoomSetting"
import CalendarManager from "packs/models/CalendarManager"

import RoomCreateManager from "packs/models/RoomCreateManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        IconHelp,
    },
})
export default class MeetingTimeContent extends Vue {
    @Prop()
    appo: Appointment

    @Prop() // room_settings/schedule_view/user_settings
    from: string

    @Prop() // room_settingsの場合にroom_settingを取得
    rs: RoomSetting

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    currentAppo: Appointment = null
    Appointment = Appointment
    edited = false
    isCustomTime = false
    openSubTitleView = false

    cm = CalendarManager

    public async mounted() {
        // this.currentAppo = roomStorage.fetchAppointment() || Appointment.createDefault()
        this.updateAppoFromParent()
    }

    @Watch(`appo`, { deep: true })
    public updateAppoFromParent() {
        if (!this.appo) return
        let _appo = Appointment.copy(this.appo)
        this.currentAppo = _appo
        let timestr = SelectUtil.changeTimeToString(this.currentAppo.duration)
        if (!timestr) {
            // カスタム設定の値.
            this.isCustomTime = true
        } else {
            this.isCustomTime = false
        }
    }

    get getDefaultOption(): string {
        if (!this.currentAppo || !this.currentAppo.duration) return "1時間"
        if (this.isCustomTime) {
            return `カスタム`
        } else {
            let timestr = SelectUtil.changeTimeToString(this.currentAppo.duration)
            return timestr
        }
    }

    // 所要時間
    public selectMeetingTime(e: string) {
        if (e == `カスタム`) {
            this.isCustomTime = true
            return
        }

        this.isCustomTime = false
        this.currentAppo.duration = SelectUtil.changeTimeStringToNum(e)
        this.updateCurrentAppo()
    }

    inputCustomDuration(e) {
        Logger(`inputCustomDuration: ${e}`)
        this.currentAppo.duration = +e
        this.updateCurrentAppo()
    }

    blurCustomDuration() {
        if (this.currentAppo.duration < 1) {
            this.currentAppo.duration = 1
        }
        if (this.currentAppo.duration > 720) {
            this.currentAppo.duration = 720
        }
        this.updateCurrentAppo()
    }

    /**
     * 最後に編集した状態を保存しておきます。
     */
    // @Watch("currentAppo", { deep: true })
    public updateCurrentAppo(): void {
        Logger(`updatecurrentAppo: ${Util.output(this.currentAppo)}`)
        if (this.from == `room_settings`) {
            if (this.rcm && this.rcm.room && this.rcm.room_setting) {
                this.rcm.room_setting.duration
            }
            if (this.cm && this.cm.rs) {
                this.cm.previewDuration = this.currentAppo.duration
            }
        }

        this.$emit("updateAppo", this.currentAppo)
    }
}
