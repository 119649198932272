
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import SpanSlotContent from "packs/components/common/SpanSlotContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomManager from "packs/models/RoomManager"
import RoomSetting from "packs/models/RoomSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import SelectUtil from "packs/utils/SelectUtil"
import Const from "packs/utils/Const"
import NotificationControl from "packs/utils/NotificationControl"

@Options({
    components: {
        MdTextfieldTag,
        CheckboxTag,
        SelectTag,
    },
})
export default class InstantRoomSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    // data
    isSP = isMobile(window.navigator).phone

    loading = false
    rcm = RoomCreateManager
    rm = RoomManager

    Util = Util
    CalendarUtil = CalendarUtil
    SelectUtil = SelectUtil
    Const = Const

    cancelSpanDate = DateTime.fromSeconds(
        this.rcm.room_setting.available_cancel_time_at || DateTime.local().toSeconds()
    ).toFormat("yyyy/MM/dd")

    // 紐付ける公開ページの公開ID
    publicId = null
    selectedRoom: Room = null

    mounted() {
        this.setPublicRooms()
    }

    get canUseInstantRoom() {
        return this.rm.canUseOption(`room_instant_room`, false)
    }

    clickIsInstantRoom() {
        let rs = this.rcm.room_setting
        if (!rs) {
            rs = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        }
        if (rs.is_instant_room) {
            rs.is_instant_room = false
        } else {
            rs.is_instant_room = true
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    clickUseInstantRoom() {
        let rs = this.rcm.room_setting
        if (!rs) {
            rs = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        }
        if (rs.use_instant_room) {
            rs.use_instant_room = false
        } else {
            rs.use_instant_room = true
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    inputPublicID(e) {
        Logger(`${funcName()} e:${e}`)
        this.publicId = e
    }

    clickConnectInstantPage() {
        Logger(`${funcName()} `)
        if (this.loading) return
        this.loading = true

        this.rm.startProgress()
        Room.search([this.publicId], this.rm.userInfo.user_id, null, null, null, null, null).then(rooms => {
            if (Util.isPresent(rooms)) {
                let selectedRoom = rooms[0]
                let rs = selectedRoom.room_setting
                if (rs.is_instant_room) {
                    this.selectedRoom = selectedRoom
                    this.rcm.room_setting.instant_room_id = selectedRoom.id
                } else {
                    Notice.message = `「スグINボタン」専用ページが見つかりませんでした。`
                }
                this.rcm.updateRoomSetting(this.rcm.room_setting)
            } else {
                Notice.message = `「スグINボタン」専用ページが見つかりませんでした。`
            }
            this.rm.endProgress()
            this.loading = false
        })
    }

    setPublicRooms() {
        if (!this.rm.userInfo) return

        let rs = this.rcm.room_setting
        let roomId = rs.instant_room_id
        if (Util.isBlank(roomId)) return
        if (this.selectedRoom && this.selectedRoom.id == roomId) return

        Room.getPublicRoom(roomId, this.rm).then(_room => {
            if (_room) {
                this.selectedRoom = _room
                this.publicId = _room.public_id
            }
        })
    }

    public deselect() {
        Logger(`${funcName()} 選択した公開ページ instant_roomをはずします.`)

        this.rcm.room_setting.instant_room_id = null
        this.selectedRoom = null
        this.publicId = null
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }
}
