
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import ZapierNotification from "packs/models/ZapierNotification"
import Notice from "packs/models/Notice"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
    },
})
export default class ZapierNotificationContent extends Vue {
    @Prop()
    noti: ZapierNotification

    @Prop() // user, user_group, room_setting
    scope: string

    @Prop() // func
    showOptionModal

    // data:

    rm = RoomManager
    Util = Util
    notice = Notice

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: ZapierNotification = null
    showTip = false
    optionEventName = `管理画面Zapier 課金リクエスト`

    mounted() {}

    @Watch(`noti`, { deep: true })
    updateNoti() {
        if (!this.noti) return
        this.notiDic = { ...this.noti }
    }

    // Zapier通知設定を有効化もしくはAPI KEYを更新します。
    public enable(need_update: boolean) {
        Logger(`ZapierNotificationContent.enable: ${Util.output(this.notiDic)}, need_update:${need_update}`)
        if (!this.rm.canUseOption(`management_zapier`, false)) {
            // Zapierオプション解禁していません.モーダルを表示します.
            this.showOptionModal(`管理画面Zapier 課金リクエスト`)
            return
        }
        if (this.loading) return
        this.loading = true

        ZapierNotification.save(this.notiDic, need_update).then(noti => {
            Logger(`AFTER SAVE UPDATE NOTI: ${Util.output(noti)}`)
            if (noti) {
                Logger(`  => UPDATE NOTI: ${Util.output(noti)}`)
                this.notiDic = noti
            }
            this.$emit(`updateNotification`, this.notiDic, `zapier`)
            this.loading = false
        })
    }

    public updateApiKey() {
        this.enable(true)
    }

    public copyToken() {
        $(this).select()
        copyToClipboard(this.notiDic.api_key)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`Copy Zapier API KEY`, ``)
    }

    public hideTooltip(e) {
        this.showTip = false
    }
}
