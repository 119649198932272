
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// モデルを読込.
import Room from "packs/models/Room"
import Plan from "packs/models/Plan"
import Invoice from "packs/models/Invoice"

@Options({
    components: {},
})
export default class Payment extends Vue {
    @Prop()
    plan: Plan
    @Prop()
    willStop: boolean

    // data:

    isSP = isMobile(window.navigator).phone

    @Emit("confirmChangePlan")
    confirmChangePlan(plan) {
        return plan
    }

    created() {}

    updated() {}

    mounted() {}
}
