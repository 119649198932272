
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger, funcName, sleep } from "packs/common"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import UrlWithQRcodeContent from "packs/components/common/UrlWithQRcodeContent.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import AddCommentToMail from "packs/pages/link/parts/room_settings/AddCommentToMail.vue"
import SendMailContent from "packs/pages/link/parts/available_schedule/SendMailContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import TemplatesContent from "packs/pages/link/parts/room_settings/TemplatesContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SuggestedDatesPeriodSettings from "packs/pages/link/parts/room_summary/SuggestedDatesPeriodSettings.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import AstagOpenScheduleSettings from "packs/pages/link/parts/room_summary/AstagOpenScheduleSettings.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomTab from "packs/models/RoomTab"
import MailFlow from "packs/models/MailFlow"
import Notice from "packs/models/Notice"
import ContactListGroup from "packs/models/ContactListGroup"
import VotedRoomManager from "packs/models/VotedRoomManager"
import ChukaiFetcher from "packs/models/fetcher/ChukaiFetcher"

@Options({
    components: {
        SelectTag,
        FormFieldContent,
        UrlWithQRcodeContent,
        AddressBookContent,
        UserIcon,
        AddCommentToMail,
        SendMailContent,
        VueFinalModal,
        MdProgressBar,
        MdProgressSpinner,
        TemplatesContent,
        MdTextfieldTag,
        SuggestedDatesPeriodSettings,
        IconLock,
        AstagOpenScheduleSettings,
    },
})
export default class ChukaiMenuModal extends Vue {
    @Prop()
    room: Room

    @Prop()
    member: RoomMember

    @Prop()
    displayChukaiModalMenuType: string

    // data
    rm = RoomManager
    rcm = RoomCreateManager
    vrm = VotedRoomManager

    loading = false
    // isMe = false

    // results = null
    Util = Util
    // currentRoom: Room = null

    rooms: Room[] = null
    // pubRoomsArray: any[] = []
    // publics: string[] = null

    // copyAstagForOtherPublicRoom / changeAstagForOtherPublicRoom
    createPlanType = null
    publicId = null
    selectedRoom = null

    /**
     * ボタンに設定している公開ページ
     * ParentRoomのユーザー（member）を指定してきた場合のみ入ります.
     */
    defaultPubName = null
    selectedPubRoom: Room = null
    fields: FormField[] = null
    systemUpdatedAt = Util.getSec()

    additionalParams = {}
    generatedUrl = null

    step = 0

    // currentMember: RoomMember = null
    // editOrNewForm = ``
    members: RoomMember[] = []
    UserPermissionsOption = UserPermissionsOption
    // titles = TemplateUtil.getTitles()
    // mailsDic: { [key: string]: MailFlow } = {} // {key: user.email, value: {subject:, content:, cc_emails:, use_template: false}}
    // currentMail: MailFlow = null

    viewType = `pub_room` // pub_room, form, period
    openMember: RoomMember = null
    suggestedDates: any[] = []
    selectedLength = 0

    // 作成されたルーム情報 Array<{room: Room, room_link: string}>
    generatedRoomsDic: any[] = null

    isOnlyPeriodPage = false

    openModal() {
        Logger(`ChukaiMenuModal#openModal room_id:${this.room?.id}, mem:${this.member?.id}`)
        this.resetRooms()
        this.selectedPubRoom = null

        this.updateRoom()
    }

    @Watch("room", { deep: true })
    updateRoom() {
        if (Util.isBlank(this.room)) return
        // if (this.selectedPubRoom) return
        Logger(`${funcName()} room_id:${this.room?.id}, mem:${this.member?.id}`)
        if (!this.openMember && this.member) {
            this.openMember = RoomMember.copy(this.member)
            this.suggestedDates = this.openMember.suggested_dates
            this.vrm.openMember = this.openMember
            if (this.displayChukaiModalMenuType) {
                if (this.displayChukaiModalMenuType == `changePeriod`) {
                    this.isOnlyPeriodPage = true
                    this.vrm.suggested_dates_display_type = this.openMember.suggested_dates_display_type
                    this.vrm.suggested_dates_display_start_at = this.openMember.suggested_dates_display_start_at
                    this.vrm.suggested_dates_display_end_at = this.openMember.suggested_dates_display_end_at
                    this.viewType = `period`
                }
            }
            this.updateCount()
        }
    }

    hide() {
        if (confirm(`閉じるとリセットされますが、よろしいですか？`)) {
            this.resetRooms()
            this.selectedPubRoom = null
            this.$vfm.close(`ChukaiMenuModal`)
        }
    }

    copyAstagForOtherRoom() {
        Logger(`copyAstagForOtherRoom`)
        if (!this.canUseChukaiMenu) {
            Notice.message = `仲介メニューは、オプションで利用できます。担当者にお問い合わせください。`
            this.clickRequestOption(`上`)
            return
        }

        this.createPlanType = `copyAstagForOtherPublicRoom`
        this.step = 1
    }

    changePubRoomsAstag() {
        Logger(`changePubRoomsAstag`)
        if (!this.canUseChukaiMenu) {
            Notice.message = `仲介メニューは、オプションで利用できます。担当者にお問い合わせください。`
            this.clickRequestOption(`下`)
            return
        }

        this.createPlanType = `changeAstagForOtherPublicRoom`
        this.step = 1
    }

    inputPublicID(e) {
        Logger(`${funcName()} e:${e}`)
        this.publicId = e
    }

    clickConnectPublicRoom() {
        Logger(`${funcName()} `)
        if (this.loading) return
        this.loading = true

        this.rm.startProgress()
        Room.search([this.publicId], this.rm.userInfo.user_id, null, null, null, null, null).then(rooms => {
            if (Util.isPresent(rooms)) {
                let selectedRoom = rooms[0]
                this.selectedRoom = selectedRoom
                this.vrm.sendPublicRooms = [selectedRoom]
            } else {
                Notice.message = `公開ページが見つかりませんでした。`
            }
            this.rm.endProgress()
            this.loading = false
        })
    }

    // PublicRoomの選択をしたら次のステップへ行きます.
    selectPubRoomEnd() {
        Logger(`${funcName()}`)
        if (this.loading) return
        this.loading = true

        this.rm.startProgress()

        if (this.createPlanType == `copyAstagForOtherPublicRoom`) {
            this.vrm.createOtherRoomsFromChukai().then(res => {
                this.rm.endProgress()
                this.loading = false

                if (res) {
                    this.viewType = `complete`
                }
            })
        } else if (this.createPlanType == `changeAstagForOtherPublicRoom`) {
            this.vrm.updatePublicRoomsAstagFromChukai().then(res => {
                if (res) {
                    // rmにある公開ページのastagを更新します.
                    // TODO: summaryページのastagも更新する必要があります.
                    this.rm.endProgress()
                    this.loading = false

                    let ownerAstag = res.owner_astag
                    let tabs = { ...this.rm.roomTabs }
                    let selfTab = tabs[`self`][`public`]
                    if (Util.isPresent(selfTab?.rooms)) {
                        for (let room of selfTab.rooms) {
                            if (room.id == this.selectedRoom.id) {
                                room.owner_avaialble_schedule_tag_id = ownerAstag.owner_avaialble_schedule_tag_id
                                room.owner_avaialble_schedule_tag_name = ownerAstag.owner_avaialble_schedule_tag_name
                                room.owner_avaialble_schedule_tag_color = ownerAstag.owner_avaialble_schedule_tag_color
                            }
                        }
                    }

                    let allTab = tabs[`all`][`public`]
                    if (Util.isPresent(allTab?.rooms)) {
                        for (let room of allTab.rooms) {
                            if (room.id == this.selectedRoom.id) {
                                room.owner_avaialble_schedule_tag_id = ownerAstag.owner_avaialble_schedule_tag_id
                                room.owner_avaialble_schedule_tag_name = ownerAstag.owner_avaialble_schedule_tag_name
                                room.owner_avaialble_schedule_tag_color = ownerAstag.owner_avaialble_schedule_tag_color
                            }
                        }
                    }
                    this.rm.roomTabs = tabs
                    this.viewType = `complete`
                }
            })
        }
    }

    public restart() {
        this.additionalParams = {}
        this.step = 0
    }

    resetRooms() {
        if (Util.isPresent(this.members)) {
            for (let m of this.members) {
                m.answer_results = {}
            }
        }

        this.members = []
        this.rcm.currentSendMem = null
        this.viewType = `pub_room`
        this.openMember = null
        this.suggestedDates = []
        this.selectedLength = 0
        this.selectedRoom = null
        // this.selectedPubRoom.form_fields
        this.vrm.resetChukaiMenu()

        this.restart()
    }

    updateSelectedRoom(room: Room) {
        Logger(`${funcName()} room:${room?.id}`)
        this.$emit(`updateSelectedRoom`, room)
    }

    clickChangeName() {
        Logger(`${funcName()}`)
        this.viewType = `form`
    }

    clickChangeSpan() {
        Logger(`${funcName()}`)
        this.viewType = `period`
    }

    inputOpenMemberName(e) {
        Logger(`${funcName()} e:${e}`)
        this.openMember.name = e
    }

    clickPreStepFromForm() {
        Logger(`${funcName()}`)
        this.viewType = `pub_room`
    }

    clickPreStepFromPeriod() {
        Logger(`${funcName()}`)
        this.updateCount()
        this.viewType = `pub_room`
    }

    changeOnlyPeriod() {
        Logger(`${funcName()}`)
        this.openMember = RoomMember.copy(this.vrm.openMember)
        this.openMember.suggested_dates_display_type = this.vrm.suggested_dates_display_type
        this.openMember.suggested_dates_display_start_at = this.vrm.suggested_dates_display_start_at
        this.openMember.suggested_dates_display_end_at = this.vrm.suggested_dates_display_end_at

        let params = { member: this.openMember }
        ChukaiFetcher.updateDisplayOrderFromChukai(params).then(res => {
            if (res) {
                Notice.message = res.message

                // 成功したら、メンバーをアップデートします.
                let currentMem = this.vrm.suggestedMembers.find(m => m.id == this.openMember.id)
                if (currentMem) {
                    currentMem.suggested_dates_display_type = this.openMember.suggested_dates_display_type
                    currentMem.suggested_dates_display_start_at = this.openMember.suggested_dates_display_start_at
                    currentMem.suggested_dates_display_end_at = this.openMember.suggested_dates_display_end_at
                    currentMem.suggested_dates_optional_schedules = this.openMember.suggested_dates_optional_schedules
                }
                // this.suggestedDates = res
                // this.vrm.openMember = this.openMember
                // this.updateCount()
                // this.viewType = `pub_room`
            }
        })

        // 変更を保存
    }

    /**
     * 表示件数を更新します.
     */
    updateCount() {
        let type = this.vrm.suggested_dates_display_type
        if (type == `all`) {
            this.selectedLength = this.suggestedDates?.length || 0
        } else if (type == `period`) {
            let selectedPds = []
            for (let pd of this.suggestedDates) {
                if (
                    this.vrm.suggested_dates_display_start_at < pd.start_time &&
                    this.vrm.suggested_dates_display_end_at > pd.start_time
                ) {
                    selectedPds.push(pd)
                }
            }
            this.selectedLength = selectedPds.length
        } else if (type == `select_self`) {
            this.selectedLength = this.suggestedDates.filter(e => e.selected).length
        }
    }

    closeModalFromComplete() {
        this.resetRooms()
        this.selectedPubRoom = null
        this.$vfm.close(`ChukaiMenuModal`)
    }

    get canUseChukaiMenu() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_chukai_menu`, false)
    }

    clickRequestOption(text) {
        // 送信
        Util.sendInquiry(`仲介メニューオプション ${text}ボタン 課金リクエストがありました`, -1, 16).then(e => {})
    }

    changeOpenSchedulesType() {
        Logger(`${funcName()}`)
        if (!this.canUseChukaiMenu) {
            Notice.message = `仲介メニューは、オプションで利用できます。担当者にお問い合わせください。`
            this.clickRequestOption(`確定一覧表示`)
            return
        }

        this.createPlanType = `changeOpenSchedulesType`
        this.step = 1
    }
}
