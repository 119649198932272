
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { gtagClick } from "packs/GoogleTagManager"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import TextareaTag from "packs/components/forms/TextareaTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        TextareaTag,
    },
})
export default class ModalInnerPaymentRequest extends Vue {
    @Prop()
    type: string // upgrade/account

    // data
    isSP = isMobile(window.navigator).phone

    changedSomeStatus = false
    isActive = true

    notice = Notice
    rm = RoomManager
    showAds = false
    requestForm: string

    // @Emit()
    // hide(didChange: boolean, appo: null) {}

    public created() {}

    public request() {
        // this.currentDateTag = currentDateTag;
        gtagClick(`課金フォーム`, `リクエスト`)
        Logger(`request 有料課金`)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(this.requestForm, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.requestForm = ``
                this.fadeOutAdModal()
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    public cancelInput() {
        // モーダルを閉じます。
        // this.$vfm.close("CancelAppointmentModal");
        this.$emit("hide")
    }

    get requestValue(): string {
        if (!this.requestForm) ``
        return this.requestForm
    }

    public inputRequest(e) {
        this.requestForm = e
    }

    // public hideAvailableTimeModal(dtag) {
    //     // this.$vfm.close("AvailableTimeModal", dtag);
    //     this.hideAvailableTimeModal(this.changedSomeStatus, dtag);
    //     // this.$emit("hideAvailableTimeModal", this.changedSomeStatus, dtag);
    // }
    public fadeOutAdModal() {
        // this.showAds = false;
        this.$emit("hide")
    }
}
