
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import RoomLightOverViewContent from "packs/pages/link/parts/RoomLightOverViewContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: { RoomLightOverViewContent, VueFinalModal, MdProgressBar },
})
export default class CreateFromDraftModal extends Vue {
    @Prop()
    room: Room

    @Prop()
    modalType: string // create（下書きのみ）, delete（終了していない調整ページすべて）, createAll（下書きのみ）, deleteAll（下書きのみ）

    @Prop() // func
    closeDraftModal

    @Prop()
    userFilter: string

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Room = Room
    Util = Util
    isSP = isMobile(window.navigator).phone
    text = ``
    loading = false

    public getTitle(): string {
        if (!this.modalType) return "確認"

        if (this.modalType == `create`) {
            return "下書きから調整ページを作成"
        } else if (this.modalType == `delete`) {
            return "確認"
        } else if (this.modalType == `createAll`) {
            return "下書きから調整ページを一括作成"
        } else if (this.modalType == `deleteAll`) {
            return "下書きから調整ページを一括削除"
        }
    }

    public getDescription() {
        if (!this.modalType) return "確認"

        if (this.modalType == `create`) {
            if (!this.room) return
            return `「${this.room.current_appointment.name}」の日程調整を開始します。（作成者がオーナーになります。）`
        } else if (this.modalType == `delete`) {
            if (Room.isParentDraft(this.room.status)) {
                if (!this.room) return
                return `「${this.room.current_appointment.name}」の下書きを削除します。一度削除すると元に戻せません。`
            } else {
                return `公開中のURLは無効になりますが日程調整を終了しますか？\n終了すると、お相手がページを確認できなくなります。\n\n※ お相手には、終了したことは通知されません`
            }
        } else if (this.modalType == `createAll`) {
            return "下書きから調整ページを一括で作成します。\n\n※ 作成情報に不備がある場合は、そのまま下書きに残ります。\nまた、作成者がオーナーになります。"
        } else if (this.modalType == `deleteAll`) {
            return "下書きから調整ページを一括削除します。一度削除すると元に戻せません。"
        }
    }

    public getButtonName() {
        if (!this.modalType) return "作成"

        if (this.modalType == `create`) {
            return "作成"
        } else if (this.modalType == `delete`) {
            return Room.isParentDraft(this.room.status) ? "削除" : "終了"
        } else if (this.modalType == `createAll`) {
            return "一括作成"
        } else if (this.modalType == `deleteAll`) {
            return "一括削除"
        }
    }

    public closeModal() {
        // モーダルを閉じます。
        this.$vfm.close("CreateFromDraftModal")
    }

    public destroyed() {}

    create() {
        if (this.loading) return
        gtagClick(`下書きモーダルからアクション ${this.modalType}`, `${this.room ? `${this.room.public_id}` : ``}`)

        this.loading = true

        if (this.modalType == `create`) {
            this.createRoom()
        } else if (this.modalType == `createAll`) {
            this.createAllRoom()
        } else if (this.modalType == `delete`) {
            if (this.room.room_type == `public_room`) {
                this.deleteRoom()
            } else if (Room.isParentDraft(this.room.status)) {
                // 下書き削除.
                this.deleteRoom()
            } else {
                this.closeRoom()
            }
        } else if (this.modalType == `deleteAll`) {
            this.deleteAllRoom()
        }
    }

    createRoom() {
        this.rm.startProgress()
        const mems = Room.deleteMeFromRoomMembers(this.room, this.rm.userInfo.user_id)
        this.rcm.setNew(
            this.room,
            this.room.current_appointment,
            mems,
            this.rm.astag,
            this.rm.userInfo,
            this.rm.user_setting,
            `new`
        )
        this.rcm.saveRoom().then(result => {
            // 保存できた場合、ストレージの情報を削除しておきます。
            this.closeDraftModal(result.isSuccess, result.room)
            this.closeModal()
            this.rm.endProgress()
            this.loading = false
        })
    }

    createAllRoom() {
        this.rm.startProgress()
        Room.createAll(this.userFilter).then(result => {
            // 保存できた場合、ストレージの情報を削除しておきます。
            if (result) {
                Logger(`キャッシュ情報を削除します。`)
                this.closeDraftModal(true, null)
            } else {
                Logger(`送信が失敗したため、そのまま.`)
                this.closeDraftModal(false, null)
            }
            this.closeModal()
            this.rm.endProgress()
            this.loading = false
        })
    }

    // 公開しているルームを終了
    closeRoom() {
        this.rm.startProgress()
        Room.close(this.room).then(e => {
            if (e) {
                this.closeDraftModal(true, this.room)
            } else {
                this.closeDraftModal(false, null)
            }
            this.closeModal()
            this.rm.endProgress()
            this.loading = false
        })
    }

    // 下書きから削除 / PublicRoomを削除・隠す場合もこちら
    deleteRoom() {
        this.rm.startProgress()
        Room.delete(this.room).then(e => {
            if (e) {
                this.closeDraftModal(true, this.room)
            } else {
                this.closeDraftModal(false, null)
            }
            this.closeModal()
            this.rm.endProgress()
            this.loading = false
        })
    }

    deleteAllRoom() {
        this.rm.startProgress()
        Room.deleteAll(this.userFilter).then(e => {
            if (e) {
                this.closeDraftModal(true, null)
            } else {
                this.closeDraftModal(false, null)
            }
            this.closeModal()
            this.rm.endProgress()
            this.loading = false
        })
    }
}
