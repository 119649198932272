
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "../../../utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Card, createToken } from "vue-stripe-elements-plus"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "../../../components/common/FlashNotice.vue"
import SelectTag from "../../../components/forms/SelectTag.vue"
import TextfieldTag from "../../../components/forms/TextfieldTag.vue"
import AccountHeader from "./AccountHeader.vue"
import PlanBox from "packs/components/common/PlanBox.vue"
import ModalInnerConfirmChangePlan from "./../modals/ModalInnerConfirmChangePlan.vue"
import PlanLoader from "packs/components/loader/PlanLoader.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import SubscriptionManager from "packs/models/SubscriptionManager"
import Room from "../../../models/Room"
import PropertyStore from "../../../models/PropertyStore"
import RoomManager from "../../../models/RoomManager"
import Notice from "../../../models/Notice"
import RoomStorage from "../../../models/RoomStorage"
import RoomMember from "../../../models/RoomMember"
import Plan from "packs/models/Plan"
import Invoice from "packs/models/Invoice"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        TextfieldTag,
        AccountHeader,
        Card,
        PlanBox,
        ModalInnerConfirmChangePlan,
        PlanLoader,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class Payment extends Vue {
    // data:
    sharedState = PropertyStore
    notice = Notice

    rm = RoomManager
    util = Util
    selectedTab = `payment`

    isSP = isMobile(window.navigator).phone
    loading = false

    plans: Plan[] = null
    currentPlan: Plan = null // 選択中のプラン（途中ユーザーが変更した場合、そのデータを覚えておく）
    upcomingInvoice: Invoice = null
    invoices: Invoice[] = null
    stripeOptions = { hidePostalCode: true }
    complete = false
    paymentInfo = null
    stripeKey = location.href.includes(`https://waaq.jp`)
        ? `pk_live_0VBJw5tgdJnWOCm4coePCZgF`
        : `pk_test_s7tBm4XtHFIkk7dfghQPJpxL`

    originPlan: Plan = null //送られてきた時点での元々のプラン.
    willChangePlan = null
    action = null // "toPay"無料->有料, "toFree"有料->無料, "change"有料->有料
    users = []
    willStop = false
    subm = SubscriptionManager

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                if (this.rm.userInfo.user_group_role == 100) {
                    this.getSubscriptionData()
                }
            })
        } else if (this.rm.userInfo.user_group_role == 100) {
            this.getSubscriptionData()
        } else {
            this.checkAdmin()
        }
        this.getUserList()
    }

    getUserList() {
        RoomMember.getUserList().then(list => {
            this.users = list || []
        })
    }

    public getSubscriptionData() {
        this.rm.startProgress()
        this.subm.getSubscriptionData().then(data => {
            if (data) {
                this.plans = Plan.fetchFromJson(data.plans)
                Logger(`plans: ${Util.output(this.plans)}`)
                this.currentPlan = this.plans.find(p => p.is_current)
                this.willStop = this.plans.some(p => p.will_stop)
                const _plan: Plan = { ...this.currentPlan }
                this.originPlan = _plan

                this.upcomingInvoice = data.upcoming_invoice
                this.invoices = Invoice.fetchFromJson(data.invoices)
                Logger(`invoices: ${Util.output(this.invoices)}`)
                this.paymentInfo = data.payment_method
            } else {
                this.paymentInfo = {}
                this.invoices = []
                this.plans = []
            }
            // this.currentPlan = data;
            this.rm.endProgress()
        })
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/payment")
    }

    /**
     * Stripeの情報を更新し、その後サーバーの情報を更新します。
     */
    public pay(): Promise<any> {
        Logger(`pay!`)
        Notice.message = null
        this.rm.startProgress()
        return createToken().then(data => {
            Logger(`return payment data: ${Util.output(data)}`)
            const error = data.error
            if (error) {
                Notice.message = error.message
                return false
            }
            this.complete = true

            const token = data.token
            if (token) {
                const newCard = token.card
                const tokenId = token.id
                Logger(`token id: ${tokenId}`)
                // オリジンプランとカレントプランが同じでカード情報が違う場合は、カード変更があった旨をサーバーに連絡.
                if (
                    (!this.willChangePlan || this.originPlan.id == this.willChangePlan.id) &&
                    Plan.isDiff(newCard, this.paymentInfo)
                ) {
                    Plan.updatePaymentMethod(tokenId).then(success => {
                        if (success) {
                            Notice.message = `カード情報を更新しました。`
                            // this.paymentInfo = newCard;
                            this.getSubscriptionData()
                            return true
                        } else {
                            // Notice.message = `うまく更新できませんでした。後ほどお試しください。`;
                            return false
                        }
                        this.rm.endProgress()
                        return true
                    })
                }

                // オリジンプランとカレントプランが異なる場合、その変更をサーバーに連絡.
                if (this.originPlan.id != this.willChangePlan.id) {
                    Plan.changePlan(this.willChangePlan, tokenId).then(success => {
                        if (success) {
                            Notice.message = `プランを変更しました。`
                            this.dismissConfirmChangePlanModal()
                            this.getSubscriptionData()
                            // this.originPlan = { ...this.willChangePlan };
                            // this.currentPlan = { ...this.willChangePlan };
                            // this.changeUIPlan(this.currentPlan);
                            return true
                        } else {
                            // Notice.message = `うまく変更できませんでした。後ほどお試しください。`;
                            return false
                        }
                        this.rm.endProgress()
                    })
                }
                // return false
            }
        })
    }

    /**
     * どのプランからどのプランにするのか、プラン変更の判定をします。
     */
    public confirmChangePlan(plan) {
        // 無料 -> 有料
        if (this.originPlan.amount == 0 && plan.amount > 0) {
            // this.changeUIPlan(plan);
            // return false;
            this.action = "toPay"
        }

        // 有料 -> 有料
        else if (this.originPlan.amount > 0 && plan.amount > 0 && this.originPlan.id != plan.id) {
            this.action = "change"
        }

        // 有料 -> 無料
        else if (this.originPlan.amount > 0 && plan.amount == 0) {
            this.action = "toFree"
        }

        if (!this.action) return

        gtagClick(`プラン変更モーダルオープン ${this.action}`, `${plan.amount}円 ${plan.coupon_name}`)

        this.willChangePlan = plan
        this.$vfm.open("ConfirmChangePlanModal")
    }

    public dismissConfirmChangePlanModal() {
        this.willChangePlan = null
        this.action = null
        this.loading = false
        this.$vfm.close("ConfirmChangePlanModal")
    }

    public fixChangePlan() {
        this.loading = true
        // プラン変更 有料 -> 有料、無料 -> 有料
        if (["change", "toPay"].includes(this.action)) {
            // カードの登録が既にある場合、
            if (this.paymentInfo.registered) {
                Plan.changePlan(this.willChangePlan, null).then(success => {
                    if (success) {
                        Notice.message = `プランを変更しました。`
                        // this.changeUIPlan(this.willChangePlan);
                        this.getSubscriptionData()
                    }
                    this.dismissConfirmChangePlanModal()
                })
            }
            // カード情報の登録およびプランを変更します。
            else {
                this.pay().then(success => {
                    if (success) {
                        this.dismissConfirmChangePlanModal()
                    }
                    this.loading = false
                })
            }
        }
        // 有料 -> 無料
        else if (this.action == "toFree") {
            Plan.stopSubscription().then(success => {
                if (success) {
                    Notice.message = `プランを変更しました。`
                    // this.changeUIPlan(this.willChangePlan);
                    this.dismissConfirmChangePlanModal()
                    this.getSubscriptionData()
                }
            })
        }
    }

    /**
     * 一度決定した解約（stop）をキャンセルします。
     */
    cancelStopSubscription() {
        this.rm.startProgress()
        Plan.cancelStopSubscription().then(success => {
            if (success) {
                Notice.message = `変更を取り消しました。`
                // this.changeUIPlan(this.willChangePlan);
                // 最新の情報を取得しなおします。
                this.getSubscriptionData()
            } else {
                Notice.message = `うまく変更できませんでした。管理者にお問い合わせください。`
            }
            this.rm.endProgress()
        })
    }

    public changeUIPlan(plan) {
        for (let _plan of this.plans) {
            if (_plan.id == plan.id) {
                _plan.is_current = true
            } else {
                _plan.is_current = false
            }
        }
        this.currentPlan = plan
    }

    /**
     * ユーザーの役割がアドミン以外の場合、調整ページ一覧に戻ります。
     */
    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (this.rm.userInfo && this.rm.userInfo.user_group_role != 100) {
            this.$router.push("/")
        }
        // location.href = "https://google.com";
    }
}
