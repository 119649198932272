import { DateTime } from "luxon"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import Room from "./Room"
import PossibleDate from "./PossibleDate"
import RoomMember from "./RoomMember"
import NotificationControl from "packs/utils/NotificationControl"

export default class AppointmentForm {
    constructor(
        public name: string,
        public id: string,
        public created_at: number,
        public updated_at: number,
        public url_from: string,
        public html_from: string,
        public url: string,
        public html: string,
        public fields: any[],
        public form_info: any,
        public submit_button_info: any
    ) {}

    static fetchFromJson(apfsJson: any): AppointmentForm[] {
        let appointments = []
        // const arrOfGroups = JSON.parse(groupsJson)
        Logger(`Appointment Forms arrOfGroups: ${apfsJson.length}`)
        for (let appo of apfsJson) {
            const _appo: AppointmentForm = appo
            appointments.push(_appo)
        }
        return appointments
    }

    static createDefault(): AppointmentForm {
        let now = Util.getSec()
        return new AppointmentForm(`打ち合わせ`, `newId`, now, now, ``, ``, ``, ``, [], {}, {})
    }

    /**
     * URLから外部のフォームを取得してきます
     * @from [string]
     */
    static getForm(from: string): Promise<AppointmentForm> {
        const params = { from_url: from }
        //     // Getリクエストのパラムをオブジェクト化するオプション
        return axios
            .get(`${Util.prefixUrl}/appointment_forms/from_url`, { params: params })
            .then(result => {
                Logger(`AppointmentForm.getForm result: ${Util.output(result.data)}`)
                const form: AppointmentForm = result.data.appointment_form
                return form
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
