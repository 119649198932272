
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import SelectFieldLi from "packs/pages/link/parts/room_settings/SelectFieldLi.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import SpanSlotContent from "packs/components/common/SpanSlotContent.vue"
import { Dropdown } from "uiv"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import FormField from "packs/models/FormField"
import UserFile from "packs/models/UserFile"
import SelectUtil from "packs/utils/SelectUtil"
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        Switcher,
        SelectFieldLi,
        CheckboxTag,
        SelectTag,
        IconLock,
        IconHelp,
        SpanSlotContent,
        MdTextfieldTag,
    },
})
export default class FormFieldEditSettingOptionsContent extends Vue {
    @Prop()
    editingField: FormField

    FormField = FormField
    field: FormField = null
    rm = RoomManager

    value: string = ``
    selectableTypes: string[] = [`radio`, `select`, `checkbox`]
    selectableWithImagesTypes: string[] = [`radio_image`, `checkbox_image`]
    selSystemUpdatedAt = Util.getSec()
    drag: boolean = false
    imageEditIndex = null
    currentImageUrl = null
    displayTypes = SelectUtil.fieldDisplayTypes
    displayType = null
    rcm = RoomCreateManager

    selectedType = FormField.RELATIVE_TIME_RANGE
    currentBirthDateOption = null

    startDate = DateTime.fromSeconds(DateTime.local().toSeconds()).toFormat("yyyy/MM/dd")
    endDate = DateTime.fromSeconds(DateTime.local().plus({ days: 100 }).toSeconds()).toFormat("yyyy/MM/dd")
    useBirthdatePeriod = false

    mounted() {
        this.updateField()
    }

    @Watch("editingField", { deep: true })
    updateField() {
        if (!this.editingField) return
        this.field = this.editingField
        let settingOptions = this.field?.option_hash?.field_setting_options || []
        let option = settingOptions.find(_t => [FormField.ABSOLUTE_TIME_RANGE, FormField.RELATIVE_TIME_RANGE].includes(_t.type))
        if (option) {
            this.selectedType = option.type
            this.currentBirthDateOption = option
            if (option.type == FormField.ABSOLUTE_TIME_RANGE) {
                this.startDate = DateTime.fromSeconds(option.start_at).toFormat("yyyy/MM/dd")
                this.endDate = DateTime.fromSeconds(option.end_at).toFormat("yyyy/MM/dd")
            }
            this.useBirthdatePeriod = true
        }
    }

    selectPeriod() {
        this.selectedType = FormField.RELATIVE_TIME_RANGE
        this.currentBirthDateOption = { type: FormField.RELATIVE_TIME_RANGE, start_day: 0, end_day: 730, restrict_date: false }
        this.useBirthdatePeriod = true
    }

    updateFieldFromChild() {
        if (this.currentBirthDateOption) {
            let options = []

            options.push(this.currentBirthDateOption)
            this.field.option_hash = { field_setting_options: options }
        } else {
            // if (this.field.option_hash) {
            //     delete this.field.option_hash.field_setting_options
            //     if (Object.keys(this.field.option_hash).length == 0) {
            //         this.field.option_hash = null
            //     }
            // }
            this.field.option_hash = null
        }

        this.$emit(`updateFieldFromChild`, this.field)
    }

    changeBirthdateSpanType(type: string) {
        Logger(`${funcName()} type:${type}`)
        if (type == FormField.RELATIVE_TIME_RANGE) {
            this.currentBirthDateOption = { type: type, start_day: 0, end_day: 730, restrict_date: false }
        } else {
            let startAt = DateTime.local()
            let endAt = startAt.plus({ days: 100 })
            this.currentBirthDateOption = {
                type: type,
                start_at: startAt.toSeconds(),
                end_at: endAt.toSeconds(),
                restrict_date: false,
            }
        }
        this.selectedType = type
        this.updateFieldFromChild()
    }

    @Watch(`startDate`, { deep: true })
    updateStartDate() {
        let sec = DateTime.fromFormat(this.startDate, `yyyy/MM/dd`).toSeconds()
        this.currentBirthDateOption.start_at = sec
        this.updateFieldFromChild()
    }

    @Watch(`endDate`, { deep: true })
    updateEndDate() {
        let sec = DateTime.fromFormat(this.endDate, `yyyy/MM/dd`).toSeconds()
        this.currentBirthDateOption.end_at = sec
        this.updateFieldFromChild()
    }

    inputStartDay(value: string) {
        this.currentBirthDateOption.start_day = parseInt(value)
        this.updateFieldFromChild()
    }

    inputEndDay(value: string) {
        this.currentBirthDateOption.end_day = parseInt(value)
        this.updateFieldFromChild()
    }

    clickChangeRestrictDate() {
        if (this.currentBirthDateOption.restrict_date) {
            this.currentBirthDateOption.restrict_date = false
        } else {
            this.currentBirthDateOption.restrict_date = true
        }
        this.updateFieldFromChild()
    }

    resetPeriod() {
        this.currentBirthDateOption = null
        this.useBirthdatePeriod = false
        this.selectedType = FormField.RELATIVE_TIME_RANGE
        this.updateFieldFromChild()
    }
}
