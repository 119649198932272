import { DateTime } from "luxon"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "packs/models/Notice"
import NotificationControl from "packs/utils/NotificationControl"

export default class EmailDomain {
    constructor(
        public name: string,
        public id: string,
        public status: number,
        public submit_status: number,
        public invalid_message: string,
        public email_domain_records: any[]
    ) {}

    static deleteDomain(domain: EmailDomain) {
        let params = { email_domain: domain }
        return axios
            .delete(`${Util.prefixUrl}/organizations/email_domains`, { data: params })
            .then(res => {
                Logger(`deleteDomain:res ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return res.data.email_domain as EmailDomain
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * ドメインを登録します
     * @param name [string] "waaq.jp"
     * @returns
     */
    static createDomain(name: string) {
        return axios
            .post(`${Util.prefixUrl}/organizations/email_domains`, {
                name: name,
            })
            .then(res => {
                let maildomain = res.data.email_domain
                Logger(`maildomain: ${maildomain}`)
                Notice.message = res.data.message

                return maildomain
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static changeSubmitStatus(domain: EmailDomain) {
        let params = { email_domain: domain }
        return axios
            .post(`${Util.prefixUrl}/organizations/email_domains/change_submit_status`, params)
            .then(res => {
                Logger(`changeSubmitStatus:res ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return res.data.email_domain as EmailDomain
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static assignDomainToTeam(ug: any, dom: EmailDomain) {
        let params = { user_group: ug, email_domain: dom }
        return axios
            .post(`${Util.prefixUrl}/organizations/email_domains/assign_user_group`, params)
            .then(res => {
                Logger(`assignDomainToTeam:res ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return res.data.email_domain
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static removeAssignDomain(ug: any, dom: EmailDomain) {
        let params = { user_group: ug, email_domain: dom }
        return axios
            .delete(`${Util.prefixUrl}/organizations/email_domains/assign_user_group`, { data: params })
            .then(res => {
                Logger(`removeAssignDomain:res ${res.data.message}`)
                Notice.message = `${res.data.message}`
                // let _room = Room.fetchFromJson([res.data.room], user_id)[0]
                return res.data.email_domain
            })
            .catch(err => {
                Logger(`err: ${Util.output(err)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
