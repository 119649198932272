
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import Popper from "vue3-popper"
import PopupInnerContent from "packs/pages/link/parts/room_settings/PopupInnerContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import TemplateUtil from "packs/utils/TemplateUtil"

// const roomStorage = new RoomStorage()

@Options({
    components: {
        UserIcon,
        Popper,
        PopupInnerContent,
    },
})
export default class SelectableGroups extends Vue {
    // data:
    @Prop()
    appo: Appointment

    notice = Notice

    rm = RoomManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    TemplateUtil = TemplateUtil
    truncate = truncate

    currentFile: UserFile = null // イメージプレビューをするファイル.
    description = null
    teamName = null
    mags: MeetingAttendeesGroup[] = null
    systemUpdatedAt = null
    currentMag: MeetingAttendeesGroup = null //選択中のmag

    displayType = null

    public mounted() {
        this.updateMags()
        this.showSelectableContent()
    }

    showSelectableContent() {
        // Logger(`SelectableGroups.showSelectableContentAfterFixed`)
        if (
            this.appo &&
            this.appo.status == 10 &&
            Util.isPresent(this.appo.selectedMags) &&
            this.pdm.displayType != `reschedule`
        ) {
            this.displayType = `fixed`
        } else if (this.pdm && this.pdm.selectable_mode && this.mags && this.mags.length > 0) {
            this.displayType = `selectable`
        } else {
            this.displayType = null
        }
    }

    /**
     * 「誰でも」を作成します。
     */
    public createAnyMag() {
        let anymag = MeetingAttendeesGroup.createDefault()
        anymag.id = `any`
        return anymag
    }

    @Watch("pdm", { deep: true })
    updateMags() {
        // Logger(`${funcName()} pdm.selectable_mode:${this.pdm ? this.pdm.selectable_mode : null}`)
        if (!this.pdm || !this.pdm.selectable_mag_dic) return
        let mags = []
        let isSelect = false
        Object.entries(this.pdm.selectable_mag_dic).forEach(([mag_id, _mag]) => {
            Logger(`inner entry _mag.name=${_mag.name}`)
            if (this.pdm.selectedMag && this.pdm.selectedMag.id == mag_id) {
                _mag.selected = true
                this.currentMag = _mag
                isSelect = true
            }
            mags.push(_mag)
        })
        let magDesc = Util.sortByWords(mags, `name`, true)

        if (Util.isPresent(mags) && mags.length > 1) {
            let anymag = this.createAnyMag()
            if (!isSelect) anymag.selected = true
            magDesc.unshift(anymag)
        }

        // Logger(`${funcName()} mags.length:${magDesc ? magDesc.length : null}`)
        this.mags = magDesc
        this.showSelectableContent()
    }

    public mouseoverImage(mag: MeetingAttendeesGroup) {
        this.description = mag.description
        this.teamName = mag.team_name
        $(`.sel${mag.id}`).css({ transform: `scale(1.1, 1.1)` })
    }

    public mouseleaveImage(mag: MeetingAttendeesGroup) {
        // this.description = null
        if (!this.currentMag) {
            this.description = null
            this.teamName = null
            return
        }
        $(`.sel${mag.id}`).css({ transform: `scale(1.0, 1.0)` })
        this.description = this.currentMag.description
        this.teamName = this.currentMag.team_name
    }

    public clickMag(mag: MeetingAttendeesGroup) {
        this.resetSelect()

        let _mag = this.mags.find(m => m.id == mag.id)
        _mag.selected = true
        this.currentMag = _mag

        this.systemUpdatedAt = Util.getSec()
        this.$emit("selectMag", this.currentMag)
    }

    public resetSelect() {
        for (let _mag of this.mags) {
            _mag.selected = false
        }
    }

    profileText(mag: MeetingAttendeesGroup) {
        if (!mag || !mag.description || !mag.team_name) return ``

        let retText = `<div class='with_white_bg ddgrey pad20' style='width:320px;'>`
        if (mag.team_name) {
            retText += `<p class='fw600 w100 fs16 mb5'>${mag.team_name}</p>`
        }
        if (mag.description) {
            let linkTextArray = this.autoLink(mag.description, true)
            let linkText = ``
            for (let text of linkTextArray) {
                if (text.match(TemplateUtil.buttonRegex) || text.match(TemplateUtil.buttonRegexWithOptions)) {
                    let link = text.match(TemplateUtil.buttonLinkRegex)[0].replace(`(`, ``).split(`)`)[0]
                    let buttonText = text.match(TemplateUtil.buttonTextRegex)[0].replace(`[`, ``).replace(`]`, ``)
                    linkText += `<a
                                    href="${link}"
                                    class=" ${this.isSP ? `fs12` : `fs14`}"
                                    rel="noopener noreferrer nofollow"
                                    target="_blank"
                                    style="text-decoration:none;"
                                >
                                    <div class="w100 pad5 skyblueBorder l2 with_white_bg centered fw600 rad8 hoverGrey">
                                        <span class="">${buttonText}</span>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="clear"></div>
                                </a>
                                `
                } else if (text.match(TemplateUtil.regex)) {
                    linkText += `<span>
                                    <a
                                        href="${text}"
                                        class="innerLink skyblue fw600 ${this.isSP ? `fs12` : ``}"
                                        target="_blank"
                                        rel="noopener noreferrer nofollow"
                                        >${text}</a
                                    >
                                </span>`
                } else if (
                    text.match(TemplateUtil.telRegex1) ||
                    text.match(TemplateUtil.telRegex2) ||
                    text.match(TemplateUtil.telRegex3)
                ) {
                    linkText += `<span>
                                    <a
                                        href="tel:${text}"
                                        class="innerLink skyblue fw600 ${this.isSP ? `fs12` : ``}"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style="text-decoration: underline"
                                        >${text}</a
                                    >
                                </span>`
                } else {
                    linkText += `<span class="fw600" style="line-height: 1.6">${text}</span>`
                }
            }
            retText += `<p class='fw600 w100 fs11 mt10'>紹介文</p><p class='text-left fs13'>${linkText}<div class='clear'></div></p>`
        }
        retText += `</div>`

        return retText
    }

    autoLink(text: string, changeBr: boolean = false): string[] {
        if (Util.isBlank(text)) return []

        let processText = text
        if (changeBr) processText = text.replace(/\n/g, `<br />`)

        return TemplateUtil.processText(processText)
    }
}
