
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import ReportStatCell from "packs/pages/link/parts/ReportStatCell.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import SubscriptionManager from "packs/models/SubscriptionManager"
import Report from "packs/models/Report"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        RoomsLoader,
        ReportStatCell,
        SelectTag,
    },
})
export default class Reports extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    mem = RoomMember
    isSP = isMobile(window.navigator).phone

    subm = SubscriptionManager
    UserPermissionsOption = UserPermissionsOption

    repo: Report = null
    repoDate = null
    loading = false

    Const = Const
    openReportUserIds = null
    selectMonths = [
        `${DateTime.local().month}月`,
        `${DateTime.local().minus({ month: 1 }).month}月`,
        `${DateTime.local().minus({ month: 2 }).month}月`,
    ]

    public created() {
        this.openReportUserIds = roomStorage.fetchOpenReportUserIDs()
        if (!this.rm.hasAsTag()) {
            this.rm.getAvailableSchedule().then(e => {
                this.rm.redirectTopIfChukaiFreePlan()
                this.checkPermission()
                this.getReports()
            })
        } else {
            this.rm.redirectTopIfChukaiFreePlan()
            this.checkPermission()
            this.getReports()
        }
    }

    /**
     * @param beforeMonth 当月:0, 前月:1,
     */
    public getReports(beforeMonth: number = 0) {
        if (Const.paidOverBussiness(this.rm)) {
            if (this.loading) this.loading = true

            Report.getReports(beforeMonth).then(repo => {
                this.loading = false
                Logger(`repoを取得してきました: ${Util.output(repo)}`)
                this.repoDate = repo.start
                this.repo = repo
            })
        } else {
            // 他ページに遷移
            this.$router.push(`/`)
        }
    }

    public mounted() {
        gtagPage(`#/reports`)
    }

    checkPermission() {
        if (UserPermissionsOption.permitDisplay(this.rm.userInfo, `reports`, `reports`)) {
            return
        }
        if (UserPermissionsOption.permitDisplay(this.rm.userInfo, `reports`, `progresses`)) {
            this.$router.push(`/progresses`)
            return
        }
        this.$router.push(`/`)
    }

    toggleDetail(type: string, user: RoomMember) {
        Logger(`Reports#toggleDetail type:${type}`)
        if (type == `open`) {
            this.openReportUserIds = roomStorage.saveOpenReportUserIDs(user.user_id)
        } else if (type == `close`) {
            this.openReportUserIds = roomStorage.removeOpenReportUserIDs(user.user_id)
        }
    }

    selectMonth(e) {
        Logger(`selectMonth: ${e}`)
        let monthIndex = this.selectMonths.findIndex(m => m == e)
        if (this.loading) {
            Notice.message = `現在取得中です。取得が終わってから変更してください。`
            return
        }
        this.repo = null
        this.getReports(monthIndex)
    }
}
