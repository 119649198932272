
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagEvent, gtagEventOwner, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FormContent from "packs/pages/schedule/FormContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import NotificationControl from "packs/utils/NotificationControl"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import FormUtil from "packs/utils/FormUtil"
import FormField from "packs/models/FormField"

@Options({
    components: {
        FormContent,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class FormContentModal extends Vue {
    // data:

    @Prop()
    pd: PossibleDate

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    loading = false
    cm = CalendarManager
    pdm = PossibleDatesManager

    additionalParams = null

    // スグINボタンから確定する場合こちらの内容で確定する
    instantRoom: Room = null
    loadingFormSending = false

    answeredForm: boolean = false
    answeredFields: FormField[] = null

    openModal() {
        gtagPage(`#/フォームモーダル`)

        if (!this.rm.currentInstantRoom) return
        this.getAdditionalParams()

        this.instantRoom = this.rm.currentInstantRoom
        this.confirmFixSchedule()
    }

    @Watch(`pd`)
    public confirmFixSchedule() {
        if (!this.pd) return

        Logger(`${funcName()} date format: ${this.pd.jp_format} , ${this.pdm.sharedMembers}`)
        if (Util.isRoomSettingsPreview()) {
            Notice.message = `調整ページ編集画面のため、確定できません。`
            return
        }
        this.pdm.selectCurrentPd(this.pd)
        gtagClick(`この日程を確認ボタン`, `${this.pd.jp_format}`)
    }

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("FormContentModal")
    }

    public send() {
        // if (this.loading) return
        // this.loading = true
        // let myAstag: AvailableScheduleTag = null
        // if (Util.isPresent(this.cm.registrable_attendees)) {
        //     myAstag = AvailableScheduleTag.createGuestRegistrableAstag(this.cm.registrable_attendees, this.cm.astag)
        //     Logger(`myAstagに詰め込みます: ${Util.output(myAstag)}`)
        // } else {
        //     myAstag = AvailableScheduleTag.createGuestRegistrableAstag([], this.cm.astag)
        // }
        // gtagClick(`出席者を登録モーダル 登録`, `${(this.cm.registrable_attendees || []).map(att => att.email).join(",")}`)
        // axios
        //     .put(`${Util.prefixUrl}/available_schedule_tags/update_member_astag`, {
        //         available_time: myAstag,
        //         parent_room_id: this.room.id,
        //         registrable_attendees: this.cm.registrable_attendees,
        //     })
        //     .then(res => {
        //         Notice.message = `${res.data.message}`
        //         this.$vfm.close(`AddAttendeesFormModal`)
        //         this.loading = false
        //     })
        //     .catch(err => {
        //         this.loading = false
        //         Logger(`err: ${err.message}`)
        //         NotificationControl.showErrorMessage(err)
        //     })
    }

    /**
     * パラメータがURLに付随している場合は、自動でそのフィールドを埋めます
     */
    getAdditionalParams() {
        this.additionalParams = FormUtil.getParams()
        Logger(`${funcName()} additionalParams:${Util.output(this.additionalParams)}`)
    }

    /**
     * フォーム対応
     */

    public completeForm(fields: FormField[]) {
        Logger(`${funcName()} formが完成しました:${Util.output(fields)}`)
        this.answeredForm = true
        this.answeredFields = fields

        // 送信するルームを作成.
        let _room = Room.copy(this.instantRoom)
        if (Util.isPresent(fields)) {
            _room.form_fields = fields
        }
        let nextstep = `complete`
        Logger(`${funcName()} nextstep:${nextstep}`)

        let startTime = this.pdm.currentPd ? this.pdm.currentPd.start_time : null
        let startTimes = Util.isPresent(this.pdm.currentPds) ? this.pdm.currentPds.map(pd => pd.start_time) : null
        this.rm.startProgress()
        this.loadingFormSending = true
        // if (Util.isPresent(this.pdm.currentPds) && this.SelectPossibleDatesContent) {
        //     this.SelectPossibleDatesContent.changeSavingStatus(true)
        // }

        if (Util.isPresent(this.pdm.currentTZDic)) {
            this.rm.userInfo.timezone_value = this.pdm.currentTZDic.value
        }

        gtagEvent(`フォーム送信`, `send`, `start: ${startTime}, ${startTimes}`)
        FormField.send(
            _room,
            startTime,
            startTimes,
            this.pdm,
            nextstep,
            false,
            false,
            null,
            null,
            this.cm.registrable_attendees,
            this.pdm.currentTZDic,
            this.pdm.rdlog_id,
            this.additionalParams
        ).then(res => {
            this.rm.endProgress()
            this.$vfm.close("FixLoadingModal")
            if (res) {
                // this.rm.userInfo = res.userInfo
                this.rm.setUserInfo(res.userInfo)
                let room = res.room as Room

                // 確定できずにnextAction==`reload`だった場合、モーダルを表示し、日程候補をリロードします.
                let nextAction: string = res.next_action
                let type: string = res.type

                this.rm.updateCurrentInfo(room)

                let _room = (this.rm.rooms || []).find(r => r.room_id == room.room_id)
                if (_room) {
                    Object.assign(_room, room)
                } else {
                    let _rooms = this.rm.rooms ? [...this.rm.rooms] : []
                    _rooms.push(room)
                    this.rm.rooms = _rooms
                }
                if (Util.isPresent(res.astag)) {
                    this.cm.astag = res.astag
                }
                if (Util.isPresent(res.registered_attendees)) {
                    this.cm.registered_attendees = res.registered_attendees
                }
                let _appo = room.current_appointment

                this.pdm.setNew(room, res.userInfo, _appo)

                gtagEvent(`フォーム完了`, `progress_${room.progress_status}`, `appo status: ${_appo.status}`)
                if (_appo.status == 10) {
                    // this.rm.currentAppointment = _appo
                    let notificationType = `FIXED`
                    gtagEventOwner(notificationType, `engagement`, `${_appo.jp_format}`)
                    if (Util.isEmbeds()) {
                        // 埋め込み形式の場合に、親要素に確定した旨を通知します.
                        let notiParams = { type: notificationType, startTime: `${startTime}` }
                        NotificationControl.notifyParent(notificationType, notiParams)
                    }
                }
                // 埋め込みの場合、cookiesにセットできないため、一時的なキーを使います.
                if (Util.isEmbeds() && Util.isPresent(res.guestKey)) {
                    this.rm.guestKey = res.guestKey
                    Logger(`FormField.send ゲストキーをセットしました key:${res.guestKey}`)
                }
            }
            this.loadingFormSending = false
        })
        // } else {
        //     this.currentStep = nextstep
        // }
    }

    changeDate() {}

    skipForm() {}
}
