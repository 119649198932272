
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import CustomPermissionModal from "packs/pages/link/settings/CustomPermissionModal.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import InviteMembersContent from "packs/pages/link/settings/InviteMembersContent.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import MemberSettingsHeader from "packs/pages/link/settings/MemberSettingsHeader.vue"
import ClientUserGroupsContent from "packs/pages/link/settings/ClientUserGroupsContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        CustomPermissionModal,
        OptionModal,
        UserIcon,
        InviteMembersContent,
        AlertModal,
        MemberSettingsHeader,
        ClientUserGroupsContent,
    },
})
export default class Account extends Vue {
    // data:

    @Ref()
    InviteContent

    @Ref()
    ClientUserGroupsContent

    notice = Notice

    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil
    selectedTab = `members`

    isSP = isMobile(window.navigator).phone
    users: any[] = []

    invitedUserEmail = ""
    // 送信する / 変更するユーザー
    invitedUser: RoomMember = null
    invitedUserRole = 1
    failedMail = false // Emailが正しいか.
    sending = false
    action = `new` // new / edit
    systemUpdatedAt = Util.getSec()

    optionEventName = `カスタム権限 課金リクエスト`
    canUseCustomPermissions = false

    willDeleteUser: RoomMember = null

    alertModalDic = {
        title: `アカウント削除`,
        text: ``,
        okButtonName: `削除`,
        eventName: `delete_ug_member`,
        type: `account`,
        item: null,
    }

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkAdmin()

                this.getUserList()

                // this.subm.getSubscriptionData().then(e => {});
            })
        } else {
            this.checkAdmin()
            this.getUserList()
        }
    }

    getUserList() {
        RoomMember.getUserList().then(list => {
            this.users = list
        })
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/account")
    }

    changePermissions(po: UserPermissionsOption) {
        this.$vfm.close(`CustomPermissionModal`)
        if (Util.isPresent(this.invitedUser)) {
            Logger(`既存ユーザーのパーミッションを更新します.`)
            // 既存ユーザー
            this.InviteContent.invite([this.invitedUser.email], -1, po)
        } else {
            Logger(`新規ユーザーのパーミッションを更新します.`)
            // 新規ユーザー
            this.InviteContent.sendInviteMailWithPermissions(po)
        }
    }

    /**
     * アラートモーダルの内容を生成します.
     * @param type `account` or `client_group`
     * @param item `RoomMember` or `ClientUserGroup`
     */
    generateAlertModalDic(type = `account`, item = null) {
        if (type == `account`) {
            this.alertModalDic = {
                title: `アカウント削除`,
                text: ``,
                okButtonName: `削除`,
                eventName: `delete_ug_member`,
                type: `account`,
                item: item,
            }
            return
        }
        this.alertModalDic = {
            title: `招待リンク解除`,
            text: ``,
            okButtonName: `解除`,
            eventName: `delete_client_ug`,
            type: `client_group`,
            item: item,
        }
    }

    selectRole(e) {
        Logger(`role : ${e}`)
        if (e === "メンバー") {
            Logger(`メンバー`)
            this.invitedUserRole = 1
        } else if (e === `管理者`) {
            Logger(`管理者`)
            this.invitedUserRole = 100
        } else {
            Logger(`カスタムのため、モーダルを開いて設定します. mem: ${Util.output(this.invitedUser)}`)
            this.invitedUserRole = -1
            this.$vfm.open(`CustomPermissionModal`)
        }
    }

    // 招待済みのユーザー権限を変更します.
    selectUserRole(e, indexstr: string) {
        Logger(`selectUserRole: ${e}`)
        const index = +indexstr
        let user = this.users[index]
        // this.invitedUserEmail = user.email
        this.invitedUser = user
        this.action = `edit`
        if (e == `カスタム` && !this.canUseCustomPermissions) {
            this.$vfm.open(`OptionModal`)
            return
        }
        this.selectRole(e)

        if (this.invitedUserRole == -1) {
            // モーダルを開くため、ここで止めます.
            return
        }

        if (user.email == this.rm.userInfo.email) {
            if (confirm(`自分の権限を変更すると、管理権限がなく入れなくなります。`)) {
                Logger(`ok`)
                this.InviteContent.invite([this.invitedUser.email], this.invitedUserRole)
            }
        } else {
            this.InviteContent.invite([this.invitedUser.email], this.invitedUserRole)
        }
    }

    // 新規追加フィールドをリセットします。
    public resetNewField() {
        this.invitedUserEmail = ""
        this.invitedUserRole = 1
        this.invitedUser = null
        this.action = null
    }

    /**
     * 招待メールを再送します。
     */
    resendInviteMail(user) {
        if (this.sending) return
        Logger(`resend`)
        this.rm.startProgress()
        this.sending = true
        axios
            .post(`${Util.prefixUrl}/user_groups/resend`, {
                email: user.email,
                option_name: `account_settings_members`,
            })
            .then(res => {
                Logger(`list: ${Util.output(res.data.invited_user)}`)
                this.notice.message = res.data.message
                this.rm.endProgress()
                this.sending = false
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                this.rm.endProgress()
                this.sending = false
            })
        gtagClick(`管理者 ユーザー招待再送`, `${user.email}`)
    }

    hideAlertModal() {
        this.$vfm.close(`AlertModal`)
        this.willDeleteUser = null
    }

    alertDeleteUser(user) {
        this.generateAlertModalDic(`account`, user)
        this.willDeleteUser = user
        let text = this.willDeleteUser
            ? `${this.willDeleteUser.email}のアカウントを削除しますか？\n\n${
                  Util.isPresent(this.willDeleteUser.name) ? `${this.willDeleteUser.name}様がオーナーの` : ``
              }公開ページは見えなくなり、外部カレンダーの共有が解除されます。`
            : null
        this.alertModalDic.text = text
        this.$vfm.open(`AlertModal`)
    }

    unlinkGroup() {
        let ug = this.alertModalDic.item
        Logger(`${funcName()} ug:${ug?.company_name}`)

        if (!ug) return

        if (this.sending) return
        this.sending = true
        this.rm.startProgress()
        axios
            .delete(`${Util.prefixUrl}/user_groups/client_user_group/${ug.user_group_id}`)
            .then(res => {
                this.sending = false
                this.notice.message = res.data.message
                if (res.data) {
                    let ugId = res.data.client_user_group_id
                    this.ClientUserGroupsContent.unlinkedUserGroup(ugId)
                    this.$vfm.close(`AlertModal`)
                }
                this.rm.endProgress()
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                this.sending = false
                this.rm.endProgress()
            })
    }

    deleteUser() {
        if (this.alertModalDic.type == `client_group`) {
            this.unlinkGroup()
            return
        }

        let user = this.willDeleteUser
        if (!user) return

        Logger(`${funcName()} ${this.rm.userInfo.user_group_role}, user:${user.id}`)

        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `account_settings`, `account_settings_members`)) {
            this.notice.message = "削除権限がありません。"
            return
        }
        let admins = this.users.filter(u => u.role == 100)
        if (admins.length == 1 && user.role == 100) {
            this.notice.message = "最後の管理者1人のため削除できません。他のユーザーに管理者権限を渡してから削除してください。"
            return
        }
        if (this.rm.userInfo.email == user.email) {
            // 自分を削除しようとしている場合、
            if (confirm(`削除した場合、このグループに自分で参加することができなくなります。`)) {
                this.delete(user.email)
            }
        } else {
            this.delete(user.email)
        }
    }

    delete(email: string) {
        if (this.sending) return
        this.rm.startProgress()
        this.sending = true
        axios
            .delete(`${Util.prefixUrl}/user_groups/member`, {
                params: {
                    email: email,
                },
            })
            .then(res => {
                this.notice.message = res.data.message
                this.users = this.users.filter(u => u.email != email)
                this.rm.endProgress()
                this.sending = false
                this.$vfm.close(`AlertModal`)
                this.willDeleteUser = null
                gtagClick(`管理者 ユーザー削除`, `${email}`)
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                this.rm.endProgress()
                this.sending = false
            })
    }

    updateUsers(users: RoomMember[], resetField = true) {
        this.users = users
        if (resetField) this.resetNewField()
    }

    /**
     * ユーザーの役割がアドミン以外の場合、調整ページ一覧に戻ります。
     */
    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (Util.isBlank(this.rm.userInfo)) return

        this.canUseCustomPermissions = this.rm.canUseOption(`management_custom_permissions`, false)

        const uf = this.rm.userInfo
        const cate = `account_settings`

        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            // このページ.
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings/notifications")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)) {
            // セキュリティに飛ばす
            this.$router.push("/account_settings/security")
            return
        }
        this.$router.push("/")
        // location.href = "https://google.com";
    }

    // カスタム権限でのパーミッションを変更後、こちらにきます.
    didChangePermissions(user: RoomMember) {
        if (user) {
            let u = this.users.find(u => u.email == user.email)
            if (u) {
                Object.assign(u, user)
            } else {
                this.users.push(user)
            }
            gtagClick(`カスタム権限変更`, `${this.users.length}人目 ${user.email}`)
            this.resetNewField()
        }
        this.$vfm.close(`CustomPermissionModal`)
    }

    checkCustomDetail(user: RoomMember) {
        this.invitedUser = user
        this.action = `edit`
        this.$vfm.open(`CustomPermissionModal`)
    }

    clickOK() {
        gtagClick(`${this.optionEventName}`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
            this.$vfm.close(`OptionModal`)
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.optionEventName} キャンセル`, ``)
        this.$vfm.close(`OptionModal`)
    }

    selectTab(tabName) {
        this.selectedTab = tabName
    }

    showUnlinkGroupModal(ug) {
        this.generateAlertModalDic(`client_group`, ug)
        let text = `「${ug.company_name}」の招待リンクを解除しますか？\n\nこのグループの招待リンクを解除すると、このグループに招待された全てのユーザーがこのグループの調整一覧を見ることができなくなります。`
        this.alertModalDic.text = text
        this.$vfm.open(`AlertModal`)
    }
}
