
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger, funcName, sleep } from "packs/common"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import LocationTag from "packs/models/LocationTag"

@Options({
    components: { MdTextfieldTag, VueFinalModal, MdProgressBar },
})
export default class InputLocationModal extends Vue {
    @Prop()
    appo: Appointment

    @Prop()
    from: string // asview 調整カレンダー画面, schedule スケジュールビュー

    @Prop()
    isPreview: boolean

    @Ref()
    InputField

    // data
    rm = RoomManager
    loading = false
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validLocation = true

    locationName = ``
    saving = false
    notice = Notice

    openModal() {
        if (Util.isPresent(this.appo)) {
            this.locationName = this.appo.location_name
        }

        sleep(200).then(_ => {
            if (this.InputField) {
                this.InputField.focusInput(true)
            }
        })
    }

    enterLocationName() {
        this.fixLocation()
    }

    public inputLocation(e) {
        this.locationName = e
    }

    public checkLocation() {
        this.validLocation = this.locationName && this.locationName.length > 0
    }

    public closeModal() {
        this.$vfm.close(`InputLocationModal`)
    }

    // public fadeOutModal() {
    //     // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
    //     Logger(`フリーフォームを閉じます`)
    //     this.$emit(`updatedLocation`, null)
    // }

    public fixLocation() {
        Logger(`${funcName()}`)
        this.checkLocation()
        if (!this.validLocation) {
            this.notice.message = `場所情報を正しく入力してください`
            return
        }
        if (Util.isPublic()) {
            Logger(`${funcName()} isPublic:true`)
            // アップデート（リモート）せずにlocation_nameのみを更新します.
            let _appo = Appointment.copy(this.appo)
            _appo.location_name = this.locationName
            this.$emit(`updatedLocation`, _appo)
            return
        }
        // 場所情報を変更する処理.
        if (this.loading) return
        this.loading = true

        let appo: Appointment = Appointment.copy(this.appo)
        appo.location_name = this.locationName
        Appointment.update(appo, "put", this.rm.currentRoom).then(dic => {
            // this.appo = appo;
            this.loading = false
            if (dic && dic.appo) {
                // this.rm.currentAppointment = appo
                this.rm.setAppo(dic.appo)
                Logger(`場所情報を更新しました`)
                this.$emit(`updatedLocation`, dic.appo)
                this.closeModal()
                gtagClick(`フリーフォーム場所変更`, `${dic.appo.location_name}`)
            }
        })
    }
}
