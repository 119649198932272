
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, funcName } from "packs/common"
import { QuillEditor } from "@vueup/vue-quill"
import ImageResize from "@taoqf/quill-image-resize-module"
import Quill from "quill"
// import BetterTable from "quill-better-table"
// import quillTable from "quill-table-module"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AppealContent from "packs/models/AppealContent"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

Quill.register("modules/imageResize", ImageResize)
// Quill.register(quillTable.TableCell)
// Quill.register(quillTable.TableRow)
// Quill.register(quillTable.Table)
// Quill.register(quillTable.Contain)
// Quill.register("modules/table", quillTable.TableModule)
// Quill.register(
//     {
//         "modules/better-table": BetterTable,
//     },
//     true
// )

@Options({
    components: {
        TextareaTag,
        TextfieldTag,
        LblueListContent,
        SelectTag,
        QuillEditor,
    },
})
export default class LeftNavPanelAppealContent extends Vue {
    // data:

    // @Prop()
    // currentRoom: Room

    @Ref()
    QuillEditor

    toolbarOptions = {
        debug: "info",
        modules: {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image", "video"],
                    ["clean"],
                ],
                handlers: {
                    image: function () {
                        const range = this.quill.getSelection()
                        const value = prompt("画像のURLを入力してください。")
                        if (value) {
                            this.quill.insertEmbed(range.index, "image", value, "user")
                        }
                    },
                    // newtable_1_1: function () {
                    //     const range = this.quill.getSelection()
                    //     this.quill.insertEmbed(range.index, "table", { rows: 1, cols: 1 }, Quill.sources.USER)
                    // },
                    // newtable_2_2: function () {
                    //     const range = this.quill.getSelection()
                    //     this.quill.insertEmbed(range.index, "table", { rows: 2, cols: 2 }, Quill.sources.USER)
                    // },
                    // newtable_3_3: function () {
                    //     const range = this.quill.getSelection()
                    //     this.quill.insertEmbed(range.index, "table", { rows: 3, cols: 3 }, Quill.sources.USER)
                    // },
                    // append_row: function () {
                    //     const range = this.quill.getSelection()
                    //     this.quill.updateContents(range.index, {
                    //         insert: {
                    //             table: 1,
                    //         },
                    //     })
                    // },
                    // append_col: function () {
                    //     const range = this.quill.getSelection()
                    //     this.quill.updateContents(range.index, {
                    //         insert: {
                    //             table: 1,
                    //         },
                    //     })
                    // },
                },
            },
            imageResize: {
                handleStyles: {
                    backgroundColor: "black",
                    border: "none",
                    color: `white`,
                    // other camelCase styles for size display
                },
            },
            // "better-table": {
            //     operationMenu: {
            //         items: {
            //             unmergeCells: {
            //                 text: "Another unmerge cells name",
            //             },
            //         },
            //     },
            //     keyboard: {
            //         bindings: BetterTable.keyboardBindings,
            //     },
            // },
        },
        theme: "snow",
    }

    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager

    isSP = isMobile(window.navigator).phone

    appoStatus = Appointment.typeNew

    notice = Notice
    Util = Util
    UserPermissionsOption = UserPermissionsOption

    saving = false
    // showShareButton: boolean;
    // astag: AvailableScheduleTag = null

    loading = false

    mounted() {
        sleep(200).then(_ => {
            this.setDeltaContent()
            if (this.rcm.appeal_content?.content_type == `text`) {
                $(`.ql-toolbar`).addClass(`nodisp`)
            } else {
                $(`.ql-toolbar`).removeClass(`nodisp`)
            }
        })
    }

    deltaContent() {
        return this.rcm?.appeal_content?.delta_content || {}
    }

    setDeltaContent() {
        if (!this.QuillEditor) return

        let deltaContent = this.rcm.appeal_content.delta_content || {}
        if (Util.isBlank(deltaContent)) return

        Logger(`LeftNavPanelAppealContent.deltaContent:${Util.output(deltaContent)}`)

        if (typeof deltaContent === "string") {
            // JSON 文字列としてエンコードされている場合、パースする
            try {
                deltaContent = JSON.parse(deltaContent)
            } catch (e) {
                Logger(`LeftNavPanelAppealContent.deltaContent parse error`)
                deltaContent = {}
            }
        }
        try {
            let contents = this.QuillEditor.getContents()
            if (contents.length > 1) {
                this.QuillEditor.updateContents(deltaContent)
                return
            } else {
                this.QuillEditor.setContents(deltaContent)
            }
        } catch (e) {
            Logger(`LeftNavPanelAppealContent.setDeltaContents error:${Util.output(e)}`)
        }
    }

    public inputAppealContent(e) {
        Logger(`${funcName()} text:${e}`)
        let apc = { ...this.rcm.appeal_content }

        apc.content = e
        apc.presetInfo = false
        this.rcm.updateAppealContent(apc)
    }

    inputAppealDeltaContent(e) {
        Logger(`${funcName()} delta_text:${Util.output(e)}`)
        let apc = { ...this.rcm.appeal_content }

        apc.delta_content = e
        apc.presetInfo = false
        this.rcm.updateAppealContent(apc)
    }

    public inputAppealContentTitle(e) {
        let apc = { ...this.rcm.appeal_content }

        apc.title = e
        apc.presetInfo = false
        this.rcm.updateAppealContent(apc)
    }

    addLinkButton() {
        if (!this.rcm) return

        let apc = { ...this.rcm.room.appeal_content }
        if (!apc) return
        let buttonLinkText = `[公開ページを開くリンクボタン](https://waaq.jp/p/waaq/XXXXXXXXX)`
        let content = `${apc.content}\n\n${buttonLinkText}`
        apc.content = content
        this.rcm.updateAppealContent(apc)
    }

    changeContentType(type: string) {
        Logger(`${funcName()} type:${type}`)
        let apc = { ...this.rcm.appeal_content }

        apc.content_type = type
        this.rcm.updateAppealContent(apc)

        if (type == `delta`) {
            $(`.ql-toolbar`).removeClass(`nodisp`)
            // sleep(1000).then(_ => {
            //     this.setDeltaContent()
            // })
            // apc.content = { delta: { ops: [{ insert: apc.content }] } }
            // let Delta = Quill.import("delta")
            // let delta = new Delta().insert(apc.content)
            // sleep(500).then(_ => {
            //     if (this.quillEditor) {
            //         this.quillEditor.setContents(delta)
            //     }
            //     apc.content_type = type
            //     this.rcm.updateAppealContent(apc)
            // })
            // this.setDeltaContent()
            // sleep(500).then(_ => {
            //     // if (this.QuillEditor) {
            //     //     this.QuillEditor.setContents(apc.delta_content)
            //     // }
            // })
        } else {
            $(`.ql-toolbar`).addClass(`nodisp`)
            // let quill = this.quillEditor
            // // エディターの内容をテキスト形式で取得
            // let text = quill.getText()
            // // 取得したテキストを使用する（例えば、他のオブジェクトのプロパティに設定）
            // apc.content = text.trim()
        }
    }
}
