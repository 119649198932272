
import { Logger } from "packs/common"
import { Options, Prop, Vue } from "vue-property-decorator"

export default class CheckboxTag extends Vue {
    @Prop()
    value: string
    @Prop()
    name: string
    @Prop()
    checked: boolean
    @Prop()
    prefix: string
    @Prop()
    disabled: boolean

    get getTypeName() {
        return `type[${this.prefix}_${this.value}]`
    }

    get defaultChecked(): boolean {
        // Logger(`${this.name}===${this.checked}`);
        if (this.checked) {
            return true
        } else {
            return false
        }
    }

    get defaultDisabled(): boolean {
        // Logger(`${this.name}===${this.checked}`);
        if (this.disabled) {
            return true
        } else {
            return false
        }
    }

    public selectCheckbox(e) {
        // this.$emit("click", e.target.getAttribute("value"));
        const status = $(`[name="${this.getTypeName}"]`).prop("checked")
        let checked = false
        if (status) {
            checked = true
        } else {
            checked = false
        }
        let dic = { value: this.value, checked: checked }
        this.$emit("clickCheckbox", dic)
    }
}
