
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.

// モデルを読込.
import { VueFinalModal } from "vue-final-modal"

// const roomStorage = new RoomStorage();

@Options({
    components: { VueFinalModal },
})
export default class GoAvailableScheduleModal extends Vue {
    // data:
    // sharedState = PropertyStore;
    // notice = Notice;
    //  ;
    // rm = RoomManager;
    // util = Util;
    // selectedTab = `calendar`;

    isSP = isMobile(window.navigator).phone
    // users: RoomMember[] = [];

    public goAvailableSchedule() {
        gtagClick(`調整カレンダー未作成→調整カレンダー作成`, ``)
        this.$router.push("/available_schedule")
    }
}
