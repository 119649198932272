
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.

@Options({
    components: { UserIcon, AddressBookContent, VueFinalModal },
})
export default class ModalSearchBox extends Vue {
    // data:

    members: RoomMember[] = []
    loading = false
    currentMember = null

    Util = Util

    rm = RoomManager

    openModal() {
        gtagPage(`#/検索詳細モーダル`)
    }

    hide() {
        this.$vfm.close(`SearchBoxModal`)
    }

    public addAttendee(member: RoomMember) {
        Logger(`memberを追加します : ${Util.output(member)}`)
        // gtagClick(`出席者追加`, `${member.name}`)
        // if (member.email == this.rm.userInfo.email) return

        let _mem = this.members.find(m => m.email == member.email)

        if (Util.isBlank(_mem)) {
            // answer_resultsの内容を作成します.
            member = RoomMember.setAnswerResultsFromMember(member)
            Logger(`addAttendee:member: ${Util.output(member)}`)
            this.members.push(member)
        } else {
            Object.assign(_mem, member)
        }

        // if (this.members.length == 1) {
        //     this.selectSendUser(member)
        // }
        Logger(`addAttendee roommeberを更新しました: ${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        // this.editOrNewForm = ``
    }

    deleteUser(mem: RoomMember) {
        this.members = this.members.filter(m => m != mem)
    }

    clickSearch() {
        if (Util.isBlank(this.members)) {
            Notice.message = `検索するメンバーを選択してください。`
            return
        }
        gtagClick(`詳細な検索を開始`, `${this.members.map(m => m.user_id)}`)
        Logger(`検索を開始します.${Util.output(this.members)}`)
        this.$emit(`searchMembers`, this.members)
        this.hide()
    }

    clickCancel() {
        this.hide()
    }
}
