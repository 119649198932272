
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import { DateTime } from "luxon"
import { truncate } from "packs/models/TruncateUtil"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import OneMagSmallContent from "packs/pages/link/parts/available_schedule/OneMagSmallContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import ScheduleSlideContent from "packs/pages/link/ScheduleSlideContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomManager from "packs/models/RoomManager"
import VotedRoomManager from "packs/models/VotedRoomManager"
import ChukaiFetcher from "packs/models/fetcher/ChukaiFetcher"
import Notice from "packs/models/Notice"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        OneMagSmallContent,
        CheckboxTag,
        ScheduleSlideContent,
        MdTextfieldTag,
        SelectTag,
        CopyButton,
        TextfieldTag,
    },
})
export default class AstagOpenScheduleSettings extends Vue {
    rm = RoomManager
    vrm = VotedRoomManager

    // astagの確定一覧公開設定用
    openScheduleType = null // `all`, `none`
    openUuid = null // 一覧公開用のuuid
    canLinkToScheduleView = null // 提案できるボタンを表示するか
    astagId = null

    mounted() {
        let params = { id: this.vrm.openMember.id }
        ChukaiFetcher.getMembersOpenSchedulesFromChukai(params).then(res => {
            if (res) {
                let astag = res.astag

                this.openScheduleType = astag.open_schedule_type
                this.openUuid = astag.open_uuid
                this.canLinkToScheduleView = astag.can_link_to_schedule_view
                this.astagId = astag.id
            }
        })
    }

    clickCanLinkToScheduleView() {
        Logger(`${funcName()}`)
        // このメンバーに紐づくastagの一部の情報を取得してきます。

        if (this.canLinkToScheduleView) {
            this.canLinkToScheduleView = false
        } else {
            this.canLinkToScheduleView = true
        }
    }

    clickOpenScheduleType() {
        Logger(`${funcName()}`)
        if (this.openScheduleType == `all`) {
            this.openScheduleType = `none`
        } else {
            this.openScheduleType = `all`
        }
    }

    uuidUrl() {
        let namespace = this.rm.namespace
        return `${location.protocol}//${location.host}/open_schedules/${namespace}/${this.openUuid}`
    }

    save(is_refresh_uuid = false) {
        Logger(`${funcName()}`)
        let params = {
            id: this.astagId,
            open_schedule_type: this.openScheduleType,
            can_link_to_schedule_view: this.canLinkToScheduleView,
        }
        if (is_refresh_uuid) {
            params[`refresh_uuid`] = true
        }
        ChukaiFetcher.changeOpenScheduleType(params).then(res => {
            if (res) {
                Notice.message = res.message
                this.openUuid = res.astag.open_uuid
            }
        })
    }

    clickRefresh() {
        this.save(true)
    }

    public clickTextfield() {
        Logger(`${funcName()}`)
        $(`input.copyAstagUUID`).select()
    }
}
