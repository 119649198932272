
// モジュールを読込.
import { Vue, Options, Prop } from "vue-property-decorator"
import { gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class FilledScheduleModal extends Vue {
    // data
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    saving = false
    Util = Util

    public closeModal() {
        this.$emit(`closeModal`)
    }

    public openModal() {
        gtagPage(`#/埋まってしまいましたモーダル`)
    }

    public clickOK() {
        this.$emit(`clickOK`)
    }

    public clickCancel() {
        this.$emit(`clickCancel`)
    }
}
