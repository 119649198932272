
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { funcName, Logger, copyToClipboard } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import CustomPermissionModal from "packs/pages/link/settings/CustomPermissionModal.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import InviteMembersContent from "packs/pages/link/settings/InviteMembersContent.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import MemberSettingsHeader from "packs/pages/link/settings/MemberSettingsHeader.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import ChukaiFetcher from "packs/models/fetcher/ChukaiFetcher"

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        CustomPermissionModal,
        OptionModal,
        UserIcon,
        InviteMembersContent,
        AlertModal,
        MemberSettingsHeader,
        RoomsLoader,
        MdTextfieldTag,
    },
})
export default class ClientUserGroupsContent extends Vue {
    // data:

    @Ref()
    InviteContent

    notice = Notice

    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil

    isSP = isMobile(window.navigator).phone
    users: any[] = []
    loading = false

    invitedUserEmail = ""
    // 送信する / 変更するユーザー
    invitedUser: RoomMember = null
    invitedUserRole = 1
    failedMail = false // Emailが正しいか.
    sending = false
    action = `new` // new / edit
    systemUpdatedAt = Util.getSec()

    optionEventName = `カスタム権限 課金リクエスト`
    canUseCustomPermissions = false

    willDeleteUser: RoomMember = null
    ugs: any[] = null
    membersDic = {} // key: ug_id [string], value: RoomMember[]
    selectedUgId = null

    // 新規でUGを作成する場合.
    newUserGroupInfo = {
        user_group_id: null,
        company_name: ``,
        name: "",
        email: "",
        invite_connect_key: null,
    }

    // 新規でクライアントを招待する場合.
    newClientInfo = {
        user_group_id: null,
        name: "",
        email: "",
    }

    @Ref()
    header

    created() {
        ChukaiFetcher.getClientUserGroups().then(res => {
            this.ugs = res.user_groups
        })
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/account/client_user_groups")
    }

    /**
     * 招待メールを再送します。
     */
    resendInviteMail(user) {
        if (this.sending) return
        Logger(`resend`)
        this.rm.startProgress()
        this.sending = true
        axios
            .post(`${Util.prefixUrl}/user_groups/resend_client_user`, {
                email: user.email,
            })
            .then(res => {
                Logger(`list: ${Util.output(res.data.invited_user)}`)
                this.notice.message = res.data.message
                this.rm.endProgress()
                this.sending = false
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                this.rm.endProgress()
                this.sending = false
            })
    }

    hideAlertModal() {
        this.$vfm.close(`AlertModal`)
        this.willDeleteUser = null
    }

    alertDeleteUser(user) {
        this.willDeleteUser = user
        this.$vfm.open(`AlertModal`)
    }

    deleteUser() {
        let user = this.willDeleteUser
        if (!user) return

        Logger(`${funcName()} ${this.rm.userInfo.user_group_role}, user:${user.id}`)

        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `account_settings`, `account_settings_members`)) {
            this.notice.message = "削除権限がありません。"
            return
        }
        let admins = this.users.filter(u => u.role == 100)
        if (admins.length == 1 && user.role == 100) {
            this.notice.message = "最後の管理者1人のため削除できません。他のユーザーに管理者権限を渡してから削除してください。"
            return
        }
        if (this.rm.userInfo.email == user.email) {
            // 自分を削除しようとしている場合、
            if (confirm(`削除した場合、このグループに自分で参加することができなくなります。`)) {
                this.delete(user.email)
            }
        } else {
            this.delete(user.email)
        }
    }

    delete(email: string) {
        if (this.sending) return
        this.rm.startProgress()
        this.sending = true
        axios
            .delete(`${Util.prefixUrl}/user_groups/member`, {
                params: {
                    email: email,
                },
            })
            .then(res => {
                this.notice.message = res.data.message
                this.users = this.users.filter(u => u.email != email)
                this.rm.endProgress()
                this.sending = false
                this.$vfm.close(`AlertModal`)
                this.willDeleteUser = null
                gtagClick(`管理者 ユーザー削除`, `${email}`)
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                this.rm.endProgress()
                this.sending = false
            })
    }

    /**
     * ユーザーの役割がアドミン以外の場合、調整ページ一覧に戻ります。
     */
    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (Util.isBlank(this.rm.userInfo)) return

        this.canUseCustomPermissions = this.rm.canUseOption(`management_custom_permissions`, false)

        const uf = this.rm.userInfo
        const cate = `account_settings`

        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            // このページ.
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings/notifications")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)) {
            // セキュリティに飛ばす
            this.$router.push("/account_settings/security")
            return
        }
        this.$router.push("/")
        // location.href = "https://google.com";
    }

    clickOK() {
        gtagClick(`${this.optionEventName}`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
            this.$vfm.close(`OptionModal`)
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.optionEventName} キャンセル`, ``)
        this.$vfm.close(`OptionModal`)
    }

    inviteNewClient(dic) {
        Logger(`${funcName()} dic:${Util.output(dic)}`)
        if (this.loading) return
        this.loading = true

        if (dic.user_group_id) {
            // 既存のグループに招待
            ChukaiFetcher.inviteClientUser(dic).then(res => {
                this.notice.message = res.message
                this.loading = false

                if (res) {
                    let ug = res.client_user_group
                    let ugId = ug.user_group_id
                    if (this.membersDic[ugId]) {
                        this.membersDic[ugId].push(res.client_user)
                    } else {
                        this.membersDic[ugId] = [res.client_user]
                    }

                    this.selectUg(ugId)
                }
            })
        } else {
            // 新規グループを作成して招待.
            ChukaiFetcher.createNewClientUserGroup(dic).then(res => {
                this.notice.message = res.message
                this.loading = false

                if (res) {
                    let ug = res.client_user_group
                    this.ugs.push(ug)

                    let ugId = ug.user_group_id
                    if (this.membersDic[ugId]) {
                        this.membersDic[ugId].push(res.client_user)
                    } else {
                        this.membersDic[ugId] = [res.client_user]
                    }

                    this.selectUg(ugId)

                    this.newUserGroupInfo = {
                        user_group_id: null,
                        company_name: ``,
                        name: "",
                        email: "",
                        invite_connect_key: null,
                    }
                }
            })
        }
    }

    selectUg(ug_id) {
        this.selectedUgId = ug_id
        this.resetNewClientInfo()
        this.newClientInfo.user_group_id = ug_id
    }

    showClientMembers(ug) {
        Logger(`${funcName()} ug:${ug?.user_group_id}`)
        this.selectUg(ug.user_group_id)
        if (this.membersDic[ug.user_group_id]) {
            // すでに取得済み
            return
        }
        if (this.loading) return
        this.loading = true

        ChukaiFetcher.getClientUsers(ug.user_group_id).then(res => {
            Logger(`ChukaiFetcher.getClientUsers res: ${Util.output(res)}`)
            if (res) {
                this.membersDic[res.client_user_group_id] = res.client_users
            }
            this.loading = false
        })
    }

    resetNewClientInfo() {
        this.newClientInfo = {
            user_group_id: null,
            name: "",
            email: "",
        }
    }

    inputClientName(e) {
        this.newClientInfo.name = e
    }

    inputClientEmail(e) {
        this.newClientInfo.email = e
    }

    public checkEmailField(email) {
        email ||= this.invitedUserEmail
        let validate = Util.validateEmail(email)
        // Logger(`emailフィールドを検証します: ${this.member.email} ${validate}`);
        if (!validate) {
            this.failedMail = true
        } else {
            this.failedMail = false
        }
    }

    showUnlinkGroupModal(ug) {
        this.$emit(`showUnlinkGroupModal`, ug)
    }

    unlinkedUserGroup(ugId) {
        this.ugs = this.ugs.filter(ug => ug.user_group_id != ugId)
        this.selectedUgId = null
        this.membersDic[ugId] = null
    }

    copyInviteUrl(user) {
        /**
         * 勝手に入ってしまうと仲介が入る事故が発生する可能性があるので、いったん利用しない.
         */
        // Logger(`${funcName()} user:${Util.output(user)}`)
        // axios
        //     .post(`${Util.prefixUrl}/user_groups/generate_invite_link`, { user_group_id: user.user_group_id, email: user.email })
        //     .then(res => {
        //         let invite_link = res.data.invite_link
        //         copyToClipboard(invite_link)
        //         this.notice.message = `招待URLをコピーしました。`
        //     })
    }
}
