
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: {
        CalendarIcon,
        FlashNotice,
    },
})
export default class AstagLists extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    @Prop()
    canDelete: boolean

    @Prop() // quick_vote
    from: string

    @Prop()
    hideSharedAstags: boolean

    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    cm = CalendarManager
    astags: AvailableScheduleTag[] = null

    loading = false
    systemUpdatedAt = Util.getSec()

    mounted() {
        Logger(`${funcName()} from:${this.from}, canDelete:${this.canDelete}`)
        let astags = this.getAllAstags()
        if (this.from == `quick_vote`) {
            // is_only_use_optional_schedulesのもののみを利用します.
            astags = astags.filter(ast => ast.is_only_use_optional_schedules)
        }
        this.astags = astags
        // this.astags = this.rm.astags
    }

    getAllAstags() {
        if (!this.rm.astags) return []
        let astags = this.rm.getAllAstags(this.cm.astag)
        if (this.hideSharedAstags) {
            astags = astags.filter(ast => !ast.is_shared)
        }
        // return this.rm.astags.filter(a => [1, 2, 50].includes(a.type))
        return astags
    }

    public selectAstag(astag: AvailableScheduleTag) {
        Logger(`astagを選択`)
        this.rm.astag = astag
        this.cm.astag = astag
        this.selectEnd()
    }

    public deleteAstag(astag: AvailableScheduleTag) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        if (confirm(`削除した場合、元に戻せません。※作成済みの調整ページに影響はありません。`)) {
            if (astag.id == `newId`) {
                Logger(`削除したいastag: ${Util.output(astag)}`)
                this.astags = this.astags.filter(a => a.id != `newId` && a.name != astag.name)
                this.rm.endProgress()
                this.rm.astags = this.rm.astags.filter(a => a.id != `newId` && a.name != astag.name)
                this.loading = false
                return
            }
            AvailableScheduleTag.delete(astag).then(res => {
                this.rm.endProgress()
                this.loading = false
                if (res) {
                    this.astags = this.astags.filter(ast => ast.id != res.id)
                    this.systemUpdatedAt = Util.getSec()
                    // 最終astagsを保存.
                    let { astag, chukaiAstag, defaultAstag, astags } = AvailableScheduleTag.setAstags(this.astags)
                    this.rm.astag = astag
                    this.rm.defaultAstag = defaultAstag
                    this.rm.astags = this.rm.astags.filter(a => a.id != res.id)
                }
            })
        }
    }

    public changeDefault(astag: AvailableScheduleTag) {
        AvailableScheduleTag.changeDefault(astag).then(e => {
            if (e) {
                this.rm.changeDefaultAstag(astag)
                this.astags = this.getAllAstags()
            }
        })
    }

    createVotedRoom(astag: AvailableScheduleTag) {
        this.$emit(`createVotedRoom`, astag)
    }

    public clickCopy(astag: AvailableScheduleTag) {
        let _astag = { ...astag }
        _astag.id = `newId`
        _astag.name = `${_astag.name}のコピー`
        _astag.color = Util.getRandomColor()
        _astag.type = 1
        if (Util.isPresent(_astag.meeting_attendees_groups)) {
            let mags = _astag.meeting_attendees_groups
            for (let mag of mags) {
                mag.id = null
            }
        }
        this.selectAstag(_astag)
    }

    public selectEnd() {
        Logger(`選択しました。`)
        // const astag = this.astags.find(e => e.selected)
        // this.rm.astag = astag;
        // this.$vfm.close("SelectAstagsModal")
        this.$emit("selectEnd", this.rm.astag, `normal`)
    }
}
