
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import HeaderControl from "packs/utils/HeaderControl"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import DataContent from "packs/components/data/DataContent.vue"
import ModalRoomMembersLink from "packs/pages/link/modals/ModalRoomMembersLink.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import ModalSearchBox from "packs/pages/link/modals/ModalSearchBox.vue"
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import RoomCell from "packs/pages/link/parts/RoomCell.vue"
import ModalUpload from "packs/pages/link/modals/ModalUpload.vue"
import CreateFromDraftModal from "packs/pages/link/modals/CreateFromDraftModal.vue"
import GoAvailableScheduleModal from "packs/pages/link/modals/GoAvailableScheduleModal.vue"
import PublicLinkModal from "packs/pages/link/modals/PublicLinkModal.vue"
import ChooseRoomTypeModal from "packs/pages/link/parts/room_settings/ChooseRoomTypeModal.vue"
import ModalCancelAppointment from "packs/pages/link/modals/ModalCancelAppointment.vue"
import CreateFeedbackModal from "packs/pages/link/parts/room_summary/CreateFeedbackModal.vue"
import ConnectCalendarModal from "packs/pages/link/modals/ConnectCalendarModal.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AnsweredFormModal from "packs/pages/link/parts/room_summary/AnsweredFormModal.vue"
import PrivateMemoModal from "packs/pages/link/modals/PrivateMemoModal.vue"
import FixTabRoomCellsContent from "packs/pages/link/parts/room_summary/FixTabRoomCellsContent.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import ShareAuthModal from "packs/pages/link/modals/ShareAuthModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import SubscriptionManager from "packs/models/SubscriptionManager"
import Appointment from "packs/models/Appointment"
import Const from "packs/utils/Const"
import RoomTab from "packs/models/RoomTab"
import SearchedRoomsInfo from "packs/models/SearchedRoomsInfo"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarUtil from "packs/utils/CalendarUtil"
import GroupTag from "packs/models/GroupTag"
import DateTag from "packs/models/DateTag"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        RoomsLoader,
        RoomCell,
        DataContent,
        ModalRoomMembersLink,
        TextfieldTag,
        ModalSearchBox,
        AlertBadge,
        ModalUpload,
        CreateFromDraftModal,
        GoAvailableScheduleModal,
        PublicLinkModal,
        ChooseRoomTypeModal,
        ModalCancelAppointment,
        CreateFeedbackModal,
        ConnectCalendarModal,
        SelectTag,
        AnsweredFormModal,
        PrivateMemoModal,
        FixTabRoomCellsContent,
        AlertModal,
        ShareAuthModal,
    },
})
export default class Rooms extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    truncate = truncate

    // 選択しているタブ情報
    userFilter: string = null // self / all
    selectedTabName: string = null // all, favorite, active, active_in_progress, fixed, closed, unread, draft

    // 公開ページのタブ情報を記憶.
    selectedSmallCategoryTabName: string = `public`

    selectedRoom: Room = null
    modalType: string = null

    // rooms = null //絞り込む前のすべてのルーム.
    currentTabRooms: Room[] = null
    fixTabRoomsByDay: { [key: string]: Room[] } = {} // {"0619": Room[]}
    unreadTab: RoomTab = null // self / all　関わらず、自分の通知のみを受け取っているため、同じものを表示.
    draftTab: RoomTab = null // 下書き

    subm = SubscriptionManager
    Room = Room
    RoomTab = RoomTab

    isRedirect = false
    redirectParentRoomId = null

    redirectAppo = null
    members_with_links: RoomMember[] = []
    searchText = ``
    showSearchField = false
    Const = Const
    isTabGroupTag = false
    showTagText = false

    loading = false
    blockingTabs = false
    systemUpdatedAt = Util.getSec()
    showDraftTab = false
    selectSearchField = false

    SearchedRoomsInfo = SearchedRoomsInfo
    searchCategory = `pubRoomName`

    // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: "", rooms: Room[]}
    searchedDic: SearchedRoomsInfo = null
    // フォーム確認時のメンバー
    formMemDetail: RoomMember = null

    // alertModalの内容.
    alertDic = null

    // デフォルトテンプレを更新した際に、そのIDを上書きします.
    defaultTemplateDic = {}

    public created() {
        ;[this.selectedTabName, this.userFilter] = roomStorage.fetchCurrentRoomsTab()
        if (!RoomTab.DISPLAY_TAB_NAMES.includes(this.selectedTabName)) this.selectedTabName = `active_in_progress`

        this.searchedDic = roomStorage.fetchSearchedRoomsInfo()

        // パラメータで開きたいタブがある場合、優先します.
        let urlParams = FormUtil.getParams()
        if (Util.isPresent(urlParams) && Util.isPresent(urlParams.tab_name)) {
            //
            let userFilter = urlParams.user_filter
            if ([`self`, `all`].includes(userFilter)) {
                // 自分 / 全体 の指定がある場合は入れます.
                this.userFilter = userFilter
            }

            let tabName = urlParams.tab_name
            if (RoomTab.DISPLAY_TAB_NAMES.includes(tabName)) {
                // 指定のタブを開きます.
                this.selectCategoryTab(tabName)
            }
        } else if (Util.isPresent(this.searchedDic)) {
            // 検索状態を復元します.
            this.selectedTabName = `search`
            this.currentTabRooms = this.searchedDic.rooms
        }

        // 既に取得済みなので、取得不要.
        if (this.rm.roomTabs && this.rm.rooms && this.rm.rooms.length > 0) {
            this.filterUser(this.userFilter)
            // if (!this.unreadTab) this.getUnreadTabInfo()
            if (!this.draftTab) this.getDraftTabInfo()
            return
        }

        this.rm.getAvailableSchedule().then(e => {
            Logger(`subm in rooms:::: ${Util.output(this.subm)}`)
            if (this.isRedirect) {
                this.rm.getRoom(this.redirectParentRoomId).then(room => {
                    if (Util.isPresent(room)) {
                        this.goRoom(room)
                        this.resetSearchDic()
                    } else {
                        // 現在のタブを指定して、そのページを取得.
                        this.filterUser(this.userFilter)
                    }
                })
            } else {
                // 現在のタブを指定して、そのページを取得.
                this.filterUser(this.userFilter)

                // if (!this.unreadTab) this.getUnreadTabInfo()
                if (!this.draftTab) this.getDraftTabInfo()
                if (!this.rm.didConnectCal && !this.rm.confirmed_connect_cal && !Const.chukaiFreePlan(this.rm)) {
                    // 外部カレンダーモーダルを表示.
                    this.$vfm.open(`ConnectCalendarModal`)
                }
            }
        })
    }

    checkNextAction() {
        Logger(`Rooms#checkNextAction`)
        let dic = this.rm.nextActionInRoomsDic
        if (Util.isPresent(dic)) {
            if (dic.method == `searchByMember`) {
                let vals = dic.variables
                this.searchByMember(vals.mem)
            } else if (dic.method == `searchByMag`) {
                let vals = dic.variables
                this.searchByMag(vals.mag)
            }
        }
    }

    public mounted() {
        gtagPage(`#/rooms`)
        this.searchText = ``
        if (Util.isBlank(this.rm.astag)) {
            this.rm.resetCurrentInfo()
        }
        // if (this.rooms) this.updateTitleWithUnreadNum()
    }

    resetSearchDic() {
        this.searchedDic = null
        roomStorage.saveSearchedRoomsInfo(null)
    }

    get currentTabLength() {
        return (this.currentTabRooms || []).length
    }

    get showInstantRoomManagement() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_instant_room`, false)
    }

    showPublicRoomsTab() {
        if (!this.rm) return false
        if (Const.chukaiFreePlan(this.rm)) {
            return false
        }

        return true
    }

    goInstantRoomManagement() {
        this.$router.push(`instant_rooms`)
    }

    // 未読のみ別途取得.
    // public getUnreadTabInfo() {
    //     let tab: RoomTab = this.rm.roomTabs[`all`][`unread`]
    //     if (tab) {
    //         this.unreadTab = tab
    //         if (!this.unreadTab.has_next) return
    //     }
    //     this.getRooms(`all`, `unread`)
    // }

    // 下書きのみ別途取得.
    public getDraftTabInfo() {
        if (this.rm && Const.chukaiFreePlan(this.rm)) return

        let tab: RoomTab = this.rm.roomTabs[`all`][`draft`]
        if (tab) {
            this.draftTab = tab
            if (!this.draftTab.has_next) return
        }
        this.getRooms(`all`, `draft`)
    }

    @Watch("rm.roomTabs.all.unread", { deep: true })
    public updateUnreadTab() {
        this.unreadTab = this.rm.fetchRoomTab(`all`, `unread`)
        Logger(
            `RoomTabが更新されました updateUnreadTab ${this.userFilter} / ${this.selectedTabName}: ${
                this.unreadTab.rooms ? this.unreadTab.rooms.length : `-`
            }`
        )
    }

    @Watch("rm.roomTabs", { deep: true })
    public updateCurrentRoomTab() {
        Logger(`Rooms.vue#updateCurrentRoomTab フェッチします。 ${this.userFilter} ${this.selectedTabName}`)
        let tab = this.rm.fetchRoomTab(this.userFilter, this.selectedTabName)

        Logger(`Rooms.vue#updateCurrentRoomTab tabを表示: ${Util.output(tab)}`)

        // let tab = this.rm.roomTabs[this.userFilter][this.selectedTabName] as RoomTab
        if (!tab || !tab.rooms) return

        // タブの表示ルールに従って並び替えます。
        this.currentTabRooms = RoomTab.sortRoomsWithTabName(tab.rooms, tab.tab_name)
        this.systemUpdatedAt = Util.getSec()
    }

    // @Watch(`currentTabRooms`)
    // public generateFixTabRooms() {
    //     Logger(`${funcName()} FixTab用の日付まとめを作成`)
    //     if (Util.isBlank(this.currentTabRooms)) return
    //     if (this.selectedTabName != `upcoming_fixed`) return

    //     let dic = {}
    //     for (let room of this.currentTabRooms) {
    //         let appo = room.current_appointment
    //         let dateId = CalendarUtil.toJpFormatDate(appo.start_time)
    //         let rooms = dic[dateId] || []
    //         rooms.push(room)
    //         dic[dateId] = rooms
    //     }
    //     this.fixTabRoomsByDay = dic
    // }

    public getRooms(uf: string, tabname: string) {
        Logger(`${funcName()} uf:${uf}, tabname:${tabname}`)
        this.startProgress()
        this.rm.getRooms(uf, tabname).then(val => {
            this.blockingTabs = false
            this.endProgress()
            if (!val) return

            let rooms = val.rooms as Room[]
            const tab = val.tab as RoomTab
            Logger(
                `はめます: rooms.length:${rooms.length}, tab.tab_name:${tab.tab_name}, selectedTabName:${this.selectedTabName}`
            )

            let type = uf
            if ([`unread`, `favorite`].includes(this.selectedTabName)) type = `all`
            if (type == tab.user_filter && this.selectedTabName == tab.tab_name) {
                // タブの表示ルールに従って並び替えます。
                rooms = RoomTab.sortRoomsWithTabName(rooms, tab.tab_name)

                this.currentTabRooms = rooms
            }

            // if (tab.tab_name == `unread`) {
            //     this.unreadTab = tab

            //     const title = HeaderControl.updateHeaderTitleWithUnreadNum(rooms)
            //     this.$router.beforeEach((to, from, next) => {
            //         document.title = title
            //         next()
            //     })
            // }

            if (tab.tab_name == `draft`) {
                this.draftTab = tab
                if ((tab.rooms || []).length > 0) {
                    this.showDraftTab = true
                }
            }
        })
    }

    startProgress() {
        this.rm.startProgress()
    }

    endProgress() {
        this.rm.endProgress()
    }

    getMyTabRooms(grTag: GroupTag) {
        //
        Logger(`${funcName()} grTag:${grTag.id}, grTag.name:${grTag.name}`)
        this.startProgress()

        this.rm.getRooms(`all`, grTag.id).then(val => {
            this.blockingTabs = false
            this.endProgress()
            if (!val) return

            let rooms = val.rooms as Room[]
            const tab = val.tab as RoomTab
            Logger(
                `はめます: rooms.length:${rooms.length}, tab.tab_name:${tab.tab_name}, selectedTabName:${this.selectedTabName}`
            )

            let type = `all`
            if (type == tab.user_filter && tab.groupTag && this.selectedTabName == tab.groupTag.id) {
                // タブの表示ルールに従って並び替えます。
                // rooms = RoomTab.sortRoomsWithTabName(rooms, tab.tab_name)

                this.currentTabRooms = rooms
            }
        })
    }

    /**
     * 自分/全体の切り替えを受け取ります。
     */
    public filterUser(type: string) {
        Logger(`Rooms:filterUser selectedTabName:${this.selectedTabName}`)
        // 自分のみしか表示されない権限のユーザーは切り替えできません.
        if (!this.showAllContent()) type = `self`

        this.userFilter = type
        this.searchText = ``

        if (Util.isPresent(this.rm.nextActionInRoomsDic)) {
            this.checkNextAction()
            return
        }

        if (Const.chukaiFreePlan(this.rm)) {
            if (!RoomTab.PARENT_ROOM_TAB_NAMES.includes(this.selectedTabName)) {
                this.selectedTabName = `upcoming_fixed`
            }
        }

        this.selectCategoryTab(this.selectedTabName)
    }

    public updateDataFromRails(data) {
        window.history.pushState({}, document.title, "/link#/")
        Logger(`railsから受信しました: ${Util.output(data)}`)
        if (!data) return
        if (data.parent && data.rid) {
            let type = data.type
            if (type == `fix`) {
                // 一発確定するためのアポを保存しておきます。
                let appo = Appointment.createDefault()
                appo.id = `fix`
                appo.start_time = data.start_time
                this.redirectAppo = appo
            }
            const parentRoomId = data.parent
            if (parentRoomId && parentRoomId.length > 0) {
                this.isRedirect = true
                this.redirectParentRoomId = parentRoomId
            }
        }
    }

    resetStorage() {
        Logger(`resetStorage::Rooms`)
        roomStorage.save(null)
        roomStorage.saveAction(null)
        roomStorage.saveAppointment(null)
        roomStorage.deleteRoomInfo()
    }

    public goSummary(room: Room) {
        this.rm.updateCurrentInfo(room)
        if (!room.is_owner_group) return
        roomStorage.save(room)
        roomStorage.saveAppointment(room.current_appointment)
        roomStorage.saveSelectedRoomKey(room.keyId)
        if (room.room_type == `public_room`) {
            this.$router.push("/pub_summary")
        } else {
            this.$router.push("/summary")
        }
    }

    public showMembersLinks(members, room) {
        this.members_with_links = []
        this.selectedRoom = null
        Logger(`${funcName()} members:${members?.map(m => m.name)}, room:${room?.title}`)
        sleep(200).then(_ => {
            this.members_with_links = members
            this.selectedRoom = room
            this.$vfm.open("RoomMembersLinkModal")
        })
    }

    public showPublicLink(room: Room) {
        this.selectedRoom = room
        this.$vfm.open("PublicLinkModal")
    }

    public goRoomSettings() {
        Logger(`goRoomSettings`)
        this.$router.push("/room_settings")
    }

    public createGroup() {
        Logger(`createGroup`)
        this.rm.resetCurrentInfo()
        if (this.rm.astag.id != `newId`) {
            gtagClick("調整ページ作成", `一覧 → 新規作成`)

            this.resetStorage()
            this.$vfm.open(`ChooseRoomTypeModal`)
        } else {
            // 先にastagを作成
            gtagClick("調整ページ作成 Astag未作成", `一覧 → AstagView`)
            // this.notice.message = `調整ページを作成するためには、調整カレンダーの作成を先にしてください。`
            // this.$router.push("/available_schedule")
            this.$vfm.open("GoAvailableScheduleModal")
            // roomStorage.saveAction("new")
            // this.goRoomSettings()
        }
    }

    /**
     * セルをクリックしたら調整ページに遷移.
     */
    public goRoomFromTr(room: Room) {
        Logger("ごールーム")
        if (room.room_type == `public_room`) {
            gtagClick(`公開ページへ`, `[${room.appeal_content ? room.appeal_content.title : ``}] pubid:${room.id}`)
            open(`${room.page_url}`, `_blank`)
        } else {
            gtagClick(`限定公開ページへ`, `${room.members.length}人 [${room.title}] rid:${room.room_id}`)
            this.goRoom(room)
        }

        // PCの場合はサマリーに遷移.
        // this.goSummary(room);
    }

    public clickSummary(room: Room, tabName: string = null) {
        Logger(`ごーサマリー ${tabName}`)
        gtagClick("セルから進捗確認", `進捗管理 ${room.members.length}人 ${room.room_type} rid:${room.id} ${tabName}`)
        if (room.room_type == `public_room`) {
            if (Util.isPresent(tabName)) {
                roomStorage.saveSummaryTab(tabName)
            } else {
                roomStorage.saveSummaryTab(`pv`)
            }
        }

        this.goSummary(room)
    }

    // メッセージページに遷移.
    public goRoom(room) {
        this.rm.updateCurrentInfo(room)
        roomStorage.saveSelectedRoomKey(room.keyId)
        roomStorage.saveSelectedParentRoomId(room.id)
        // fix用のアポがある場合保存しておきます。
        if (this.redirectAppo) {
            let appo: Appointment = { ...this.rm.currentAppointment }
            if (appo.status != 10) {
                appo.start_time = this.redirectAppo.start_time
                appo.localId = `fix`
            }
            this.rm.currentAppointment = appo
            this.rm.currentRoom.current_appointment = appo
            // roomStorage.saveAppointment(this.redirectAppo);
        }
        // this.$router.push("/messages");
        this.$router.push("/schedule")
    }

    // 調整ページ表示切り替え
    public selectCategoryTab(selectedClass: string) {
        Logger(`${funcName()} selectedClass:${selectedClass}`)
        if (selectedClass == `search`) return
        if (this.userFilter == `all` && selectedClass == `shared`) {
            selectedClass = `public`
            this.selectedSmallCategoryTabName = `public`
        }
        if (selectedClass == `public`) {
            if (this.selectedSmallCategoryTabName == `shared`) {
                this.selectSmallCategory(`sharedPublicPage`)
                return
            }
            // this.selectedSmallCategoryTabName = `public`
        }

        gtagClick(`一覧タブ切り替え ${this.userFilter} ${selectedClass}`, ``)
        // 取得中の切り替え防止.
        if (this.blockingTabs) return
        this.blockingTabs = true

        this.currentTabRooms = null
        this.isTabGroupTag = false
        this.selectedTabName = selectedClass
        this.resetSearchDic()
        // this.selectedTabNameStatus = this.roomStatusesHash[selectedClass]
        // Logger(`rooms: ${Util.output(this.rooms)}`)
        const typeString = this.userFilter == "self" ? "自分" : "全体"
        let type = this.userFilter
        if ([`unread`, `favorite`].includes(this.selectedTabName)) type = `all`

        if (!this.rm.roomTabs) {
            this.rm.roomTabs = RoomTab.createAllTabs(this.rm.tabGroupTags)
        }

        let currentTab = this.rm.roomTabs[type][this.selectedTabName] as RoomTab
        if (currentTab.total == -1) {
            // 取得してきます。
            Logger(`${funcName()} 未取得のため取得してきます。`)
            this.getRooms(type, this.selectedTabName)
        } else {
            Logger(`${funcName()} 取得済みのためcurrentTabRoomsにはめます.: ${currentTab.rooms}`)
            // this.rooms = currentTab.rooms
            let rooms = RoomTab.sortRoomsWithTabName(currentTab.rooms, this.selectedTabName)
            this.currentTabRooms = rooms
            this.blockingTabs = false
        }

        // this.updateCurrentTabRooms()
        gtagClick(`一覧タブ切り替え ${typeString} ${this.selectedTabName}`, `表示:${(this.currentTabRooms || []).length}`)
        roomStorage.saveCurrentRoomsTab(selectedClass, this.userFilter)
    }

    selectTabGroupTag(grTag: GroupTag) {
        Logger(`${funcName()} grTag:${grTag.name}`)
        gtagClick(`一覧タブ切り替え ${this.userFilter} TabGroupTag`, `${grTag.name}`)
        // 取得中の切り替え防止.
        if (this.blockingTabs) return
        this.blockingTabs = true

        this.currentTabRooms = null
        this.selectedTabName = grTag.id
        this.resetSearchDic()
        this.isTabGroupTag = true

        let currentTab = this.rm.roomTabs[`all`][this.selectedTabName] as RoomTab
        if (!currentTab) {
            this.rm.roomTabs = RoomTab.mergeGroupTags(this.rm.roomTabs, this.rm.tabGroupTags)
            currentTab = this.rm.roomTabs[`all`][this.selectedTabName] as RoomTab
        }

        if (currentTab.total == -1) {
            // 取得してきます。
            Logger(`${funcName()} 未取得のため取得してきます。`)
            this.startProgress()
            this.getMyTabRooms(grTag)
        } else {
            Logger(`${funcName()} 取得済みのためcurrentTabRoomsにはめます.: ${currentTab.rooms}`)
            // this.rooms = currentTab.rooms
            // let rooms = RoomTab.sortRoomsWithTabName(currentTab.rooms, this.selectedTabName)
            let rooms = currentTab.rooms
            this.currentTabRooms = rooms
            this.blockingTabs = false
        }
    }

    selectSmallCategory(tabname: string) {
        Logger(`${funcName()} tabname:${tabname}`)
        // 取得中の切り替え防止.
        // if (this.blockingTabs) return
        // this.blockingTabs = true

        this.currentTabRooms = null

        if (tabname == `sharedPublicPage`) {
            this.selectedTabName = `shared`
            this.selectedSmallCategoryTabName = `shared`
            let currentTab = this.rm.roomTabs[`self`][`shared`] as RoomTab
            if (currentTab.total == -1) {
                // 取得してきます。
                Logger(`${funcName()} 未取得のため取得してきます。`)
                // 共有されている公開ページを取得します.
                this.getRooms(`self`, `shared`)
            } else {
                Logger(`${funcName()} 取得済みのためcurrentTabRoomsにはめます: ${currentTab.rooms}`)
                this.currentTabRooms = currentTab.rooms
            }
        } else {
            // this.blockingTabs = false
            this.selectedSmallCategoryTabName = `public`
            this.selectCategoryTab(`public`)
        }
    }

    /**
     * スクロールを検知して、下までいったらルーム情報を取得します。
     */
    public scroll() {
        Logger(`${funcName()}`)
        if (Util.isPubSummary()) {
            return
        }
        // $(".roomTableContent").get(0).scrollHeight
        // Logger(`scroll: ${$(".roomTableContent").outerHeight()} ${window.innerHeight + window.scrollY}`)
        if ($(".roomTableContent").outerHeight() < window.innerHeight + window.scrollY) {
            Logger(`${funcName()} 下端までスクロール uf:${this.userFilter} this.selectedTabName:${this.selectedTabName}`)
            let currentTab = this.rm.roomTabs[this.userFilter][this.selectedTabName] as RoomTab
            if (Util.isBlank(currentTab)) {
                if (this.isTabGroupTag) {
                    currentTab = this.rm.roomTabs[`all`][this.selectedTabName] as RoomTab

                    if (Util.isBlank(currentTab)) return
                } else {
                    return
                }
            }

            if (currentTab.has_next) {
                if (this.blockingTabs) return
                // 取得中の切り替え防止.
                this.blockingTabs = true

                if (this.isTabGroupTag) {
                    this.getMyTabRooms(currentTab.groupTag)
                } else {
                    this.getRooms(this.userFilter, this.selectedTabName)
                }
            } else {
                Logger(`これ以上取得するものはありません.`)
            }
            // this.selectCategoryTab(this.selectedTabName)
        }
    }

    /**
     * 検索した結果を表示させます。
     */
    public clickSearch(text) {
        this.inputSearchText(text)
        this.startSearch()
        this.$vfm.close("SearchBoxModal")
    }

    /**
     * スマホで検索窓のモーダルを開きます。
     */
    public clickSpSearchBox() {
        this.$vfm.open("SearchBoxModal")
    }

    public clickSearchIcon() {
        // this.searchText = ``
        // if (this.searchText && this.searchText.length > 0) {
        //     this.startSearch()
        // }

        this.searchedDic = null
        this.showSearchField = true
        sleep(500).then(_ => {
            $("input.searchField").eq(0).focus()
        })
    }

    public startSearch() {
        Logger(`検索を開始します。 ${this.searchText}`)
        let searchText = this.searchText.trim()
        let email = null
        let publicIds = []
        let pubRoomName = null
        if (this.searchCategory == `email`) {
            email = searchText
        } else if (this.searchCategory == `roomId`) {
            publicIds = [searchText]
        } else if (this.searchCategory == `pubRoomName`) {
            pubRoomName = searchText
        }

        if (this.blockingTabs) return
        this.blockingTabs = true
        this.startProgress()
        Room.search(publicIds, this.rm.userInfo.user_id, null, email, null, pubRoomName, this.searchCategory).then(rooms => {
            if (rooms) {
                Logger(`検索して取得してきました。${rooms.length}`)
                this.selectedTabName = `search`

                this.searchedDic = SearchedRoomsInfo.createSearchInfo(this.searchCategory, rooms, this.searchText)
                roomStorage.saveSearchedRoomsInfo(this.searchedDic)
                // // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: ""}
                this.currentTabRooms = rooms
            }
            this.endProgress()
            this.blockingTabs = false
        })
    }

    /**
     * 検索をした場合に「すべて」タブに変更して、以下の内容から部分一致するものを取得してきます。
     * アポ名/publicID/出席者名/出席者の会社名/メールアドレス
     */
    public inputSearchText(text) {
        this.searchText = text
    }

    public openUploadModal() {
        if (Const.paidOverBussiness(this.rm)) {
            this.$vfm.open("UploadModal")
        } else {
            // ビジネスプラン以上でご利用いただけます。
            this.$vfm.open("AdPromBusinessModal")
        }
    }

    public uploadedFile() {
        // this.repos.push(repo);
        // this.sort(this.currentSortDic);
        this.$vfm.close("UploadModal")
    }

    /**
     * 調整ページを作成/削除/一括作成/一括削除するためのモーダルを表示します。
     * room [Room] コントロール対象のルーム.
     * type [string] create, delete, createAll, deleteAll
     */
    public showRoomModal(room: Room, type: string) {
        this.selectedRoom = room
        this.modalType = type
        this.$vfm.open("CreateFromDraftModal")
    }

    /**
     * 直接削除しようとした場合に、先に停止中に変更してくださいモーダルを表示します.
     */
    public showInstructionDeleteModal() {
        Logger(`${funcName()}`)
        this.alertDic = null
        // ドメインを削除するか確認モーダルを表示します.
        this.alertDic = {
            title: `停止中に変更してください`,
            text: `間違って削除しないよう、先に「停止中」に変更した後で削除できるようになります。`,
            buttonName: `確認`,
            type: `clickDeleteRoom`,
            imageLargeUrl: `/assets/tutorial/change_to_suspend.png`,
            height: 360,
        }
        this.$nextTick(() => {
            this.$vfm.open("AlertModal")
        })
    }

    hideAlertModal() {
        this.$vfm.close("AlertModal")
        this.alertDic = null
    }

    /**
     * 下書きモーダルを閉じる際に、成功していたら、現在のタブにその情報をはめます。
     */
    public closeDraftModal(success: boolean, room: Room) {
        if (!success) return
        Logger(`closeDraftModal: type: ${this.modalType} ${Util.output(room)}`)

        if (this.modalType == `create`) {
            // currentTabに登録
            this.rm.setNewRoom(room)
        } else if (this.modalType == `createAll`) {
            // すべて非表示.
            this.deleteFromDraftTab(true)
            this.systemUpdatedAt = Util.getSec()

            // this.draftTab.rooms = this.draftTab.rooms.filter(r => !r.is_valid)
        } else if (this.modalType == `delete`) {
            if (this.selectedRoom.room_type == `public_room`) {
                this.rm.deleteFromPublicTab(room)
            } else if (Room.isParentDraft(this.selectedRoom.status)) {
                // 下書き削除.
                // this.rm.deleteFromDraftTab(room)
                let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => r.id != room.id)
                let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => r.id != room.id)
            } else {
                // currentTabの当該ルームを終了に変更.
            }
            this.systemUpdatedAt = Util.getSec()
        } else if (this.modalType == `deleteAll`) {
            //すべて非表示
            this.deleteFromDraftTab(false)
        }
    }

    deleteFromDraftTab(onlyValid: boolean) {
        if (!onlyValid) {
            let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
            tab.rooms = []
            let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
            alltab.rooms = []
        } else {
            if (this.userFilter == `self`) {
                let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => !r.is_valid)
                let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => r.owner.user_id != this.rm.userInfo.user_id)
            } else {
                let tab = this.rm.roomTabs[`self`][`draft`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => !r.is_valid)
                let alltab = this.rm.roomTabs[`all`][`draft`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => !r.is_valid)
            }
        }
    }

    createAllDrafts() {
        this.showRoomModal(null, `createAll`)
    }

    deleteAllDrafts() {
        this.showRoomModal(null, `deleteAll`)
    }

    cancelAppo(room: Room) {
        this.selectedRoom = room
        this.rm.updateCurrentInfo(room)
        this.$vfm.open(`CancelAppointmentModal`)
    }

    canceledRoom(_appo: Appointment) {
        if (!this.rm.currentRoom || Util.isBlank(_appo)) return
    }

    rescheduledRoom(appo: Appointment) {
        this.canceledRoom(appo)
    }

    cancelVoted(room: Room) {
        this.selectedRoom = room
        this.$vfm.open(`CancelAppointmentModal`)
    }

    changeActive(room: Room) {
        this.startProgress()
        Room.changeStatus(room, `reopen`).then(success => {
            this.endProgress()
            // 終了タブから消して、別タブに移行.
            if (success) {
                room.is_expired = false
                room.status = room.status - 100
                room.status_name = room.status_name.replace(`(終了)`, ``)
                room.expired_at = -1

                // クローズタブから削除
                let tab = this.rm.roomTabs[`self`][`closed`] as RoomTab
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => r.room_id != room.room_id)
                let alltab = this.rm.roomTabs[`all`][`closed`] as RoomTab
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => r.room_id != room.room_id)

                // TODO:確定している場合は確定タブへ.

                // TODO:調整中の場合は調整中タブへ.
            }
        })
    }

    /**
     * 投票状況の更新があった場合に呼びます.
     */
    public updatedVotedRooms(success: boolean, dic: any) {
        this.$vfm.close(`CancelAppointmentModal`)
        if (success) {
            let _appo = dic.appo as Appointment
            let room_ids: string[] = dic.room_ids
            let fixedRooms = [...(this.selectedRoom.rooms_fixed || [])]
            for (let _room of fixedRooms) {
                if (room_ids.includes(_room.id)) {
                    _room.current_appointment = _appo

                    if (_appo.status == 10) {
                        _room.status = Room.STATUS_ACTIVE_IN_PROGRESS
                        _room.status_name = `調整済`
                        _room.is_voted = true
                        _room.updatedAtText = DateTag.getTimeStringSlashFromSeconds(DateTime.local().toSeconds())
                    } else {
                        _room.is_voted = false
                    }
                }
            }
            let fixed = fixedRooms.filter(_r => _r.current_appointment.status == 10)
            let notFixed = fixedRooms.filter(_r => ![10, -1].includes(_r.current_appointment.status))
            this.selectedRoom.rooms_fixed = fixed
            Array.prototype.push.apply(this.selectedRoom.rooms_voted, notFixed)
        }
    }

    public createFeedback(room: Room) {
        this.selectedRoom = null
        let templateId = this.defaultTemplateDic[room.id]
        if (Util.isPresent(templateId)) {
            room.default_message_template_id = templateId
        }
        this.selectedRoom = room
        Logger(`${funcName()} room:${room?.id}`)

        sleep(500).then(_ => {
            this.$vfm.open(`CreateFeedbackModal`)
        })
    }

    // 新規調整ボタンを表示するか.

    showNewButton() {
        if (Const.suspend(this.rm)) return false
        if (Const.chukaiFreePlan(this.rm)) return false

        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_create_room`)
    }

    showAllContent() {
        if (Util.isBlank(this.rm.userInfo)) return
        if (UserPermissionsOption.permitDisplay(this.rm.userInfo, `rooms`, `rooms_all_tab`)) {
            return true
        } else {
            return false
        }
    }

    searchByMembers(mems: RoomMember[]) {
        Logger(`Rooms#searchByMembers ${Util.output(mems)}`)
        let userIds = mems.map(m => m.user_id)
        let category = `member`

        if (this.blockingTabs) return
        this.blockingTabs = true
        this.startProgress()
        this.currentTabRooms = null

        Room.search(null, this.rm.userInfo.user_id, userIds, null, null, null, category).then(rooms => {
            this.endProgress()
            this.blockingTabs = false
            if (rooms) {
                Logger(`検索して取得してきました。${rooms.length}`)
                let memsText = mems.map(m => m.name).join(", ")
                this.searchedDic = SearchedRoomsInfo.createSearchInfo(category, rooms, memsText)
                roomStorage.saveSearchedRoomsInfo(this.searchedDic)
                // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: ""}
                this.selectedTabName = `search`
                this.currentTabRooms = rooms
                this.rm.nextActionInRoomsDic = null
            }
        })
    }

    searchByMember(mem: RoomMember) {
        Logger(`Rooms#searchByMember ${mem.email}`)
        this.searchByMembers([mem])
    }

    searchByMag(mag: MeetingAttendeesGroup) {
        Logger(`Rooms#searchByMag ${Util.output(mag)}`)
        let category = `mag`

        if (this.blockingTabs) return
        this.blockingTabs = true
        this.startProgress()
        Room.search(null, this.rm.userInfo.user_id, null, null, mag.id, null, category).then(rooms => {
            this.endProgress()
            this.blockingTabs = false
            if (rooms) {
                Logger(`検索して取得してきました。${rooms.length}`)
                this.searchedDic = SearchedRoomsInfo.createSearchInfo(category, rooms, mag.team_name || mag.name)
                // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: ""}
                this.selectedTabName = `search`
                this.currentTabRooms = rooms
                this.rm.nextActionInRoomsDic = null
            }
        })
    }

    clickDetailSearch() {
        Logger(`clickDetailSearch`)
        this.$vfm.open("SearchBoxModal")
    }

    clickSearchField() {
        this.selectSearchField = true
    }

    blurSearchField() {
        this.selectSearchField = false
    }

    selectSearchType(type: string) {
        Logger(`${funcName()} type:${type}`)
        Object.keys(SearchedRoomsInfo.categoryDic).forEach(_key => {
            let val: string = SearchedRoomsInfo.categoryDic[_key]
            if (type == val) {
                this.searchCategory = _key
            }
        })
    }

    clickFormMember(mem: RoomMember, room: Room) {
        Logger(`${funcName()} mem.id:${mem?.id}, room.id:${room?.id}`)
        this.formMemDetail = mem
        if (room) {
            this.selectedRoom = room
        } else {
            this.selectedRoom = null
        }

        this.$nextTick(() => {
            this.$vfm.open(`AnsweredFormModal`)
        })
    }

    editPrivateMemo(room: Room) {
        Logger(`${funcName()} `)
        this.selectedRoom = null
        this.selectedRoom = room
        sleep(500).then(_ => {
            this.$vfm.open(`PrivateMemoModal`)
        })
    }

    updatedPrivateMemo(memo, groupTags) {
        let room = this.currentTabRooms.find(r => r.id == this.selectedRoom.id)
        if (room) {
            Logger(`${funcName()} memo:${memo}`)
            room.private_memo = memo
        }
    }

    showShareAuthModal(room: Room) {
        this.selectedRoom = room
        this.$vfm.open(`ShareAuthModal`)
    }

    hideShareAuthModal() {
        this.selectedRoom = null
        this.$vfm.close(`ShareAuthModal`)
    }

    addTabGroupTags() {
        Logger(`${funcName()}`)
        this.$router.push("/settings/rooms/group_tags")
    }

    hoverAddTag() {
        this.showTagText = true
    }

    blurAddTag() {
        this.showTagText = false
    }

    // デフォルトのメッセージテンプレートを変更したことを覚えておきます.（リフレッシュされれば再取得されるため、それまで利用.）
    updateSelectedRoom(room: Room) {
        Logger(`${funcName()}`)
        this.defaultTemplateDic[room.id] = room.default_message_template_id
    }

    updateMember(mem: RoomMember) {
        Logger(`${funcName()}`)
        ;(this.currentTabRooms || []).forEach(r => {
            if (r.id == mem.parent_room_id) {
                let _mem = r.members.find(m => m.id == mem.id)
                if (_mem) {
                    Object.assign(_mem, mem)
                }
            }
        })
    }
}
