
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique } from "packs/common"
import { gtagPage } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import ImageBoardModal from "packs/pages/link/modals/ImageBoardModal.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import UserSettingsAddressBook from "packs/pages/link/settings/UserSettingsAddressBook.vue"
import TemplatesContent from "packs/pages/link/parts/room_settings/TemplatesContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import UserFile from "packs/models/UserFile"
import UserSetting from "packs/models/UserSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        TextfieldTag,
        UserSettingsHeader,
        ImageBoardModal,
        MdTextfieldTag,
        TextareaTag,
        UserSettingsAddressBook,
        TemplatesContent,
    },
})
export default class UserSettingsMail extends Vue {
    // data:
    @Prop()
    tab: string

    notice = Notice

    rm = RoomManager
    selectedTab = `mail`

    isSP = isMobile(window.navigator).phone
    UserPermissionsOption = UserPermissionsOption

    Const = Const
    SelectUtil = SelectUtil

    saving = false

    us: UserSetting = null

    logoUrl: string = null
    selectedCategory = `mail`
    smallTabNames = [`mail`, `addressbook`, `templates`]

    demoMem = RoomMember.createForPublicRoom()

    @Ref()
    header

    created() {
        let tabName = this.tab || `mail`

        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkHasAstag()
                this.logoUrl = this.rm.logo_url
                this.us = this.rm.user_setting
            })
        } else {
            this.checkHasAstag()
            this.logoUrl = this.rm.logo_url
            this.us = this.rm.user_setting
        }

        if (Util.isPresent(tabName)) {
            if (this.smallTabNames.includes(tabName)) {
                this.selectCategory(tabName)
            }
        }
    }

    selectCategory(name: string) {
        this.selectedCategory = name
    }

    mounted() {
        gtagPage("#/settings/mail")
        this.checkHasAstag()
    }

    public checkHasAstag() {
        this.rm.redirectTopIfChukaiFreePlan(`UserSettingsInfo1`)
    }

    public changeLogo() {
        Logger(`imageBoardオープン`)
        if (this.rm.userInfo.user_group_role == 1) {
            Notice.message = `ロゴ変更できるのは、管理者権限をもつユーザーのみです。管理者に変更依頼してください。`
            return
        }
        this.$vfm.open(`ImageBoardModal`)
    }

    public selectFile(file: UserFile) {
        let size = file.file_size_byte
        Logger(`size: ${size}`)
        if (size > 100_000) {
            Notice.message = `ロゴに設定できる画像サイズは、100KBまでです`
            return
        }
        if (this.saving) return
        this.saving = true
        this.rm.startProgress()
        Logger(`ロゴを選択しました ${Util.output(file)}`)

        UserFile.saveLogoUrl(file.image_url).then(res => {
            this.saving = false
            this.rm.endProgress()
            this.notice.message = `ロゴを変更しました`
            if (res && res.logo_url) {
                this.rm.logo_url = res.logo_url
                this.logoUrl = res.logo_url
            }

            this.$vfm.close(`ImageBoardModal`)
        })
    }

    inputPrefixSubject(e) {
        this.us.prefix_subject = e
    }

    savePrefixSubject() {
        // if (Util.isBlank(this.us.prefix_subject)) {
        //     this.notice.message = `入力されていません。`
        //     return
        // }
        this.updateUserSetting(`prefix_subject`)
    }

    defaultMailDisplayType() {
        if (!this.us) return `リッチ`
        let dic = SelectUtil.mailDisplayTypes.find(m => m.type == this.us.mail_display_type)
        if (dic) return dic.name
        return `リッチ`
    }

    get defaultChatContentDisplayType() {
        if (!this.us) return `非表示`
        let dic = SelectUtil.commonDisplayTypes.find(m => m.type == this.us.chat_notification_display_type)
        if (dic) return dic.name
        return `非表示`
    }

    selectDisplayType(e) {
        let dic = SelectUtil.mailDisplayTypes.find(m => m.name == e)
        if (!dic) return
        this.us.mail_display_type = dic.type

        this.updateUserSetting(`mail_display_type`)
    }

    selectChatNotificationDisplayType(e) {
        let dic = SelectUtil.commonDisplayTypes.find(m => m.name == e)
        if (!dic) return
        this.us.chat_notification_display_type = dic.type

        this.updateUserSetting(`chat_notification_display_type`)
    }

    updateUserSetting(endpoint: string) {
        if (this.saving) return
        this.saving = true
        this.rm.startProgress()

        UserSetting.update(this.us, endpoint).then(res => {
            this.saving = false
            this.rm.endProgress()

            if (res) {
                this.notice.message = res.mes
                this.us = res.us
                this.rm.user_setting = this.us
            }
        })
    }

    getSignature() {
        if (!this.us) return null
        return this.us.signature_text
    }

    inputSignature(e) {
        this.us.signature_text = e
    }

    saveSignature() {
        this.updateUserSetting(`signature_text`)
    }
}
