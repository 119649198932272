
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, onlyUnique, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import DailyLine from "packs/pages/link/parts/available_schedule/DailyLine.vue"
import CalendarEventModal from "packs/components/calendar/CalendarEventModal.vue"
import WeeklyDetailHeader from "packs/pages/link/parts/available_schedule/WeeklyDetailHeader.vue"
import Notice from "packs/models/Notice"
import CalendarTagDetailModal from "packs/pages/link/parts/available_schedule/CalendarTagDetailModal.vue"
import CalendarEventDetailModal from "packs/pages/link/parts/available_schedule/CalendarEventDetailModal.vue"
import IconRefresh from "packs/components/icons/IconRefresh.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import ChangeMaxBookingsNumModal from "packs/pages/link/parts/room_settings/room_settings_parts/ChangeMaxBookingsNumModal.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import Event from "packs/models/Event"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import CalendarTag from "packs/models/CalendarTag"
import _RoomCreateManager from "packs/models/RoomCreateManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        DailyLine,
        CalendarEventModal,
        WeeklyDetailHeader,
        FlashNotice,
        CalendarTagDetailModal,
        CalendarEventDetailModal,
        IconRefresh,
        AlertModal,
        ChangeMaxBookingsNumModal,
    },
})
export default class WeeklyDetailSchedule extends Vue {
    // data

    rm = RoomManager
    cm = CalendarManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    Const = Const
    Util = Util
    SelectUtil = SelectUtil
    loading = false

    hours = Util.selectHours
    dayOfTheWeekArr = [0, 1, 2, 3, 4, 5, 6]
    today = DateTime.local().setZone("Asia/Tokyo")
    sundayDate = this.getCurrentSunday()
    // slides = [0, 1, 2, 3, 4] // 3週間分表示.

    // 現在表示中のスライド番号 0-16が入る.
    sliderNum = 0
    MAX_SLIDE_NUM = 16

    location = ""

    isOpenPreview = true // スマホでプレビュー画面を開いているか.
    isDisplayDailyLine = true
    systemUpdatedAt = DateTime.local().toMillis()

    optionalAddEvents = {} // "MMdd": {start_hour: ...}
    optionalRemoveEvents = {}

    avtimezone: any = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }

    pdm = PossibleDatesManager
    pd = null
    alldayEvents = {}

    // カレンダー情報を変更するctagが入ります.
    ctag: CalendarTag = null
    editCtagType: string = null // ignore/available

    /**
     * フロートしているリロードボタンを表示するか.
     * カレンダー連携がされていて、「外部カレンダーの変更」「調整可能時間の変更」「オプションの変更」があった場合に表示します。
     */
    showReloadButton = false
    showListLengthAfterReload = false
    showTooltip = false

    /**
     * 手動<=>自動抽出切り替え時にモーダルを表示します.
     */
    alertDic = {
        title: `登録日時をリセット`,
        text: `個別で可能日時に設定した時間をリセットします。`,
        type: `mode`,
        buttonName: `リセットして切り替え`,
    }

    // astag: AvailableScheduleTag = null

    public mounted() {
        this.updateAstagFromParent()
        this.defaultPosition()
        this.updateWeekSpan(this.today)
    }

    defaultPosition() {
        // for (let page of this.slides) {
        //     $(`#WeeklyContent${page}`).animate({ scrollTop: $(`#grid${page}08`).offset().top - 200 }, `fast`)
        // }
        $(`#WeeklyContent${this.sliderNum}`).animate({ scrollTop: $(`#grid${this.sliderNum}08`).offset().top - 200 }, `fast`)
    }

    public created() {
        // this.updateAstagFromParent();
    }

    public prevSlide() {
        if (this.sliderNum > 0) {
            this.sliderNum -= 1
            let firstDay = this.sundayDate.plus({ days: this.sliderNum * 7 })
            let _format = firstDay.toFormat(`yyyyMM`)
            Logger(
                `${funcName()} this.cm.pdm.months:${this.cm?.pdm?.months}, _format:${_format}, cm.pdm.available_months:${
                    this.cm?.pdm?.available_months
                }`
            )
            if (!(this.cm.pdm.months || []).includes(_format)) {
                if (
                    this.cm.pdm.available_months == null ||
                    this.cm.pdm.available_months.includes(_format) ||
                    !this.cm.isCachedMagEvents(firstDay)
                ) {
                    Logger(`${funcName()} 取得処理します.`)
                    this.getMagsEventsAndPossibleDates(false, _format, firstDay)
                } else {
                    Logger(`${funcName()} 既に取得済みか候補がありませんでした.`)
                }
            }

            gtagClick(`調整カレンダー`, ` ${this.sliderNum}`)
            this.updateWeekSpan(firstDay)
            this.fadeDailyLineAnimation()
        }
    }

    fadeDailyLineAnimation() {
        $(`.availableTimePreviewContentWeekly`).hide(20)
        this.isDisplayDailyLine = false
        sleep(20).then(_ => {
            this.isDisplayDailyLine = true
            $(`.availableTimePreviewContentWeekly`).fadeIn()
        })
    }

    public nextSlide() {
        if (this.sliderNum < this.MAX_SLIDE_NUM) {
            this.sliderNum += 1
            // 取得している日程の中にない場合は、追加で取得処理をします.
            let lastDay = this.sundayDate.plus({ days: (this.sliderNum + 1) * 7 - 1 })
            let _format = lastDay.toFormat(`yyyyMM`)
            Logger(`${funcName()} lastDay:${_format}, months:${this.cm.pdm?.months}`)
            if (!(this.cm.pdm.months || []).includes(_format)) {
                if (
                    this.cm.pdm.available_months == null ||
                    this.cm.pdm.available_months.includes(_format) ||
                    !this.cm.isCachedMagEvents(lastDay)
                ) {
                    Logger(`${funcName()} 取得処理します.`)
                    this.getMagsEventsAndPossibleDates(false, _format, lastDay)
                } else {
                    Logger(`${funcName()} 既に取得済みか候補がありませんでした.`)
                }
            }
            gtagClick(`調整カレンダー`, `${this.sliderNum}`)
            this.updateWeekSpan(lastDay)
            this.fadeDailyLineAnimation()
        }
    }

    updateWeekSpan(startTime: DateTime) {
        let startWeekNum = `${startTime.weekNumber}`.padStart(2, "0")
        let weekNum = Number(`${startTime.year}${startWeekNum}`)
        this.cm.updateWeekSpan(weekNum)
    }

    @Watch("rcm.room_setting.available_days_of_weeks", { deep: true })
    public updateAstagFromParent() {
        Logger(`${funcName()}`)
        if (!this.rcm.room_setting) return

        // 曜日ごとの可能日時を保持します。
        let avtimes = {}
        let adows = this.rcm.room_setting.available_days_of_weeks || []
        for (let dtag of adows) {
            if (!dtag) continue

            let st = dtag.start_hour + dtag.start_min / 60
            let et = dtag.end_hour + dtag.end_min / 60

            if (Util.isBlank(dtag.days_of_the_week)) continue

            for (let wday of dtag.days_of_the_week) {
                avtimes[wday] = avtimes[wday] || []
                Array.prototype.push.apply(
                    avtimes[wday],
                    DateTag.availableArray(dtag)
                    // Array.from({ length: et - st }, (v, k) => k + st)
                )
                avtimes[wday] = avtimes[wday].sort((a, b) => a - b).filter(onlyUnique)
            }
        }

        Logger(`${funcName()} avtimes:${Util.output(avtimes)}`)
        const evs = Event.changeEventFromZone(avtimes)
        this.avtimezone = evs
        Logger(`${funcName()} avtimezone:${Util.output(this.avtimezone)}`)
        // this.optionalEventsDic = this.cm.schedules
    }

    @Watch(`cm.dailyEvents`, { deep: true })
    updateDailyEvents() {
        if (Util.isBlank(this.cm.dailyEvents)) return
    }

    /**
     * オプショナルのイベントに追加して、親に伝えます。
     * @param ev [Event]
     * @param type [string] add/remove
     */
    public addEvent(ev: Event, type: string) {
        Logger(`WeeklyDetailSchedule.addEvent: type: ${type}`)
        let date = DateTime.fromSeconds(ev.start_time)
        let dateStr = date.toFormat(`MMdd`)

        if (type == `cal`) {
            // カレンダーに追加（モーダルを表示）
            this.$vfm.open(`CalendarEventModal`)
        }
    }

    public updateEvent(ev: Event, date) {
        let arr = this.optionalAddEvents[date.toFormat(`MMdd`)]
        for (let _ev of arr) {
            if (ev.systemUpdatedAt == _ev.systemUpdatedAt) {
                _ev = ev
            }
        }
        this.$emit("update", this.optionalAddEvents, "add")
    }

    /**
     * 今週の日曜日の日付を取得します。
     * @return dt [DateTime]
     */
    public getCurrentSunday(): DateTime {
        let dt = DateTime.local().setZone("Asia/Tokyo")
        Logger(`getCurrentSunday: ${DateTime.local().toSQL()} weekday: ${dt.weekday}`)
        // 今日が日曜日だったら、そこをスタートにします。
        if (dt.weekday != 7) {
            dt = DateTime.local().setZone("Asia/Tokyo").startOf("week").minus({ day: 1 })
        }

        Logger(`sunday: ${dt.toSQL()}`)
        return dt
    }

    start(e) {
        let datetime = e.target.id
        // let dt = DateTime.fromSeconds(Number(date));
        // Logger(`start: ${dt.toISODate()} ${hour + 8}`);
        Logger(`start date: ${datetime}`)
    }

    end(e) {
        let datetime = e.target.id
        // let dt = DateTime.fromSeconds(Number(date));
        // Logger(`end: ${dt.toISODate()} ${hour + 8}`);
        Logger(`end date: ${datetime} ${e.target.id}`)
    }

    @Watch(`cm.gettingEvents`)
    updateGettingEvents() {
        Logger(`${funcName()} gettingEvents:${this.cm.gettingEvents}`)
        if (this.cm.gettingEvents) {
            this.loading = true
        } else {
            this.loading = false
        }
    }

    @Watch(`cm.autoloadWithIndexName`)
    autoloadFromOtherView() {
        if (!this.cm.autoloadWithIndexName) return
        Logger(`${funcName()} autoloadWithIndexName:${this.cm.autoloadWithIndexName}`)

        sleep(500).then(_ => {
            if (this.loading) {
                this.autoloadFromOtherView()
                return
            }
            this.cm.autoloadWithIndexName = null
            let firstDay = this.sundayDate.plus({ days: this.sliderNum * 7 })
            this.getMagsEventsAndPossibleDates(true, null, firstDay)
        })
    }

    /***
     *
     * @param needRefresh [boolean] リフレッシュが必要か.必要な場合は、イベント情報・pdmをリセットし、eventから取得しなおします.
     * @param period [string] "202103" でその月の情報を取得してきます.
     * @param date [DateTime] その日から1ヶ月後の終了までのMAGイベント情報を取得します.
     */
    getMagsEventsAndPossibleDates(needRefresh = false, period: string = null, date: DateTime = null) {
        if (this.loading) return
        this.loading = true
        Logger(`${funcName()} needRefresh:${needRefresh}, period:${period}, date:${date?.toISO()}`)
        date = date ? date : DateTime.local()
        let week = CalendarUtil.toWeekSpan(date)

        // 表示を初期値に戻す.
        if (needRefresh || !this.cm.isCachedMagEvents(date)) {
            // magごとにイベントを取得します.
            if (needRefresh) this.cm.updateMagName(`A`)
            let selectedMagName = this.cm.displayMagName

            this.cm.getMagEvents(date, selectedMagName).then(e => {
                if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
                    this.cm.gettingEvents = false
                    this.loading = false
                    return
                }
                this.cm.getPossibleDatesEvents(needRefresh, period, null, week).then(e => {
                    this.loading = false
                })
            })
        } else {
            if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
                this.cm.gettingEvents = false
                this.loading = false
                return
            }
            this.cm.getPossibleDatesEvents(needRefresh, period, null, week).then(e => {
                Logger(`${funcName()} pdEventsの取得処理が終わりました`)
                this.loading = false
            })
        }
    }

    @Watch("showListLengthAfterReload")
    updateShowListLengthAfterReload() {
        if (!this.showListLengthAfterReload) return
        // 3秒後に表示を消します。
        setTimeout(this.hideListLength, 2500)
    }

    public hideListLength() {
        this.showListLengthAfterReload = false
    }

    createdEvent(ev: Event) {
        Logger(`${funcName()} ev:${Util.output(ev)}`)
        const date = DateTime.fromSeconds(ev.start_time)
        const datestr = date.toFormat(`MMdd`)

        // optionalEventsから削除します.
        let oEvents = this.cm.schedules[datestr] || []
        oEvents = [...oEvents]
        oEvents = oEvents.filter(e => !Event.isSame(e, ev))
        this.cm.updateSchedules(oEvents, `all`, date)

        this.updateEventsWithPossibles()

        // イベントを作成した後に取得した情報をeventsに追加します.
        // let evsDic = { ...this.cm.eventsMagDic[this.cm.displayMagName].daily_events }
        // let evs = evsDic[datestr] || []
        // evs.push(ev)
        // evsDic[datestr] = evs
        // this.cm.eventsMagDic[this.cm.displayMagName].daily_events = evs
    }

    updateEventsWithPossibles() {
        // 表示を初期値に戻す.
        let selectedMagName = this.cm.displayMagName
        this.cm.getMagEvents(null, selectedMagName).then(e => {
            Logger(`${funcName()} イベントを更新しました:`)
            if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
                this.cm.gettingEvents = false
                this.cm.astag.systemUpdatedAt = Util.getSec()
                return
            }
            this.cm.getPossibleDatesEvents().then(e => {
                this.cm.astag.systemUpdatedAt = Util.getSec()
            })
        })
    }

    changeAvailableEvent(ev: Event) {
        // 選択中のmagにあるctagを取得
        let _mag = this.cm.mags.find(m => m.name == this.cm.displayMagName)
        Logger(`changeAvailableEvent 選択中のmag: ${Util.output(_mag)}, ${ev.calendar_id}`)
        const ctag = _mag.required_attendees.find(ct => ct.email == ev.calendar_id)
        // this.ctag = this.cm.editStartCtag(_ctag)
        if (!ctag) return
        let _ctag = { ...ctag }
        _ctag.can_use_available_event_keyword = true
        let keyword = _ctag.available_event_keyword
        let keywordArray = keyword ? keyword.split(`|`) : []
        Logger(`keywordArray: ${keywordArray}`)
        if (keywordArray.includes(ev.title)) {
            // すでに追加済み
        } else {
            keywordArray.push(ev.title)
        }
        Logger(`keywordArrayAfter: ${keywordArray}`)
        _ctag.available_event_keyword = keywordArray.join(`|`)
        this.cm.edittingAttendees = _mag.required_attendees
        this.cm.edittingCtag = _ctag
        this.cm.edittingMag = _mag

        this.editCtagType = `available`

        this.$vfm.open(`CalendarTagDetailModal`)
    }

    changeIgnoreEvent(ev: Event) {
        // 選択中のmagにあるctagを取得
        let _mag = this.cm.mags.find(m => m.name == this.cm.displayMagName)
        Logger(`changeIgnoreEvent 選択中のmag: ${Util.output(_mag)}, ${ev.calendar_id}`)
        const ctag = _mag.required_attendees.find(ct => ct.email == ev.calendar_id)
        if (!ctag) return
        let _ctag = { ...ctag }
        _ctag.can_use_ignore_event_keyword = true

        let keyword = _ctag.ignore_event_keyword
        let keywordArray = keyword ? keyword.split(`|`) : []
        Logger(`keywordArray: ${keywordArray}`)
        if (keywordArray.includes(ev.title)) {
            // すでに追加済み
        } else {
            keywordArray.push(ev.title)
        }
        Logger(`keywordArrayAfter: ${keywordArray}`)
        _ctag.ignore_event_keyword = keywordArray.join(`|`)

        this.cm.edittingAttendees = _mag.required_attendees
        this.cm.edittingCtag = _ctag
        this.editCtagType = `ignore`
        this.$vfm.open(`CalendarTagDetailModal`)
    }

    // Ctagの内容にアップデートがあったため、イベントを更新します.
    updatedCtag() {
        this.updateEventsWithPossibles()
    }

    @Watch(`cm.calendarRefreshText`, { deep: true })
    updateCalendarText() {
        if (Util.isBlank(this.cm.calendarRefreshText)) {
            this.showTooltip = false
        } else {
            this.showTooltip = true
        }
    }

    // リロードボタン押下時.
    public reloadPossibleDates() {
        gtagClick(`調整カレンダー`, `イベントリロード`)
        this.cm.calendarRefreshText = null
        this.getMagsEventsAndPossibleDates(true, null)
    }

    get showRefreshButton() {
        // 投票形式でカレンダー連携しない場合、リフレッシュが不要です.
        if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) {
            return false
        }
        return true
    }

    showEventDetail() {
        this.$vfm.open(`CalendarEventDetailModal`)
    }

    changeMonth(monthindex: number) {
        gtagClick(`調整カレンダー`, `月変更 ${monthindex}`)
        if (monthindex == 0) {
            this.sliderNum = 0
        } else {
            let weekNum = this.sundayDate.plus({ month: monthindex }).startOf(`month`).weekNumber - this.sundayDate.weekNumber - 1
            if (weekNum < 0) {
                Logger(`${funcName()} weekNumが0より小さいため、足し直します. weekNum:${weekNum}`)
                // 年をまたいでいるため、今年の残り週+weekNumberを出します.
                let lastWeekNum = this.sundayDate.endOf(`year`).weekNumber
                weekNum =
                    lastWeekNum -
                    this.sundayDate.weekNumber +
                    this.sundayDate.plus({ month: monthindex }).startOf(`month`).weekNumber
            }
            Logger(`${funcName()} monthindex:${monthindex}, weekNum:${weekNum}`)
            this.sliderNum = weekNum
        }
    }

    /**
     *
     * @param idName [string] "0819" => 8/19表示週まで移動.
     */
    @Watch(`pdm.gotoDate`, { deep: true })
    goToDate() {
        let idName = this.pdm.gotoDate
        if (Util.isBlank(idName)) return
        Logger(`${funcName()} WeeklyDetailSchedule#goToDate ${idName}`)

        let month = Number(idName.slice(0, 2))
        let year = this.today.year
        if (this.today.month > month) {
            year += 1
        }
        let day = DateTime.fromFormat(`${idName}-${year}`, "MMdd-yyyy")
        // Logger(`${this.sundayDate.weekNumber} - `)
        // let weekNum = this.sundayDate.plus({ month: monthindex }).startOf(`month`).weekNumber - this.sundayDate.weekNumber - 1
        this.sliderNum = day.weekNumber - this.sundayDate.weekNumber - 1
        this.pdm.gotoDate = null
    }

    get showIconRefreshCal() {
        if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) return false
        return true
    }

    openResetOptionalSchedulesModal() {
        Logger(`${funcName()} schedules:${Util.output(this.cm.schedules)}`)

        if (this.cm.astag.is_only_use_optional_schedules) {
            this.cm.astag.is_only_use_optional_schedules = false
        } else {
            this.cm.astag.is_only_use_optional_schedules = true
        }

        if (Util.isPresent(this.cm.schedules)) {
            // this.alertDic = {
            //     title: `登録日時をリセット`,
            //     text: `個別で可能日時に設定した時間をリセットします。`,
            //     type: `mode`,
            //     buttonName: `リセットして切り替え`,
            // }

            this.$vfm.open(`AlertModal`)
        } else {
            this.resetOptionalSchedules()
            this.changeMode()
        }
    }

    clickOK() {
        if (this.alertDic.type == `mode`) {
            this.resetOptionalSchedules()
            this.changeMode()
        }
        this.$vfm.close(`AlertModal`)
    }

    clickCancel() {
        Logger(`${funcName()} 手動モード:${this.cm.astag.is_only_use_optional_schedules}`)
        this.$vfm.close(`AlertModal`)
        let autoMode = !this.cm.astag.is_only_use_optional_schedules
        this.cm.astag.is_only_use_optional_schedules = null
        if (autoMode) {
            this.cm.astag.is_only_use_optional_schedules = true
        } else {
            this.cm.astag.is_only_use_optional_schedules = false
        }
    }

    resetOptionalSchedules() {
        Logger(`${funcName()}`)
        this.cm.resetSchedules()
        this.cm.schedules = {}
        // もしmagがある場合は、イベントを取得し直します.
        if (Util.output(this.cm.mags)) {
            this.updateEventsWithPossibles()
        }
    }

    changeMode() {
        Logger(`${funcName()} this.cm.astag.is_only_use_optional_schedules:${this.cm.astag.is_only_use_optional_schedules}`)
        // if (this.cm.astag.is_only_use_optional_schedules) {
        //     this.cm.astag.is_only_use_optional_schedules = false
        // } else {
        //     this.cm.astag.is_only_use_optional_schedules = true
        // }
    }

    changeMaxBookingsNum(ev: Event) {
        this.pd = ev
        this.$vfm.open(`ChangeMaxBookingsNumModal`)
    }

    changedMaxBookingsNum(pd: Event) {
        Logger(`${funcName()} pd:${Util.output(pd)}`)
        let dt = DateTime.fromSeconds(pd.start_time)
        this.$vfm.close(`ChangeMaxBookingsNumModal`)
        // this.updateEvent(pd, dt)

        const datestr = dt.toFormat(`MMdd`)

        // optionalEventsから削除します.
        let oEvents = this.cm.schedules[datestr] || []
        oEvents = [...oEvents]
        let ev = oEvents.find(e => Event.isSame(e, pd, true))
        if (ev) {
            ev.max_bookings_num = pd.max_bookings_num
        }

        // oEvents = oEvents.filter(e => !Event.isSame(e, pd))
        // oEvents.push(pd)

        this.cm.updateSchedules(oEvents, `all`, dt)

        this.updateEventsWithPossibles()
    }
}
