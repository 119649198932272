
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"

// コンポーネントを読込.

// モデルを読込.
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import RoomManager from "packs/models/RoomManager"
import { Logger, funcName } from "packs/common"
import Const from "packs/utils/Const"

@Options({
    components: {},
})
export default class MemberSettingsHeader extends Vue {
    // data:
    @Prop()
    selectedTab

    isSP = isMobile(window.navigator).phone
    showAds = false
    from: string = ``
    UserPermissionsOption = UserPermissionsOption
    rm = RoomManager

    mounted() {
        if (this.rm.userInfo) {
            Logger(`${funcName()}`)
        }
    }

    // 課金フォームを表示します。
    // 親のAccount.vueからも飛んでくる.
    public showAdsModal(from: string) {
        this.from = from
        this.showAds = true
    }

    get showTabs() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_chukai_menu`, false)
    }

    showRoomsSettings() {
        if (Const.chukaiFreePlan(this.rm)) return false
        return true
    }

    selectTab(tabName) {
        this.$emit("selectTab", tabName)
    }
}
