
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique, copyToClipboard, funcName } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UrlWithQRcodeContent from "packs/components/common/UrlWithQRcodeContent.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import Room from "packs/models/Room"

@Options({
    components: { TextfieldTag, UrlWithQRcodeContent, TextareaTag, CopyButton, IconHelp, VueFinalModal },
})
export default class PublicLinkModal extends Vue {
    // data:
    @Prop()
    room: Room

    isSP = isMobile(window.navigator).phone
    showTip = false

    isInstantRoom = false
    instantRoomRedirectUrl = null

    selectedTabName = `link` // link, embeds

    public hideTooltip(e) {
        this.showTip = false
        // this.showAppoTip = false;
    }

    selectCategoryTab(name: string) {
        this.selectedTabName = name
    }

    getInstantRoomHtml() {
        if (!this.room) return

        let rs = this.room.room_setting
        if (!rs || !rs.is_instant_room) return
        let url = (this.room.page_url || "").slice()
        let newUrl = url.replace(`/p/`, `/p/instant_button/`)
        let redirect_to = this.instantRoomRedirectUrl || `https://link.waaq.jp`
        let html = `<iframe src="${newUrl}?hide_appointment_info=true&background_color=ffffff&redirect_to=${redirect_to}&hide_instant_comment=true" title="waaq button" height="100" width="300" style="background-color: transparent" frameborder="0" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin"></iframe>`
        return html
    }

    get getEmbedsHtml() {
        let instantUrl = this.getInstantRoomHtml()
        if (instantUrl) {
            this.isInstantRoom = true
            return instantUrl
        }
        let url = (this.room.page_url || "").slice()
        let newUrl = url.replace(`/p/`, `/p/embeds/`)
        let html = `<iframe src="${newUrl}?hide_appointment_info=true&background_color=ffffff" style="width: 100%; height: 1000px; border: 0px; background-color: transparent" frameborder="0" allowtransparency="true"></iframe>`
        return html
    }

    clickTextarea() {
        $(`textarea.pageIframe`).select()
        Logger(`clickTextarea`)
    }

    inputRedirectUrl(e) {
        Logger(`${funcName()} e:${e}`)
        this.instantRoomRedirectUrl = e
    }

    get boardUrl() {
        if (!this.room) return
        let url = this.room.page_url
        return (url || ``).replace(`/p/`, `/p/board/`)
    }
}
