
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import IconGear from "packs/components/icons/IconGear.vue"
import IconRefresh from "packs/components/icons/IconRefresh.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomSetting from "packs/models/RoomSetting"
import SelectUtil from "packs/utils/SelectUtil"
import CalendarUtil from "packs/utils/CalendarUtil"

@Options({
    components: {
        CalendarIcon,
        IconGear,
        IconRefresh,
    },
})
export default class ScheduleMainEditableCalendarHeaderContent extends Vue {
    // data:
    // @Prop()
    // astag: AvailableScheduleTag
    @Prop()
    gettingPds: boolean

    rcm = RoomCreateManager
    cm = CalendarManager
    rm = RoomManager
    pdm = PossibleDatesManager

    showTooltip = false
    periodText = ``
    truncate = truncate

    mounted() {
        this.spanText()
    }

    get tooltipName() {
        if (Util.isBlank(this.rcm.astag)) return null
        let name = this.rcm.astag.name || ``
        if (name.length < 18) return null
        return name
    }

    public clickEditable() {
        Logger(`${funcName()} 調整カレンダーを交換 を押しました.`)
        if (!this.rcm.room.is_owner) return
        this.$emit(`editAstag`)
    }

    clickEditTimeSpan() {
        this.$emit(`editAppoInfo`)
    }

    public clickEditRoomSetting() {
        Logger(`ScheduleMainEditableCalendarHeaderContent#clickEditRoomSetting`)
        this.rcm.currentTab = null
        this.rcm.currentTab = `schedule`
    }

    public clickEditCurrentAstag() {
        Logger(`調整カレンダーの編集 を押しました.`)
        this.cm.setNew(this.rm.userInfo, this.rm.user_setting, this.rcm.astag, this.rcm.room_setting, true, this.rcm.appo)
        if (this.cm.astag) {
            if (this.rm.didConnectCal) {
                // カレンダーリストを取得.
                Logger(`リモートからカレンダーリストを取得します。`)

                this.cm.getCalList().then(res => {
                    // this.PreviewContent.getEvents()

                    this.getMagsEventsAndPossibleDates()
                })
            } else {
                this.getMagsEventsAndPossibleDates()
            }
        }
        $(".leftNavPanel").removeClass("open")
        this.rcm.edittingAstag = this.rcm.astag
        this.rcm.hasUnsaved = true
    }

    getMagsEventsAndPossibleDates() {
        Logger(`${funcName()} `)
        // magごとにイベントを取得します.
        let selectMagName = `A`
        this.cm.updateMagName(selectMagName)
        // if (this.cm.createType == `vote` && !this.cm.astag.can_connect_calendar) {
        //     this.cm.pdm = PossibleDatesManager
        //     let today = DateTime.local()
        //     let nextMonth = today.plus({ month: 1 })
        //     this.cm.pdm.available_months = [today.toFormat(`MMdd`), nextMonth.toFormat(`MMdd`)]
        //     return
        // }
        // 表示を初期値に戻す.
        this.cm.getMagEvents(null, selectMagName).then(e => {
            if (this.cm.createType == `vote` || this.cm.astag.is_only_use_optional_schedules) {
                this.cm.gettingEvents = false
                return
            }
            this.cm.getPossibleDatesEvents()
        })
    }

    @Watch(`rcm.periodText`, { deep: true })
    spanText() {
        let ugSetting = this.rm.findUserGroupSetting()

        // 手動モードで期間無視設定の場合、日程は表示しない.
        if (
            this.rcm.astag &&
            this.rcm.astag.is_only_use_optional_schedules &&
            ugSetting &&
            ugSetting.can_ignore_filters_if_manual_mode
        ) {
            this.periodText = `手動モードでは期間設定が無効になっています（「企業設定」で変更可能）`
            return
        }

        this.periodText = this.rcm.periodText
    }

    get settingDetailText() {
        let rs = this.rcm.room_setting
        let modeName = RoomSetting.changeModeToText(rs)
        if (modeName != `移動考慮不要`) {
            if (rs.fixed_travel_before_min > 0) {
                modeName += ` 前:${rs.fixed_travel_before_min}分`
            }
            if (rs.fixed_travel_after_min > 0) {
                modeName += ` 後:${rs.fixed_travel_after_min}分`
            }
        }

        const typeArray = SelectUtil.assignMagRuleOptions
        let magAssignText = ``
        if (![`display_none`, null].includes(rs.mag_display_type)) {
            const currentOpt = typeArray.find(t => t.type == rs.assign_mag_rule)
            magAssignText += currentOpt ? currentOpt.name : `上から優先順で割り当てる`
        }

        return `${modeName} ${magAssignText}`
    }

    public refreshPreviewSchedule() {
        if (!this.rcm.astag) return
        Logger(`スケジュール表示を更新 `)
        this.pdm.possibleDates = null

        let _astag = { ...this.rcm.astag }
        _astag.systemUpdatedAt = Util.getSec()
        this.rcm.updateAstag(_astag)
    }
}
