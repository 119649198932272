
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { Logger, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import OAuthButtons from "packs/pages/link/settings/OAuthButtons.vue"

// モデルを読込.
import Notice from "packs/models/Notice"
import HeaderControl from "packs/utils/HeaderControl"
import NotificationControl from "packs/utils/NotificationControl"
import Organization from "packs/models/Organization"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        FlashNotice,
        MdTextfieldTag,
        TextfieldTag,
        OAuthButtons,
    },
})
export default class Gate extends Vue {
    // data:
    notice = Notice

    isSP = isMobile(window.navigator).phone
    rm = RoomManager

    email = ""
    didSend = false
    failedMail = false // Emailが正しいか.

    selectedBoxIndex = 0
    values: string[] = [null, null, null, null, null, null]
    invalidCode = false

    // SSO関係
    useSSO = false
    useSSODomain = true
    ssoEmail = ``
    ssoDomain = ``

    loading = false

    public async created() {
        $("#sidebar-port").css({ display: "none" })
        $("#main-content-port").css({ width: "100%" })
        // $("#emailContent").parsley();
        HeaderControl.updateTitle(`サインイン`)
    }

    mounted() {
        $(`.codeBox.codebox_0 input`).focus()
        axios.post(`reset/data`).then(res => {})
    }

    public inputEmail(e) {
        Logger(`${e}`)
        this.email = e
    }

    public login() {
        if (!Util.validateEmail(this.email)) {
            Logger("emailではありません。")
            return false
        }
        if (this.loading) return
        this.loading = true

        axios
            .post(`/m/send`, {
                email: this.email,
            })
            .then(res => {
                Logger(`res: ${Util.output(res.data)}`)
                const result = res.data.result
                if (result) {
                    // 既にログイン済みの場合調整ページ一覧に飛ばします。
                    if (result == `already_logged_in`) {
                        location.href = "/link"
                    } else {
                        // Notice.message = `${res.data.message}`;
                        this.didSend = true
                        sleep(300).then(e => {
                            $(`.codeBox.codebox_0 input`).focus()
                        })
                    }
                }
                this.loading = false

                return []
            })
            .catch(err => {
                Logger(`err: ${err}`)
                NotificationControl.showErrorMessage(err)
                this.loading = false
            })
    }

    public goLP() {
        gtagClick(`遷移: gate → LP`, ``)
        // location.href = `https://link.waaq.jp/`;
        open(`https://link.waaq.jp/`, `_blank`)
    }

    public goForm() {
        gtagClick(`遷移: gate → 資料請求フォーム`, ``)
        open(`https://link.waaq.jp/contact`, `_blank`)
    }

    public goCompany() {
        gtagClick(`遷移: gate → 会社ページ`, ``)
        // location.href = `https://link.waaq.jp/`;
        // open(`https://link.waaq.jp/`, `_blank`);
    }

    public goSct() {
        gtagClick(`遷移: gate → 特商法ページ`, ``)
        // location.href = `https://link.waaq.jp/`;
        // open(`https://link.waaq.jp/`, `_blank`);
    }

    public goTos() {
        gtagClick(`遷移: gate → 利用規約ページ`, ``)
        // location.href = `https://link.waaq.jp/`;
        // open(`https://link.waaq.jp/`, `_blank`);
    }

    public goPp() {
        gtagClick(`遷移: gate → PPページ`, ``)
        // location.href = `https://link.waaq.jp/`;
        // open(`https://link.waaq.jp/`, `_blank`);
    }

    public goHelp() {
        gtagClick(`遷移: gate → ヘルプセンター`, ``)
        // location.href = `https://link.waaq.jp/`;
        // open(`https://link.waaq.jp/`, `_blank`);
    }

    public goSecurityPolicy() {
        gtagClick(`遷移: gate → 情報セキュリティポリシーページ`, ``)
        // location.href = `/security_policy`
    }

    public checkEmailField() {
        let validate
        if (this.useSSO) {
            validate = Util.validateEmail(this.ssoEmail)
            Logger(`checkEmailField validate: ${validate} ssoEmail: ${this.ssoEmail}`)
        } else {
            validate = Util.validateEmail(this.email)
            Logger(`checkEmailField validate: ${validate} email: ${this.email}`)
        }
        // Logger(`emailフィールドを検証します: ${this.member.email} ${validate}`);
        if (!validate) {
            this.failedMail = true
        } else {
            this.failedMail = false
        }
    }

    public inputCodeBox(e) {
        Logger(`inputCodeBox: ${e}`)
        this.values[this.selectedBoxIndex] = e
        this.selectCodeBox(this.selectedBoxIndex + 1)
        this.checkCodeBoxes()
    }

    public selectCodeBox(index: number) {
        this.selectedBoxIndex = index
        $(`.codeBox.codebox_${index} input`).focus()
    }

    public onPaste(e) {
        let text = e.clipboardData.getData("text/plain")
        Logger(`pasteを受け取りました: ${text}`)
        let arr = text.split("").slice(0, 6)
        let vals = [...this.values]
        let startIndex = this.selectedBoxIndex
        let arrIndex = 0
        for (let _index of [0, 1, 2, 3, 4, 5]) {
            if (_index < startIndex) continue
            vals[_index] = arr[arrIndex] ? arr[arrIndex] : vals[_index]
            arrIndex += 1
        }
        // vals.slice(this.selectedBoxIndex, arr.length,arr)
        this.values = vals
        this.checkCodeBoxes()
    }

    // 全角の入力を半角に変更します.
    public toHankaku(str: string) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
        })
    }

    public checkCodeBoxes() {
        Logger(`checkCodeBoxes: ${this.values}`)
        let exists = this.values.filter(v => Util.isPresent(v))
        if (exists.length != 6) return
        let _code = this.values.join("")
        let code = this.toHankaku(_code)
        this.rm.startProgress()
        let sendParams = { email: this.email, code: code }
        axios
            .post(`/m/check_number_token`, sendParams)
            .then(res => {
                this.rm.endProgress()
                if (res.data.result == `ok`) {
                    if (res.data.need_totp) {
                        location.href = `/two_factor_auth`
                    } else {
                        Logger(`ログインしました`)
                        location.href = `/link`
                    }
                } else {
                    if (Util.isPresent(res.data.error_page)) {
                        location.href = res.data.error_page
                    }
                    this.invalidCode = true
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                this.invalidCode = true
                return null
            })
    }

    clickSSO() {
        this.useSSO = true
    }

    clickBackSSO() {
        this.useSSO = false
    }

    clickUseSSOEmail() {
        if (this.useSSODomain) {
            this.useSSODomain = false
        } else {
            this.useSSODomain = true
        }
    }

    inputSSODomain(e) {
        Logger(`inputSSODomain: ${e}`)
        this.ssoDomain = e
    }

    inputSSOEmail(e) {
        Logger(`inputSSOEmail: ${e}`)
        this.ssoEmail = e
    }

    loginSSO() {
        if (this.useSSODomain) {
            // ドメインを利用
            location.href = `/sso_saml/${this.ssoDomain}/sign_in`
        } else {
            if (this.loading) return
            this.loading = true

            // emailでチェックしてあればログインします.
            Organization.checkSSOEmail(this.ssoEmail).then(dic => {
                if (dic) {
                    let url = dic.url
                    if (url) {
                        location.href = Util.getUrl(url)
                    }
                }
                this.loading = false
            })
        }
    }
}
