
// モジュールを読込.
import { Options, Prop, Vue, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomCreator from "packs/utils/RoomCreator"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MailFlow from "packs/models/MailFlow"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomMember from "packs/models/RoomMember"
import ContactListGroup from "packs/models/ContactListGroup"

const roomStorage = new RoomStorage()

@Options({ components: { TextfieldTag, UserIcon } })
export default class CompleteToCreateVotedRoom extends Vue {
    @Prop()
    height: string

    @Prop() // 今回作成したclg
    currentClg: ContactListGroup

    cm = CalendarManager

    step = 1

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util

    pdm = PossibleDatesManager
    rcm = RoomCreateManager

    editOrNewForm = ``
    members: RoomMember[] = []
    opMembers: RoomMember[] = []
    fixMembers: RoomMember[] = []
    UserPermissionsOption = UserPermissionsOption
    titles = TemplateUtil.getTitles()
    mailsDic: { [key: string]: MailFlow } = {} // {key: user.email, value: {subject:, content:, cc_emails:, use_template: false}}
    currentMail: MailFlow = null

    tmp: MessageTemplate = null
    loading = false

    clg: ContactListGroup = null
    viewType = `will_save` // will_save / saved

    // 作成されたルーム情報 Array<{room: Room, room_link: string}>
    generatedRoomsDic: any[] = null

    public mounted() {
        // 完了ページでできることはすべて終わったのでfalseに変えます.
        this.cm.hasUnsaved = false
        this.clg = this.currentClg ? ({ ...this.currentClg } as ContactListGroup) : null
    }

    inputGroupName(e) {
        this.clg.name = e
    }

    createListGroup() {
        Logger(`CompleteToCreateVotedRoom#createListGroup`)
        if (Util.isBlank(this.clg.name)) {
            Notice.message = `グループ名を名前を入力してください。`
            return
        }
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        ContactListGroup.save(this.clg).then(clgDic => {
            this.loading = false
            this.rm.endProgress()
            if (clgDic) {
                this.clg = clgDic.contact_list_group
                this.rm.address_book.contact_list_groups.unshift(clgDic.contact_list_group)

                this.rcm.reset()
                this.cm.resetAstagInfo()
                if (this.pdm) {
                    this.pdm.resetPdm()
                    this.pdm.resetInfo()
                }
                this.viewType = `saved`
            }
        })
    }

    goSummary() {
        let room = this.rcm.room
        this.rm.updateCurrentInfo(room)

        roomStorage.save(room)
        roomStorage.saveAppointment(room.current_appointment)
        roomStorage.saveSelectedRoomKey(room.keyId)
        this.$router.push("/pub_summary")
    }

    goRooms() {
        this.$router.push("/")
    }
}
