
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import axios from "axios"
import { Logger, copyToClipboard } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import SelectableImage from "packs/components/images/SelectableImage.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import UserFile from "packs/models/UserFile"

@Options({
    components: { SelectableImage, VueFinalModal, MdProgressBar },
})
export default class ImageBoardModal extends Vue {
    @Prop()
    title: string

    @Prop()
    currentImageUrl: string

    @Prop() // 画像 + アップロードしている画像を表示
    showWithFiles: boolean

    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    loading = false
    isDrag = null
    // astags: AvailableScheduleTag[] = null

    imageUrl: string = null
    beforeUrl: string = null
    selectedFile: UserFile = null // 選択中の画像ファイル

    // 公開で保存している画像.（UGごと）
    userFiles: UserFile[] = null

    validImage = true

    public dismissModal() {
        this.$vfm.close("ImageBoardModal")
    }

    cancelInput() {
        this.dismissModal()
    }

    openModal() {
        this.updateBeforeUrl()
        if (this.rm.userInfo) {
            this.updateImages()
        }
    }

    updateImages() {
        if (this.rm.userFiles) {
            this.userFiles = this.rm.userFiles.filter(im => im.is_image_file)
        } else {
            axios.get(`${Util.prefixUrl}/file_uploaders/index_public_images?show_with_files=true`).then(e => {
                let _images = e.data.images
                if (_images) {
                    let images = UserFile.fetchFromJson(_images)
                    if (this.showWithFiles) {
                        this.userFiles = images.filter(im => im.is_image_file)
                    } else {
                        this.userFiles = images
                    }
                    this.rm.userFiles = images

                    return this.userFiles
                }
            })
        }
    }

    @Watch(`currentImageUrl`)
    updateBeforeUrl() {
        if (Util.isPresent(this.currentImageUrl)) {
            this.beforeUrl = this.currentImageUrl
        } else {
            this.beforeUrl = null
        }
    }

    public create() {
        if (!this.selectedFile) {
            Notice.message = `画像を選択してください。`
            return
        }
        this.$emit(`select`, this.selectedFile)
    }

    closeModal() {
        this.$emit("saveProfile", null)
    }

    public changeImage(file) {
        Logger(`changeImage: ${file.image_url}`)
        this.imageUrl = file.image_url
        this.beforeUrl = file.image_url
        this.selectedFile = file
    }

    public mouseoverImage(file) {
        this.imageUrl = file.image_url
    }

    public mouseleaveImage() {
        this.imageUrl = this.beforeUrl
    }

    public deleteImage(file: UserFile) {
        if (confirm(`削除してよろしいですか？この操作は元に戻せません。`)) {
            UserFile.delete(file.file_id).then(e => {
                this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
                this.rm.userFiles = this.userFiles
            })
        }
    }

    checkDrag(event, key, status) {
        this.isDrag = status ? key : null
        Logger(`isDrag: ${this.isDrag}`)
    }

    onDrop(event, key = "", image = {}) {
        Logger(`ドロップしました。`)
        if (this.loading) {
            Notice.message = `アップロード中です。`
            return false
        }
        this.isDrag = null

        let params = UserFile.createFileParams(event, `public_image`)[0]
        if (!params) return

        params.append(`update_self`, `${false}`)

        this.loading = true
        this.rm.startProgress()

        RoomMember.uplaodUserImage(params).then(data => {
            event.target.value = null
            event = null
            this.loading = false
            this.rm.endProgress()
            if (data.result == `ok`) {
                Logger(`イメージを置き換えます : ${data.file.image_url}`)
                // this.rm.userInfo.image_url = data.image_url
                let uf = UserFile.fetchFromJson([data.file])[0]
                if (Util.isPresent(this.userFiles)) {
                    this.userFiles.push(uf)
                } else {
                    this.userFiles = [uf]
                }
                this.rm.userFiles = this.userFiles
                this.changeImage(uf)
                this.$emit("uploaded", data.file)
                // this.$emit("uploadedFile")
            }
        })
    }
}
