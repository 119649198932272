
// モジュールを読込.
import { Options, Prop, Vue, Ref, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import DownloadConditionsContent from "packs/pages/link/parts/room_summary/DownloadConditionsContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import DownloadableFileCell from "packs/pages/link/settings/DownloadableFileCell.vue"
import InputFileNameModal from "packs/pages/link/settings/InputFileNameModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import NotificationControl from "packs/utils/NotificationControl"
import FileUtil from "packs/utils/FileUtil"
import UserFile from "packs/models/UserFile"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        DownloadConditionsContent,
        MdTextfieldTag,
        IconHelp,
        DownloadableFileCell,
        InputFileNameModal,
    },
})
export default class UserSettingsDownload extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    selectedTab = `download`
    Util = Util

    isSP = isMobile(window.navigator).phone
    // users: RoomMember[] = [];

    // invitedUserEmail = "";
    // invitedUserRole = 1;
    // subm = SubscriptionManager;

    beforeAdow: DateTag = null // オプショナルスケジュールが入っている場合、確認してadow(dtag)に追加します。
    showCustoms = false
    showSaveButton = false
    astag: AvailableScheduleTag = null

    Const = Const

    showNotAttendAstagSaveButton = false
    buttonName = ``
    saving = false

    publicIds: string[] = []

    downloadableFiles: UserFile[] = null
    currentFile: UserFile = null

    @Ref()
    header

    @Watch(`rm.downloadableFiles`)
    updateDownloadableFiles() {
        Logger(`${funcName()} downloadableFiles更新を受け取りました. ${(this.rm.downloadableFiles || []).length}`)
        this.downloadableFiles = this.rm.downloadableFiles || []
    }

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkPermission()
                this.buildData()
            })
        } else {
            this.checkPermission()
            this.buildData()
        }
    }

    mounted() {
        gtagPage("#/settings/download")
        this.updateDownloadableFiles()
    }

    public buildData() {
        // this.astag = this.rm.chukaiAstag
        // this.astag.can_connect_calendar = false
        // Logger(`buildData astag: ${Util.output(this.astag)}`)

        // if (this.astag && this.astag.id == `newId`) {
        //     this.buttonName = `作成`
        // } else {
        //     this.buttonName = `更新`
        // }
        // if (!this.rm.astag || this.rm.astag.id == "newId") {
        //     this.showNotAttendAstagSaveButton = false
        // } else {
        //     this.showNotAttendAstagSaveButton = true
        // }

        this.getDownloadableFiles()
    }

    checkPermission() {
        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `user_settings`, `user_settings_download`)) {
            this.$router.push("/settings/user")
        }
    }

    inputPublicIDs(e) {
        Logger(`${funcName()} e:${e}`)
        this.publicIds = e.split(",")
    }

    getDownloadableFiles() {
        Logger(`${funcName()}`)
        UserFile.getDownloadableFiles().then(data => {
            if (data && data.files) {
                // this.downloadableFiles = data.files
                this.rm.downloadableFiles = data.files
            }
        })
    }

    editName(uf: UserFile) {
        this.currentFile = uf
        this.$vfm.open(`InputFileNameModal`)
    }

    renamed(uf: UserFile) {
        this.$vfm.close(`InputFileNameModal`)
        let files = this.rm.downloadableFiles
        let file = files.find(f => f.file_id == uf.file_id)
        if (file) {
            file.original_filename = uf.original_filename
            this.currentFile = null
        }
    }
}
