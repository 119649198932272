
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        TextareaTag,
    },
})
export default class InviteMembersContent extends Vue {
    // data:
    @Prop()
    users: RoomMember[]

    @Prop()
    canUseCustomPermissions: boolean

    @Prop() // my_ug, client_ug
    type: string

    @Prop() //any
    newClientInfoDic: any

    // solo, multi
    emailDisplayType = `solo`

    notice = Notice

    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil

    isSP = isMobile(window.navigator).phone

    invitedUserEmail = ""
    // 送信する / 変更するユーザー
    invitedUser: RoomMember = null
    invitedUserRole = 1
    failedMail = false // Emailが正しいか.
    sending = false
    action = `new` // new / edit
    systemUpdatedAt = Util.getSec()

    selectCreateRoomTypes = [`simple`]
    addedEventListener = false

    newClientInfo = {
        user_group_id: null,
        company_name: "",
        name: "",
        email: "",
        invite_connect_key: null,
    }

    mounted() {
        Logger(`InviteMembersContent#mounted`)
        this.updateNewClientInfo()
    }

    @Watch(`newClientInfoDic`, { deep: true })
    updateNewClientInfo() {
        this.newClientInfo = this.newClientInfoDic
    }

    updated() {
        let contenteditable = document.querySelector(".emailsContent")
        if (contenteditable && !this.addedEventListener) {
            contenteditable.addEventListener("paste", function (e: any) {
                // Prevent the default pasting event and stop bubbling
                e.preventDefault()
                e.stopPropagation()
                // Get the clipboard data
                let paste = (e.clipboardData || (window as any).clipboardData).getData("text/plain")
                Logger(`paste string ${Util.output(paste)}`)

                // Do something with paste like remove non-UTF-8 characters
                // paste = paste.replace(/\x0D/gi, "\n")
                // let arr = paste.split(`\n`)
                // let html = contenteditable.innerHTML
                // html.replace(`<div></div>`, ``)
                // let arr = paste.split(/[\s,]+/)
                // Logger(`paste arr: ${Util.output(arr)}`)
                // let text = "<div>" + arr.join("</div><div>") + "</div>"
                // Logger(`完成したtext: ${Util.output(text)}`)
                // e.target.textContent += paste
                // document.execCommand("insertHTML", false, text)
                document.execCommand("insertText", false, paste)
            })
            this.addedEventListener = true
        }
    }

    getDefaultOption() {
        return "管理者"
    }

    registMulti() {
        if (this.emailDisplayType == `solo`) {
            this.emailDisplayType = `multi`
        }
    }

    selectRoleNewMember(e) {
        this.action = `new`
        if (e == `カスタム` && !this.canUseCustomPermissions) {
            this.$vfm.open(`OptionModal`)
            return
        }
        this.selectRole(e)
    }

    selectRole(e) {
        Logger(`role : ${e}`)
        if (e === "メンバー") {
            Logger(`メンバー`)
            this.invitedUserRole = 1
        } else if (e === `管理者`) {
            Logger(`管理者`)
            this.invitedUserRole = 100
        } else {
            Logger(`カスタムのため、モーダルを開いて設定します. mem: ${Util.output(this.invitedUser)}`)
            this.invitedUserRole = -1
            this.$vfm.open(`CustomPermissionModal`)
        }
    }

    inputEmail(e) {
        Logger(`email: ${e}`)
        this.invitedUserEmail = e
        if (!this.invitedUser) {
            let user = RoomMember.createDefaultClient()
            user.user_permissions_option = UserPermissionsOption.createDefault()
            user.email = e
            this.invitedUser = user
        } else {
            this.invitedUser.email = e
        }
    }

    // カスタムパーミッションの場合はこちらを通ります.
    sendInviteMailWithPermissions(po: UserPermissionsOption) {
        Logger(`InviteMembersContent#sendInviteMailWithPermissions po: ${Util.output(po)}`)
        if (Util.isBlank(this.invitedUser)) {
            let user = RoomMember.createDefaultClient()
            user.user_permissions_option = UserPermissionsOption.createDefault()
            this.invitedUser = user
        }
        this.invitedUserRole = -1
        this.invitedUser.user_permissions_option = po
        this.sendInviteMail()
    }

    /**
     * 招待メールを送信します。
     */
    sendInviteMail() {
        let sendEmails: string[] = []
        if (this.emailDisplayType == `solo`) {
            if (!Util.validateEmail(this.invitedUserEmail)) {
                Logger("emailではありません。")
                return false
            }
            // 既に作成済みのユーザーの場合、入れない.
            let user = this.users.find(u => u.email == this.invitedUserEmail)
            if (user) {
                Notice.message = `既に追加済みのユーザーです。`
                this.resetNewField()
                return false
            }
            sendEmails = [this.invitedUserEmail]
        } else {
            let userEmails = this.users.map(u => u.email)
            // let contenteditable = document.querySelector("[contenteditable]")
            let contenteditable = document.getElementById("editor")
            let emails = []
            if (contenteditable) {
                let innerText = (contenteditable.innerText || ``).split("\n")

                for (let _em of innerText) {
                    Logger(`email: ${_em}`)
                    if (Util.validateEmail(_em) && !userEmails.includes(_em) && !emails.includes(_em)) {
                        emails.push(_em)
                    }
                }
            }
            sendEmails = emails
        }

        this.invite(sendEmails)
    }

    // 新規追加フィールドをリセットします。
    public resetNewField() {
        this.invitedUserEmail = ""
        this.invitedUserRole = 1
        this.invitedUser = null
        this.action = null
        let contenteditable = document.getElementById("editor")
        if (contenteditable) {
            contenteditable.innerHTML = ``
        }
        this.emailDisplayType = `solo`
    }

    public invite(emails: string[], role: number = null, upo: any = null) {
        Logger(`InviteMembersContent#invite emails: ${Util.output(emails)}, role: ${role}, upo: ${Util.output(upo)}`)
        if (this.sending) return
        this.rm.startProgress()
        this.sending = true

        if (Util.isBlank(this.invitedUser)) {
            let user = RoomMember.createDefaultClient()
            user.user_permissions_option = UserPermissionsOption.createDefault()
            this.invitedUser = user
        }

        if (role) {
            this.invitedUserRole = role
        }
        if (upo) {
            this.invitedUser.user_permissions_option = upo
        }

        UserPermissionsOption.invite(
            emails,
            this.invitedUserRole,
            this.invitedUser.user_permissions_option,
            this.selectCreateRoomTypes
        ).then(createdUsers => {
            this.rm.endProgress()
            if (createdUsers) {
                let users = [...(this.users || [])]
                for (let user of createdUsers) {
                    let u = users.find(u => u.email == user.email)
                    if (u) {
                        Object.assign(u, user)
                    } else {
                        users.push(user)
                    }
                }
                this.$emit(`updateUsers`, users)
                this.resetNewField()
                this.rm.endProgress()
                gtagClick(`管理者 ユーザー招待`, `${users.length}人目 ${this.invitedUserEmail}`)
            }
            this.sending = false
        })
    }

    public checkEmailField(email) {
        email ||= this.invitedUserEmail
        let validate = Util.validateEmail(email)
        // Logger(`emailフィールドを検証します: ${this.member.email} ${validate}`);
        if (!validate) {
            this.failedMail = true
        } else {
            this.failedMail = false
        }
    }

    clickCreateRoomCell(type: string) {
        Logger(`clickCreateRoomCell type: ${type}`)
        if (this.selectCreateRoomTypes.includes(type)) {
            this.selectCreateRoomTypes = this.selectCreateRoomTypes.filter(r => r != type)
        } else {
            this.selectCreateRoomTypes.push(type)
        }
    }

    showContent() {
        if (Const.chukaiFreePlan(this.rm)) return false
        return true
    }

    inputClientCompanyName(e) {
        this.newClientInfo.company_name = e
    }

    inputClientName(e) {
        this.newClientInfo.name = e
    }

    inputClientEmail(e) {
        this.newClientInfo.email = e
    }

    inviteNewClient() {
        this.$emit(`inviteNewClient`, this.newClientInfo)
    }
}
