
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { gtagClick } from "packs/GoogleTagManager"
import TemplateUtil from "packs/utils/TemplateUtil"
import { Logger, copyToClipboard, funcName } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TemplateCell from "packs/pages/link/parts/TemplateCell.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import MessageTemplate from "packs/models/MessageTemplate"
import RoomMember from "packs/models/RoomMember"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: { MdTextfieldTag, LblueListContent, TextareaTag, TemplateCell, VueFinalModal, MdProgressBar },
})
export default class TemplatesContent extends Vue {
    @Prop()
    pageTitle: string

    @Prop()
    showEditButton: boolean

    @Prop()
    showAddNewButton: boolean

    @Prop() // settings: ユーザー設定, createRoom: ルーム作成
    pageFrom: string

    @Prop()
    members: RoomMember[]

    @Prop()
    appo: Appointment

    @Ref()
    TemplateTextarea

    @Prop()
    room: Room

    // data
    isSP = isMobile(window.navigator).phone
    appos = null
    rm = RoomManager
    pdm = PossibleDatesManager
    rcm = RoomCreateManager
    loading = false
    // astags: AvailableScheduleTag[] = null

    showNew = true

    tmps: MessageTemplate[] = null
    mode: string = `select` // select / create
    currentTmp: MessageTemplate = null
    owner: RoomMember = null
    currentMembers: RoomMember[] = null
    afterText = ``
    titles = TemplateUtil.getTitles()

    selectedTmp: MessageTemplate = null
    openVariableList = false

    text = `<<出席者-1-氏名>>様にあったご案内をさせていただきます。下記日程よりご確定ください。`

    public dismissModal() {
        this.$vfm.close("ModalTemplates")
    }

    mounted() {
        if (!this.tmps) {
            this.rm.getTemplates().then(tmps => {
                this.tmps = tmps
                if (this.pageFrom != `settings` && this.rcm.room && this.rcm.room.default_message_template_id) {
                    this.selectedTmp = this.tmps.find(t => t.id == this.rcm.room.default_message_template_id)
                }
            })
        } else {
            if (this.pageFrom != `settings` && this.rcm.room && this.rcm.room.default_message_template_id) {
                this.selectedTmp = this.tmps.find(t => t.id == this.rcm.room.default_message_template_id)
            }
        }

        this.updateMembers()
    }

    @Watch("members", { deep: true })
    updateMembers() {
        Logger(`updateMembers`)
        if (!this.members) return
        if (!this.rm.userInfo) return
        this.currentMembers = this.members.filter(m => m.user_id != this.rm.userInfo.user_id)
        this.owner = (this.members || []).find(m => m.role == 100) || this.rm.userInfo
        Logger(`modal templatesで受け取ったmembers: ${(this.members || []).length}`)
        this.updateAfterText()
    }

    backTemplates() {
        this.mode = `select`
    }

    createNewTemplate() {
        Logger(`新規作成`)
        if (this.pageFrom == `settings`) {
            this.currentTmp = MessageTemplate.createDefaultTemplate()
            this.text = `<<出席者-1-氏名>>様にあったご案内をさせていただきます。下記日程よりご確定ください。`
            this.owner = (this.members || []).find(m => m.role == 100) || this.rm.userInfo
            this.updateAfterText()
            this.mode = `create`
        } else {
            if (confirm(`ページを離れますがよろしいですか？`)) {
                this.$router.push(`/settings/mail/templates`)
                return
            }
        }
    }

    editTmp(tmp: MessageTemplate) {
        // 編集画面に移行.
        this.currentTmp = { ...tmp } as MessageTemplate
        this.text = this.currentTmp.content
        this.owner = (this.members || []).find(m => m.role == 100) || this.rm.userInfo
        this.updateAfterText()
        this.mode = `create`
    }

    deleteTmp(tmp: MessageTemplate) {
        // 削除していいかのアラートを表示します。
        if (confirm(`テンプレ「${tmp.title}」を削除します。この操作は元に戻せません。`)) {
            Logger(`削除します`)
            tmp.status = -1
            MessageTemplate.create(tmp, `put`).then(retTmp => {
                if (retTmp) {
                    // 成功した場合、表示から消します。
                    this.tmps = this.tmps.filter(t => t.id != retTmp.id)
                    this.rm.templates = [...this.tmps]
                }
                this.mode = `select`
                this.loading = false
            })
        }
    }

    /**
     * 作成ボタン押下時.
     */
    create() {
        if (!this.currentTmp.title || this.currentTmp.title.length == 0) {
            Notice.message = `テンプレート名を入力してください。`
            return
        }

        if (!this.text || this.text.length == 0) {
            Notice.message = `テンプレートの内容がありません。`
            return
        }

        if (this.loading) return

        this.loading = true

        this.currentTmp.content = this.text
        /**
         * 作成した場合 → モーダルを消して本文内容にはめます.
         * 更新した場合 → モーダルを消さずに一覧に戻ります.
         */
        MessageTemplate.create(this.currentTmp, `post`).then(tmp => {
            if (tmp) {
                if (this.tmps) {
                    let _tmp = this.tmps.find(t => t.id == tmp.id)
                    if (_tmp) {
                        Object.assign(_tmp, tmp)
                    } else {
                        this.tmps.push(tmp)
                    }
                    // let tmps = this.tmps.filter(t => t.id != tmp.id) as MessageTemplate[]

                    // this.tmps = tmps
                } else {
                    this.tmps = [tmp]
                }
                this.rm.templates = [...this.tmps]
                if (this.currentTmp.id == `newId`) {
                    // 成功した場合、modalを閉じて本文のテンプレに代入します。
                    this.$emit("decideTemplate", tmp)
                    this.dismissModal()
                } else {
                    // 更新
                }
            }
            this.mode = `select`
            this.loading = false
        })
    }

    public inputTitle(e) {
        this.currentTmp.title = e.trim()
    }

    public inputSubject(e) {
        this.currentTmp.subject = e.trim()
    }

    public inputContent(e) {
        // this.currentTmp.content = e;
        this.text = e.target.value
        Logger(`inputContent e: ${this.text}`)
        this.updateAfterText()
    }

    public updateAfterText() {
        // 変換.
        let atts = (this.members || []).filter(m => m.user_id != this.rm.userInfo.user_id)
        let owner = (this.members || []).find(m => m.role == 100) || this.rm.userInfo
        let str = TemplateUtil.insertTemplateText(
            this.text,
            atts,
            owner,
            this.appo,
            this.rm.userInfo,
            this.pdm.possibleDates,
            null,
            null,
            `rich`,
            this.room
        )
        Logger(`変換後のstr: ${str}`)

        this.afterText = str
    }

    /**
     * 選択した変数をカーソルがある位置に挿入します。
     */
    public selectVariable(e) {
        Logger(`select ${e}`)
        let textarea = this.TemplateTextarea
        const pos = textarea.selectionStart
        let sentence = textarea.value
        const len = sentence.length

        const before = sentence.substr(0, pos)
        const after = sentence.substr(pos, len)
        sentence = before + e + after
        this.text = sentence

        // プレビューを更新.
        this.updateAfterText()

        Logger(`テキストを更新したあと、フォーカスを移動します。 ${(before + e).length}`)

        // カーソルを元の位置（挿入した変数の後ろ）に変更.
        setTimeout(() => {
            textarea.focus()
            textarea = this.TemplateTextarea
            textarea.selectionStart = textarea.selectionEnd = (before + e).length
        }, 10)
    }

    reselectTemp() {
        this.selectedTmp = null
    }

    public selectTmp(tmp: MessageTemplate) {
        Logger(`${funcName()} tmp.title:${tmp.title}`)
        if (this.pageFrom == `settings`) {
            return
        }
        if (this.selectedTmp) {
            if (!confirm(`選択中のテンプレがありますが、変更してよろしいですか？`)) {
                return
            }
        }

        this.selectedTmp = { ...tmp }
        this.$emit("decideTemplate", tmp)
    }

    addDefaultTemplate() {
        if (this.loading) return
        this.loading = true

        Room.addDefaultTemplate(this.rcm.room, this.selectedTmp.id).then(res => {
            if (res.success) {
                this.rcm.room.default_message_template_id = this.selectedTmp.id

                let room = this.rm.rooms?.find(r => r.id == this.rcm.room.id)
                if (room) {
                    room.default_message_template_id = this.selectedTmp.id
                    if (this.rm.currentRoom?.id == room?.id) {
                        this.rm.currentRoom.default_message_template_id = this.selectedTmp.id
                    }
                }
                this.$emit(`updateSelectedRoom`, this.rcm.room)
            }
            this.loading = false
        })
    }

    public hideTooltip(index) {
        // this.showTip = false
        let tmp = this.tmps[index]
        tmp.showTooltip = false
        // this.showTips[index] = false
    }

    toggleVariableList() {
        this.openVariableList = !this.openVariableList
    }

    // public changeDefault(astag) {
    //     AvailableScheduleTag.changeDefault(astag).then(e => {
    //         if (e) {
    //             this.rm.changeDefaultAstag(astag)
    //             this.astags = this.rm.getAllAstags()
    //         }
    //     })
    // }

    // public selectEnd() {
    //     Logger(`選択しました。`)
    //     const astag = this.astags.find(e => e.selected)
    //     // this.rm.astag = astag;
    //     this.$vfm.close("SelectAstagsModal")
    //     this.$emit("selectEnd", astag)
    // }
}
