
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import { DateTime } from "luxon"
import { truncate } from "packs/models/TruncateUtil"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import OneMagSmallContent from "packs/pages/link/parts/available_schedule/OneMagSmallContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import ScheduleSlideContent from "packs/pages/link/ScheduleSlideContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"
import Appointment from "packs/models/Appointment"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesConditions from "packs/models/PossibleDatesConditions"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import VotedRoomManager from "packs/models/VotedRoomManager"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        OneMagSmallContent,
        CheckboxTag,
        ScheduleSlideContent,
        MdTextfieldTag,
        SelectTag,
    },
})
export default class SuggestedDatesPeriodSettings extends Vue {
    SelectUtil = SelectUtil
    vrm = VotedRoomManager

    room: Room = null
    appo: Appointment = null
    mags: MeetingAttendeesGroup[] = null

    // data
    rm = RoomManager

    pdm = PossibleDatesManager
    pdc = PossibleDatesConditions
    cm = CalendarManager

    loading = false

    Util = Util
    truncate = truncate
    suggestedDates = []

    // selectedLength
    selectedLength = 0

    startDate = DateTime.fromSeconds(this.vrm.suggested_dates_display_start_at || DateTime.local().toSeconds()).toFormat(
        "yyyy/MM/dd"
    )
    endDate = DateTime.fromSeconds(
        this.vrm.suggested_dates_display_start_at || DateTime.local().plus({ days: 7 }).toSeconds()
    ).toFormat("yyyy/MM/dd")

    mounted() {
        // this.updateCurrentPd()
        // for (let pd of this.vrm.openMember.suggested_dates) {
        //     pd.selected = true
        // }
        let suggestedDates = [...this.vrm.openMember.suggested_dates]
        let now = DateTime.local().toSeconds()
        suggestedDates = suggestedDates.filter(sd => sd.start_time > now)
        Logger(
            `${funcName()} suggestedDates:${suggestedDates?.length}, vrm.suggested_dates_display_type:${
                this.vrm.suggested_dates_display_type
            }, vrm.suggested_dates_display_start_at:${
                this.vrm.suggested_dates_display_start_at
            }, vrm.suggested_dates_display_end_at:${this.vrm.suggested_dates_display_end_at}`
        )
        if (this.vrm.suggested_dates_display_type == `period`) {
            this.startDate = DateTime.fromSeconds(this.vrm.suggested_dates_display_start_at).toFormat("yyyy/MM/dd")
            this.endDate = DateTime.fromSeconds(this.vrm.suggested_dates_display_end_at).toFormat("yyyy/MM/dd")
        } else if (this.vrm.suggested_dates_display_type == `select_self`) {
            let ops = this.vrm.openMember.suggested_dates_optional_schedules
            for (let op of ops) {
                for (let pd of suggestedDates) {
                    if (op == pd.start_time) {
                        pd.selected = true
                    }
                }
            }
        }
        this.suggestedDates = suggestedDates
        this.updateStartDate()
        this.updateEndDate()
        this.updateCount()
    }

    defaultDisplayType(): string {
        Logger(`${funcName()} vrm.suggested_dates_display_type:${this.vrm.suggested_dates_display_type}`)
        if (this.vrm.suggested_dates_display_type) {
            let typeDic = SelectUtil.suggestedDatesDisplayType.find(t => t.type == this.vrm.suggested_dates_display_type)
            if (typeDic) {
                return typeDic.name
            }
        }
        return SelectUtil.suggestedDatesDisplayType[0].name
    }

    selectedDiscription() {
        if (this.vrm.suggested_dates_display_type) {
            let typeDic = SelectUtil.suggestedDatesDisplayType.find(t => t.type == this.vrm.suggested_dates_display_type)
            if (typeDic) {
                return typeDic.description
            }
        }
        return SelectUtil.suggestedDatesDisplayType[0].description
    }

    selectDisplayType(e) {
        Logger(`selectDisplayType: ${e}`)
        let typeDic = SelectUtil.suggestedDatesDisplayType.find(t => t.name == e)
        if (typeDic) {
            this.vrm.suggested_dates_display_type = typeDic.type
            this.updateCount()
        }
    }

    updateCount() {
        let type = this.vrm.suggested_dates_display_type
        if (type == `all`) {
            this.selectedLength = this.suggestedDates?.length || 0
        } else if (type == `period`) {
            let selectedPds = []
            for (let pd of this.suggestedDates) {
                if (
                    this.vrm.suggested_dates_display_start_at < pd.start_time &&
                    this.vrm.suggested_dates_display_end_at > pd.start_time
                ) {
                    selectedPds.push(pd)
                }
            }
            this.selectedLength = selectedPds.length
        } else if (type == `select_self`) {
            this.selectedLength = this.suggestedDates.filter(e => e.selected).length
        }
    }

    clickSuggestDate(pd: PossibleDate, index: number) {
        Logger(`clickSuggestDate: ${pd.jp_format}`)
        // pd.selected = !pd.selected
        // let _pd = this.vrm.openMember.suggested_dates.find(e => e.id == pd.id)
        for (let _pd of this.suggestedDates) {
            if (_pd.id == pd.id) {
                _pd.selected = !_pd.selected
            }
        }
        // if (_pd) {
        //     this.suggestedDates = this.vrm.openMember.suggested_dates.filter(e => e.id != pd.id)
        // } else {
        //     // this.suggestedDates = this.vrm.openMember.suggested_dates.push(pd)
        // }
        // this.suggestedDates[index] = pd

        this.vrm.openMember.suggested_dates_optional_schedules = this.suggestedDates
            .filter(sd => sd.selected)
            .map(sd => sd.start_time)
        this.updateCount()
    }

    @Watch(`startDate`, { deep: true })
    updateStartDate() {
        let sec = DateTime.fromFormat(this.startDate, `yyyy/MM/dd`).toSeconds()
        this.vrm.suggested_dates_display_start_at = sec
        this.updateCount()
    }

    @Watch(`endDate`, { deep: true })
    updateEndDate() {
        let sec = DateTime.fromFormat(`${this.endDate} 23:59:59`, `yyyy/MM/dd HH:mm:ss`).toSeconds()
        this.vrm.suggested_dates_display_end_at = sec
        this.updateCount()
    }

    selectAll() {
        for (let _pd of this.suggestedDates) {
            _pd.selected = true
        }
        this.vrm.openMember.suggested_dates_optional_schedules = this.suggestedDates
            .filter(sd => sd.selected)
            .map(sd => sd.start_time)
        this.updateCount()
    }

    deselectAll() {
        for (let _pd of this.suggestedDates) {
            _pd.selected = false
        }
        this.vrm.openMember.suggested_dates_optional_schedules = this.suggestedDates
            .filter(sd => sd.selected)
            .map(sd => sd.start_time)
        this.updateCount()
    }
}
