
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.

// モデルを読込.
import RoomMember from "packs/models/RoomMember"
import Util from "packs/utils/Util"
import RoomManager from "packs/models/RoomManager"
import UserFile from "packs/models/UserFile"
import Notice from "packs/models/Notice"

@Options({})
export default class UserIcon extends Vue {
    // data:
    @Prop()
    member: RoomMember

    @Prop()
    width: number

    @Prop()
    canEdit: boolean

    @Prop()
    imageUrl: string

    @Prop()
    updateSelf: boolean // canEdit=trueのときにアップロードしたイメージを自分の画像に設定するか.（リモートのアップデートを走らすか）

    @Prop()
    tooltipName: string

    @Prop()
    showTooltip: boolean

    @Prop()
    tooltipWithEmail: boolean

    mem: RoomMember = null
    rm = RoomManager

    loading = false
    isDrag: string = null
    fileForm: any = null

    public created() {}

    public mounted() {
        this.updateMemberInfo()
    }

    @Watch("member", { deep: true })
    updateMemberInfo() {
        if (!this.member) return
        // Logger(`updateMemberInfo: ${this.member.image_url}`)
        this.mem = this.member
    }

    public hasImage(): boolean {
        if (this.imageUrl) return true
        if (this.mem && this.mem.image_url) {
            return true
        }
        return false
    }

    get memberIconPath(): string {
        if (this.imageUrl) return this.imageUrl

        if (!this.mem) return ``

        const imageUrl = this.mem.image_url
        if (imageUrl) return imageUrl
        return ``
    }

    get tooltipString() {
        if (!this.showTooltip) return null
        if (this.tooltipName) return this.tooltipName
        if (this.mem && !this.mem.name && !this.mem.company_name) {
            return null
        }

        let str = ``
        if (this.mem && this.mem.company_name) {
            str += `${this.mem.company_name}\n`
        }
        if (this.mem && this.mem.name) str += `${this.mem.name}`
        if (this.tooltipWithEmail && this.mem && this.mem.email) {
            str += `\n${this.mem.email}`
        }
        return str
    }

    public showChangeImage() {
        if (!this.canEdit) return
        // $(".ChangeImageIcon").fadeIn(100).css("display", "inline-block")
        $(".ChangeImageIcon").fadeIn(100).css({ display: "block", top: "0" })
        this.$emit("mouseoverImage")
    }

    public hideChangeImage() {
        $(".ChangeImageIcon").fadeOut(60)
        this.$emit("mouseleaveImage")
    }

    public clickImage() {
        this.$emit("clickImage")
    }

    /**
     *
     */
    checkDrag(event, key, status) {
        if (!this.canEdit) return
        Logger(`event.dataTransfer.types: ${Util.output(event.dataTransfer.types)}`)
        // if (status && event.dataTransfer.types != "text/plain") {
        //     //ファイルではなく、html要素をドラッグしてきた時は処理を中止
        //     return false;
        // }
        this.isDrag = status ? key : null
        if (status) {
            this.showChangeImage()
            Logger(`isDrag: ${this.isDrag}`)
        } else {
            this.hideChangeImage()
        }
    }

    onDrop(event, key = "", image = {}) {
        if (!this.canEdit) return
        Logger(`ドロップしました。`)
        if (this.loading) {
            Notice.message = `アップロード中です。`
            return false
        }
        this.isDrag = null

        let params = UserFile.createFileParams(event, `public_image`)[0]
        if (!params) return

        params.append(`update_self`, `${this.updateSelf}`)

        this.loading = true
        this.rm.startProgress()

        RoomMember.uplaodUserImage(params).then(data => {
            event.target.value = null
            event = null
            this.loading = false
            this.rm.endProgress()
            if (data.result == `ok`) {
                Logger(`イメージを置き換えます : ${data.file.image_url}`)
                // this.rm.userInfo.image_url = data.image_url
                this.$emit("uploaded", data.file)
                // this.$emit("uploadedFile")
            }
        })
    }
}
