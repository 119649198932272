
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { Logger, copyToClipboard } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: {},
})
export default class CalendarActionLargeButtons extends Vue {
    // data:

    @Prop()
    appo

    rm = RoomManager

    groupTitle = ""
    isSP = isMobile(window.navigator).phone
    fixedTypes = [`fixed`, `already`]

    showTip = false
    notice = Notice
    Util = Util
    // showShareButton: boolean;

    location = ``

    public copyScheduleInfo() {
        if (!this.appo) return
        let text = ``
        if (this.appo.status == 10) {
            text = `■ ${this.appo.name}\n${this.appo.jp_format}(${this.appo.duration}分)\n場所: ${this.appo.location}`
        } else {
            text = `■ ${this.appo.name}\n${this.appo.duration}分\n場所: ${this.appo.location}`
        }
        // const text = `■ ${this.appo.name}\n${this.appo.jp_format}(${this.appo.duration}分)\n場所: ${this.appo.location}`;
        copyToClipboard(text)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`FIX クリップボードにコピー`, text)
    }

    public hideTooltip(e) {
        this.showTip = false
    }

    /**
     * グーグルカレンダーに追加します。
     */
    public addEventToGoogleCalendar() {
        if (!this.appo) return
        const url = CalendarUtil.createGoogleEvent(this.appo, this.rm.currentRoom.public_id)
        gtagClick(`FIX Googleカレンダー登録`, url)
        window.open(url, "_blank")
    }

    public addEventToO365Calendar() {
        if (!this.appo) return
        const url = CalendarUtil.createMSEvent(this.appo, this.rm.currentRoom.public_id, "office")
        gtagClick(`FIX Office365登録`, url)
        window.open(url, "_blank")
    }

    public addEventToOutlook() {
        if (!this.appo) return
        const url = CalendarUtil.createMSEvent(this.appo, this.rm.currentRoom.public_id, "outlook")
        gtagClick(`FIX Outlook登録`, url)
        window.open(url, "_blank")
    }

    public addEventToOutlookDL() {
        if (!this.appo) return
        gtagClick(`FIX Outlook iCS DL`, "")
        this.downloadICS()
    }

    public addEventToiCalDL() {
        if (!this.appo) return
        gtagClick(`FIX iCal iCS DL`, "")
        this.downloadICS()
    }

    public downloadICS() {
        Logger(`create ics.`)
        if (!this.rm.currentRoom) return

        CalendarUtil.createEvent(this.appo, this.rm.currentRoom.public_id, this.rm.currentRoom.attendees)
    }
}
