
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"
import { Dropdown } from "uiv"

// コンポーネントを読込.

// モデルを読込.
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: { Dropdown },
})
export default class AttendeesGroupFieldMenu extends Vue {
    @Prop()
    mag: MeetingAttendeesGroup

    @Prop()
    magindex: number

    @Prop()
    astag: AvailableScheduleTag

    @Prop() // normal / instant_room
    purposeType: string

    cm = CalendarManager
    isSP = isMobile(window.navigator).phone

    public mounted() {}

    public deleteGroup() {
        Logger(`グループごと削除します: ${this.mag.id}`)
        this.$emit("deleteGroup", this.mag)
    }

    public addProfile() {
        Logger(`公開プロフィールを設定します。`)
        this.$emit("addProfile", this.mag)
    }

    public addZoom() {
        this.$emit("addZoom", this.mag)
    }
}
