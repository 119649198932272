
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomMember from "packs/models/RoomMember"

@Options({
    components: { UserIcon },
})
export default class MailPreviewSenderIconContent extends Vue {
    @Prop()
    member: RoomMember

    @Prop()
    message: string

    // data
    isSP = isMobile(window.navigator).phone

    created() {}

    mounted() {}
}
