
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomStorage from "packs/models/RoomStorage"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

const roomStorage = new RoomStorage()

@Options({
    components: { AdLimitFree },
})
export default class PossibleDateControl extends Vue {
    // @Prop()
    // pd: PossibleDate
    @Prop() // キーボードの左右で日程を変更させるか.
    controlPressKey: boolean

    // data
    rm = RoomManager

    currentPd?: PossibleDate = null
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone

    pdm = PossibleDatesManager
    contentHeight = 300

    public created() {}

    public mounted() {
        this.updatePd()
        this.updateControlPressKey()
    }

    @Watch(`controlPressKey`)
    updateControlPressKey() {
        if (!this.controlPressKey) return

        window.addEventListener("keyup", ev => {
            // Logger(String.fromCharCode(ev.code))
            Logger(`press: ${ev.code}`)
            if (ev.code == `ArrowLeft`) {
                gtagClick(`FIX MODAL ←`, `${this.currentPd ? this.currentPd.jp_format : ``}`)
                this.showBeforePossibleDate()
            } else if (ev.code == `ArrowRight`) {
                gtagClick(`FIX MODAL →`, `${this.currentPd ? this.currentPd.jp_format : ``}`)
                this.showAfterPossibleDate()
            }
        })
    }

    // @Watch(`pd`, { deep: true })
    // public updateCurrentPd() {
    //     if (!this.pd) return
    //     this.currentPd = this.pd
    //     let pds = this.getCurrentPds()
    //     const num = this.checkPdIndex(pds)
    //     this.checkPdIndexType(pds, num)
    // }

    beforeUnmount() {
        window.removeEventListener("keyup", null)
    }

    @Watch("pdm.currentPd", { deep: true })
    public updatePd() {
        Logger(`FixScheduleModal : created ${Util.output(this.pdm.currentPd)}`)
        Logger(`FixScheduleModal: センタ中のmag ${Util.output(this.pdm.selectedMag)}`)
        this.currentPd = this.pdm.currentPd
        let pds = this.getCurrentPds()
        const num = this.checkPdIndex(pds)
        this.checkPdIndexType(pds, num)
        // this.adjustHeight()
        // if (this.from == `vote`) {
        //     this.pdm.canFix = true
        // }
    }

    public showBeforePossibleDate() {
        let pds = this.getCurrentPds()
        Logger(`before: pds length: ${pds.length}`)

        let num = this.checkPdIndex(pds)
        if (num >= 1) {
            num -= 1
            this.currentPd = pds[num]
            Logger(`pdを変更: ${Util.output(this.currentPd)}`)
            gtagClick(`FIX MODAL 前の予定を確認`, `${this.currentPd.jp_format}`)
            this.pdm.selectCurrentPd(this.currentPd)
        }
        this.checkPdIndexType(pds, num)
    }

    public showAfterPossibleDate() {
        let pds = this.getCurrentPds()
        Logger(`after: pds length: ${pds.length}`)
        let num = this.checkPdIndex(pds)
        Logger(`before num: ${num}, pds: ${Util.output(pds[num])}`)
        if (num >= 0) {
            if (pds.length > num + 1) {
                num += 1
                Logger(`After num: ${num}, pds: ${Util.output(pds[num])}`)
                this.currentPd = pds[num]
                Logger(`pdを変更: ${Util.output(this.currentPd)}`)
                gtagClick(`FIX MODAL 次の予定を確認`, `${this.currentPd.jp_format}`)
                this.pdm.selectCurrentPd(this.currentPd)
            }
        }
        this.checkPdIndexType(pds, num)
    }

    public checkPdIndex(pds: PossibleDate[]): number {
        return PossibleDate.checkPdIndex(pds, this.currentPd)
    }

    public checkPdIndexType(pds: PossibleDate[], num: number) {
        this.pdIndexType = PossibleDate.checkPdIndexType(pds, num)
    }

    /**
     * 今回利用するPossibleDate[]を3タイプの中から取得してきます。
     */
    public getCurrentPds(): PossibleDate[] {
        return this.pdm.getPdsAfterRemoved()
    }
}
