
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"
import { Dropdown } from "uiv"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({ components: { UserIcon, Dropdown } })
export default class CalendarIcon extends Vue {
    // data:
    @Prop()
    astag: AvailableScheduleTag

    @Prop()
    astagColor: string

    @Prop()
    width: number

    @Prop()
    iconWidth: number

    @Prop()
    totalHeight: number

    @Prop()
    canDelete: boolean

    @Prop()
    tooltipName: string

    @Prop()
    showTooltip: boolean

    @Prop()
    useLocationIcon: boolean

    @Prop()
    hideName: boolean

    @Prop()
    hideBorder: boolean

    @Prop()
    iconStyles: any

    @Prop()
    canEdit: boolean

    @Prop()
    classes: string

    @Prop()
    showSharedInfo: boolean

    loading = false
    isDrag: string = null
    fileForm: any = null

    Util = Util
    truncate = truncate

    public created() {}

    public mounted() {}

    get tooltipString() {
        if (!this.showTooltip) return ``
        if (this.tooltipName) return this.tooltipName

        return `${this.astag ? `${this.astag.name}<br>` : ``}`
    }

    public showChangeImage() {
        // $(".ChangeImageIcon").fadeIn(100).css("display", "inline-block")
        $(".ChangeImageIcon").fadeIn(100).css({ display: "block", top: "0" })
        this.$emit("mouseoverImage")
    }

    public hideChangeImage() {
        $(".ChangeImageIcon").fadeOut(60)
        this.$emit("mouseleaveImage")
    }

    public clickImage() {
        this.$emit("clickImage")
    }

    public deleteAstag() {
        this.$emit(`deleteAstag`, this.astag)
    }

    public clickAstag() {
        this.$emit(`clickAstag`, this.astag)
    }

    clickCopy() {
        this.$emit(`clickCopy`, this.astag)
    }

    changeDefault() {
        this.$emit(`changeDefault`, this.astag)
    }

    createVotedRoom() {
        this.$emit(`createVotedRoom`, this.astag)
    }

    clickEdit() {
        this.$emit(`clickEdit`, this.astag)
    }
}
