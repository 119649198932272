
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import MdRadioButtonTag from "packs/components/forms/MdRadioButtonTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"
import Notice from "packs/models/Notice"

@Options({
    components: {
        UserIcon,
        RoomsLoader,
        UserFileImage,
        MdRadioButtonTag,
        TextareaTag,
        CheckboxTag,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class ChangeMemberInfoModal extends Vue {
    @Prop()
    room: Room

    @Prop()
    member: RoomMember

    // data
    rm = RoomManager
    loading = false

    // 10 = member, 9 = optional
    attendeeType: number = null
    // fix, watch, none
    assignType = null

    Util = Util
    canShowSendFixMemberFlg = false
    sendMailFixMember = true
    message = ``
    // currentRoom: Room = null

    openModal() {
        gtagPage(`#/メンバー情報を変更`)
        this.updateRoomMember()
    }

    hide() {
        this.$vfm.close(`ChangeMemberInfoModal`)
    }

    @Watch(`member`, { deep: true })
    updateRoomMember() {
        if (!this.member) return

        this.assignType = this.member.assign_type
        this.attendeeType = this.member.role
        Logger(`ChangeMemberInfoModal#updateRoomMember assignType: ${this.assignType}, attendeeType: ${this.attendeeType}`)
    }

    selectAttendeeType(e) {
        Logger(`ChangeMemberInfoModal#selectAttendeeType ${e}`)
        let mem = { ...this.member }
        if (e == `require`) {
            this.attendeeType = RoomMember.MEMBER
        } else if (e == `option`) {
            this.attendeeType = RoomMember.MEMBER_OPTIONAL
        } else if (e == `observer`) {
            this.attendeeType = RoomMember.OBSERVER
        }
    }

    selectAssignType(e) {
        Logger(`ChangeMemberInfoModal#selectAssignType ${e}`)
        this.assignType = e
        if (e == `fix` && this.member.assign_type != `fix`) {
            // 日程決定者になったことを通知する
            this.canShowSendFixMemberFlg = true
        }
    }

    clickSendMailFixMember() {
        Logger(`clickSendMailFixMember`)
        if (this.sendMailFixMember) {
            this.sendMailFixMember = false
        } else {
            this.sendMailFixMember = true
        }
    }

    inputMessage(e) {
        this.message = e
    }

    send() {
        // 保存します.
        if (this.loading) return
        this.loading = true

        let mem = RoomMember.copy(this.member)
        mem.role = this.attendeeType
        mem.assign_type = this.assignType

        let sendMail = false

        RoomMember.changeMemberInfo(this.room, mem, sendMail, this.message).then(mem => {
            this.loading = false
            if (mem) {
                this.$emit(`updateMemberInfo`, mem)
                this.hide()
            }
        })
    }

    cancelInput() {
        // 保存せず閉じます.
        this.hide()
    }
}
