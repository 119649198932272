
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

// モデルを読込.

@Options({ components: { MdProgressSpinner } })
export default class IconRefresh extends Vue {
    @Prop()
    tooltipText: string

    @Prop()
    loading: boolean

    @Prop()
    showTooltip: boolean

    clickLoad() {
        this.$emit(`refresh`)
    }
}
