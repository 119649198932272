
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ModalInnerAvailableTime from "packs/pages/link/modals/ModalInnerAvailableTime.vue"
import AvailableDaysOfWeeksTableCell from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTableCell.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: { ModalInnerAvailableTime, AvailableDaysOfWeeksTableCell, IconHelp },
})
export default class AvailableDaysOfWeeksTable extends Vue {
    // @Prop()
    // astag: AvailableScheduleTag

    @Prop()
    showDeleteButton: boolean

    @Prop() // true: 絞り込みなし、 false: themeを表示しない.
    displayAllAdows: boolean

    @Prop({ default: "400" })
    width: string

    @Prop()
    hideTitle: boolean

    @Prop() // room_settings/user_settings
    from: string

    @Prop()
    defaultTimezoneValue: string

    selectTimeThemes: DateTag[] = null
    displayAdows: DateTag[] = null
    rm = RoomManager
    cm = CalendarManager
    rcm = RoomCreateManager

    // data:

    mounted() {
        this.updateUserSetting()
        this.updateRoomSetting()
        // this.createSelectTimeThemes()
    }

    @Watch(`rm.user_setting`)
    updateUserSetting() {
        this.updateDisplayAdows()
    }

    @Watch(`rcm.room_setting.available_days_of_weeks`, { deep: true })
    updateRoomSetting() {
        Logger(`${funcName()}`)
        this.updateDisplayAdows()
    }

    getRs() {
        let rs = null
        if (this.from == `room_settings`) {
            rs = { ...this.rcm.room_setting }
        } else if (this.from == `user_settings`) {
            rs = { ...this.rm.user_setting }
        }
        return rs
    }

    /**
     * 定期で登録したadowを削除します。
     */
    public deleteAdow(adow: DateTag) {
        Logger(`AvailableDaysOfWeeksTable.deleteAdow from: ${this.from}`)
        // this.$emit("deleteAdow", adow)
        let rs = this.getRs()
        let adows = rs.available_days_of_weeks || []
        adows = adows.filter(_ad => !DateTag.isSameAdow(_ad, adow))
        rs.available_days_of_weeks = adows
        if (this.from == `room_settings`) {
            this.rcm.updateRoomSetting(rs)
            if (this.cm && this.cm.rs) {
                this.cm.updateRoomSetting(rs)
            }
        }
        this.$emit(`deleteAdow`, adows)
    }

    updateDisplayAdows() {
        let rs = this.getRs()
        Logger(`displayAdows displayAllAdows: ${this.displayAllAdows}, adows: ${Util.output(rs.available_days_of_weeks)}`)
        if (this.displayAllAdows) {
            return rs.available_days_of_weeks
        }
        let adows = rs.available_days_of_weeks
        if (!adows) return
        let selectTimeThemes = DateTag.createSelectTimeThemes(adows)
        this.selectTimeThemes = selectTimeThemes
        let arr = adows.filter(av => av.type != `theme`)
        Logger(`displayAdows arr: ${Util.output(arr)}`)
        this.displayAdows = arr
        // return arr
    }

    // createSelectTimeThemes() {
    //     let rs = this.getRs()
    //     Logger(`createSelectTimeThemes from:${this.from} rs: ${Util.output(rs)}`)
    //     if (!rs) return
    //     const adows = rs.available_days_of_weeks || []
    //     let selectTimeThemes = DateTag.createSelectTimeThemes(adows)
    //     this.selectTimeThemes = selectTimeThemes
    // }

    public selectTheme(theme: DateTag) {
        Logger(`select theme::::`)
        gtagClick(`テーマ選択 ${theme.text}`, `${theme.select ? `✔あり→なし` : `✔なし→あり`}`)
        // if (!this.$emit("showConfrimResetSchedules", `adow`)) return false

        this.$emit("addAdow", theme)
        // this.addAdow(theme)
    }

    /**
     * AvailableScheduleTag内にあるavailable_days_of_weeksを追加する通知が子要素から来た場合の処理.
     */
    public updateAdow(_dtag: DateTag) {
        Logger(`${funcName()} _dtag:${Util.output(_dtag)}`)
        gtagClick(
            `カスタム設定 追加`,
            `adow: dow ${_dtag.days_of_the_week}, ${_dtag.start_hour}:${_dtag.start_min} ~ ${_dtag.end_hour}:${_dtag.end_min}`
        )

        this.$emit("addAdow", _dtag)
    }

    updateHoliday() {
        Logger(`${funcName()}`)
        this.$emit("updateHoliday", null)
    }

    get title() {
        if (this.rm && this.rm.didConnectCal) {
            return `調整可能時間を設定`
        }
        return `調整可能時間を定期で設定`
    }

    changeDefaultTimezone() {
        Logger(`${funcName()}`)
        this.$emit(`changeDefaultTimezone`)
    }
}
