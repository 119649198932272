import { DateTime } from "luxon"
import RoomMember from "./RoomMember"
import axios from "axios"
import { Logger, onlyUnique } from "packs/common"
import Util from "packs/utils/Util"
import AvailableScheduleTag from "./AvailableScheduleTag"
import PossibleDate from "./PossibleDate"
import Appointment from "./Appointment"
import Room from "./Room"
import Notice from "./Notice"
import MeetingAttendeesGroup from "./MeetingAttendeesGroup"
import RoomHistoryDetail from "./RoomHistoryDetail"
import NotificationControl from "packs/utils/NotificationControl"

interface RoomHistoryManager {
    has_next: boolean
    histories: any[]
    is_desc: boolean
    last_created_at: number
    last_id: string
    total: number
    currentRoomId: string
    histsDic: any
    resetData()
    getHistory(room: Room, isLoop: boolean)
}
let _RoomHistoryManager: RoomHistoryManager = {
    /**
     * 現在のPDM
     */
    has_next: true,
    histories: [],
    is_desc: false,
    last_created_at: null,
    last_id: null,
    total: -1,
    currentRoomId: ``,

    // TODO: ルームごとにhistoryを保持していく情報を RoomTabみたいにもたせる必要がある
    histsDic: {},

    resetData() {
        this.has_next = true
        this.histories = []
        this.last_created_at = null
        this.last_id = null
        this.histsDic = {}
    },

    getHistory(room: Room, isLoop: boolean) {
        if (!room) return
        if (room.room_type == `public_room`) return
        // this.currentRoom = room
        let params = { parent_room_id: room.id }
        if (this.last_id) {
            params[`last_id`] = this.last_id
            params[`last_created_at`] = this.last_created_at
        }
        // Getリクエストのパラムをオブジェクト化するオプション
        return axios
            .get(`${Util.prefixUrl}/room_histories`, { params: params })
            .then(res => {
                const _hists = res.data.histories
                let last_id = res.data.last_id
                if (last_id == this.last_id) {
                    // すでに代入済みなので、更新しません
                    return this.histories
                }
                this.has_next = res.data.has_next
                this.last_created_at = res.data.last_created_at
                this.last_id = last_id
                let room_id = res.data.parent_room_id
                Logger(`room_id: ${room_id}`)

                let hists = RoomHistoryDetail.fetchFromJson(_hists)

                if (this.currentRoomId == room_id && isLoop) {
                    // 連結
                    let beforeHists = this.histsDic[room_id] || []
                    Logger(`before hists: ${beforeHists.length}`)
                    Array.prototype.push.apply(beforeHists, hists)

                    Logger(`total: ${beforeHists.length}`)
                    this.histories = beforeHists
                } else {
                    // 新規
                    this.histories = hists
                }
                //
                Logger(`total hist: ${(this.histories || []).length}`)
                // beforeHists = beforeHists.filter(onlyUnique)
                this.histsDic[room_id] = [...this.histories]
                this.currentRoomId = room_id

                return hists
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    },
}
export default _RoomHistoryManager
