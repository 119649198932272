
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {},
})
export default class MailPreviewPartsCandidateDates extends Vue {
    @Prop()
    message: string

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    pds: PossibleDate[] = null
    selectedPds: PossibleDate[] = null
    pdm = PossibleDatesManager

    // lastAstagId: string = null // 最後にpdを叩いたastagIDと同じ場合叩かない.
    // lastDuration: number = null
    canFix: boolean = true

    Util = Util

    created() {}

    mounted() {
        this.getPossibles()
    }

    @Watch("rcm.appo", { deep: true })
    updateAppo() {
        this.showFixButton()
        // アポの仲介参加/不参加が変更になった場合に、pdを叩きます。
        this.getPossibles()
    }

    // タイプが変更されたときに、fill, overstart, overdue の場合、再度pdmsを取得しなおして表示.
    @Watch("pdm", { deep: true })
    public getPossibles() {
        Logger(`getPossibles:MailPreviewPartsCandidateDates ${Util.output(this.pdm.possibleDates)}`)
        if (!this.pdm) return
        if (!this.pdm.possibleDates) {
            Logger(`もっかい取得してきます.`)
            if (this.loading) return
            this.rm.startProgress()
            this.loading = true

            PossibleDate.getPossibleDates(this.rcm.astag, this.rcm.appo.duration).then(pdmDic => {
                this.loading = false
                Logger(`pdmを取得してきました。 ${Util.output(pdmDic.selected_pdm)}`)
                // this.pds = pdmDic.pdm
                this.pdm = pdmDic.pdm
                this.pdm.possibleDates = pdmDic.selected_pdm
                // this.selectedPds = pdmDic.selected_pdm
                this.rm.endProgress()
            })
        }
        if (!this.pdm.possibleDates) return

        this.pds = this.pdm.possibleDates

        let us = this.rm.user_setting
        let rs = this.rcm.room_setting
        let num = rs.email_suggests_num || us.email_suggests_num || 1
        Logger(`getPossibles:num: ${this.pds.length} ${num}`)
        this.selectedPds = [...this.pds].slice(0, num)
        Logger(`this.selectedPds: ${Util.output(this.selectedPds)}`)
    }

    @Watch("rcm.appo", { deep: true })
    showFixButton() {
        Logger(`showFixButton fix_type: ${this.rcm.appo.fix_type}`)
        if (!this.rcm.appo.fix_type) {
            this.canFix = false
            return
        }
        if (this.rcm.appo.fix_type == 100) {
            this.canFix = true
            return
        }
        if (this.rcm.appo.fix_type == 1) {
            this.canFix = false
            return
        }
        if (Util.isBlank(this.rcm.members) || this.rcm.members.length <= 1) {
            this.canFix = true
            return
        }

        let exceptOwner = this.rcm.members.filter(m => m.user_id != this.rm.userInfo.user_id)
        if (exceptOwner.length == 1) {
            this.canFix = true
        } else {
            this.canFix = false
        }
    }
}
