
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import RoomMembersMenuTr from "./RoomMembersMenuTr.vue"
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import HeaderControl from "packs/utils/HeaderControl"
import FormUtil from "packs/utils/FormUtil"

const roomStorage = new RoomStorage()
// declare var activeStatus: string; //focus=このページにきた, blur=はずれた
// var activeStatus = ``;

@Options({
    components: {
        RoomMembersMenuTr,
        AlertBadge,
        IconLock,
        UserIcon,
        Dropdown,
    },
})
export default class RoomMembersMenu extends Vue {
    // data:

    @Prop()
    room: Room

    @Prop()
    selectedTab: string // スマホタブの場合:  appo / schedule

    rm = RoomManager
    rooms: Room[] = null
    isSP = isMobile(window.navigator).phone
    Util = Util
    isPublic = Util.isPublic()
    truncate = truncate
    $store: any

    // スマホのときにタブで表示するメニュー
    headersMenu = [`apc`, `chat`]
    menuTabWidth = { width: `calc( 100% / ${this.headersMenu.length})` }

    // selectedTab = `schedule` // スマホタブの場合:  appo / schedule

    beforeCreate() {
        let status = this.$store.state.activeStatus
        if (status.length == 0) this.$store.dispatch("start")
    }

    mounted() {
        this.updateRoom()
    }

    @Watch(`room`)
    updateRoom() {
        Logger(`RoomMembersMenu#updateRoom`)
        if (!this.room || !this.room.room_setting) return

        let useChat = this.room.room_setting.use_chat
        if (useChat) {
            if (!this.headersMenu.includes(`chat`)) {
                this.headersMenu.push(`chat`)
            }
        } else {
            this.headersMenu = this.headersMenu.filter(m => m != `chat`)
        }
        if (this.rm.designType() == `seminar`) {
            this.headersMenu.push(`schedule`)
        }
        this.updateMenuTabWidth()
        this.rm.spHeadersMenu = this.headersMenu

        // if (this.headersMenu.includes(`apc`)) {
        //     this.selectedTab = `apc`
        // }
        this.rm.spSelectTab = this.selectedTab
        this.showChat
    }

    updateMenuTabWidth() {
        this.menuTabWidth = { width: `calc( 100% / ${this.headersMenu.length})` }
    }

    get showChat(): boolean {
        if (!this.room) return false
        if (!this.room.room_setting) return false
        if (Util.isEmbeds()) return false

        return this.room.room_setting.use_chat
    }

    @Emit("selectedRoom")
    selectMessageRoom(selectedRoom: Room) {
        gtagClick(`チャット選択`, `${selectedRoom.isParent ? `全体` : `個別`}`)
        return selectedRoom
    }

    @Watch("room", { deep: true })
    public updateCurrentRooms() {
        if (Util.isBlank(this.room)) return
        let rooms = [this.room]
        if (this.isPublic) {
            this.rooms = rooms
            return
        }
        // let status = roomStorage.fetchMessageWindowStatus();
        Array.prototype.push.apply(rooms, this.room.children)
        Logger(`${funcName()} roomlength:${rooms.length}`)

        // 今開いているルームにバッジが来ていたら更新して消します。

        let currentRoom = Room.find(rooms, "#", this.rm.currentMessageRoom.room_id)
        if (currentRoom && this.$store.state.activeStatus != `blur`) {
            currentRoom.unread_num = 0
        }
        HeaderControl.countUnreadNumAndUpdateTitle(rooms)

        this.rooms = rooms
    }

    /**
     * スマホで、調整内容ボタンを押下した場合の処理.
     * tabType: appo / schedule
     * appo: CardViewを開く
     * schedule: 日程調整のカレンダーを開く
     */
    public showSchedule(tabType: string) {
        gtagClick(`スマホ調整ページタブ:${tabType}`, tabType)
        // this.selectedTab = tabType
        this.$emit("selectTab", tabType)
    }

    /**
     * 子ルームを含めて、未読メッセージがないか検証します。
     * 未読がある場合、1を返す（バッジに数字を表示するわけでないので、これでOK）
     */
    get doesUnreads(): number {
        if (!this.room) return
        if (this.room.unread_num > 0) return 1
        if (!this.room.children) return
        if (this.room.children.find(c => c.unread_num > 0)) return 1
        return 0
    }

    showSPHeader() {
        let additionalParams: any = FormUtil.getParams()
        if (additionalParams) {
            if (additionalParams.hide_header == `true`) {
                return false
            }
            if (additionalParams.hide_appointment_info == `true`) {
                return false
            }
            if (additionalParams.hm == `true`) {
                return false
            }
            if (additionalParams.hide_main == `true`) {
                return false
            }
        }

        if (this.isSP) {
            if (this.headersMenu.length <= 1) return false
            return true
        }
        return false
    }
}
