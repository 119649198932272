
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import ConsiderationTravelMode from "packs/pages/link/parts/available_schedule/ConsiderationTravelMode.vue"
import OptionalConnectedCalendar from "packs/pages/link/parts/available_schedule/OptionalConnectedCalendar.vue"
import AssignMagContent from "packs/pages/link/parts/available_schedule/AssignMagContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import InstantRoomGroupConnectField from "packs/pages/link/parts/room_settings/InstantRoomGroupConnectField.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import InstantRoomAssignedUser from "packs/models/InstantRoomAssignedUser"
import { DateTime } from "luxon"

@Options({
    components: {
        MdTextfieldTag,
        SelectTag,
        ConsiderationTravelMode,
        OptionalConnectedCalendar,
        AssignMagContent,
        CheckboxTag,
        UserIcon,
        InstantRoomGroupConnectField,
    },
})
export default class RoomSettingsInstantRoom extends Vue {
    @Prop() // func
    updateRoomSetting

    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting
    cm = CalendarManager

    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    timeArray = SelectUtil.timeArray
    notice = Notice
    members: RoomMember[] = []
    assignedMembers: InstantRoomAssignedUser[] = []
    systemUpdatedAt = Util.getSec()

    mags: MeetingAttendeesGroup[] = null

    mounted() {
        if (this.rcm.astag) {
            let mags = this.rcm.astag.meeting_attendees_groups
            this.mags = mags || []
        }

        if (this.rcm.room && this.rcm.room.id && this.rcm.room.id != `newId`) {
            InstantRoomAssignedUser.getActiveStatusesInRoom(this.rcm.room.id).then(e => {
                if (e) {
                    this.rcm.room.instant_room_assigned_users = e.assigned_users
                    this.setAssignedMembers()
                }
            })
        } else {
            this.setAssignedMembers()
        }

        this.getUserList()
    }

    setAssignedMembers() {
        // let assignedMembers = Array((this.mags || ["a"]).length)
        let magsNum = (this.mags || ["a"]).length
        let assignedNum = (this.rcm.room.instant_room_assigned_users || []).length
        let assignedMembers = assignedNum > magsNum ? Array(assignedNum) : Array(magsNum)

        if (assignedMembers.length > magsNum) {
            let mags = [...this.mags]
            let sabun = assignedMembers.length - magsNum

            let addArr = Array(sabun)
            for (let _ of addArr) {
                let mag = MeetingAttendeesGroup.createDefault()
                mags.push(mag)
            }
            mags = MeetingAttendeesGroup.addNames(mags)
            this.mags = mags
        }

        for (let userMinimumInfo of this.rcm.room.instant_room_assigned_users || []) {
            if (userMinimumInfo.meeting_attendees_group_id) {
                let index = this.mags.findIndex(m => m.id == userMinimumInfo.meeting_attendees_group_id)
                assignedMembers[index] = userMinimumInfo
            } else {
                assignedMembers[userMinimumInfo.index_num] = userMinimumInfo
            }
        }
        this.assignedMembers = assignedMembers
    }

    // 同一UGのメンバーを取得します.
    getUserList() {
        RoomMember.getUserList().then(list => {
            this.members = list
        })
    }

    // showDetail = false

    inputNumberOfBookings(e) {
        this.rcm.room.max_bookings_num = +e
        this.rcm.updateRoom(this.rcm.room)
    }

    blurNumberOfBookings() {
        if (this.rcm.room.max_bookings_num < 1) {
            this.rcm.room.max_bookings_num = 1
        }
        if (this.rcm.room.max_bookings_num > 999) {
            this.rcm.room.max_bookings_num = 999
        }
        this.rcm.updateRoom(this.rcm.room)
    }

    clickChangeTimezone() {
        Logger(`${funcName()} can_change_timezone:${this.rcm.room_setting.can_change_timezone}`)
        if (this.rcm.room_setting.can_change_timezone) {
            this.rcm.room_setting.can_change_timezone = false
        } else {
            this.rcm.room_setting.can_change_timezone = true
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    connectMember(mem: RoomMember, mag: MeetingAttendeesGroup, magindex: number) {
        Logger(`${funcName()} mem:${mem}, magindex:${magindex}`)
        if (Util.isBlank(mem)) {
            // 連携を解除します.
            this.assignedMembers[magindex] = null
        } else {
            if (mag) {
                this.assignedMembers[magindex] = InstantRoomAssignedUser.createDefault(mem, magindex, mag.id)
            } else {
                this.assignedMembers[magindex] = InstantRoomAssignedUser.createDefault(mem, magindex, null)
            }
        }

        this.rcm.room.instant_room_assigned_users = this.assignedMembers
        this.rcm.updateRoom(this.rcm.room)
        this.systemUpdatedAt = Util.getSec()
    }

    changeActive(assignedUser: InstantRoomAssignedUser) {
        if (!assignedUser) return

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        let mem = this.assignedMembers[assignedUser.index_num]

        InstantRoomAssignedUser.willChangeStatusForOwner(`each`, this.rcm.room.id, mem.is_active, [mem]).then(res => {
            this.rm.endProgress()
            Logger(`res:${Util.output(res)}`)
            this.loading = false
            let assigned_users = res.assigned_users || ([] as InstantRoomAssignedUser[])
            if (Util.isPresent(assigned_users)) {
                mem.is_active = assignedUser.is_active
                this.rcm.room.instant_room_assigned_users = this.assignedMembers
                this.rcm.updateRoom(this.rcm.room)

                for (let asu of assigned_users) {
                    let lau = InstantRoomAssignedUser.findFromRoom(this.rcm.room, asu.line_instant_room_assigned_user_id)
                    if (Util.isPresent(lau)) {
                        lau.is_active = asu.is_active
                    }
                }
                this.systemUpdatedAt = Util.getSec()
            }
        })
    }

    changeActiveAll() {}

    addNextField() {
        Logger(`${funcName()}`)
        // this.$emit("addNextGroupField")
        let mag = MeetingAttendeesGroup.createDefault(`instant_room`)
        let mags = [...(this.mags || [])]
        mags.push(mag)
        mags = MeetingAttendeesGroup.addNames(mags)
        this.mags = mags
        // let mem = InstantRoomAssignedUser.createDefault()
        // this.assignedMembers.push(mem)
        // this.rcm.room.instant_room_assigned_users
        // this.systemUpdatedAt = Util.getSec()
    }

    get showAddMemberButton() {
        if (!this.rcm.astag) return
        if (this.rcm.astag.can_connect_calendar) return false
        return true
    }
}
