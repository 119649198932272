
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, Logger } from "packs/common"

// コンポーネントを読込.
import AddCommentToMail from "packs/pages/link/parts/room_settings/AddCommentToMail.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import SpanSlotContent from "packs/components/common/SpanSlotContent.vue"
import CustomMailFlowList from "packs/pages/link/parts/room_settings/mail_parts/CustomMailFlowList.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomStorage from "packs/models/RoomStorage"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import MailFlow from "packs/models/MailFlow"
import SelectUtil from "packs/utils/SelectUtil"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AddCommentToMail,
        Switcher,
        MdTextfieldTag,
        SelectTag,
        IconHelp,
        SpanSlotContent,
        CustomMailFlowList,
        CheckboxTag,
    },
})
export default class MailEditDetailContent extends Vue {
    // data:

    @Prop()
    tmp: MessageTemplate // templateのpublic_id

    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone

    // 今回利用するテンプレ.
    localTmp: MessageTemplate = null
    memo = ``
    selectedTabName = this.rcm.room && this.rcm.room.room_type == `parent_room` ? `create_room` : `inquire_on_public_room`

    // テンプレ更新ボタンの表示
    showUpdateTmpButton = false

    currentAppo: Appointment = null

    parentTimingArray = Util.timingsDicArray(`parent_room`)
    publicTimingArray = Util.timingsDicArray(`public_room`)
    sendTimingArray = null
    showSwitchers = false

    SelectUtil = SelectUtil

    // 設定したカスタム通知内容
    customMailFlows = []

    // 紐付ける公開ページの公開ID
    publicId = null

    loading = false
    selectedRoom: Room = null

    mounted() {
        // this.currentAppo = roomStorage.fetchAppointment()
        this.updateMembers()
        this.setPublicRooms()
    }

    public inputMemo(e: string, tmpId: string) {
        Logger(`inputMemo from AddCommentToMail(${e})`)
        let _mf = this.rcm.selectedMailFlow

        if (_mf.template_public_id != tmpId) {
            _mf.template_public_id = tmpId
        }

        if (_mf) {
            _mf.content = e
        }

        this.rcm.updateMailFlow(_mf)

        // room_settingのmail_flowsの選択中のタブのコンテントに入れる.
    }

    inputSubject(e: string, tmp: MessageTemplate) {
        // room_settingのmail_flowsの選択中のタブのサブジェクトに入れる.
        let _mf = this.rcm.selectedMailFlow
        if (_mf) {
            // let _mf = this.rcm.selectedMailFlow
            _mf.subject = e
        }
        this.rcm.updateMailFlow(_mf)
    }

    inputSmsContent(e: string) {
        Logger(`${funcName()} e:${e}`)
        let _mf = this.rcm.selectedMailFlow
        if (_mf) {
            // let _mf = this.rcm.selectedMailFlow
            _mf.sms_content = e
        }
        this.rcm.updateMailFlow(_mf)
    }

    @Watch("rcm.members", { deep: true })
    updateMembers() {
        if (Util.isBlank(this.rcm.members)) return
        Logger(`メンバーを更新するとテンプレも更新します`)
        this.showUpdateTmpButton = true
        this.updateTmpId()
    }

    fetchActiveStatus(timingArray: any[]) {
        for (let _ti of timingArray) {
            let _mf = this.rcm.mail_flows.find(m => m.def_type == _ti.type)
            if (_mf) {
                _ti.is_active = _mf.is_active
            }
        }
        return timingArray
    }

    @Watch(`tmp`, { deep: true })
    updateTmpId() {
        if (!this.tmp) return
        Logger(`tmpを更新しました addCommentToMail: ${this.tmp.public_id}`)

        if (Util.isBlank(this.localTmp) || this.tmp.public_id != this.localTmp.public_id) {
            this.localTmp = this.tmp
            this.updateTmp()
        }
    }

    inputPublicID(e) {
        Logger(`${funcName()} e:${e}`)
        this.publicId = e
    }

    clickConnectPublicPage() {
        if (this.loading) return
        this.loading = true

        this.rm.startProgress()
        Room.search([this.publicId], this.rm.userInfo.user_id, null, null, null, null, null).then(rooms => {
            if (Util.isPresent(rooms)) {
                this.selectedRoom = rooms[0]
                let _mf = this.rcm.selectedMailFlow
                _mf.button_public_room_id = this.selectedRoom.id
                this.rcm.updateMailFlow(_mf)
            } else {
                Notice.message = `公開ページが見つかりませんでした。`
            }
            this.rm.endProgress()
            this.loading = false
        })
    }

    @Watch("rm.userInfo")
    setPublicRooms() {
        if (!this.rm.userInfo) return

        let _mf = this.rcm.selectedMailFlow
        let roomId = _mf.button_public_room_id
        if (Util.isBlank(roomId)) return
        if (this.selectedRoom && this.selectedRoom.id == roomId) return

        Room.getPublicRoom(roomId, this.rm).then(_room => {
            if (_room) {
                this.selectedRoom = _room
                this.publicId = _room.public_id
                _mf.button_public_room_id = _room.id
                this.rcm.updateMailFlow(_mf)
            }
        })
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
        this.$vfm.open("ModalTemplates")
    }

    /**
     * 使うテンプレを決定.
     *
     */
    public decideTemplate(tmp: MessageTemplate) {
        //
        this.localTmp = tmp
        this.updateTmp()
    }

    public updateTmp() {
        // テンプレを最新のユーザー/アポの内容をはめて更新します。
        if (!this.localTmp) return
        // let atts = this.rcm.members.filter(m => m.user_id != this.rm.userInfo.user_id)
        // let owner = this.rcm.members.find(m => m.role == 100) || this.rm.userInfo
        // let str = TemplateUtil.insertTemplateText(this.localTmp.content, atts, owner, this.rcm.appo, this.rm.userInfo)
        // let subj = TemplateUtil.insertTemplateText(this.localTmp.subject, atts, owner, this.rcm.appo, this.rm.userInfo)

        this.inputMemo(this.localTmp.content, this.localTmp.public_id)
        this.inputSubject(this.localTmp.subject, this.localTmp)
        // this.showUpdateTmpButton = false
    }

    public selectCategoryTab(tabName: string) {
        gtagClick(`mailTab`, `${tabName}`)
        this.selectedTabName = tabName
        if ([`create_room`, `inquire_on_public_room`, `fix`, `fixed_appointment`].includes(tabName)) {
            this.showSwitchers = false
        }
        if (tabName == `settings`) {
            this.showSwitchers = true
            return
        }

        let _mfs = this.rcm.mail_flows
        let _mf = _mfs.find(_mf => _mf.def_type == tabName)
        this.rcm.selectedMailFlow = _mf
    }

    public changeActiveMailFlow() {
        let _mf = this.rcm.selectedMailFlow
        if (_mf.is_active) {
            _mf.is_active = false
        } else {
            _mf.is_active = true
        }
        this.sendTimingArray = this.fetchActiveStatus([...this.sendTimingArray])
        this.rcm.updateMailFlow(_mf)
    }

    public inputSendTiming(e) {
        let _mf = this.rcm.selectedMailFlow
        _mf.send_timing = e as number
        this.rcm.updateMailFlow(_mf)
    }

    public inputButtonName(e) {
        let _mf = this.rcm.selectedMailFlow
        _mf.button_name = e
        this.rcm.updateMailFlow(_mf)
    }

    public inputButtonLink(e) {
        let _mf = this.rcm.selectedMailFlow
        _mf.button_link = e
        this.rcm.updateMailFlow(_mf)
    }

    checkSendTiming() {
        let _mf = this.rcm.selectedMailFlow
        Logger(`${funcName()} ${_mf.send_timing} `)

        if (_mf.send_timing < 0) {
            _mf.send_timing = 0
            Notice.message = `マイナスは入力できません。`
        } else if (_mf.send_timing > 999) {
            _mf.send_timing = 999
            Notice.message = `1000以上は入力できません。`
        }
        this.rcm.updateMailFlow(_mf)
    }

    changeShowQA() {
        Logger(`changeShowQA: `)
        if (!this.rcm.selectedMailFlow) return
        let _mf = this.rcm.selectedMailFlow

        if (_mf.show_qa) {
            _mf.show_qa = false
        } else {
            _mf.show_qa = true
        }
        this.rcm.updateMailFlow(_mf)
    }

    public deselect() {
        Logger(`選択した公開ページFEEDBACKをはずします.`)

        let _mf = this.rcm.selectedMailFlow
        _mf.button_public_room_id = null
        this.selectedRoom = null
        this.publicId = null
        this.rcm.updateMailFlow(_mf)
    }

    get getDescription(): string {
        let _mf = this.rcm.selectedMailFlow
        if (_mf.def_type == `create_room`) {
            return `この調整ページが作成された際に送信されるメール内容です。`
        } else if (_mf.def_type == `fixed_appointment`) {
            return `日程が確定された際に送信されるメール内容です。フォーム送信時に日程も確定した場合は、こちらの内容が送られます。`
        } else if (_mf.def_type == `inquire_on_public_room`) {
            return `フォームの入力が終わった際に送信される完了メールの内容です。フォーム送信時に日程も同時に確定した場合は、こちらの内容は送られません。`
        } else if (_mf.def_type == `feedback`) {
            return `開催日当日に送られるアンケートメールです。イベント開始後の日時で自動で送信されます。アンケート用のURLやアンケートフォームとして利用する公開ページを紐付けてください。`
        } else if (_mf.def_type == `the_day_before`) {
            return `開催日前日に送られる自動リマインドメールの内容です。開催日前日の16時に自動送信されます。`
        } else if (_mf.def_type == `one_day_before_deadline`) {
            return `有効期限前日に送られるメールの内容です。日程が確定していない場合で、調整ページのやりとりが10日間ない場合に送られます。`
        } else if (_mf.def_type == `custom`) {
            return `好きなタイミングで送信する内容を作成できます。`
        }
        return ``
    }

    get defaultTimingTypeText() {
        if (!this.rcm.selectedMailFlow) return `イベント開始`
        let mf = this.rcm.selectedMailFlow
        let timing = mf.timing_type

        let typeDic = SelectUtil.selectMailFlowTimingTypes.find(tt => tt.type == timing)
        Logger(`${funcName()} timing:${timing} typeDic:${Util.output(typeDic)}`)
        if (typeDic) {
            return typeDic.name
        }
        return `イベント開始`
    }

    selectTimingType(name: string) {
        Logger(`${funcName()} name:${name}`)
        if (!this.rcm.selectedMailFlow) return

        let mf = this.rcm.selectedMailFlow
        let typeDic = SelectUtil.selectMailFlowTimingTypes.find(tt => tt.name == name)
        if (typeDic) {
            mf.timing_type = typeDic.type
            this.rcm.updateMailFlow(mf)
        }
    }

    updateCustomTimingMin(mins: number) {
        Logger(`${funcName()} mins:${mins}`)
        if (!this.rcm.selectedMailFlow) return

        let mf = this.rcm.selectedMailFlow
        mf.send_timing = mins
        this.rcm.updateMailFlow(mf)
    }

    selectAvailableSpan(name: string) {
        Logger(`${funcName()} name:${name}`)
        if (!this.rcm.selectedMailFlow) return

        let mf = this.rcm.selectedMailFlow

        if (name == `前に送信`) {
            mf.send_timing_around = `before`
        } else {
            mf.send_timing_around = `after`
        }
        this.rcm.updateMailFlow(mf)
    }

    changeDisplayLinkButton() {
        Logger(`${funcName()}`)
        if (!this.rcm.selectedMailFlow) return
        let _mf = this.rcm.selectedMailFlow

        if (_mf.display_parent_room_link_button) {
            _mf.display_parent_room_link_button = false
        } else {
            _mf.display_parent_room_link_button = true
        }
        this.rcm.updateMailFlow(_mf)
    }

    /**
     * @param dic [any] {value: string, checked: boolean}
     */
    clickSendTo(dic: any) {
        Logger(`${funcName()} val:${dic.value}, checked:${dic.checked}`)
        let sendTo = this.rcm.selectedMailFlow.send_to
        if (dic.checked) {
            //
            sendTo.push(dic.value)
        } else {
            //
            if (sendTo.includes(`all`)) {
                sendTo = [`owner`, `invitees`, `selected_mag`]
            }
            sendTo = sendTo.filter(s => s != dic.value)
        }
        this.rcm.selectedMailFlow.send_to = sendTo
        Logger(`${funcName()} 保存するsendTo:${Util.output(sendTo)}`)
        this.rcm.updateMailFlow(this.rcm.selectedMailFlow)
    }

    checkAllSendTo() {
        this.rcm.selectedMailFlow.send_to = ["all"]
        this.rcm.updateMailFlow(this.rcm.selectedMailFlow)
    }
}
