
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import HeaderControl from "packs/utils/HeaderControl"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"

// コンポーネントを読込.
import RoomCell from "packs/pages/link/parts/RoomCell.vue"

// モデルを読込.
import Room from "packs/models/Room"
import CalendarUtil from "packs/utils/CalendarUtil"
import RoomMember from "packs/models/RoomMember"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"

@Options({
    components: {
        RoomCell,
    },
})
export default class FixTabRoomCellsContent extends Vue {
    // data:
    @Prop()
    currentTabRooms

    @Prop() // func
    showRoomModal

    fixTabRoomsByDay: { [key: string]: Room[] } = {} // {"0619": Room[]}

    mounted() {
        this.generateFixTabRooms()
    }

    @Watch(`currentTabRooms`)
    public generateFixTabRooms() {
        Logger(`${funcName()} FixTab用の日付まとめを作成`)
        if (Util.isBlank(this.currentTabRooms)) return

        let dic = {}
        for (let room of this.currentTabRooms) {
            let appo = room.current_appointment
            let dateId = CalendarUtil.toJpFormatDate(appo.start_time)
            let rooms = dic[dateId] || []
            rooms.push(room)
            dic[dateId] = rooms
        }
        this.fixTabRoomsByDay = dic
    }

    get currentTabLength() {
        return (this.currentTabRooms || []).length
    }

    currentRoomNum(roomId: string) {
        let roomIds = this.currentTabRooms.map(r => r.room_id)
        return roomIds.indexOf(roomId)
    }

    goRoomFromTr(room: Room) {
        this.$emit(`goRoom`, room)
    }

    clickSummary(room: Room) {
        this.$emit(`clickSummary`, room)
    }

    showMembersLinks(members, room) {
        this.$emit(`showMembersLinks`, members, room)
    }

    showPublicLink(room: Room) {
        this.$emit(`showPublicLink`, room)
    }

    cancelVoted(room: Room) {
        this.$emit(`cancelVoted`, room)
    }

    cancelAppo(room: Room) {
        this.$emit(`cancelAppo`, room)
    }

    createFeedback(room: Room) {
        this.$emit(`createFeedback`, room)
    }

    changeActive(room: Room) {
        this.$emit(`changeActive`, room)
    }

    searchByMember(mem: RoomMember) {
        this.$emit(`searchByMember`, mem)
    }

    searchByMag(mag: MeetingAttendeesGroup) {
        this.$emit(`searchByMag`, mag)
    }

    clickFormMember(mem: RoomMember, room: Room) {
        this.$emit(`clickFormMember`, mem, room)
    }

    editPrivateMemo() {
        this.$emit(`editPrivateMemo`)
    }
}
