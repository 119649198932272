
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { decodeQueryParam, funcName, Logger, sleep } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagEvent } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import OneMessageContent from "./OneMessageContent.vue"
import MessagesLoader from "packs/components/loader/MessagesLoader.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import AdRegistrationModal from "packs/components/advertise/AdRegistrationModal.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import EmailNameFieldsModal from "packs/pages/link/modals/EmailNameFieldsModal.vue"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import TemplateUtil from "packs/utils/TemplateUtil"
import MessageTemplate from "packs/models/MessageTemplate"
import UserFile from "packs/models/UserFile"
import RoomMember from "packs/models/RoomMember"
import FormUtil from "packs/utils/FormUtil"
import Refresher from "packs/models/Refresher"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        OneMessageContent,
        MessagesLoader,
        ModalTemplates,
        AdRegistrationModal,
        UserFileImage,
        EmailNameFieldsModal,
        MdProgressBar,
    },
})
export default class RoomMessages extends Vue {
    @Prop()
    isDev

    @Prop()
    isSuspend: boolean

    // data:
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    Util = Util
    refresher = Refresher

    dtag = null
    $cable: any

    hold = "bottom" // "bottom", "top" bottomなら、スクロールボトム、トップならスクロールボトムはさせない
    addScrollEvent = false

    tmp: MessageTemplate = null

    Const = Const

    loading = false
    isDrag: string = null
    fileForm: any = null
    hasAttachment = false
    userFiles: UserFile[] = [] // メッセージに添付するファイル.

    currentFile: UserFile = null // プレビューに表示する選択中のファイル.
    currentMessages: RoomMessage[] = null
    additionalParams = null

    public created() {}

    public updated() {
        Logger(`this.hold: ${this.hold}`)
        if (this.hold == "bottom") {
            this.scrollToBottom()
        }

        this.typedMessage = this.typedMessage.replace(/^\s+/, "")

        if (!this.addScrollEvent) {
            var container = document.getElementById("messageArea")
            if (container) {
                container.addEventListener("scroll", this.handleScroll)
                this.addScrollEvent = true
            }
        }
    }

    public async mounted() {
        // 最新のメッセージが表示されます。
        // this.hold = "bottom";
        this.fetchMessages()
        this.scrollToBottom()
    }

    public destroyed() {
        Logger(`未送信メッセージを削除します。`)
        roomStorage.deleteUnsentMessagesAll()
    }

    /**
     * スクロールを検知して、ページネーションに利用します。
     */
    public handleScroll() {
        if (!this.$el) return
        let container = this.$el.querySelector(".messageArea")

        if (container) {
            // scrollHeight すべての高さ
            const height = container.scrollHeight ? container.scrollHeight : 0
            const currentHeight = container.scrollTop
            if (currentHeight == 0) {
                this.hold = "top"
                Logger(`change to top.`)
            } else if (height - currentHeight < 400) {
                this.hold = "bottom"
                Logger(`change to bottom.`)
            } else {
                this.hold = ""
            }
        }
    }

    // スマホでメッセージを入力する場合はモーダルを表示します。
    public inputStart() {
        this.$vfm.open("InputMessageModal")
    }

    public sendFromModal(msg) {
        this.typedMessage = msg

        this.send()
    }

    public inputMessage(e) {
        Logger(`inputMessage: ${e}`)
        let count = (e.match(/\n/g) || []).length
        // Logger(`改行数: ${count}`);
        this.typedMessage = e
        this.saveInput()
    }

    @Watch(`rm.memberDicPerRoom`, { deep: true })
    public updateReadCount() {
        // const memberDic = this.getMemberDic();
        this.currentMessages = this.getCurrentMessages()
        Logger(`未読を既読にアップデートします RoomMessages: ${Util.output(this.rm.memberDicPerRoom)}`)
    }

    // 改行数によってtextareaの高さを変更します。
    @Watch("typedMessage")
    public changeHeight() {
        if (this.isSP) return

        let breakCount = (this.typedMessage.match(/\n/g) || []).length
        let lines = this.typedMessage.split("\n")
        breakCount += lines.filter(l => Math.floor(l.length / 20)).length
        const defaultHeight = 56
        let enteredHeight = defaultHeight + 22 * breakCount
        $(".responseArea").css({
            height: `${enteredHeight}px`,
            "min-height": `123px`,
            "max-height": `352px`,
        })
        $("#messageText").css({
            height: `${enteredHeight - 42}px`,
            "min-height": `80px`,
            "max-height": `310px`,
        })
    }

    /**
     * 1. メッセージを作成, 2. ローカルストレージに保存, 3. チャット欄に追加します。
     */
    public configureMessage() {
        // いったんストレージに保存し、送信できた場合は受信したタイミングでストレージから削除します。
        // リフレッシュされたタイミングで未送信のストレージのメッセージは削除します。
        const currentMes = RoomMessage.createUnsentMessage(
            this.rm.userInfo,
            this.typedMessage,
            this.rm.currentMessageRoom.room_id
        )
        if (this.hasAttachment) currentMes.setFileIds(this.userFiles)
        roomStorage.saveUnsentMessages(this.rm.currentMessageRoom.room_id, currentMes)
        Logger(`新規のメッセージ: ${Util.output(currentMes)}`)

        Logger(`pushする先のroom: ${Util.output(this.currentMessages)}`)
        let _messages = this.getCurrentMessages()
        if (Util.isBlank(_messages)) {
            this.currentMessages = [currentMes]
        } else {
            _messages.push(currentMes)
        }
        return currentMes
    }

    public send() {
        Logger(`RoomMessages.send: ${this.typedMessage}, rm.currentMessageRoom: ${Util.output(this.rm.currentMessageRoom)}`)
        if ((this.typedMessage || "").trim().length == 0 && !this.hasAttachment) {
            return
        }

        const parentRoomId = this.getParentId()

        if (Util.isPublic()) {
            Logger(`send:userInfo: ${Util.output(this.rm.userInfo)}`)
            let params = FormUtil.getParams()
            let mem = RoomMember.createDefaultClient()
            if (Util.isPresent(params)) {
                if (Util.isPresent(params[`name`])) {
                    mem.name = decodeQueryParam(params[`name`])
                }
                if (Util.isPresent(params[`email`])) {
                    mem.email = decodeQueryParam(params[`email`])
                }
                if (mem.name || mem.email) {
                    this.rm.userInfo = mem
                }
            }

            if (Util.isBlank(this.rm.userInfo) || !this.rm.userInfo.email || !this.rm.userInfo.name) {
                Logger(`メールアドレスと名前を入力してもらう`)
                this.openEmailNameFieldsModal()
                return
            } else {
                if (this.loading) return
                this.loading = true
                RoomMessage.startChat(this.rm.userInfo, this.rm.currentMessageRoom.id, this.typedMessage).then(dic => {
                    this.loading = false
                    if (Util.isPresent(dic)) this.sentEmailName(dic)
                })
                return
            }
        }

        this.typedMessage = `${this.typedMessage}`

        Logger(`送信 : ${this.typedMessage}`)
        gtagClick(`send`, `${this.typedMessage}, room: ${this.rm.currentMessageRoom.room_id} PERFORM`)

        if (Util.isBlank(this.rm.wsConnection) || Util.isBlank(this.rm.cable)) {
            Logger(`wsConnectionがはられていないので、はります:`)
            this.rm.setupWebSocket()
            sleep(1000).then(_ => {
                // 再送信する.
                this.send()
            })
            return
        }
        const currentMes = this.configureMessage()

        Logger(
            `speak: message: ${this.typedMessage}, room_id: ${this.rm.currentMessageRoom.room_id}, isDev: ${this.isDev}, parent_id: ${parentRoomId}, file_ids: ${currentMes.file_ids}`
        )

        this.rm.wsConnection.perform("speak", {
            message: this.typedMessage,
            room_id: this.rm.currentMessageRoom.room_id,
            isDev: this.isDev,
            parent_id: parentRoomId,
            file_ids: currentMes.file_ids,
            owner_ug_id: Room.getOwnerUgId(this.rm.currentRoom),
        })
        // 送信後削除.
        this.hold = "bottom"
        this.scrollToBottom()
        this.resetMessage()
        if (this.isSP) this.closeInputMessageModal()
    }

    @Watch("rm.messagesPerRoom", { deep: true })
    updateMessagesPerRoom() {
        this.fetchMessages()
    }

    public getCurrentMessages(): RoomMessage[] {
        Logger(`RoomMessages.getCurrentMessages: rm.currentMessageRoom: ${Util.output(this.rm.currentMessageRoom)}`)
        if (!this.rm.currentMessageRoom) return null
        if (Util.isBlank(this.rm.messagesPerRoom)) return null

        let _roomMessagesDic = this.rm.messagesPerRoom[this.rm.currentMessageRoom.room_id]
        Logger(`_roomMessagesDic: ${Util.output(_roomMessagesDic)}`)
        if (Util.isBlank(_roomMessagesDic)) return null

        let _messages = _roomMessagesDic.messages
        return _messages
    }

    public resetMessage() {
        this.typedMessage = ""
        this.hasAttachment = false
        this.userFiles = null
        this.saveInput()
    }

    public closeInputMessageModal() {
        this.$vfm.close("InputMessageModal")
    }

    public resend(message: RoomMessage) {
        gtagClick(`${funcName()} メッセージ再送`, `${message.message}, room: ${this.rm.currentMessageRoom.room_id}`)
        message.id = `sending`
        return axios
            .post(`${Util.prefixUrl}/messages/resend`, {
                message: message.message,
                parent_id: this.getParentId(),
                room_id: this.rm.currentMessageRoom.room_id,
                file_ids: message.file_ids,
            })
            .then(res => {
                Logger(`resend data: ${Util.output(res.data)}`)
                if (res.data.message) {
                    let messageDic = res.data.message
                    if (Util.isPresent(messageDic.id)) {
                        // 送信できているので送信完了に変更します.
                        message.id = messageDic.id
                    }
                }
            })
    }

    /**
     * IDを送信中から送信不可に変更します。
     */
    public changeUnsent(message: RoomMessage) {
        this.currentMessages.forEach((el, index) => {
            if (el.id == `sending` && el.message == message.message) {
                el.id = `unsent`
            }
        })
    }

    getParentId(): string {
        Logger(`getParentId: ${Util.output(this.rm.currentMessageRoom)}`)
        return this.rm.currentMessageRoom.isParent ? this.rm.currentMessageRoom.id : this.rm.currentMessageRoom.parentRoomId
    }

    public resetTextarea() {
        if (!this.isSP) {
            $(".responseArea").css({ height: `98px` })
        }

        // $("#messageText").css({ height: `32px` })
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        if (Const.started(this.rm)) {
            // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
            this.$vfm.open("ModalTemplates")
        } else {
            this.$vfm.open("AdRegistrationModal")
        }
    }

    /**
     * 使うテンプレを決定.
     *
     */
    public decideTemplate(tmp: MessageTemplate) {
        //
        this.tmp = tmp
        this.updateTmp()
    }

    public updateTmp() {
        // テンプレを最新のユーザー/アポの内容をはめて更新します。
        if (!this.tmp && !this.rm.currentMessageRoom) return
        let mems = this.rm.currentMessageRoom.members
        let owner = mems.find(m => m.role == 100) || this.rm.userInfo
        let atts = mems.filter(m => m.user_id != this.rm.userInfo.user_id)
        let str = TemplateUtil.insertTemplateText(
            this.tmp.content,
            atts,
            owner,
            this.rm.currentMessageRoom.current_appointment,
            this.rm.userInfo,
            null,
            null,
            null,
            `rich`,
            this.rm.currentRoom
        )
        Logger(`updateTmp:: ${str}`)
        this.typedMessage = str
        this.saveInput()
    }

    public scrollToBottom() {
        if (!this.$el) return
        let container = this.$el.querySelector(".messageArea")

        if (container) {
            const height = container.scrollHeight ? container.scrollHeight : 0
            Logger(`scrollToBottom3 height: ${height}`)
            container.scrollTop = height
        }
    }

    /**
     * 入力途中のメッセージを保存します。
     */
    public saveInput() {
        let message = RoomMessage.createUnsentMessage(this.rm.userInfo, this.typedMessage, this.rm.currentMessageRoom.room_id)
        if (this.hasAttachment) message.files = this.userFiles
        roomStorage.saveInputMessage(this.rm.currentMessageRoom.room_id, message)
    }

    /**
     * RoomIdに紐づく入力途中のメッセージを取得してinput formに はめます。
     */
    @Watch("rm.currentMessageRoom", { deep: true })
    public fetchInput() {
        if (this.rm.currentMessageRoom) {
            let message = roomStorage.fetchInputMessage(this.rm.currentMessageRoom.room_id)
            this.typedMessage = message ? message.message : ``
            if (message && Util.isPresent(message.files)) {
                this.userFiles = message.files
                this.hasAttachment = true
            } else {
                this.userFiles = []
                this.hasAttachment = false
            }
        } else {
            this.typedMessage = ""
            this.userFiles = []
            this.hasAttachment = false
        }
    }

    /**
     * RoomIdに紐づくメッセージをフェッチします。メッセージをリモートから取得してきていない場合、取得してきます。
     * その後、既読に変更します。
     */
    @Watch("rm.currentMessageRoom", { deep: true })
    public fetchMessages() {
        const cRoom = Util.isPresent(this.rm.currentMessageRoom) ? this.rm.currentMessageRoom : null
        Logger(`fetchMessages: ${Util.output(cRoom)}`)
        if (!cRoom) return
        if (cRoom.room_type == `public_room` || Util.isPublic()) {
            gtagEvent(`openChat`, ``, ``)
            this.currentMessages = [this.createFirstPublicRoomMessage()]
            return
        }
        Logger(`選択中のルームを変更しました： ${cRoom.room_id}, ${cRoom.isParent}`)
        if (cRoom.keyId == `preview`) return

        this.hold = "bottom"
        this.currentMessages = this.getCurrentMessages()

        if (this.currentMessages) {
            Logger(`fetchMessages: メッセージ取得済みなので表示します。`)
            this.changeMarkAsRead(cRoom.room_id)
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        Logger(`fetchMessages: メッセージ未取得なので取得します。`)
        // const pRoom = cRoom.isParent ? cRoom : this.rm.getParentRoom(cRoom);
        // this.rm.getMessages(pRoom.room_id, 1).then(res => {
        this.rm.getMessages(cRoom, 1).then(res => {
            if (res) {
                Logger(`fetchMessages -> changeMarkAsRead`)
                this.currentMessages = this.getCurrentMessages()
                // this.changeMarkAsRead(cRoom.room_id)
            }
            this.loading = false
            this.rm.endProgress()
        })
    }

    /**
     * メッセージを既読に変更します。
     */
    public changeMarkAsRead(roomId: string) {
        Logger(`changeMarkAsRead room_id:${roomId}`)
        // let currentMem = this.rm.currentMessageRoom.members.find(m => m.user_id == this.rm.userInfo.user_id)
        let currentMem = this.rm.currentMember(roomId)
        Logger(`currentMem :${Util.output(currentMem)}`)
        if (!currentMem) return

        const cMsgs = this.currentMessages || []
        if (cMsgs.length === 0) return

        const lastMsg = cMsgs[cMsgs.length - 1]
        // 表示中の最新メッセージが以前読み込んだメッセージより新しい場合に更新します。
        Logger(
            `lastMsg: ${Util.output(lastMsg.created_at)}, last_read_message_created_at: ${Util.output(
                currentMem.last_read_message_created_at
            )}`
        )
        if (lastMsg && lastMsg.created_at >= currentMem.last_read_message_created_at) {
            if (!this.rm.wsConnection) return
            Logger(`既読変更をサーバーに送ります. room_id:${roomId} PERFORM`)
            this.rm.wsConnection.perform("mark_as_read", {
                message_id: lastMsg.id,
                owner_ug_id: Room.getOwnerUgId(this.rm.currentRoom),
            })
            // currentMem.last_read_message_created_at = Util.getSec()
        }
    }

    public pressShift() {
        this.send()
    }

    createFirstPublicRoomMessage() {
        let _user = RoomMember.createDefaultClient()
        let text = String(this.$t(`message.first_chat_message`))
        // let text = `ご利用いただきありがとうございます！\nこちらからお問い合わせください！`
        let mes = RoomMessage.createUnsentMessage(_user, text, `public_room`)
        mes.is_system = true
        mes.id = `sent`
        return mes
    }

    @Watch("hold")
    public getOldMessages() {
        const cRoom = this.rm.currentMessageRoom
        if (!cRoom) return

        if (this.hold === "top") {
            const msgs_dic = this.rm.messagesPerRoom[cRoom.room_id]
            if (Math.ceil(msgs_dic.total_num / 30) > msgs_dic.page) {
                this.rm.startProgress()
                this.rm.getMessages(cRoom, msgs_dic.page + 1).then(e => {
                    this.rm.endProgress()
                })
            }
            this.hold = ""
        }

        if (this.hold === "bottom") {
            this.changeMarkAsRead(cRoom.room_id)
        }
    }

    /**
     * ファイルアップロード系
     */

    public showChangeImage() {
        $(".AttachFile").fadeIn(100).css("display", "inline-block")
    }

    public hideChangeImage() {
        $(".AttachFile").fadeOut(60)
    }

    /**
     * 添付したファイルを削除. 削除自体は、UserFileImage.vue上で行います。
     */
    public deletedAttachment(file) {
        if (!file) return // 失敗した場合
        Logger(`添付ファイルを削除します: ${file.original_filename}`)
        this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
        if (this.userFiles.length == 0) {
            this.hasAttachment = false
            this.userFiles = null
        }
        this.saveInput()
    }

    /**
     *
     */
    checkDrag(event, key, status) {
        Logger(`event.dataTransfer.types: ${Util.output(event.dataTransfer.types)}`)
        this.isDrag = status ? key : null
        Logger(`isDrag: ${this.isDrag}`)
        status ? this.showChangeImage() : this.hideChangeImage()
    }

    onDrop(event, key = "", image = {}) {
        Logger(`ドロップしました。`)
        if (this.userFiles && this.userFiles.length > 5) {
            Notice.message = `一度に添付できるファイルは${Const.uploadFileLimit()}つまでです。`
            return false
        }
        if (this.loading) {
            Notice.message = `アップロード中です。`
            return false
        }
        this.isDrag = null
        this.hideChangeImage()

        /**
         *
         */
        let paramsArray = UserFile.createFileParams(event, `chat`, true)
        let allowUploadNum = Const.uploadFileLimit() - (this.userFiles || []).length

        // let params = UserFile.createFileParams(event)
        if (!paramsArray || paramsArray.length == 0) return

        this.loading = true
        this.rm.startProgress()

        let uploadNum = 0

        for (let _params of paramsArray) {
            if (allowUploadNum <= uploadNum) {
                Notice.message = `一つのメッセージに添付できるファイルは${Const.uploadFileLimit()}つまでです。`
                event = null
                this.loading = false
                this.rm.endProgress()
                break
            }
            uploadNum += 1
            let parentId = this.getParentId()
            _params.append(`parent_room_id`, parentId)
            _params.append(`room_id`, this.rm.currentMessageRoom.room_id)
            _params.append(`owner_ug_id`, Room.getOwnerUgId(this.rm.currentRoom))

            Logger(`送信するparams: ${Util.output(_params)}`)

            UserFile.uploadFile(_params).then(data => {
                if (event) event.target.value = null
                // event.target.value = null
                event = null
                this.loading = false
                this.rm.endProgress()
                if (data.result == `ok`) {
                    let uf: UserFile = UserFile.fetchFromJson([data.file])[0]
                    if (uf) {
                        this.hasAttachment = true
                        if (Util.isPresent(this.userFiles)) {
                            this.userFiles.push(uf)
                        } else {
                            this.userFiles = [uf]
                        }

                        this.saveInput()
                    }
                }
            })
        }
    }

    showPreview(file) {
        // this.currentFile = file
        // this.$vfm.open("ImagePreviewModal")
        this.$emit("showPreview", file)
    }

    openEmailNameFieldsModal() {
        this.$vfm.open(`EmailNameFieldsModal`)
    }

    /**
     * メールアドレスと名前、メッセージを送信してParentRoomに変換します。
     * dic.uf: RoomMember
     * dic.room: Room
     */
    sentEmailName(dic: any) {
        Logger(`sentEmailName 取得してきたparentとuserInfoを入れます.`)

        this.rm.setUserInfo(dic.uf)
        this.rm.updateCurrentInfo(dic.room)

        let currentMes = this.configureMessage()
        currentMes.id = Math.random().toString(32).substring(2)
        currentMes.owner = dic.uf

        this.$vfm.close(`EmailNameFieldsModal`)
        // 送信後削除.
        this.hold = "bottom"
        this.scrollToBottom()
        this.resetMessage()
        // this.currentMessages = this.getCurrentMessages()
        // this.currentMessages.push(currentMes)
        let systemMessage = this.createFirstPublicRoomMessage()
        systemMessage.message = `お問合わせいただきありがとうございました。担当者が確認しますので、このページを閉じてお待ちください。\n問い合わせいただいたメールアドレス（${currentMes.owner.email}）に通知がいきます。`
        this.currentMessages.push(systemMessage)
        this.rm.messagesPerRoom[this.rm.currentMessageRoom.room_id] = { messages: this.currentMessages, page: 1, total_num: 2 }

        this.refresher.needRefresh.push(`room`)
    }
}
