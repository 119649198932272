
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import AccountHeader from "./AccountHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import Organization from "packs/models/Organization"
import RoomHistory from "packs/models/RoomHistory"
import UserGroupHistory from "packs/models/UserGroupHistory"

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        AccountHeader,
        MdTextfieldTag,
        IconLock,
        OptionModal,
        AlertModal,
    },
})
export default class OneLogContent extends Vue {
    // data:

    @Prop() // 同一UGのユーザーリスト.
    users: RoomMember[]

    @Prop()
    log: UserGroupHistory

    notice = Notice

    rm = RoomManager
    Util = Util

    isSP = isMobile(window.navigator).phone

    user: RoomMember = null

    mounted() {
        this.updateLog()
    }

    @Watch(`log`, { deep: true })
    updateLog() {
        if (!this.users || !this.log) return
        let user = this.users.find(u => u.user_id == this.log.user_id)
        if (user) {
            this.user = user
        }
    }

    get createdAt() {
        if (!this.log) return
        let time = this.log.created_at

        return Util.getTimeDateFormatFromSeconds(time, `MM月dd日 HH:mm`)
    }

    get publicId() {
        if (!this.log) return ``
        let params = this.log.params
        if (!params) return ``
        let room = params.room
        if (!room) return ``
        return `（${room.public_id}）`
    }

    get getFilename() {
        if (!this.log) return ``
        let params = this.log.params
        if (!params) return ``
        let uf = params.user_file
        if (!uf) return ``
        return `（${uf.original_filename}）`
    }

    get getCondistions() {
        if (!this.log) return ``
        let params = this.log.params
        if (!params) return ``
        let param = params.download_request_hash
        if (!param) return ``
        return `（${param.start_date}~${param.end_date}） ${param.file_type.toUpperCase()}`
    }

    htmlText(log: any, user: RoomMember) {
        let str = ``
        let params = this.log.params

        if (log.large_category == `create_room`) {
            str = this.getNameWithActionText(user.name, `公開ページ${this.publicId}を作成しました。`)
        } else if (log.large_category == `update_room`) {
            str = this.getNameWithActionText(user.name, `公開ページ${this.publicId}を更新しました。`)
        } else if (log.large_category == `request_download_rooms`) {
            str = this.getNameWithActionText(
                user.name,
                `調整ページ一覧${this.getCondistions}のダウンロードをリクエストしました。`
            )
        } else if (log.large_category == `download_rooms`) {
            str = this.getNameWithActionText(user.name, `調整ページ一覧${this.getFilename}のダウンロードしました。`)
        } else if (log.large_category == `request_change_owner_my_public_rooms`) {
            let param = params.next_owner
            str = this.getNameWithActionText(
                user.name,
                `${param.name}（${param.email}）さん宛に公開ページのオーナー変更をリクエストしました。`
            )
        } else if (log.large_category == `share_my_room`) {
            let param = params.user
            str = this.getNameWithActionText(
                user.name,
                `${param.name}（${param.email}）さんに公開ページ${this.publicId}の編集権限を共有しました。`
            )
        } else if (log.large_category == `share_my_room_auto`) {
            let param = params.user
            str = this.getNameWithActionText(
                user.name,
                `${param.name}（${param.email}）さんに公開ページ${this.publicId}の編集権限を自動共有しました。`
            )
        } else if (log.large_category == `delete_shared_member`) {
            let param = params.user
            str = this.getNameWithActionText(
                user.name,
                `${param.name}（${param.email}）さんの公開ページ${this.publicId}の編集権限を削除しました。`
            )
        } else if ([`create_available_schedule_tag`, `update_available_schedule_tag`].includes(log.large_category)) {
            let actionName = `create_available_schedule_tag` == log.large_category ? `作成` : `更新`

            str = this.getNameWithActionText(user.name, `調整カレンダー(${params.name})を${actionName}しました。`)
            let diffs = params?.diffs
            if (Util.isPresent(diffs)) {
                for (let d of diffs) {
                    str += `<span class="fs12 dgrey pad5">[${d.name}] 変更前:${d.before}, 変更後:${d.after} </span><br />`
                }
            }
        } else if ([`create_other_rooms_from_chukai`].includes(log.large_category)) {
            str = this.getNameWithActionText(
                user.name,
                `仲介メニューから、別の公開ページ(${params.public_room_public_id})に複製同期しました。`
            )
        } else if (log.large_category == `update_public_rooms_astag_from_chukai`) {
            str = this.getNameWithActionText(
                user.name,
                `仲介メニューから、公開ページ(${params.public_room_public_id})に提案者(${params.room_member_name})の調整カレンダーを紐づけました。`
            )
        } else if (log.large_category == `update_room_members_display_type`) {
            str = this.getNameWithActionText(
                user.name,
                `${params.astag_name}(${params.astag_public_id})の表示期限を変更しました。`
            )
            if (params.suggested_dates_display_type == `all`) {
                str += `\n<span class="fs12 dgrey pad5">表示形式: 全期間</span>`
            } else if (params.suggested_dates_display_type == `period`) {
                if (
                    typeof params?.suggested_dates_display_start_at == "number" &&
                    typeof params?.suggested_dates_display_end_at == "number"
                ) {
                    str += `\n<span class="fs12 dgrey pad5">表示形式: 期間指定 (${DateTime.fromSeconds(
                        params.suggested_dates_display_start_at
                    ).toISODate()}~${DateTime.fromSeconds(params.suggested_dates_display_end_at).toISODate()})</span>`
                }
            } else if (params.suggested_dates_display_type == `select_self`) {
                str += `\n<span class="fs12 dgrey pad5">表示形式: 手動選択 ${
                    (params?.suggested_dates_optional_schedules || []).length
                }件</span>`
            }
        } else if (log.large_category == `create_api_key`) {
            str = this.getNameWithActionText(user.name, `APIキー${params.key_id}を作成しました。`)
        } else if (log.large_category == `delete_api_key`) {
            str = this.getNameWithActionText(user.name, `APIキー${params.key_id}を削除しました。`)
        }
        return str
    }

    getNameWithActionText(username, actionName) {
        return `<p><span class="fs16 fw600">${username}</span>さんが${actionName}</p>`
    }
}
