
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import MemberDailyLinePerCalendarTag from "packs/pages/link/parts/available_schedule/MemberDailyLinePerCalendarTag.vue"

// import "fullcalendar";
import Event from "packs/models/Event"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDatesConditions from "packs/models/PossibleDatesConditions"

const ONE_HOUR_HEIGHT = 30

@Options({
    components: { MemberDailyLinePerCalendarTag },
})
export default class MemberDailyLine extends Vue {
    // data:
    @Prop()
    date

    @Prop()
    dailyEventsInfo // その日のそのuserの情報が入っている

    @Prop()
    member

    // たぶん不要
    @Prop()
    type // all, one

    @Prop()
    mag_dic // {key: magId, {name: , calendar_name_dic: {cal_id: 日本語カレンダー名}}}

    @Prop()
    openChildCals: boolean

    @Prop()
    currentTime: DateTime

    @Prop()
    myAttendee: RoomMember

    @Prop()
    duration: number

    @Prop()
    pd: PossibleDate
    // 持っている情報
    //{ "event_readable": true, "possible_events": [ { "type": "possible_event", "short_format": "11071300", "start_time": 1573099200, "start_hour": 13, "duration": 240 } ], "events": [], "events_by_cal_id": {} }

    // openCals = false
    magsNum = 0 //出席可能なグループ（MeetingAttendeesGroup）の数.
    magId: string = null
    selectedMagEventsDic: any = null
    rm = RoomManager
    alldayEvents: Event[] = null
    pdm = PossibleDatesManager
    pdc = PossibleDatesConditions

    // 全体のイベント情報（dailyEventsInfo）かmagの情報が入ります。
    currentEventsDic: any = null

    // currentEvent = null;
    // addedEvents = null;
    // removedEvents = null;
    // events = null; // event, possible_eventを保持
    // alldayEvents = null;
    // possibleEvents = null; // possible_eventを保持
    // dragEnd = false;
    // alldayHeight = 0;
    // // currentEventPosition = `` // "top", "bottom", "both" 上にavzoneがある、下にavzoneがある、上下両方にある

    mounted() {
        // DailyLineをクリック時にイベントを作成するイベント処理を追加します。
        // 他のDailyLineと区別するためにidを指定します。
        // Logger(`${this.id} this avzones: ${Util.output(this.avzones)}`);
        if (this.dailyEventsInfo) {
            this.alldayEvents = this.dailyEventsInfo.events.filter(ev => ev.allday)
            this.currentEventsDic = this.dailyEventsInfo
        }

        this.checkCtags()
        this.calcurateOrangeBar()
        // this.openCals = this.openChildCals
    }

    startEndText() {
        return ""
        // if (!event) return "";
        // let st = this.event.start_hour;
        // let et = this.event.start_hour + this.event.duration / 60;
        // let etPoint = et - Math.floor(et);
        // let etmin = etPoint * 60;
        // return `${st} ~ ${Math.floor(et)}:${zeroPadding(etmin)}`;
    }

    /**
     * アコーディオンにするカレンダー(ctags)があるか。
     */
    public checkCtags(): boolean {
        if (!this.pd) return false
        // this.magsNum = Object.keys(this.dailyEventsInfo.group_events_dic).length
        this.magsNum = (this.pd.ok_groups || []).length
        // Logger(`has ctags: ${this.magsNum} ${Object.keys(this.dailyEventsInfo.group_events_dic)}`)
        // this.calcurateOrangeBar();
    }

    public openChildCal() {
        // this.openCals = !this.openCals
        // gtagClick(`FIX MODALカレンダー開閉処理`, `${this.openCals ? `開く` : `閉じる`}`)
    }

    @Watch(`pdc.selectedMag`)
    selectMag() {
        if (!this.pdc.selectedMag) return
        if (Util.isBlank(this.dailyEventsInfo) || Util.isBlank(this.dailyEventsInfo.group_events_dic)) return
        Logger(`${funcName()} dailyEventsInfo.group_events_dic:${Util.output(this.dailyEventsInfo.group_events_dic)}`)

        const magName = this.pdc.selectedMag.name
        let magId = this.pdc.selectedMag.id
        let evs = this.dailyEventsInfo.group_events_dic[magId]
        Logger(`${funcName()} evs(${evs ? evs.length : null}):${Util.output(evs)}`)
        if (evs) {
            this.selectedMagEventsDic = evs
        } else {
            evs = this.dailyEventsInfo.group_events_dic[magName]
            this.selectedMagEventsDic = evs
        }
        this.magId = magId

        this.currentEventsDic = this.selectedMagEventsDic
        this.$emit(`select`, magId)
    }

    public openGroup(magId: string) {
        Logger(`${funcName()} click magId:${magId}`)
        // this.openCals = true
        this.pdc.selectedMag = this.mag_dic[magId]
    }

    updated() {
        this.calcurateOrangeBar()
    }

    closeMag() {
        Logger(`開いたmagを閉じます。`)
        this.magId = null
        this.selectedMagEventsDic = null
        this.currentEventsDic = this.dailyEventsInfo
        this.pdc.openDetailSchedules = false
        // this.openCals = false
        this.$emit(`select`, null)
    }

    // @Emit("calcurateOrangeBar")
    calcurateOrangeBar() {
        // 選択しているMeetingAttendeesGroupのIDも一緒に送ります。
        this.$emit(`calcurateOrangeBar`, this.magId)
    }

    // @Watch("astag", { deep: true })
}
