
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger, funcName } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import RoomCreateManager from "packs/models/RoomCreateManager"
import PossibleDate from "packs/models/PossibleDate"
import Event from "packs/models/Event"

@Options({
    components: { VueFinalModal, MdProgressBar, MdTextfieldTag },
})
export default class ChangeMaxBookingsNumModal extends Vue {
    @Prop()
    ev: Event

    @Prop()
    height: number

    @Prop()
    retValue: any

    // data
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    saving = false
    Util = Util

    retHeight = null

    rcm = RoomCreateManager

    changeablePd = null

    public closeModal() {
        // this.$emit(`closeModal`)
    }

    public openModal() {
        this.updateContent()
    }

    @Watch(`ev`)
    updateContent() {
        Logger(`${funcName()} `)
        this.changeablePd = { ...this.ev }
    }

    public clickOK() {
        if (this.changeablePd.max_bookings_num < 1) {
            this.changeablePd.max_bookings_num = 1
        }
        if (this.changeablePd.max_bookings_num > 999) {
            this.changeablePd.max_bookings_num = 999
        }

        this.$emit(`clickOK`, this.changeablePd)
    }

    public clickCancel() {
        this.$vfm.close(`ChangeMaxBookingsNumModal`)
    }

    inputNumberOfBookings(e) {
        this.changeablePd.max_bookings_num = +e
    }
}
