
// モジュールを読込.
import { Options, Prop, Vue, Ref } from "vue-property-decorator"
import { Dropdown } from "uiv"

// コンポーネントを読込.
import BlackBoxEventMenu from "packs/pages/link/parts/available_schedule/BlackBoxEventMenu.vue"

// import "fullcalendar";
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, zeroPadding } from "packs/common"
import Event from "packs/models/Event"
import Const from "packs/utils/Const"
import CalendarManager from "packs/models/CalendarManager"
import Util from "packs/utils/Util"

@Options({
    components: { BlackBoxEventMenu, Dropdown },
})
export default class BlackBoxEvent extends Vue {
    // data:
    @Prop()
    ev: Event

    @Prop()
    blackIndex: number

    @Prop()
    alldayHeight: number

    @Prop() // 通常のイベントはnull, zoom
    provider: string

    @Prop() // top: ScheduleViewから、 asview: AstagViewのWeeklyScheduleから
    fromType: string

    CELL_HEIGHT = Const.CELL_HEIGHT
    notCloseLi = []

    @Ref()
    dropdown

    cm = CalendarManager

    // @Ref()
    // BlackBoxEventMenu

    mounted() {
        if (!this.provider) {
            this.notCloseLi.push(this.dropdown.$el)
        }

        // this.notCloseLi.push(this.BlackBoxEventMenu.getDetailLi())
    }

    // @Emit("deleteOptionalEvent")
    deleteOptionalEvent(event, type) {
        // return [event, type];
        this.$emit("deleteOptionalEvent", event, type)
    }

    get startEndText(): string {
        if (!this.ev) return ""
        let _time = DateTime.fromSeconds(this.ev.start_time)
        let st = _time.hour
        let et = _time.plus({ minutes: this.ev.duration })
        // let etPoint = et - Math.floor(et);
        // let etmin = etPoint * 60;
        return `${st}:${zeroPadding(this.ev.start_min)} ~ ${et.hour}:${zeroPadding(et.minute)}`
    }

    addEvent(type: string) {}

    changeAvailableEvent() {
        this.$emit(`changeAvailableEvent`, this.ev)
    }

    changeIgnoreEvent() {
        this.$emit(`changeIgnoreEvent`, this.ev)
    }

    showEventDetail() {
        this.$emit(`showEventDetail`)
    }

    get eventBgColor() {
        if (!this.ev) return
        if (this.ev.is_match_available_keyword) {
            return `orangeBg `
        } else if (this.ev.is_match_ignore_keyword) {
            ;`dblueBg opa2`
        } else if (this.provider == `zoom`) {
            ;`with_white_bg op9 ofh`
        }
        return `blackbrownBg`
    }

    get isSelectingEvent() {
        if (!this.ev) return false
        if (!this.cm.selectedEvent) return false
        if (
            this.ev.short_format == this.cm.selectedEvent.short_format &&
            this.ev.systemUpdatedAt == this.cm.selectedEvent.systemUpdatedAt &&
            this.ev.calendar_id == this.cm.selectedEvent.calendar_id
        ) {
            return true
        }
        return false
    }

    clickEv(ev: Event) {
        Logger(`${funcName()} ev:${Util.output(ev)}`)
        this.cm.resetSelectingEventsDic(this.cm.schedules, null)

        ev.isSelecting = true
        this.cm.selectedEvent = ev
    }

    changeMaxBookingsNum(ev: Event) {
        this.$emit(`changeMaxBookingsNum`, ev)
    }
}
