
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {},
})
export default class RoomLightOverViewContent extends Vue {
    @Prop()
    room: Room

    @Prop()
    appo: Appointment

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Room = Room
    Util = Util
    isSP = isMobile(window.navigator).phone
    text = ``
    loading = false
    truncate = truncate

    selectedMagTeamName() {
        let dic = Object.values(this.appo.selected_mag_dic)[0]
        if (!dic) return null
        // team_nameは自分で設定した名前、なければ name: A, Bで選択されたものを表示.
        let name = dic.team_name || dic.name
        return name || null
    }
}
