import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import axios from "types/axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"

export default class Plan {
    constructor(
        public id: string,
        public name: string,
        public amount: number,
        public id_num: number,
        public max_appointment: number,
        public max_quantity: number,
        public is_current: boolean,

        public quantity: number,
        public will_stop: boolean,
        public current_period_start: number,
        public current_period_end: number,

        public cost_estimate_current_period: any,
        public cost_estimate_next_period: any,
        public applied_coupon: boolean,
        public coupon_name: string,

        public format: string
    ) {}

    static fetchFromJson(plansJson: any): Plan[] {
        let plans = []
        // const arrOfDateTag = JSON.parse(plansJson)
        // Logger(`arrOfDateTag: ${arrOfDateTag.length}`)
        for (let plan of plansJson) {
            let _plan: Plan = plan
            if (_plan.is_current) {
                _plan.format = Plan.setFormat(plan.current_period_start, plan.current_period_end)
            }

            plans.push(_plan)
        }
        return plans
    }

    static fetchFromCurrent(plan: Plan, planInfo: Plan): Plan {
        plan.quantity = planInfo.quantity
        plan.will_stop = planInfo.will_stop
        plan.current_period_start = planInfo.current_period_start
        plan.current_period_end = planInfo.current_period_end
        plan.format = Plan.setFormat(plan.current_period_start, plan.current_period_end)
        return plan
    }

    static setFormat(st: number, et: number): string {
        const _st = DateTime.fromSeconds(st)
        const _et = DateTime.fromSeconds(et)
        return `${_st.toFormat(`yyyy年MM月dd日`)} ~ ${_et.toFormat(`yyyy年MM月dd日`)}`
    }

    /**
     * サーバーにプラン変更を通知します。
     * @param plan
     * @param token
     */
    static changePlan(plan: Plan, token: {}): Promise<boolean> {
        return axios
            .put(`${Util.prefixUrl}/subscriptions/change_plan`, {
                token: token,
                plan_id: plan.id,
            })
            .then(e => {
                return true
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * カード情報を更新します。
     * @param token
     */
    static updatePaymentMethod(token: {}): Promise<boolean> {
        return axios
            .put(`${Util.prefixUrl}/subscriptions/update_payment_method`, {
                token: token,
            })
            .then(e => {
                return true
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static stopSubscription(): Promise<boolean> {
        return axios
            .put(`${Util.prefixUrl}/subscriptions/stop`)
            .then(e => {
                return true
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static cancelStopSubscription(): Promise<boolean> {
        return axios
            .put(`${Util.prefixUrl}/subscriptions/cancel_stop`)
            .then(e => {
                return true
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * カード情報が同じか
     * @param card
     * @param newCard
     */
    static isDiff(card, newCard): boolean {
        return (
            newCard.last4 != card.last4 ||
            newCard.brand != card.brand ||
            newCard.exp_month != card.exp_month ||
            newCard.exp_year != card.exp_year
        )
    }
}
