
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MailPreviewCreateRoom from "packs/pages/link/parts/room_settings/MailPreviewCreateRoom.vue"
import MailPreviewFeedback from "packs/pages/link/parts/room_settings/MailPreviewFeedback.vue"
import MailPreviewCustom from "packs/pages/link/parts/room_settings/MailPreviewCustom.vue"
import MailPreviewFixAppo from "packs/pages/link/parts/room_settings/MailPreviewFixAppo.vue"
import MailPreviewInquire from "packs/pages/link/parts/room_settings/MailPreviewInquire.vue"
import MailPreviewTheDayBefore from "packs/pages/link/parts/room_settings/MailPreviewTheDayBefore.vue"
import MailPreviewBeforeDeadline from "packs/pages/link/parts/room_settings/MailPreviewBeforeDeadline.vue"
import MailPreviewAutoRemind from "packs/pages/link/parts/room_settings/MailPreviewAutoRemind.vue"
import MailPreviewSimpleText from "packs/pages/link/parts/room_settings/MailPreviewSimpleText.vue"
import MailPreviewSharedCalendar from "packs/pages/link/parts/room_settings/MailPreviewSharedCalendar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import MailFlow from "packs/models/MailFlow"
import TemplateUtil from "packs/utils/TemplateUtil"

@Options({
    components: {
        MailPreviewCreateRoom,
        MailPreviewFixAppo,
        MailPreviewFeedback,
        MailPreviewInquire,
        MailPreviewTheDayBefore,
        MailPreviewBeforeDeadline,
        MailPreviewAutoRemind,
        MailPreviewSimpleText,
        MailPreviewSharedCalendar,
        MailPreviewCustom,
    },
})
export default class MailPreview extends Vue {
    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    appos = null
    rm = RoomManager
    rcm = RoomCreateManager
    astags: AvailableScheduleTag[] = null
    showNew: boolean

    member: RoomMember = null
    pdm = PossibleDatesManager

    lastAstagId: string = null // 最後にpdを叩いたastagIDと同じ場合叩かない.
    lastDuration: number = null
    lastStepSize: number = null

    Util = Util

    public dismissModal() {}

    created() {}

    mounted() {
        this.updateMember()
        this.getPossibles()
        this.addMessage()
    }

    getMailSubject() {
        let _us = this.rm.user_setting
        let title = `${_us && _us.prefix_subject ? _us.prefix_subject : ``}`
        let _mf = this.rcm.selectedMailFlow
        let subj = ``
        if (Util.isPresent(_mf)) {
            if (Util.isPresent(_mf.subject)) {
                subj = _mf.subject
            } else {
                subj = MailFlow.getSubject(_mf.def_type, this.rcm.appo)
            }
            let str = this.rcm.insertTemplateText(subj)
            title += `${str}`
        }

        return title
    }

    addMessage() {
        this.editEditableContent(`message`)
    }

    @Watch("members", { deep: true })
    updateMember() {
        if (!this.rcm.members) return
        if (!this.rm.userInfo) return
        Logger(`updateMember 親から受け取ります. ${this.rcm.members.length}`)
        this.member = this.rcm.members.find(m => m.user_id != this.rm.userInfo.user_id)
        this.getPossibles()
    }

    @Watch("rcm.appo", { deep: true })
    updateAppo() {
        // アポの仲介参加/不参加が変更になった場合に、pdを叩きます。
        this.getPossibles()
    }

    // タイプが変更されたときに、fill, overstart, overdue の場合、再度pdmsを取得しなおして表示.
    @Watch("rcm.astag", { deep: true })
    public getPossibles() {
        // const getPDArray = [`fill`, `overstart`, `overdue`];
        if (!this.rcm.astag) return
        if (!this.rcm.appo) return

        let astag = this.rcm.astag
        if (this.rcm.members.length > 1 && !this.rcm.appo.required_owner_participation) {
            astag = this.rm.chukaiAstag
        }

        let rs = this.rcm.room_setting

        if (this.lastAstagId == astag.id && this.lastDuration == this.rcm.appo.duration) {
            return
        }

        // 最後に取得したastagIDを覚えておいて、同じ場合は叩きません。
        this.lastAstagId = astag.id
        this.lastDuration = this.rcm.appo.duration

        if (this.rcm.astag.mag_type == `display_and_selectable`) {
            this.rcm.astag.meeting_attendees_groups
        }

        if (this.loading) return
        this.rm.startProgress()
        this.loading = true

        PossibleDate.getPossibleDates(astag, this.rcm.appo.duration).then(pdmDic => {
            this.loading = false
            Logger(`pdmを取得してきました。 ${Util.output(pdmDic.selected_pdm)}`)
            // this.pds = pdmDic.pdm
            this.pdm = pdmDic.pdm
            let pds = pdmDic.selected_pdm
            this.pdm.possibleDates = pds
            let us = this.rm.user_setting
            let rs = this.rcm.room_setting
            let num = rs.email_suggests_num || us.email_suggests_num || 1

            this.rcm.pds = [...pds].slice(0, num)
            // this.selectedPds = pdmDic.selected_pdm
            this.rm.endProgress()
        })
    }

    clickTitle() {
        // this.editEditableContent(`appo`)
        this.editEditableContent(`message`)
    }
}
