
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique } from "packs/common"
import { gtagPage } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"
import axios from "axios"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import ImageBoardModal from "packs/pages/link/modals/ImageBoardModal.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import UserSettingsAddressBook from "packs/pages/link/settings/UserSettingsAddressBook.vue"
import TemplatesContent from "packs/pages/link/parts/room_settings/TemplatesContent.vue"
import UrlWithQRcodeContent from "packs/components/common/UrlWithQRcodeContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import UserFile from "packs/models/UserFile"
import UserSetting from "packs/models/UserSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        TextfieldTag,
        UserSettingsHeader,
        ImageBoardModal,
        MdTextfieldTag,
        TextareaTag,
        UserSettingsAddressBook,
        TemplatesContent,
        UrlWithQRcodeContent,
    },
})
export default class UserSettingsSecurity extends Vue {
    // data:
    @Prop()
    tab: string

    notice = Notice

    rm = RoomManager
    selectedTab = `security`

    isSP = isMobile(window.navigator).phone
    UserPermissionsOption = UserPermissionsOption

    Const = Const
    SelectUtil = SelectUtil

    saving = false

    us: UserSetting = null

    logoUrl: string = null
    selectedCategory = `mail`

    qrCodeUrl: string = null
    secret: string = null
    totpCode: string = ""
    otpRequiredForLogin = false
    challengeCode = false

    @Ref()
    header

    created() {
        let tabName = this.tab || `security`

        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkHasAstag()
            })
        } else {
            this.checkHasAstag()
        }
    }

    mounted() {
        gtagPage("#/settings/security")
        this.checkHasAstag()
    }

    public checkHasAstag() {
        this.otpRequiredForLogin = this.rm.userInfo?.otp_required_for_login || false
        this.rm.redirectTopIfChukaiFreePlan(`UserSettingsInfo1`)
    }

    defaultSecurityType() {
        if (!this.otpRequiredForLogin) return `無効`

        return this.otpRequiredForLogin ? `有効` : `無効`
    }

    selectSecurityType(e) {
        if (e == `有効`) {
            this.generateTotpSecret()
            this.challengeCode = true
        } else {
            if (this.otpRequiredForLogin && confirm(`2段階認証を無効にします。この操作は取り消せません。\nよろしいですか？`)) {
                this.qrCodeUrl = null
                this.secret = null
                this.challengeCode = false
                this.disableTotp()
            }
        }
    }

    async generateTotpSecret() {
        try {
            const response = await axios.post("/api/front/user_groups/generate_totp_secret")
            this.qrCodeUrl = response.data.qr_code_url
            this.secret = response.data.secret
            Logger(`generateTotpSecret qr_code_url:${this.qrCodeUrl}, secret:${this.secret}`)
        } catch (error) {
            Logger(`generateTotpSecret error:${error}`)
        }
    }

    async verifyTotp() {
        try {
            const response = await axios.post("/api/front/user_groups/verify_totp", { code: this.totpCode })
            if (response.data.success) {
                Notice.message = response.data.message
                Logger(`verifyTotp success:${response.data.message}`)
                // 2段階認証が有効になったことをユーザーに表示
                this.otpRequiredForLogin = true
                this.rm.userInfo.otp_required_for_login = true
                this.challengeCode = false
            }
        } catch (error) {
            Notice.message = "コードの検証に失敗しました"
        }
    }

    async disableTotp() {
        const response = await axios.post("/api/front/user_groups/disable_totp")
        if (response.data.success) {
            Notice.message = response.data.message
            Logger(`disableTotp success:${response.data.message}`)
            this.otpRequiredForLogin = false
            this.rm.userInfo.otp_required_for_login = false
        }
    }
}
