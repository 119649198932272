
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarTag from "packs/models/CalendarTag"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        SelectTag,
        CheckboxTag,
    },
})
export default class OptionalConnectedCalendar extends Vue {
    @Prop()
    hideZoom: boolean

    cm = CalendarManager
    rcm = RoomCreateManager
    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util

    mounted() {}

    public saveInLocal() {
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    /**
     * プライベートにカレンダーに登録するかの設定を変更します。
     */
    public clickRegisterCalPrivate(dic) {
        if (this.rcm.room_setting.is_private) {
            // this.$emit("changePrivate", false)
            this.rcm.room_setting.is_private = false
        } else {
            // this.$emit("changePrivate", true)
            this.rcm.room_setting.is_private = true
        }
        this.saveInLocal()
    }

    get showContent() {
        if (!this.rcm.room_setting) return false
        if (this.rm.didConnectCal || Util.isPresent(this.cm.sharedCalendarTagsDic[this.rcm.astag.id])) {
            return true
        }
        return false
    }
}
