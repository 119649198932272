
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        CheckboxTag,
    },
})
export default class RoomSettingsUseChatContent extends Vue {
    // data
    rcm = RoomCreateManager

    public clickUseChat() {
        if (this.rcm.room_setting.use_chat) {
            this.rcm.room_setting.use_chat = false
        } else {
            this.rcm.room_setting.use_chat = true
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }
}
