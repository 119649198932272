import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, onlyUnique } from "packs/common"

import Util from "packs/utils/Util"
import DateTag from "packs/models/DateTag"
import PossibleDate from "./PossibleDate"
import Event from "./Event"
import CalendarUtil from "packs/utils/CalendarUtil"

export default class RoomHistoryDetail {
    constructor(
        public created_at: number,
        public event_name: string,
        public large_category: string,
        public medium_category: string,
        public small_category: string,
        public message: string,
        public option_messages: string[],
        public user_group_id: string,
        public user_id: string,
        public is_system: boolean,
        public request_id: string,
        public hostname: string,
        public options: any,
        public can_show_admin: boolean,

        public createdAtText: string,
        public history_id: string
    ) {}

    static fetchFromJson(histsJson: any[]): RoomHistoryDetail[] {
        let hists: RoomHistoryDetail[] = []
        if (!histsJson || histsJson.length == 0) return hists
        const now = DateTime.local()
        // const arrOfGroups = JSON.parse(groupsJson)
        // Logger(`roomMember arrOfGroups: ${membersJson.length}`)
        for (let _hist of histsJson) {
            if (!_hist) continue
            // Logger(`mem: ${Util.output(mem)}`)
            let _h = this.createDefaultClient()
            Object.assign(_h, _hist)
            let hist: RoomHistoryDetail = _h
            let createdAtText = DateTag.getTimeStringSlashFromSeconds(_h.created_at || now.toSeconds())
            hist.createdAtText = createdAtText

            hists.push(hist)
        }
        return hists
    }

    static createDefaultClient(): RoomHistoryDetail {
        return new RoomHistoryDetail(
            Util.getSec(),
            null,
            null,
            null,
            null,
            ``,
            [],
            ``,
            ``,
            false,
            null,
            null,
            null,
            false,
            ``,
            null
        )
    }

    static findKeyName(optionkey) {
        if (optionkey == `appointment`) {
            return `アポ設定情報`
        } else if (optionkey == `mail_info`) {
            return `メール情報`
        } else if (optionkey == `err_msg`) {
            return `メッセージ`
        } else if (optionkey == `false_reasons`) {
            return `確定できなかった理由`
        } else if (optionkey == `owner_astag`) {
            return `オーナー調整カレンダー情報`
        } else if (optionkey == `start_time`) {
            return `確定日時`
        } else if (optionkey == `new_mag`) {
            return `担当者指定`
        } else if (optionkey == `mag_id`) {
            return `担当者ID`
        } else if (optionkey == `is_force`) {
            return `強制確定の有無`
        } else if (optionkey == `is_fix_through_reschedule`) {
            return `確定と同時にリスケしたか`
        } else if (optionkey == `force_online_search_keys`) {
            return `強制的に利用するオンラインID`
        } else if (optionkey == `start_times`) {
            return `提案日時`
        } else if (optionkey == `mag_info`) {
            return `担当者情報`
        } else if (optionkey == `error_params`) {
            return `エラー情報`
        }

        return optionkey
    }

    static booleanToName(val) {
        if (!val) return `指定なし`
        return val ? `はい` : `いいえ`
    }

    // オプションの中で表示するパラメータを指定します.
    static showHistoryDic(options: any, optionkey: string, isAdmin: boolean = true) {
        if (!options) return

        let ret = []
        if (typeof options == "string") {
            return options
        } else if (Array.isArray(options)) {
            return [null, options]
        } else {
            for (let [key, value] of Object.entries(options)) {
                //
                if (optionkey == `owner_astag`) {
                    if (key == `name`) {
                        ret.push([`調整カレンダー名`, `「${value}」`])
                    }
                } else if (optionkey == `mag_info`) {
                    if (key == `date`) {
                        ret.push([`確定日`, `${value}`])
                    } else if (key == `events`) {
                        let evs = value as Event[]
                        let evs_arr = []
                        let index = 1
                        for (let _ev of evs) {
                            evs_arr.push(
                                `<p class="fs13 mt10 ml10">${index}.${
                                    _ev.title
                                }<br><span class="fs13 fw400">${CalendarUtil.toJpFormat(_ev.start_time, _ev.duration)}  (${
                                    _ev.duration
                                }分)</span></p>`
                            )
                            index += 1
                        }

                        ret.push([`イベント(${evs_arr.length})`, `${evs_arr.join("")}`])
                    } else if (key == `team_name`) {
                        if (Util.isPresent(value)) {
                            ret.push([`担当者名`, `${value}`])
                        }
                    } else if (key == `name`) {
                        ret.push([`担当者記号`, `${value}`])
                    }
                }
                // アポ関係
                if (key == `duration`) {
                    ret.push([`会議時間（分）`, value])
                } else if (key == `fix_type_name`) {
                    ret.push([`確定可能タイプ`, value])
                } else if (key == `location`) {
                    ret.push([`会議場所`, value])
                } else if (key == `required_owner_participation`) {
                    let bool = RoomHistoryDetail.booleanToName(value)
                    ret.push([`オーナーの参加は必要か`, bool])
                } else if (key == `start_time`) {
                    if (typeof value == `number`) {
                        let val = DateTag.getTimeStringFromSeconds(value)
                        ret.push([`開始日時`, val])
                    } else if (typeof value == `string`) {
                        let val = DateTag.getTimeStringFromSeconds(Number(value))
                        ret.push([`開始日時`, val])
                    } else {
                        if (!value) continue

                        let stDic = value as PossibleDate
                        ret.push([`開始日時`, stDic.jp_format])
                        // ret.push([`開始時間`, value])
                    }
                    // let val = DateTag.getTimeStringFromSeconds(value as number)
                } else if (key == `start_times`) {
                    let arr = (value as PossibleDate[]) || []
                    if (arr.length > 1) {
                        for (let stDic of arr) {
                            ret.push([`開始日時`, stDic.jp_format])
                        }
                    }

                    // let val = DateTag.getTimeStringFromSeconds(value as number)
                }

                // メール情報
                if (key == `action_user_name`) {
                    ret.push([`アクションした人`, value])
                } else if (key == `recipient_name`) {
                    ret.push([`送信対象者`, value])
                }
            }
        }
        return ret
    }
}
