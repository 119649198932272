
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { copyToClipboard, Logger } from "packs/common"
import Const from "packs/utils/Const"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDate from "packs/models/PossibleDate"
import Room from "packs/models/Room"
import Event from "packs/models/Event"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import { DateTime } from "luxon"

@Options({
    components: {},
})
export default class PossibleDateLi extends Vue {
    // data:
    @Prop()
    pd: PossibleDate

    @Prop()
    showConfirmButton: boolean

    @Prop()
    useSelectbox: boolean

    @Prop()
    idprefix: string

    @Prop()
    canDelete: boolean

    @Prop()
    listStyle: string

    isSP = isMobile(window.navigator).phone
    pdm = PossibleDatesManager
    windowWidth = 0
    // showGroups = false

    mounted() {
        this.calculateWindowWidth()
        window.addEventListener("resize", this.calculateWindowWidth)
    }

    // @Watch("pd", { deep: true })
    get showGroups(): boolean {
        if (!this.pd) return false
        if (!this.pdm) return false
        if (!Util.isPreview()) return false

        // if (!)
        return this.pd.ok_groups && this.pd.ok_groups.length > 0
    }

    get pdGroups() {
        let groups = []
        for (let group_id of this.pd.ok_groups) {
            let mag = this.pdm.mag_dic[group_id]
            if (mag) {
                groups.push(mag)
            }
        }
        let descGroups = Util.sortByWords(groups, "name", true)

        return descGroups
    }

    // @Emit("confirmFixSchedule")
    confirmFixSchedule(pd) {
        if (this.idprefix == `sel`) {
            // 選択モーダルの場合は、clickCellを適用.
            this.$emit(`clickCell`, pd)
        } else {
            this.$emit(`confirmFixSchedule`, pd)
        }
    }

    // @Emit("clickCell")
    // public clickCell() {
    //     return this.pd
    // }

    public removeDate() {
        this.$emit(`removeDate`, this.pd)
    }

    calculateWindowWidth() {
        this.windowWidth = window.innerWidth
        if (this.windowWidth < Const.spWidth) {
            this.isSP = true
        } else {
            this.isSP = isMobile(window.navigator).phone
        }
    }

    mouseoverLi() {
        this.pdm.mouseoverPd = this.pd
    }

    mouseleaveLi() {
        if (this.pdm.mouseoverPd == this.pd) {
            this.pdm.mouseoverPd = null
        }
    }

    showMaxBookings() {
        let room = this.pdm?.room
        let rs = this.pdm?.room?.room_setting
        if (!rs) return false
        if (rs.max_bookings_show_type == `none`) return false
        // if (this.pd.max_bookings_num <= 1) return false
        if (room.max_bookings_num <= 1) return false

        return true
    }

    get maxBookingsText() {
        let rs = this.pdm?.room?.room_setting
        if (!rs) return ``
        if (rs.max_bookings_show_type == `none`) return ``

        if (rs.max_bookings_show_type == `show_slot_remain`) {
            return `残り ${this.pd.max_bookings_num - this.pd.booked_num}`
        }

        return `${this.pd.booked_num} /${this.pd.max_bookings_num}`
    }
}
