
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import Const from "packs/utils/Const"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick } from "packs/GoogleTagManager"
import { DateTime } from "luxon"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import ConsiderationTravelMode from "packs/pages/link/parts/available_schedule/ConsiderationTravelMode.vue"
import AssignMagContent from "packs/pages/link/parts/available_schedule/AssignMagContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import TimezoneSelectContent from "packs/components/calendar/TimezoneSelectContent.vue"
import OpenPeriodSettingsContent from "packs/pages/link/parts/room_settings/OpenPeriodSettingsContent.vue"
import SpanSlotContent from "packs/components/common/SpanSlotContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

@Options({
    components: {
        MdTextfieldTag,
        SelectTag,
        ConsiderationTravelMode,
        AssignMagContent,
        CheckboxTag,
        TimezoneSelectContent,
        OpenPeriodSettingsContent,
        SpanSlotContent,
    },
})
export default class RoomSettingsAvailabletime extends Vue {
    @Prop() // func
    updateRoomSetting

    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager

    Util = Util
    Const = Const
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting
    cm = CalendarManager

    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    timeArray = SelectUtil.timeArray
    notice = Notice

    isAvailableTimezone = false

    cancelSpanDate = DateTime.fromSeconds(
        this.rcm.room_setting.available_cancel_time_at || DateTime.local().toSeconds()
    ).toFormat("yyyy/MM/dd")

    mounted() {
        if (Util.isBlank(this.rcm.room_setting.timezone_value)) {
            this.isAvailableTimezone = false
        } else {
            this.isAvailableTimezone = true
        }
    }

    clickFixType() {
        this.$emit(`clickFixType`)
    }

    inputSubmitNum(e) {
        Logger(`inputSubmitNum: ${e}`)
        let rs = this.rcm.room_setting
        rs.submit_lower_limit_num = +e
        this.rcm.updateRoomSetting(rs)
    }

    get getShowVoteType() {
        let types = SelectUtil.selectShowVoteTypes
        let defaultType = types.find(t => t.type == `hide_all`)
        if (!this.rcm.room_setting) return defaultType.name

        const currentType = this.rcm.room_setting.show_vote_type
        let _t = types.find(t => t.type == currentType)
        Logger(`${funcName()} currentType: ${Util.output(currentType)}, ${Util.output(_t)}`)
        if (!_t) return defaultType.name
        return _t.name
    }

    get getShowFilterVoteType() {
        let types = SelectUtil.selectShowFilterVoteTypes
        let defaultType = types.find(t => t.type == `none`)
        if (!this.rcm.room_setting) return defaultType.name

        const currentType = this.rcm.room_setting.show_filter_vote_type
        let _t = types.find(t => t.type == currentType)

        if (!_t) return defaultType.name
        return _t.name
    }

    selectShowVoteType(typeName: string) {
        let types = SelectUtil.selectShowVoteTypes
        let _t = types.find(t => t.name == typeName)
        this.$emit(`selectShowVoteType`, _t.type)
    }

    get getMaxBookingsShowType() {
        let types = SelectUtil.selectMaxBookingsShowTypes
        let defaultType = types.find(t => t.type == `none`)
        if (!this.rcm.room_setting) return defaultType.name

        const currentType = this.rcm.room_setting.max_bookings_show_type
        let _t = types.find(t => t.type == currentType)

        if (!_t) return defaultType.name
        return _t.name
    }

    selectMaxBookingsShowType(typeName: string) {
        let types = SelectUtil.selectMaxBookingsShowTypes
        let _t = types.find(t => t.name == typeName)
        if (_t) {
            this.rcm.room_setting.max_bookings_show_type = _t.type
            this.rcm.updateRoomSetting(this.rcm.room_setting)
            if (this.pdm?.room?.room_setting) {
                this.pdm.room.room_setting.max_bookings_show_type = _t.type
            }
        }
    }

    selectShowFilterVoteType(typeName: string) {
        Logger(`${funcName()} typeName:${typeName}`)
        let types = SelectUtil.selectShowFilterVoteTypes
        let _t = types.find(t => t.name == typeName)
        this.$emit(`selectShowFilterVoteType`, _t.type)
    }

    // showDetail = false

    inputNumberOfBookings(e) {
        this.rcm.room.max_bookings_num = +e
        this.rcm.updateRoom(this.rcm.room)
    }

    blurNumberOfBookings() {
        if (this.rcm.room.max_bookings_num < 1) {
            this.rcm.room.max_bookings_num = 1
        }
        if (this.rcm.room.max_bookings_num > 999) {
            this.rcm.room.max_bookings_num = 999
        }
        this.rcm.updateRoom(this.rcm.room)
    }

    clickChangeTimezone() {
        Logger(`${funcName()} can_change_timezone:${this.rcm.room_setting.can_change_timezone}`)
        if (this.rcm.room_setting.can_change_timezone) {
            this.rcm.room_setting.can_change_timezone = false
        } else {
            this.rcm.room_setting.can_change_timezone = true
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    selectTimezone(tzDic: any) {
        Logger(`${funcName()} tzDic:${Util.output(tzDic)}`)

        if (tzDic) {
            this.rcm.room_setting.timezone_value = tzDic.value
        } else {
            this.rcm.room_setting.timezone_value = null
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    get defaultTimezone() {
        if (!this.rcm.room_setting) return null

        let tzValue = this.rcm.room_setting.timezone_value
        if (this.rcm.room_setting.can_change_timezone) {
            tzValue = tzValue || `Japan Standard Time`
        }
        return tzValue
    }

    clickUseTimezoneAvailableTime() {
        if (this.isAvailableTimezone) {
            this.isAvailableTimezone = false
            this.rcm.room_setting.timezone_value = null
        } else {
            this.isAvailableTimezone = true
            if (Util.isBlank(this.rcm.room_setting.timezone_value)) {
                this.rcm.room_setting.timezone_value = `Japan Standard Time`
            }
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    get cancelSpanType() {
        if (!this.rcm.room_setting.available_cancel_min_before_appo_start_time) {
            return `常に表示する`
        }
        if (this.rcm.room_setting.available_cancel_min_before_appo_start_time == -1) {
            return `常に表示しない`
        }
        if (this.rcm.room_setting.available_cancel_min_before_appo_start_time == -2) {
            return `指定日付まで表示する`
        }
        return `指定期間まで表示する`
    }

    selectCancelSpanType(e) {
        Logger(`selectCancelSpanType: ${e}`)
        let dic = SelectUtil.cancelSpanTypes.find(span => span.name == e)
        if (dic) {
            this.rcm.room_setting.available_cancel_min_before_appo_start_time = dic.number
        }
    }

    @Watch(`cancelSpanDate`, { deep: true })
    updateEndDate() {
        Logger(`${funcName()} cancelSpanDate:${this.cancelSpanDate}`)
        let sec = DateTime.fromFormat(this.cancelSpanDate, `yyyy/MM/dd`).toSeconds()
        this.rcm.room_setting.available_cancel_time_at = sec
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    updateSpan(mins: number) {
        Logger(`updateSpan: mins: ${mins}`)
        this.rcm.room_setting.available_cancel_min_before_appo_start_time = mins
    }

    inputEmailSuggestsNum(e) {
        this.rcm.room_setting.email_suggests_num = +e
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    blurEmailSuggestsNum() {
        if (this.rcm.room_setting.email_suggests_num < 0) {
            this.rcm.room_setting.email_suggests_num = 0
        }
        if (this.rcm.room_setting.email_suggests_num > 10) {
            this.rcm.room_setting.email_suggests_num = 10
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    get requestDatesButtonType() {
        if (!this.rcm.room_setting) return `表示`
        let dic = SelectUtil.commonDisplayTypes.find(m => m.type == this.rcm.room_setting.request_dates_button_type)
        if (dic) return dic.name
        return `表示`
    }

    selectRequestDatesButtonType(e) {
        let dic = SelectUtil.commonDisplayTypes.find(m => m.name == e)
        if (!dic) return
        this.rcm.room_setting.request_dates_button_type = dic.type

        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    getDefaultCalendarShowType() {
        const defaultValue = `monthly_monthly`
        const typeArray = SelectUtil.calendarShowTypes
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.rcm.room_setting) return defaultName

        const currentOpt = typeArray.find(t => t.type == this.rcm.room_setting.calendar_show_type)

        return currentOpt ? currentOpt.name : defaultName
    }

    selectCalendarShowType(e) {
        Logger(`${funcName()} e:${e}`)
        let choice = SelectUtil.calendarShowTypes.find(mt => mt.name == e)

        this.rcm.room_setting.calendar_show_type = choice.type
        this.pdm.calendarShowType = null
        this.pdm.getCalendarShowType(this.rcm.room_setting, false)
        if (this.cm.pdm) {
            this.cm.pdm = this.pdm
        }

        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    getDesignType() {
        const defaultValue = `normal`
        const typeArray = SelectUtil.designTypes
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.rcm.room_setting) return defaultName

        const currentOpt = typeArray.find(t => t.type == this.rcm.room_setting.design_type)

        return currentOpt ? currentOpt.name : defaultName
    }

    selectDesignType(e) {
        Logger(`${funcName()} e:${e}`)
        let choice = SelectUtil.designTypes.find(mt => mt.name == e)

        this.rcm.room_setting.design_type = choice.type

        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    inputVoteWarningText(e) {
        Logger(`inputVoteWarningText: ${e}`)
        if (e.length > 40) {
            this.notice.message = `40文字以内で入力してください。`
            return
        }
        this.rcm.room_setting.vote_warning_text = e
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    inputSelectTimeText(e) {
        Logger(`inputSelectTimeText: ${e}`)
        if (e.length > 40) {
            this.notice.message = `40文字以内で入力してください。`
            return
        }
        this.rcm.room_setting.select_time_text = e
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }
}
