
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick } from "packs/GoogleTagManager"
import { DateTime } from "luxon"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import ConsiderationTravelMode from "packs/pages/link/parts/available_schedule/ConsiderationTravelMode.vue"
import OptionalConnectedCalendar from "packs/pages/link/parts/available_schedule/OptionalConnectedCalendar.vue"
import AssignMagContent from "packs/pages/link/parts/available_schedule/AssignMagContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import TimezoneSelectContent from "packs/components/calendar/TimezoneSelectContent.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"

@Options({
    components: {
        MdTextfieldTag,
        SelectTag,
        ConsiderationTravelMode,
        OptionalConnectedCalendar,
        AssignMagContent,
        CheckboxTag,
        TimezoneSelectContent,
        Dropdown,
    },
})
export default class OpenPeriodSettingsContent extends Vue {
    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting
    cm = CalendarManager

    dayOfTheWeek = [1, 2, 3, 4, 5]
    timeArray = SelectUtil.timeArray
    notice = Notice

    startDate = DateTime.local().toFormat("yyyy/MM/dd")
    endDate = DateTime.local().plus({ days: 7 }).toFormat("yyyy/MM/dd")
    errMsgSpan: string = null

    startTimeStr: string = `08:00`
    endTimeStr: string = `21:00`

    created() {
        if (![`none`, null].includes(this.rcm.room_setting.open_period_type)) {
            if ([`start_time_only`, `start_and_end_time`].includes(this.rcm.room_setting.open_period_type)) {
                if (Util.isPresent(this.rcm.room_setting.open_period_start_at)) {
                    let st = DateTime.fromSeconds(this.rcm.room_setting.open_period_start_at)
                    this.startDate = st.toFormat(`yyyy/MM/dd`)
                    this.startTimeStr = st.toFormat(`HH:mm`)
                }
            }

            if ([`end_time_only`, `start_and_end_time`].includes(this.rcm.room_setting.open_period_type)) {
                if (Util.isPresent(this.rcm.room_setting.open_period_end_at)) {
                    let et = DateTime.fromSeconds(this.rcm.room_setting.open_period_end_at)
                    this.endDate = et.toFormat(`yyyy/MM/dd`)
                    this.endTimeStr = et.toFormat(`HH:mm`)
                }
            }
        }
    }

    selectOpenPeriodType(e) {
        Logger(`${funcName()} e:${e}`)
        let choice = SelectUtil.openPeriodTypes.find(mt => mt.name == e)
        // if (choice) this.magRuleOption = choice.type
        this.rcm.room_setting.open_period_type = choice.type
        if (choice.type == `none`) {
            this.rcm.room_setting.open_period_start_at = null
            this.rcm.room_setting.open_period_end_at = null
            this.rcm.updateRoomSetting(this.rcm.room_setting)
        } else if (choice.type == `start_time_only`) {
            this.rcm.room_setting.open_period_end_at = null
            this.updateStartDate()
        } else if (choice.type == `end_time_only`) {
            this.rcm.room_setting.open_period_start_at = null
            this.updateEndDate()
        } else if (choice.type == `start_and_end_time`) {
            // 何もしない
            this.updateStartDate()
            this.updateEndDate()
        }

        // this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    @Watch(`startDate`)
    updateStartDate() {
        let time = DateTime.fromFormat(this.startDate + ` ` + this.startTimeStr, `yyyy/MM/dd HH:mm`)
        Logger(`${funcName()} time:${time.toISO()}, startDate:${this.startDate}, startTimeStr:${this.startTimeStr}`)
        if (
            this.rcm.room_setting.open_period_type == `start_and_end_time` &&
            Util.isPresent(this.startDate) &&
            Util.isPresent(this.endDate) &&
            this.startDate > this.endDate
        ) {
            this.errMsgSpan = `公開期間の終了日は開始日よりも後の日付を指定してください。`
            return
        }
        this.errMsgSpan = null
        this.rcm.room_setting.open_period_start_at = time.toSeconds()
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    @Watch(`endDate`)
    updateEndDate() {
        let time = DateTime.fromFormat(this.endDate + ` ` + this.endTimeStr, `yyyy/MM/dd HH:mm`)
        Logger(`${funcName()} time:${time.toISO()}, endDate:${this.endDate}, endTimeStr:${this.endTimeStr}`)

        if (
            this.rcm.room_setting.open_period_type == `start_and_end_time` &&
            Util.isPresent(this.startDate) &&
            Util.isPresent(this.endDate) &&
            this.startDate > this.endDate
        ) {
            this.errMsgSpan = `公開期間の終了日は開始日よりも後の日付を指定してください。`
            return
        }
        this.errMsgSpan = null
        this.rcm.room_setting.open_period_end_at = time.toSeconds()
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    public selectStartTime(e) {
        Logger(`${funcName()} sttime:${e}`)
        this.startTimeStr = e
        this.updateStartDate()
        // this.changedSomeStatus = true
    }

    public selectEndTime(e) {
        Logger(`${funcName()} ettime:${e}`)
        this.endTimeStr = e
        this.updateEndDate()
        // this.changedSomeStatus = true
    }

    getDefaultOpenPeriodtype() {
        const defaultValue = `none`
        const typeArray = SelectUtil.openPeriodTypes
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.rcm.room_setting) return defaultName

        const currentOpt = typeArray.find(t => t.type == this.rcm.room_setting.open_period_type)

        return currentOpt ? currentOpt.name : defaultName
    }
}
