
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import InstantRoomSettings from "packs/pages/link/parts/room_settings/InstantRoomSettings.vue"
import { Dropdown } from "uiv"
import OptionalConnectedCalendar from "packs/pages/link/parts/available_schedule/OptionalConnectedCalendar.vue"
import HubspotNotificationContent from "packs/pages/link/settings/HubspotNotificationContent.vue"
import SalesforceNotificationContent from "packs/pages/link/settings/SalesforceNotificationContent.vue"
import RoomSettingsAssignFormContent from "packs/pages/link/parts/room_settings/room_settings_parts/RoomSettingsAssignFormContent.vue"
import GoogleSpreadSheetNotificationContent from "packs/pages/link/settings/GoogleSpreadSheetNotificationContent.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomManager from "packs/models/RoomManager"
import RoomSetting from "packs/models/RoomSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import SelectUtil from "packs/utils/SelectUtil"
import Const from "packs/utils/Const"
import CustomNotification from "packs/models/CustomNotification"

@Options({
    components: {
        MdTextfieldTag,
        CheckboxTag,
        SelectTag,
        InstantRoomSettings,
        Dropdown,
        OptionalConnectedCalendar,
        HubspotNotificationContent,
        SalesforceNotificationContent,
        RoomSettingsAssignFormContent,
        GoogleSpreadSheetNotificationContent,
    },
})
export default class RoomDetailSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    @Prop() // public_room / parent_room
    from: string

    @Prop() // func
    editProviderEvent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false
    rcm = RoomCreateManager
    rm = RoomManager

    Util = Util
    CalendarUtil = CalendarUtil
    SelectUtil = SelectUtil
    Const = Const

    eventTitle = ``
    eventDesc = ``
    hubspotDic = null
    salesforceDic = null
    googleSpreadSheetDic = null

    mounted() {
        this.updateRoomSetting()
    }

    clickExpirationType() {
        let rs = { ...this.rcm.room_setting }

        if (rs.expiration_type == `auto_close`) {
            rs.expiration_type = `permanent`
        } else {
            rs.expiration_type = `auto_close`
        }
        this.rcm.updateRoomSetting(rs)
    }

    clickShareType() {
        let rs = { ...this.rcm.room_setting }

        if (rs.share_type == `self`) {
            rs.share_type = `all`
        } else {
            rs.share_type = `self`
        }
        this.rcm.updateRoomSetting(rs)
    }

    inputGACrossDomains(e) {
        Logger(`${funcName()} `)
        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.ga_cross_domains = e
        this.rcm.updateRoomSetting(setting)
    }

    blurGACrossDomains() {
        Logger(`${funcName()} `)
        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.ga_cross_domains = this.rcm.room_setting.ga_cross_domains
        this.rcm.updateRoomSetting(setting)
    }

    inputEmailDomainsWhitelist(e: string) {
        let list = (e || ``).split(`,`).map(l => l.trim())

        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.email_domains_whitelist = list
        this.rcm.updateRoomSetting(setting)
    }

    inputEmailDomainsBlacklist(e: string) {
        let list = (e || ``).split(`,`).map(l => l.trim())

        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.email_domains_blacklist = list
        this.rcm.updateRoomSetting(setting)
    }

    public inputIPWhitelist(e: string) {
        let list = (e || ``).split(`,`).map(l => l.trim())

        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        Logger(`inputIPWhitelist list: ${list}, setting: ${Util.output(setting)}`)
        setting.ip_addresses_whitelist = list
        this.rcm.updateRoomSetting(setting)
    }

    public inputIPBlacklist(e: string) {
        let list = (e || ``).split(`,`).map(l => l.trim())
        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        Logger(`inputIPBlacklist list: ${list}, setting: ${Util.output(setting)}`)
        setting.ip_addresses_blacklist = list
        this.rcm.updateRoomSetting(setting)
    }

    get showShareTeam() {
        if (Util.isBlank(this.rm.userInfo)) return false
        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_create_private`)
    }

    selectAddEventType(e) {
        let typeDic = CalendarUtil.selectAddEventTypes.find(t => t.name == e)
        if (!typeDic) return

        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.add_event_type = typeDic.type
        this.rcm.updateRoomSetting(setting)
    }

    clickIPAddresses() {
        this.rm.canUseOption(`room_restriction_ip_addresses`, true)
    }

    clickEmailDomains() {
        this.rm.canUseOption(`room_restriction_email_domains`, true)
    }

    get canUseInstantRoom() {
        return this.rm.canUseOption(`room_instant_room`, false)
    }

    get availableFixType() {
        if (!this.rcm.room_setting.available_fix_min_before_appo_start_time) {
            return `設定しない`
        }
        return `設定する`
    }

    selectAvailableFix(e) {
        Logger(`selectAvailableFix: ${e}`)
        let dic = SelectUtil.availableOrNotTypes.find(span => span.name == e)
        if (dic) {
            this.rcm.room_setting.available_fix_min_before_appo_start_time = dic.number
        }
    }

    updateAvailableFix(mins: number) {
        Logger(`updateAvailableFix: mins: ${mins}`)
        if (mins == 0) mins = null

        this.rcm.room_setting.available_fix_min_before_appo_start_time = mins
    }

    selectCanChangeAppoFromOther(e) {
        Logger(`selectCanChangeAppoFromOther: ${e}`)
        let dic = SelectUtil.canChangeOrNot.find(span => span.name == e)
        if (dic) {
            this.rcm.room_setting.can_change_appointment_from_other = dic.number
        }
    }

    selectAutoShareMyRoomType(e) {
        Logger(`selectAutoShareMyRoomType: ${e}`)
        let dic = SelectUtil.autoShareMyRoomTypes.find(span => span.name == e)
        if (dic) {
            this.rcm.room_setting.auto_share_my_room_type = dic.type
            if (dic.type == `none`) {
                this.rcm.room_setting.auto_share_public_id = null
            }
        }
    }

    selectAdditionalMailSubjectType(e) {
        Logger(`selectAdditionalMailSubjectType: ${e}`)
        let dic = SelectUtil.additionalMailSubjectTypes.find(span => span.name == e)
        if (dic) {
            this.rcm.room_setting.additional_mail_subject_type_for_owner = dic.type
        }
    }

    inputAdditionalMailSubjectForOwner(e) {
        Logger(`inputAdditionalMailSubjectForOwner: ${e}`)
        this.rcm.room_setting.additional_mail_subject_for_owner = e
    }

    @Watch(`rcm.room_setting`, { deep: true })
    updateRoomSetting() {
        if (!this.rcm.room_setting) return

        if (this.rcm.room_setting.provider_event_title || this.rcm.room_setting.provider_event_description) {
            let title = this.rcm.room_setting.provider_event_title
            let desc = this.rcm.room_setting.provider_event_description

            this.eventTitle = this.rcm.insertTemplateText(title)
            this.eventDesc = this.rcm.insertTemplateText(desc)
        }

        this.rm.getNotifications().then(dic => {
            if (this.rcm.room_setting.custom_notifications) {
                this.hubspotDic =
                    (this.rcm.room_setting.custom_notifications || []).find(noti => noti.provider == `hubspot`) ||
                    CustomNotification.createDefault(`room_setting`, `hubspot`)
                Logger(`${funcName()} hubspotDic:${Util.output(this.hubspotDic)}`)
                this.salesforceDic =
                    (this.rcm.room_setting.custom_notifications || []).find(noti => noti.provider == `salesforce`) ||
                    CustomNotification.createDefault(`room_setting`, `salesforce`)
                Logger(`${funcName()} salesforceDic:${Util.output(this.salesforceDic)}`)
                this.googleSpreadSheetDic =
                    (this.rcm.room_setting.custom_notifications || []).find(noti => noti.provider == `google_spread_sheet`) ||
                    CustomNotification.createDefault(`room_setting`, `google_spread_sheet`)
                Logger(`${funcName()} googleSpreadSheetDic:${Util.output(this.googleSpreadSheetDic)}`)
            } else {
                this.hubspotDic = CustomNotification.createDefault(`room_setting`, `hubspot`)
                this.salesforceDic = CustomNotification.createDefault(`room_setting`, `salesforce`)
                this.googleSpreadSheetDic = CustomNotification.createDefault(`room_setting`, `google_spread_sheet`)
            }
        })
    }

    inputGAId(e) {
        this.rcm.room_setting.google_analytics_id = e
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    clickMaskMembersInfo() {
        if (this.rcm.room_setting.mask_members_info) {
            this.rcm.room_setting.mask_members_info = false
        } else {
            this.rcm.room_setting.mask_members_info = true
        }
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    updateNotification(noti: any, provider: string) {
        let notis
        if (provider == `hubspot`) {
            this.hubspotDic = noti
            notis = (this.rcm.room_setting.custom_notifications || []).filter(noti => noti.provider != `hubspot`)
        } else if (provider == `salesforce`) {
            this.salesforceDic = noti
            notis = (this.rcm.room_setting.custom_notifications || []).filter(noti => noti.provider != `salesforce`)
        } else if (provider == `google_spread_sheet`) {
            this.googleSpreadSheetDic = noti
            notis = (this.rcm.room_setting.custom_notifications || []).filter(noti => noti.provider != `google_spread_sheet`)
        }
        if (Util.isPresent(noti)) notis.push(noti)
        this.rcm.room_setting.custom_notifications = notis
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    createFieldConverter() {
        this.rcm.showLeftPanelContentWithType = `field_converter`
    }
}
