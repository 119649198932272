
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import ConsiderationTravelMode from "packs/pages/link/parts/available_schedule/ConsiderationTravelMode.vue"
import OptionalConnectedCalendar from "packs/pages/link/parts/available_schedule/OptionalConnectedCalendar.vue"
import AssignMagContent from "packs/pages/link/parts/available_schedule/AssignMagContent.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import InstantRoomAssignedUser from "packs/models/InstantRoomAssignedUser"

@Options({
    components: {
        MdTextfieldTag,
        SelectTag,
        ConsiderationTravelMode,
        OptionalConnectedCalendar,
        AssignMagContent,
        CheckboxTag,
        UserIcon,
        Switcher,
        TextfieldTag,
    },
})
export default class InstantRoomGroupConnectField extends Vue {
    @Prop()
    mag: MeetingAttendeesGroup

    @Prop()
    members: RoomMember[]

    @Prop()
    assignedMembers: InstantRoomAssignedUser[]

    @Prop()
    magindex: number

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting
    cm = CalendarManager

    startTimeStr = `10:00`
    notice = Notice

    mags: MeetingAttendeesGroup[] = null
    connectEmail = null
    selectedMember: InstantRoomAssignedUser = null

    mounted() {
        if (this.rcm.astag) {
            this.mags = this.rcm.astag.meeting_attendees_groups
        }

        this.updateAssignedMembers()
    }

    @Watch(`assignedMembers`, { deep: true })
    updateAssignedMembers() {
        Logger(`${funcName()} assignedMembers:${(this.assignedMembers || []).length} magindex:${this.magindex}`)
        if (!this.assignedMembers) return

        let userInfo: InstantRoomAssignedUser = this.assignedMembers[this.magindex]
        Logger(`${funcName()} selectedMember:${Util.output(userInfo)}`)
        if (userInfo) {
            this.selectedMember = userInfo
        }
    }

    hasProfile(mag) {
        Logger(`hasProfile: ${Util.output(mag.profile_image_urls)}`)
        return (mag.profile_image_urls && mag.profile_image_urls[0]) || (mag.description && mag.description.length > 0)
    }

    inputEmail(e) {
        Logger(`email: ${e}`)
        this.connectEmail = e
    }

    get isActive() {
        if (!this.selectedMember) return false
        return this.selectedMember.is_active
    }

    changeActive() {
        if (!this.selectedMember || !this.selectedMember.line_instant_room_assigned_user_id) return
        if (this.selectedMember.is_active) {
            this.selectedMember.is_active = false
        } else {
            this.selectedMember.is_active = true
        }
        this.$emit(`changeActive`, this.selectedMember)
    }

    connectMember() {
        Logger(`${funcName()} email:${this.connectEmail}`)
        let mem = this.members.find(m => m.email == this.connectEmail)
        if (!mem) return
        this.$emit(`connectMember`, mem, this.mag, this.magindex)
    }

    disconnect() {
        this.$emit(`connectMember`, null, this.mag, this.magindex)
    }
}
