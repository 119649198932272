import { DateTime } from "luxon"
import axios from "axios"
import { zeroPadding, Logger } from "../common"
import Notice from "./Notice"
import Util from "packs/utils/Util"
import DateTag from "./DateTag"
import NotificationControl from "packs/utils/NotificationControl"

export default class CustomNotification {
    /**
     *
     * @param id
    STATUS_DRAFT = 1 # 下書き
    STATUS_HIDE = 10 # 非表示/停止中
    STATUS_SHOW = 100 # 公開済み
    STATUS_DELETE = -1 # 削除

    RELEASE_TYPE_NOTIFY_IN_PAGE = 1 # ログイン後の「お知らせ」で表示
    RELEASE_TYPE_NOTIFY_MAIL = 2 # メールでお知らせ
    RELEASE_TYPE_TEST = 3 # リリーステスト
    RELEASE_TYPE_ALL = 100 # メールとログイン後の画面でお知らせ
     * @param status
     * @param release_type
     * @param title
     * @param content
     * @param sent_count
     * @param mark_as_read_num
     * @param systemUpdatedAt
     */
    constructor(
        public id: string,
        public provider: string,
        public events: string[],
        public settings: any, // webhook_url
        public scope: string,
        public private_memo: string,
        public user_id: string,
        public user_group_id: string,
        public account_id: string
    ) {}

    static fetchFromJson(groupsJson: any): CustomNotification[] {
        let notifications = []
        if (Util.isBlank(groupsJson) || groupsJson.length == 0) return notifications
        const now = DateTime.local()

        Logger(`Notification arrOfGroups: ${Util.output(groupsJson.length)}`)
        for (const noti of groupsJson) {
            let _noti: CustomNotification = noti

            notifications.push(_noti)
        }

        return notifications
    }

    static createDefault(scope: string = `user_group`, provider: string = `webhook`): CustomNotification {
        const today = Util.getSec()
        let createdAtText = DateTag.getTimeStringSlashFromSeconds(today)
        /**
         * public id: string,
        public provider: string,
        public events: string[],
        public settings: any, // webhook_url
        public scope: string,
        public private_memo: string,
        public user_id: string,
        public user_group_id: string,
         */
        return new CustomNotification(`new`, provider, [], { webhook_url: null }, scope, null, null, null, null)
    }

    static copy(o: CustomNotification): CustomNotification {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        return clone
    }

    /**
     * Scopeに対応するエンドポイントを返却します。
     * @param scope [string] user/user_group
     */
    static endpoint(scope: string) {
        if (scope == "user_group") {
            return `${Util.prefixUrl}/user_groups/custom_notifications`
        } else if (scope == "user") {
            return `${Util.prefixUrl}/user_settings/custom_notifications`
        } else if (scope == "room_setting") {
            return `${Util.prefixUrl}/room_settings/custom_notifications`
        } else {
            Notice.message = `不正なリクエストです。(scope:${scope} is unknown.)`
            return
        }
    }

    /**
     * 通知を取得してきます.
     * @param scope [string] user/user_group
     */
    static get(scope: string = `user`, params = {}): Promise<any> {
        if (!params) {
            params = {}
        }
        params["scope"] = scope

        return axios
            .get(`${CustomNotification.endpoint(scope)}`, { params: params })
            .then(e => {
                let dic = e.data
                Logger(`CustomNotification.get custom通知情報: ${Util.output(dic)}`)
                return dic
            })
            .catch(err => {
                Logger(`CustomNotification.get err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 連携可能な連携済みの通知を取得します.
     * @param providerName
     * @param providers
     * @returns
     */
    static getIntegNotifications(providerName: string = `hubspot`, providers: string[] = []): Promise<any> {
        let uri = `${Util.prefixUrl}/user_settings/notifications`

        return axios
            .get(`${uri}`, { params: { provider_name: providerName, provider_names: providers } })
            .then(e => {
                let notis = e.data.notifications
                Logger(`CustomNotification.getIntegNotifications custom通知情報: ${Util.output(notis)}`)
                return { notifications: notis, available_events: e.data.available_events }
            })
            .catch(err => {
                Logger(`CustomNotification.getIntegNotifications err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * 作成したカスタム通知を 作成もしくは更新リクエストを送信します。
     * @param noti [CustomNotification]
     */
    static save(noti: CustomNotification) {
        let dic = { provider: noti.provider, settings: noti.settings, events: noti.events }
        return axios({
            method: `post`,
            url: CustomNotification.endpoint(noti.scope),
            data: dic,
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                let notice = Notice
                if (res.data.message) {
                    notice.message = `${res.data.message}`
                }
                if (noti.provider == `waaq_api_keys`) {
                    return res.data.notification
                } else {
                    let noti = CustomNotification.fetchFromJson([res.data.notification])[0]
                    return noti
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return
            })
    }

    /**
     * カスタム通知を 作成もしくは更新リクエストを送信します。
     * @param provider [String]
     * @param scope [String]
     */
    static delete(provider: string, scope: string, keyId: string = null) {
        return axios({
            method: `delete`,
            url: CustomNotification.endpoint(scope),
            data: { provider: provider, key_id: keyId },
        })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return true
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static hubspotRegisterTypes() {
        return [
            { value: "hs_contacts", name: "コンタクトのみ作成" },
            { value: "hs_meetings", name: "コンタクト、ミーティングを作成" },
            { value: "hs_meetings_and_deals", name: "コンタクト、ミーティング、取引を作成" },
        ]
    }

    static salesforceRegisterTypes() {
        return [
            { value: "sf_leads", name: "リードのみ作成" },
            { value: "sf_events", name: "リード、行動(Event)を作成" },
        ]
    }

    static getGoogleSpreadSheetFileName(parentId = null) {
        return axios
            .get(`${Util.prefixUrl}/room_settings/google_spread_sheet_file_lists`, { params: { parent_id: parentId } })
            .then(res => {
                Logger(`CustomNotification.getGoogleSpreadSheetFileName res:${res.data.message} data:${Util.output(res.data)}`)
                return res.data.spreadsheet_name
            })
            .catch(err => {
                Logger(`CustomNotification.getGoogleSpreadSheetFileName err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }
}
