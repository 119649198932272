import axios from "axios"
import { DateTime } from "luxon"
import NotificationControl from "packs/utils/NotificationControl"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import PossibleDate from "./PossibleDate"

export default class Event {
    constructor(
        // "optional_add", "optional_remove", "possible_event", "event"
        public id: string,
        public type: string,
        public title: string,
        public description: string,
        public calendar_id: string,
        public start_time: number,
        public start_hour: number,
        public start_min: number,
        public duration: number,
        public allday: boolean,
        public ignore_allday: boolean,
        public location: string,
        public is_match_available_keyword: boolean,
        public is_match_ignore_keyword: boolean,
        public provider_event_id: string,
        public short_format: string,
        public account: any, // dictionary {id:, search_key: , provider: , email: , is_owner}

        public parent_room_id: string,
        public parent_room_public_id: string,
        public parent_room_url: string,
        public public_room_id: string,
        public public_room_public_id: string,
        public is_online_meeting: boolean,
        public online_meeting_url: string,
        // 今作成した選択中の日程です.
        public isSelecting: boolean,
        public is_force: boolean,
        public max_bookings_num: number,

        // 最終更新日時（アップデートした際にセルを動的に更新するために利用）
        public systemUpdatedAt: number
    ) {}

    static copy(o: Event): Event {
        return JSON.parse(JSON.stringify(o)) as Event
    }

    /**
     * 主にオプショナルイベント、PossibleDatesを作成します。
     * @param type [string] "optional_add", "optional_remove"
     * @param startTime [DateTime]
     * @param duration [number]
     * @param isForce [boolean]
     * @return event [Event]
     */
    static createOptional(type: string, startTime, duration, isForce = false): Event {
        let startHour = startTime.hour

        let system = DateTime.local().toMillis() + startHour
        return new Event(
            null,
            type,
            "",
            "customEvent",
            null,
            startTime.toSeconds(),
            startHour,
            startTime.minute,
            duration,
            false,
            false,
            "",
            false,
            false,
            null,
            startTime.toFormat(`MMddHHmm`),
            {},
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            false,
            isForce,
            null,
            system
        )
    }

    /**
     * 定期空き予定の可能日時用イベントを作成します。（この情報をリモートに送るわけではないのでstart_timeは未入力)
     *
     * @param startHour [number]
     * @param startMin [number]
     * @param duration [number]
     * @return event [Event]
     */
    static createAvailable(startHour, startMin, duration) {
        let system = DateTime.local().toMillis() + startHour
        return new Event(
            null,
            "available",
            "",
            null,
            "availableZone",
            -1,
            startHour,
            startMin,
            duration,
            false,
            false,
            "",
            true,
            false,
            null,
            "",
            {},
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            false,
            false,
            null,
            system
        )
    }

    /**
     * 曜日ごとに出席可能な時間をまとめてイベント化していきます。
     * @params avtimes [Hash] railsと同じように wday[number]: available_hours[number[]] ex.) {0: [8,9,10,12,17,18,19,20,21]}
     * => {0: [Event(start_hour:8,duration:180),Event(start_hour:12,duration:60),Event(start_hour:17,duration:300)]  }
     */
    static changeEventFromZone(avtimes) {
        let avtimezone = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }
        for (const [wday, _avtime] of Object.entries(avtimes)) {
            const avtime = _avtime as number[] // [8,9,10,12,17,18,19,20,21]
            let avzone = []
            let st = -1
            let duration = 15
            // avtimeは現在15分ごとが入っている. 8.25, 8.5 8.75 9
            for (const _avt of avtime) {
                const avt = _avt // 出席可能な時間
                if (st == -1) {
                    st = avt
                    continue
                }
                if (st + duration / 60 == avt) {
                    duration += 15
                } else {
                    let sh = Math.floor(st)
                    let sm = st - sh
                    avzone.push(Event.createAvailable(st, sm, duration))
                    st = avt
                    duration = 15
                }
            }
            if (st != -1) {
                avzone.push({
                    type: "available",
                    title: "",
                    calendar_id: "availableZone",
                    start_hour: st,
                    start_min: 0,
                    duration: duration,
                    systemUpdatedAt: DateTime.local().toMillis() + st,
                })
            }
            avtimezone[wday] = avzone
        }
        // this.avtimezone = avtimezone;
        return avtimezone
    }

    /**
     * 同じイベントかどうかを内容から判断します。
     * @param ev1 [Event]
     * @param ev2 [Event]
     */
    static isSame(ev1: Event, ev2: Event, withType: boolean = false): boolean {
        if (!ev1 || !ev2) return false
        return withType
            ? ev1.start_time == ev2.start_time &&
                  ev1.start_min == ev2.start_min &&
                  ev1.duration == ev2.duration &&
                  ev1.type == ev2.type
            : ev1.start_time == ev2.start_time && ev1.start_min == ev2.start_min && ev1.duration == ev2.duration
    }

    /**
     * 外部連携カレンダーにイベントを登録します.
     * @param params
     */
    static save(params: any): Promise<any> {
        return axios
            .post(`${Util.prefixUrl}/calendar/events`, params)
            .then(res => {
                Notice.message = res.data.message
                return res.data
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    // 2つのイベント時間帯がかぶっているか確認します.
    static matchEvents(ev1, ev2) {
        let st1 = ev1.start_time
        let et1 = ev1.start_time + ev1.duration * 60
        let st2 = ev2.start_time
        let et2 = ev2.start_time + ev2.duration * 60
        if (st1 < st2 && et1 > st2) {
            return true
        }
        if (st1 < et2 && et1 > et2) {
            return true
        }
        return false
    }

    /**
     *
     * @param ev イベント durationは幅広です.
     * @param duration 会議時間（分）
     * @param stepSize 分
     * @returns
     */
    static toSeparatePossibleDates(ev: Event, duration: number, stepSize: number) {
        Logger(`toSeparatePossibleDates: ev=${Util.output(ev)}, duration=${duration}, stepsize: ${stepSize}`)
        if (!ev) return []

        let evLength = ev.duration // イベントの長さ（幅広のため）
        let pds = []
        let evEndtime = ev.start_time + ev.duration * 60
        // 1時間毎 2h 120 / 60
        // 30分毎 120/30 = 4
        // 15分毎 120/15 = 8
        let isEndtime = false
        let i = 0
        while (!isEndtime) {
            let start = ev.start_time + i * stepSize * 60
            // let startTime = DateTime.fromSeconds(start)
            let _pd = PossibleDate.createFromStartTime(start, duration)
            _pd.max_bookings_num = ev.max_bookings_num
            _pd.booked_num = 0
            Logger(`pdを作成しました[${i}]: ${Util.output(_pd)}`)
            if (_pd.start_time + _pd.duration * 60 > evEndtime) {
                isEndtime = true
                break
            }
            pds.push(_pd)

            i += 1
            if (i > 100) {
                Logger(`100件作成して、これ以上不要のためストップします.`)
                isEndtime = true
                break
            }
        }

        // let posLength = Math.floor(evLength / duration) + Math.floor(evLength / 60) - 1
        // let posLength = Math.floor((evLength / duration) * (60 / stepSize))
        // Logger(`toSeparatePossibleDates: ${posLength}`)
        // for (let i = 0; i < posLength; i++) {
        //     let start = ev.start_time + i * stepSize * 60
        //     // let startTime = DateTime.fromSeconds(start)
        //     let _pd = PossibleDate.createFromStartTime(start, duration)
        //     Logger(`pdを作成しました[${i}]: ${Util.output(_pd)}`)
        //     pds.push(_pd)
        // }
        return pds
    }

    // static toPossibleDate(ev) {
    //     let time = DateTime.fromSeconds(ev.start_time)

    //     /* {"type":"possible_event",
    //     "date_format":"0526",
    //     "short_format":"05261300",
    //     "start_time":1590465600,
    //     "start_hour":13,
    //     "start_min":0,
    //     "wday":2,
    //     "duration":60,
    //     "format":"2020-05-26-13:00",
    //     "jp_format":"5月26日（火）13:00~14:00","ok_groups":["5ec79e4864516c1455adfbe6","5ec79e4e64516c1455adfcb6"],"ok_attendees":[],"ng_attendees":[],"unknown_attendees":[]},
    //     */
    //     return new PossibleDate(``, time.toFormat("yyyy-MM-dd-HH:mm", time.toFormat("MMddHHmm")), ev.start_time, ev.type, )
    // }

    // static fetchFromJson(dateTagsJson: any): DateTag[] {
    //     let dateTags = []
    //     const arrOfDateTag = JSON.parse(dateTagsJson)
    //     Logger(`arrOfDateTag: ${arrOfDateTag.length}`)
    //     for (let dateTag of arrOfDateTag) {
    //         const _dateTag: DateTag = dateTag
    //         let events = DateTag.toWeeklyEvents(_dateTag)
    //         Logger(`events: ${events}`)
    //         dateTags.push(_dateTag)
    //     }
    //     return dateTags
    // }

    // static fetchFromAstag(astag: any): DateTag {
    //     let _dateTag: DateTag = astag
    //     _dateTag.days_of_the_week = astag.available_days_of_weeks
    //     _dateTag.updated_at = DateTime.local().toMillis()
    //     _dateTag.systemUpdatedAt = DateTime.local().toMillis()
    //     _dateTag.calendar_tags = astag.calendar_tags

    //     return _dateTag
    // }
}
