
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, getLang, funcName } from "packs/common"
import { gtagLog, gtagClick, gtagPage, gtagEvent, gtagEventOwner, gtagPageOwner } from "packs/GoogleTagManager"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMember from "packs/models/RoomMember"
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import PossibleDate from "packs/models/PossibleDate"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import Notice from "packs/models/Notice"
import RoomSetting from "packs/models/RoomSetting"
import FormUtil from "packs/utils/FormUtil"
import CalendarManager from "packs/models/CalendarManager"
import Refresher from "packs/models/Refresher"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarUtil from "packs/utils/CalendarUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        UserIcon,
    },
})
export default class InstantRoomContent extends Vue {
    // data:

    @Prop()
    loading: boolean

    @Prop()
    isEdit: boolean

    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone
    isEmbedsInstantButton = Util.isEmbedsInstantButton()

    astag: AvailableScheduleTag = null
    href
    currentPossibleDates = null
    Util = Util
    refresher = Refresher
    Appointment = Appointment
    loadingFormSending = false
    exceptSelf = false
    // parentId = null

    // selectedMessageRoom: Room = null

    gettingPds: boolean = false
    appoStatus = Appointment.typeAddNew

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.
    rcm = RoomCreateManager

    rs: RoomSetting = null
    appo: Appointment = null
    room: Room = null
    owner: RoomMember = null

    currentPossibleDate = null
    instantRoom = null
    additionalParams = null

    get showInstantRoomContent() {
        // return true

        let room = this.rm.currentRoom
        if (this.isPreview) {
            room = this.rcm.room
        }
        if (!room) return false

        let appo = room.current_appointment
        if (appo && appo.status == 10) return false

        let rs = room.room_setting
        this.room = room
        this.appo = appo
        this.owner = room.owner
        this.rs = rs
        let isInstant = rs.is_instant_room

        if (this.isPreview && isInstant) {
            return true
        }

        if (this.isEmbedsInstantButton && isInstant && this.instantRoom) {
            // this.rm.currentInstantRoom = room
            return true
        }

        let useInstant = rs.use_instant_room
        if (!useInstant) return false
        if (!this.currentPossibleDate) return
        if (this.pdm.displayType == `reschedule`) return false

        return true
    }

    get bgColor() {
        if (!this.additionalParams) return null
        return this.additionalParams.background_color || null
    }

    get hideShadow() {
        if (!this.additionalParams) return false
        return this.additionalParams.hide_shadow == `true`
    }

    created() {
        this.getAdditionalParamsInstantRoom()
    }

    public mounted() {
        let room = this.rm.currentRoom
        if (!room) return

        this.getPossibleAfterGotMainPossibleDates()
    }

    get buttonTitle() {
        let defaultText = `今すぐ相談可能です！`
        if (this.isEdit) {
            let rs = this.rcm.room_setting
            if (rs && rs.is_instant_room) {
                return rs.instant_room_button_title || defaultText
            }
            return defaultText
        }
        let room = this.rm.currentInstantRoom
        if (!room) return defaultText
        let rs = room.room_setting
        if (!rs) return defaultText
        return rs.instant_room_button_title || defaultText
    }

    get buttonSmallText() {
        let defaultText = `こちらからご予約ください`
        if (this.isEdit) {
            let rs = this.rcm.room_setting
            if (rs && rs.is_instant_room) {
                return rs.instant_room_button_small_text || defaultText
            }
            return defaultText
        }
        let room = this.rm.currentInstantRoom
        if (!room) return defaultText
        let rs = room.room_setting
        if (!rs) return defaultText
        return rs.instant_room_button_small_text || defaultText
    }

    get buttonDescription() {
        let defaultText = `最短10分でご案内できます。${this.isSP ? `下` : `右`}のボタンよりフォームに回答後、お待ち下さい。`
        if (this.isEdit) {
            let rs = this.rcm.room_setting
            if (rs && rs.is_instant_room) {
                return rs.instant_room_description || defaultText
            }
            return defaultText
        }
        let room = this.rm.currentInstantRoom
        if (!room) return defaultText
        let rs = room.room_setting
        if (!rs) return defaultText
        return rs.instant_room_description || defaultText
    }

    /**
     * PDM取得後、スグINボタンの取得を開始します.
     */
    @Watch(`pdm.possibleDates`)
    getPossibleAfterGotMainPossibleDates() {
        if (!this.pdm.possibleDates) return
        let room = this.rm.currentRoom

        if (Appointment.isFix(room) || Appointment.isCanceled(room)) {
            return
        }

        this.getPossibleInstantRoom()
    }

    getPossibleInstantRoom() {
        Logger(`${funcName()} rs:${this.rs}`)

        if (!this.rs) return
        if (this.pdm.displayType == `reschedule`) return

        let instantRoomId = null
        if (this.rs.use_instant_room) {
            instantRoomId = this.rs.instant_room_id
        } else if (this.rs.is_instant_room) {
            instantRoomId = this.room.id
        }
        // if (!this.rs.use_instant_room) return
        if (!instantRoomId) return

        if (this.gettingPds) return
        this.gettingPds = true

        Room.getPossibleInstantRoom(instantRoomId).then(_res => {
            this.gettingPds = false
            if (_res.success) {
                let availableInstantRoom = _res.res.data.available_instant_room
                if (!availableInstantRoom) return

                let pds = _res.res.data.possible_dates
                let room = Room.fetchFromJson([_res.res.data.instant_room], this.rm.userInfo.user_id)[0]
                Logger(`instant_roomの情報を取得しました. ${Util.output(pds)}`)
                if (Util.isPresent(pds)) {
                    this.currentPossibleDate = pds[0]
                    this.instantRoom = room
                    this.rm.currentInstantRoom = room
                }
            }
        })
    }

    clickInstantButton() {
        if (this.isEmbedsInstantButton) {
            // iframe形式の場合モーダルを開けないため、指定のページに移動します.
            let url = this.additionalParams.redirect_to
            window.top.location.href = url || `https://link.waaq.jp`
            return
        }
        this.pdm.isInstantRoom = true
        this.$emit(`clickInstantButton`, this.currentPossibleDate)
    }

    /**
     * パラメータがURLに付随している場合は、自動でそのフィールドを埋めます
     */
    getAdditionalParamsInstantRoom() {
        this.additionalParams = FormUtil.getParams()
        Logger(`${funcName()} additionalParams:${Util.output(this.additionalParams)}`)
    }

    get hideFromParams() {
        if (Util.isBlank(this.additionalParams)) return false
        if (this.additionalParams.hide_instant_comment == `true`) return true
        return false
    }
}
