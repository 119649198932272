
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import AttendeeForm from "packs/pages/link/parts/AttendeeForm.vue"
import ModalUpload from "packs/pages/link/modals/ModalUpload.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import ContactListGroup from "packs/models/ContactListGroup"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        TextfieldTag,
        UserSettingsHeader,
        AddressBookContent,
        AttendeeForm,
        ModalUpload,
        MdTextfieldTag,
        UserIcon,
        TextareaTag,
        OptionModal,
    },
})
export default class UserSettingsAddressBook extends Vue {
    // data:
    sharedState = PropertyStore
    notice = Notice

    rm = RoomManager

    isSP = isMobile(window.navigator).phone
    astag: AvailableScheduleTag = null
    today = DateTime.local().setZone("Asia/Tokyo")

    Const = Const
    Util = Util
    truncate = truncate

    editOrNewForm = ``
    currentMember: RoomMember = null
    UserPermissionsOption = UserPermissionsOption

    loading = false

    // 表示するタイプを指定します。 address_book/new_group/edit_group
    selectedTab = `address_book`

    newGroup: ContactListGroup = ContactListGroup.createDefault(`新規作成`)
    editGroup: ContactListGroup = null
    clgs: ContactListGroup[] = []
    edittingGroup = null

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkPermission()

                // this.rm.getContactListGroups().then(clgs => {
                //     this.clgs = clgs
                // })
            })
        } else {
            this.checkPermission()
            // this.rm.getContactListGroups().then(clgs => {
            //     this.clgs = clgs
            // })
        }
    }

    mounted() {
        gtagPage("#/settings/address_books")
    }

    public addAttendee(member) {
        Logger(`memberを追加します : ${Util.output(member)}`)
        let list = this.rm.address_book.contact_list.filter(m => m.email != member.email)
        list.push(member)
        // let mem = this.invitedUsers.find(m => m.email == member.email);
        // if (mem) {
        //     mem = member;
        // } else {
        //     this.invitedUsers.push(member);
        // }
        this.editOrNewForm = ``

        this.$vfm.close("AttendeesModal")
    }

    public addAttendeeFromUnderBox(member) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        RoomMember.saveAddressBook(member, `post`).then(saved_user => {
            if (saved_user) {
                this.addAttendee(saved_user)
                let _mem = this.rm.address_book.contact_list.find(e => e.email == member.email && e.display_status == 10)
                if (_mem) {
                    // 既存ユーザーのアップデート
                    Notice.message = `アドレス帳の情報を更新しました。`
                    _mem = saved_user
                } else {
                    // 新規保存.
                    Notice.message = `アドレス帳に保存しました。`
                }
            }
            this.loading = false
            this.rm.endProgress()
        })

        // gtagClick(`出席者下から出席者を追加`, `出席者:${this.members.length}`);
    }

    public editAttendee(member) {
        // this.currentMember = member;
        this.editOrNewForm = `edit`
        // gtagClick(`出席者を編集`, `出席者:${this.members.length}`);
        // this.$vfm.open("AttendeesModal");
    }

    /**
     * ユーザーがアドレス帳から削除されたら表示を消します。
     */
    public deleteUser(user) {
        Logger(`削除されたユーザー: ${Util.output(user)}`)
        this.rm.address_book.contact_list = this.rm.address_book.contact_list.filter(u => u.user_id != user.user_id)
    }

    public openUploadModal() {
        this.$vfm.open("UploadModal")
    }

    public uploadedFile() {
        this.$vfm.close("UploadModal")
    }

    checkPermission() {
        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `user_settings`, `user_settings_address_book`)) {
            this.$router.push("/settings/user")
        }
    }

    createContactListGroup() {
        this.selectedTab = `new_group`
        let newGroup = ContactListGroup.createDefault(`新規作成`)
        this.edittingGroup = newGroup
    }

    editContactListGroup() {
        this.editGroup = null
        this.selectedTab = `edit_group`
        this.edittingGroup = null

        if (Util.isBlank(this.clgs)) {
            this.rm.getContactListGroups().then(clgs => {
                this.clgs = clgs
            })
        }
    }

    selectEditGroup(e) {
        Logger(`${funcName()} e:${Util.output(e)}`)
        let editGroup = this.clgs.find(clg => clg.name == e)
        if (editGroup.is_fetched) {
            this.edittingGroup = { ...editGroup }
        } else {
            ContactListGroup.fetch(editGroup.id).then(res => {
                if (res) {
                    let clg = res.contact_list_group
                    this.edittingGroup = { ...clg }

                    let _clg = this.clgs.find(clg => clg.id == res.contact_list_group.id)
                    _clg.is_fetched = true
                    _clg.contact_lists = clg.contact_lists
                }
            })
        }
    }

    inputNewGroupName(e) {
        Logger(`${funcName()} e:${Util.output(e)}`)
        this.edittingGroup.name = e
    }

    inputEditGroupName(e) {
        Logger(`${funcName()} e:${Util.output(e)}`)
        this.edittingGroup.name = e
    }

    inputPrivateMemo(e) {
        this.edittingGroup.private_memo = e
    }

    addAttendeeForGroup(member: RoomMember) {
        Logger(`${funcName()} member:${Util.output(member)}`)
        let cls = this.edittingGroup.contact_lists || []
        let currentMem = cls.find(mem => mem.user_id == member.user_id)
        if (currentMem) {
            Notice.message = `既に追加されています。`
            return
        }
        let po = this.rm.plan_option
        let maxNum = po.room_contact_list_group_register_max_num
        if (cls.length + 1 > maxNum) {
            this.rm.showPlanOptionModalWithName = `アドレス帳グループ 登録上限解放`
            return
        }

        cls.push(member)
        this.edittingGroup.contact_lists = cls
    }

    @Watch(`rm.showPlanOptionModalWithName`, { deep: true })
    updateShowPlanOptionModalWithName() {
        Logger(`updateShowPlanOptionModalWithName: ${this.rm.showPlanOptionModalWithName}`)
        if (this.rm.showPlanOptionModalWithName) {
            this.$vfm.open(`OptionModal`)
        } else {
            this.$vfm.close(`OptionModal`)
        }
    }

    clickCancel() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} キャンセル 課金リクエスト`, ``)
        this.rm.showPlanOptionModalWithName = null
    }

    closeOptionModal() {
        this.rm.showPlanOptionModalWithName = null
    }

    clickOK() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} 課金リクエスト`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.rm.showPlanOptionModalWithName} 課金リクエストがありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.rm.showPlanOptionModalWithName = null
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    removeMemberFromGroup(member: RoomMember) {
        Logger(`${funcName()} member:${Util.output(member)}`)
        this.edittingGroup.contact_lists = this.edittingGroup.contact_lists.filter(mem => mem.email != member.email)
    }

    cancelEditGroup() {
        if (!confirm(`保存していませんが、編集を終了しますか？`)) return
        this.selectedTab = `address_book`
        this.edittingGroup = null
    }

    saveGroup() {
        Logger(`${funcName()} id:${this.edittingGroup.id} ${(this.edittingGroup.contact_lists || []).length}人を保存`)
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        ContactListGroup.save(this.edittingGroup).then(res => {
            this.loading = false
            this.rm.endProgress()

            if (res) {
                let clg = res.contact_list_group
                this.rm.getContactListGroups().then(_clgs => {
                    let clgs = _clgs.filter(clg => clg.id != res.contact_list_group.id)
                    clgs.unshift(clg)
                    this.clgs = clgs
                    this.rm.address_book.contact_list_groups = clgs
                    this.selectedTab = `address_book`
                    this.edittingGroup = null
                })
            }
        })
    }
}
