import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "centered w100",
  style: {"padding-top":"10px"}
}
const _hoisted_2 = {
  class: "mc",
  style: {"width":"450px"}
}
const _hoisted_3 = {
  key: 1,
  class: "with_white_bg",
  style: {"padding":"30px 25px"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "fs13 fw400 text-left" }
const _hoisted_6 = {
  class: "fs18 fw600 text-left",
  style: {"color":"#222222","padding-bottom":"13px","line-height":"1.6"}
}
const _hoisted_7 = {
  key: 0,
  style: {"margin":"0","border-style":"solid none none none","border-top-width":"1px","border-top-color":"#cccccc"}
}
const _hoisted_8 = {
  key: 2,
  class: "w100 skyblueBg centered fw600 fs12",
  style: {"height":"5px","padding-top":"0px","letter-spacing":"0.3px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailPreviewPartsHeader = _resolveComponent("MailPreviewPartsHeader")!
  const _component_MailPreviewSenderIconContent = _resolveComponent("MailPreviewSenderIconContent")!
  const _component_MailPreviewPartsEditContent = _resolveComponent("MailPreviewPartsEditContent")!
  const _component_MailPreviewPartsBlueButton = _resolveComponent("MailPreviewPartsBlueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MailPreviewPartsHeader),
      (_ctx.rm.userInfo)
        ? (_openBlock(), _createBlock(_component_MailPreviewSenderIconContent, {
            key: 0,
            member: _ctx.rm.userInfo,
            message: `からメッセージが届きました`
          }, null, 8, ["member"]))
        : _createCommentVNode("", true),
      (_ctx.rcm.selectedMailFlow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.member)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.member.company_name), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.member.name) + " 様 ", 1),
                  (_ctx.Util.isPresent(_ctx.rcm.appo.memo))
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_MailPreviewPartsEditContent, { onAddMessage: _ctx.addMessage }, null, 8, ["onAddMessage"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.Util.isPresent(_ctx.rcm.selectedMailFlow.content))
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true),
      _createVNode(_component_MailPreviewPartsBlueButton, {
        buttonName: _ctx.rcm.selectedMailFlow.button_name
      }, null, 8, ["buttonName"])
    ])
  ]))
}