
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagLog, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import axios from "types/axios"
import RoomMessage from "packs/models/RoomMessage"

@Options({
    components: { UserIcon, CheckboxTag, VueFinalModal },
})
export default class NewMessageModal extends Vue {
    // @Prop()
    // member: RoomMember

    // data
    rm = RoomManager

    loading = false
    currentMem: RoomMember = null
    hideDisplayNotification: boolean = false

    public created() {}

    public mounted() {}

    openModal() {}

    // cssUpdate() {
    //     $(`body .NewMessageModalContent`).css({ left: 0 }).addClass(`fr`)
    // }

    // @Watch("room", { deep: true })
    updateRoomMessage(mem: RoomMember) {
        this.currentMem = mem
        gtagPage(`#/新着メッセージモーダル`)
        Logger(`新着メッセージモーダルのroom: ${Util.output(this.currentMem)}`)
    }

    public hideModal() {
        this.$vfm.close("NewMessageModal")
    }

    closeModal() {
        let confirmed = this.rm.confirmed_new_message
        gtagClick(`閉じる`, `新着メッセージモーダル confirmed:${confirmed}`)
        if (!confirmed && this.hideDisplayNotification) {
            // 次回以降表示しないようにします。
            axios.put(`${Util.prefixUrl}/confirmed_new_message`).then(res => {
                if (res.data.result == `ok`) {
                    this.rm.confirmed_new_message = true
                }
                this.$vfm.close("NewMessageModal")
            })
        } else {
            this.$vfm.close("NewMessageModal")
        }
    }

    hideAgain(e) {
        if (this.hideDisplayNotification) {
            this.hideDisplayNotification = false
        } else {
            this.hideDisplayNotification = true
        }
        Logger(`hideAgain: ${e}`)
    }
}
