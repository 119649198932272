import axios from "axios"
import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, onlyUnique } from "packs/common"
import NotificationControl from "packs/utils/NotificationControl"

import Util from "packs/utils/Util"
import Notice from "./Notice"
import RoomMember from "./RoomMember"

export default class UserGroupHistory {
    constructor(
        public ip_address: string,
        public large_category: string,
        public user_id: string,
        public public_room_id: string,
        public created_at: number,
        public params: any
    ) {}

    static getAccesses() {
        return axios
            .get(`${Util.prefixUrl}/user_groups/access_logs`)
            .then(res => {
                return { access_logs: res.data.logs }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return { access_logs: null }
            })
    }

    static getOperationLogs() {
        return axios
            .get(`${Util.prefixUrl}/user_groups/operation_logs`)
            .then(res => {
                return { operation_logs: res.data.logs }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return { operation_logs: null }
            })
    }

    static shareMyRoom(pubRoomId: string, email: string) {
        let params = { email: email }
        return axios
            .post(`${Util.prefixUrl}/public_rooms/${pubRoomId}/share_my_room`, params)
            .then(res => {
                let mem = res.data.shared_member as RoomMember
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return mem
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getsharedMembers(pubRoomId: string) {
        return axios
            .get(`${Util.prefixUrl}/public_rooms/${pubRoomId}/shared_members`)
            .then(res => {
                let mems = res.data.shared_members as RoomMember[]

                return mems
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                // NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static deleteSharedMember(pubRoomId: string, userId: string) {
        let params = { user_id: userId }
        return axios
            .delete(`${Util.prefixUrl}/public_rooms/${pubRoomId}/shared_members`, { params: params })
            .then(res => {
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return res.data.deleted_member
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
