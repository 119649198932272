
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AccountHeader from "./AccountHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import EmailDomainContent from "packs/pages/link/settings/EmailDomainContent.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import NotificationControl from "packs/utils/NotificationControl"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import EmailDomain from "packs/models/EmailDomain"
import Organization from "packs/models/Organization"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        AccountHeader,
        MdTextfieldTag,
        EmailDomainContent,
        AlertModal,
        OptionModal,
        IconLock,
        CheckboxTag,
    },
})
export default class AccountSettings extends Vue {
    // data:
    sharedState = PropertyStore
    notice = Notice

    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil
    selectedTab = "group"
    selectedCategory = `general`

    isSP = isMobile(window.navigator).phone
    users: RoomMember[] = []

    invitedUserEmail = ""
    invitedUserRole = 1
    subm = SubscriptionManager
    failedMail = false // Emailが正しいか.
    sending = false

    namespace = ``
    maildomain = ``
    validName = true
    validMailDomain = true
    saving = false
    ngReasons: string[] = null
    loading = false
    emailDomains: EmailDomain[] = null
    canUseDomain = false
    autoRegiType = null
    org: Organization = null
    ug: any = null

    newUgInfo: any = { name: null, short_name: null, namespace: null }
    validUgName = null
    validUgNamespace = null
    validUgShortName = null

    // modalに引き渡す情報をセットします.
    // type: options, deleteDomain
    // {title, text, buttonName, type}
    alertDic = null
    optionEventName = "メール独自ドメイン 課金リクエスト"

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkAdmin()
                this.canUseDomain = this.rm.canUseOption(`room_email_domain`, false)
                this.namespace = this.rm.namespace
                this.emailDomains = this.rm.email_domains
                this.autoRegiType = this.rm.contact_list_registration_type
                this.org = this.rm.organization
                let ugs = this.org.user_groups
                if (Util.isPresent(ugs)) {
                    this.ug = ugs[0]
                }

                this.newUgInfo.namespace = this.rm.namespace
                this.newUgInfo.name = this.rm.userInfo.company_name
                this.newUgInfo.short_name = this.rm.userInfo.short_name

                // this.subm.getSubscriptionData().then(e => {});
            })
        } else {
            // this.subm.getSubscriptionData().then(e => {});
            this.checkAdmin()
            this.canUseDomain = this.rm.canUseOption(`room_email_domain`, false)
            this.namespace = this.rm.namespace
            this.emailDomains = this.rm.email_domains
            this.autoRegiType = this.rm.contact_list_registration_type
            this.org = this.rm.organization
            let ugs = this.org.user_groups
            if (Util.isPresent(ugs)) {
                this.ug = ugs[0]
            }

            this.newUgInfo.namespace = this.rm.namespace
            this.newUgInfo.name = this.rm.userInfo.company_name
            this.newUgInfo.short_name = this.rm.userInfo.short_name
        }
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/account_settings")
    }

    public inputNamespace(e) {
        this.namespace = e
        this.checkName()
    }

    public inputMailDomain(e) {
        this.maildomain = e
        this.checkMailDomain()
    }

    public checkName() {
        this.validName = !Util.isBlank(this.namespace)
    }

    public checkMailDomain() {
        this.validMailDomain = !Util.isBlank(this.maildomain)
    }

    public createMailDomain() {
        this.checkMailDomain()
        if (!this.validMailDomain) {
            return
        }

        if (this.loading) return
        this.loading = true
        this.ngReasons = null
        this.rm.startProgress()

        EmailDomain.createDomain(this.maildomain).then(dom => {
            this.loading = false
            this.rm.endProgress()
            if (dom) {
                // this.rm.namespace = namespace
                let domains = [...(this.rm.email_domains || [])]
                let domain = domains.find(d => d.name == dom.name)
                if (domain) {
                    Object.assign(domain, dom)
                    this.rm.email_domains = domains
                } else {
                    domains.push(dom)
                    this.rm.email_domains = domains
                }
                this.emailDomains = this.rm.email_domains
            }
        })
    }

    showOptionModal() {
        this.alertDic = {
            type: `option`,
        }
        this.$vfm.open(`OptionModal`)
    }

    removeDomain(dom: EmailDomain) {
        this.alertDic = null
        // ドメインを削除するか確認モーダルを表示します.
        this.alertDic = {
            title: `ドメイン削除`,
            text: `設定したドメインを削除します。この操作は元に戻せません。`,
            buttonName: `削除`,
            type: `deleteDomain`,
            domain: dom,
        }
        this.$vfm.open(`AlertModal`)
    }

    updateDom(dom: EmailDomain) {
        Logger(`Domainを更新しました: ${Util.output(dom)}`)
        let _dom = this.emailDomains.find(_dom => _dom.id == dom.id)
        Object.assign(_dom, dom)
    }

    clickOK() {
        // AlertModalのボタン押下

        if (this.alertDic && this.alertDic.type == `deleteDomain`) {
            // ドメインを削除します.
            this.rm.startProgress()
            EmailDomain.deleteDomain(this.alertDic.domain).then(_dom => {
                this.$vfm.close(`OptionModal`)
                this.$vfm.close(`AlertModal`)
                if (_dom) {
                    this.emailDomains = this.emailDomains.filter(_d => _d.id != _dom.id)
                    this.rm.email_domains = this.emailDomains
                } else {
                    this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
                }
                // モーダル消す.
                this.rm.endProgress()
            })
        } else {
            gtagClick(`${this.optionEventName}`, ``)
            // オプションメニューモーダルを閉じます.
            this.rm.startProgress()
            Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
                this.$vfm.close(`AlertModal`)
                this.$vfm.close(`OptionModal`)
                if (e.data.result == "ok") {
                    this.notice.message = e.data.message
                } else {
                    this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
                }
                // モーダル消す.
                this.rm.endProgress()
            })
        }
    }

    clickCancel() {
        if (this.alertDic && this.alertDic.type == `deleteDomain`) {
        } else {
            gtagClick(`${this.optionEventName} キャンセル`, ``)
        }

        this.$vfm.close(`OptionModal`)
        this.$vfm.close(`AlertModal`)
        this.alertDic = null
    }

    public createNamespace() {
        this.checkName()
        if (!this.validName) {
            this.notice.message = `企業URLを正しく入力してください`
            return
        }

        if (this.loading) return
        this.loading = true
        this.ngReasons = null

        return axios
            .get(`${Util.prefixUrl}/user_groups/check_namespace?namespace=${this.namespace}`)
            .then(res => {
                this.loading = false
                const can_register = res.data.can_register
                if (can_register) {
                    this.loading = true
                    return axios
                        .put(
                            `${Util.prefixUrl}/user_groups/namespace?namespace=${this.namespace}&option_name=account_settings_organization`
                        )
                        .then(res => {
                            let namespace = res.data.namespace
                            this.notice.message = res.data.message
                            this.loading = false
                            if (namespace) {
                                // this.rm.namespace = namespace
                                this.$emit(`updateNamespace`, namespace)
                            }
                        })
                        .catch(err => {
                            this.loading = false
                            Logger(`err: ${err.message} ${Util.output(err.response)}`)
                            NotificationControl.showErrorMessage(err)
                            return null
                        })
                } else {
                    const ng_reasons = res.data.ng_reasons
                    this.ngReasons = ng_reasons
                    this.notice.message = ng_reasons.join()
                }

                // let tmps = MessageTemplate.fetchFromJson(res.data.tmps)
                // return tmps
            })
            .catch(err => {
                this.loading = false
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })

        // this.$emit(`saveNamespace`, )
    }

    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (Util.isBlank(this.rm.userInfo)) return

        const uf = this.rm.userInfo
        const cate = `account_settings`

        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`)) {
            // このページ.
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            // アカウント.
            this.$router.push("/account")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings/notifications")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)) {
            // セキュリティに飛ばす
            this.$router.push("/account_settings/security")
            return
        }
        this.$router.push("/")
        // location.href = "https://google.com";
    }

    clickMailDomainField() {
        if (!this.canUseDomain) {
            // モーダルを表示します.
            this.$vfm.open(`OptionModal`)
        }
    }

    selectAddressbookAutoRegistType(e) {
        Logger(`FormFieldEditContent#selectDisplayType: ${e}`)
        if (this.loading) return
        this.loading = true

        let typeDic = SelectUtil.addressbookAutoRegiTypes.find(d => d.name == e)
        if (typeDic) {
            return axios
                .put(`${Util.prefixUrl}/user_groups/contact_list_registration_type`, {
                    contact_list_registration_type: typeDic.type,
                })
                .then(res => {
                    this.loading = false
                    if (res.data.contact_list_registration_type) {
                        this.rm.contact_list_registration_type = res.data.contact_list_registration_type
                    }
                    Notice.message = res.data.message
                })
                .catch(err => {
                    this.loading = false
                    Logger(`err: ${err.message} ${Util.output(err.response)}`)
                    NotificationControl.showErrorMessage(err)
                    return null
                })
            // this.field.display_type = typeDic.type
            // this.updateFieldFromChild()
        }
    }

    selectCategory(name: string) {
        this.selectedCategory = name
    }

    clickAttachmentIcsFile() {
        Logger(`${funcName()}`)
        if (this.ug.user_group_setting.can_attach_ics_file) {
            this.ug.user_group_setting.can_attach_ics_file = false
        } else {
            this.ug.user_group_setting.can_attach_ics_file = true
        }

        this.updateUserGroupSetting(this.ug.user_group_setting)
    }

    clickChangeTimezone() {
        Logger(`${funcName()}`)
        if (this.ug.user_group_setting.can_change_timezone) {
            this.ug.user_group_setting.can_change_timezone = false
        } else {
            this.ug.user_group_setting.can_change_timezone = true
        }

        this.updateUserGroupSetting(this.ug.user_group_setting)
    }

    clickChangeIgnoreFilters() {
        Logger(`${funcName()}`)
        if (this.ug.user_group_setting.can_ignore_filters_if_manual_mode) {
            this.ug.user_group_setting.can_ignore_filters_if_manual_mode = false
        } else {
            this.ug.user_group_setting.can_ignore_filters_if_manual_mode = true
        }

        this.updateUserGroupSetting(this.ug.user_group_setting)
    }

    clickChangeUseEventIfGoogleTransparencyIsTransparent() {
        Logger(`${funcName()}`)
        if (this.ug.user_group_setting.can_use_event_if_google_transparency_is_transparent) {
            this.ug.user_group_setting.can_use_event_if_google_transparency_is_transparent = false
        } else {
            this.ug.user_group_setting.can_use_event_if_google_transparency_is_transparent = true
        }

        this.updateUserGroupSetting(this.ug.user_group_setting)
    }

    /**
     * ユーザーグループ設定を更新します.
     * @param ug_setting ユーザーグループ設定
     */
    updateUserGroupSetting(ug_setting: any) {
        if (this.loading) return
        this.loading = true

        Organization.updateUserGroupSettings(this.ug.user_group_setting).then(res => {
            this.loading = false
            if (res) {
                this.ug.user_group_setting = res
            }
        })
    }

    clickChangeAddAttendeeIfCalendarOwner() {
        Logger(`${funcName()}`)
        if (this.ug.user_group_setting.add_attendee_if_calendar_owner) {
            this.ug.user_group_setting.add_attendee_if_calendar_owner = false
        } else {
            this.ug.user_group_setting.add_attendee_if_calendar_owner = true
        }

        this.updateUserGroupSetting(this.ug.user_group_setting)
    }

    inputNewUgName(e) {
        // TODO
    }

    checkNewUgName() {
        // TODO
    }

    inputNewUgShortName() {
        // TODO
    }

    checkNewUgShortName() {
        // TODO
    }

    inputNewUgNamespace() {
        // TODO
    }

    checkNewUgNamespace() {
        // TODO
    }

    submitNewUg() {
        // TODO
    }
}
