
import { Options, Prop, Vue } from "vue-property-decorator"
import { Logger } from "packs/common"

export default class LblueListContent extends Vue {
    @Prop()
    value: string
    @Prop()
    name: string
    @Prop()
    display: boolean
    @Prop()
    showDeleteButton: boolean
    @Prop()
    type: string //underline,circle

    mounted() {}

    public deleteContent(e) {
        this.$emit("deleteContent", e.target.getAttribute("value"))
    }

    public clickContent() {
        this.$emit("clickContent", this.value)
    }
}
