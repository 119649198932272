
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import AssignForm from "packs/models/AssignForm"

// モデルを読込.
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomManager from "packs/models/RoomManager"
import RoomSetting from "packs/models/RoomSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import SelectUtil from "packs/utils/SelectUtil"
import Const from "packs/utils/Const"

@Options({
    components: {
        MdTextfieldTag,
        CheckboxTag,
        SelectTag,
    },
})
export default class RoomSettingsAssignFormContent extends Vue {
    @Prop() // public_room / parent_room
    from: string

    // data
    isSP = isMobile(window.navigator).phone

    loading = false
    rcm = RoomCreateManager
    rm = RoomManager

    Util = Util

    SelectUtil = SelectUtil
    Const = Const

    publicId = ``
    selectedForm = null

    mounted() {
        if (!this.rcm.room) return

        this.setPublicRooms()
    }

    inputAssignFormPublicId(e) {
        Logger(`${funcName()} e:${e}`)
        this.publicId = e
    }

    clickConnectAssignForm() {
        Logger(`${funcName()} publicId:${this.publicId}`)
        if (Util.isBlank(this.publicId)) {
            Notice.message = `公開ページIDを入力してください。`
            return
        }
        if (!this.publicId.startsWith(`AF`)) {
            Notice.message = `公開ページIDは「AF」から始まるIDを入力してください。`
            return
        }
        if (this.loading) return
        this.loading = true

        this.rm.startProgress()
        let params = {
            public_ids: [this.publicId],
            user_id: this.rm.userInfo.user_id,
            search_category: `public_id`,
        }
        AssignForm.search(params).then(forms => {
            if (Util.isPresent(forms)) {
                let selectedForm = forms[0]

                this.selectedForm = selectedForm
                this.rcm.room.assign_form_id = selectedForm.id
                this.rcm.updateRoom(this.rcm.room)
            } else {
                Notice.message = `振り分けフォームが見つかりませんでした。`
            }
            this.rm.endProgress()
            this.loading = false
        })
    }

    setPublicRooms() {
        if (!this.rm.userInfo) return

        let formId = this.rcm.room.assign_form_id

        if (Util.isBlank(formId)) return
        if (this.selectedForm && this.selectedForm.id == formId) return

        AssignForm.getAformDetail(formId, false).then(_form => {
            if (_form) {
                this.selectedForm = _form
                this.publicId = _form.public_id
            }
        })
    }

    public deselect() {
        Logger(`${funcName()} 選択した公開ページ assign_formをはずします.`)

        this.rcm.room.assign_form_id = null
        this.selectedForm = null
        this.publicId = null
        this.rcm.updateRoom(this.rcm.room)
    }
}
