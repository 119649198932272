
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Notice from "packs/models/Notice"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class ConfirmChangeOwnerModal extends Vue {
    // data
    loading = false
    isSP = isMobile(window.navigator).phone

    rcm = RoomCreateManager

    contentHeight = 300
    saving = false
    notice = Notice

    public created() {}

    public updated() {}

    public closeModal() {
        this.$vfm.close(`ConfirmChangeOwnerModal`)
    }

    public openModal() {
        gtagPage(`#/オーナー変更モーダル表示`)
    }

    public clickOK() {
        gtagClick(`オーナー変更モーダル オーナー変更更新`, ``)
        this.$emit(`clickOK`)
    }

    public clickCancel() {
        gtagClick(`オーナー変更モーダル オーナー変更せず更新`, ``)
        this.$emit(`clickCancel`)
    }
}
