
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import axios from "types/axios"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import UserSetting from "packs/models/UserSetting"
import CalendarManager from "packs/models/CalendarManager"

const roomStorage = new RoomStorage()

@Options({
    components: {},
})
export default class DisconnectCalendar extends Vue {
    // data:

    notice = Notice

    rm = RoomManager
    cm = CalendarManager
    Util = Util
    SelectUtil = SelectUtil

    isSP = isMobile(window.navigator).phone
    users: any[] = []
    loading = false

    mounted() {
        gtagPage("#/disconnect_calendar")

        if (!this.rm.userInfo) {
            this.rm.startProgress()
            this.rm.getAvailableSchedule().then(res => {
                this.rm.endProgress()
                if (this.rm.didConnectCal) {
                    //
                } else {
                    this.$router.push("/")
                }
            })
        } else {
            if (this.rm.didConnectCal) {
                //
            } else {
                this.$router.push("/")
            }
        }
    }

    disconnectCalendar() {
        Logger(`${funcName()} カレンダー連携を解除します.`)
        this.rm.startProgress()
        UserSetting.disconnectCalendar()
            .then(e => {
                this.rm.endProgress()
                if (Const.started(this.rm)) {
                    this.rm.didConnectCal = false
                    if (this.cm) {
                        this.cm.resetAstagInfo()
                        if (this.cm.ctList) {
                            this.cm.ctList = null
                        }
                    }
                } else {
                    Logger(`未開始ユーザーのため、ログアウト処理します.`)

                    this.logout()
                }
                window.location.href = "/"
            })
            .catch(err => {
                this.rm.endProgress()
                Notice.message = `カレンダー連携の解除に失敗しました。`
            })
    }

    logout() {
        // ログアウト処理を実行
        axios
            .delete("/users/sign_out")
            .then(() => {
                // ログアウト成功時の処理
                roomStorage.resetAll()
                window.location.href = "/"
            })
            .catch(error => {
                // エラー処理
                console.error("ログアウトに失敗しました。", error)
            })
    }
}
