
// モジュールを読込.
import { Vue, Options, Prop } from "vue-property-decorator"
import { Logger } from "packs/common"
import Notice from "packs/models/Notice"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import ContactListGroup from "packs/models/ContactListGroup"

@Options({
    components: { UserIcon, VueFinalModal, MdProgressBar },
})
export default class ModalDeleteFromAddressBook extends Vue {
    @Prop()
    user: RoomMember

    @Prop()
    clg: ContactListGroup

    @Prop()
    height: number

    @Prop()
    okButtonName: string

    @Prop()
    cancelButtonName: string

    @Prop()
    hideCancelButton: boolean

    rm = RoomManager
    loading = false

    openModal() {}

    clickCancel() {
        this.hideDeleteUserModal(false)
    }

    public hideDeleteUserModal(deleted) {
        this.$vfm.close("ModalDeleteFromAddressBook")

        this.$emit("deleteUser", deleted)
    }

    public submitDeleteFromAddressBook() {
        if (this.loading) return
        this.loading = true

        if (this.user) {
            let mem: RoomMember = RoomMember.copy(this.user)
            mem.display_status = -1
            RoomMember.saveAddressBook(mem, "put").then(saved_user => {
                let deleted = false
                if (saved_user) {
                    Notice.message = `削除しました.`
                    deleted = true
                } else {
                    Notice.message = `うまく削除できませんでした。`
                    mem.display_status = 10
                }
                this.loading = false
                this.hideDeleteUserModal(deleted)
            })
        } else if (this.clg) {
            ContactListGroup.delete(this.clg).then(dic => {
                this.loading = false
                this.hideDeleteUserModal(dic ? true : false)
            })
        }
    }
}
