import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AppealContent from "packs/models/AppealContent"
import FormField from "packs/models/FormField"
import { funcName, Logger } from "packs/common"
import NotificationControl from "packs/utils/NotificationControl"
import RoomMember from "packs/models/RoomMember"
import AnswerCondition from "packs/models/AnswerCondition"

/**
 * 振り分け先を指定します.
 */
export default class AnswerConditionGroup {
    constructor(public id: string, public public_id: string, public answer_conditions: AnswerCondition[]) {}

    static createDefault() {
        let pubId = Util.getRandomPublicId(`ACG`)
        return new AnswerConditionGroup(null, pubId, [AnswerCondition.createDefault()])
    }
}
