import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import AppointmentForm from "./AppointmentForm"

interface Storable {
    getItem(key: string): string | null
    setItem(key: string, value: string): void
}

const STORAGE_KEY_PREFIX = `${process.env.NODE_ENV} ${navigator ? navigator.appVersion : ``}` // ローカル/本番/ブラウザで分けるため
const STORAGE_KEY = `${STORAGE_KEY_PREFIX}LinkForm` // 作成中の調整ページ [Room]
const STORAGE_KEY_STATUS = `${STORAGE_KEY_PREFIX}LinkRoomStatus` // 作成中の調整ページのステータス [string] new, edit, copy

export default class FormStorage {
    constructor(
        // デフォルト引数でローカルストレージを DI
        private storage: Storable = localStorage
    ) {}

    // 現在の調整ページを保存する
    public saveForm(form: AppointmentForm) {
        this.storage.setItem(STORAGE_KEY, JSON.stringify(form))
    }

    // 現在の調整ページを取得する
    public fetchForm(): AppointmentForm {
        const form: AppointmentForm = JSON.parse(this.storage.getItem(STORAGE_KEY) || "{}")
        return form
    }
}
