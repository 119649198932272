
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

@Options({
    components: {},
})
export default class EditableContent extends Vue {
    @Prop()
    id: string

    @Prop()
    placeholder: string

    @Prop()
    value: string

    @Prop()
    maxlength: number

    currentVal = null
    currentPos = -1
    Util = Util

    mounted() {
        this.updateValueFromParent()
        // document.onselectionchange = () => {
        //     const pos = window.getSelection().getRangeAt(0).getBoundingClientRect()
        //     Logger(`カーソルpos: ${pos}`)
        //     Logger(`カーソルpos: ${Util.output(pos)}`)
        //     // this.currentPos = pos.
        // }
    }

    @Watch(`value`, { deep: true })
    updateValueFromParent() {
        Logger(`EditableContent:updateValueFromParent ${this.value}`)
        this.currentVal = this.value || ``
    }

    public updateValue(e) {
        // Logger(`update value has called.${e.target.value}`);
        this.$emit("input", e.target.innerText)
    }

    public focusout() {
        this.$emit("blur")
    }

    public click(e) {
        this.$emit("clickContent", e.target)
    }
}
