
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import HeaderControl from "packs/utils/HeaderControl"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import SubscriptionManager from "packs/models/SubscriptionManager"
import Appointment from "packs/models/Appointment"
import Const from "packs/utils/Const"
import SearchedRoomsInfo from "packs/models/SearchedRoomsInfo"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import AstagsTab from "packs/models/fetcher/AstagsTab"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        RoomsLoader,
        TextfieldTag,
        SelectTag,
    },
})
export default class AstagSearchBox extends Vue {
    @Prop()
    canSelectShared: boolean

    // data:
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    truncate = truncate

    // 選択しているタブ情報
    selectedTabName: string = null // all, favorite, active, active_in_progress, fixed, closed, unread, draft

    subm = SubscriptionManager
    Room = Room

    AstagsTab = AstagsTab

    redirectAppo = null
    members_with_links: RoomMember[] = []
    searchText = ``
    showSearchField = false
    Const = Const
    isTabGroupTag = false
    showTagText = false

    loading = false
    blockingTabs = false

    selectSearchField = false

    SearchedRoomsInfo = SearchedRoomsInfo
    searchCategory = `self`

    // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: "", rooms: Room[]}
    searchedDic: SearchedRoomsInfo = null

    public created() {
        this.searchedDic = null
    }

    public mounted() {
        this.searchText = ``
        if (!this.canSelectShared) {
            this.searchCategory = `self`
        }
        if (Util.isBlank(this.rm.astag)) {
            this.rm.resetCurrentInfo()
        }
        // if (this.rooms) this.updateTitleWithUnreadNum()
    }

    resetSearchDic() {
        this.searchedDic = null
        roomStorage.saveSearchedRoomsInfo(null)
    }

    startProgress() {
        this.rm.startProgress()
    }

    endProgress() {
        this.rm.endProgress()
    }

    /**
     * 検索した結果を表示させます。
     */
    public clickSearch(text) {
        this.inputSearchText(text)
        this.startSearch()
        this.$vfm.close("SearchBoxModal")
    }

    /**
     * スマホで検索窓のモーダルを開きます。
     */
    public clickSpSearchBox() {
        this.$vfm.open("SearchBoxModal")
    }

    public clickSearchIcon() {
        this.searchedDic = null
        this.showSearchField = true
        sleep(500).then(_ => {
            $("input.searchField").eq(0).focus()
        })
    }

    public startSearch() {
        Logger(`検索を開始します。 ${this.searchText}`)
        let searchText = this.searchText.trim()

        let params = {
            category: this.searchCategory,
            text: searchText,
        }

        if (this.blockingTabs) return
        // this.rm.astagsTab.searched_astags = []
        // this.rm.astagsTab.tab_name = null
        this.blockingTabs = true
        this.startProgress()

        AvailableScheduleTag.search(params).then(astags => {
            this.endProgress()
            this.blockingTabs = false
            if (!astags) return

            Logger(`search 検索して取得してきました。${astags?.length}`)
            this.selectedTabName = `search`
            let astagsTab = { ...this.rm.astagsTab }
            astagsTab.searched_astags = astags
            astagsTab.tab_name = `search`
            this.rm.astagsTab = astagsTab

            // this.searchedDic = SearchedRoomsInfo.createSearchInfo(this.searchCategory, rooms, this.searchText)
            // roomStorage.saveSearchedRoomsInfo(this.searchedDic)
            // // {category: "roomId,email,mag,member", categoryName:"調整ページID,メールアドレス,担当,メンバー", text: ""}
        })
    }

    /**
     * 検索をした場合に「すべて」タブに変更して、以下の内容から部分一致するものを取得してきます。
     * アポ名/publicID/出席者名/出席者の会社名/メールアドレス
     */
    public inputSearchText(text) {
        this.searchText = text
    }

    clickSearchField() {
        this.selectSearchField = true
    }

    blurSearchField() {
        this.selectSearchField = false
    }

    selectSearchType(type: string) {
        Logger(`${funcName()} type:${type}`)
        if (type == `自分`) {
            this.searchCategory = `self`
        } else {
            this.searchCategory = `shared`
        }
    }
}
