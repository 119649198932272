
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LocationIcon from "packs/components/icons/LocationIcon.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import FormFieldConverter from "packs/models/FormFieldConverter"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import VariableListsContent from "packs/pages/link/parts/room_settings/VariableListsContent.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import FormCreateManager from "packs/models/FormCreateManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import CustomNotification from "packs/models/CustomNotification"

@Options({
    components: { CheckboxTag, MdTextfieldTag, SelectTag, RoomsLoader, VariableListsContent, TextareaTag },
})
export default class GoogleSpreadSheetFileSelector extends Vue {
    @Prop()
    canEdit: boolean

    // data:
    notice = Notice

    rm = RoomManager
    fcm = FormCreateManager
    rcm = RoomCreateManager
    util = Util
    selectedTab = `locations`

    isSP = isMobile(window.navigator).phone
    failedKeyword = false
    loading = false

    truncate = truncate

    Util = Util
    SelectUtil = SelectUtil

    fileLists = null
    selectedFile = null
    displayFiles = true

    listsDic = { root: null }
    currentDirName = `root`
    currentDirId = null

    googleSpreadSheetDic = null

    /**
     * let settings = {
            provider_account_id: ``,
            google_sheets_file_id: ``,
            google_sheets_sheet_name: ``,
            check_field_type: `email`,
            fixed_appointment: { content: `` },
            inquire_on_public_room: { content: `` },
        }
     */
    sheetSettings = null
    openVariablesFixedAppointment = false
    openVariablesInquireOnPublicRoom = false
    openVariablesCancelAppointment = false

    openFixedAppointmentContent = false
    openInquireOnPublicRoomContent = false
    openCancelAppointmentContent = false

    sheetUrl = null

    mounted() {
        this.googleSpreadSheetDic =
            (this.rcm.room_setting.custom_notifications || []).find(noti => noti.provider == `google_spread_sheet`) ||
            CustomNotification.createDefault(`room_setting`, `google_spread_sheet`)
        this.sheetSettings = this.googleSpreadSheetDic?.settings
        if (this.sheetSettings?.google_sheets_file_id) {
            this.sheetUrl = this.sheetSettings.google_sheets_file_id

            // this.selectFile({ id: this.sheetSettings.google_sheets_file_id, name: this.sheetSettings.google_sheets_sheet_name })
            this.selectedFile = {
                id: this.sheetSettings.google_sheets_file_id,
                name: this.sheetSettings.google_sheets_sheet_name,
            }
            this.displayFiles = false
            return
        }
    }

    getGoogleSpreadSheetFileName(parentId = null) {
        Logger(`${funcName()} parentId:${parentId}`)
        if (this.loading) return
        this.loading = true

        CustomNotification.getGoogleSpreadSheetFileName(parentId).then(fileName => {
            Logger(`${funcName()} fileName:${Util.output(fileName)}`)

            if (fileName) {
                this.sheetSettings.google_sheets_sheet_name = fileName
            }

            this.loading = false
        })
    }

    updateRoomSetting() {
        Logger(`${funcName()}`)
        this.googleSpreadSheetDic.settings = this.sheetSettings

        let notis = (this.rcm.room_setting.custom_notifications || []).filter(noti => noti.provider != `google_spread_sheet`)
        if (Util.isPresent(this.googleSpreadSheetDic)) notis.push(this.googleSpreadSheetDic)

        this.rcm.room_setting.custom_notifications = notis
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    decideFile() {
        if (!this.sheetSettings.google_sheets_file_id) return

        this.displayFiles = false
        this.selectedFile = { id: this.sheetSettings.google_sheets_file_id, name: this.sheetSettings.google_sheets_sheet_name }

        this.updateRoomSetting()
    }

    reselectFile() {
        // this.displayFiles = true
        // if (this.listsDic.root) {
        //     this.fileLists = this.listsDic.root
        // } else {
        //     this.fileLists = null
        //     this.getGoogleSpreadSheetFileName()
        // }
        this.selectedFile = null
        this.sheetSettings.google_sheets_file_id = null
        this.sheetSettings.google_sheets_sheet_name = null
        this.updateRoomSetting()
    }

    goRootDir() {
        this.currentDirId = null
        this.currentDirName = `root`
        this.fileLists = this.listsDic.root
    }

    selectCheckFieldType(type) {
        Logger(`${funcName()} type:${type}`)
        let seldic = SelectUtil.checkFieldTypes.find(seldic => seldic.name == type)
        if (seldic) this.sheetSettings.check_field_type = seldic.type
    }

    checkFieldTypeDefaultOption() {
        let type = this.sheetSettings.check_field_type
        if (!type) type = `email`

        return SelectUtil.checkFieldTypes.find(seldic => seldic.type == type)?.name
    }

    inputSheetFixedAppointment(value) {
        Logger(`${funcName()} value:${Util.output(value)}`)
        if (!this.sheetSettings.fixed_appointment) this.sheetSettings.fixed_appointment = {}
        this.sheetSettings.fixed_appointment.content = value
        this.updateRoomSetting()
    }

    inputSheetInquireOnPublicRoom(value) {
        Logger(`${funcName()} value:${Util.output(value)}`)
        if (!this.sheetSettings.inquire_on_public_room) this.sheetSettings.inquire_on_public_room = {}
        this.sheetSettings.inquire_on_public_room.content = value
        this.updateRoomSetting()
    }

    inputSheetCancelAppointment(value) {
        Logger(`${funcName()} value:${Util.output(value)}`)
        if (!this.sheetSettings.cancel_appointment) this.sheetSettings.cancel_appointment = {}
        this.sheetSettings.cancel_appointment.content = value
        this.updateRoomSetting()
    }

    addVal(type) {
        if (type == `fixed_appointment`) {
            if (this.openVariablesFixedAppointment) {
                this.openVariablesFixedAppointment = false
            } else {
                this.openVariablesFixedAppointment = true
            }
        } else if (type == `inquire_on_public_room`) {
            if (this.openVariablesInquireOnPublicRoom) {
                this.openVariablesInquireOnPublicRoom = false
            } else {
                this.openVariablesInquireOnPublicRoom = true
            }
        } else if (type == `cancel_appointment`) {
            if (this.openVariablesCancelAppointment) {
                this.openVariablesCancelAppointment = false
            } else {
                this.openVariablesCancelAppointment = true
            }
        }
    }

    selectVariable(variable, type) {
        Logger(`${funcName()} variable:${Util.output(variable)}, type:${type}`)
        if (!this.sheetSettings[type]) this.sheetSettings[type] = {}
        let content = this.sheetSettings[type].content
        if (content) {
            content = `${content || ``}${variable}`
        } else {
            content = variable
        }
        this.sheetSettings[type].content = content
        this.updateRoomSetting()
    }

    openContent(type) {
        Logger(`${funcName()} type:${type}`)
        this.openFixedAppointmentContent = false
        this.openInquireOnPublicRoomContent = false
        this.openCancelAppointmentContent = false

        if (type == `fixed_appointment`) {
            this.openFixedAppointmentContent = true
        } else if (type == `inquire_on_public_room`) {
            this.openInquireOnPublicRoomContent = true
        } else if (type == `cancel_appointment`) {
            this.openCancelAppointmentContent = true
        }
    }

    inputSheetGoogleSpreadSheetUrl(value) {
        Logger(`${funcName()} url:${Util.output(value)}`)
        this.sheetUrl = value

        let sheetUrl = this.sheetUrl
        if (Util.isBlank(sheetUrl)) {
            this.sheetSettings.google_sheets_file_id = null
            this.sheetSettings.google_spread_sheet_sheet_name = null
            return
        }

        if (Util.isUrl(sheetUrl)) {
            let fileId = this.extractFileIdFromUrl(sheetUrl)
            // let sheetName = url.searchParams.get(`gid`)
            if (fileId) {
                this.sheetSettings.google_sheets_file_id = fileId
            } else {
                Logger(`${funcName()} fileId is null`)
                Notice.message = `GoogleスプレッドシートのURLが正しくありません。`
            }
            // this.sheetSettings.google_spread_sheet_file_id = fileId
            // this.sheetSettings.google_spread_sheet_sheet_name = sheetName
        } else {
            this.sheetSettings.google_sheets_file_id = sheetUrl
            // this.sheetSettings.google_sheets_sheet_name = this.selectedFile.name
        }

        if (Util.isPresent(this.sheetSettings.google_sheets_file_id)) {
            this.getGoogleSpreadSheetFileName(this.sheetSettings.google_sheets_file_id)
        }
    }

    extractFileIdFromUrl(url) {
        try {
            // URLオブジェクトを作成
            const parsedUrl = new URL(url)

            // Google SpreadsheetsのURLであることを確認
            if (!parsedUrl.hostname.includes("docs.google.com") || !parsedUrl.pathname.includes("/spreadsheets/d/")) {
                throw new Error("Invalid Google Spreadsheet URL")
            }

            // パスを分割してファイルIDを取得
            const pathParts = parsedUrl.pathname.split("/")
            const fileIdIndex = pathParts.indexOf("d") + 1

            if (fileIdIndex >= pathParts.length) {
                throw new Error("File ID not found in URL")
            }

            const fileId = pathParts[fileIdIndex]

            // ファイルIDの形式を簡易チェック（より厳密なチェックが必要な場合は正規表現を使用）
            if (fileId.length < 25 || fileId.length > 44) {
                throw new Error("Invalid file ID format")
            }

            return fileId
        } catch (error) {
            console.error("Error extracting file ID:", error.message)
            return null
        }
    }
}
