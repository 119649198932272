import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger, onlyUnique, funcName } from "packs/common"

import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"
import Notice from "../Notice"
import RoomMember from "../RoomMember"

export default class ChukaiFetcher {
    constructor(
        public astags: AvailableScheduleTag[],
        public shared_astags: AvailableScheduleTag[],

        public tab_name: string,

        // default
        public has_next: boolean,
        public total_astags_num: number,
        public page: number,
        public total_astag_page: number,

        // shared
        public shared_has_next: boolean,
        public shared_total_astags_num: number,
        public shared_page: number,
        public shared_total_astag_page: number
    ) {}

    static copy(t: ChukaiFetcher): ChukaiFetcher {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(t)), t)
        return clone
    }

    static createOtherRoomsFromChukai(params) {
        return axios
            .post(`${Util.prefixUrl}/public_rooms/create_other_rooms_from_chukai`, params)
            .then(result => {
                Logger(`ChukaiFetcher.createOtherRoomsFromChukai ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.createOtherRoomsFromChukai err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static updateDisplayOrderFromChukai(params) {
        return axios
            .put(`${Util.prefixUrl}/available_schedule_tags/room_members_display_type`, params)
            .then(result => {
                Logger(`ChukaiFetcher.updateDisplayOrderFromChukai ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.createOtherRoomsFromChukai err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static updatePublicRoomsAstagFromChukai(params) {
        return axios
            .post(`${Util.prefixUrl}/public_rooms/update_public_rooms_astag_from_chukai`, params)
            .then(result => {
                Logger(`ChukaiFetcher.updatePublicRoomsAstagFromChukai ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.createOtherRoomsFromChukai err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getMembersOpenSchedulesFromChukai(params) {
        return axios
            .post(`${Util.prefixUrl}/available_schedule_tags/members_open_schedules`, params)
            .then(result => {
                Logger(`ChukaiFetcher.getMembersOpenSchedulesFromChukai ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.createOtherRoomsFromChukai err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static changeOpenScheduleType(params) {
        return axios
            .put(`${Util.prefixUrl}/available_schedule_tags/change_open_schedule_type`, params)
            .then(result => {
                Logger(`ChukaiFetcher.changeOpenScheduleType ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.createOtherRoomsFromChukai err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getClientUserGroups() {
        return axios
            .get(`${Util.prefixUrl}/user_groups/client_user_groups`)
            .then(result => {
                Logger(`ChukaiFetcher.getClientUserGroups ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.getClientUserGroups err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static createNewClientUserGroup(params) {
        return axios
            .post(`${Util.prefixUrl}/user_groups/client_user_group`, params)
            .then(result => {
                Logger(`ChukaiFetcher.createNewClientUserGroup ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.getClientUsers err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static inviteClientUser(params) {
        return axios
            .post(`${Util.prefixUrl}/user_groups/client_user`, params)
            .then(result => {
                Logger(`ChukaiFetcher.inviteClientUser ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.getClientUsers err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getClientUsers(ugid: string) {
        let params = { user_group_id: ugid }
        return axios
            .get(`${Util.prefixUrl}/user_groups/client_users`, params)
            .then(result => {
                Logger(`ChukaiFetcher.getClientUsers ${Util.output(result.data)}`)

                return result.data
            })
            .catch(err => {
                Logger(`ChukaiFetcher.getClientUsers err:${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
