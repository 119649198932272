
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Notice from "packs/models/Notice"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import GroupProfileModal from "packs/pages/link/modals/GroupProfileModal.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import RoomManager from "packs/models/RoomManager"

// 管理用カレンダー未設定時の選択肢名
const UNSET_CAL_NAME = "未設定"

@Options({
    components: {
        GroupProfileModal,
        UserIcon,
        SelectTag,
        IconHelp,
        MdTextfieldTag,
    },
})
export default class AvailableScheduleSettings extends Vue {
    @Prop()
    astag: AvailableScheduleTag

    // data:
    cm = CalendarManager
    rm = RoomManager
    Util = Util

    isSP = isMobile(window.navigator).phone
    SelectUtil = SelectUtil
    alertTextGroupDic = null
    magForProfile: MeetingAttendeesGroup = null

    // 選択したカレンダーを設定します。
    public selectCalendar(e) {
        Logger(`管理用カレンダーを更新します。${e}`)
        if (!this.astag) return
        let _astag = { ...this.astag }
        if (e === UNSET_CAL_NAME) {
            _astag.optional_calendar_tag_search_key = null
            this.updateAstag(_astag)

            return
        }
        const selectedCal = this.cm.getWritableCalendars().find(cal => cal.name === e)
        if (!selectedCal) {
            _astag.optional_calendar_tag_search_key = null
        } else {
            _astag.optional_calendar_tag_search_key = selectedCal.search_key
        }
        this.updateAstag(_astag)
    }

    public defaultOption() {
        if (!this.astag.optional_calendar_tag_search_key) return UNSET_CAL_NAME
        const selectedCal = this.cm
            .getWritableCalendars()
            .find(cal => cal.search_key === this.astag.optional_calendar_tag_search_key)
        if (!selectedCal) return UNSET_CAL_NAME
        return selectedCal.name
    }

    @Watch("cm.ctList", { deep: true })
    public selectableCalendars() {
        const selectable_cals = this.cm.getWritableCalendars().map(cal => cal.name)
        selectable_cals.unshift(UNSET_CAL_NAME)
        return selectable_cals
    }

    addGroupProfile(mag: MeetingAttendeesGroup) {
        this.magForProfile = mag
        this.$vfm.open("GroupProfileModal")
    }

    // プロフィールを設定した後に返ってきます。
    public saveProfile(mag: MeetingAttendeesGroup) {
        if (!mag) return
        Logger(`${funcName()} mag.name:${mag.name}`)
        if (mag) {
            this.cm.edittingMag = mag
            Logger(`updateしなくても言ってるのでは: mags: ${Util.output(this.cm.mags)}`)
            // Logger(`アップデートします edittingMag: ${Util.output(mag)}`)
        } else {
            this.cm.edittingMag = this.magForProfile
        }
        let _mag = this.cm.mags.find(m => m.name == mag.name)
        Object.assign(_mag, mag)
        // this.updateAlertText()

        this.$vfm.close("GroupProfileModal")
    }

    public inputEmails(e) {
        Logger(`inputEmails: ${e}`)
        let _astag = { ...this.astag }
        _astag.emails = e
        this.updateAstag(_astag)
    }

    updateAstag(astag: AvailableScheduleTag) {
        this.$emit(`updateAstag`, astag)
    }

    selectDailyMeetingMaxNum(e) {
        Logger(`${funcName()} e:${e}`)
        let _astag = { ...this.astag }
        if (e == `無制限`) {
            _astag.daily_meeting_max_num = -1
        } else {
            _astag.daily_meeting_max_num = e as number
        }
        this.updateAstag(_astag)
    }

    inputDailyCountableEventKeyword(e) {
        Logger(`${funcName()} e:${e}`)
        let _astag = { ...this.astag }
        _astag.daily_countable_event_keyword = e
        this.updateAstag(_astag)
    }

    get showAdminCalendarContent() {
        if (!this.cm.astag) return false
        if (this.rm.didConnectCal || Util.isPresent(this.cm.sharedCalendarTagsDic[this.astag.id])) {
            return true
        }
        return false
    }
}
