
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Logger, onlyUnique, sleep } from "packs/common"
import Const from "packs/utils/Const"

// コンポーネントを読込.

// モデルを読込.
import Appointment from "packs/models/Appointment"

@Options({
    components: {},
})
export default class FixTypeButtons extends Vue {
    // data:
    @Prop()
    appo: Appointment

    public fixBy(typeString) {
        Logger(`日程を確定できるタイプ: ${typeString}`)
        let fixType = Appointment.fixTypeFromString(typeString)
        // this.send()
        this.$emit(`fixBy`, fixType)
    }
}
