
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({
    components: {},
})
export default class AlertBadge extends Vue {
    @Prop()
    num
    @Prop()
    display

    getText() {
        if (this.display && this.display == "num") {
            return this.num
        }
        return ` `
    }

    clickBudge() {
        this.$emit(`click`)
    }
}
