import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "fs13 text-left kaigyo",
  style: {"color":"#333333"}
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: {"margin":"20px 0 0 0","box-shadow":"0 0","padding":"0px"},
    class: "ScheduleMessageContent withEditable posRe",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addMessage && _ctx.addMessage(...args)))
  }, [
    _createElementVNode("table", null, [
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("p", _hoisted_1, [
            _createElementVNode("span", {
              innerHTML: _ctx.getDefaultContent()
            }, null, 8, _hoisted_2)
          ])
        ])
      ])
    ])
  ]))
}