
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, funcName } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ModalEditAppointment from "packs/pages/link/modals/ModalEditAppointment.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import ModalSelectAstags from "packs/pages/link/modals/ModalSelectAstags.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import ModalPastAppointments from "packs/pages/link/modals/ModalPastAppointments.vue"
import InputLocationModal from "packs/pages/link/modals/InputLocationModal.vue"
import AnsweredFormModal from "packs/pages/link/parts/room_summary/AnsweredFormModal.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import AlertWithTextareaModal from "packs/components/modals/AlertWithTextareaModal.vue"
import CalendarEventModal from "packs/components/calendar/CalendarEventModal.vue"
import ChangeMeetingAttendeesGroupModal from "packs/pages/schedule/modals/ChangeMeetingAttendeesGroupModal.vue"
import EditAttendeesModal from "packs/pages/schedule/modals/EditAttendeesModal.vue"
import IconStar from "packs/components/icons/IconStar.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Room from "packs/models/Room"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import Refresher from "packs/models/Refresher"
import CalendarManager from "packs/models/CalendarManager"
import Event from "packs/models/Event"
import RoomTab from "packs/models/RoomTab"
import LocationTag from "packs/models/LocationTag"

@Options({
    components: {
        ModalEditAppointment,
        TextfieldTag,
        ModalSelectAstags,
        UserIcon,
        ModalPastAppointments,
        InputLocationModal,
        AnsweredFormModal,
        AlertModal,
        AlertWithTextareaModal,
        CalendarEventModal,
        ChangeMeetingAttendeesGroupModal,
        EditAttendeesModal,
        IconStar,
        Dropdown,
    },
})
export default class AppointmentCardViewLarge extends Vue {
    // data:

    @Prop()
    appo: Appointment

    @Prop()
    type: string // `fill`, `overstart`, `overdue`, `already`, `fixed`

    @Prop()
    userType: string // user / guest / logged_in

    @Ref()
    AlertModal

    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager

    groupTitle = ""
    isSP = isMobile(window.navigator).phone
    isPreview = Util.isPreview()
    fixedTypes = [`fixed`, `already`]

    attendees: RoomMember[] = null // room.attendeesと同義.
    owner: RoomMember = null
    showTip = false

    // beforeAppo: Appointment = null;
    Appointment = Appointment
    UserPermissionsOption = UserPermissionsOption

    appoStatus = Appointment.typeAddNew
    doesChangeAppo: boolean
    notice = Notice
    Util = Util
    LocationTag = LocationTag
    saving = false
    // showShareButton: boolean;
    astag: AvailableScheduleTag = null

    locationName = ``
    currentAppo: Appointment = null
    contentWidth = 350

    member: RoomMember = null //フォーム表示用.

    alertTitle: string = null
    alertText: string = null
    loading = false
    refresher = Refresher

    public mounted() {
        this.astag = this.rm.astag
        this.updateAppo()
        this.updateContentWidth()
        this.updateMembers()
    }

    public updated() {
        this.updateContentWidth()
    }

    public updateContentWidth() {
        let width = $(".ScheduleFixContent").innerWidth()
        Logger(`${funcName()} width:${width}`)
        if (!width) return
        this.contentWidth = width
    }

    @Watch("rm.currentAppointment", { deep: true })
    public updateAppo() {
        Logger(`${funcName()} appoの更新AppointmentCardViewLarge: currentAppointment:${this.rm?.currentAppointment?.id}`)
        this.currentAppo = this.rm.currentAppointment
    }

    /**
     * Astagを作成済みで出席者で未共有の場合「共有」ボタンを表示します。
     */
    public showShareButton(): boolean {
        if (!this.rm.userInfo) return false
        if (!this.attendees) return false
        if (!this.rm.astag || this.rm.astag.id == `newId`) return false
        if (this.currentAppo.status == 10) return false

        this.attendees = this.getDefaultMembers()
        let attend = this.attendees.some(mem => mem.user_id == this.rm.userInfo.user_id)
        if (attend && !this.pdm.didShare()) {
            return true
        }
        return false
    }

    public mouseover(category, label) {
        gtagHover(category, label)
    }

    public getDefaultMembers(): RoomMember[] {
        if (!this.appo || !this.rm.currentRoom) return
        this.owner = this.rm.currentRoom.owner
        let attendees = this.rm.currentRoom.attendees

        return attendees
    }

    @Watch("rm.currentRoom", { deep: true })
    public updateMembers() {
        this.attendees = this.getDefaultMembers()
    }

    /**
     * 出席者情報とカレンダー共有情報を統合します。（pdmとcurrentAppointmentが更新されている前提で）
     */
    @Watch("pdm.sharedMembers", { deep: true })
    public updateAppoMembers() {
        if (!this.pdm || !this.appo || !this.rm.currentRoom) return
        const sharedMembers = this.pdm.sharedMembers || []
        let mems = [] // sharedの状態を入れた最終的なメンバー.
        let attendees = this.getDefaultMembers() // 出席者
        if (Util.isPresent(attendees)) {
            for (let _mem of attendees) {
                if (sharedMembers.some(m => m.user_id == _mem.user_id)) {
                    _mem.shared = true
                } else {
                    _mem.shared = false
                }
                mems.push(_mem)
            }
        }

        this.attendees = mems
    }

    /**
     * 現在のアポを編集します。
     */
    public editScheduleInfo() {
        Logger("edit!")
        if (this.appo.status == 10) {
            //確定後の変更
            this.appoStatus = Appointment.typeEditAfterFixed
        } else if (this.appo.status == -1) {
            // キャンセル後の変更
            this.appoStatus = Appointment.typeEditAfterCanceled
        } else {
            // 変更
            this.appoStatus = Appointment.typeEdit
        }
        gtagClick(`調整内容変更モーダル`, this.appoStatus)

        // this.beforeAppo = Object.assign({}, this.rm.currentAppointment);

        this.$vfm.open("EditAppointmentModal")
    }

    editAttendees() {
        this.$vfm.open("EditAttendeesModal")
    }

    public goGmap() {
        gtagClick(`FIX -> gmapへ遷移`, `${this.appo.location_name}`)
    }

    public locationHref() {
        if (Util.isUrl(this.appo.location_name)) {
            gtagClick(`アポ場所遷移`, `${this.appo.location_name}`)
            return this.appo.location_name
        }
        let online = Util.isOnline(this.appo.location_name)
        if (online) {
            // 遷移させない.
            return
        }
        gtagClick(`アポ場所遷移 > Gmap`, `${this.appo.location_name}`)
        // let address = this.appo?.selected_location?.address
        let address = LocationTag.getAddressFromCurrentAppo(this.appo, true)

        return Util.gmapLinkUrl(address)
    }

    get canCreateOrEditAppo() {
        if (!this.appo) return false
        if (!this.rm.currentRoom) return false
        if (this.rm.currentRoom.is_owner_group) return true

        if (![Appointment.statusFix, Appointment.statusCancel].includes(this.appo.status)) return false
        if (this.pdm.isSuspend) return false
        if (!this.rm.currentRoom.is_owner && !this.rm.currentRoom.room_setting.can_change_appointment_from_other) return false
        // オーナーと編集を許可されている場合のみ表示
        return true
    }

    // gtag用
    public addNewAppo() {
        gtagClick(`新規でアポ作成`, `AppointmentCardView`)
        this.addNewAppointment()
    }

    /**
     * 新規でアポを作成します。
     */
    public addNewAppointment() {
        this.appoStatus = Appointment.typeAddNew
        Logger(`${funcName()} 新規でアポを作成します。`)
        this.$vfm.open("EditAppointmentModal")
    }

    public hideEditAppointmentModal(didChange: boolean, appo: Appointment) {
        this.doesChangeAppo = didChange
        this.$vfm.close("EditAppointmentModal")
        if (didChange && appo) {
            this.pdm.resetPdm()
            this.pdm.setNew(this.rm.currentRoom, this.rm.userInfo, appo)
            sleep(50).then(_ => {
                this.refresher.needRefresh.push(`pdm`)
            })
        }
    }

    public openLocationModal() {
        Logger(`${funcName()}`)
        this.$vfm.open(`InputLocationModal`)
    }

    /**
     * 調整カレンダーを共有します。
     */
    public shareRestrictCalendar() {
        Logger(`${funcName()} `)
        if (!this.rm.currentRoom) return

        if (!this.rm.astag || this.rm.astag.id == "newId") {
            // 調整カレンダーを未作成のため、astagviewに飛ばします。
            gtagClick(`遷移: FIX → AstagView`, `shareButton`)
            this.notice.message = `先に調整カレンダーを作成してください。`
            this.$router.push(`/available_schedule`)
            return
        }

        const _astag = this.rm.astag ? this.rm.astag : this.rm.defaultAstag

        const _room = this.rm.currentRoom
        this.rm.startProgress()

        AvailableScheduleTag.share(_room, _astag).then(res => {
            // this.pdm.refreshCurrentPossibleDates()
            if (res) {
                Logger(`${funcName()} 共有できたので、possible datesを更新します。`)
                let shareMems = this.pdm.room.shared_schedule_member_ids || []
                shareMems.push(this.pdm.userInfo.user_id)
                this.refresher.needRefresh.push(`pdm`)
                this.pdm.updateDisplayType(`possibles`)
                // this.updatePossibleDates();
                this.rm.startProgress()
                gtagClick(`調整カレンダー共有`, `${_astag.id}`)
            }
        })
    }

    /**
     * 調整カレンダーの共有を解除します。
     */
    public unshareRestrictCalendar() {
        Logger(`${funcName()}`)
        if (!this.rm.currentRoom) return

        const _room = this.rm.currentRoom
        this.rm.startProgress()
        AvailableScheduleTag.unshare(_room, this.rm.astag).then(res => {
            // this.pdm.refreshCurrentPossibleDates()
            Logger(`${funcName()} 解除しました。astagを更新.`)
            this.pdm.room.shared_schedule_member_ids = (this.pdm.room.shared_schedule_member_ids || []).filter(
                memid => memid != this.rm.userInfo.user_id
            )
            this.pdm.sharedMembers = (this.pdm.sharedMembers || []).filter(m => m.user_id != this.rm.userInfo.user_id)
            this.refresher.needRefresh.push(`pdm`)
            this.pdm.updateDisplayType(`possibles`)
            // this.updatePossibleDates();
            gtagClick(`調整カレンダー共有解除`, `${this.rm.astag.id}`)
        })
    }

    public changeCalendar() {
        Logger(`${funcName()} 利用する調整カレンダーを変更.`)
        this.$vfm.open("SelectAstagsModal")
    }

    /**
     * 一覧からastagを選択してきます。
     */
    public selectEnd(astag: AvailableScheduleTag) {
        if (this.astag && this.astag.id == astag.id) return

        this.rm.astag = astag
        this.astag = astag
        this.shareRestrictCalendar()
    }

    public shareAnotherAstag() {
        this.$vfm.open("SelectAstagsModal")
    }

    public showPastAppointments() {
        this.$vfm.open("PastAppointmentsModal")
    }

    public copyPublicId() {
        copyToClipboard(this.rm.currentRoom.public_id)
        $(this).select()
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`FIX publicIDをコピー`, `rid:${this.rm.currentRoom.room_id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
    }

    get hasOnlineMeetingUrl() {
        if (!this.currentAppo.is_online_meeting) return false
        if (!this.currentAppo.online_meeting) return false
        return Util.isUrl(this.appo.online_meeting.online_meeting_url)
    }

    clickFormMember(mem: RoomMember) {
        this.member = mem
        // フォーム確認
        this.$nextTick(() => {
            this.$vfm.open(`AnsweredFormModal`)
        })
    }

    changeProgressesStatus() {
        gtagClick(`アポlarge change進捗ステータス`, ``)
        if (this.rm.currentRoom.progress_status == `stay`) {
            this.alertTitle = `日程提案を表示に変更`
            this.alertText = `日程提案を表示します。`
            this.$vfm.open(`AlertWithTextareaModal`)
        } else {
            this.alertTitle = `日程提案を非表示に変更`
            this.alertText = `日程提案を非表示にします。`
            this.$vfm.open(`AlertModal`)
        }
    }

    changeChatStatus() {
        gtagClick(`アポlarge changeチャットステータス`, ``)
        let rs = this.rm.currentRoom.room_setting

        if (rs.use_chat) {
            this.alertTitle = `チャット非表示に変更`
            this.alertText = `チャットを非表示にします。チャットが非表示の場合、チャットで確定時などに送られるメッセージは送信されません。メールでご確認ください。`
            this.$vfm.open(`AlertModal`)
        } else {
            this.alertTitle = `チャットの利用開始`
            this.alertText = `チャットを表示します。`
            this.$vfm.open(`AlertWithTextareaModal`)
        }
    }

    clickOK(message: string = null) {
        Logger(`${funcName()}`)
        let _type = ``
        if ([`チャット非表示に変更`, `チャットの利用開始`].includes(this.alertTitle)) {
            // チャット変更
            _type = `use_chat`
        } else if ([`日程提案を表示に変更`, `日程提案を非表示に変更`].includes(this.alertTitle)) {
            // 日程提案表示変更
            _type = `progress_status`
        }
        if (this.loading) return
        this.AlertModal.loading = true
        this.loading = true

        Room.changeDetailStatus(this.rm.currentRoom, _type, this.rm.userInfo.user_id, message).then(_room => {
            if (_room) {
                this.rm.updateCurrentInfo(_room)
                sleep(100).then(_ => {
                    this.refresher.needRefresh.push(`room`)
                })
            }
            this.loading = false
            this.AlertModal.loading = false
            this.$vfm.close(`AlertModal`)
            this.$vfm.close(`AlertWithTextareaModal`)
        })
    }

    clickCancel() {
        Logger(`${funcName()}`)
        this.$vfm.close(`AlertModal`)
        this.$vfm.close(`AlertWithTextareaModal`)
    }

    get showChatContent() {
        if (Util.isBlank(this.rm.userInfo)) return
        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_use_chat`)
    }

    // カスタム確定します.
    clickFixDateFromOwner() {
        Logger(`${funcName()} us: ${Util.output(this.rm.user_setting)}`)
        if (Util.isBlank(this.rm.userInfo)) {
            return
        }

        this.rm.getAstagDetail(this.rm.currentRoom.owner_avaialble_schedule_tag_id).then(res => {
            if (!res) return

            let _room = this.rm.currentRoom
            let _appo = this.appo
            this.cm.setNew(this.rm.userInfo, { ...this.rm.user_setting }, res, { ..._room.room_setting }, false, _appo)

            let startTime
            Logger(`${funcName()} appo:${_appo?.id}`)
            if (Util.isPresent(_appo.start_time)) {
                startTime = DateTime.fromSeconds(_appo.start_time)
            } else {
                let now = DateTime.local()
                startTime = DateTime.fromISO(`${now.plus({ days: 1 }).toFormat(`yyyy-MM-dd`)}T10:00:00`)
            }

            this.cm.selectedEvent = Event.createOptional(`optional_add`, startTime, _appo.duration)

            this.$vfm.open(`CalendarEventModal`)
        })
    }

    get canChangeMag() {
        if (!this.currentAppo) return false
        if (this.currentAppo.status != 10) return false
        if (this.rm.currentRoom.is_owner || this.rm.currentRoom.is_shared) return true

        // Util.isPresent(this.rm.astag.meeting_attendees_groups) &&
        // this.rm.astag.meeting_attendees_groups.length > 1 &&

        return false
    }

    clickChangeMag() {
        this.$vfm.open(`ChangeMeetingAttendeesGroupModal`)
    }

    createdEvent() {}

    get checkStarred() {
        if (!this.rm.currentRoom) return false

        return this.rm.currentRoom.starred
    }

    public pushStar() {
        Logger(`${funcName()}`)
        let action = `add`
        let room = this.rm.currentRoom

        if (room.starred) {
            // はずす
            action = `remove`
        }
        gtagClick(`fav room`, `${action}`)
        this.rm.startProgress()
        Room.saveFav(room.id, action, room.room_type).then(e => {
            this.rm.endProgress()
            if (!e) return
            if (action == `add`) {
                room.starred = true
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms.push(room)
                }
            } else {
                room.starred = false
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms = favtab.rooms.filter(r => r.id != room.id)
                }
            }
            this.rm.updateFav(room)
        })
    }

    updateMember(mem: RoomMember) {
        let _mem = this.rm.currentRoom.members.find(m => m.id == mem.id)
        if (_mem) {
            Object.assign(_mem, mem)
            // this.refresher.needRefresh.push(`room`)
        }
    }
}
