import axios from "axios"
import { funcName, Logger } from "packs/common"
import Util from "./Util"

export default class Timezones {
    static getLocalTimezone(): Promise<any> {
        let dt = new Date()
        let offset = dt.getTimezoneOffset()
        let diff = (offset / 60.0) * -1
        // let diff = 3

        return this.getTimezones().then(jsonData => {
            let tzArray = jsonData
            let tz = tzArray.find(tz => tz.diff == diff)
            return tz
        })
    }

    static getTimezones(): Promise<any> {
        return axios.get(`/timezones/timezones.json`).then(res => {
            if (Util.isPresent(res.data)) {
                return res.data
            }
            return null
        })
        // fetch("./timezones.json")
        //     .then(response => {
        //         return
        //     })
        //     .then(jsondata => console.log(jsondata))
        // return [
        //     { value: "Dateline Standard Time", name: "(UTC-12:00) International Date Line West", diff: -12.0 },
        //     { value: "Samoa Standard Time", name: "(UTC-11:00) Samoa", diff: -11.0 },
        //     { value: "UTC-11", name: "(UTC-11:00) Coordinated Universal Time-11", diff: -11.0 },
        //     { value: "Aleutian Standard Time", name: "(UTC-10:00) Aleutian Islands", diff: -10.0 },
        //     { value: "Hawaiian Standard Time", name: "(UTC-10:00) Hawaii", diff: -10.0 },
        //     { value: "Marquesas Standard Time", name: "(UTC-09:30) Marquesas Islands", diff: -9.5 },
        //     { value: "Alaskan Standard Time", name: "(UTC-09:00) Alaska", diff: -0.0 },
        //     { value: "UTC-09", name: "(UTC-09:00) Coordinated Universal Time-09", diff: -9.0 },
        //     { value: "Yukon Standard Time", name: "(UTC-07:00) Yukon", diff: -7.0 },
        //     { value: "Pacific Standard Time (Mexico)", name: "(UTC-08:00) Baja California", diff: -8.0 },
        //     { value: "UTC-08", name: "(UTC-08:00) Coordinated Universal Time-08", diff: -8.0 },
        //     { value: "Pacific Standard Time", name: "(UTC-08:00) Pacific Time (US & Canada)", diff: -8.0 },
        //     { value: "US Mountain Standard Time", name: "(UTC-07:00) Arizona", diff: -7 },
        //     { value: "Mountain Standard Time (Mexico)", name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan", diff: -7 },
        //     { value: "Mountain Standard Time", name: "(UTC-07:00) Mountain Time (US & Canada)", diff: -7 },
        //     { value: "Eastern Standard Time (Mexico)", name: "(UTC-05:00) Chetumal", diff: -5 },
        //     { value: "Central America Standard Time", name: "(UTC-06:00) Central America", diff: -6 },
        //     { value: "Central Standard Time", name: "(UTC-06:00) Central Time (US & Canada)", diff: -6 },
        //     { value: "Easter Island Standard Time", name: "(UTC-06:00) Easter Island", diff: -6 },
        //     { value: "Central Standard Time (Mexico)", name: "(UTC-06:00) Guadalajara, Mexico City, Monterrey", diff: -6 },
        //     { value: "Canada Central Standard Time", name: "(UTC-06:00) Saskatchewan", diff: -6 },
        //     { value: "SA Pacific Standard Time", name: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco", diff: -5 },
        //     { value: "Eastern Standard Time", name: "(UTC-05:00) Eastern Time (US & Canada)", diff: -5 },
        //     { value: "Haiti Standard Time", name: "(UTC-05:00) Haiti", diff: -5 },
        //     { value: "Cuba Standard Time", name: "(UTC-05:00) Havana", diff: -5 },
        //     { value: "US Eastern Standard Time", name: "(UTC-05:00) Indiana (East)", diff: -5 },
        //     { value: "Turks And Caicos Standard Time", name: "(UTC-05:00) Turks and Caicos", diff: -5 },
        //     { value: "Venezuela Standard Time", name: "(UTC-04:00) Caracas", diff: -4 },
        //     { value: "Magallanes Standard Time", name: "(UTC-03:00) Punta Arenas", diff: -3 },
        //     { value: "Paraguay Standard Time", name: "(UTC-04:00) Asuncion", diff: -4 },
        //     { value: "Atlantic Standard Time", name: "(UTC-04:00) Atlantic Time (Canada)", diff: -4 },
        //     { value: "Central Brazilian Standard Time", name: "(UTC-04:00) Cuiaba", diff: -4 },
        //     { value: "SA Western Standard Time", name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan", diff: -4 },
        //     { value: "Pacific SA Standard Time", name: "(UTC-04:00) Santiago", diff: -4 },
        //     { value: "Newfoundland Standard Time", name: "(UTC-03:30) Newfoundland", diff: -3.5 },
        //     { value: "Tocantins Standard Time", name: "(UTC-03:00) Araguaina", diff: -3 },
        //     { value: "E. South America Standard Time", name: "(UTC-03:00) Brasilia", diff: -3 },
        //     { value: "SA Eastern Standard Time", name: "(UTC-03:00) Cayenne, Fortaleza", diff: -3 },
        //     { value: "Argentina Standard Time", name: "(UTC-03:00) City of Buenos Aires", diff: -3 },
        //     { value: "Greenland Standard Time", name: "(UTC-03:00) Greenland", diff: -3 },
        //     { value: "Montevideo Standard Time", name: "(UTC-03:00) Montevideo", diff: -3 },
        //     { value: "Saint Pierre Standard Time", name: "(UTC-03:00) Saint Pierre and Miquelon", diff: -3 },
        //     { value: "Bahia Standard Time", name: "(UTC-03:00) Salvador", diff: -3 },
        //     { value: "UTC-02", name: "(UTC-02:00) Coordinated Universal Time-02", diff: -2 },
        //     { value: "Mid-Atlantic Standard Time", name: "(UTC-02:00) Mid-Atlantic", diff: -2 },
        //     { value: "Azores Standard Time", name: "(UTC-01:00) Azores", diff: -1 },
        //     { value: "Cape Verde Standard Time", name: "(UTC-01:00) Cabo Verde Is.", diff: -1 },
        //     { value: "UTC", name: "(UTC) Coordinated Universal Time", diff: 0 },
        //     { value: "GMT Standard Time", name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London", diff: 0 },
        //     { value: "Greenwich Standard Time", name: "(UTC+00:00) Monrovia, Reykjavik", diff: 0 },
        //     { value: "Morocco Standard Time", name: "(UTC+01:00) Casablanca", diff: 1 },
        //     { value: "W. Europe Standard Time", name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", diff: 1 },
        //     {
        //         value: "Central Europe Standard Time",
        //         name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        //         diff: 1,
        //     },
        //     { value: "Romance Standard Time", name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", diff: 1 },
        //     { value: "Central European Standard Time", name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb", diff: 1 },
        //     { value: "W. Central Africa Standard Time", name: "(UTC+01:00) West Central Africa", diff: 1 },
        //     { value: "Libya Standard Time", name: "(UTC+02:00) Tripoli", diff: 2 },
        //     { value: "Namibia Standard Time", name: "(UTC+02:00) Windhoek", diff: 2 },
        //     { value: "Jordan Standard Time", name: "(UTC+02:00) Amman", diff: 2 },
        //     { value: "GTB Standard Time", name: "(UTC+02:00) Athens, Bucharest", diff: 2 },
        //     { value: "Middle East Standard Time", name: "(UTC+02:00) Beirut", diff: 2 },
        //     { value: "Egypt Standard Time", name: "(UTC+02:00) Cairo", diff: 2 },
        //     { value: "E. Europe Standard Time", name: "(UTC+02:00) Chisinau", diff: 2 },
        //     { value: "Syria Standard Time", name: "(UTC+02:00) Damascus", diff: 2 },
        //     { value: "West Bank Standard Time", name: "(UTC+02:00) Gaza, Hebron", diff: 2 },
        //     { value: "South Africa Standard Time", name: "(UTC+02:00) Harare, Pretoria", diff: 2 },
        //     { value: "FLE Standard Time", name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", diff: 2 },
        //     { value: "Israel Standard Time", name: "(UTC+02:00) Jerusalem", diff: 2 },
        //     { value: "South Sudan Standard Time", name: "(UTC+02:00) Juba", diff: 2 },
        //     { value: "Kaliningrad Standard Time", name: "(UTC+02:00) Kaliningrad", diff: 2 },
        //     { value: "Sudan Standard Time", name: "(UTC+02:00) Khartoum", diff: 2 },
        //     { value: "Turkey Standard Time", name: "(UTC+03:00) Istanbul", diff: 3 },
        //     { value: "Belarus Standard Time", name: "(UTC+03:00) Minsk", diff: 3 },
        //     { value: "Arabic Standard Time", name: "(UTC+03:00) Baghdad", diff: 3 },
        //     { value: "Arab Standard Time", name: "(UTC+03:00) Kuwait, Riyadh", diff: 3 },
        //     { value: "Russian Standard Time", name: "(UTC+03:00) Moscow, St. Petersburg", diff: 3 },
        //     { value: "E. Africa Standard Time", name: "(UTC+03:00) Nairobi", diff: 3 },
        //     { value: "Astrakhan Standard Time", name: "(UTC+04:00) Astrakhan, Ulyanovsk", diff: 4 },
        //     { value: "Russia Time Zone 3", name: "(UTC+04:00) Izhevsk, Samara", diff: 4 },
        //     { value: "Saratov Standard Time", name: "(UTC+04:00) Saratov", diff: 4 },
        //     { value: "Volgograd Standard Time", name: "(UTC+04:00) Volgograd", diff: 4 },
        //     { value: "Iran Standard Time", name: "(UTC+03:30) Tehran", diff: 3.5 },
        //     { value: "Arabian Standard Time", name: "(UTC+04:00) Abu Dhabi, Muscat", diff: 4 },
        //     { value: "Azerbaijan Standard Time", name: "(UTC+04:00) Baku", diff: 4 },
        //     { value: "Mauritius Standard Time", name: "(UTC+04:00) Port Louis", diff: 4 },
        //     { value: "Georgian Standard Time", name: "(UTC+04:00) Tbilisi", diff: 4 },
        //     { value: "Caucasus Standard Time", name: "(UTC+04:00) Yerevan", diff: 4 },
        //     { value: "Afghanistan Standard Time", name: "(UTC+04:30) Kabul", diff: 4.5 },
        //     { value: "West Asia Standard Time", name: "(UTC+05:00) Ashgabat, Toshkent", diff: 5 },
        //     { value: "Ekaterinburg Standard Time", name: "(UTC+05:00) Ekaterinburg", diff: 5 },
        //     { value: "Pakistan Standard Time", name: "(UTC+05:00) Islamabad, Karachi", diff: 5 },
        //     { value: "Qyzylorda Standard Time", name: "(UTC+05:00) Qyzylorda", diff: 5 },
        //     { value: "India Standard Time", name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", diff: 5.5 },
        //     { value: "Sri Lanka Standard Time", name: "(UTC+05:30) Sri Jayawardenepura", diff: 5.5 },
        //     { value: "Nepal Standard Time", name: "(UTC+05:45) Kathmandu", diff: 5.75 },
        //     { value: "Central Asia Standard Time", name: "(UTC+06:00) Astana", diff: 6.0 },
        //     { value: "Bangladesh Standard Time", name: "(UTC+06:00) Dhaka", diff: 6 },
        //     { value: "Omsk Standard Time", name: "(UTC+06:00) Omsk", diff: 6 },
        //     { value: "Altai Standard Time", name: "(UTC+07:00) Barnaul, Gorno-Altaysk", diff: 7 },
        //     { value: "N. Central Asia Standard Time", name: "(UTC+07:00) Novosibirsk", diff: 7 },
        //     { value: "Tomsk Standard Time", name: "(UTC+07:00) Tomsk", diff: 7 },
        //     { value: "Myanmar Standard Time", name: "(UTC+06:30) Yangon (Rangoon)", diff: 6.5 },
        //     { value: "SE Asia Standard Time", name: "(UTC+07:00) Bangkok, Hanoi, Jakarta", diff: 7 },
        //     { value: "W. Mongolia Standard Time", name: "(UTC+07:00) Hovd", diff: 7 },
        //     { value: "North Asia Standard Time", name: "(UTC+07:00) Krasnoyarsk", diff: 7 },
        //     { value: "China Standard Time", name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", diff: 8 },
        //     { value: "North Asia East Standard Time", name: "(UTC+08:00) Irkutsk", diff: 8 },
        //     { value: "Singapore Standard Time", name: "(UTC+08:00) Kuala Lumpur, Singapore", diff: 8 },
        //     { value: "W. Australia Standard Time", name: "(UTC+08:00) Perth", diff: 8 },
        //     { value: "Taipei Standard Time", name: "(UTC+08:00) Taipei", diff: 8 },
        //     { value: "Ulaanbaatar Standard Time", name: "(UTC+08:00) Ulaanbaatar", diff: 8 },
        //     { value: "Tokyo Standard Time", name: "(UTC+09:00) Osaka, Sapporo, Tokyo", diff: 9 },
        //     { value: "Transbaikal Standard Time", name: "(UTC+09:00) Chita", diff: 9 },
        //     { value: "North Korea Standard Time", name: "(UTC+09:00) Pyongyang", diff: 9 },
        //     { value: "Aus Central W. Standard Time", name: "(UTC+08:45) Eucla", diff: 8.75 },
        //     { value: "Korea Standard Time", name: "(UTC+09:00) Seoul", diff: 9 },
        //     { value: "Yakutsk Standard Time", name: "(UTC+09:00) Yakutsk", diff: 9 },
        //     { value: "Cen. Australia Standard Time", name: "(UTC+09:30) Adelaide", diff: 9.5 },
        //     { value: "AUS Central Standard Time", name: "(UTC+09:30) Darwin", diff: 9.5 },
        //     { value: "E. Australia Standard Time", name: "(UTC+10:00) Brisbane", diff: 10.0 },
        //     { value: "AUS Eastern Standard Time", name: "(UTC+10:00) Canberra, Melbourne, Sydney", diff: 10.0 },
        //     { value: "West Pacific Standard Time", name: "(UTC+10:00) Guam, Port Moresby", diff: 10.0 },
        //     { value: "Tasmania Standard Time", name: "(UTC+10:00) Hobart", diff: 10.0 },
        //     { value: "Vladivostok Standard Time", name: "(UTC+10:00) Vladivostok", diff: 10.0 },
        //     { value: "Bougainville Standard Time", name: "(UTC+11:00) Bougainville Island", diff: 11.0 },
        //     { value: "Magadan Standard Time", name: "(UTC+11:00) Magadan", diff: 11.0 },
        //     { value: "Sakhalin Standard Time", name: "(UTC+11:00) Sakhalin", diff: 11.0 },
        //     { value: "Lord Howe Standard Time", name: "(UTC+10:30) Lord Howe Island", diff: 10.5 },
        //     { value: "Russia Time Zone 10", name: "(UTC+11:00) Chokurdakh", diff: 11.0 },
        //     { value: "Norfolk Standard Time", name: "(UTC+11:00) Norfolk Island", diff: 11.0 },
        //     { value: "Central Pacific Standard Time", name: "(UTC+11:00) Solomon Is., New Caledonia", diff: 11.0 },
        //     { value: "Russia Time Zone 11", name: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky", diff: 12.0 },
        //     { value: "New Zealand Standard Time", name: "(UTC+12:00) Auckland, Wellington", diff: 12.0 },
        //     { value: "UTC+12", name: "(UTC+12:00) Coordinated Universal Time+12", diff: 12.0 },
        //     { value: "Fiji Standard Time", name: "(UTC+12:00) Fiji", diff: 12.0 },
        //     { value: "Kamchatka Standard Time", name: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old", diff: 12.0 },
        //     { value: "Chatham Islands Standard Time", name: "(UTC+12:45) Chatham Islands", diff: 12.75 },
        //     { value: "UTC+13", name: "(UTC+13:00) Coordinated Universal Time+13", diff: 13.0 },
        //     { value: "Tonga Standard Time", name: "(UTC+13:00) Nuku'alofa", diff: 13.0 },
        //     { value: "Line Islands Standard Time", name: "(UTC+14:00) Kiritimati Island", diff: 14.0 },
        // ]
    }
}
