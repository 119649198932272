
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, getLang, funcName } from "packs/common"
import { gtagLog, gtagClick, gtagPage, gtagEvent, gtagEventOwner, gtagPageOwner } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FormContent from "packs/pages/schedule/FormContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import Notice from "packs/models/Notice"
import RoomSetting from "packs/models/RoomSetting"
import FormUtil from "packs/utils/FormUtil"
import CalendarManager from "packs/models/CalendarManager"
import Refresher from "packs/models/Refresher"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarUtil from "packs/utils/CalendarUtil"
import AssignForm from "packs/models/AssignForm"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FormContent,
    },
})
export default class AssignFormMainContent extends Vue {
    // data:

    @Prop()
    room: Room

    @Prop()
    loading: boolean

    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone

    href
    Util = Util
    refresher = Refresher
    Appointment = Appointment
    loadingFormSending = false

    currentStep: string = null

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.

    answeredForm: boolean = false
    answeredFields: FormField[] = null

    additionalParams = null // パラメータを保持.

    aform: AssignForm = null
    endWithMessage: string = null

    public created() {
        let lang = getLang()
        Logger(`${funcName()} lang:${lang}`)
        // if (!Util.isPublic()) this.currentStep = `calendar`
        this.getAdditionalParams()
    }

    public mounted() {
        this.getAssignForm()
    }

    public getAssignForm() {
        this.rm.getAformDetail(this.room.assign_form_id).then(res => {
            this.aform = res
        })
    }

    /**
     * パラメータがURLに付随している場合は、自動でそのフィールドを埋めます
     */
    getAdditionalParams() {
        this.additionalParams = FormUtil.getParams()
    }

    /**
     * 入力すべきフォームがあるparentRoomか.（入力したかどうかの判定ではありません.）
     * ParentRoomの場合は、日程 -> Formの順番で固定です。
     */
    public hasFieldsYetInParentRoom(room: Room = null): boolean {
        if (this.isPreview) return
        if (Util.isBlank(room)) room = this.room
        if (Util.isBlank(room) || Util.isBlank(room.members)) return
        let _ffs = room.form_fields
        let myMem = room.members.find(m => m.user_id == this.rm.userInfo.user_id && m.answered_status == `not_yet`)
        return Util.isPresent(_ffs) && myMem && myMem.role != 100 ? true : false
    }

    /**
     * フォーム送信
     */
    public completeForm(fields: FormField[]) {
        Logger(`${funcName()} formが完成しました:${Util.output(fields)}`)
        this.answeredForm = true
        this.answeredFields = fields

        // 送信するルームを作成.
        let _aform = AssignForm.copy(this.aform)
        if (Util.isPresent(fields)) {
            _aform.form_fields = fields
        }

        if (this.loadingFormSending) return
        this.rm.startProgress()
        this.loadingFormSending = true
        // let keyType = `public_id` // public_id or param_key

        let params = {
            room_id: this.room.id,
            assign_form_id: this.room.assign_form_id,
            form: FormField.toSendParams(fields),
        }

        AssignForm.complete(this.room.assign_form_id, params).then(res => {
            this.rm.endProgress()
            this.loadingFormSending = false
            if (res) {
                res.userInfo ? this.rm.setUserInfo(res.userInfo) : null
                let room = res.room as Room
                if (res.goto_type == `public_room` && Util.isPresent(room)) {
                    this.rm.updateCurrentInfo(room)
                    let _appo = room.current_appointment
                    this.pdm.setNew(room, res.userInfo, _appo)
                    this.refresher.needRefresh.push(`room`)
                    this.pdm.resetPdm()
                    this.pdm.setNew(this.rm.currentRoom, this.rm.userInfo, _appo)
                    this.rm.guestKey = res.guestKey
                    sleep(50).then(_ => {
                        this.refresher.needRefresh.push(`pdm`)
                    })

                    this.checkAndRedirectIfUrlHasPresent(room)
                } else if (res.goto_type == `end_with_message`) {
                    this.endWithMessage = res.ret_params.destination_value
                } else if (res.goto_type == `url`) {
                    window.location.href = Util.getUrl(res.ret_params.destination_value)
                }
            }
        })
    }

    /**
     * ThanksPage表示時にリダイレクトURLを設定している場合は、リダイレクトします。
     */
    checkAndRedirectIfUrlHasPresent(room: Room) {
        if (Util.isBlank(room)) return

        let _appo = room.current_appointment
        let _mem = room.members.find(m => m.user_id == this.rm.userInfo.user_id)

        // フォームの入力後、日程調整表示ステータスがfalseの場合、そのまま完成に変更.
        if (_mem && _mem.answered_status == `done` && (_appo.status == 10 || this.room.progress_status == `stay`)) {
            if (RoomSetting.isRedirectUrl(room)) {
                let _thanksPage = room.room_setting.thanks_page
                let _url = _thanksPage.redirect_url_after_send
                gtagEvent(`timing_complete`, `完了後遷移`, `${_url}`)
                location.href = Util.getUrl(_url)
                return
            }
        }
    }

    autoSendForm(fields: FormField[]) {
        Logger(`${funcName()}`)
        this.completeForm(fields)
    }

    @Watch(`currentStep`)
    updateCurrentStep() {
        this.pdm.updateCurrentStep(this.currentStep)
    }

    skipForm() {}
}
