
// モジュールを読込.
import { Options, Prop, Vue, Emit } from "vue-property-decorator"
declare var gtag: any
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import Notice from "packs/models/Notice"
import Util from "packs/utils/Util"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
    },
})
export default class ModalInnerInquiry extends Vue {
    // data
    isSmartPhone = isMobile(window.navigator).phone
    notice = Notice

    content = ""
    rating = -1
    rm = RoomManager

    @Emit()
    hide() {}

    public created() {}

    public rate(starNum: number) {
        const images: any = $("img.satiStar")
        this.rating = starNum

        let index = 1
        for (let image of images) {
            if (index <= starNum) {
                $(image).attr("src", "/assets/icons/star_fill")
            } else {
                $(image).attr("src", "/assets/icons/star_null")
            }
            index += 1
        }
    }

    public inputContent(e) {
        this.content = e
    }

    public sendInquiry() {
        // フィールドに入力があるか確認
        if (this.content.length == 0) {
            this.notice.message = "お問い合わせ内容を入力してください。"
            return
        }
        if (this.rating == -1) {
            this.notice.message = "現在の満足度を評価してください。"
            return
        }
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(this.content, this.rating).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
            this.hide()
        })
    }
}
