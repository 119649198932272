
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { funcName, Logger } from "packs/common"
import CalendarUtil from "packs/utils/CalendarUtil"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import SelectLocationsModal from "packs/pages/link/modals/SelectLocationsModal.vue"
import LocationIcon from "packs/components/icons/LocationIcon.vue"
import FixTypeButtons from "packs/pages/link/parts/room_settings/FixTypeButtons.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import AvailableStepSizeContent from "packs/pages/link/parts/available_schedule/AvailableStepSizeContent.vue"
import MeetingSpanContent from "packs/pages/link/parts/available_schedule/MeetingSpanContent.vue"
import AvailableDaysOfWeeksTable from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTable.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import MeetingTimeContent from "packs/pages/link/parts/room_settings/MeetingTimeContent.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import Util from "packs/utils/Util"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import RoomSetting from "packs/models/RoomSetting"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import { DateTime } from "luxon"
import RoomCreateManager from "packs/models/RoomCreateManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        SelectTag,
        FlashNotice,
        TextfieldTag,
        TextareaTag,
        LblueListContent,
        SelectLocationsModal,
        LocationIcon,
        FixTypeButtons,
        MdTextfieldTag,
        AvailableStepSizeContent,
        MeetingSpanContent,
        AvailableDaysOfWeeksTable,
        IconHelp,
        MeetingTimeContent,
    },
})
export default class AppointmentInfoContent extends Vue {
    /**
     * Appointment.typeEdit `日程調整内容の変更`
       Appointment.typeEditAfterFixed `日程調整内容の変更`
       Appointment.typeEditAfterCanceled `日程調整内容の変更`
       Appointment.typeAddNew `日程調整を新規で作成`
     */
    @Prop()
    appoStatus: string

    @Prop()
    members: RoomMember[]

    @Prop()
    locationNames: string[]

    @Prop()
    titles: string[]

    @Prop()
    appo: Appointment

    @Prop() // room_settings/schedule_view
    from: string

    @Prop() // room_settingsの場合にroom_settingを取得
    rs: RoomSetting

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    currentAppo: Appointment = null
    action
    beforeAppo: Appointment = null
    loctags: LocationTag[] = null // 場所から登録した作成済みのloctags一覧.
    Appointment = Appointment
    edited = false
    isCustomTime = false
    openSubTitleView = false

    cm = CalendarManager

    public async mounted() {
        this.action = roomStorage.fetchAction()
        // this.currentAppo = roomStorage.fetchAppointment() || Appointment.createDefault()
        this.updateAppoFromParent()
        Logger(`action: ${this.action}`)
        Logger(`AppointmentInfo: ${Util.output(this.currentAppo)}`)
    }

    @Watch("appo", { deep: true })
    public updateAppoFromParent() {
        if (!this.appo) return
        let _appo = Appointment.copy(this.appo)
        this.beforeAppo = Appointment.copy(_appo)

        if (_appo.location_select_type == `selectable_or_registrable`) {
            let _loctags = _appo.selectable_locations
            let _freeFormLoctag = LocationTag.createRegistrable()
            if (Util.isPresent(_loctags)) {
                if (!_loctags.find(l => l.public_id == `free`)) {
                    _appo.selectable_locations.push(_freeFormLoctag)
                }
            } else {
                _appo.selectable_locations = [_freeFormLoctag]
            }
            _appo = Appointment.setLocationSelectType(_appo, _appo.selectable_locations)
        }
        if ([Appointment.typeAddNew].includes(this.appoStatus)) {
            // location_nameを空にして、selectableを有効にします。
            _appo.location_name = null
        }

        this.currentAppo = _appo

        this.updateLocations()
    }

    updateLocations() {
        this.rm.getLocations().then(e => {
            Logger(`登録済みのロケーション情報を取得してきました: ${Util.output(e)} `)
            this.loctags = e || []
        })
    }

    public getTitle(): string {
        if (!this.currentAppo) return `打ち合わせ`
        return this.currentAppo.name
    }

    public clickOpenSubTitleView() {
        if (this.openSubTitleView) {
            this.openSubTitleView = false
        } else {
            this.openSubTitleView = true
        }
    }

    public inputTitle(e) {
        this.currentAppo.name = e
        this.updateCurrentAppo()
    }

    public inputSameTitle() {
        this.currentAppo.name = this.beforeAppo.name
        this.updateCurrentAppo()
    }

    public getLocationName(): string {
        if (this.currentAppo) {
            return this.currentAppo.location_name
        }
        return ``
    }

    public inputLocationName(e) {
        this.edited = true
        this.currentAppo.location_name = e
        this.currentAppo = LocationTag.searchAndSetLoctag(e, this.loctags, this.currentAppo)
        this.updateCurrentAppo()
    }

    // public inputSameLocation() {
    //     this.currentAppo.location_name = this.beforeAppo.location_name
    //     this.searchAndSetLoctag(this.beforeAppo.location_name)
    // }

    public setLocationInfo(loc: string) {
        this.currentAppo.location_name = loc
        this.currentAppo = LocationTag.searchAndSetLoctag(loc, this.loctags, this.currentAppo)
        this.updateCurrentAppo()
    }

    public setTitleInfo(title) {
        this.currentAppo.name = title
        this.updateCurrentAppo()
    }

    public getMemo(): string {
        if (this.currentAppo) {
            return this.currentAppo.memo
        }
        return ``
    }

    public inputMemo(e) {
        this.currentAppo.memo = e
        this.updateCurrentAppo()
    }

    /**
     * 場所タグ選択モーダルを表示します。
     */
    public useSelectLocations() {
        // 複数場所選択モードに変える.
        this.$vfm.open(`SelectLocationsModal`)
    }

    /**
     * 選択された場所タグを表示します。
     */
    public saveLocations(loctags: LocationTag[]) {
        let _appo = Appointment.setLocationSelectType(this.currentAppo, loctags)
        this.$vfm.close(`SelectLocationsModal`)
        this.currentAppo = _appo
        this.updateCurrentAppo()
    }

    public removeLoc(loctag: LocationTag) {
        // let _appo = Appointment.copy(this.currentAppo)
        let _loctags = this.currentAppo.selectable_locations.filter(l => l.public_id != loctag.public_id)
        let _appo = Appointment.setLocationSelectType(this.currentAppo, _loctags)
        this.currentAppo = _appo
        this.updateCurrentAppo()
    }

    /**
     * 仲介が参加する用の調整カレンダーを紐付けます。
     */
    public linkCal(e) {
        // Chukaiなら変更できます。
        if (!this.currentAppo.required_owner_participation) {
            this.currentAppo.required_owner_participation = true
        } else {
            this.currentAppo.required_owner_participation = false
        }
        Logger(`AppoInfo appo required_owner_participation: ${this.currentAppo.required_owner_participation}`)
        this.updateCurrentAppo()
    }

    // 日程を確定できるタイプ
    public fixBy(typeString) {
        Logger(`日程を確定できるタイプ: ${typeString}`)
        this.currentAppo.fix_type = Appointment.fixTypeFromString(typeString)
        this.updateCurrentAppo()
    }

    public defaultStepSizeTitle() {
        if (Util.isBlank(this.rs)) return
        let stepSize = this.rs.step_size_type || `hourly`
        Logger(`stepsize: ${stepSize}`)

        let meetingTimeArr = CalendarUtil.selectDisplayMeetingTimeArr
        return meetingTimeArr.find(mt => mt.time == stepSize).type
    }

    // 刻み幅を選択します. （RoomSettingの場合のみ.）
    public selectStepSize(e) {
        Logger(`selectStepSize: ${e}`)
        let dic = CalendarUtil.selectDisplayMeetingTimeArr.find(m => m.type == e)
        if (!dic) return
        this.$emit(`updateStepSize`, dic.time)
    }

    /**
     * 最後に編集した状態を保存しておきます。
     */
    // @Watch("currentAppo", { deep: true })
    public updateCurrentAppo(): void {
        Logger(`updatecurrentAppo: ${Util.output(this.currentAppo)}`)
        if (this.rcm && this.rcm.room && this.rcm.room_setting) {
            this.rcm.room_setting.duration = this.currentAppo.duration
        }
        if (this.cm && this.cm.rs) {
            this.cm.previewDuration = this.currentAppo.duration
        }
        this.$emit("updateAppo", this.currentAppo)
    }

    public addAdow(dtag: DateTag) {
        Logger(`${funcName()} from:${this.from}, dtag:${Util.output(dtag)}`)
        if (this.from == `room_settings`) {
            let rs = { ...this.rcm.room_setting }
            if (dtag.select) {
                dtag.select = false
                rs.available_days_of_weeks = DateTag.deleteFromArray(this.rs.available_days_of_weeks, dtag)
            } else {
                dtag.select = true
                let hasAdow = false
                rs.available_days_of_weeks.forEach(adow => {
                    if (DateTag.isSameAdow(adow, dtag)) {
                        hasAdow = true
                    }
                })
                // 同じdtagがなかったら登録.
                if (hasAdow) {
                    // 既に登録済みです。
                    Notice.message = `既に同じ設定の繰り返し予定が登録済みです。`
                } else {
                    rs.available_days_of_weeks.push(dtag)
                    // this.cm.astag.systemUpdatedAt = DateTime.local().toMillis()
                }
            }
            this.rcm.updateRoomSetting(rs)
            if (this.cm && this.cm.rs) {
                this.cm.updateRoomSetting(rs)
            }
            return
        }
        if (dtag.select) {
            dtag.select = false
            this.rcm.room_setting.available_days_of_weeks = DateTag.deleteFromArray(
                this.rcm.room_setting.available_days_of_weeks,
                dtag
            )

            this.cm.astag.systemUpdatedAt = DateTime.local().toMillis()
        } else {
            dtag.select = true
            let hasAdow = false
            this.rcm.room_setting.available_days_of_weeks.forEach(adow => {
                if (DateTag.isSameAdow(adow, dtag)) {
                    hasAdow = true
                }
            })
            // 同じdtagがなかったら登録.
            if (hasAdow) {
                // 既に登録済みです。
                Notice.message = `既に同じ設定の繰り返し予定が登録済みです。`
            } else {
                this.rcm.room_setting.available_days_of_weeks.push(dtag)
                // this.cm.astag.systemUpdatedAt = DateTime.local().toMillis()
            }
        }
    }

    updateFromChild(appo: Appointment) {
        this.currentAppo = Appointment.copy(appo)
        this.updateCurrentAppo()
    }

    changeDefaultTimezone() {
        this.rcm.currentTab = `schedule`
    }
}
