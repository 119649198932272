
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Logger } from "packs/common"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import AttendeeForm from "packs/pages/link/parts/AttendeeForm.vue"
import ModalDeleteFromAddressBook from "packs/pages/link/modals/ModalDeleteFromAddressBook.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"

// import { type } from "os";

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextfieldTag,
        TextareaTag,
        AttendeeForm,
        ModalDeleteFromAddressBook,
        RoomsLoader,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class AttendeeEditModal extends Vue {
    @Prop()
    currentMember: RoomMember

    @Prop()
    action: string

    @Prop()
    loading: boolean

    // data:
    notice = Notice

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    dtag = null
    type = ``
    comment = `` // メールに添付するコメント. appoのmemoに入れる.
    // loading = false

    selectedMember: RoomMember = null
    searchText = ``
    displayMembers: RoomMember[] = null
    systemUpdatedAt: number = Util.getSec()

    public created() {}

    public beforeUnmount() {}

    mounted() {}

    public addAttendee(member) {
        this.$emit("addAttendee", member)
    }

    public editAttendee(member: RoomMember) {
        this.$emit(`update`, member)
        // this.loading = true
        // RoomMember.saveAddressBook(member, "put").then(saved_user => {
        //     if (saved_user) {
        //         let mem = this.displayMembers.find(m => m.email == member.email)
        //         Object.assign(mem, saved_user)
        //         this.systemUpdatedAt = Util.getSec()
        //         this.notice.message = `アドレス帳を更新しました。`
        //     } else {
        //         this.notice.message = `アドレス帳をうまく更新できませんでした。後ほどお試しください。`
        //     }
        //     this.fadeOutAttendeesModal()
        //     this.loading = false
        // })
    }

    public fadeOutAttendeesModal() {
        this.$vfm.close("AttendeeEditModal")
    }

    public editUser(user) {
        this.selectedMember = user
        this.$vfm.open("AttendeeEditModal")
    }

    public cancel() {}

    public save() {}
}
