
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import Util from "packs/utils/Util"
import draggable from "vuedraggable"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UserIcon from "packs/components/icons/UserIcon.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import InputGroupNamespaceModal from "packs/pages/link/modals/InputGroupNamespaceModal.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import FormFieldContent from "packs/pages/link/parts/room_settings/FormFieldContent.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import FormField from "packs/models/FormField"
import RoomCreateManager from "packs/models/RoomCreateManager"
import FormCreateManager from "packs/models/FormCreateManager"
import AssignCondition from "packs/models/AssignCondition"
import AnswerCondition from "packs/models/AnswerCondition"

@Options({
    components: {
        UserIcon,
        MdTextfieldTag,
        InputGroupNamespaceModal,
        CheckboxTag,
        SelectTag,
        FormFieldContent,
        draggable,
        AnswerCondition,
        TextfieldTag,
        TextareaTag,
    },
})
export default class OneConditionContent extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room
    @Prop()
    cond: AssignCondition

    @Prop()
    condindex: number

    @Prop()
    fieldPublicIdDic: any

    @Prop()
    total: number

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    fcm = FormCreateManager
    // viewType = `list` // list / detail

    Util = Util
    AssignCondition = AssignCondition
    AnswerCondition = AnswerCondition
    // fieldPublicIdDic = {}
    updateTime = Util.getSec()

    // fields: FormField[] = null
    // systemUpdatedAt = Util.getSec()
    drag: boolean = false

    // selectableValues: string[] = []
    // // acond = []
    // edittingCondition: AssignCondition = null
    // edittingCondIndex: number = null // condition index
    // edittingCondgIndex: number = null // group index
    // newTextValue = ``
    // showNewAnscondField = false

    mounted() {
        Logger(`${funcName()}`)
    }

    editCondition(ac: AssignCondition, index: number) {
        Logger(`${funcName()} index:${index} ac:${ac?.id}`)
        // this.edittingCondition = ac
        // this.edittingCondIndex = index
        // this.edittingCondgIndex = 0
        // this.viewType = `detail`
        this.$emit(`editCondition`, ac, index)
    }

    clickUp() {
        Logger(`${funcName()}`)
        this.$emit(`clickUp`, this.cond)
    }

    clickDown() {
        Logger(`${funcName()}`)
        this.$emit(`clickDown`, this.cond)
    }

    removeCondition(cond, condindex) {
        Logger(`${funcName()} condindex:${condindex}`)
        this.$emit(`removeCondition`)
    }
}
