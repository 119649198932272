
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"

// コンポーネントを読込.
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import RoomLightOverViewContent from "packs/pages/link/parts/RoomLightOverViewContent.vue"
import AnsweredFormTable from "packs/pages/link/parts/room_summary/AnsweredFormTable.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import RoomManager from "packs/models/RoomManager"
import RoomHistoryDetail from "packs/models/RoomHistoryDetail"

@Options({
    components: { RoomsLoader, RoomLightOverViewContent, AnsweredFormTable, TextareaTag, VueFinalModal, MdProgressBar, IconHelp },
})
export default class AnsweredFormModal extends Vue {
    @Prop()
    room: Room

    @Prop()
    member: RoomMember

    // data
    hm = RoomHistoryManager
    rm = RoomManager

    selectedTabName = `current`

    loading = false
    isMe = false

    results = null
    attendees: any[] = null
    Util = Util
    otherRooms: Room[] = null
    // 選択中のルーム
    otherRoom: Room = null
    otherResults = null
    otherAttendees: any[] = null
    privateMemo: string = null
    isOpenPrivateMemoContent = false
    // currentRoom: Room = null
    assignFormAnswerResultsDic: any = null

    openModal() {
        gtagPage(`#/回答フォームモーダル`)
        this.updateRoomMember()
    }

    hide() {
        this.$vfm.close(`AnsweredFormModal`)
    }

    @Watch(`member`, { deep: true })
    updateRoomMember() {
        if (!this.member) return
        this.results = this.member.answer_results
        this.assignFormAnswerResultsDic = this.member.assign_form_answer_results
        this.privateMemo = this.member.private_memo
        if (Util.isPresent(this.privateMemo)) {
            this.isOpenPrivateMemoContent = true
        } else {
            this.isOpenPrivateMemoContent = false
        }
        this.attendees = this.member.additional_attendees
    }

    selectCategoryTab(tabname: string) {
        this.selectedTabName = tabname
        if (tabname == `other` && this.room) {
            if (this.otherRooms) return
            this.searchByMember(this.member)
        } else {
            this.updateRoomMember()
        }
    }

    searchByMember(mem: RoomMember) {
        Logger(`AnsweredFormModal#searchByMember ${mem.email}`)
        let userIds = [mem.user_id]
        let category = `member`

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        Room.search(null, this.rm.userInfo.user_id, userIds, null, null, null, category).then(rooms => {
            this.rm.endProgress()
            this.loading = false
            Logger(`AnsweredFormModal 検索して取得してきました。${(rooms || []).length}`)
            if (this.room) {
                rooms = rooms.filter(r => r.public_id != this.room.public_id)
            }

            this.otherRooms = rooms || []
        })
    }

    clickRoomCell(room: Room) {
        if (!room) return
        let mem = room.members.find(m => m.role != 100)
        if (mem) {
            this.otherResults = mem.answer_results
            this.otherAttendees = mem.additional_attendees
        }
        this.otherRoom = room
        sleep(100).then(_ => {
            document.getElementById(`otherAnsweredFormContent`).scrollIntoView({
                behavior: "smooth",
                block: "start",
            })
        })
    }

    backSelectRoom() {
        this.otherResults = null
        this.otherRoom = null
    }

    inputPrivateMemo(e) {
        Logger(`${funcName()} text:${e}`)
        this.privateMemo = e
    }

    clickSavePrivateMemo() {
        if (this.loading) return
        this.loading = true

        let mem = { ...this.member } as RoomMember
        mem.private_memo = this.privateMemo

        RoomMember.updatePrivateMemo(this.room, mem).then(e => {
            if (e) {
                Logger(`更新しました: private_memo:${this.privateMemo}`)
                this.$emit(`updateMember`, mem)
            }
            this.loading = false
        })
    }

    updateAnsweredResults(resultsDic, editAnswerKey) {
        Logger(`${funcName()} editAnswerKey:${editAnswerKey}, member:${Util.output(this.member)}`)
        if (this.loading) return
        this.loading = true
        let mem = { ...this.member } as RoomMember
        mem.answer_results = resultsDic
        let roomId = this.room?.id
        if (this.room?.room_type == `public_room` && this.member?.parent_room_id) {
            roomId = this.member?.parent_room_id
        }
        RoomMember.updateAnswerResults(this.room, mem, editAnswerKey, roomId).then(e => {
            if (e) {
                this.results = resultsDic
                if (editAnswerKey == `attendee1_name`) {
                    let dic = resultsDic[editAnswerKey]
                    if (dic) {
                        mem.name = dic.value
                    }
                }
                Logger(`${funcName()} 更新しました updateAnswerResults:${this.results}`)
                this.$emit(`updateMember`, mem)
            }
            this.loading = false
        })
    }

    openPrivateMemo() {
        if (this.isOpenPrivateMemoContent) {
            this.isOpenPrivateMemoContent = false
        } else {
            this.isOpenPrivateMemoContent = true
        }
    }
}
