
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"

// コンポーネントを読込.
import InputLocationModal from "packs/pages/link/modals/InputLocationModal.vue"
import AlertModal from "packs/components/modals/AlertModal.vue"
import AlertWithTextareaModal from "packs/components/modals/AlertWithTextareaModal.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Room from "packs/models/Room"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import RoomCreateManager from "packs/models/RoomCreateManager"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: {
        InputLocationModal,
        AlertModal,
        AlertWithTextareaModal,
    },
})
export default class AppointmentCardViewSmall extends Vue {
    // data:

    @Prop()
    type: string // `fill`, `overstart`, `overdue`, `already`, `fixed`

    @Prop()
    userType: string // user / guest / logged_in

    @Prop() // func
    editEditableContent

    @Ref()
    AlertModal

    rm = RoomManager
    rcm = RoomCreateManager
    cm = CalendarManager
    pdm = PossibleDatesManager

    isSP = isMobile(window.navigator).phone

    // beforeAppo: Appointment = null;
    Appointment = Appointment
    CalendarUtil = CalendarUtil
    UserPermissionsOption = UserPermissionsOption

    notice = Notice
    Util = Util
    saving = false
    // showShareButton: boolean;
    astag: AvailableScheduleTag = null

    locationName = ``
    contentWidth = 350

    member: RoomMember = null //フォーム表示用.

    alertTitle: string = null
    alertText: string = null
    loading = false

    public mounted() {
        this.astag = this.rm.astag
    }

    get hasOnlineMeetingUrl() {
        if (!this.rcm.appo.is_online_meeting) return false
        if (!this.rcm.appo.online_meeting) return false
        return Util.isUrl(this.rcm.appo.online_meeting.online_meeting_url)
    }

    editAppoInfo() {
        this.editEditableContent(`appo`)
    }

    editLocations() {
        this.editEditableContent(`locations`)
    }
}
