import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalInnerPaymentRequest = _resolveComponent("ModalInnerPaymentRequest")!
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, {
    "modal-id": "PaymentRequestModal",
    "content-class": "ofs modalContent shadowContent pad15 rad10 noscroll",
    onBeforeOpen: _ctx.openModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ModalInnerPaymentRequest, { onHide: _ctx.fadeOutAdModal }, null, 8, ["onHide"])
    ]),
    _: 1
  }, 8, ["onBeforeOpen"]))
}