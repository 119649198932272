
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import { Dropdown } from "uiv"

// モデルを読込.
import Room from "packs/models/Room"

@Options({
    components: { Dropdown },
})
export default class RoomCellEditMenu extends Vue {
    @Prop()
    room: Room

    @Prop()
    canDelete: boolean

    isSP = isMobile(window.navigator).phone
    Room = Room
    now = DateTime.local()

    public mounted() {}

    public copyPage() {
        this.$emit(`copyPage`)
    }

    public editPage() {
        this.$emit(`editPage`)
    }

    public showCloseRoomModal() {
        this.$emit(`showCloseRoomModal`)
    }

    public goSummary(room: Room) {
        this.$emit(`goSummary`, room)
    }

    public goSummaryNotFixed(room: Room) {
        this.$emit(`goSummaryNotFixed`, room)
    }

    public cancelVoted(room: Room) {
        this.$emit(`cancelVoted`, room)
    }

    public createFeedback(room: Room) {
        this.$emit(`createFeedback`, room)
    }

    public clickEditMenu() {
        this.$emit(`clickEditMenu`)
    }

    public changeActive(room: Room) {
        this.$emit(`changeActive`, room)
    }

    public cancelAppo() {
        this.$emit(`cancelAppo`, this.room)
    }

    public showShareAuthModal() {
        this.$emit(`showShareAuthModal`, this.room)
    }

    get showEditColumn() {
        if (this.room.room_type == `public_room`) {
            if (this.room.is_owner) return true
            if (this.room.is_shared) return true
        }
        if (this.room.status == -10) return true
        // if (this.room.is_owner || this.room.is_shared) return true
        return false
    }
}
