import axios from "axios"
import Util from "../utils/Util"
import { DateTime } from "luxon"
import Notice from "./Notice"
import { Logger } from "packs/common"
import Plan from "./Plan"
import Invoice from "./Invoice"

interface subm {
    plans?: Plan[]
    currentPlan?: Plan
    willStop?: boolean
    originPlan?: Plan
    upcomingInvoice?: Invoice
    invoices?: Invoice[]
    registered?: boolean
    brand?: string
    exp_month?: number
    exp_year?: number
    last4?: number
    paymentInfo?: any
    getSubscriptionData()
    setDataFromJson(data: any)
}

//  データストア
let _SubscriptionManager: subm = {
    plans: null,
    currentPlan: null,
    willStop: false,
    originPlan: null,
    upcomingInvoice: null,
    invoices: null,

    registered: false,
    brand: "",
    exp_month: -1,
    exp_year: -1,
    last4: -1,
    paymentInfo: null,

    getSubscriptionData() {
        // this.rm.startProgress()
        return axios
            .get(`${Util.prefixUrl}/subscriptions`)
            .then(res => {
                Logger(`list: ${Util.output(res.data)}`)
                this.plans = Plan.fetchFromJson(res.data.plans)
                Logger(`plans: ${Util.output(this.plans)}`)
                this.currentPlan = this.plans.find(p => p.is_current)
                this.willStop = this.plans.some(p => p.will_stop)
                const _plan: Plan = { ...this.currentPlan }
                this.originPlan = _plan

                this.upcomingInvoice = res.data.upcoming_invoice
                this.invoices = Invoice.fetchFromJson(res.data.invoices)
                Logger(`invoices: ${Util.output(this.invoices)}`)
                const paymentData = res.data.payment_method
                this.paymentInfo = paymentData
                // if (paymentData) {
                //     this.registered = paymentData.registered
                //     this.brand = paymentData.brand
                //     this.exp_month = paymentData.exp_month
                //     this.exp_year = paymentData.exp_year
                //     this.last4 = paymentData.last4
                // }
                // this.rm.endProgress()
                return res.data
            })
            .catch(e => {
                Notice.message = `うまく取得できませんでした。後ほどお試しください。`
                this.paymentInfo = {}
                this.invoices = []
                this.plans = []
                // this.rm.endProgress()
                return null
            })
    },
    /**
     * astagと一緒に送られてきたJSONデータをパースして保存します。（Invoice系は なし）
     * @param plans [Dictionary] JSONデータ.
     */
    setDataFromJson(plans: any) {
        Logger(`サブスク: ${Util.output(plans)}`)
        this.plans = Plan.fetchFromJson(plans)
        this.currentPlan = this.plans.find(p => p.is_current)
        this.willStop = this.plans.some(p => p.will_stop)
        const _plan: Plan = { ...this.currentPlan }
        this.originPlan = _plan
    },
}

export default _SubscriptionManager
