
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import Const from "packs/utils/Const"
import SelectUtil from "packs/utils/SelectUtil"
import DateTag from "packs/models/DateTag"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
    },
})
export default class SpanSlotContent extends Vue {
    @Prop()
    mins: number

    @Prop()
    afterText: string

    // 最小入力値
    @Prop()
    min: number

    // 最大入力値
    @Prop()
    max: number

    Util = Util
    spanSlotArr = SelectUtil.spanSlotArr
    SelectUtil = SelectUtil

    // 選択中のTypeにおけるvalue
    value: number = null
    // minutes, hours, days
    currentType: string = null
    defaultUnit = null

    isSP = isMobile(window.navigator).phone

    mounted() {
        // $(`.textFieldInModal`).eq(0).focus()
        // this.textFieldInModal.focus()
        this.calcurateMins()
    }

    calcurateMins(mins = null) {
        Logger(`${funcName()} mins:${mins}, this.mins:${this.mins}`)
        if (!mins && !this.mins) {
            this.value = 0
            this.defaultUnit = `分`
            return
        }

        let minutes = mins ? mins : this.mins
        let [time, unit] = DateTag.calcurateMins(minutes)
        this.value = time
        this.defaultUnit = unit
        Logger(`${funcName()} this.value:${time}, unit:${unit}`)
    }

    inputValue(e) {
        this.value = +e
        let dic = this.spanSlotArr.find(slotDic => slotDic.name == this.defaultUnit)
        if (dic) {
            this.defaultUnit = dic.name
            let mins = this.changeMin(dic.type)
            this.$emit(`updateSpan`, mins)
        }
    }

    clickValue() {}

    selectType(e) {
        Logger(`selectType: ${e}`)
        let dic = this.spanSlotArr.find(slotDic => slotDic.name == e)
        if (dic) {
            this.defaultUnit = dic.name
            let mins = this.changeMin(dic.type)
            this.$emit(`updateSpan`, mins)
        }
    }

    /**
     * @param type [string] minutes, hours, days
     */
    changeMin(type: string) {
        Logger(`${funcName()} value:${this.value} type:${type}`)
        let mins

        let val = this.value || 0
        if (type == `hours`) {
            mins = val * 60
        } else if (type == `days`) {
            mins = val * 60 * 24
        } else {
            mins = val
        }
        Logger(`${funcName()} mins:${mins}`)

        return mins
    }

    blurField() {
        if (!this.value) return

        let dic = this.spanSlotArr.find(slotDic => slotDic.name == this.defaultUnit)
        let mins = this.changeMin(dic.type)
        if (this.min && this.min > 0 && mins < this.min) {
            Logger(`最小値を入力値に変更します.`)
            mins = this.min
        }
        if (this.max && this.max > 0 && mins > this.max) {
            Logger(`最大値を入力値に変更します.`)
            mins = this.max
        }

        this.calcurateMins(mins)
        this.$emit(`updateSpan`, mins)
    }
}
