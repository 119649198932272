
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard } from "packs/common"

// コンポーネントを読込.
import SelectLocationsList from "packs/pages/link/parts/room_settings/SelectLocationsList.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import LocationTag from "packs/models/LocationTag"
import Appointment from "packs/models/Appointment"

@Options({
    components: { SelectLocationsList, VueFinalModal, MdProgressBar },
})
export default class SelectLocationsModal extends Vue {
    @Prop()
    parentId

    @Prop()
    isSuspend

    @Prop()
    isPreview

    @Prop()
    loctags: LocationTag[]

    @Prop()
    selectedLoctags: LocationTag[]

    @Prop()
    appo: Appointment

    loading = false

    rm = RoomManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    systemUpdatedAt = Util.getSec()

    currentLoctags = null
    allowFreeForm = false
    contentHeight = 380
    Util = Util

    // selectablePdm = null

    mounted() {
        // this.updateSelectablePdm()
    }

    @Watch("loctags", { deep: true })
    updateLoctags() {
        Logger(`updateLoctags: `)
        if (!this.loctags) return
        let selectedPublicIds = (this.selectedLoctags || []).map(l => l.public_id)
        let _loctags = [...this.loctags]
        if (this.currentLoctags && this.currentLoctags.find(l => l.public_id == `free`)) {
        } else {
            let freeFormLoctag = LocationTag.createRegistrable()
            _loctags.push(freeFormLoctag)
            this.currentLoctags = _loctags
        }
        for (let loc of this.currentLoctags) {
            loc.selected = selectedPublicIds.includes(loc.public_id) ? true : false
        }
        this.allowFreeForm = selectedPublicIds.includes(`free`) ? true : false
        this.systemUpdatedAt = Util.getSec()
    }

    @Watch("selectedLoctags", { deep: true })
    updateSelectedLoctags() {
        this.updateLoctags()
    }

    public changeLoading(status) {
        this.loading = status
    }

    public selectLocation(loctag: LocationTag) {
        if (loctag.selected) {
            loctag.selected = false
            if (loctag.public_id == `free`) this.allowFreeForm = false
        } else {
            loctag.selected = true
            if (loctag.public_id == `free`) this.allowFreeForm = true
        }
        this.systemUpdatedAt = Util.getSec()
    }

    public clickFree() {
        let freeform = this.currentLoctags.find(l => l.public_id == `free`)
        if (this.allowFreeForm) {
            this.allowFreeForm = false
            freeform.selected = false
        } else {
            this.allowFreeForm = true
            freeform.selected = true
        }

        this.systemUpdatedAt = Util.getSec()
    }

    closeModal() {
        this.$vfm.close(`SelectLocationsModal`)
    }

    /**
     * 提案を保存
     */
    public save() {
        let _loctags = this.currentLoctags.filter(l => l.selected)
        this.$emit(`saveLocations`, _loctags)
    }

    // @Watch("pdm", { deep: true })
    // updateSelectablePdm() {
    //     this.selectablePdm = this.pdm
    // }
}
