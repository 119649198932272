
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"

const roomStorage = new RoomStorage()

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class ChangeOwnerMyPublicRoomsModal extends Vue {
    @Prop()
    email: string

    // data
    rm = RoomManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validName = true

    astagName = ``
    saving = false
    notice = Notice

    public closeModal() {
        this.$vfm.close(`ChangeOwnerMyPublicRoomsModal`)
    }

    public start() {
        if (this.loading) return
        this.loading = true

        let params = { email: this.email }

        axios
            .post(`${Util.prefixUrl}/user_groups/change_owner_my_public_rooms`, params)
            .then(res => {
                this.loading = false
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                this.$emit(`requestedChangeOwner`, true)
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                this.loading = false
                NotificationControl.showErrorMessage(err)

                this.$emit(`requestedChangeOwner`, false)
            })
    }
}
