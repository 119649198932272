
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "../ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import AccountHeader from "./AccountHeader.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import IconLock from "packs/components/icons/IconLock.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import Switcher from "packs/components/forms/Switcher.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import Organization from "packs/models/Organization"

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        AccountHeader,
        TextfieldTag,
        TextareaTag,
        IconLock,
        OptionModal,
        Switcher,
    },
})
export default class AccountSettingsSaml extends Vue {
    // data:

    notice = Notice

    rm = RoomManager
    Util = Util
    selectedTab = "saml"

    isSP = isMobile(window.navigator).phone

    validName = true
    saving = false
    loading = false

    ipAddresses: string[] = null
    optionEventName = `SSO 課金リクエスト`
    canUseOption = false
    org: Organization = null

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkAdmin()
                this.canUseOption = this.rm.canUseOption(`management_sso`, false)

                this.getUserGroupSettings()
                // this.subm.getSubscriptionData().then(e => {});
            })
        } else if (this.rm.userInfo.user_group_role == 100) {
            // this.subm.getSubscriptionData().then(e => {});
            this.canUseOption = this.rm.canUseOption(`management_sso`, false)
            this.getUserGroupSettings()
            // this.namespace = this.rm.namespace
        } else {
            this.checkAdmin()
        }
    }

    public getUserGroupSettings() {
        this.org = this.rm.organization
    }

    updated() {
        this.checkAdmin()
    }

    mounted() {
        gtagPage("#/account/saml")
    }

    @Watch("rm.userInfo", { deep: true })
    public checkAdmin() {
        if (Util.isBlank(this.rm.userInfo)) return

        const uf = this.rm.userInfo
        const cate = `account_settings`

        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)) {
            // セキュリティに飛ばす

            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`)) {
            // 企業設定に飛ばす
            this.$router.push("/account_settings/notifications")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`)) {
            // このページ.
            this.$router.push("/account_settings")
            return
        }
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            // アカウント.
            this.$router.push("/account")
            return
        }

        this.$router.push("/")
        // location.href = "https://google.com";
    }

    public inputIPWhitelist(e: string) {
        let list = (e || ``).split(`,`).map(l => l.trim())

        Logger(`inputIPWhitelist list: ${Util.output(list)}`)
        this.ipAddresses = list
    }

    save() {
        gtagClick(`SSO 保存`, `${(this.ipAddresses || []).length}`)
        Organization.updateSSO(this.org).then(e => {
            // 保存完了
            if (e) {
                this.rm.organization.sso_settings = e.sso_settings
            }
        })
    }

    clickTextfield() {
        if (!this.canUseOption) {
            // モーダルを表示します.
            this.$vfm.open(`OptionModal`)
        }
    }

    clickOK() {
        gtagClick(`${this.optionEventName}`, `${(this.ipAddresses || []).length}`)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.$vfm.close(`OptionModal`)
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.optionEventName} キャンセル`, ``)
        this.$vfm.close(`OptionModal`)
        this.org.sso_settings.can_use_sso = false
    }

    clickCopyLink() {}

    inputEntityId(e) {
        Logger(`inputEntityId: ${e}`)
        this.org.sso_settings.sso_idp_entity_id = e
    }

    inputSSOTargetUrl(e) {
        Logger(`inputSSOTargetUrl: ${e}`)
        this.org.sso_settings.sso_idp_sso_target_url = e
    }

    inputSLOTargetUrl(e) {
        Logger(`inputSLOTargetUrl: ${e}`)
        this.org.sso_settings.sso_idp_slo_target_url = e
    }

    inputIdpCert(e) {
        Logger(`inputIdpCert: ${e}`)
        this.org.sso_settings.sso_idp_cert = e
    }

    changeActive() {
        if (!this.canUseOption) {
            this.$vfm.open(`OptionModal`)
            this.org.sso_settings.can_use_sso = false
            return
        }
        if (this.org.sso_settings.can_use_sso) {
            this.org.sso_settings.can_use_sso = false
        } else {
            this.org.sso_settings.can_use_sso = true
        }
        this.save()
    }

    changeForceSignIn() {
        if (this.org.sso_settings.is_force_sso_when_sign_in) {
            this.org.sso_settings.is_force_sso_when_sign_in = false
        } else {
            this.org.sso_settings.is_force_sso_when_sign_in = true
        }
        this.save()
    }
}
