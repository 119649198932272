export function truncate(value, length, omission = `...`) {
    if (!value) return ``

    length = length ? parseInt(length, 10) : 20
    omission = omission ? omission.toString() : "..."
    if (value.length <= length) {
        return value
    }
    return value.substring(0, length) + omission
}
