
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import BlackBoxEvent from "packs/pages/link/parts/available_schedule/BlackBoxEvent.vue"

// モデルを読込.
import Event from "packs/models/Event"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"

@Options({
    components: { BlackBoxEvent },
})
export default class MemberDailyLinePerCalendarTag extends Vue {
    // data:
    // @Prop()
    // date;

    @Prop()
    dailyEventsInfo

    @Prop()
    member

    // @Prop()
    // type; // all, one

    @Prop()
    mag_dic

    @Prop()
    currentTime: DateTime

    @Prop()
    myAttendee: RoomMember

    @Prop()
    events

    @Prop()
    calId: string

    @Prop()
    magId: string

    @Prop()
    calindex: number

    @Prop()
    duration: number

    @Prop()
    possible_events // このctagの可能日時.

    // 持っている情報
    //{ "event_readable": true, "possible_events": [ { "type": "possible_event", "short_format": "11071300", "start_time": 1573099200, "start_hour": 13, "duration": 240 } ], "events": [], "events_by_cal_id": {} }
    // ctagsNum = 0;
    rm = RoomManager

    currentHour?: number = null
    currentMin?: number = null
    barHeight = 0
    openChildCals

    // {before: {mode:, time:}, after: {mode:, time:}}
    travelTimeDic: any

    travelTimeBefore = 0 // minutes
    travelTimeBeforeStart: DateTime = null
    travelTimeAfter = 0 // minutes
    travelTimeAfterStart: DateTime = null
    tooltipBefore = ``
    tooltipAfter = ``
    calendarName: string = null
    // alldayEvents: Event[] = null;

    // currentEvent = null;
    // addedEvents = null;
    // removedEvents = null;
    // events = null; // event, possible_eventを保持
    // alldayEvents = null;
    // possibleEvents = null; // possible_eventを保持
    // dragEnd = false;
    // alldayHeight = 0;
    // // currentEventPosition = `` // "top", "bottom", "both" 上にavzoneがある、下にavzoneがある、上下両方にある

    mounted() {
        // DailyLineをクリック時にイベントを作成するイベント処理を追加します。
        // 他のDailyLineと区別するためにidを指定します。
        // Logger(`${this.id} this avzones: ${Util.output(this.avzones)}`);
        // if (this.dailyEventsInfo) {
        //     this.alldayEvents = this.dailyEventsInfo.events.filter(
        //         ev => ev.allday
        //     );
        // }

        // this.checkCtags();
        this.calcurateOrangeBar()

        this.currentHour = this.currentTime.hour
        this.currentMin = this.currentTime.minute

        this.updateCalendarName()

        if (!this.myAttendee) return
        // let dailyMyEvents = this.dailyEventsInfo[this.myAttendee.user_id];
        // Logger(`dailyMyEvents: ${dailyMyEvents}`);
        // if (!dailyMyEvents) return;

        // possible_eventsは幅のイベント.
        let magInfo = this.mag_dic[this.magId]
        let _dic = this.dailyEventsInfo.group_events_dic[this.magId]
        let posEvents = []
        if (_dic) {
            posEvents = _dic.possible_events
        } else {
            _dic = this.dailyEventsInfo.group_events_dic[magInfo.name]
            if (_dic) posEvents = _dic.possible_events
        }

        for (let _ev of posEvents) {
            this.travelTimeAfterStart = this.currentTime.plus({
                minutes: this.duration,
            })
            let eventEndTime = _ev.start_time + _ev.duration * 60

            if (_ev.start_time > this.currentTime.toSeconds() || this.travelTimeAfterStart.toSeconds() > eventEndTime) {
                continue
            }
            if (!_ev || !_ev.travel_time_by_cal_id) continue

            let timeDic = _ev.travel_time_by_cal_id[this.calId]
            if (!timeDic) continue

            this.travelTimeDic = timeDic
            this.travelTimeBefore = timeDic.before.time || 0 //min width
            this.travelTimeBeforeStart = this.currentTime.minus({
                minutes: this.travelTimeBefore,
            })
            if (timeDic.before.from) {
                this.tooltipBefore = `${timeDic.before.from}\n ↓ \n${timeDic.before.to}`
            }
            this.travelTimeAfter = timeDic.after.time || 0 //min width

            if (timeDic.after.from) {
                this.tooltipAfter = `${timeDic.after.from}\n ↓ \n${timeDic.after.to}`
            }
        }

        // Logger(`ev: ${ev}`);

        // 設定したバッファタイムを表示.（仮置き30分）

        // let travelTime = ev.travel_time_by_cal_id[this.calId]
        // this.travelTime = travelTime
        // Logger(`travelTime: ${travelTime}`)
        // if (!travelTime) return
        // this.travelTimeBefore = travelTime.before.time || 0 //min width
        // this.travelTimeBeforeStart = this.currentTime.minus({
        //     minutes: this.travelTimeBefore,
        // })
        // if (travelTime.before.from) {
        //     this.tooltipBefore = `${travelTime.before.from}<br> ↓ <br>${travelTime.before.to}`
        // }
    }

    public clickGoogleRoute(which) {
        gtagClick(`FIX -> gmap移動考慮`, `${which}`)
    }

    /**
     * which before/after
     */
    public locationHref(which) {
        let travel
        if (which == `before`) {
            travel = this.travelTimeDic.before
        } else {
            travel = this.travelTimeDic.after
        }
        if (!travel) return
        return `http://www.google.com/maps/dir/${travel.from}/${travel.to}`
    }

    startEndText() {
        return ""
        // if (!event) return "";
        // let st = this.event.start_hour;
        // let et = this.event.start_hour + this.event.duration / 60;
        // let etPoint = et - Math.floor(et);
        // let etmin = etPoint * 60;
        // return `${st} ~ ${Math.floor(et)}:${zeroPadding(etmin)}`;
    }

    @Watch("magId", { deep: true })
    updateCalendarName() {
        // if (!this.mag_dic || !this.magId || !this.calId) return
        let magInfo = this.mag_dic[this.magId]
        this.calendarName = magInfo.calendar_name_dic[this.calId]
        Logger(`calendarName: ${this.calendarName}`)
    }

    /**
     * アコーディオンにするカレンダー(ctags)があるか。
     */
    // public checkCtags(): boolean {
    //     if (!this.dailyEventsInfo) return false;
    //     Logger(
    //         `has ctags: ${Object.keys(this.dailyEventsInfo.events_by_cal_id)}`
    //     );
    //     this.ctagsNum = Object.keys(
    //         this.dailyEventsInfo.events_by_cal_id
    //     ).length;
    //     // this.calcurateOrangeBar();
    // }

    updated() {
        this.calcurateOrangeBar()
    }

    @Emit("calcurateOrangeBar")
    calcurateOrangeBar() {}

    // @Watch("astag", { deep: true })
}
