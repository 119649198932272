import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  width: "100%",
  height: "100",
  style: {"padding":"10px","background":"#f8f8f8"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { width: "76" }
const _hoisted_4 = {
  width: "390",
  style: {"text-align":"left"}
}
const _hoisted_5 = { class: "ml10" }
const _hoisted_6 = { class: "fs12 fw600" }
const _hoisted_7 = { class: "fs18 fw600" }
const _hoisted_8 = { class: "fs12 fw600 ml10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserIcon = _resolveComponent("UserIcon")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    (_ctx.member)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_UserIcon, {
                width: 60,
                canEdit: false,
                member: _ctx.member,
                class: "fr fw600 fs20",
                style: {"margin-left":"25px"}
              }, null, 8, ["member"])
            ]),
            _createElementVNode("td", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.member.company_name), 1)
                ]),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.member.name), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.message), 1)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}