
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"
import ScheduleSlideContent from "../ScheduleSlideContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import PossibleDate from "packs/models/PossibleDate"
import SubscriptionManager from "packs/models/SubscriptionManager"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"

const roomStorage = new RoomStorage()

@Options({
    components: { AdLimitFree, ScheduleSlideContent, UserIcon, VueFinalModal, MdProgressBar },
})
export default class FixScheduleModal extends Vue {
    @Prop()
    room: Room
    // @Prop() private canFix: boolean;

    @Prop()
    currentPdType: string // default,all,secondary

    @Prop()
    from: string // asview 調整カレンダー画面, schedule スケジュールビュー

    // data
    rm = RoomManager
    loading = false
    subm = SubscriptionManager
    showAds = false
    currentPd?: PossibleDate = null
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone
    Util = Util
    canFix = false

    currentAttendees?: RoomMember[] = null
    currentNotAttendees?: RoomMember[] = null
    currentUnknownAttendees?: RoomMember[] = null
    attendeesNum = -1

    pdm = PossibleDatesManager
    contentHeight = `calc(90vh - 90px)`

    selectedMag: MeetingAttendeesGroup = null // あくまで今は {name: string, id: string}のdictionary

    openModal() {
        this.pdm.checkFixOrNot()
        this.canFix = this.pdm.canFix
        gtagPage(`#/確定モーダル`)
        Logger(`確定前にcheckFixします: ${this.pdm.canFix}`)
        window.addEventListener("keyup", ev => {
            Logger(String.fromCharCode(ev.keyCode))
            if (ev.keyCode == 37) {
                gtagClick(`FIX MODAL ←`, `${this.currentPd ? this.currentPd.jp_format : ``}`)
                this.showBeforePossibleDate()
            } else if (ev.keyCode == 39) {
                gtagClick(`FIX MODAL →`, `${this.currentPd ? this.currentPd.jp_format : ``}`)
                this.showAfterPossibleDate()
            }
        })
    }

    public mounted() {
        this.updateRoom()
    }

    @Watch("room", { deep: true })
    updateRoom() {
        if (!this.room || !this.rm.currentAppointment) return
        if (this.from == `schedule` && Util.isPresent(this.room.members)) {
            this.attendeesNum = this.rm.currentAppointment.required_owner_participation
                ? this.room.members.length
                : this.room.members.length - 1
        }
    }

    // beforeUnmount() {
    //     window.removeEventListener("resize", this.adjustHeight)
    // }

    @Watch("pdm.currentPd", { deep: true })
    public updatePd() {
        Logger(`FixScheduleModal : created ${Util.output(this.pdm.currentPd)}`)
        Logger(`FixScheduleModal: センタ中のmag ${Util.output(this.pdm.selectedMag)}`)
        this.currentPd = this.pdm.currentPd
        let pds = this.getCurrentPds()
        const num = this.checkPdIndex(pds)
        this.checkPdIndexType(pds, num)
        // this.adjustHeight()
    }

    @Watch("pdm.selectedMag", { deep: true })
    public updateSelectedMag() {
        Logger(`FixScheduleModal : mag ${Util.output(this.pdm.selectedMag)}`)
        this.selectMag(this.pdm.selectedMag)
        // this.adjustHeight()
    }

    public hideFixScheduleModal() {
        this.$vfm.close("confirmFixScheduleModal")
    }

    public fixSchedule() {
        Logger(`予定を確定します。`)

        let appo = this.rm.currentRoom.current_appointment
        gtagClick(`日程確定`, `appo: ${appo.id} ${this.currentPd.jp_format}`)
        // if (this.rm.fixed_owner_this_month.length >= 5 && this.subm.currentPlan && this.subm.currentPlan.amount == 0) {
        //     Logger(`無料プランで6回目以降のアポ確定です。`)
        //     this.showAds = true
        //     return false
        // }

        if (this.loading) return
        this.loading = true

        let magId = this.selectedMag ? this.selectedMag.id : null
        Logger(`fixするmagid: ${magId}`)

        Appointment.update(appo, "fix", this.room, this.currentPd.start_time, magId).then(dic => {
            if (dic && Util.isPresent(dic.appo)) {
                // 確定できずにnextAction==`reload`だった場合、モーダルを表示し、日程候補をリロードします.
                let nextAction: string = dic.next_action
                let type: string = dic.type

                this.rm.setAppo(dic.appo)

                // 埋まっていた場合はモーダルを表示し、再調整を促します。
                if (type == `fix_schedule_failed` && nextAction == `reload`) {
                    Logger(`埋まっていたため再調整します。 ${dic.appo}`)
                    this.$emit(`rescheduledRoom`, dic.appo, true)
                } else {
                    gtagPage(`fixed`)
                    Logger(`appoが確定しました。 ${dic.appo}`)
                    this.$emit(`actionAfterFixed`, dic)
                }
            } else {
                // エラーの場合nullで更新しない。
            }
            this.loading = false
            this.$vfm.close("confirmFixScheduleModal")
        })
    }

    public fadeOutAdModal() {
        this.showAds = false
    }

    @Watch("currentPd", { deep: true })
    public refreshCurrentPdInfo() {
        if (!this.currentPd) return
        if (this.from == `asview`) return

        // this.selectedMag = null

        // TODO: 仲介者が参加する場合、しない場合を計算すること
        // ○の人数は、共有者全員
        this.currentAttendees = this.pdm.sharedMembers || []

        // それ以外が ?
        let members = this.rm.currentRoom.attendees || []
        let unknowns = []
        for (let _mem of members) {
            if (!this.currentAttendees.some(att => att.user_id == _mem.user_id)) {
                unknowns.push(_mem)
            }
        }
        this.currentUnknownAttendees = unknowns

        // 現在×の人を取得していないため、
        this.currentNotAttendees = []
    }

    public showBeforePossibleDate() {
        let pds = this.getCurrentPds()
        Logger(`before currentPdType: ${this.currentPdType} pds length: ${pds.length}`)

        let num = this.checkPdIndex(pds)
        if (num >= 1) {
            num -= 1
            this.currentPd = pds[num]
            Logger(`pdを変更: ${Util.output(this.currentPd)}`)
            gtagClick(`FIX MODAL 前の予定を確認`, `${this.currentPd.jp_format}`)
            this.pdm.selectCurrentPd(this.currentPd)
        }
        this.checkPdIndexType(pds, num)
    }

    public showAfterPossibleDate() {
        let pds = this.getCurrentPds()
        Logger(`after currentPdType: ${this.currentPdType} pds length: ${pds.length}`)
        let num = this.checkPdIndex(pds)
        if (num >= 0) {
            if (pds.length > num + 1) {
                num += 1
                this.currentPd = pds[num]
                Logger(`pdを変更: ${Util.output(this.currentPd)}`)
                gtagClick(`FIX MODAL 次の予定を確認`, `${this.currentPd.jp_format}`)
                this.pdm.selectCurrentPd(this.currentPd)
            }
        }
        this.checkPdIndexType(pds, num)
    }

    public checkPdIndex(pds: PossibleDate[]): number {
        let num = -1
        if (!pds || pds.length == 0) return num
        if (!this.currentPd) return num

        num = pds.findIndex(pd => PossibleDate.isSame(this.currentPd, pd))

        Logger(`num: ${num}`)
        return num
    }

    public checkPdIndexType(pds, num) {
        if (!pds) return
        if (num == 0) {
            this.pdIndexType = "first"
        } else if (num == pds.length - 1) {
            this.pdIndexType = "last"
        } else {
            this.pdIndexType = ``
        }
    }

    /**
     * 今回利用するPossibleDate[]を3タイプの中から取得してきます。
     */
    public getCurrentPds(): PossibleDate[] {
        return this.pdm.getPdsAfterRemoved()
    }

    /**
     * 選択中のmagIdを取得します。
     * magDic = {name: string, id: string}
     */
    public selectMag(magDic: MeetingAttendeesGroup) {
        Logger(`selectMag: ${Util.output(magDic)}`)
        // Logger(`selectMag ${Util.output(this.rm.astag)}`)
        // let mags = this.rm.astag.meeting_attendees_groups
        // let mag = mags.find(m => m.id == magId)
        this.selectedMag = magDic
        Logger(`magをはめました： ${magDic ? magDic.name : `null`}`)
    }
}
