
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import ListLoader from "packs/components/loader/ListLoader.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import UserSettingsGroupTags from "packs/pages/link/settings/UserSettingsGroupTags.vue"
import UserSettingsLocations from "packs/pages/link/settings/UserSettingsLocations.vue"
import UserSettingsCustomNotifications from "packs/pages/link/settings/UserSettingsCustomNotifications.vue"
import UserSettingsDownload from "packs/pages/link/settings/UserSettingsDownload.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import GroupTag from "packs/models/GroupTag"
import { useRoute } from "vue-router"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        SelectTag,
        TextfieldTag,
        UserSettingsHeader,
        MdTextfieldTag,
        ListLoader,
        ChipButton,
        UserSettingsGroupTags,
        UserSettingsLocations,
        UserSettingsCustomNotifications,
        UserSettingsDownload,
        OptionModal,
    },
})
export default class UserSettingsRooms extends Vue {
    // data:
    @Prop()
    tab: string
    // tab = null

    notice = Notice

    UserPermissionsOption = UserPermissionsOption
    rm = RoomManager
    Util = Util
    selectedTab = `rooms`

    isSP = isMobile(window.navigator).phone
    astag: AvailableScheduleTag = null
    today = DateTime.local().setZone("Asia/Tokyo")
    selectedCategory = `locations`

    Const = Const

    newTag: GroupTag = null
    groupTags: GroupTag[] = null
    editTag: GroupTag = null

    loading = false

    showSuggests = false
    myTabGroups = []
    searchText = null
    searchedTags = []

    smallTabNames = [`locations`, `group_tags`, `notifications`, `download`]

    @Ref()
    header

    created() {
        // let tabName = this.$route.params.tab
        // const route = useRoute()
        // let tabName = (route.params.tab as string) || `locations`
        let tabName = this.tab || `locations`
        Logger(`${funcName()} tabName:${tabName}`)
        this.newTag = GroupTag.createDefault()

        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                this.checkPermission()
            })
        } else {
            this.checkPermission()
        }

        if (Util.isPresent(tabName)) {
            if (this.smallTabNames.includes(tabName)) {
                this.selectCategory(tabName)
            }
        }
    }

    selectCategory(name: string) {
        this.selectedCategory = name
    }

    mounted() {
        gtagPage("#/settings/rooms")
        this.checkPermission()
    }

    checkPermission() {
        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `user_settings`, `user_settings_address_book`)) {
            this.$router.push("/settings/user")
        }
        this.rm.redirectTopIfChukaiFreePlan(`UserSettingsInfo1`)
    }

    @Watch(`rm.showPlanOptionModalWithName`)
    updateShowPlanOptionModalWithName() {
        Logger(`updateShowPlanOptionModalWithName: ${this.rm.showPlanOptionModalWithName}`)
        if (this.rm.showPlanOptionModalWithName) {
            this.$vfm.open(`OptionModal`)
        } else {
            this.$vfm.close(`OptionModal`)
        }
    }

    clickOK() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} 課金リクエスト`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.rm.showPlanOptionModalWithName} 課金リクエストがありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.rm.showPlanOptionModalWithName = null
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} キャンセル 課金リクエスト`, ``)
        this.rm.showPlanOptionModalWithName = null
    }

    closeOptionModal() {
        this.rm.showPlanOptionModalWithName = null
    }
}
