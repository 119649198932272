
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard } from "packs/common"
import { gtagLog, gtagPage, gtagClick, gtagEvent, gtagPageOwner } from "packs/GoogleTagManager"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"

@Options({
    components: {},
})
export default class ThanksPage extends Vue {
    // data:
    @Prop()
    appo: Appointment

    @Prop()
    room: Room

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    Util = Util
    Appointment = Appointment

    mounted() {
        gtagPage(`#/ThanksPage`)
    }

    @Watch(`room`, { deep: true })
    updateRoom() {
        if (!this.room) return
        if (!this.room.room_setting) return
        Logger(`ThanksPageでroomを更新しました.${Util.output(this.room.room_setting.thanks_page)}`)
    }

    getTitle() {
        return Util.isPresent(this.room.room_setting.thanks_page) && Util.isPresent(this.room.room_setting.thanks_page.title)
            ? this.room.room_setting.thanks_page.title
            : Util.thanksPageDefaultTitle
    }

    getContent() {
        return Util.isPresent(this.room.room_setting.thanks_page) && Util.isPresent(this.room.room_setting.thanks_page.content)
            ? this.room.room_setting.thanks_page.content
            : Util.thanksPageDefaultContent
    }

    hasButton() {
        return (
            Util.isPresent(this.room.room_setting.thanks_page) &&
            Util.isPresent(this.room.room_setting.thanks_page.button_name) &&
            Util.isUrl(this.room.room_setting.thanks_page.button_link)
        )
    }

    goLink() {
        gtagClick(`#/ThanksPage buttonLink`, `${this.room.room_setting.thanks_page.button_link}`)
        let url = this.room.room_setting.thanks_page.button_link
        location.href = Util.getUrl(url)
    }
}
