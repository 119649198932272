
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import LocationTag from "packs/models/LocationTag"
import Appointment from "packs/models/Appointment"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: { CheckboxTag },
})
export default class SelectLocationsList extends Vue {
    @Prop()
    parentId

    @Prop()
    isSuspend

    @Prop()
    isPreview

    @Prop() // すべての場所
    loctags: LocationTag[]

    @Prop()
    astag: AvailableScheduleTag

    // @Prop() // 選択中の場所
    // selectedLoctags: LocationTag[]

    @Prop()
    appo: Appointment

    @Prop()
    hideSelectFreeBox: boolean

    @Prop() // 単一選択・複数選択
    selectOne: boolean

    loading = false

    rm = RoomManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    systemUpdatedAt = Util.getSec()
    selectedLoctags: LocationTag[] = []

    currentLoctags = null
    allowFreeForm = false
    contentHeight = 380
    Util = Util
    truncate = truncate

    // selectablePdm = null

    mounted() {
        // this.updateSelectablePdm()
        this.updateLoctags()
    }

    @Watch("appo", { deep: true })
    updateAppo() {
        this.updateLoctags()
    }

    @Watch("loctags", { deep: true })
    updateLoctags() {
        Logger(`${funcName()} loctags:${this.loctags?.length} appo:${this.appo?.id}, astag:${this.astag?.id}`)
        if (Util.isBlank(this.loctags)) return

        this.selectedLoctags = Util.isPresent(this.appo?.selectable_locations) ? [...this.appo.selectable_locations] : []
        let selectedPublicIds = (this.selectedLoctags || []).map(l => l.public_id)

        let loctags = [...this.loctags]
        if (this.astag?.is_shared) {
            // 共有を受けている場合は、共有されている場所のみを表示します.
            Logger(`${funcName()} sharedLocationsDic:${Util.output(this.rm.sharedLocationsDic)}`)
            loctags = this.rm.sharedLocationsDic[this.astag.id]
        }
        let _currentLoctags = Appointment.adjustLocations(this.appo, loctags)

        // フリーフォームの場所を追加します.
        // 選択されている場合は、上のチェックボックスと下の選択を連動させます.
        let freeLocation = _currentLoctags.find(l => l.public_id == `free`)
        if (freeLocation && (freeLocation.selected || this.allowFreeForm)) {
            this.allowFreeForm = true
            freeLocation.selected = true
        } else {
            this.allowFreeForm = false
            if (!freeLocation) {
                let freeform = LocationTag.createRegistrable()
                _currentLoctags.push(freeform)
            }
        }

        for (let _loctag of _currentLoctags) {
            if (selectedPublicIds.includes(_loctag.public_id)) {
                _loctag.selected = true
            } else {
                _loctag.selected = false
            }
        }
        Logger(`${funcName()} _currentLoctags(${_currentLoctags?.length}):${Util.output(_currentLoctags)}`)
        this.currentLoctags = _currentLoctags

        // let selectedPublicIds = (this.selectedLoctags || []).map(l => l.public_id)
        // let _loctags = [...this.loctags]
        // if (this.currentLoctags && this.currentLoctags.find(l => l.public_id == `free`)) {
        //     this.allowFreeForm = true
        // } else {
        //     let freeFormLoctag = LocationTag.createRegistrable()
        //     _loctags.push(freeFormLoctag)
        //     this.currentLoctags = _loctags
        // }
        // for (let loc of this.currentLoctags) {
        //     loc.selected = selectedPublicIds.includes(loc.public_id) ? true : false
        // }

        // this.allowFreeForm = true

        this.systemUpdatedAt = Util.getSec()
    }

    public changeLoading(status) {
        this.loading = status
    }

    public selectLocation(loctag: LocationTag) {
        Logger(`${funcName()} loctag:${Util.output(loctag)}`)
        // 単一選択
        if (this.selectOne) {
            loctag.selected = true
            this.selectedLoctags = [loctag]
        } else {
            // 複数選択
            if (loctag.selected) {
                loctag.selected = false
                if (loctag.public_id == `free`) this.allowFreeForm = false

                this.selectedLoctags = (this.selectedLoctags || []).filter(_lt => _lt.public_id != loctag.public_id)
            } else {
                loctag.selected = true
                if (loctag.public_id == `free`) this.allowFreeForm = true
                this.selectedLoctags = [...(this.selectedLoctags || [])]
                this.selectedLoctags.push(loctag)
            }
        }

        // let _selectedLoctags = this.currentLoctags.filter(l => l.selected)
        this.$emit(`select`, this.selectedLoctags)
        this.systemUpdatedAt = Util.getSec()
    }

    public clickFree() {
        let freeform = this.currentLoctags.find(l => l.public_id == `free`)
        Logger(`clickFree: ${Util.output(freeform)}`)
        if (this.allowFreeForm) {
            this.allowFreeForm = false
            if (freeform) {
                freeform.selected = false
            } else {
                freeform = LocationTag.createRegistrable()
                freeform.selected = false
            }
        } else {
            this.allowFreeForm = true
            freeform.selected = true
        }

        this.systemUpdatedAt = Util.getSec()
        let _selectedLoctags = this.currentLoctags.filter(l => l.selected)
        this.$emit(`select`, _selectedLoctags)
    }

    closeModal() {
        this.$vfm.close(`SelectLocationsModal`)
    }

    /**
     * 提案を保存
     */
    public save() {
        let _loctags = this.currentLoctags.filter(l => l.selected)
        this.$emit(`saveLocations`, _loctags)
    }

    // @Watch("pdm", { deep: true })
    // updateSelectablePdm() {
    //     this.selectablePdm = this.pdm
    // }
}
