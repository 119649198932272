import { Logger } from "./common"
import Util from "./utils/Util"
declare var gtag: any
declare var $: any
declare var dataLayer2: any

let isProduction = process.env.NODE_ENV == "production" ? true : false

export function gtagLog(...args) {
    gtag(...args)
    // gtag("event", "click", {
    //     event_category: "調整ページへ遷移",
    //     event_label: `一覧 → 調整ページ ${room.members.length}人 [${room.title}] rid:${room.room_id}`
    // });
    // }
}

export function gtagEvent(eventName: string, category: string, label: string) {
    Logger(`gtag => eventName: ${eventName}, category: ${category} label: ${label}`)
    gtag("event", eventName, { event_category: category, event_label: label })
    /**
     * 推奨タグ: timing_complete, view_item
     */
}

/**
 * クリックイベントを送信
 * args any {event_category: string, event_label: string}
 */
export function gtagClick(category: string, label: string) {
    Logger(`gtag => category: ${category} label: ${label}`)
    gtag("event", "click", { event_category: category, event_label: label })
}

/**
 * マウスオーバー（ホバー）イベントを送信
 * args any {event_category: string, event_label: string}
 */
export function gtagHover(category: string, label: string) {
    Logger(`gtag => category: ${category} label: ${label}`)
    gtag("event", "hover", { event_category: category, event_label: label })
}

/**
 *
 * @param location ex.) "#/messages"
 */
export function gtagPage(location: string) {
    Logger(`gtag => page_view: ${location}`)
    gtag("event", "page_view", {
        page_path: location,
        page_location: location,
    })
}

/**
 * ユーザーグループに設定されたGAにデータを送信します。
 * @param location ページURLや表示している内容など
 * @param params [Dictionary<key: string, value:string>] 未実装
 */
export function gtagPageOwner(location: string, params: any = null) {
    Logger(`gtag owner => page_view: ${location}`)
    try {
        // GAの場合、
        gtag("event", "page_view", { send_to: "owner", page_path: location, page_location: location })

        // GTMの場合、
        dataLayer2.push({ event: `page_view`, eventCategory: location, eventAction: location })
    } catch (e) {}
}

/**
 * ユーザーグループに設定されたGAにデータを送信します。
 * @param eventName [string] `FIX`
 * @param category [string] `engagement`
 * @param label [string] `日程確定日・日程提案日`
 */
export function gtagEventOwner(eventName: string = `FIX`, category: string = `engagement`, label: string) {
    Logger(`gtag owner => event: eventName:${eventName}, category:${category}, label:${label}`)

    try {
        // GAの場合、
        gtag("event", eventName, { send_to: "owner", event_category: category, event_label: label })

        // GTMの場合、
        dataLayer2.push({ event: eventName, eventCategory: category, eventAction: label })
    } catch (e) {}
}
