
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { funcName, getLang, Logger, sleep } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"
import { marked } from "marked"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import UserFileImage from "packs/components/icons/UserFileImage.vue"
import { Quill } from "@vueup/vue-quill"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Const from "packs/utils/Const"
import { gtagClick } from "packs/GoogleTagManager"
import RoomSetting from "packs/models/RoomSetting"
import AppealContent from "packs/models/AppealContent"
import FileUtil from "packs/utils/FileUtil"
import UserFile from "packs/models/UserFile"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import TemplateUtil from "packs/utils/TemplateUtil"
import { DateTime } from "luxon"

@Options({
    components: { UserIcon, UserFileImage },
})
export default class ScheduleAppealContent extends Vue {
    // data:

    @Prop()
    room: Room

    @Prop()
    isSuspend: boolean

    @Prop()
    additionalParams: any

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    pdm = PossibleDatesManager
    marked = marked

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.
    rs: RoomSetting = null
    apc: AppealContent = null
    attachmentFile: UserFile = null

    TemplateUtil = TemplateUtil
    truncate = truncate

    finishDL = false

    deltaHtml = ``

    public mounted() {
        this.updateRoomSetting()
        this.updateDisplayType()
    }

    get isHideAppoInfo() {
        if (Util.isBoard()) return true
        if (!this.additionalParams) return false
        if (this.additionalParams.hide_appointment_info == `true`) return true
        return false
    }

    @Watch(`room`, { deep: true })
    updateRoomSetting() {
        if (Util.isBlank(this.room)) return

        this.rs = this.room.room_setting
        this.apc = this.room.appeal_content
        let appo = this.room.current_appointment
        if (this.rs && Util.isPresent(this.rs.attachment_files)) {
            this.attachmentFile =
                appo.status == 10
                    ? this.rs.attachment_files.find(f => f.timing_type == `fixed_appointment`)
                    : this.rs.attachment_files.find(f => f.timing_type == `inquire_on_public_room`)
            if (this.rs.can_auto_download) {
                // ページ開封3秒後にDL開始.
                sleep(3000).then(_ => {
                    if (this.finishDL) return
                    this.downloadFile(this.attachmentFile)
                })
            }
        }
        this.createDeltaHtml()
    }

    createDeltaHtml() {
        if (this.apc && this.apc.content_type == `delta`) {
            const quill = new Quill(document.createElement("div"))
            quill.setContents(this.apc.delta_content)
            const html = quill.root.innerHTML
            this.deltaHtml = html
        }
    }

    @Watch(`pdm.displayType`, { deep: true })
    updateDisplayType() {
        this.hasAttachment
    }

    get hasImage(): boolean {
        if (Util.isBlank(this.room)) return false
        if (Util.isBlank(this.apc)) return false
        return this.apc.image_url ? true : false
    }

    get hasContent(): boolean {
        if (Util.isBlank(this.room)) return false
        if (Util.isBlank(this.apc)) return false
        return this.apc.content || this.apc.delta_content ? true : false
    }

    get hasTitle(): boolean {
        if (Util.isBlank(this.room)) return false
        if (Util.isBlank(this.apc)) return false
        return this.apc.title ? true : false
    }

    get hasAttachment(): boolean {
        if (Util.isBlank(this.room)) return false
        if (Util.isBlank(this.rs)) return false
        let appo = this.room.current_appointment
        // let mem = this.room.members.find(m => m.user_id==this.rm.userInfo.user_id)

        let mem = this.room.members.find(m => m.user_id == this.rm.userInfo.user_id)
        if (mem) {
            if (this.room.is_owner) return false
        }

        // 確定時または提案時のみ表示.
        if (appo.status == 10 || this.pdm.didShare() || mem?.answered_status == `done`) {
            let files = this.rs.attachment_files
            if (appo.status == 10) {
                files = files.filter(f => f.timing_type == `fixed_appointment`)
            } else {
                files = files.filter(f => f.timing_type == `inquire_on_public_room`)
            }
            return Util.isPresent(files) ? true : false
        }
        return false
    }

    get hasOpenPeriod(): boolean {
        if (Util.isBlank(this.room)) return false
        if (Util.isBlank(this.rs)) return false
        Logger(`${funcName()} open_period_type:${this.rs.open_period_type}`)
        return [`start_time_only`, `end_time_only`, `start_and_end_time`].includes(this.rs.open_period_type)
    }

    // 文中にあるURLを分割して抽出します。
    get autoLink(): string[] {
        if (Util.isBlank(this.room.appeal_content)) return []
        if (Util.isBlank(this.room.appeal_content.content)) return []

        let retTextArr = TemplateUtil.processText(this.room.appeal_content.content)
        Logger(`${funcName()} retTextArr:${Util.output(retTextArr)}`)

        return retTextArr
    }

    clickInnerMessage(text: string) {
        gtagClick(`AppealContent リンク`, `${text}`)
    }

    clickMailto(text: string) {
        gtagClick(`AppealContent Mailto`, `${text}`)
    }

    clickButtonLink(text: string) {
        gtagClick(`AppealContent ボタンリンク`, `${text}`)
        let link = text.match(TemplateUtil.buttonLinkRegex)[0].replace(`(`, ``).split(`)`)[0]
        let optionsText = text.match(TemplateUtil.buttonOptionsRegex) ? text.match(TemplateUtil.buttonOptionsRegex)[0] : null
        let additionalParams = location.search
        Logger(`${funcName()} link:${link}, optionsText:${optionsText}, location.search:${additionalParams}`)
        let optionsDic = {}
        if (Util.isPresent(optionsText)) {
            optionsText = optionsText.replace(`{`, ``).replace(`}`, ``)
            let optionsArr = optionsText.split(`,`)
            for (let op of optionsArr) {
                if (op.match(`=`)) {
                    let opKeyValue = op.split(`=`)
                    optionsDic[opKeyValue[0]] = opKeyValue[1]
                }
            }
            Logger(`${funcName()} optionsDic:${Util.output(optionsDic)}`)
            if (Object.keys(optionsDic).includes(`:target`)) {
                let target = optionsDic[`:target`]
                if (target.includes(`_blank`)) {
                    if (Util.isPresent(additionalParams)) {
                        if (link.includes(`?`)) {
                            additionalParams = additionalParams.replace(`?`, `&`)
                        }
                        link += additionalParams
                    }
                    open(link, `_blank`)
                }
            }
        } else {
            if (Util.isPresent(additionalParams)) {
                if (link.includes(`?`)) {
                    additionalParams = additionalParams.replace(`?`, `&`)
                }
                link += additionalParams
            }

            location.href = link
        }
    }

    downloadFile(file: UserFile) {
        gtagClick(`ファイルDL ${this.room.public_id}`, `${file.original_filename}`)
        this.rm.startProgress()
        FileUtil.downloadAttachment(file, this.rm.currentRoom.id).then(_ => {
            this.rm.endProgress()
            this.finishDL = true
        })
    }

    get openPeriod() {
        if (Util.isBlank(this.room)) return null
        if (Util.isBlank(this.rs)) return null
        let type = this.rs.open_period_type
        if (type == `none`) return null

        let st = null
        let et = null

        if (type == `start_time_only`) {
            st = DateTime.fromSeconds(this.rs.open_period_start_at)

            // text = `${st.toFormat(`yyyy年MM月dd日 HH:mm`)} 〜`
        } else if (type == `end_time_only`) {
            et = DateTime.fromSeconds(this.rs.open_period_end_at)
            // text = `〜 ${et.toFormat(`yyyy年MM月dd日 HH:mm`)}`
        } else if (type == `start_and_end_time`) {
            st = DateTime.fromSeconds(this.rs.open_period_start_at)
            et = DateTime.fromSeconds(this.rs.open_period_end_at)
            // text = `${st.toFormat(`yyyy年MM月dd日 HH:mm`)} 〜 ${et.toFormat(`yyyy年MM月dd日 HH:mm`)}`
        }
        return this.getLocalDateStr(st, et)
    }

    /**
     * @param st [DateTime]
     * @param et [DateTime]
     */
    getLocalDateStr(st, et) {
        let weekArray = [
            this.$t(`calendar.sun`),
            this.$t(`calendar.mon`),
            this.$t(`calendar.tue`),
            this.$t(`calendar.wed`),
            this.$t(`calendar.thu`),
            this.$t(`calendar.fri`),
            this.$t(`calendar.sat`),
            this.$t(`calendar.sun`),
        ]
        let lang = getLang()
        let stfmt = ``
        if (st) {
            stfmt = this.$t(`calendar.format_date`, { val1: weekArray[st.weekday] }) as string
        }
        let etfmt = ``
        if (et) {
            etfmt = this.$t(`calendar.format_date`, { val1: weekArray[et.weekday] }) as string
        }

        let dateStr = ``
        if (lang.includes(`ja`)) {
            let startTimeStr = st ? st.toFormat(`${stfmt}HH:mm`) : null
            let endTimeStr = et ? et.toFormat(`${etfmt}HH:mm`) : null

            if (st && et) {
                dateStr = `${startTimeStr} 〜 ${endTimeStr}`
            } else if (st) {
                dateStr = `${startTimeStr} 〜`
            } else if (et) {
                dateStr = `〜 ${endTimeStr}`
            }

            return dateStr
        } else {
            let startTimeStr = st ? st.toFormat(`HH:mm, ${stfmt}`) : null
            let endTimeStr = et ? et.toFormat(`HH:mm, ${etfmt}`) : null

            if (st && et) {
                dateStr = `${startTimeStr} - ${endTimeStr}`
            } else if (st) {
                dateStr = `${startTimeStr} -`
            } else if (et) {
                dateStr = `- ${endTimeStr}`
            }
            // let dateStr = st.toFormat(`HH:mm, ${stfmt} - ${endTimeStr}, ${etfmt}`)
        }
        Logger(`${funcName()} dateStr:${dateStr}`)
        return dateStr
    }
}
