import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger } from "packs/common"
import RoomMember from "packs/models/RoomMember"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "./AvailableScheduleTag"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarTag from "./CalendarTag"
import ContactListGroup from "packs/models/ContactListGroup"

export default class AddressBook {
    constructor(
        public id: string,

        // アドレス帳
        public contact_lists: RoomMember[],

        // waaqで招待しているUGメンバー
        public ug_members: RoomMember[],

        //
        public voted_members: RoomMember[],

        public contact_list_groups: ContactListGroup[],

        // コンタクトリストのページ
        public total: number,
        public page: number,
        public has_next: boolean,
        public next_page: number = 1
    ) {}

    static createDefault() {
        const time = DateTime.local()
        return new AddressBook(null, null, null, null, null, null, null, null)
    }

    /**
     * 調整ページにスターします。
     * @param parentId
     * @param action
     * @param room_type
     */
    static getContactList(adb: AddressBook): Promise<any> {
        let params = { page: adb?.next_page }
        return axios
            .get(`${Util.prefixUrl}/address_books`, { params: params })
            .then(res => {
                const usersDic = res.data.contact_list_dic
                const clg = res.data.contact_list_groups as ContactListGroup[]
                const ugMembers = res.data.users
                let mems = []
                for (const user of usersDic.contact_lists) {
                    let member: RoomMember = user
                    if (member.email == "service@demo.waaq.jp") {
                        continue
                    }
                    mems.push(member)
                }
                if (!adb) {
                    adb = AddressBook.createDefault()
                }

                let cls = adb.contact_lists || []
                adb.contact_lists = [...cls, ...mems]
                adb.contact_list_groups = clg
                adb.ug_members = ugMembers
                adb.total = usersDic.total
                adb.page = usersDic.page
                adb.has_next = usersDic.has_next
                adb.next_page = usersDic.next_page

                return adb

                // return {
                //     contact_lists: mems,
                //     contact_list_groups: clg,
                //     ug_members: ugMembers,
                //     total: usersDic.total,
                //     page: usersDic.page,
                //     has_next: usersDic.has_next,
                // }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static search(text: string): Promise<RoomMember[]> {
        let params = { text: text }

        return axios
            .post(`${Util.prefixUrl}/address_books/search`, params)
            .then(res => {
                return res.data.contact_lists as RoomMember[]
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
