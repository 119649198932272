
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({
    components: {},
})
export default class UploadImageButton extends Vue {
    // data
    @Prop()
    returnVal: string

    clickButton() {
        this.$emit(`clickButton`, this.returnVal)
    }
}
