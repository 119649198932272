
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"

// モデルを読込.
import EmailDomain from "packs/models/EmailDomain"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        TextareaTag,
    },
})
export default class EmailDomainContent extends Vue {
    @Prop()
    domain: EmailDomain

    // data:
    buttonName = null
    buttonActive = `inactive` // active,inactive,
    submitStatusText = ``

    // 割り当て可能な Array<ug.name>
    teamNames = []

    // ドメインを割り当て済みのug
    assignedUgs = null
    rm = RoomManager
    currentUg = null

    loading = false
    Util = Util
    showTip = false

    mounted() {
        this.textFromSubmitStatus()
        this.updateUserGroups()
    }

    @Watch(`domain`, { deep: true })
    updateDomain() {
        this.updateUserGroups()
    }

    @Watch(`rm.user_groups`, { deep: true })
    updateUserGroups() {
        if (!this.rm.user_groups) return
        if (!this.domain) return

        let ugs = [...(this.rm.user_groups || [])]
        let teamNames = []
        let assignedUgs = [...(this.assignedUgs || [])]
        for (let _ug of ugs) {
            let doms = _ug.assigned_email_domains || []
            let dom = doms.find(_dom => _dom.id == this.domain.id)
            if (dom) {
                // let assigned = [...(this.assignedUgs || [])]
                assignedUgs.push(_ug)
            } else {
                teamNames.push(_ug.name)
            }
        }
        this.assignedUgs = assignedUgs
        this.teamNames = teamNames
    }

    removeDomain() {
        gtagClick(`ドメイン削除`, `${this.domain.name}`)
        this.$emit(`removeDomain`, this.domain)
    }

    send() {
        if (!this.domain) return
        if (this.buttonActive != `active`) return
        gtagClick(`ドメイン進捗 送信`, `${this.domain.submit_status}`)
        let dom = { ...this.domain }
        let submitStatus = this.domain.submit_status
        if (submitStatus == 9) {
            dom.submit_status = 10
            Logger(`9 -> 10`)
        } else if (submitStatus == 20) {
            dom.submit_status = 30
            Logger(`20 -> 30`)
        } else if (submitStatus == 29) {
            dom.submit_status = 30
            Logger(`29 -> 30`)
        }
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        EmailDomain.changeSubmitStatus(dom).then(newDom => {
            this.rm.endProgress()
            this.loading = false
            if (newDom) {
                this.$emit("updateDom", newDom)
                this.textFromSubmitStatus()
            }
        })
    }

    selectTeamName(name: string) {
        this.currentUg = this.rm.user_groups.find(ug => ug.name == name)
    }

    assignDomainToTeam() {
        if (!this.currentUg) return

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        EmailDomain.assignDomainToTeam(this.currentUg, this.domain).then(_dom => {
            this.loading = false
            this.rm.endProgress()
            if (_dom) {
                // 割当に成功.
                let _ug = this.rm.user_groups.find(ug => ug.id == this.currentUg.id)
                _ug.assigned_email_domains.push(_dom)
                this.currentUg = null
            }
        })
    }

    textFromSubmitStatus() {
        if (!this.domain) return

        let submitStatus = this.domain.submit_status
        Logger(`textFromSubmitStatus: ${Util.output(this.domain)}`)
        if (submitStatus == 10) {
            this.buttonName = `申請中`
            this.buttonActive = `inactive`
            this.submitStatusText = `申請中です。サポートが対応するまでしばらくお待ち下さい。`
        } else if (submitStatus == 9) {
            this.buttonName = `送信`
            this.buttonActive = `active`
            this.submitStatusText = `不備があるため、差し戻されました。メッセージを確認し、送信し直してください。`
        } else if (submitStatus == 20) {
            this.buttonName = `送信`
            this.buttonActive = `active`
            this.submitStatusText = `メール送信を可能にするために、SPFとDKIMを有効にするTXTレコードに以下を追加してください。\n以下を追加が終わったら、送信ボタンを押してください。`
        } else if (submitStatus == 30) {
            this.buttonName = `登録確認中`
            this.buttonActive = `inactive`
            this.submitStatusText = `TXTレコードが登録されているか確認しています。サポートが対応するまでしばらくお待ちください。`
        } else if (submitStatus == 29) {
            this.buttonName = `送信`
            this.buttonActive = `active`
            this.submitStatusText = `作成を試みましたが、設定内容に不備がありました。再度記載のレコードが設定されているか確認の上、ご送信ください。`
        }
    }

    clickTextarea(index, value) {
        $(`textarea.recordValue${index}`).select()
        Logger(`clickTextarea`)
    }

    removeAssign(ug) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        EmailDomain.removeAssignDomain(ug, this.domain).then(_dom => {
            this.loading = false
            this.rm.endProgress()
            if (_dom) {
                this.currentUg = ug
                let ugs = [...(this.assignedUgs || [])]
                ugs = ugs.filter(_ug => _ug.id != ug.id)
                this.assignedUgs = ugs
                let names = this.teamNames || []
                names.push(ug.name)
            }
        })
    }

    clickCopyIcon(rec) {
        copyToClipboard(rec.registration_value)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`ドメインレコード コピー`, `uid:${rec.id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
    }
}
