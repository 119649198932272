
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import { VueFinalModal } from "vue-final-modal"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import AstagLists from "packs/pages/link/parts/available_schedule/AstagLists.vue"
import AstagSearchBox from "packs/pages/link/parts/available_schedule/AstagSearchBox.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"

@Options({
    components: {
        CalendarIcon,
        AstagLists,
        VueFinalModal,
        AstagSearchBox,
    },
})
export default class ModalSelectAstags extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    @Prop()
    canDelete: boolean

    @Prop()
    showAddNewButton: boolean

    @Prop()
    fromAstag: AvailableScheduleTag

    // data
    isSP = isMobile(window.navigator).phone
    appos = null
    rm = RoomManager
    astags: AvailableScheduleTag[] = null
    showNew: boolean

    loading = false

    public dismissModal() {
        this.$vfm.close("SelectAstagsModal")
    }

    openModal() {
        if (this.rm.astagsTab?.tab_name == `shared`) {
            this.rm.astagsTab.tab_name = `default`
        }
        this.checkShowNewButton()
        this.updateAstags()
    }

    checkShowNewButton() {
        if (this.showAddNewButton) {
            this.showNew = true
        } else {
            this.showNew = false
        }
    }

    @Watch("fromAstag", { deep: true })
    updateAstags() {
        this.astags = this.getAllAstags()
    }

    getAllAstags() {
        if (!this.rm.astags) return []
        // let astags = this.rm.astags.filter(a => [1, 2, 50].includes(a.type))
        // 共有カレンダーは表示しません
        // astags = this.rm.astags.filter(ast => !ast.is_shared)
        return this.rm.astags
    }

    public changeDefault(astag) {
        AvailableScheduleTag.changeDefault(astag).then(e => {
            if (e) {
                this.rm.changeDefaultAstag(astag)
                this.astags = this.getAllAstags()
            }
        })
    }

    public selectEnd(astag: AvailableScheduleTag) {
        Logger(`選択しました。`)
        this.$vfm.close("SelectAstagsModal")
        this.$emit("selectEnd", this.rm.astag)
    }

    changeCategoryTab(tabname: string) {
        this.rm.astagsTab.tab_name = tabname
    }
}
