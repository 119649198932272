
// モジュールの読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import { ContentLoader } from "vue-content-loader"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

@Options({
    components: { ContentLoader, MdProgressSpinner },
})
export default class ScheduleViewLoader extends Vue {
    // data:
    @Prop()
    loadingMessage: string
}
