
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import LocationTag from "packs/models/LocationTag"

const roomStorage = new RoomStorage()

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class ChangeLocationModal extends Vue {
    @Prop()
    appo: Appointment

    @Prop()
    willSelectLoctag: LocationTag // asview 調整カレンダー画面, schedule スケジュールビュー

    @Prop()
    isPreview: boolean

    // data
    rm = RoomManager
    loading = false
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validLocation = true
    width = 80

    locationName = ``
    saving = false
    notice = Notice
    Util = Util

    public created() {}

    public updated() {}

    public closeModal() {
        this.$vfm.close(`ChangeLocationModal`)
    }

    public fadeOutModal() {
        // gtagClick(`場所変更せず閉じる`, `${this.willSelectLoctag.name}`)
        // Logger(`フリーフォームを閉じます`)
        // this.$vfm.close(`ChangeLocationModal`)
        // this.$emit(`updatedLocation`, false)
    }

    public fixLocation() {
        this.$emit(`select`, this.willSelectLoctag)
    }

    public locationHref() {
        gtagClick(`selectableアポ遷移 > Gmap`, `${this.willSelectLoctag.keyword}`)

        let address = LocationTag.getAddressFromCurrentAppo(this.appo, true)
        if (this.willSelectLoctag.lat && this.willSelectLoctag.lng) {
            address = `${this.willSelectLoctag.lat},${this.willSelectLoctag.lng}`
        }

        return Util.gmapLinkUrl(address)
    }
}
