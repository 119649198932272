
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import FixTypeButtons from "packs/pages/link/parts/room_settings/FixTypeButtons.vue"
import RoomDetailSettings from "packs/pages/link/parts/room_settings/RoomDetailSettings.vue"
import RoomSettingsUseChatContent from "packs/pages/link/parts/room_settings/RoomSettingsUseChatContent.vue"
import RoomSettingsAvailableTime from "packs/pages/link/parts/room_settings/RoomSettingsAvailableTime.vue"
import SettingsTabContent from "packs/pages/link/parts/room_settings/SettingsTabContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"

@Options({
    components: {
        FixTypeButtons,
        RoomDetailSettings,
        RoomSettingsUseChatContent,
        RoomSettingsAvailableTime,
        SettingsTabContent,
    },
})
export default class ParentSettings extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    // @Prop()
    // room: Room

    // @Prop()
    // appo: Appointment

    @Prop() // func
    closePanel

    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    UserPermissionsOption = UserPermissionsOption

    Util = Util
    selectedTabName = `public` // public / schedule

    mounted() {
        this.closePanel()
    }

    selectTab(tabname: string) {
        this.selectedTabName = tabname
        if (tabname != `schedule`) {
            this.rcm.currentTab = null
        }
    }

    fixBy(fixType: any) {
        this.$emit(`fixBy`, fixType)
    }

    inputIPWhitelist(e) {
        if (Util.isBlank(e)) {
            this.$emit(`updateIPAddresses`, null, `white`)
            return
        }
        let list = (e || ``).split(`,`).map(l => l.trim())
        this.$emit(`updateIPAddresses`, list, `white`)
    }

    inputIPBlacklist(e) {
        if (Util.isBlank(e)) {
            this.$emit(`updateIPAddresses`, null, `black`)
            return
        }
        let list = (e || ``).split(`,`).map(l => l.trim())
        this.$emit(`updateIPAddresses`, list, `black`)
    }

    get showChatContent() {
        if (Util.isBlank(this.rm.userInfo)) return
        return UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_use_chat`)
    }

    editProviderEvent() {
        this.editEditableContent(`provider_event`)
    }
}
