
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, onlyUnique } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"
import { Dropdown } from "uiv"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import DateTag from "packs/models/DateTag"
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"
import Event from "packs/models/Event"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"

const roomStorage = new RoomStorage()

@Options({
    components: { SelectTag, Dropdown },
})
export default class WeeklyDetailHeader extends Vue {
    @Prop()
    dotw

    @Prop()
    date: DateTime

    @Prop()
    today

    // data

    rm = RoomManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone
    Const = Const
    Util = Util
    SelectUtil = SelectUtil

    pdm = PossibleDatesManager
    possibleEvents = null
    selectableMonthArray = null
    alldayOptionalEvent: Event = null

    /**
     * フロートしているリロードボタンを表示するか.
     * カレンダー連携がされていて、「外部カレンダーの変更」「調整可能時間の変更」「オプションの変更」があった場合に表示します。
     */
    // showReloadButton = false
    // showListLengthAfterReload = false

    // astag: AvailableScheduleTag = null

    public mounted() {
        // ページ送り可能な月を半年間設定可能にします.
        this.selectableMonthArray = this.getSelectableMonths()

        this.updatePossibleDates()

        this.updateSchedules()
    }

    updateSchedules() {
        if (Util.isBlank(this.cm.astag)) return
        const datestr = this.date.toFormat(`MMdd`)
        // 描画に負荷がかかるので、アップデートする必要があるかを確認します.
        // if (!this.cm.updatedEventDays.includes(datestr)) return

        let optionalEvents = this.cm.schedules[datestr] || []
        let optionalEvent = optionalEvents.find(opev => opev.allday)
        Logger(`${funcName()} optionalEvent:${optionalEvent}`)
        if (!optionalEvent) return

        this.alldayOptionalEvent = optionalEvent
    }

    @Watch(`cm.dailyPossibleDates`, { deep: true })
    updatePossibleDates() {
        if (Util.isBlank(this.cm.dailyPossibleDates)) {
            this.possibleEvents = null
            return
        }
        const datestr = this.date.toFormat(`MMdd`)
        let evsDic = this.cm.dailyPossibleDates[datestr] || []
        if (Util.isPresent(evsDic)) {
            const teamName = this.cm.displayMagName || `A`
            let _dic = null
            let userId = this.cm.getOwnerUserId()
            _dic = evsDic[userId].group_events_dic[teamName]

            if (_dic) {
                this.possibleEvents = _dic.possible_events
            }
        }
        // if (this.pdm && this.pdm.available_months) {
        //     Logger(`available_months: ${Util.output(this.pdm.available_months)}`)
        //     const date = DateTime.local()
        // }
    }

    getSelectableMonths(): string[] {
        let months = Array(6)
            .fill(0)
            .map((_, i) => {
                return `${this.today.plus({ month: i }).month}月`
            })
        return months
    }

    // その月まで移動します.
    selectMonth(monthStr: string) {
        let index = this.selectableMonthArray.indexOf(monthStr)
        Logger(`${funcName()} month: ${monthStr}, index: ${index}`)
        this.$emit(`changeMonth`, index)
    }

    @Watch(`cm.displayMagName`, { deep: true })
    updateDisplayMagName() {
        this.updatePossibleDates()
    }

    changeNotAvailableEventAllday() {
        let time = this.date.startOf(`day`)
        Logger(`${funcName()} date:${time.toISO()}`)
        const datestr = this.date.toFormat(`MMdd`)
        let optionalEvents = this.cm.schedules[datestr] || []

        let alldayEv = Event.createOptional(`optional_remove`, time, 60 * 24, false)
        alldayEv.allday = true
        optionalEvents.push(alldayEv)
        this.cm.updateSchedules(optionalEvents, `all`, this.date)
        this.alldayOptionalEvent = alldayEv
    }

    changeAvailableEventAllday() {
        let time = this.date.endOf(`day`)
        Logger(`${funcName()} date:${time.toISO()}`)
        const datestr = this.date.toFormat(`MMdd`)
        let optionalEvents = this.cm.schedules[datestr] || []
        let optionalRemovedEvents = optionalEvents.filter(opev => !opev.allday)

        this.cm.updateSchedules(optionalRemovedEvents, `all`, this.date)
        this.alldayOptionalEvent = null
    }
}
