import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormContent = _resolveComponent("FormContent")!

  return (_ctx.aform)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.answeredForm && _ctx.Util.isPresent(_ctx.endWithMessage))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`ScheduleCalendarContent with_white_bg ofs ${_ctx.rm.designType() == `normal` ? `` : `floatRightContent ofs`} ${
                _ctx.isSP ? `spContent` : ``
            }`)
            }, _toDisplayString(_ctx.endWithMessage), 3))
          : (_openBlock(), _createElementBlock("section", {
              key: 1,
              class: _normalizeClass(`ScheduleCalendarContent with_white_bg ofs ${_ctx.rm.designType() == `normal` ? `` : `floatRightContent ofs`} ${
                _ctx.isSP ? `spContent` : ``
            }`)
            }, [
              _createVNode(_component_FormContent, {
                room: null,
                aform: _ctx.aform,
                currentStep: `form`,
                additionalParams: _ctx.additionalParams,
                onCompleteForm: _ctx.completeForm,
                onSkipForm: _ctx.skipForm,
                onAutoSendForm: _ctx.autoSendForm,
                loadingFormSending: _ctx.loadingFormSending
              }, null, 8, ["aform", "additionalParams", "onCompleteForm", "onSkipForm", "onAutoSendForm", "loadingFormSending"])
            ], 2))
      ]))
    : _createCommentVNode("", true)
}