
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import { gtagClick } from "packs/GoogleTagManager"
import FormUtil from "packs/utils/FormUtil"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import FormField from "packs/models/FormField"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import CalendarTag from "packs/models/CalendarTag"

@Options({
    components: {
        UserIcon,
        TextfieldTag,
    },
})
export default class AddAttendeesForm extends Vue {
    @Prop()
    room: Room

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    cm = CalendarManager

    Util = Util

    fields: FormField[] = null
    loggedinFields: FormField[] = null
    systemUpdatedAt = Util.getSec()
    hasInvalidFields: boolean = false
    // additionalParams = null
    setEnd = false
    openForm = false

    newName = ``
    newEmail = ``
    newSearchKey = null

    // updatedFields: FormField[] = null

    mounted() {
        this.updateRoom()

        this.updateRegisteredAttendees()
    }

    @Watch("cm.registered_attendees", { deep: true })
    updateRegisteredAttendees() {
        if (Util.isPresent(this.cm.registered_attendees)) {
            let atts = [...this.cm.registered_attendees]
            this.cm.registrable_attendees = atts
        }
    }

    @Watch(`newName`)
    updateNewName() {
        Logger(`${funcName()} newName:${this.newName}`)
        let ct = this.cm.ctList.find(ct => ct.name == this.newName)
        if (ct) {
            this.newEmail = ct.email
            this.newName = ct.name
            this.newSearchKey = ct.search_key
        }
    }

    @Watch(`newEmail`)
    updateNewEmail() {
        Logger(`${funcName()} newEmail:${this.newEmail}, cm.ctList:${Util.output(this.cm.ctList)}`)
        let ct = this.cm.ctList.find(ct => ct.name == this.newEmail)
        Logger(`${funcName()} ct:${Util.output(ct)}`)
        if (ct) {
            this.newEmail = ct.email
            this.newName = ct.name
            this.newSearchKey = ct.search_key
        }
    }

    clickCtagOption(ctag) {
        Logger(`clickCtagOption: ${Util.output(ctag)}`)
    }

    @Watch("room", { deep: true })
    updateRoom() {
        if (!this.room) return
        if (this.setEnd) return
        let _fields = this.room.form_fields ? [...this.room.form_fields] : []

        this.setEnd = true
        Logger(`${funcName()} フィールドのバリューにはめました: ${Util.output(this.fields)}`)
        // this.updatedFields = this.room.form_fields ? [...this.room.form_fields] : []
    }

    inputName(e) {
        this.newName = e
    }

    inputEmail(e) {
        this.newEmail = e
    }

    blurName() {}
    blurEmail() {}

    selectCtag(ctag: CalendarTag) {
        Logger(`AddAttendeesForm#selectCtag: ${Util.output(ctag)}`)
        let att = (this.cm.registrable_attendees || []).find(att => att.email == ctag.email)
        if (att) {
            Notice.message = `すでに同席者に追加しています。`
        } else {
            this.cm.registrable_attendees.push(ctag)
            this.newEmail = ``
            this.newName = ``
        }
    }

    addAttendee() {
        if (Util.isBlank(this.newName) || Util.isBlank(this.newEmail)) return
        gtagClick(`出席者を追加`, `${(this.cm.registrable_attendees || []).length} + 1`)
        let att = (this.cm.registrable_attendees || []).find(att => att.email == this.newEmail)
        if (!att) {
            let att: any = { name: this.newName, email: this.newEmail, color: Util.getRandomColor() }
            if (this.cm.ctList) {
                let ct = this.cm.ctList.find(ct => ct.email == this.newEmail)
                if (ct) {
                    att = ct
                }
            }
            this.cm.registrable_attendees.push(att)
            this.newEmail = ``
            this.newName = ``
        } else {
            Notice.message = `すでにメールアドレスは追加してあります。`
        }
    }

    deleteAttendee(att) {
        gtagClick(`出席者を削除`, `${(this.cm.registrable_attendees || []).length} - 1`)
        this.cm.registrable_attendees = this.cm.registrable_attendees.filter(_att => _att.email != att.email)
    }

    toggleForm(open: boolean) {
        this.openForm = open
    }
}
