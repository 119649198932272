
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import IconLock from "packs/components/icons/IconLock.vue"

// モデルを読込.
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomManager from "packs/models/RoomManager"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextareaTag,
        IconLock,
    },
})
export default class TemplateForAiEditContent extends Vue {
    // data:

    optionEventName = `フォームファイルアップロード`

    Util = Util
    rm = RoomManager
    rcm = RoomCreateManager
    typeArray = null

    mounted() {
        let ffs = this.rcm.room.form_fields
        this.typeArray = TemplateUtil.filledFieldsExamples(ffs)
    }

    public closePanel() {
        this.$emit(`closePanel`)
    }

    clickField(nameExample: any) {
        Logger(`${funcName()} select ${nameExample}`)

        // let _f = FormFieldCreator.getFieldFromFieldType(fieldDic)
        let rule = this.rcm.edittingRule
        let manual = rule.manual || ``
        rule.manual = `${manual}${nameExample}`

        this.rcm.updateEdittingRule(rule)
        this.closePanel()
    }
}
