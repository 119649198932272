
import { Options, Prop, Vue } from "vue-property-decorator"
import { Logger } from "packs/common"

@Options({
    components: {},
})
export default class Switcher extends Vue {
    @Prop()
    value: string

    @Prop()
    name: string

    @Prop()
    checked: boolean

    @Prop()
    display: string

    @Prop()
    disabled: boolean

    public get willDisplay() {
        if (this.display) {
            return true
        } else {
            return false
        }
    }

    public get getName() {
        return `ignoreAllday[${this.value}]`
    }

    public get defaultChecked(): boolean {
        Logger(`${this.name}===${this.checked}`)
        if (this.checked) {
            return true
        } else {
            return false
        }
    }

    public updateValue() {}

    clickSwitcher(e) {
        if (this.disabled) {
            this.$emit("select", e)
            return
        }
    }

    // ターゲットのクラスやバリューを取得するために、e自体を親に伝えます。
    public selectCheckbox(e) {
        $(e.target).toggleClass("checked")
        this.$emit("select", e)
    }
}
