
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.

// import "fullcalendar";
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import Const from "packs/utils/Const"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {},
})
export default class BlackBoxEventMenu extends Vue {
    // data:
    @Prop()
    ev: Event

    @Prop() // top: ScheduleViewから、 asview: AstagViewのWeeklyScheduleから
    fromType: string

    @Ref()
    EventDetailLi

    DateTime = DateTime
    cm = CalendarManager
    Util = Util
    truncate = truncate
    rcm = RoomCreateManager

    mounted() {
        // DailyLineをクリック時にイベントを作成するイベント処理を追加します。
        // 他のDailyLineと区別するためにidを指定します。
    }

    /**
     * イベントを追加/可能日時に変更/不可日時に変更
     * @param type [string] cal,available,notAvailable
     */
    addEvent(type: string) {
        Logger(`addEvent ${type}`)
        this.$emit(`addEvent`, type)
    }

    get calendarName() {
        if (!this.ev) return
        if (Util.isBlank(this.cm.calendar_name_dic)) return
        return this.cm.calendar_name_dic[this.ev.calendar_id]
    }

    getDetailLi() {
        return this.EventDetailLi
    }

    changeAvailableEvent() {
        this.$emit(`changeAvailableEvent`, this.ev)
    }

    changeIgnoreEvent() {
        this.$emit(`changeIgnoreEvent`, this.ev)
    }

    showAvailable() {
        this.$emit(`changeAvailableEvent`, this.ev)
    }

    showIgnore() {
        this.$emit(`changeIgnoreEvent`, this.ev)
    }

    showDetail() {
        this.cm.selectedEvent = this.ev
        this.$emit(`showEventDetail`)
    }

    changeMaxBookingsNum() {
        this.$emit(`changeMaxBookingsNum`, this.ev)
    }

    get isSelfMode() {
        if (this.cm.createType == `vote`) return true
        if (this.cm.astag?.is_only_use_optional_schedules) return true
        return false
    }
}
