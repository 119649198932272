
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName, onlyUnique, sleep } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LocationIcon from "packs/components/icons/LocationIcon.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"
import LocationConvertersList from "packs/pages/link/parts/room_settings/left_panel_parts/LocationConvertersList.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import LocationConverter from "packs/models/LocationConverter"
import LocationTagSettingModal from "packs/pages/link/modals/LocationTagSettingModal.vue"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        CheckboxTag,
        MdTextfieldTag,
        SelectTag,
        LocationIcon,
        ScheduleViewLoader,
        MdProgressSpinner,
        LocationConvertersList,
        LocationTagSettingModal,
    },
})
export default class UserSettingsLocations extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    util = Util
    selectedTab = `locations`

    isSP = isMobile(window.navigator).phone
    loctags: LocationTag[] = null
    loctag: LocationTag = null // 新規/編集をするloctag.
    failedKeyword = false

    loading = false
    invitedUsers: RoomMember[] = null
    userInfo = null
    myUser: RoomMember = null //コンタクトリストにある情報を使うため.
    defaultLoctag: LocationTag = null

    failedConvBeforeKeyword = false
    failedConvAfterKeyword = false
    edittingLoctag = null

    Util = Util

    @Ref()
    header

    created() {
        this.resetLoctag()
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                let userInfo = this.rm.userInfo
                this.userInfo = userInfo
                this.defaultLoctag = this.rm.default_location
                this.getLocations()
            })
        } else {
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            this.defaultLoctag = this.rm.default_location
            this.getLocations()
        }
    }

    mounted() {
        gtagPage("#/settings/locations")
    }

    resetLoctag() {
        this.loctag = LocationTag.createDefault()
        this.edittingLoctag = null
    }

    getDefaultOption() {
        return this.loctag.location_type_name
    }

    inputLocationName(e) {
        this.loctag.name = e
    }

    inputLocationKeyword(e) {
        this.loctag.keyword = e
    }

    checkLocationKeyword() {}

    selectChannel(e) {
        this.loctag.location_type_name = e
        if (e == `オフライン`) {
            this.loctag.location_type = `offline`
        } else {
            this.loctag.location_type = `online`
        }
    }

    public getLocations() {
        this.rm.getLocations().then(res => {
            if (res) {
                this.loctags = res.filter(loc => !loc.is_shared)
            }
        })
    }

    public saveLoctag(loctag: LocationTag) {
        if (!loctag) loctag = this.loctag

        if (Util.isBlank(loctag.keyword)) {
            this.notice.message = `場所名が未入力です。`
            return
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        let sendType = `post`

        if (loctag.id) {
            sendType = `put`
        }

        if (sendType == `post`) {
            // 新規作成の場合のみ、keywordからlocation_typeを判定します.
            let _keyword = loctag.keyword
            if (Util.isOnline(_keyword)) {
                loctag.location_type = `online`
            } else {
                loctag.location_type = `offline`
            }
        }

        LocationTag.save(loctag, sendType).then(loc => {
            if (loc) {
                let _loctags = (this.loctags || []).filter(l => l.id != loc.id)
                _loctags.push(loc)
                this.loctags = _loctags
                if (this.loctags) {
                    let loctag = (this.rm.loctags || []).find(l => l.id == loc.id)
                    if (loctag) {
                        Object.assign(loctag, loc)
                    } else {
                        this.rm.loctags.push(loc)
                    }
                }

                this.resetLoctag()
                this.$vfm.close(`LocationTagSettingModal`)
            }
            this.loading = false
            this.rm.endProgress()
        })
    }

    public deleteLocation(loctag) {
        this.rm.startProgress()
        if (this.loading) return
        this.loading = true

        LocationTag.delete(loctag).then(loc => {
            if (loc) {
                let _loctags = (this.loctags || []).filter(l => l.id != loc.id)
                this.loctags = _loctags
                if (loctag.provider == `google_for_link`) {
                    this.rm.can_use_google_meet = false
                } else if (loctag.provider == `ms_for_link`) {
                    this.rm.can_use_teams = false
                } else if (loctag.provider == `zoom`) {
                    this.rm.can_use_zoom = false
                } else if (loctag.provider == `teams_for_link`) {
                    this.rm.can_use_teams = false
                }
            }
            this.loading = false
            this.rm.endProgress()
        })
    }

    public setDefault(loctag: LocationTag) {
        LocationTag.saveDefault(loctag).then(e => {
            this.defaultLoctag = loctag
            this.rm.default_location = loctag
        })
    }

    public clickZoom() {
        // window.open(`/users/auth/zoom`)
        window.location.href = `/sign_in/zoom`
    }

    public clickGoogleMeet() {
        if (!this.rm.did_connect_google_cal) {
            this.notice.message = `先にGooleカレンダーと連携してください。`
            return
        }
        this.connectCal(`google_meet`)
    }

    public clickTeams() {
        if (!this.rm.did_connect_ms_cal) {
            this.notice.message = `先にMicrosoftアカウントと連携してください。`
            return
        }
        this.connectCal(`teams`)
    }

    clickOnlyTeams() {
        window.open(`/sign_in/ms_teams`)
    }

    public connectCal(endpoint: string) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        axios.post(`${Util.prefixUrl}/user_settings/${endpoint}`).then(res => {
            this.rm.endProgress()
            this.loading = false
            this.notice.message = res.data.message
            let _loc = res.data.location
            if (_loc) {
                let loctag = LocationTag.fetchFromJson([_loc])[0]
                this.loctags.push(loctag)
                if (loctag.provider == `google_for_link`) {
                    this.rm.can_use_google_meet = true
                } else if ([`ms_for_link`, `teams_for_link`].includes(loctag.provider)) {
                    this.rm.can_use_teams = true
                }
            }
        })
    }

    public noshowConnectCal() {
        if (!this.rm.can_use_zoom) return false
        if (this.rm.did_connect_google_cal && !this.rm.can_use_google_meet) return false
        if (this.rm.did_connect_ms_cal && !this.rm.can_use_teams) return false
        if (!this.rm.did_connect_ms_cal && !this.rm.can_use_teams) return false
        return true
    }

    clickLoctag(loctag: LocationTag) {
        this.edittingLoctag = loctag
        sleep(200).then(_ => {
            this.$vfm.open(`LocationTagSettingModal`)
        })
    }
}
