import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "value", "checked", "id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "checkbox",
    name: _ctx.getTypeName,
    value: _ctx.value,
    checked: _ctx.defaultChecked,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectCheckbox && _ctx.selectCheckbox(...args))),
    id: `${_ctx.prefix}_${_ctx.value}`,
    disabled: _ctx.defaultDisabled
  }, null, 8, _hoisted_1))
}