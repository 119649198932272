
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique, copyToClipboard, funcName, sleep } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import ListLoader from "packs/components/loader/ListLoader.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import GroupTag from "packs/models/GroupTag"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: { TextareaTag, MdTextfieldTag, ChipButton, ListLoader, VueFinalModal, MdProgressBar },
})
export default class PrivateMemoModal extends Vue {
    // data:
    @Prop()
    room: Room

    loading = false

    isSP = isMobile(window.navigator).phone
    showTip = false
    rm = RoomManager
    truncate = truncate

    memo: string = null
    selectedTags: GroupTag[] = []
    searchText = null
    groupTags = null
    searchedTags = []

    showSuggests = false

    openModal() {
        this.updateRoom()

        if (!this.groupTags) {
            this.rm.getGroupTags().then(tags => {
                this.groupTags = tags || []
                if (this.searchText) {
                    this.inputSearchText(this.searchText)
                } else {
                    this.searchedTags = [...this.groupTags]
                }
            })
        }
    }

    @Watch(`room`, { deep: true })
    updateRoom() {
        if (!this.room) return

        Logger(`${funcName()} room:${this.room.id}`)
        this.memo = this.room.private_memo
        let selectedTags = this.room.group_tags
        if (Util.isPresent(selectedTags)) {
            this.selectedTags = selectedTags
        } else {
            this.selectedTags = []
        }
    }

    clickTextarea() {
        // $(`textarea.privateMemoArea`).select()
        Logger(`privateMemoArea`)
    }

    inputTextarea(e) {
        this.memo = e
    }

    inputSearchText(e) {
        this.searchText = e
        let grTags = this.groupTags.filter(gt => gt.name.includes(this.searchText))
        this.searchedTags = grTags
    }

    enterSearch() {
        if (!this.searchText) return
        let grTag = this.selectedTags.find(gt => gt.name == this.searchText)
        // すでに追加済みのタグです.
        if (grTag) return

        let grTags = this.groupTags.filter(gt => gt.name == this.searchText)
        if (Util.isPresent(grTags)) {
            let selectedTag = grTags[0]
            this.selectedTags.push(selectedTag)
        } else {
            let gt = GroupTag.createDefault()
            gt.name = this.searchText
            gt.color = Util.getRandomColor()
            Logger(`${funcName()} 新規タグ:${Util.output(gt)}`)
            this.selectedTags.push(gt)
            this.searchText = null
        }
    }

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("PrivateMemoModal")
    }

    send() {
        if (this.memo && this.memo.length > 100) {
            Notice.message = `入力可能な文字数は100文字までです。`
            return
        }
        let tags = []
        if (Util.isPresent(this.selectedTags)) {
            for (let tag of this.selectedTags) {
                if (tag && (tag.id || "").includes(`newId:`)) {
                    tag.id = null
                }
                tags.push(tag)
            }
        }
        Room.updateRoomGroupSetting(this.room, this.memo, tags).then(e => {
            if (e && e.success) {
                this.$emit(`updatedPrivateMemo`, e.memo, e.group_tags)
                this.$vfm.close("PrivateMemoModal")
            }
        })
    }

    clickSelectGrtag(grTag: GroupTag) {
        //
    }

    selectGroupTag(grTag: GroupTag) {
        Logger(`${funcName()} grTag:${grTag.name}`)
        let selected = this.selectedTags.find(grt => grt.id == grTag.id)
        if (selected) {
            //
        } else {
            this.selectedTags.push(grTag)
        }
    }

    deleteSelectGrtag(grTag: GroupTag) {
        //
        this.selectedTags = this.selectedTags.filter(gt => gt.id != grTag.id)
    }

    clickSearchField() {
        this.showSuggests = true
    }

    blurSearchField() {
        sleep(200).then(_ => {
            this.showSuggests = false
        })
    }
}
