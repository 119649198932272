
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.
import FormField from "packs/models/FormField"

@Options({
    components: {
        TextfieldTag,
    },
})
export default class SelectFieldLi extends Vue {
    @Prop()
    field: FormField

    @Prop()
    selvalue: string

    @Prop()
    selindex: number

    value: string = ``
    selectableTypes: string[] = FormField.selectableTypes
    selectableWithImageTypes: string[] = FormField.selectableWithImageTypes

    inputSelValue(e) {
        Logger(`選択肢: ${e}`)
        this.$emit(`inputSelValue`, e, this.selindex)
    }

    deleteSel() {
        this.$emit(`deleteSel`, this.selindex)
    }

    uploadSelImage() {
        this.$emit(`uploadSelImage`, this.selindex)
    }
}
