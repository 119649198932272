
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import Util from "../../../utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Card, createToken } from "vue-stripe-elements-plus"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"

// モデルを読込.
import Room from "packs/models/Room"
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import Plan from "packs/models/Plan"
import Invoice from "packs/models/Invoice"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        Card,
    },
})
export default class ModalInnerConfirmChangePlan extends Vue {
    @Prop()
    action: string //"toPay"無料->有料, "toFree"有料->無料, "change"有料->有料

    @Prop()
    willChangePlan: Plan

    @Prop()
    users

    @Prop()
    paymentInfo

    // @Prop()
    // complete;
    complete() {
        Logger(`${funcName()}`)
    }

    // data:
    sharedState = PropertyStore
    notice = Notice

    rm = RoomManager

    isSP = isMobile(window.navigator).phone

    stripeKey = location.href.includes(`https://waaq.jp`)
        ? `pk_live_0VBJw5tgdJnWOCm4coePCZgF`
        : `pk_test_s7tBm4XtHFIkk7dfghQPJpxL`
    stripeOptions = { hidePostalCode: true }

    totalAmount = -1

    @Emit("fixChangePlan")
    fixChangePlan() {}

    @Emit("dismissConfirmChangePlanModal")
    dismissConfirmChangePlanModal() {}

    created() {}

    updated() {}

    /**
     * 税別のトータルコスト.
     */
    public nextPeriodTotalCost(): string {
        if (!this.willChangePlan) return

        let totalAmount = ``
        const unitNum: number =
            this.willChangePlan.id_num == 1 ? this.users.length : Math.ceil(this.users.length / this.willChangePlan.id_num)
        if (this.willChangePlan.applied_coupon) {
            // クーポン決済のため、翌月の見積額から判断（トータルで数を計算しているので、こちらでは計算しない）
            // let amount =
            //     unitNum *
            //     this.willChangePlan.cost_estimate_next_period.total_without_tax;
            totalAmount = this.willChangePlan.cost_estimate_next_period.total_without_tax.toLocaleString()
        } else {
            // 通常の決済
            let amount = unitNum * this.willChangePlan.amount
            totalAmount = amount.toLocaleString()
        }

        return totalAmount
    }

    /**
     * どのプランからどのプランにするのか、プラン変更の判定をします。
     */
    // public confirmChangePlan(plan) {
    //     // 無料 -> 有料
    //     if (this.originPlan.amount == 0 && plan.amount > 0) {
    //         // this.changeUIPlan(plan);
    //         // return false;
    //         this.action = "toPay";
    //     }

    //     // 有料 -> 有料
    //     else if (
    //         this.originPlan.amount > 0 &&
    //         plan.amount > 0 &&
    //         this.originPlan.id != plan.id
    //     ) {
    //         this.action = "change";
    //     }

    //     // 有料 -> 無料
    //     else if (this.originPlan.amount > 0 && plan.amount == 0) {
    //         this.action = "toFree";
    //     }

    //     if (!this.action) return;

    //     this.willChangePlan = plan;
    //     this.$vfm.open("ConfirmChangePlanModal");
    // }

    // public dismissConfirmChangePlanModal() {
    //     this.willChangePlan = null;
    //     this.action = null;
    //     this.loading = false;
    //     this.$vfm.close("ConfirmChangePlanModal");
    // }

    // public fixChangePlan() {
    //     this.loading = true;
    //     // プラン変更 有料 -> 有料、無料 -> 有料
    //     if (["change", "toPay"].includes(this.action)) {
    //         // カードの登録が既にある場合、
    //         if (this.paymentInfo.registered) {
    //             Plan.changePlan(this.willChangePlan, null).then(success => {
    //                 if (success) {
    //                     Notice.message = `プランを変更しました。`;
    //                     // this.changeUIPlan(this.willChangePlan);
    //                     this.getSubscriptionData();
    //                 }
    //                 this.dismissConfirmChangePlanModal();
    //             });
    //         }
    //         // カード情報の登録およびプランを変更します。
    //         else {
    //             this.pay().then(success => {
    //                 if (success) {
    //                     this.dismissConfirmChangePlanModal();
    //                 }
    //                 this.loading = false;
    //             });
    //         }
    //     }
    //     // 有料 -> 無料
    //     else if (this.action == "toFree") {
    //         Plan.stopSubscription().then(success => {
    //             if (success) {
    //                 Notice.message = `プランを変更しました。`;
    //                 // this.changeUIPlan(this.willChangePlan);
    //                 this.dismissConfirmChangePlanModal();
    //                 this.getSubscriptionData();
    //             }
    //         });
    //     }
    // }
}
