
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger, funcName } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class AlertModal extends Vue {
    @Prop()
    title: string

    @Prop()
    text: string

    @Prop()
    okButtonName: string

    @Prop()
    cancelButtonName: string

    @Prop() // gtagPageで送る名前で利用
    eventName: string

    @Prop()
    hideCancelButton: boolean

    @Prop()
    hideDismissButton: boolean

    @Prop()
    imageUrl: string

    @Prop()
    imageLargeUrl: string

    @Prop()
    height: number

    @Prop()
    retValue: any

    // data
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    saving = false
    Util = Util

    retTitle = null
    retText = null
    retImageUrl = null
    retImageLargeUrl = null
    retOkButtonName = null
    retCancelButtonName = null
    retHeight = null

    public closeModal() {
        this.$emit(`closeModal`)
    }

    public openModal() {
        if (this.eventName) gtagPage(`${this.eventName}`)
        this.updateContent()
    }

    updateContent() {
        Logger(`${funcName()} title:${this.title} text:${this.text}`)
        this.retTitle = this.title
        this.retText = this.text
        this.retImageUrl = this.imageUrl
        this.retImageLargeUrl = this.imageLargeUrl
        this.retOkButtonName = this.okButtonName
        this.retCancelButtonName = this.cancelButtonName
        this.retHeight = this.height
    }

    public clickOK() {
        if (Util.isPresent(this.retValue)) {
            this.$emit(`clickOK`, this.retValue)
        } else {
            this.$emit(`clickOK`)
        }
    }

    public clickCancel() {
        if (Util.isPresent(this.retValue)) {
            this.$emit(`clickCancel`, this.retValue)
        } else {
            this.$emit(`clickCancel`)
        }
    }
}
