
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import ListLoader from "packs/components/loader/ListLoader.vue"
import OAuthButtons from "packs/pages/link/settings/OAuthButtons.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import UserSetting from "packs/models/UserSetting"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        CheckboxTag,
        SelectTag,
        MdTextfieldTag,
        UserIcon,
        ListLoader,
        OAuthButtons,
    },
})
export default class UserSettingsShareCalendars extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    Util = Util
    CalendarUtil = CalendarUtil
    SelectUtil = SelectUtil

    selectedTab = `schedule`

    isSP = isMobile(window.navigator).phone
    // users: RoomMember[] = [];

    loading = false
    userInfo = null
    us: UserSetting = null
    defaultStepSizeTitle = ``
    defaultDurationTitle = ``

    users = null
    shareUser = RoomMember.createDefaultClient()
    updatedAt = Util.getSec()
    failedMail = false
    sharedCtags = null // 自分に共有してくれているカレンダー

    selectedCategory = `list`

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.getAstag()
        } else {
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            this.us = this.rm.user_setting

            RoomMember.getSharedList().then(_dic => {
                if (Util.isPresent(_dic)) {
                    this.users = _dic.users
                    this.sharedCtags = _dic.shared_me_list
                }
            })
        }
    }

    getAstag() {
        this.rm.getAvailableSchedule().then(res => {
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            this.us = this.rm.user_setting

            RoomMember.getSharedList().then(_dic => {
                if (Util.isPresent(_dic)) {
                    this.users = _dic.users
                    this.sharedCtags = _dic.shared_me_list
                }
            })
        })
    }

    mounted() {
        gtagPage("#/settings/share_calendars")
    }

    selectCategory(category: string) {
        this.selectedCategory = category
    }

    inputEmail(e) {
        this.shareUser.email = e
    }

    public checkEmailField() {
        let validate = Util.validateEmail(this.shareUser.email)
        // Logger(`emailフィールドを検証します: ${this.member.email} ${validate}`);
        if (!validate) {
            this.failedMail = true
        } else {
            this.failedMail = false
        }
    }

    findShareTypeDicByType(shareType: string) {
        const default_dic = SelectUtil.calendarShareTypes.find(dic => dic.type == `detail`)
        if (Util.isBlank(shareType)) {
            return default_dic
        }
        let _dic = SelectUtil.calendarShareTypes.find(dic => dic.type == shareType)
        if (!_dic) {
            Logger(`findShareTypeDicByType unknown shareType: '${shareType}'`)
            _dic = default_dic
        }
        return _dic
    }

    findShareTypeDicByName(shareTypeName: string) {
        const default_dic = SelectUtil.calendarShareTypes.find(dic => dic.type == `detail`)
        if (Util.isBlank(shareTypeName)) {
            return default_dic
        }
        let _dic = SelectUtil.calendarShareTypes.find(dic => dic.name == shareTypeName)
        if (!_dic) {
            Logger(`findShareTypeDicByName unknown shareTypeName: '${shareTypeName}'`)
            _dic = default_dic
        }
        return _dic
    }

    selectNewShareType(e) {
        let _dic = SelectUtil.calendarShareTypes.find(dic => dic.name == e)
        this.shareUser.share_type = _dic.type
    }

    shareMyCalendar() {
        if (Util.isBlank(this.shareUser.email)) {
            Notice.message = `メールアドレスが未入力です。`
            return
        }
        if (Util.isBlank(this.shareUser.share_type)) {
            this.shareUser.share_type = `detail`
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        RoomMember.shareMyCalendar(`post`, this.shareUser.email, this.shareUser.share_type).then(_users => {
            this.loading = false
            this.rm.endProgress()
            this.updatedAt = Util.getSec()
            if (_users) {
                this.users = _users || []
                this.shareUser = RoomMember.createDefaultClient()
            }
        })
    }

    selectShareType(user, index) {
        Logger(`selectShareType user: ${user.name} ${user.email} ${index}`)
        let val = $(`#select_share_type${index} option:selected`).attr("value")
        Logger(`selectShareType val:'${val}'`)
        let _dic = this.findShareTypeDicByName(val)
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        RoomMember.shareMyCalendar(`post`, user.email, _dic.type).then(_users => {
            this.loading = false
            this.rm.endProgress()
            this.updatedAt = Util.getSec()
            if (_users) {
                this.users = _users || []
            }
        })
    }

    // 閲覧権限を削除します.
    deleteSharedUser(user: RoomMember) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        RoomMember.shareMyCalendar(`delete`, user.email, null).then(_users => {
            this.loading = false
            this.rm.endProgress()
            this.updatedAt = Util.getSec()
            if (_users) {
                this.users = _users || []
            }
        })
    }

    disconnectCalendar() {
        gtagClick(`ユーザー設定`, `カレンダー解除`)
        if (
            confirm(
                `連携を解除しますが、よろしいですか?作成済みの調整カレンダーに外部カレンダーを連携している場合、自動的に外れます。`
            )
        ) {
            UserSetting.disconnectCalendar().then(e => {
                if (e) {
                    // astag取得から
                    this.getAstag()
                }
            })
        }
        Logger(`連携を解除しますが、よろしいですか?`)
    }
}
