
// モジュールを読込.
import { Options, Prop, Vue, Ref, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import EditableContent from "packs/components/forms/EditableContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomCreator from "packs/utils/RoomCreator"
import Room from "packs/models/Room"
import MailFlow from "packs/models/MailFlow"

@Options({ components: { EditableContent, TextareaTag, TextfieldTag } })
export default class SendMailContent extends Vue {
    @Prop()
    mf: MailFlow

    cm = CalendarManager

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util

    pdm = PossibleDatesManager
    rcm = RoomCreateManager

    currentMailSubject = ``
    currentMailContent = ``

    public mounted() {
        this.updateMailFlow()
    }

    @Watch(`mf`, { deep: true })
    updateMailFlow() {
        if (!this.mf) return

        this.currentMailSubject = this.mf.subject
        this.currentMailContent = this.mf.content
    }

    inputSubject(e: string) {
        Logger(`inputSubject: ${e}`)
        this.currentMailSubject = e
        this.updateMailFlowToParent()
    }

    inputContent(e) {
        Logger(`inputContent: ${e}`)
        this.currentMailContent = e
        this.updateMailFlowToParent()
    }

    updateMailFlowToParent() {
        let _mf = { ...this.mf }
        _mf.subject = this.currentMailSubject
        _mf.content = this.currentMailContent
        this.$emit(`updateMail`, _mf)
    }
}
