
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import IconLock from "packs/components/icons/IconLock.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import FormField from "packs/models/FormField"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"
import DateTag from "packs/models/DateTag"
import CalendarUtil from "packs/utils/CalendarUtil"
import UserFile from "packs/models/UserFile"
import AvailableScheduleRule from "packs/models/AvailableScheduleRule"

@Options({
    components: { TextareaTag, CalendarIcon, IconHelp },
})
export default class AvailableScheduleRulesContent extends Vue {
    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager

    Util = Util
    SelectUtil = SelectUtil
    CalendarUtil = CalendarUtil
    RoomSetting = RoomSetting

    selectedTabName = `public` // public / schedule
    cm = CalendarManager

    notice = Notice
    truncate = truncate

    availableScheduleRules = []
    availableAstags = []

    editingRule = null
    alphabets = Util.alphabets

    // showDetail = false
    created() {
        this.getAllAstags()
    }

    mounted() {
        // 現在の調整カレンダーをセットします.

        let rules = this.rcm.room.available_schedule_rules
        Logger(`${funcName()} rules:${Util.output(rules)}`)

        if (Util.isBlank(rules)) {
            // 現在の調整カレンダーがない場合は、デフォルトの調整カレンダーをセットします.
            let rule = AvailableScheduleRule.createDefault(this.rcm.astag.id, this.rcm.astag)
            rules = [rule]
        } else {
            for (let rule of rules) {
                Logger(`${funcName()} rule:${rule.id}, astag:${rule.available_schedule_tag_id}`)
                let _r = this.availableAstags.find(ast => ast.id === rule.available_schedule_tag_id)
                if (_r) {
                    rule.astag_info = _r
                }
            }
        }
        this.availableScheduleRules = rules
    }

    getAllAstags() {
        if (!this.rm.astags) return []

        let astags = this.rm.astags.filter(a => [1, 2, 50].includes(a.type))

        // astags = astags.filter(ast => !ast.is_shared)
        this.availableAstags = astags
    }

    addRule() {
        let rule = AvailableScheduleRule.createDefault(this.rcm.astag.id, this.rcm.astag)

        this.availableScheduleRules.push(rule)
    }

    inputManual(manual, ruleId) {
        Logger(`${funcName()} ruleId:${ruleId}, manual:${manual}`)
        let rules = this.availableScheduleRules
        for (let rule of rules) {
            if (rule.id === ruleId) {
                rule.manual = manual
                break
            }
        }
        this.availableScheduleRules = rules
        this.updateRules()
    }

    deleteRule(rule: AvailableScheduleRule) {
        this.availableScheduleRules = this.availableScheduleRules.filter(r => r.id !== rule.id)
        this.updateRules()
    }

    updateRules() {
        this.rcm.room.available_schedule_rules = this.availableScheduleRules
    }

    public clickChangeAstag(ruleId: string) {
        Logger(`調整カレンダーを変更 を押しました. ruleId:${ruleId}`)
        this.rcm.showLeftPanelContentWithType = `rules_by_ai_astag`
        let rule = this.availableScheduleRules.find(r => r.id === ruleId)
        if (rule) {
            // this.rcm.edittingRule = null
            this.rcm.updateEdittingRule(rule)
        }
    }

    clickSelectTemplateForAI(ruleId: string) {
        Logger(`テンプレを例から追加 を押しました. ruleId:${ruleId}`)
        this.rcm.showLeftPanelContentWithType = `template_for_ai`
        let rule = this.availableScheduleRules.find(r => r.id === ruleId)
        if (rule) {
            this.rcm.edittingRule = null
            this.rcm.updateEdittingRule(rule)
        }
    }

    selectAvailableFieldsForAI() {
        Logger(`${funcName()} 利用する質問を設定 を押しました.`)
        this.rcm.showLeftPanelContentWithType = `available_fields_for_ai`
    }

    // @Watch(`rcm.edittingRule`, { deep: true })
    // updateEdittingRule() {
    //     if (!this.rcm.edittingRule) return

    //     Logger(`${funcName()} rcm.edittingRule:${Util.output(this.rcm.edittingRule)}`)
    //     if (this.rcm.edittingRule) {
    //         let editingRule = this.rcm.edittingRule
    //         for (let rule of this.availableScheduleRules) {
    //             if (rule.id === editingRule.id) {
    //                 rule = editingRule
    //                 // this.rcm.edittingRule = null
    //                 break
    //             }
    //         }
    //     }
    // }
}
