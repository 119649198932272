
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { gtagClick } from "packs/GoogleTagManager"
import { Logger } from "packs/common"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UsageContent from "packs/pages/link/modals/UsageContent.vue"
import Util from "packs/utils/Util"
import RegistrationForm from "packs/pages/link/parts/RegistrationForm.vue"
import YokosoAgreement from "packs/pages/link/parts/YokosoAgreement.vue"

// モデルを読込.
import RoomMember from "packs/models/RoomMember"
import RoomManager from "packs/models/RoomManager"
import Const from "packs/utils/Const"

@Options({
    components: {
        UsageContent,
        RegistrationForm,
        YokosoAgreement,
    },
})
export default class ModalInnerHowToUseCal extends Vue {
    @Prop()
    tutorialPhase: number

    @Prop()
    isDev

    @Prop()
    devType

    @Prop()
    howToUseType

    @Prop()
    action // fix_scheduleページでのみ利用.astagViewの場合はnilで渡ってくる.

    // data
    isSP = isMobile(window.navigator).phone
    notice = Notice

    content = ""
    rating = -1

    rm = RoomManager
    Const = Const

    @Emit("hide")
    closeWhatStrictCal() {}

    public created() {}

    /**
     * 同意後
     */
    public agreed() {
        if (this.isDev) {
            if (this.devType == 0) {
                this.rm.tutorial_phase = 9
                this.rm.userInfo.group_status = 1
            } else if (this.devType == 1) {
                this.rm.tutorial_phase = 10
                this.rm.userInfo.group_status = 1
            }
        }
        /**
         * FixViewからの場合、利用規約に同意した時点で閉じます。ログイン後登録する際に、form入力してもらう
         * */
        if (this.rm.tutorial_phase >= 9 && this.action) this.$emit("hide")
    }

    public completeRegistration() {
        // チュートリアルが15の場合閉じます。
        if (Const.started(this.rm)) {
            // this.hide();
            this.$emit("hide")
        }
        // チュートリアルが14以下（未招待者）の場合、
        // 「登録が完了しました！こちらからご案内いたしますので、しばらくお待ち下さい」
        else {
            // $(".schedule_view").height("300px");
        }
    }

    public clickBanner() {
        gtagClick(`登録完了 バナークリック`, ``)
        location.href = `https://waaq.jp/p/waaq/hikakusheet?utm_source=registration&utm_medium=banner&utm_campaign=hikaku16_202009`
    }
}
