
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { useProgress } from "@marcoschulte/vue3-progress"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import ImageBoardModal from "packs/pages/link/modals/ImageBoardModal.vue"
import TalkInterface from "packs/pages/link/settings/TalkInterface.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import DateTag from "packs/models/DateTag"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import NotificationControl from "packs/utils/NotificationControl"
import EmailDomain from "packs/models/EmailDomain"
import UserSetting from "packs/models/UserSetting"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        TextfieldTag,
        UserSettingsHeader,
        UserIcon,
        ImageBoardModal,
        TalkInterface,
    },
})
export default class UserSettingsInfo extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    util = Util
    selectedTab = `settings`

    isSP = isMobile(window.navigator).phone

    Const = Const

    us: UserSetting = null

    userName = ``
    companyName = ``
    companyShortName = ``
    ccEmails = ``
    replyEmails = ``

    currentImageUrl = null

    canUseEmailDomain = false
    myEmailDomain = null
    loading = false

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.startProgress()
            this.rm.getAvailableSchedule().then(res => {
                let userInfo = this.rm.userInfo
                this.setInfos(userInfo)
                this.canUseEmailDomain = this.rm.can_use_email_domain
                this.setMyEmailDomainName()
                this.rm.endProgress()
            })
        } else {
            let userInfo = this.rm.userInfo
            this.setInfos(userInfo)
            this.canUseEmailDomain = this.rm.can_use_email_domain
            this.setMyEmailDomainName()
        }
    }

    setMyEmailDomainName() {
        if (!this.canUseEmailDomain) return
        if (!this.rm.user_groups) return
        if (this.rm.user_groups.length == 0) return
        let currentUg = this.rm.user_groups.find(ug => ug.id == this.rm.userInfo.user_group_id)
        if (!currentUg) return
        let dom: EmailDomain = currentUg.assigned_email_domains[0]

        if (Util.isPresent(dom)) this.myEmailDomain = dom.name
    }

    /**
     * 変数に入れます.
     */
    setInfos(userInfo: RoomMember) {
        this.userName = userInfo.name
        this.companyName = userInfo.company_name
        this.companyShortName = userInfo.short_name
        this.ccEmails = userInfo.cc_emails
        if (this.rm.user_setting) {
            this.replyEmails = this.rm.user_setting.reply_emails
        }
    }

    mounted() {
        gtagPage("#/settings/user")
    }

    public inputUserName(e) {
        this.userName = e
    }

    public inputCompanyName(e) {
        this.companyName = e
    }

    public inputCompanyShortName(e) {
        this.companyShortName = e
    }

    public inputCcEmails(e) {
        Logger(`${funcName()} e:${e}`)
        this.ccEmails = e
    }

    inputReplyEmails(e) {
        Logger(`${funcName()} e:${e}`)
        this.replyEmails = e
    }

    public saveName() {
        let uf = this.rm.userInfo

        // バリデーションチェック.
        if (uf.is_company) {
            if (this.userName && this.companyShortName && this.companyName) {
            } else {
                Notice.message = `未入力の項目があります。ユーザー名、会社名、所属名は必須項目です。`
                return
            }
        } else {
            if (!this.userName) {
                Notice.message = `未入力の項目があります。ユーザー名は必須項目です。`
                return
            }
        }

        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        axios
            .put(`${Util.prefixUrl}/user_groups/user_info`, {
                name: this.userName,
                company_name: this.companyName,
                short_name: this.companyShortName,
                cc_emails: this.ccEmails,
                image_url: this.rm.userInfo.image_url,
                email_name: this.rm.email_name,
                reply_emails: this.replyEmails,
            })
            .then(res => {
                this.loading = false
                this.rm.endProgress()

                if (res.data) {
                    const userInfo = res.data.user_info
                    const us = res.data.user_setting
                    if (userInfo) {
                        this.setInfos(userInfo)
                        this.rm.userInfo = userInfo
                    }
                    if (us) {
                        this.rm.user_setting = us
                        this.replyEmails = us.reply_emails
                    }
                    const msg = res.data.message
                    Logger(`saveName msg:${msg}`)
                    this.notice.message = msg
                }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                this.loading = false
                this.rm.endProgress()
            })
    }

    // 独自ドメインを設定できる場合
    inputEmailName(e: string) {
        this.rm.email_name = e
    }

    /**
     * リモートから取得したURLをはめます.
     */
    public uploadedUserImage(file: UserFile) {
        if (!file) return
        this.rm.userInfo.image_url = file.image_url
    }

    selectFile(image: UserFile) {
        Logger(`selectFile: ${Util.output(image)}`)
        // 画像を選択しました.
        this.rm.userInfo.image_url = image.image_url

        // this.changeImage(image)
        this.$vfm.close(`ImageBoardModal`)
        this.saveName()
    }

    showImageBoardModal() {
        this.currentImageUrl = this.rm.userInfo.image_url
        this.$vfm.open(`ImageBoardModal`)
    }
}
