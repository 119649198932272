
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"

@Options({
    components: {},
})
export default class CalendarPinkButton extends Vue {
    // data:
    @Prop()
    room: Room

    @Prop()
    appo: Appointment

    @Prop()
    pdm

    rm = RoomManager

    public mouseover(category, label) {
        gtagHover(category, label)
    }

    public goAvailableScheduleView() {
        gtagClick(`遷移: FIX → AstagView`, `pinkButton`)
        // もし仲介で、仲介不参加の場合、不参加カレンダー設定ページにリダイレクトします。
        if (this.room.is_owner && !this.appo.required_owner_participation) {
            this.$router.push(`/settings/not_attendees_calendar`)
        } else {
            this.$router.push(`/available_schedule`)
        }
    }
}
