
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import qs from "qs"
import { Logger, copyToClipboard, funcName } from "packs/common"
import Util from "packs/utils/Util"
import NotificationControl from "packs/utils/NotificationControl"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import PublicRoomStatusMenu from "packs/pages/link/parts/public_rooms/PublicRoomStatusMenu.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"
import Switcher from "packs/components/forms/Switcher.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import RoomMember from "packs/models/RoomMember"
import RoomTab from "packs/models/RoomTab"
import Notice from "packs/models/Notice"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Appointment from "packs/models/Appointment"
import AppealContent from "packs/models/AppealContent"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import InstantRoomAssignedUser from "packs/models/InstantRoomAssignedUser"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AlertBadge,
        PublicRoomStatusMenu,
        UserIcon,
        CopyButton,
        Switcher,
    },
})
export default class OneInstantRoom extends Vue {
    @Prop()
    room: Room

    @Prop()
    showRoomModal //モーダルを表示するfunction

    @Prop()
    canDelete: boolean

    @Prop()
    isLastCell: boolean

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    selectedRoom = this.rm.currentRoom
    roomKeyId = null
    appo: Appointment = null
    apc: AppealContent = null
    // companyName = null;
    displayMember: RoomMember
    showTip = false
    showAppoTip = false
    showUrlTip = false
    Room = Room
    Util = Util
    title = ``

    currentMemIndex = null
    isHoverPrivateMemo = false

    myAssignedUser: InstantRoomAssignedUser = null

    // members_with_links: RoomMember[] = [];
    // selectedTabStatus = this.roomStatusesHash.categoryProgress;

    mounted() {
        this.getRoomTitle()
        this.updateAssignedMembers()
    }

    get isActive() {
        if (!this.myAssignedUser) return false
        return this.myAssignedUser.is_active
    }

    @Watch(`room`, { deep: true })
    updateAssignedMembers() {
        Logger(`${funcName()}`)
        if (!this.room) return
        let mems: InstantRoomAssignedUser[] = this.room.instant_room_assigned_users
        let mem = mems.find(m => m.user.id == this.rm.userInfo.user_id)
        if (mem) {
            this.myAssignedUser = mem
        }
    }

    bgColor(): string {
        if (!this.room) return this.isSP ? `skyblueBg` : `lskyblueBg`

        if (Room.isParentFixed(this.room.status)) return this.isSP ? `greenBg` : `lgreenBg`
        if (Room.isParentCanceled(this.room.status)) return this.isSP ? `alertRedBg` : `lalertRedBg`
        return this.isSP ? `skyblueBg` : `lskyblueBg`
    }

    getRoomTitle() {
        if (!this.room) return

        let _ac = this.room.appeal_content
        this.appo = this.room.current_appointment
        if (_ac) {
            this.apc = _ac
            if (_ac && _ac.title) {
                this.title = _ac.title
                return
            }
        }

        if (this.appo && this.appo.name) {
            this.title = this.appo.name
        }
    }

    // @Emit("goRoom")
    goRoom(room: Room) {
        if (Room.isParentDraft(room.status)) return false
        if (room.room_type == `public_room`) {
            if ([`suspended`, `draft`].includes(room.public_status)) {
                return
            }
        }

        this.$emit("goRoom", room)
        // return room
    }

    public copyPublicId() {
        $(this).select()
        copyToClipboard(this.room.public_id)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`publicIDをコピー`, `rid:${this.room.id}`)
    }

    public copyAppoText() {
        $(this).select()
        copyToClipboard(this.room.current_appointment.jp_format)
        this.showAppoTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`確定したアポ時間をコピー`, `rid:${this.room.id}`)
    }

    public copyPageUrl() {
        $(this).select()
        copyToClipboard(this.room.page_url)
        this.showUrlTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`公開ページのURLをコピー`, `rid:${this.room.id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
        this.showAppoTip = false
        this.showUrlTip = false
    }

    public roomLabels(): string {
        if (this.room.room_type == `public_room`) {
            let rs = this.room.room_setting
            if (rs && rs.is_instant_room) {
                return `label gradBlueBg`
            }
            return `label dblueBg`
        } else if (Room.isParentFixed(this.room.status)) {
            return "label label-green"
        } else if (this.room.status == Room.STATUS_ACTIVE_IN_PROGRESS) {
            return "fw600 ddgrey"
        }
    }

    get roomLabelName() {
        if (Util.isBlank(this.room)) return ``

        if (this.room.room_type == `public_room`) {
            let rs = this.room.room_setting
            if (rs && rs.is_instant_room) {
                return `スグINボタン`
            }

            return `公開ページ`
        } else if (Room.isParentFixed(this.room.status)) {
            return this.room.status_name
        } else if (this.room.status == Room.STATUS_ACTIVE_IN_PROGRESS) {
            return this.room.status_name
        }
    }

    // モーダル表示ボタンの表示/非表示
    public showHideCloseRoomModalButton(): boolean {
        const role = this.selectedRoom ? this.selectedRoom.role : 0 || 0
        if (
            this.selectedRoom &&
            this.selectedRoom.isParent &&
            Room.isParentActiveWithoutCanceled(this.selectedRoom.status) &&
            role == 1
        ) {
            return true
        } else {
            return false
        }
    }

    public fadeOutCloseRoom() {
        if (!this.rm.currentRoom && this.rm.openRooms) {
            this.rm.updateCurrentInfo(this.rm.openRooms[0])
        }
    }

    public selectCell(room) {
        $("tr").removeClass("selected")

        const roomKey = room.keyId
        $(`.${roomKey}`).addClass("selected")

        // 最後に参照したルームIDを保存しておきます。
        this.roomKeyId = roomKey
        Logger(`room key: ${this.roomKeyId} id: ${room.id}`)
        roomStorage.saveSelectedRoomKey(this.roomKeyId)

        // current系を最新の情報にアップデートします。
        RoomManager.updateCurrentInfo(room)
    }

    get checkStarred() {
        if (!this.room) return false

        return this.room.starred
    }

    public pushStar() {
        let action = ``
        if (this.room.starred) {
            // はずす
            action = `remove`
        } else {
            // スター
            action = `add`
        }
        gtagClick(`fav room`, `${action}`)
        this.rm.startProgress()
        Room.saveFav(this.room.id, action, this.room.room_type).then(e => {
            this.rm.endProgress()
            if (!e) return
            if (action == `add`) {
                this.room.starred = true
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms.push(this.room)
                }
            } else {
                this.room.starred = false
                let favtab: RoomTab = this.rm.roomTabs[`all`][`favorite`]
                if (favtab && favtab.rooms) {
                    favtab.rooms = favtab.rooms.filter(r => r.id != this.room.id)
                }
            }
            this.rm.updateFav(this.room)
        })
    }

    /**
     * 公開ページの公開ステータスを変更します.
     * status = open, suspend, delete
     */
    public changeStatus(status: string) {
        Logger(`changeStatus: ${this.room.public_status_name} -> ${status}`)
        //
        if (status == `delete`) {
            this.showRoomModal(this.room, "delete")
        } else {
            Room.changeStatus(this.room, status).then(isSuccess => {
                if (isSuccess) {
                    this.room.public_status_name = {
                        draft: `下書き`,
                        publishing: `公開中`,
                        suspended: `公開停止中`,
                        hidden: `削除済み`,
                    }[status]
                    this.room.public_status = status
                }
            })
        }
    }

    get hasImage(): boolean {
        if (!this.apc) return false
        return this.apc.image_url ? true : false
    }

    // 投票形式で確定した予定をキャンセルします。（モーダルを表示）
    cancelVoted(room: Room) {
        this.$emit(`cancelVoted`, room)
    }

    cancelAppo(room: Room) {
        this.$emit(`cancelAppo`, room)
    }

    createFeedback(room: Room) {
        this.$emit(`createFeedback`, room)
    }

    clickEditMenu() {
        Logger(`clickEditMenu`)
        gtagClick(`roomCell`, `EditMenu: isLast: ${this.isLastCell}`)
        if (this.isLastCell) {
            // 一番したのセルだったら場所をずらします.
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "-110px" })
        } else {
            $(`#roomEditMenu ul.dropdown-menu`).css({ "margin-top": "auto" })
        }
    }

    // 終了したページを戻します.
    changeActive() {
        let active = this.myAssignedUser.is_active
        this.$emit(`changeActive`, this.room, !active)
    }

    selectedMagTeamName(truncate: number) {
        let dic = Object.values(this.appo.selected_mag_dic)[0]
        if (!dic) return null
        // team_nameは自分で設定した名前、なければ name: A, Bで選択されたものを表示.
        let name = dic.team_name || dic.name
        return name || null
    }

    searchByMember(mem: RoomMember) {
        gtagClick(`セル検索 メンバー`, `${mem.user_id}`)
        this.$emit(`searchByMember`, mem)
    }

    mouseoverMember(mem: RoomMember, memindex: number) {
        Logger(`mouseoverMember: mem: ${mem.email}, memindex: ${memindex}`)
        this.currentMemIndex = memindex
    }

    mouseleaveMember() {
        this.currentMemIndex = null
    }

    searchByMag() {
        let mag: MeetingAttendeesGroup = Object.values(this.appo.selected_mag_dic)[0]
        gtagClick(`セル検索 担当`, `${mag.team_name}`)
        this.$emit(`searchByMag`, mag)
    }

    clickFormMember(mem: RoomMember) {
        // フォーム確認
        this.$emit(`clickFormMember`, mem, this.room)
    }

    editPrivateMemo() {
        this.$emit(`editPrivateMemo`, this.room)
    }

    mouseoverPrivateMemo() {
        this.isHoverPrivateMemo = true
    }

    mouseleavePrivateMemo() {
        this.isHoverPrivateMemo = false
    }
}
