
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MailPreviewPartsEditContent from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsEditContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Util from "packs/utils/Util"

@Options({
    components: {
        MailPreviewPartsEditContent,
    },
})
export default class MailPreviewSimpleText extends Vue {
    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    rcm = RoomCreateManager

    created() {}

    mounted() {
        this.addMessage()
    }

    get showSignature() {
        if (this.rcm && this.rcm.showSMSField) {
            return false
        }
        if (this.rm && this.rm.user_setting && this.rm.user_setting.signature_text) {
            return true
        }
        return false
    }

    get showButton() {
        if (this.rcm && this.rcm.showSMSField) {
            return false
        }
        return true
    }

    addMessage() {
        // this.editEditableContent(`message`)
        this.$emit(`addMessage`)
    }

    buttonName() {
        if (!this.rcm.selectedMailFlow) return
        let mf = this.rcm.selectedMailFlow
        if ([`create_room`, `shared_calendar`, `one_day_before_deadline`, `auto_remind`].includes(mf.def_type)) {
            if (this.rcm.canFix()) {
                return `上記以外の日程をご希望の場合はこちらからお願いいたします`
            } else {
                return `日程をこちらからご提案ください`
            }
        } else if (mf.def_type == `feedback`) {
            if (Util.isPresent(mf.button_link) && Util.isPresent(mf.button_name)) {
                return mf.button_name
            } else {
                return `アンケートに答える`
            }
        } else if (mf.def_type == `notify_message`) {
            return `チャットメッセージを確認する`
        }

        return `調整ページで確認する`
    }
}
