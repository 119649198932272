
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { gtagClick, gtagPage, gtagEvent } from "packs/GoogleTagManager"
import { VueFinalModal } from "vue-final-modal"

// コンポーネントを読込.
import ModalInnerPaymentRequest from "packs/pages/link/modals/ModalInnerPaymentRequest.vue"

// モデルを読込.

@Options({
    components: { ModalInnerPaymentRequest, VueFinalModal },
})
export default class AdLimitFree extends Vue {
    @Prop()
    showAds: boolean //現在は課金フォーム

    @Prop()
    addFrom: string

    // data:

    openModal() {
        if (this.showAds) {
            gtagEvent(`show`, `Trial Limitを表示`, `${this.addFrom}`)
        }
    }

    fadeOutAdModal() {
        gtagClick(`Trial Limitモーダル:閉じる`, this.addFrom)
        // this.$emit(`fadeOutAdModal`)
        this.$vfm.close(`PaymentRequestModal`)
    }

    // public fadeOutPaymentRequestModal() {
    //     // this.showAds = false;
    //     gtagClick(`Trial Limitモーダル:閉じる`, this.addFrom);
    //     this.$vfm.close("PaymentRequestModal");
    // }

    // goPayment() {
    //     gtagClick(`Adモーダル:${this.addFrom}`, this.addFrom);
    //     this.$router.push("/payment");
    // }
}
