
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import HeaderControl from "packs/utils/HeaderControl"

// コンポーネントを読込.
import SuggestedRoomsForAll from "packs/pages/link/parts/room_summary/SuggestedRoomsForAll.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import axios from "types/axios"
import NotificationControl from "packs/utils/NotificationControl"
import VotedRoomManager from "packs/models/VotedRoomManager"
import Refresher from "packs/models/Refresher"

@Options({
    components: { SuggestedRoomsForAll, RoomsLoader },
})
export default class ScheduleMainHeaderTitle extends Vue {
    // data:
    @Prop()
    type: string

    @Prop()
    appo: Appointment

    rcm = RoomCreateManager
    cm = CalendarManager
    rm = RoomManager
    pdm = PossibleDatesManager
    vrm = VotedRoomManager

    showTooltip = false
    periodText = ``
    showVotedPage = false
    votedRooms: Room[] = null
    canShowVotedPage = false

    // 日程のみ表示（trueの場合、日程を確認・確定はできない.）
    onlyShowDates = false

    room: Room = null
    refresher = Refresher

    mounted() {
        Logger(`${funcName()} appo:${this.appo?.id}`)
        this.updateAppointment()
    }

    // @Watch("type", { deep: true })
    // public updateType() {
    //     this.headerTitle()
    // }

    // すぐインボタンの場合は、表示しない.
    get showHeaderTitle() {
        if (!this.pdm.room) return true
        let rs = this.pdm.room.room_setting
        if (!rs) return true
        if (rs.is_instant_room) return false
        return true
    }

    get headerTitle(): string {
        Logger(`${funcName()} type:${this.type}`)

        return HeaderControl.getScheduleViewTitle(this.type, this.appo)
    }

    @Watch(`appo`, { deep: true })
    updateAppointment() {
        Logger(
            `${funcName()} appo:${this.appo?.id}, room:${this.pdm.room?.id}, canShowVotedPage:${
                this.canShowVotedPage
            }, onlyShowDates:${this.onlyShowDates}`
        )
        if (!this.appo) return false
        if (!this.pdm.room) return false
        let room = this.pdm.room
        let rs = room.room_setting

        if (Util.isPublic()) {
            if (rs && [`display_all`, `display_pinned`].includes(rs.show_vote_type)) {
                this.canShowVotedPage = true
                this.onlyShowDates = true
                this.vrm.myParentRoom = room
                this.clickVotedPage()
                return
            }
            return false
        }
        if (Util.isRoomSettingsPreview()) return false
        if (!this.rm.userInfo) return false
        if (this.appo.status == 10) {
            this.canShowVotedPage = false
            return
        }

        if (this.appo.fix_type == 100) return false

        let myMem = room.members.find(_m => _m.user_id == this.rm.userInfo.user_id)
        if (!myMem) return false

        if (myMem.role == RoomMember.OWNER) {
            this.canShowVotedPage = true
            return
        }
        // 限定公開ページの場合、投票決定者以外
        if (rs && [`display_all`, `display_pinned`].includes(rs.show_vote_type) && ![`fix`].includes(myMem.assign_type)) {
            this.canShowVotedPage = true
            this.onlyShowDates = true

            this.clickVotedPage()
            return
        }
        if ([`fix`].includes(myMem.assign_type)) {
            this.canShowVotedPage = true
            this.vrm.myParentRoom = room
            // オーナーではない日程決定者だったら、投票状況を自動的に開きます.
            this.clickVotedPage()
            return
        }
        this.canShowVotedPage = false
        return
    }

    clickVotedPageButton() {
        Logger(`ScheduleMainHeaderTitle.clickVotedPageButton`)
        if (this.showVotedPage) {
            // 閉じます.
            this.showVotedPage = false
            return
        }

        // 開きます.
        this.showVotedPage = true
    }

    clickVotedPage() {
        Logger(`ScheduleMainHeaderTitle.clickVotedPage`)

        if (!this.showVotedPage) {
            // 閉じている場合のみ開く処理を入れます。
            this.clickVotedPageButton()
        }

        if (this.votedRooms) {
            // すでに取得済みのためリターン.
            return
        }

        let room = this.pdm.room
        let rs = room.room_setting
        let parentId = room.id
        let pubId = this.pdm.room.public_room_id
        if (rs && [`display_all`, `display_pinned`].includes(rs.show_vote_type)) {
            if (room.room_type == `public_room`) {
                pubId = room.id
            }
        }

        if (this.onlyShowDates) {
            // 日程決定者またはオーナー以外の、公開ページで投票状況を確認できる場合の処理.
            let rooms = this.vrm.rooms
            Logger(
                `${funcName()} Room.getPublicRoomWithVotedRoomsForDisplayAll pubId:${pubId}, 提案済みのvrm.rooms:${rooms?.length}`
            )

            if (rooms) {
                this.fetchParams(this.vrm.publicRoom)
                return
            }
            Room.getPublicRoomWithVotedRoomsForDisplayAll(pubId, this.rm.userInfo).then(room => {
                if (room) {
                    this.fetchParams(room)
                }
            })
        } else {
            Logger(`${funcName()} Room.getPublicRoomWithVotedRooms pubId:${pubId}, parentId:${parentId}`)
            // 日程決定者またはオーナー.
            Room.getPublicRoomWithVotedRooms(pubId, parentId, this.rm.userInfo).then(room => {
                if (room) {
                    this.fetchParams(room)
                }
            })
        }
    }

    fetchParams(__room: Room) {
        if (__room.room_type == `public_room`) {
            __room.rooms_fixed = Room.fetchFromJson(__room.rooms_fixed, this.rm.userInfo.user_id)
            let notFixedRooms = Room.fetchFromJson(__room.rooms_voted, this.rm.userInfo.user_id)
            let notVotedRooms = Room.fetchFromJson(__room.rooms_not_voted, this.rm.userInfo.user_id)

            // 投票していないルーム
            __room.rooms_not_voted = notVotedRooms
            // 日程投票しているルーム
            __room.rooms_voted = notFixedRooms
            __room.rooms_closed = Room.fetchFromJson(__room.rooms_closed, this.rm.userInfo.user_id)
        }
        Logger(
            `${funcName()} vrm.userInfo:${this.vrm?.userInfo?.user_id}, rooms_voted:${Util.output(
                __room.rooms_voted
            )}, room.room_type:${__room?.room_type}`
        )

        this.room = __room
        this.votedRooms = __room.rooms_voted
        if (!this.vrm.userInfo) {
            Logger(`${funcName()} vrmにセットします.`)
            this.vrm.createNew(__room, __room.rooms_voted, this.rm.userInfo)
        }
    }

    /**
     * 確定後に確定したルームと未確定のルームをアップデートします。
     */
    public fixedAppo(fixedRooms: Room[], notFixedRooms: Room[]) {
        Logger(`ScheduleMainHeaderTitle#fixedAppo`)
        if (Util.isPresent(this.vrm.myParentRoom)) {
            this.rm.updateCurrentInfo(this.vrm.myParentRoom)
            sleep(50).then(_ => {
                this.refresher.needRefresh.push(`room`)
            })
        }

        // Array.prototype.push.apply(this.room.rooms_fixed, fixedRooms)
        // // 前に追加.
        // // Array.prototype.unshift.call(this.room.rooms_fixed, fixedRooms)
        // let [voted, notVoted] = this.autoSeparateVotedOrNot(notFixedRooms)
        // this.room.rooms_voted = voted
        // this.room.rooms_not_voted = notVoted
        // this.room.rooms_voted_num = (voted || []).length
        // this.selectTab(this.selectedTab)
    }
}
