
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
declare var gtag: any
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import AppointmentCardView from "packs/pages/link/AppointmentCardView.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import { Logger } from "packs/common"
import SubscriptionManager from "packs/models/SubscriptionManager"
import Util from "packs/utils/Util"

@Options({
    components: {
        AppointmentCardView,
        VueFinalModal,
    },
})
export default class ModalPastAppointments extends Vue {
    @Prop()
    room: Room // addnewAppo, editAppo

    // data
    isSP = isMobile(window.navigator).phone
    appos = null

    public dismissModal() {
        this.$vfm.close("PastAppointments")
    }

    mounted() {
        if (this.room && Util.isPresent(this.room.appointments)) {
            this.appos = [...this.room.appointments].reverse()
        }
    }

    @Watch("room", { deep: true })
    updateAppos() {
        this.appos = [...this.room.appointments].reverse()
    }
}
