
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagLog, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ScheduleMainContent from "packs/pages/schedule/ScheduleMainContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import AppealContentSettings from "packs/pages/link/parts/AppealContentSettings.vue"
import EditableZoomSettings from "packs/pages/link/parts/room_settings/EditableZoomSettings.vue"
import IconGear from "packs/components/icons/IconGear.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import PossibleDate from "packs/models/PossibleDate"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import RoomCreateManager from "packs/models/RoomCreateManager"
import CalendarUtil from "packs/utils/CalendarUtil"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"
import LocationTag from "packs/models/LocationTag"

const roomStorage = new RoomStorage()

@Options({
    components: {
        UserIcon,
        ScheduleMainContent,
        AppealContentSettings,
        EditableZoomSettings,
        IconGear,
    },
})
export default class EditableScheduleView extends Vue {
    // data:

    @Prop() // func
    editEditableContent

    @Prop() // func
    closePanel

    @Ref()
    ScheduleMainContent

    @Ref()
    cardViewLarge

    notice = Notice

    rm = RoomManager
    rcm = RoomCreateManager
    cm = CalendarManager

    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    LocationTag = LocationTag
    contentWidth = 350

    // duration = null;
    type = `editable` // `fill`, `overstart`, `overdue`, `already`, `fix`, `editable`
    // roomKeyId: string = null
    // selectablePdm = null
    // pd: PossibleDate = null
    currentPossibleDates = null
    parentId = null
    $store: any

    selectedTab = `schedule` // スマホタブ: appo / schedule

    // fixの場合のstart_time
    start_time: number = null

    Const = Const
    isGuest = true
    isSuspend = false

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.
    beforeAstag: AvailableScheduleTag = null

    public created() {
        let status = this.$store.state.activeStatus
        if (status.length == 0) this.$store.dispatch("start")
    }

    public destroyed() {
        this.pdm.resetPdm()
    }

    public mounted() {
        gtagPage("#/調整ページ-Editable")
        this.closePanel()
        this.configureRoom()
        this.updateAstag()
        this.getPossibles()

        if (this.rcm.room && this.rcm.room.room_type == `parent_room` && (this.rcm.members || []).length == 0) {
            this.editEditableContent(`attendees`)
        }
        if (this.rcm.astag) {
            this.beforeAstag = { ...this.rcm.astag }
        }
        this.updateLowerLimitNum()
    }

    /**
     * Editableで利用する情報を取得します。
     */
    public configureRoom() {
        if (Util.isBlank(this.rcm.room)) return
        if (Util.isBlank(this.rcm.appo)) return
        if (Util.isBlank(this.rcm.astag)) return

        Logger(`configureRoom 更新されました editable: ${Util.output(this.rcm.appo)}`)
    }

    @Watch("rcm.room_setting.submit_lower_limit_num", { deep: true })
    updateLowerLimitNum() {
        Logger(`updateLowerLimitNum: ${Util.output(this.rcm.room_setting)}`)
        if (!this.rcm.room_setting) return
        this.pdm.owner_request_num = this.rcm.room_setting.submit_lower_limit_num
    }

    @Watch("rcm.room", { deep: true })
    public updateRoom() {
        Logger(`updateRoom`)
        if (!this.rcm.room) return
        this.parentId = this.rcm.room.id
        // this.roomKeyId = this.room.keyId
        // 更新した情報によって、possible_datesのリフレッシュが必要な場合に呼ぶ
        // this.getPossibles()
    }

    @Watch("rcm.appo", { deep: true })
    updateAppointment() {
        Logger(`updateAppointment`)
        this.configureRoom()
        // 更新した情報によって、possible_datesのリフレッシュが必要な場合に呼ぶ
        // this.getPossibles()
    }

    @Watch("rcm.astag", { deep: true })
    updateAstag() {
        if (Util.isBlank(this.rcm.astag)) return
        Logger(`EditableScheduleView.updateAstag astag.name:${this.rcm.astag.name}`)
        // this.configureRoom()
        // 更新した情報によって、possible_datesのリフレッシュが必要な場合に呼ぶ
        if (this.beforeAstag && this.beforeAstag.id != this.rcm.astag.id) {
            this.beforeAstag = { ...this.rcm.astag }
            this.getPossibles()
        } else if (!this.pdm.possibleDates) {
            this.getPossibles()
        }
    }

    /**
     * 調整カレンダーの共有・共有解除をしたため、possible_datesを取得し直します。
     */
    public changeAstag(astag) {
        Logger(`astagに変更があったため、possible_datesを取得してきます。`)
        // this.pdm = null
        if (this.rcm.appo && [10, -1].includes(this.rcm.appo.status)) {
            this.currentPossibleDates = []
        } else {
            this.currentPossibleDates = null
            this.getPossibles()
        }
    }

    /**
     * 調整カレンダーの変更を押しました.左paneを表示し、調整カレンダーの変更を促します。
     */
    editAstag() {
        this.editEditableContent(`astag`)
    }

    editAttendees() {
        this.editEditableContent(`attendees`)
    }

    editAppoInfo() {
        this.editEditableContent(`appo`)
    }

    editMessage() {
        this.editEditableContent(`message`)
    }

    editHeaderImage() {
        this.editEditableContent(`image`)
    }

    editHeaderContent() {
        this.editEditableContent(`headerContent`)
    }

    deleteHeaderImage() {
        // this.$emit("deleteHeaderImage")
        if (!this.rcm) return
        if (!this.rcm.appeal_content) return
        this.rcm.appeal_content.image_url = null
        this.rcm.updateAppealContent(this.rcm.appeal_content)
        this.notice.message = `ヘッダー画像を削除しました。`
    }

    editLocations() {
        this.editEditableContent(`locations`)
    }

    // タイプが変更されたときに、fill, overstart, overdue の場合、再度pdmsを取得しなおして表示.
    public getPossibles(needRefresh = true, month = null, dummy1 = null, dummy2 = null, week = null) {
        // this.ScheduleMainContent.getPossibles()
        Logger(
            `${funcName()} needRefresh:${needRefresh}, month:${month}, cm.pdm.available_months:${
                this.cm.pdm.available_months
            } rcm.room:${this.rcm.room?.id}, week:${week}, calendarShowType:${this.pdm.calendarShowType}`
        )
        if (!this.rcm.room) return

        Logger(`${funcName()} ${this.type} rcm.astag:${this.rcm?.astag?.id}, rcm.appo:${this.rcm.appo?.name}`)
        if (!this.rcm.astag) return
        if (!this.rcm.appo) return
        if (!this.rcm.room_setting) return

        let _astag = { ...this.rcm.astag }

        if (this.pdm.calendarShowType == `weekly`) {
            if (!week) {
                week = CalendarUtil.toWeekSpan(DateTime.local())
            }

            Logger(`${funcName()} weekly week:${week}`)
        } else if (this.pdm.calendarShowType == `monthly`) {
            if (!month) {
                month = CalendarUtil.toCalId(DateTime.local())
            }
        }

        if (!(month || week)) {
            let rs = this.rcm.room_setting

            CalendarUtil.getSpanDescriptionText(rs).then(e => {
                Logger(`EditableScheduleView#getSpanDescriptionText:${e} `)
                let availableMonths
                let availableWeeks
                if (e) {
                    this.rcm.periodText = e[0]
                    availableMonths = e[1]
                    availableWeeks = e[2]

                    if (this.cm && this.cm.pdm) {
                        Logger(`${funcName()} available_months:${Util.output(e[1])}`)
                        this.cm.pdm.available_months = availableMonths
                        this.cm.pdm.months = availableMonths
                    }
                }

                this.ScheduleMainContent.getPreviewEvents(
                    _astag,
                    this.rcm.appo,
                    this.rcm.room_setting,
                    needRefresh,
                    availableMonths ? availableMonths[0] : null,
                    this.rcm.room,
                    availableWeeks ? availableWeeks[0] : null
                )
            })
        } else {
            this.ScheduleMainContent.getPreviewEvents(
                _astag,
                this.rcm.appo,
                this.rcm.room_setting,
                needRefresh,
                month,
                this.rcm.room,
                week
            )
        }

        // this.ScheduleMainContent.currentPossibleDates = null
    }

    /**
     * スマホでタブを押下したときの処理.
     * tabType: appo: CardView / schedule: 日程調整
     */
    public selectTab(tabType: string) {
        this.selectedTab = tabType
    }

    get stepSizeText() {
        if (!this.rcm || !this.rcm.room_setting) return
        let meetingTimeArr = CalendarUtil.selectDisplayMeetingTimeArr
        let type = this.rcm.room_setting.step_size_type || `hourly`
        let dic = meetingTimeArr.find(mt => mt.time == type)
        return dic.type
    }

    get showZoomSettings() {
        if (!this.rcm || !this.rcm.appo) return false
        if (this.rcm.appo.location_name == Const.zoomConnectAccount) {
            return true
        }
        let loctags = this.rcm.appo.selectable_locations || []
        let loctag = loctags.find(lt => lt.keyword == Const.zoomConnectAccount)
        Logger(`${funcName()} loctags:${Util.output(loctags)}`)
        if (loctag) {
            return true
        }
        return false
    }
}
