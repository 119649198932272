
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import PossibleDateControl from "packs/pages/link/parts/PossibleDateControl.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import PossibleDate from "packs/models/PossibleDate"
import SubscriptionManager from "packs/models/SubscriptionManager"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"

const roomStorage = new RoomStorage()

@Options({
    components: { AdLimitFree, UserIcon, SelectTag, PossibleDateControl, VueFinalModal, MdProgressBar, MdProgressSpinner },
})
export default class FixScheduleSmallModal extends Vue {
    @Prop()
    room: Room

    @Prop()
    from: string // asview 調整カレンダー画面, schedule スケジュールビュー, vote 投票型

    @Prop() // func
    getNextStep

    // data
    rm = RoomManager
    loading = false
    subm = SubscriptionManager
    showAds = false
    currentPd?: PossibleDate = null
    pdIndexType?: string = ``
    isSP = isMobile(window.navigator).phone
    SelectUtil = SelectUtil

    currentAttendees?: RoomMember[] = null
    currentNotAttendees?: RoomMember[] = null
    currentUnknownAttendees?: RoomMember[] = null

    pdm = PossibleDatesManager
    contentHeight = 300

    selectedMag: MeetingAttendeesGroup = null // あくまで今は {name: string, id: string}のdictionary

    fixVoteType = SelectUtil.votedRoomFixTypes[0].type
    useKeyBind = false
    canFix = false
    isPreview = Util.isPreview()

    public updated() {
        setTimeout(this.adjustHeight, 100)
    }

    adjustHeight() {
        let h = $(".FixScheduleModal").innerHeight()
        if (h) {
            this.contentHeight = h - 90
            Logger(`contentHeight: ${this.contentHeight}`)
        }
    }

    openModal() {
        this.useKeyBind = true
        this.pdm.checkFixOrNot()
        this.canFix = this.pdm.canFix
        this.updatePd()
        gtagPage(`#/確定小モーダル`)
    }

    public updatePd() {
        Logger(`FixScheduleModal : created ${Util.output(this.pdm.currentPd)}`)
        Logger(`FixScheduleModal: センタ中のmag ${Util.output(this.pdm.selectedMag)}`)
        this.currentPd = this.pdm.currentPd
        // let pds = this.getCurrentPds()
        // const num = this.checkPdIndex(pds)
        // this.checkPdIndexType(pds, num)
        // this.adjustHeight()
        if (this.from == `vote`) {
            this.pdm.canFix = true
        }
    }

    @Watch("pdm.selectedMag", { deep: true })
    public updateSelectedMag() {
        Logger(`FixScheduleModal : mag ${Util.output(this.pdm.selectedMag)}`)
        this.selectMag(this.pdm.selectedMag)
        // this.adjustHeight()
    }

    public hideFixScheduleModal() {
        this.$vfm.close("FixScheduleSmallModal")
    }

    public changeLoadingStatus(load: boolean) {
        this.loading = load
    }

    public fixSchedule() {
        Logger(`予定を確定します。`)

        // このあとフォームを表示する場合は、いったんモーダルを閉じて、フォーム表示に変更します。
        let nextStep = this.getNextStep()
        if (Util.isPublic() && [`form_with_fix`, `complete`].includes(nextStep)) {
            if (Util.isEmbeds() && nextStep == `complete`) {
                // this.$vfm.open("FixLoadingModal")
            } else {
                this.$emit(`callBeforeFix`, this.currentPd)
                return
            }
        }

        let appo = this.rm.currentRoom.current_appointment
        gtagClick(`日程確定`, `appo: ${appo.id} ${this.currentPd.jp_format}`)

        if (this.loading) return
        this.loading = true

        let magId = this.selectedMag ? this.selectedMag.id : null
        Logger(`fixするmagid: ${magId}`)

        Appointment.update(appo, "fix", this.room, this.pdm.currentPd.start_time, magId, this.rm.guestKey).then(dic => {
            if (dic && dic.appo) {
                let nextAction: string = dic.next_action
                let type: string = dic.type

                this.rm.setAppo(dic.appo)

                // 埋まっていた場合はモーダルを表示し、再調整を促します。
                if (type == `fix_schedule_failed` && nextAction == `reload`) {
                    Logger(`埋まっていたため再調整します。 ${dic.appo}`)
                    this.$emit(`rescheduledRoom`, dic.appo, true)
                } else {
                    gtagPage(`fixed`)
                    Logger(`appoが確定しました。 ${dic.appo.location_name}`)
                    this.$emit(`actionAfterFixed`, dic)
                }
            } else {
                // エラーの場合nullで更新しない。
            }
            this.loading = false
            this.hideFixScheduleModal()
        })
    }

    public fadeOutAdModal() {
        this.showAds = false
    }

    @Watch("currentPd", { deep: true })
    public refreshCurrentPdInfo() {
        if (!this.currentPd) return
        if (this.from == `asview`) return
        if (this.room.room_type == `public_room`) return

        // this.selectedMag = null

        // TODO: 仲介者が参加する場合、しない場合を計算すること
        // ○の人数は、共有者全員
        this.currentAttendees = this.pdm.sharedMembers

        // それ以外が ?
        let members = this.rm.currentRoom.attendees
        let unknowns = []
        for (let _mem of members) {
            if (!this.currentAttendees.some(att => att.user_id == _mem.user_id)) {
                unknowns.push(_mem)
            }
        }
        this.currentUnknownAttendees = unknowns

        // 現在×の人を取得していないため、
        this.currentNotAttendees = []
    }

    // public showBeforePossibleDate() {
    //     let pds = this.getCurrentPds()
    //     Logger(`before currentPdType: ${this.currentPdType} pds length: ${pds.length}`)

    //     let num = this.checkPdIndex(pds)
    //     if (num >= 1) {
    //         num -= 1
    //         this.currentPd = pds[num]
    //         Logger(`pdを変更: ${Util.output(this.currentPd)}`)
    //         gtagClick(`FIX MODAL 前の予定を確認`, `${this.currentPd.jp_format}`)
    //         this.pdm.selectCurrentPd(this.currentPd)
    //     }
    //     this.checkPdIndexType(pds, num)
    // }

    // public showAfterPossibleDate() {
    //     let pds = this.getCurrentPds()
    //     Logger(`after currentPdType: ${this.currentPdType} pds length: ${pds.length}`)
    //     let num = this.checkPdIndex(pds)
    //     Logger(`before num: ${num}, pds: ${Util.output(pds[num])}`)
    //     if (num >= 0) {
    //         if (pds.length > num + 1) {
    //             num += 1
    //             Logger(`After num: ${num}, pds: ${Util.output(pds[num])}`)
    //             this.currentPd = pds[num]
    //             Logger(`pdを変更: ${Util.output(this.currentPd)}`)
    //             gtagClick(`FIX MODAL 次の予定を確認`, `${this.currentPd.jp_format}`)
    //             this.pdm.selectCurrentPd(this.currentPd)
    //         }
    //     }
    //     this.checkPdIndexType(pds, num)
    // }

    // public checkPdIndex(pds: PossibleDate[]): number {
    //     return PossibleDate.checkPdIndex(pds, this.currentPd)
    // }

    // public checkPdIndexType(pds: PossibleDate[], num: number) {
    //     this.pdIndexType = PossibleDate.checkPdIndexType(pds, num)
    // }

    /**
     * 今回利用するPossibleDate[]を3タイプの中から取得してきます。
     */
    public getCurrentPds(): PossibleDate[] {
        return this.pdm.getPdsAfterRemoved()
    }

    /**
     * 選択中のmagIdを取得します。
     * magDic = {name: string, id: string}
     */
    public selectMag(magDic: MeetingAttendeesGroup) {
        Logger(`selectMag: ${Util.output(magDic)}`)
        // Logger(`selectMag ${Util.output(this.rm.astag)}`)
        // let mags = this.rm.astag.meeting_attendees_groups
        // let mag = mags.find(m => m.id == magId)
        this.selectedMag = magDic
        Logger(`magをはめました： ${magDic ? magDic.name : `null`}`)
    }

    public selectVoteFixType(e) {
        let typeDic = SelectUtil.votedRoomFixTypes.find(t => t.name == e)
        if (typeDic) {
            this.fixVoteType = typeDic.type
        }
    }
}
