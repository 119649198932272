
// モジュールを読込.
import { Vue, Options, Prop, Watch } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import ZoomSelectContent from "packs/components/calendar/ZoomSelectContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import LocationTag from "packs/models/LocationTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: { ZoomSelectContent, VueFinalModal, MdProgressBar },
})
export default class ConnectZoomModal extends Vue {
    @Prop() // すべてのZoomアカウント.
    zoomLoctags: CalendarTag[]

    @Prop()
    magForProfile: MeetingAttendeesGroup

    isPreview: boolean = Util.isPreview()

    // data
    rm = RoomManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    Util = Util

    selectedZooms: CalendarTag[] = null
    unselectedZooms: CalendarTag[] = null
    cm = CalendarManager

    public created() {}

    public mounted() {
        this.updateZooms()
    }

    public closeModal() {
        this.$vfm.close(`ConnectZoomModal`)
        this.$emit(`cancel`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    public updateZooms() {
        Logger(`updateZooms: zooms: ${this.zoomLoctags}`)
        if (Util.isBlank(this.cm.edittingMag)) return
        if (!this.zoomLoctags) return

        let mag = this.cm.edittingMag
        Logger(`magの中身を見ます: ${Util.output(mag)}`)
        let onlines = [...(mag.online_meeting_resources || [])]
        Logger(`onlines: ${Util.output(onlines)}`)
        let zoomSearchKeys = onlines.map(z => z.search_key)
        // let selectedKeys = zoomSearchKeys.map(z => z.search_k)

        let unzooms = []
        for (let _zoom of this.zoomLoctags) {
            if (zoomSearchKeys.includes(_zoom.search_key)) {
            } else {
                unzooms.push(_zoom)
            }
        }
        this.selectedZooms = onlines
        this.unselectedZooms = unzooms
    }

    updateZoomAccounts(selectedZooms: CalendarTag[], unselectedZooms: CalendarTag[]) {
        Logger(`ConnectZoomModal:updateZoomAccounts: selectedZooms: ${Util.output(selectedZooms)}`)
        this.selectedZooms = selectedZooms
        this.unselectedZooms = unselectedZooms
    }

    public save() {
        this.$emit(`selectZoom`, this.selectedZooms)
    }
}
