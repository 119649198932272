
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import IconLock from "packs/components/icons/IconLock.vue"

// モデルを読込.
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomManager from "packs/models/RoomManager"
import FormCreateManager from "packs/models/FormCreateManager"

@Options({
    components: {
        TextareaTag,
        IconLock,
    },
})
export default class FormFieldsList extends Vue {
    // data:

    optionEventName = `フォームファイルアップロード`

    Util = Util
    rm = RoomManager
    fcm = FormCreateManager
    typeArray = FormFieldCreator.fieldTypeArray()
    typeNoneInputArray = FormFieldCreator.fieldTypeNoneInputArray()
    typeSpecialArray = null

    mounted() {
        Logger(`${funcName()} fcm.aform:${Util.isPresent(this.fcm.aform)}`)
        this.typeSpecialArray = FormFieldCreator.fieldTypeSpecialInputArray(Util.isPresent(this.fcm.aform) ? true : false)
        Logger(`${funcName()} typeSpecialArray:${Util.output(this.typeSpecialArray)}`)
    }

    clickField(fieldDic: any) {
        Logger(`select ${fieldDic.field_type}`)
        if (fieldDic.field_type == `upload_file`) {
            const available = this.rm.canUseOption(`room_upload_file_in_form`, true)
            if (!available) return
        }
        let _f = FormFieldCreator.getFieldFromFieldType(fieldDic)
        this.$emit(`select`, _f)
    }
}
