import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href", "onClick"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.message && _ctx.message.message.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`kaigyo messageInnerContent ${_ctx.message.id == `unsent` || _ctx.isMe ? `fr skyblueBg` : `fl lgreyBg`}`)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autoLink, (text, textindex) => {
          return (_openBlock(), _createElementBlock("span", {
            key: `${text}${textindex}`
          }, [
            (text.match(_ctx.regex))
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _createElementVNode("a", {
                    href: text,
                    class: _normalizeClass(`innerLink ${_ctx.message.id == `unsent` || _ctx.isMe ? `whiteColor` : `skyblue`}`),
                    target: "_blank",
                    rel: "noopener noreferrer",
                    style: {"text-decoration":"underline"},
                    onClick: ($event: any) => (_ctx.clickInnerMessage(text))
                  }, _toDisplayString(text), 11, _hoisted_2)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(text), 1))
          ]))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}