
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { Logger } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import IconGear from "packs/components/icons/IconGear.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: { CalendarIcon, IconGear, IconHelp, Switcher, UserIcon, truncate },
})
export default class CalendarThumbnailContent extends Vue {
    @Prop() // func
    clickChangeName

    @Prop() //func 自動<>手動切替
    clickChangeAutoMode

    isSP = isMobile(window.navigator).phone
    href = ``
    cm = CalendarManager
    pdm = PossibleDatesManager
    rcm = RoomCreateManager

    Util = Util
    truncate = truncate
    selectedTabName = `list`

    editAstag() {
        this.cm.editStartAstag()
        this.$vfm.open(`EditCalendarTagModal`)
    }

    get showTimezone() {
        if (!this.cm.rs) return false
        if (!this.cm.rs.timezone_value) return false
        return this.cm.rs.timezone_value
    }

    // OptionalSchedulesのみを日程提案候補としてみます.（astagのみ設定可能.）
    // changeOnlyUseOptionalSchedules() {
    //     if (!this.cm.astag) return

    //     if (this.cm.astag.is_only_use_optional_schedules) {
    //         this.cm.astag.is_only_use_optional_schedules = false
    //     } else {
    //         this.cm.astag.is_only_use_optional_schedules = true
    //     }
    //     this.clickChangeAutoMode
    // }

    // public openModalEditCalendarTag(ctag: CalendarTag) {
    //     this.editCtag = this.cm.editStartCtag(ctag)
    //     // this.selectedCtag = ctag;
    //     // this.selectedCtag.systemUpdatedAt = Util.getSec()
    //     this.$vfm.open(`EditCalendarTagModal`)
    // }
}
