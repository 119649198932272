
// モジュールの読込.
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({
    components: {},
})
export default class MdProgressSpinner extends Vue {
    // data:
    @Prop()
    width: string

    get customStyle() {
        return this.width ? `width:${this.width}px;height:${this.width}px` : `width: 60px; height: 60px`
    }
}
