
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import ZoomSelectContent from "packs/components/calendar/ZoomSelectContent.vue"
import SelectLocationsList from "packs/pages/link/parts/room_settings/SelectLocationsList.vue"

// モデルを読込.
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"
import LocationTag from "packs/models/LocationTag"
import { DateTime } from "luxon"
import CalendarTag from "packs/models/CalendarTag"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Const from "packs/utils/Const"
import Appointment from "packs/models/Appointment"

@Options({
    components: {
        TextfieldTag,
        MdTextfieldTag,
        SelectTag,
        ZoomSelectContent,
        SelectLocationsList,
    },
})
export default class LocationSelectContent extends Vue {
    @Prop() // デフォルトで選択している場所名
    locationName: string

    @Prop()
    astag: AvailableScheduleTag

    @Prop()
    maxLength: number

    // data:

    cm = CalendarManager
    Util = Util
    Const = Const

    loading = false

    location = ""
    showList = false
    appo = Appointment.createDefault()
    loctags: LocationTag[] = null
    zoomLoctags: CalendarTag[] = null
    teamsLoctags: CalendarTag[] = null

    currentZoom: CalendarTag = null
    defaultZoomEmail = null

    selectedZooms: CalendarTag[] = null
    unselectedZooms: CalendarTag[] = null

    selectedTeams: CalendarTag[] = null
    unselectedTeams: CalendarTag[] = null

    created() {}

    mounted() {
        this.location = this.locationName
        this.updateLoctags()
    }

    updateLoctags() {
        if (Util.isBlank(this.loctags)) {
            this.cm.getLocations().then(_loctags => {
                this.loctags = _loctags
            })
        }

        this.cm.getOnlineLocations([`zoom`, `teams`]).then(_loctags => {
            Logger(
                `updateLoctags:cm.getOnlineLocations: zooms: ${Util.output(this.cm.zoomLoctags)}, teams: ${Util.output(
                    this.cm.teamsLoctags
                )}`
            )
            this.zoomLoctags = this.cm.zoomLoctags
            this.teamsLoctags = this.cm.teamsLoctags
            this.updateZooms()
        })
    }

    clickTextfield() {
        this.showList = true
    }

    blurTextfield() {
        sleep(500).then(_ => {
            this.showList = false
        })
    }

    public inputLocation(e) {
        Logger(`input Title: ${e}`)
        this.location = e
        this.updateLocation()
    }

    public selectLocations(loctags: LocationTag[]) {
        Logger(`${funcName()} ${Util.output(loctags)}`)
        if (Util.isBlank(loctags)) {
            this.location = ``
        } else {
            let loctag = loctags[0]
            this.location = loctag.keyword
        }
        this.updateLocation()
        this.showList = false
        // this.$emit(`selectLocations`, loctags)
    }

    public updateZooms() {
        Logger(`LocationSelectContent:updateZooms: zooms: ${this.zoomLoctags}, astag: ${Util.output(this.astag)}`)
        if (!this.astag) {
            this.selectedZooms = []
            this.unselectedZooms = this.zoomLoctags

            this.selectedTeams = []
            this.unselectedTeams = this.teamsLoctags
            return
        }
        let onlines = [...(this.astag.online_meeting_resources || [])]

        Logger(`onlines: ${Util.output(onlines)}`)
        let zoomSearchKeys = onlines.map(z => z.search_key)
        // let selectedKeys = zoomSearchKeys.map(z => z.search_k)

        let unzooms = []
        for (let _zoom of this.zoomLoctags) {
            if (zoomSearchKeys.includes(_zoom.search_key)) {
            } else {
                unzooms.push(_zoom)
            }
        }
        this.selectedZooms = onlines
        this.unselectedZooms = unzooms

        let teamsOnline = [...(this.astag.online_meeting_resources || [])]

        let teamsSearchKeys = teamsOnline.map(z => z.search_key)

        let unTeams = []
        for (let _teams of this.teamsLoctags) {
            if (teamsSearchKeys.includes(_teams.search_key)) {
            } else {
                unTeams.push(_teams)
            }
        }
        Logger(`selectedTeams:${Util.output(teamsOnline)}, unTeams:${Util.output(unTeams)}`)
        this.selectedTeams = teamsOnline
        this.unselectedTeams = unTeams
    }

    updateZoomAccounts(selectedZooms: CalendarTag[], unselectedZooms: CalendarTag[]) {
        Logger(`ConnectZoomModal:updateZoomAccounts: selectedZooms: ${Util.output(selectedZooms)}`)
        this.selectedZooms = selectedZooms
        this.unselectedZooms = unselectedZooms
        this.updateLocation()
    }

    updateTeamsAccounts(selectedTeams: CalendarTag[], unselectedTeams: CalendarTag[]) {
        Logger(`${funcName()} selectedZooms: ${Util.output(selectedTeams)}`)
        this.selectedTeams = selectedTeams
        this.unselectedTeams = unselectedTeams
        this.updateLocation()
    }

    // 親にアップデートします.
    public updateLocation() {
        if (![Const.zoomConnectAccount, Const.teamsConnectAccount].includes(this.location)) {
            this.selectedZooms = null
        }
        if (this.location == Const.zoomConnectAccount) {
            this.$emit(`update`, this.location, this.selectedZooms)
        } else if (this.location == Const.teamsConnectAccount) {
            this.$emit(`update`, this.location, this.selectedTeams)
        } else {
            this.$emit(`update`, this.location)
        }
    }

    deleteLocation() {
        Logger(`場所を削除しました`)
        this.location = ``
        this.updateLocation()
    }
}
