
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { copyToClipboard, funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import ZapierNotification from "packs/models/ZapierNotification"
import Notice from "packs/models/Notice"
import CustomNotification from "packs/models/CustomNotification"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        SelectTag,
        MdTextfieldTag,
        CheckboxTag,
    },
})
export default class SalesforceNotificationContent extends Vue {
    @Prop()
    noti: CustomNotification

    @Prop() // user, user_group, room_setting
    scope: string

    @Prop() // func
    showOptionModal

    // data:

    rm = RoomManager
    rcm = RoomCreateManager
    Util = Util
    notice = Notice

    isSP = isMobile(window.navigator).phone

    loading = false
    notiDic: CustomNotification = null
    showTip = false
    optionEventName = `管理画面Zapier 課金リクエスト`
    connectableNotis: CustomNotification[] = null
    availableEvents = null
    notificationSettings = null
    selectingEventDic = null
    registerType = `sf_events`
    CustomNotification = CustomNotification
    defaultEventSettingDic = { register_type: this.registerType, pipeline_name: ``, dealstage_name: `` }
    showRegisterForm = true
    truncate = truncate
    connectableLeadFields = null
    connectedLeadFields = null
    leadFieldDic = { name: ``, value: ``, sf_field_name: ``, sf_default_value: `` }

    mounted() {
        Logger(`${funcName()} mounted`)
        this.updateNoti()

        this.setupConnectableLeadFields()
    }

    requestSalesforce() {
        this.rm.showPlanOptionModalWithName = `管理画面Salesforce 課金リクエスト`
    }

    @Watch(`noti`, { deep: true })
    updateNoti() {
        if (!this.noti) return
        this.notiDic = { ...this.noti }
        this.notificationSettings = { ...this.notiDic.settings }
        this.connectedLeadFields = this.notificationSettings.connected_lead_fields

        // if (!this.connectableNotis) {
        //     CustomNotification.getIntegNotifications(`salesforce`).then(dic => {
        //         Logger(`updateNoti Salesforce NOTIS: ${Util.output(dic)}`)
        //         let notis = dic.notifications || []
        //         this.connectableNotis = notis
        //         this.availableEvents = dic.available_events
        //     })
        // }
    }

    setupConnectableLeadFields() {
        // リード連携項目を表示します.
        let connectableLeadFields = [{ name: `waaqにない項目`, value: `not_in_waaq`, sf_default_value: ``, sf_field_name: `` }]
        if (Util.isPresent(this.rcm.fields)) {
            let fs = [...this.rcm.fields]
            for (let _f of fs) {
                if ([`attendee1_name`, `attendee1_email`, `attendee1_company_name`, `attendee1_tel`].includes(_f.name_attr))
                    continue

                connectableLeadFields.push({
                    name: _f.field_name,
                    value: _f.public_id,
                    sf_default_value: ``,
                    sf_field_name: ``,
                })
            }
        }
        this.connectableLeadFields = connectableLeadFields
    }

    goSalesforce() {
        Logger(`${funcName()} goSalesforce`)
        window.open(`/connect/salesforce`)
    }

    removeSalesforce() {
        CustomNotification.delete(`salesforce`, `user`).then(e => {
            Logger(`AFTER DELETE SALESFORCE NOTI: ${Util.output(e)}`)
            this.notiDic = CustomNotification.createDefault(`user`, `salesforce`)
            this.$emit(`updateNotification`, this.notiDic, `salesforce`)
        })
    }

    selectSalesforce(e) {
        Logger(`${funcName()} e: ${Util.output(e)}`)
        let noti = this.connectableNotis.find(n => n.settings.provider_account_id == e)
        if (noti) {
            this.notiDic = noti
            this.notificationSettings = { ...this.notiDic.settings }
            this.connectedLeadFields = this.notificationSettings.connected_lead_fields
            this.$emit(`updateNotification`, this.notiDic, `salesforce`)
        }
    }

    connectSalesforce() {
        // 公開ページ紐づけ
        if (this.availableEvents) {
            if (this.connectableNotis && this.connectableNotis.length == 1) {
                this.notiDic = this.connectableNotis[0]
                this.notificationSettings = { ...this.notiDic.settings }
                this.connectedLeadFields = this.notificationSettings.connected_lead_fields
                this.$emit(`updateNotification`, this.notiDic, `salesforce`)
            }
        } else {
            CustomNotification.getIntegNotifications(`salesforce`).then(dic => {
                Logger(`SALESFORCE NOTIS: ${Util.output(dic)}`)
                let notis = dic.notifications || []
                this.connectableNotis = notis
                if (notis && notis.length == 1) {
                    this.notiDic = notis[0]
                    this.notificationSettings = { ...this.notiDic.settings }
                    this.connectedLeadFields = this.notificationSettings.connected_lead_fields
                    this.$emit(`updateNotification`, this.notiDic, `salesforce`)
                }
                this.availableEvents = dic.available_events
            })
        }
    }

    disconnectSalesforce() {
        // 公開ページ紐づけ
        this.notiDic = CustomNotification.createDefault(`room_setting`, `salesforce`)
        this.$emit(`updateNotification`, null, `salesforce`)
    }

    get defaultEvent() {
        let timing = `fixed_appointment`
        let evDic = this.availableEvents.find(evdic => evdic.timing == timing)

        return evDic.name
    }

    selectEvent(name) {
        let evDic = this.availableEvents.find(evdic => evdic.name == name)
        if (evDic) {
            this.selectingEventDic = evDic
            // this.notificationSettings = { ...this.notiDic.settings }
            // this.notificationSettings.event = evDic.timing
            // if (this.notiDic.events)
        }
    }

    addEvent() {
        if (!this.selectingEventDic) return
        let evDic = this.selectingEventDic
        let currentEvents = this.notiDic.events || []
        if (currentEvents.includes(evDic.timing)) {
            Notice.message = `すでに追加済みのイベントです。`
            return
        }

        // 作成するイベントに登録します.
        currentEvents.push(evDic.timing)
        this.notiDic.events = currentEvents

        // 通知時の設定を保存します.
        this.defaultEventSettingDic.register_type = this.registerType
        this.notificationSettings = this.notificationSettings || {}
        this.notificationSettings[evDic.timing] = { ...this.defaultEventSettingDic }
        this.notiDic.settings = { ...this.notificationSettings }
        this.$emit(`updateNotification`, this.notiDic, `salesforce`)

        // 通知時の設定を初期化します.
        this.resetSelecting()
        this.showRegisterForm = false
        sleep(200).then(_ => {
            this.showRegisterForm = true
        })
    }

    resetSelecting() {
        this.selectingEventDic = null
        this.registerType = `sf_events`
        this.defaultEventSettingDic = { register_type: this.registerType, pipeline_name: ``, dealstage_name: `` }
    }

    selectRegisterType(name: string) {
        Logger(`${funcName()} name:${name}`)
        let types = CustomNotification.salesforceRegisterTypes()
        let typeDic = types.find(t => t.name == name)
        if (typeDic) {
            this.registerType = typeDic.value
        }
    }

    inputDealstageName(e) {
        this.defaultEventSettingDic.dealstage_name = e
    }

    inputPipelineName(e) {
        this.defaultEventSettingDic.pipeline_name = e
    }

    deleteSettingDic(ev) {
        this.notiDic.events = this.notiDic.events.filter(e => e != ev)
        this.notiDic.settings[ev] = null
        this.$emit(`updateNotification`, this.notiDic, `salesforce`)
    }

    public hideTooltip(e) {
        this.showTip = false
    }

    selectLeadField(e) {
        Logger(`${funcName()} e:${e}`)
        let lf = this.connectableLeadFields.find(cf => cf.name == e)
        Logger(`${funcName()} lf:${Util.output(lf)}`)
        if (!lf) return

        if (lf.value == `not_in_waaq`) {
            this.leadFieldDic.value = `not_in_waaq`
            this.leadFieldDic.name = `not_in_waaq`
        } else {
            this.leadFieldDic.value = lf.value
            this.leadFieldDic.name = lf.name
        }
    }

    removeLeadField(f) {
        this.connectedLeadFields = this.connectedLeadFields.filter(cf => cf.value != f.value)
    }

    inputSfFieldName(e) {
        this.leadFieldDic.sf_field_name = e
    }

    inputSfDefaultValue(e) {
        this.leadFieldDic.sf_default_value = e
    }

    addLeadField() {
        Logger(`${funcName()}`)
        if (Util.isBlank(this.leadFieldDic.sf_field_name)) return
        if (Util.isBlank(this.leadFieldDic.value)) return

        if (!this.connectedLeadFields) this.connectedLeadFields = []
        this.connectedLeadFields.push({ ...this.leadFieldDic })
        this.notificationSettings.connected_lead_fields = this.connectedLeadFields
        this.notiDic.settings = { ...this.notificationSettings }
        this.$emit(`updateNotification`, this.notiDic, `salesforce`)

        this.leadFieldDic = { name: ``, value: ``, sf_field_name: ``, sf_default_value: `` }
    }
}
