
// モジュールの読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import { ContentLoader } from "vue-content-loader"

@Options({
    components: { ContentLoader },
})
export default class PlanLoader extends Vue {
    // data:
}
