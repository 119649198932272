
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Const from "packs/utils/Const"
import UserFile from "packs/models/UserFile"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import TemplateUtil from "packs/utils/TemplateUtil"

// const roomStorage = new RoomStorage()

@Options({
    components: {},
})
export default class PopupInnerContent extends Vue {
    // data:
    @Prop()
    mag: MeetingAttendeesGroup

    notice = Notice

    rm = RoomManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    TemplateUtil = TemplateUtil
    truncate = truncate

    currentFile: UserFile = null // イメージプレビューをするファイル.
    description = null
    teamName = null
    mags: MeetingAttendeesGroup[] = null
    systemUpdatedAt = null
    currentMag: MeetingAttendeesGroup = null //選択中のmag

    profileText(mag: MeetingAttendeesGroup) {
        if (!mag || !mag.description || !mag.team_name) return ``

        let retText = `<div class='with_white_bg ddgrey pad20' style='width:320px;'>`
        if (mag.team_name) {
            retText += `<p class='fw600 w100 fs16 mb5'>${mag.team_name}</p>`
        }
        if (mag.description) {
            let linkTextArray = this.autoLink(mag.description, true)
            let linkText = ``
            for (let text of linkTextArray) {
                if (text.match(TemplateUtil.buttonRegex) || text.match(TemplateUtil.buttonRegexWithOptions)) {
                    let link = text.match(TemplateUtil.buttonLinkRegex)[0].replace(`(`, ``).split(`)`)[0]
                    let buttonText = text.match(TemplateUtil.buttonTextRegex)[0].replace(`[`, ``).replace(`]`, ``)
                    linkText += `<a
                                    href="${link}"
                                    class=" ${this.isSP ? `fs12` : `fs14`}"
                                    rel="noopener noreferrer nofollow"
                                    target="_blank"
                                    style="text-decoration:none;"
                                >
                                    <div class="w100 pad5 skyblueBorder l2 with_white_bg centered fw600 rad8 hoverGrey">
                                        <span class="">${buttonText}</span>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="clear"></div>
                                </a>
                                `
                } else if (text.match(TemplateUtil.regex)) {
                    linkText += `<span>
                                    <a
                                        href="${text}"
                                        class="innerLink skyblue fw600 ${this.isSP ? `fs12` : ``}"
                                        target="_blank"
                                        rel="noopener noreferrer nofollow"
                                        >${text}</a
                                    >
                                </span>`
                } else if (
                    text.match(TemplateUtil.telRegex1) ||
                    text.match(TemplateUtil.telRegex2) ||
                    text.match(TemplateUtil.telRegex3)
                ) {
                    linkText += `<span>
                                    <a
                                        href="tel:${text}"
                                        class="innerLink skyblue fw600 ${this.isSP ? `fs12` : ``}"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style="text-decoration: underline"
                                        >${text}</a
                                    >
                                </span>`
                } else {
                    linkText += `<span class="fw600" style="line-height: 1.6">${text}</span>`
                }
            }
            retText += `<p class='fw600 w100 fs11 mt10'>紹介文</p><p class='text-left fs13'>${linkText}<div class='clear'></div></p>`
        }
        retText += `</div>`

        return retText
    }

    autoLink(text: string, changeBr: boolean = false): string[] {
        if (Util.isBlank(text)) return []

        let processText = text
        if (changeBr) processText = text.replace(/\n/g, `<br />`)

        return TemplateUtil.processText(processText)
    }

    clickInnerMessage(text: string) {
        gtagClick(`AppealContent リンク`, `${text}`)
    }

    clickButtonLink(text: string) {
        gtagClick(`AppealContent ボタンリンク`, `${text}`)
        let link = text.match(TemplateUtil.buttonLinkRegex)[0].replace(`(`, ``).split(`)`)[0]
        let optionsText = text.match(TemplateUtil.buttonOptionsRegex) ? text.match(TemplateUtil.buttonOptionsRegex)[0] : null
        Logger(`${funcName()} optionsText:${optionsText}`)
        let optionsDic = {}
        if (Util.isPresent(optionsText)) {
            optionsText = optionsText.replace(`{`, ``).replace(`}`, ``)
            let optionsArr = optionsText.split(`,`)
            for (let op of optionsArr) {
                if (op.match(`=`)) {
                    let opKeyValue = op.split(`=`)
                    optionsDic[opKeyValue[0]] = opKeyValue[1]
                }
            }
            Logger(`${funcName()} optionsDic:${Util.output(optionsDic)}`)
            if (Object.keys(optionsDic).includes(`:target`)) {
                let target = optionsDic[`:target`]
                if (target.includes(`_blank`)) {
                    open(link, `_blank`)
                }
            }
        } else {
            location.href = link
        }
    }
}
