import { DateTime } from "luxon"
import AvailableScheduleTag from "./AvailableScheduleTag"
import Util from "packs/utils/Util"
import axios from "axios"
import { Logger, funcName } from "packs/common"
import Notice from "packs/models/Notice"
import ReportStat from "packs/models/ReportStat"
import NotificationControl from "packs/utils/NotificationControl"

interface SmReport {
    start_time: number
    total_send_num: number
}

export default class Report {
    static readonly typeDisplay: number = 1

    constructor(
        public id: string, //MeetingGroup_idが入る可能性があるので、ctag_idをみたい場合は、calendar_tag_idを参照すること.
        public report_id,
        public total_stats: ReportStat,
        public users_stats: ReportStat[],
        public type: string, // 今のところmonthly
        public start_time: number,
        public start: DateTime,
        public sm_report: SmReport
    ) {}

    static getReports(beforeMonth: number = 0): Promise<Report> {
        // const params = { calendar_tags: selectedCtags }
        // Getリクエストのパラムをオブジェクト化するオプション
        // const paramsSerializer = qs.stringify(params)
        return axios
            .get(`${Util.prefixUrl}/reports?before_month=${beforeMonth}`)
            .then(result => {
                Logger(`Report.${funcName()} result: ${Util.output(result.data)}`)
                let _report: Report = result.data.report
                const sm_report: SmReport = result.data.sm_report
                _report.total_stats = _report.total_stats as ReportStat
                _report.users_stats = _report.users_stats as ReportStat[]
                _report.start = DateTime.fromSeconds(_report.start_time)
                _report.sm_report = sm_report
                // let dailyEvents = {}

                return _report
            })
            .catch(err => {
                Logger(`Report.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }
}
