
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.
import MailPreviewPartsAppoTable from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsAppoTable.vue"
import MailPreviewPartsAnswerResults from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsAnswerResults.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: { MailPreviewPartsAppoTable, MailPreviewPartsAnswerResults },
})
export default class MailPreviewPartsPublicRoomTable extends Vue {
    @Prop()
    showFixedAppo: boolean

    @Prop()
    showAnswerResults: boolean

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    DateTime = DateTime

    created() {}

    mounted() {}
}
