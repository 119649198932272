import RoomMember from "./RoomMember"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import { DateTime } from "luxon"
import NotificationControl from "packs/utils/NotificationControl"

export default class FormFieldConverter {
    constructor(
        public id: string,
        public extract_type: string, // class_name / id / name

        public ext_name: string,
        public form_field_public_id: string,
        public form_field_name: string,
        public custom_form_id: string
    ) {}

    static createDefault(): FormFieldConverter {
        let now = Util.getSec()
        return new FormFieldConverter(null, `class_name`, null, null, null, null)
    }

    static fetchFromJson(loctagsJson: any): FormFieldConverter[] {
        let loctags = []
        if (!loctagsJson || loctagsJson.length == 0) return []

        for (let loctag of loctagsJson) {
            const _loctag: FormFieldConverter = loctag
            loctags.push(_loctag)
        }
        return loctags
    }

    /**
     * 招待したことのあるユーザーを取得してきます。
     * @param type [string] assign_form / public_room
     * @param id [string]
     */
    static getConverters(c_form_id: string): Promise<FormFieldConverter[]> {
        let params = { c_form_id: c_form_id }
        return axios
            .get(`${Util.prefixUrl}/auto_forms/form_field_converters`, { params: params })
            .then(res => {
                const _converters = res.data.form_field_converters
                if (!_converters) return []
                let converters = FormFieldConverter.fetchFromJson(_converters)
                return converters
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    // static save(conv: FormFieldConverter, sendType): Promise<FormFieldConverter> {
    //     let endpoint = ""

    //     // appointment["parent_room_id"] = ""
    //     Logger(`sendType: ${sendType} appo: ${Util.output(conv)}`)

    //     return axios({
    //         method: sendType,
    //         url: `${Util.prefixUrl}/user_settings/location_converters`,
    //         data: conv,
    //     })
    //         .then(res => {
    //             Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
    //             if (res.data.message) {
    //                 Notice.message = `${res.data.message}`
    //             }
    //             const _conv: FormFieldConverter = FormFieldConverter.fetchFromJson([res.data.location_converter])[0]

    //             return _conv
    //         })
    //         .catch(err => {
    //             Logger(`err: ${err.message} ${Util.output(err.response)}`)
    //             NotificationControl.showErrorMessage(err)
    //             return null
    //         })
    // }

    // static delete(conv: FormFieldConverter) {
    //     let endpoint = `location_converters`

    //     let params = { id: conv.id }

    //     return axios
    //         .delete(`${Util.prefixUrl}/user_settings/${endpoint}`, { data: params })
    //         .then(res => {
    //             Logger(`res: ${res.data.message}`)
    //             Notice.message = `${res.data.message}`

    //             const _conv: FormFieldConverter = FormFieldConverter.fetchFromJson([res.data.location_converter])[0]
    //             if (_conv) {
    //                 return _conv
    //             } else {
    //                 return null
    //             }
    //         })
    //         .catch(err => {
    //             Logger(`err: ${err.message} ${Util.output(err.response)}`)
    //             NotificationControl.showErrorMessage(err)
    //             return null
    //         })
    // }

    static isSame(a: FormFieldConverter, b: FormFieldConverter): boolean {
        return a.id == b.id && a.ext_name == b.ext_name && a.form_field_public_id == b.form_field_public_id
    }
}
