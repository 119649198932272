
// モジュールを読込.
import { Options, Prop, Vue, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, onlyUnique } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import UserSettingsHeader from "packs/pages/link/settings/UserSettingsHeader.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import AddressBook from "packs/models/AddressBook"
import UserSetting from "packs/models/UserSetting"

// const roomStorage = new RoomStorage();

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        UserSettingsHeader,
        CheckboxTag,
    },
})
export default class UserSettingsNotification extends Vue {
    // data:
    notice = Notice

    rm = RoomManager
    util = Util
    selectedTab = `notification`

    isSP = isMobile(window.navigator).phone
    timings = Util.timings(true)
    additionalTimings = Util.additionalTimings()
    // users: RoomMember[] = [];

    loading = false
    invitedUsers: RoomMember[] = null
    userInfo = null
    myUser: RoomMember = null //コンタクトリストにある情報を使うため.
    myNotifications: string[] = null

    @Ref()
    header

    created() {
        if (!this.rm.userInfo) {
            this.rm.getAvailableSchedule().then(res => {
                Logger(`UserSettingsNotification1`)
                this.checkChukaiFreePlan()
                let userInfo = this.rm.userInfo
                this.userInfo = userInfo
                let notis = this.rm.my_notifications
                if (!notis) {
                    notis = [`confirming_open_mail`, `confirming_loggin_room`]
                }
                this.myNotifications = notis
            })
        } else {
            Logger(`UserSettingsNotification2`)
            this.checkChukaiFreePlan()
            let userInfo = this.rm.userInfo
            this.userInfo = userInfo
            let notis = this.rm.my_notifications
            if (!notis) {
                notis = [`confirming_open_mail`, `confirming_loggin_room`]
            }
            this.myNotifications = notis
        }
    }

    mounted() {
        gtagPage("#/settings/notification")
    }

    public checkChukaiFreePlan() {
        this.rm.redirectTopIfChukaiFreePlan(`UserSettingsInfo1`)
    }

    public saveNotification() {
        this.rm.startProgress()
        if (this.loading) return
        this.loading = true
        // this.myUser.name = this.userInfo.name
        // this.myUser.company_name = this.userInfo.company_name
        // this.myUser.short_name = this.userInfo.short_name
        // this.myUser.email = this.userInfo.email
        UserSetting.saveMyNotifications(this.myNotifications).then(res => {
            this.loading = false
            this.rm.endProgress()
            if (res) {
                this.myNotifications = res
            }
        })

        // gtagClick(`出席者下から出席者を追加`, `出席者:${this.members.length}`);
    }

    /**
     * dic [<string, any>] keys=checked,value
     */
    public clickTiming(dic) {
        Logger(`${funcName()} dic:${Util.output(dic)}`)
        let timings = [...this.myNotifications]
        if (dic.checked) {
            // 追加.
            timings.push(dic.value)
        } else {
            // 削除.
            timings = this.myNotifications.filter(e => e != dic.value)
            timings = timings.filter(onlyUnique)
        }
        this.myNotifications = timings
        Logger(`this.myNotifications: ${Util.output(this.myNotifications)}`)
    }
}
