
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"

// コンポーネントを読込.
import IconHelp from "packs/components/icons/IconHelp.vue"

// import "fullcalendar";
import Event from "packs/models/Event"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import Const from "packs/utils/Const"

@Options({
    components: { IconHelp },
})
export default class EventMenu extends Vue {
    // data:
    @Prop()
    ev: Event

    Util = Util
    cm = CalendarManager

    mounted() {
        // DailyLineをクリック時にイベントを作成するイベント処理を追加します。
        // 他のDailyLineと区別するためにidを指定します。
    }

    /**
     * イベントを追加/可能日時に変更/不可日時に変更
     * @param type [string] cal,available,notAvailable,availableForce
     */
    addEvent(type: string) {
        Logger(`addEvent ${type}`)
        this.$emit(`addEvent`, type)
    }
}
