
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import UserFileImage from "packs/components/icons/UserFileImage.vue"

// モデルを読込.
import Util from "packs/utils/Util"
import RoomManager from "packs/models/RoomManager"
import UserFile from "packs/models/UserFile"
import Notice from "packs/models/Notice"

@Options({ components: { UserFileImage } })
export default class SelectableImage extends Vue {
    // data:
    @Prop()
    file: UserFile

    @Prop()
    width: number

    @Prop()
    selected: boolean

    @Prop()
    canDelete: boolean

    loading = false
    isDrag: string = null
    fileForm: any = null

    mouseoverImage = false
    truncate = truncate

    public created() {}

    public mounted() {}

    public showChangeImage() {
        Logger(`${funcName()} filename:${this.file.original_filename}`)
        this.mouseoverImage = true
        this.$emit("mouseoverImage", this.file)
    }

    public hideChangeImage() {
        this.mouseoverImage = false
        this.$emit("mouseleaveImage")
    }

    public change() {
        this.$emit("change", this.file)
    }

    public deleteImage() {
        this.$emit(`deleteImage`, this.file)
    }

    // get memberName() {
    //     // const mem = this.getMember();
    //     const mem = this.currentMem
    //     if (mem) return mem.name
    //     return ``
    //     // return this.message.owner.name
    // }

    // get memberCompanyName() {
    //     // const mem = this.getMember();
    //     const mem = this.currentMem
    //     if (mem) return mem.company_name
    //     return ``
    //     // return this.message.owner.company_name
    // }
}
