
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { GChart } from "vue-google-charts"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import ChukaiSidebarMenu from "packs/pages/link/ChukaiSidebarMenu.vue"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import ModalRoomMembersLink from "packs/pages/link/modals/ModalRoomMembersLink.vue"
import PublicLinkModal from "packs/pages/link/modals/PublicLinkModal.vue"
import RoomCell from "packs/pages/link/parts/RoomCell.vue"
import UserStatusCell from "packs/pages/link/parts/UserStatusCell.vue"
import RoomHistoryDetailModal from "packs/pages/link/modals/RoomHistoryDetailModal.vue"
import RoomsLoader from "packs/components/loader/RoomsLoader.vue"
import SuggestedRooms from "packs/pages/link/parts/room_summary/SuggestedRooms.vue"
import DownloadConditionsContent from "packs/pages/link/parts/room_summary/DownloadConditionsContent.vue"
import ModalCancelAppointment from "packs/pages/link/modals/ModalCancelAppointment.vue"
import CreateFeedbackModal from "packs/pages/link/parts/room_summary/CreateFeedbackModal.vue"
import CreateFromDraftModal from "packs/pages/link/modals/CreateFromDraftModal.vue"
import AnsweredFormModal from "packs/pages/link/parts/room_summary/AnsweredFormModal.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import FixTabRoomCellsContent from "packs/pages/link/parts/room_summary/FixTabRoomCellsContent.vue"
import PrivateMemoModal from "packs/pages/link/modals/PrivateMemoModal.vue"
import DownloadableFileCell from "packs/pages/link/settings/DownloadableFileCell.vue"
import InputFileNameModal from "packs/pages/link/settings/InputFileNameModal.vue"
import ShareAuthModal from "packs/pages/link/modals/ShareAuthModal.vue"
import StatsContent from "packs/pages/link/parts/room_summary/StatsContent.vue"
import ChukaiMenuModal from "packs/pages/link/parts/room_summary/ChukaiMenuModal.vue"
import RemindModal from "packs/pages/link/modals/RemindModal.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import VotedRoomManager from "packs/models/VotedRoomManager"
import RoomTab from "packs/models/RoomTab"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import UserFile from "packs/models/UserFile"
import DateTag from "packs/models/DateTag"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        ChukaiSidebarMenu,
        RoomCell,
        UserStatusCell,
        ModalRoomMembersLink,
        RoomHistoryDetailModal,
        GChart,
        RoomsLoader,
        SuggestedRooms,
        PublicLinkModal,
        ModalCancelAppointment,
        DownloadConditionsContent,
        CreateFeedbackModal,
        CreateFromDraftModal,
        AnsweredFormModal,
        CheckboxTag,
        FixTabRoomCellsContent,
        PrivateMemoModal,
        DownloadableFileCell,
        InputFileNameModal,
        ShareAuthModal,
        StatsContent,
        ChukaiMenuModal,
        RemindModal,
    },
})
export default class RoomSummary extends Vue {
    // data:

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    mem = RoomMember
    isSP = isMobile(window.navigator).phone
    modalCloseRoom: Room = null

    dtag = null

    room: Room = null
    selectedRoom: Room = null
    selectedMember: RoomMember = null
    members = []
    members_with_links = []
    hm = RoomHistoryManager
    loading = false
    Util = Util
    stats = null
    modalType: string = null

    selectedTab = "pv" // pv/fixed/notFixed
    currentTabRooms: Room[] = null
    suggestedMembers: RoomMember[] = null
    suggestedDates: PossibleDate[] = null
    mouseoverIndex: number = -1
    pdm = PossibleDatesManager
    isPubPage = Util.isPubSummary()
    showVotedAndNotVoted = false
    // publicTabDic = RoomTab.createSummaryTab()
    publicRoomTabInfo = {
        fixed: RoomTab.createDefault(`all`, `upcoming_fixed`),
        // active_in_progressは現在一括取得のため、ウォッチ不要.
        active_in_progress: RoomTab.createDefault(`all`, `upcoming_fixed`),
        closed: RoomTab.createDefault(`all`, `closed`),
    }
    blockingTabs = false

    downloadableFiles: UserFile[] = null
    currentFile: UserFile = null
    totalDeviceNum = 0
    totalFixedDeviceNum = 0

    displayChukaiModalMenuType = null

    // リマインド用
    selectedUsers: RoomMember[] = []
    selectedRooms: Room[] = []

    public created() {
        this.selectedTab = roomStorage.fetchSummaryTab()
        let _room = roomStorage.fetchRoom() || this.rm.currentRoom || Room.createDefaultRoom()
        const roomKey = roomStorage.fetchSelectedRoomKey()
        Logger(`${funcName()} _room.id:${_room.id}, this.roomKey:${roomKey}, selectedTab:${this.selectedTab}`)
        // this.members = [..._room.attendees] || []
        // this.room.members = this.members

        let roomType = this.isPubPage ? `public_room` : _room.room_type
        let roomId = _room.id

        if (this.isPubPage && Util.isPresent(_room)) {
            if (_room.room_type == `parent_room`) {
                roomId = _room.public_room_id
            }
        }
        Logger(`isPubPage: ${this.isPubPage}, ${Util.output(_room)}`)

        if (!this.rm.astag) {
            this.rm.getAvailableSchedule().then(e => {
                this.rm.redirectTopIfChukaiFreePlan()
                if (Util.isBlank(roomId)) {
                    this.$router.push(`/`)
                    return
                }

                this.getRoomAndDisplay(roomId, roomType, `active_in_progress`)
            })
        } else {
            this.rm.redirectTopIfChukaiFreePlan()
            // showのときしか入っていないパラメータがあるため、詳細情報を取得します。
            this.getRoomAndDisplay(roomId, roomType, `active_in_progress`)
        }
    }

    /**
     * 調整ページに紐づく情報を取得してきます.
     */
    getRoomAndDisplay(roomId, roomType, tabName = `active_in_progress`, page = null) {
        Logger(`${funcName()} roomId:${roomId}, roomType:${roomType}, tabName:${tabName}, page:${page}`)
        this.updateDownloadableFiles()

        this.rm.startProgress()
        // showのときしか入っていないパラメータがあるため、詳細情報を取得します。
        this.rm.getRoom(roomId, roomType, tabName, page).then(__room => {
            this.loading = false
            this.blockingTabs = false
            this.rm.endProgress()

            this.fetchParams(__room, tabName)

            // 新たに取得した情報があるため、最終結果を保存しておきます.
            roomStorage.save(this.room)

            this.selectTab(this.selectedTab)
        })
    }

    fetchParams(__room: Room, tabName: string) {
        Logger(`${funcName()} tabName:${tabName}`)
        if (Util.isBlank(__room)) return

        let _room: Room
        if (this.room && this.room.id == __room.id) {
            _room = this.room
        } else {
            _room = __room
        }

        if (__room.room_type == `public_room`) {
            if (tabName == `upcoming_fixed`) {
                let _rooms = []
                let parentIds = []
                if (Util.isPresent(_room.rooms_fixed)) {
                    _rooms = _room.rooms_fixed
                    parentIds = _rooms.map(r => r.id)
                }
                let fixedRooms = Room.fetchFromJson(__room.rooms_fixed, this.rm.userInfo.user_id) || []
                for (let _r of fixedRooms) {
                    if (parentIds.includes(_r.id)) continue

                    _rooms.push(_r)
                    parentIds.push(_r.id)
                }
                let tabInfo = __room.current_tab_info as RoomTab
                // Array.prototype.push.apply(_rooms, fixedRooms)
                _room.rooms_fixed = _rooms
                this.publicRoomTabInfo.fixed = tabInfo
            } else if (tabName == `active_in_progress`) {
                let notFixedRooms = Room.fetchFromJson(__room.rooms_voted, this.rm.userInfo.user_id) || []
                let notVotedRooms = Room.fetchFromJson(__room.rooms_not_voted, this.rm.userInfo.user_id) || []
                // 日程投票しているルーム
                _room.rooms_not_voted = notVotedRooms
                // 投票していないルーム
                _room.rooms_voted = notFixedRooms
            } else if (tabName == `closed`) {
                let _rooms = []
                if (Util.isPresent(_room.rooms_closed)) {
                    _rooms = _room.rooms_closed
                }
                let closedRooms = Room.fetchFromJson(__room.rooms_closed, this.rm.userInfo.user_id) || []
                let tabInfo = __room.current_tab_info as RoomTab
                Array.prototype.push.apply(_rooms, closedRooms)
                _room.rooms_closed = _rooms
                this.publicRoomTabInfo.closed = tabInfo
            }
        }

        this.room = _room
        this.members = [...this.room.attendees] || []
        this.room.members = this.members
    }

    public mounted() {
        gtagPage(`${this.isPubPage ? `#/pub_summary` : `#/summary`}`)
        // this.room = this.rm.currentRoom;
    }

    public beforeUnmount() {
        this.vrm.resetManager()
    }

    @Watch(`rm.downloadableFiles`)
    updateDownloadableFiles() {
        let files = this.rm.downloadableFiles || []
        if (this.room) {
            this.downloadableFiles = files.filter(_f => _f.public_room_id == this.room.id)
        }
    }

    /**
     * セルをクリックしたら調整ページに遷移.
     */
    public goRoomFromTr(room: Room) {
        Logger("ごールーム")
        gtagClick(`summary > 調整ページへ`, `${room.members.length}人 [${room.title}] rid:${room.room_id}`)
        if (room.status == -10) return false

        if (room.room_type == `public_room`) {
            if ([`suspended`, `draft`].includes(room.public_status)) {
                return
            }
            gtagClick(`公開ページへ`, `[${room.appeal_content ? room.appeal_content.title : ``}] pubid:${room.id}`)
            open(`${room.page_url}`, `_blank`)
        } else {
            gtagClick(`限定公開ページへ`, `${room.members.length}人 [${room.title}] rid:${room.room_id}`)
            this.goRoom(room)
        }
    }

    /**
     * 限定公開ページからもとの公開ページへ遷移します.
     */
    goSummaryFromParent() {
        let pubId = this.room.public_room_id
        if (!pubId) return
        if (this.loading) return
        this.loading = true
        // 今の限定公開ページがあるタブに移動
        this.$router.push("/pub_summary")
        this.isPubPage = true
        this.getRoomAndDisplay(pubId, `public_room`)
    }

    public goSummary(room: Room) {
        this.rm.updateCurrentInfo(room)
        if (!room.is_owner_group) return
        roomStorage.save(room)
        roomStorage.saveAppointment(room.current_appointment)
        roomStorage.saveSelectedRoomKey(room.keyId)
        this.room = room
        if (room.room_type == `public_room`) {
            this.isPubPage = true
            this.$router.push("/pub_summary")
        } else {
            this.isPubPage = false
            this.$router.push("/summary")
        }
    }

    public clickSummary(room: Room, tabName: string = null) {
        gtagClick("セルから進捗確認", `進捗管理 ${room.members.length}人 [${room.title}] rid:${room.room_id} tabName:${tabName}`)
        if (room.room_type == `public_room`) {
            if (Util.isPresent(tabName)) {
                this.selectTab(tabName)
            } else {
                this.selectTab("pv")
            }
            return
        }
        this.goSummary(room)
    }

    // メッセージページに遷移.
    public goRoom(room) {
        this.rm.updateCurrentInfo(room)
        roomStorage.saveSelectedRoomKey(room.keyId)
        roomStorage.saveSelectedParentRoomId(room.id)
        this.$router.push("/schedule")
        // this.$router.push("/messages");
    }

    public goProgresses() {
        Const.paidWithPath(this.rm, `/progresses`, "AdPromBusinessModal", this)
    }

    public showMembersLinks(members, room) {
        this.members_with_links = []
        this.members_with_links = members
        this.selectedRoom = room
        this.$vfm.open("RoomMembersLinkModal")
    }

    public showPublicLink(room: Room) {
        Logger(`showPublicLink:::`)
        this.selectedRoom = room
        this.$vfm.open("PublicLinkModal")
    }

    public showHistory(room: Room) {
        this.room = room
        this.$vfm.open("RoomHistoryDetailModal")
    }

    public selectTab(tabName: string) {
        if (!this.room) return
        Logger(`${funcName()} tabName:${tabName}, room:${Util.output(this.room)}`)

        this.selectedTab = tabName
        this.currentTabRooms = null
        if (tabName == `fixed`) {
            let _rooms = this.room.rooms_fixed
            let currentTab = this.publicRoomTabInfo[this.selectedTab]
            if (this.isPubPage && (!_rooms || (currentTab && !currentTab.page))) {
                // 取得してきます.
                let roomType = this.isPubPage ? `public_room` : this.room.room_type
                let roomId = this.room.id
                this.getRoomAndDisplay(roomId, roomType, `upcoming_fixed`)
            } else {
                this.currentTabRooms = RoomTab.sortRoomsWithTabName(_rooms, `upcoming_fixed`)
            }
        } else if (tabName == `voted`) {
            let _rooms = this.room.rooms_voted
            this.currentTabRooms = _rooms
            // if (!_rooms && this.isPubPage) {
            //     // 取得してきます.
            //     let roomType = this.isPubPage ? `public_room` : this.room.room_type
            //     let roomId = this.room.id
            //     this.getRoomAndDisplay(roomId, roomType, `upcoming_fixed`)
            // } else {
            //     this.currentTabRooms = RoomTab.sortRoomsWithTabName(_rooms, `upcoming_fixed`)
            // }

            // _rooms.map(r => r.members)
        } else if (tabName == `notVoted`) {
            this.showNotVotedWithVoted()
        } else if (tabName == `closed`) {
            let _rooms = this.room.rooms_closed
            if (!_rooms && this.isPubPage) {
                // 取得してきます.
                let roomType = this.isPubPage ? `public_room` : this.room.room_type
                let roomId = this.room.id
                this.getRoomAndDisplay(roomId, roomType, `closed`)
            } else {
                // this.currentTabRooms = RoomTab.sortRoomsWithTabName(_rooms, `closed`)
                this.currentTabRooms = _rooms
            }

            // _rooms.map(r => r.members)
        } else if ([`pv`].includes(tabName)) {
            this.currentTabRooms = []
        } else if ([`download`].includes(tabName)) {
            this.currentTabRooms = []
            this.getDownloadableFiles()
        }
        roomStorage.saveSummaryTab(tabName)
    }

    autoSeparateVotedOrNot(rooms: Room[]) {
        if (Util.isBlank(rooms)) return [[], []]
        let notVoted = []
        let voted = []
        for (let _r of rooms) {
            let mems = _r.members.filter(mem => mem.role != 100)
            for (let _mem of mems) {
                if (Util.isPresent(_mem.suggested_dates)) {
                    voted.push(_r)
                } else {
                    notVoted.push(_r)
                }
            }
        }
        return [voted, notVoted]
    }

    /**
     * 確定後に確定したルームと未確定のルームをアップデートします。
     */
    public fixedAppo(fixedRooms: Room[], notFixedRooms: Room[]) {
        Logger(`${funcName()} `)
        let _rooms = this.room.rooms_fixed || []
        Array.prototype.push.apply(_rooms, fixedRooms)
        this.room.rooms_fixed = _rooms
        // 前に追加.
        // Array.prototype.unshift.call(this.room.rooms_fixed, fixedRooms)
        let [voted, notVoted] = this.autoSeparateVotedOrNot(notFixedRooms)
        this.room.rooms_voted = voted
        this.room.rooms_not_voted = notVoted
        this.room.rooms_voted_num = (voted || []).length
        this.selectTab(this.selectedTab)
    }

    // 投票形式で確定した予定をキャンセルします。（モーダルを表示）
    public cancelVoted(room: Room) {
        this.selectedRoom = room
        this.$vfm.open(`CancelAppointmentModal`)
    }

    // 投票ではない通常の日程調整1つのroomをキャンセルします.
    cancelAppo(room: Room) {
        this.selectedRoom = room
        this.rm.updateCurrentInfo(room)
        this.$vfm.open(`CancelAppointmentModal`)
    }

    canceledRoom(_appo: Appointment) {
        if (!this.selectedRoom || Util.isBlank(_appo)) return

        let fixedRooms = [...this.room.rooms_fixed]
        if (_appo.status == -1) {
            // キャンセル
            let _room = fixedRooms.find(r => r.current_appointment.id == _appo.id)
            if (_room) {
                _room.status = Room.STATUS_ACTIVE_CANCELED
                _room.status_name = `キャンセル済`

                _room.current_appointment = _appo
            }
        } else if (_appo.status == 1) {
            // 再調整
            let _room = fixedRooms.find(r => r.id == this.selectedRoom.id)
            if (_room) {
                _room.status = Room.STATUS_ACTIVE_IN_PROGRESS
                _room.status_name = `調整中`
                _room.current_appointment = _appo
            }
        }

        this.setRoomsFromFixed(fixedRooms)
        this.selectTab(this.selectedTab)
    }

    /**
     * 確定している調整からキャンセル・再調整が起きた際に、それぞれのroomを振り分けます.
     */
    setRoomsFromFixed(fixedRooms: Room[]) {
        Logger(`${funcName()} fixedRooms:${fixedRooms.length}`)
        let fixed = fixedRooms.filter(_r => _r.current_appointment.status == 10)
        let notFixed = fixedRooms.filter(_r => ![10, -1].includes(_r.current_appointment.status))
        let [voted, notVoted] = this.autoSeparateVotedOrNot(notFixed)
        this.room.rooms_fixed = fixed
        Array.prototype.push.apply(this.room.rooms_voted, voted)
        Array.prototype.push.apply(this.room.rooms_not_voted, notVoted)
        this.room.rooms_voted_num = (this.room.rooms_voted || []).length
        // リフレッシュさせるために、キャンセルしたらpubroomをnullにします.
        this.vrm.publicRoom = null
    }

    rescheduledRoom(appo: Appointment) {
        this.canceledRoom(appo)
    }

    /**
     * 投票状況の更新があった場合に呼びます.
     */
    public updatedVotedRooms(success: boolean, dic: any) {
        Logger(`${funcName()} success:${success}, dic:${Util.output(dic)}`)
        this.$vfm.close(`CancelAppointmentModal`)
        if (success) {
            let _appo = dic.appo as Appointment
            let room_ids: string[] = dic.room_ids
            let fixedRooms = [...this.room.rooms_fixed]
            for (let _room of fixedRooms) {
                if (room_ids.includes(_room.id)) {
                    _room.current_appointment = _appo
                    _room.is_voted = false
                    if (_appo.status == -1) {
                        _room.status = Room.STATUS_ACTIVE_CANCELED
                        _room.status_name = `キャンセル済`
                    } else if (_appo.status == 1) {
                        _room.status = Room.STATUS_ACTIVE_IN_PROGRESS
                        _room.status_name = `調整中`
                    } else if (_appo.status == 10) {
                        _room.status = Room.STATUS_ACTIVE_UPCOMING_FIXED
                        _room.status_name = `調整済`
                        _room.is_voted = true
                        _room.updatedAtText = DateTag.getTimeStringSlashFromSeconds(DateTime.local().toSeconds())
                    }
                }
            }
            this.setRoomsFromFixed(fixedRooms)
            this.selectTab(this.selectedTab)
        }
    }

    /**
     * 調整ページを作成/削除/一括作成/一括削除するためのモーダルを表示します。
     * @param room [Room] コントロール対象のルーム.
     * 現在はtype: deleteのみ利用.
     * @param type [string] create, delete, createAll, deleteAll
     */
    public showRoomModal(room: Room, type: string) {
        this.selectedRoom = room
        this.modalType = type
        this.$vfm.open("CreateFromDraftModal")
    }

    /**
     *
     * @param room
     * @param mem
     * @param type shareOther/changePeriod
     */
    public showMenuModal(room: Room, mem: RoomMember, type: string) {
        Logger(`${funcName()} room:${room?.id}, mem:${mem?.id}, displayChukaiModalMenuType:${type}`)
        this.selectedRoom = null
        this.selectedMember = null
        this.displayChukaiModalMenuType = null

        sleep(200).then(_ => {
            this.selectedRoom = room
            this.selectedMember = mem
            this.displayChukaiModalMenuType = type

            this.$vfm.open("ChukaiMenuModal")
        })
    }

    closeDraftModal(success: boolean, room: Room) {
        if (!success) return
        Logger(`room終了に変更: type: ${this.modalType} ${Util.output(room)}`)
        if (this.modalType == `delete`) {
            let fixedRooms = [...(this.room.rooms_fixed || [])]
            fixedRooms = fixedRooms.filter(r => r.room_id != room.room_id)

            let notFixedRooms = [...(this.room.rooms_voted || [])]
            notFixedRooms = notFixedRooms.filter(r => r.room_id != room.room_id)
            this.room.rooms_fixed = fixedRooms
            this.room.rooms_voted = notFixedRooms
            this.room.rooms_voted_num = (notFixedRooms || []).length

            let closedRooms = [...(this.room.rooms_closed || [])]
            closedRooms = closedRooms.filter(r => r.room_id != room.room_id)

            room.is_expired = true
            room.status = room.status + 100
            room.status_name += `(終了)`

            closedRooms.push(room)
            this.room.rooms_closed = closedRooms
            // リフレッシュさせるために、キャンセルしたらpubroomをnullにします.
            this.vrm.publicRoom = null
            this.selectTab(this.selectedTab)
        }
    }

    // ユーザー選択時のアンケート作成.
    public createFeedback(member: RoomMember) {
        this.selectedRoom = { ...this.room }
        this.selectedMember = member
        this.$vfm.open(`CreateFeedbackModal`)
    }

    // 公開ページ押下時のアンケート作成
    public createFeedbackRoom(room: Room) {
        this.selectedRoom = { ...room }
        this.selectedMember = null
        this.$vfm.open(`CreateFeedbackModal`)
    }

    // 終了したページを戻します.
    changeActive(room: Room) {
        this.rm.startProgress()
        Room.changeStatus(room, `reopen`).then(success => {
            this.rm.endProgress()
            // 終了タブから消して、別タブに移行.
            if (success) {
                room.is_expired = false
                room.status = room.status - 100
                room.status_name = room.status_name.replace(`(終了)`, ``)
                room.expired_at = -1

                // クローズタブから削除
                let tab = this.rm.roomTabs[`self`][`closed`]
                if (tab.rooms) tab.rooms = tab.rooms.filter(r => r.room_id != room.room_id)
                let alltab = this.rm.roomTabs[`all`][`closed`]
                if (alltab.rooms) alltab.rooms = alltab.rooms.filter(r => r.room_id != room.room_id)

                if (this.room.room_type == `public_room`) {
                    // TODO:確定している場合は確定タブへ.
                    if (room.current_appointment.status == 10) {
                        if (Util.getSec() < room.current_appointment.start_time) {
                            this.room.rooms_fixed.push(room)
                            this.room.rooms_closed = this.room.rooms_closed.filter(r => r.id != room.id)
                            this.vrm.publicRoom = null
                            this.selectTab(this.selectedTab)
                        }
                    }

                    // TODO:調整中の場合は調整中タブへ.
                    if (room.current_appointment.status == 1) {
                        this.room.rooms_voted.push(room)
                        this.room.rooms_closed = this.room.rooms_closed.filter(r => r.id != room.id)
                        this.vrm.publicRoom = null
                        this.selectTab(this.selectedTab)
                    }
                }
            }
        })
    }

    clickFormMember(mem: RoomMember, room: Room) {
        this.selectedMember = mem
        this.selectedRoom = room
        // フォーム確認
        this.$nextTick(() => {
            this.$vfm.open(`AnsweredFormModal`)
        })
    }

    clickNotVotedWithVoted() {
        if (this.showVotedAndNotVoted) {
            this.showVotedAndNotVoted = false
        } else {
            this.showVotedAndNotVoted = true
        }
        this.showNotVotedWithVoted()
    }

    showNotVotedWithVoted() {
        Logger(`${funcName()} showVotedAndNotVoted:${this.showVotedAndNotVoted}`)
        if (this.showVotedAndNotVoted) {
            let voted = [...(this.room.rooms_voted || [])]
            let notVoted = [...(this.room.rooms_not_voted || [])]
            Array.prototype.push.apply(voted, notVoted)
            this.currentTabRooms = voted
        } else {
            let notVoted = [...(this.room.rooms_not_voted || [])]
            this.currentTabRooms = notVoted
        }
    }

    searchByMember(mem: RoomMember) {
        this.rm.nextActionInRoomsDic = {
            method: `searchByMember`,
            variables: { mem: mem },
        }
        this.goRooms()
    }

    searchByMag(mag: MeetingAttendeesGroup) {
        this.rm.nextActionInRoomsDic = {
            method: `searchByMag`,
            variables: { mag: mag },
        }
        this.goRooms()
    }

    goRooms() {
        this.$router.push(`/`)
    }

    /**
     * スクロールを検知して、下までいったらルーム情報を取得します。
     */
    public scroll() {
        if (!Util.isPubSummary()) return
        if (![`fixed`, `closed`].includes(this.selectedTab)) return

        // $(".roomTableContent").get(0).scrollHeight
        // Logger(`scroll: ${$(".roomTableContent").outerHeight()} ${window.innerHeight + window.scrollY}`)
        if ($(".roomTableContent").outerHeight() < window.innerHeight + window.scrollY) {
            Logger("下端までスクロール")
            let currentTab = this.publicRoomTabInfo[this.selectedTab]
            Logger(`${funcName()} currentTab:${Util.output(currentTab)}`)
            if (Util.isBlank(currentTab)) return

            if (currentTab.has_next) {
                if (this.blockingTabs) return
                // 取得中の切り替え防止.
                this.blockingTabs = true
                this.loading = true
                let roomType = this.isPubPage ? `public_room` : this.room.room_type
                let roomId = this.room.id
                let tabName = `active_in_progress`
                if (this.selectedTab == `fixed`) {
                    tabName = `upcoming_fixed`
                } else if (this.selectedTab == `closed`) {
                    tabName = `closed`
                }
                this.getRoomAndDisplay(roomId, roomType, tabName, currentTab.page + 1)
            } else {
                Logger(`これ以上取得するものはありません.`)
            }
            // this.selectCategoryTab(this.selectedTabName)
        }
    }

    editPrivateMemo(room: Room) {
        Logger(`${funcName()} `)
        this.selectedRoom = null
        this.selectedRoom = room
        sleep(500).then(_ => {
            this.$vfm.open(`PrivateMemoModal`)
        })
    }

    updatedPrivateMemo(memo, groupTags) {
        let room = this.currentTabRooms.find(r => r.id == this.selectedRoom.id)
        if (room) {
            Logger(`${funcName()} memo:${memo}`)
            room.private_memo = memo
        }
    }

    getDownloadableFiles() {
        Logger(`${funcName()}`)
        if (Util.isPresent(this.rm.downloadableFiles)) {
            let files = this.rm.downloadableFiles
            this.downloadableFiles = files.filter(_f => _f.public_room_id == this.room.id)
            return
        }
        UserFile.getDownloadableFiles().then(data => {
            if (data && data.files) {
                this.rm.downloadableFiles = data.files
            }
        })
    }

    editName(uf: UserFile) {
        this.currentFile = uf
        this.$vfm.open(`InputFileNameModal`)
    }

    renamed(uf: UserFile) {
        this.$vfm.close(`InputFileNameModal`)
        let files = this.rm.downloadableFiles
        let file = files.find(f => f.file_id == uf.file_id)
        if (file) {
            file.original_filename = uf.original_filename
            this.currentFile = null
        }
    }

    showShareAuthModal(room: Room) {
        this.selectedRoom = room
        this.$vfm.open(`ShareAuthModal`)
    }

    hideShareAuthModal() {
        this.selectedRoom = null
        this.$vfm.close(`ShareAuthModal`)
    }

    updateSelectedRoom(room: Room) {
        if (this.selectedRoom?.id == room?.id && this.room?.id == room?.id) {
            Logger(`${funcName()}`)
            this.selectedRoom = room
            this.room = room
        }
    }

    updateMember(mem: RoomMember) {
        if (this.selectedMember?.id == mem?.id) {
            this.selectedMember = mem
            this.vrm.updateRoomMember(mem)
        }
        ;(this.currentTabRooms || []).forEach(r => {
            if (r.id == mem.parent_room_id) {
                let _mem = r.members.find(m => m.id == mem.id)
                if (_mem) {
                    Object.assign(_mem, mem)
                }
            }
        })
    }

    clickRemind(mem: RoomMember, room: Room) {
        Logger(`${funcName()} mem:${mem.user_id} room:${room.id}`)
        this.selectedUsers = [mem]
        this.selectedRooms = [room]
        this.$nextTick(() => {
            this.$vfm.open("RemindModal")
        })
    }
}
