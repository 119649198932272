
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { Logger, funcName } from "packs/common"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import Notice from "packs/models/Notice"
import { gtagClick, gtagEvent } from "packs/GoogleTagManager"
import { VueFinalModal } from "vue-final-modal"

// コンポーネントを読込.
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LocationSelectContent from "packs/components/calendar/LocationSelectContent.vue"
import OneMagSmallContent from "packs/pages/link/parts/available_schedule/OneMagSmallContent.vue"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"
import LocationTag from "packs/models/LocationTag"
import Event from "packs/models/Event"
import { DateTime } from "luxon"
import SelectUtil from "packs/utils/SelectUtil"
import DateTag from "packs/models/DateTag"
import FormField from "packs/models/FormField"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Room from "packs/models/Room"
import Refresher from "packs/models/Refresher"
import NotificationControl from "packs/utils/NotificationControl"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import UserIcon from "packs/components/icons/UserIcon.vue"
import Appointment from "packs/models/Appointment"
import ChangeableDateTimeContent from "packs/components/calendar/ChangeableDateTimeContent.vue"

@Options({
    components: {
        CheckboxTag,
        LblueListContent,
        FlashNotice,
        TextfieldTag,
        TextareaTag,
        MdTextfieldTag,
        SelectTag,
        LocationSelectContent,
        UserIcon,
        OneMagSmallContent,
        VueFinalModal,
        MdProgressBar,
        ChangeableDateTimeContent,
    },
})
export default class CalendarEventModal extends Vue {
    @Prop() // event, custom_fix
    type: string
    // data:
    notice = Notice

    cm = CalendarManager
    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil

    changedSomeStatus = false // なにか変更があった場合に、適用ボタンが押せるようにする.
    usedCtags: CalendarTag[] = null
    ownerCtag: CalendarTag = null
    needNotify = false
    title = "打ち合わせ"
    memo = ""
    location = ""
    loctag: LocationTag = null
    isPrivate = false

    ev: Event = null

    date = ``

    loctags: LocationTag[] = null
    zoomLoctags: CalendarTag[] = null
    loading = false
    timeArray = SelectUtil.timeArray

    ctListWithoutSelect: CalendarTag[] = null

    currentZooms: CalendarTag[] = null

    // カスタム確定時にroomを利用します.
    room: Room = null
    appo: Appointment = null
    refresher = Refresher
    selectedMag: MeetingAttendeesGroup = null
    duration = null

    openModal() {
        this.room = { ...this.rm.currentRoom }
        let appo = this.room.current_appointment
        this.appo = appo
        this.duration = appo.duration
        this.updateMags()

        if (Util.isBlank(this.cm.ctList)) {
            // 取得.
            this.cm.getCalList().then(res => {
                this.updateCtList()
            })
        } else {
            this.updateCtList()
        }
    }

    get isCustomFixView() {
        return this.type == `custom_fix`
    }

    // @Watch(`cm.loctags`, { deep: true })
    // updateLoctags() {
    //     if (Util.isBlank(this.loctags)) {
    //         this.cm.getLocations().then(_loctags => {
    //             this.loctags = _loctags
    //         })
    //     }
    //     if (Util.isBlank(this.zoomLoctags)) {
    //         this.cm.getOnlineLocations().then(_loctags => {
    //             this.zoomLoctags = _loctags
    //         })
    //     }
    // }

    // @Watch(`cm.mags`, { deep: true })
    updateMags() {
        if (!this.cm.mags) return
        let mag = this.cm.mags.find(m => m.name == this.cm.displayMagName)

        if (this.room) {
            let appo = this.room.current_appointment
            let userId = this.room.owner.user_id
            if (appo.selected_mag_dic && appo.selected_mag_dic[userId]) {
                this.selectedMag = appo.selected_mag_dic[userId]
            } else {
                this.selectedMag = mag
            }
        }

        if (Util.isPresent(mag)) {
            this.usedCtags = [...(mag.required_attendees || [])]
            if (Util.isBlank(this.ownerCtag)) {
                let _ctag = this.usedCtags.find(c => c.writable)
                if (_ctag) {
                    this.ownerCtag = _ctag
                }
            }
        }
        Logger(`ownerCtagを指定しました1: ${Util.output(this.ownerCtag)}`)
        // オーナー本人のカレンダーを設定.
        if (Util.isBlank(this.ownerCtag)) {
            this.useOwnerCtag()
        }
        Logger(`ownerCtagを指定しました2: ${Util.output(this.ownerCtag)}`)
    }

    @Watch(`cm.displayMagName`)
    updateDisplayMagName() {
        this.updateMags()
    }

    useOwnerCtag() {
        if (Util.isBlank(this.rm.calendar_account_name)) return

        const _ctag = this.cm.getWritableCalendars().find(cal => cal.name === this.rm.calendar_account_name)
        if (_ctag) {
            this.ownerCtag = _ctag
        }
    }

    @Watch(`cm.selectedEvent`, { deep: true })
    updateEventInfo() {
        if (Util.isBlank(this.cm.selectedEvent)) return
        let ev = Event.copy(this.cm.selectedEvent)

        if (Util.isPresent(ev.title)) {
            this.title = ev.title
        }
        if (Util.isPresent(ev.calendar_id)) {
            let _ctag = this.cm.ctList.find(ct => ct.email == ev.calendar_id)
            if (_ctag) {
                this.ownerCtag = _ctag
            }
        }
        // オーナー本人のカレンダーを設定.
        if (Util.isBlank(this.ownerCtag)) {
            this.useOwnerCtag()
        }

        this.isPrivate = this.cm.astag.is_private
        this.ev = ev
        Logger(`CalendarEventModal.updateEventInfo ${Util.output(this.ev)}`)
    }

    updateCtList() {
        if (Util.isBlank(this.usedCtags)) {
            this.ctListWithoutSelect = [...(this.cm.ctList || [])]
            return
        }
        const ctagIds = this.usedCtags.map(c => c.id)
        this.ctListWithoutSelect = this.cm.ctList.filter(ct => !ctagIds.includes(ct.id))
    }

    selectMag(mag: MeetingAttendeesGroup) {
        this.selectedMag = mag
    }

    // 参加者を消す処理.
    public deleteContent(e) {
        // Logger(`delete el: ${e}, before: ${this.usedCtags}`);
        let ctags = [...(this.usedCtags || [])]
        let arr = ctags.filter(ct => ct.email != e)
        this.usedCtags = arr
        this.updateCtList()
    }

    get getWdayText() {
        if (Util.isBlank(this.cm.selectedEvent)) return
        if (Util.isBlank(this.ev)) return

        const wArray = DateTag.weekNames()

        // const dateFormat = this.cm.currentCandidateTime.date_format;
        // const [year, month, day, textArr] = dateSpliter(dateFormat);
        // var date = year + "-" + month + "-" + day;
        // var wDay = new Date(date).getDay();
        // return `(${wArray[wDay]})`;
        let wday = DateTime.fromSeconds(this.ev.start_time).weekday
        if (wday >= 7) wday -= 7
        return wArray[wday]
    }

    public get getLocationName() {
        if (Util.isPresent(this.rm.default_location)) {
            const locname = this.rm.default_location.name
            return locname
        }
        return ""
    }

    updateAppoTime(startTime: DateTime, endTime: DateTime, duration: number) {
        this.ev.start_time = startTime.toSeconds()
        this.ev.duration = duration
        this.duration = duration
    }

    public inputTitleText(e) {
        Logger(`input Title: ${e}`)
        this.title = e
    }

    public blurTitleText() {}

    public inputLocation(e) {
        Logger(`input Title: ${e}`)

        this.location = e
    }

    public inputMemo(e) {
        Logger(`input memo: ${e}`)
        this.memo = e
    }

    // ctListの中から、既に登録のあるアドレスを追加します。
    public addAttendees(e) {
        Logger(`select name: ${e}`)
        const ctList = this.cm.ctList
        let selectedCtag = null
        for (let ctag of ctList) {
            if (ctag.name == e) {
                selectedCtag = ctag
                selectedCtag.type = 1
                break
            }
        }
        if (selectedCtag) {
            let arr = [...(this.usedCtags || [])]
            arr.push(selectedCtag)
            this.usedCtags = arr
            this.updateCtList()
            Logger(`UPDATED:: this.usedCtags: ${this.usedCtags}`)
            $("#add_attends").val("")
        }
    }

    // テキストフィールドに入力して、エンターを押したときに新規でアドレスを（内部的に）登録します。
    public enterAttendee(e) {
        const text = e.target.value
        Logger(`enter: ${text}`)
        if (!Util.validateEmail(text)) {
            return
        }
        // 一時的に追加
        let _ctag = CalendarTag.createDefault()
        _ctag.name = text
        _ctag.email = text

        this.usedCtags.push(_ctag)
        this.updateCtList()
        $("#add_attends").val("")
    }

    public checkNotify(e) {
        $(e.target).toggleClass("checked")
        this.needNotify = $(e.target).hasClass("checked") ? true : false
    }

    hide() {
        this.$vfm.close(`CalendarEventModal`)
    }

    cancel() {
        this.hide()
    }

    // 限定公開ページで確定する場合はこちらを利用します.
    saveParentRoom() {
        let _room = { ...this.rm.currentRoom }
        let _appo = _room.current_appointment
        let startTime = this.ev.start_time
        let pdm = PossibleDatesManager
        gtagEvent(`カスタム確定`, `send`, `start: ${startTime}`)
        let nextstep = `complete`
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()
        if (this.selectedMag) {
            pdm.selectedMag = this.selectedMag
        }
        if (this.location) {
            _appo.location_tag_id = null
            _appo.location_name = this.location
        }
        if (this.duration && this.duration > 0) {
            _appo.duration = this.duration
        }
        _room.current_appointment = _appo

        FormField.send(_room, startTime, null, pdm, nextstep, true, true, null, this.currentZooms).then(res => {
            this.rm.endProgress()

            if (res) {
                // this.rm.userInfo = res.userInfo
                this.rm.setUserInfo(res.userInfo)
                let room = res.room as Room

                // 確定できずにnextAction==`reload`だった場合、モーダルを表示し、日程候補をリロードします.

                this.rm.updateCurrentInfo(room)

                let _room = (this.rm.rooms || []).find(r => r.room_id == room.room_id)
                if (_room) {
                    Object.assign(_room, room)
                } else {
                    let _rooms = this.rm.rooms ? [...this.rm.rooms] : []
                    _rooms.push(room)
                    this.rm.rooms = _rooms
                }
                if (Util.isPresent(res.astag)) {
                    this.cm.astag = res.astag
                }
                if (Util.isPresent(res.registered_attendees)) {
                    this.cm.registered_attendees = res.registered_attendees
                }
                let _appo = room.current_appointment

                pdm.setNew(room, res.userInfo, _appo)

                gtagEvent(`フォーム完了`, `progress_${room.progress_status}`, `appo status: ${_appo.status}`)
                if (_appo.status == 10) {
                    // this.rm.currentAppointment = _appo
                    let notificationType = `FIXED`
                    if (Util.isEmbeds()) {
                        // 埋め込み形式の場合に、親要素に確定した旨を通知します.
                        let params = { type: notificationType }
                        NotificationControl.notifyParent(notificationType, params)
                    }
                }

                this.refresher.needRefresh.push(`room`)
                this.hide()
            }
            this.loading = false
        })
    }

    public save() {
        if (Util.isBlank(this.ownerCtag)) {
            Notice.message = `書き込むオーナーのカレンダーを指定してください。`
            return
        }
        if (this.isCustomFixView) {
            this.saveParentRoom()
            return
        }

        if (this.loading) return

        Logger(`register schedule::`)
        this.rm.startProgress()
        this.loading = true
        let onlineName = Util.isOnline(this.location)
        let isOnline = Util.isPresent(onlineName) ? true : false

        let params = {
            search_key: this.ownerCtag.search_key,
            title: this.title,
            description: this.memo,
            location: this.location,
            allday: this.ev.allday,
            start_time: this.ev.start_time,
            end_time: this.ev.start_time + this.ev.duration * 60,
            required_attendees: Util.isPresent(this.usedCtags) ? this.usedCtags.map(ct => ct.email) : [],
            need_send_update: this.needNotify,
            is_private: this.isPrivate,
            is_online: isOnline,
        }

        if (onlineName == `zoom` && Util.isPresent(this.currentZooms)) {
            params[`online_meeting_resources`] = this.currentZooms.map(z => z.search_key)
        }

        Event.save(params).then(res => {
            this.rm.endProgress()
            this.loading = false
            if (res) {
                Notice.message = `イベントを作成しました`
                this.$vfm.close("CalendarEventModal")
                let ev = res.event

                //
                this.$emit(`createdEvent`, ev)
            }
        })
    }

    clickRegisterCalPrivate() {
        if (this.isPrivate) {
            this.isPrivate = false
        } else {
            this.isPrivate = true
        }
    }

    clickChangeAllday() {
        if (this.ev.allday) {
            this.ev.allday = false
        } else {
            this.ev.allday = true
        }
    }

    selectEventOwner(e) {
        const selectedCal = this.cm.getWritableCalendars().find(cal => cal.name === e)
        if (!selectedCal) {
            this.ownerCtag = null
        } else {
            this.ownerCtag = selectedCal
            Logger(`selectEventOwner: ownerCtagを指定しました: ${Util.output(this.ownerCtag)}`)
        }
    }

    updateLocation(location: string, zoomAccs: CalendarTag[], zoomEmail: string) {
        Logger(
            `CalendarEventModal#updateLocation location:${location}, zoomAccs:${Util.output(zoomAccs)}, zoomEmail:${zoomEmail}`
        )
        this.location = location
        this.currentZooms = zoomAccs
    }
}
