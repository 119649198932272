
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, getLang, funcName } from "packs/common"
import { gtagClick, gtagEventOwner, gtagPage, gtagPageOwner } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import SelectableGroups from "packs/pages/link/parts/SelectableGroups.vue"
import CopyButton from "packs/components/buttons/CopyButton.vue"
import ZoomMeetingContent from "packs/pages/schedule/ZoomMeetingContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Const from "packs/utils/Const"
import HeaderControl from "packs/utils/HeaderControl"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import LocationTag from "packs/models/LocationTag"
import Timezones from "packs/utils/Timezones"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

@Options({
    components: {
        SelectableGroups,
        CopyButton,
        ZoomMeetingContent,
    },
})
export default class ScheduleMainFixedAppoContent extends Vue {
    // data:
    @Prop()
    appo: Appointment

    @Prop()
    room: Room

    rm = RoomManager
    isSP = isMobile(window.navigator).phone

    astag: AvailableScheduleTag = null
    href
    currentPossibleDates = null
    Util = Util
    Appointment = Appointment
    timezones = null
    pdm = PossibleDatesManager
    truncate = truncate

    isPreview = Util.isPreview() // 調整ページ作成時のプレビュー画面か.

    public created() {
        // if (!Util.isPublic()) this.currentStep = `calendar`
        Timezones.getTimezones().then(tzs => {
            this.timezones = tzs
        })
    }

    public mounted() {
        if (this.appo && !Util.isEmbeds()) gtagPageOwner(`thanksPage`, {})
    }

    get hasOnlineMeetingUrl() {
        if (!this.appo) return false
        if (!this.appo.is_online_meeting) return false
        if (!this.appo.online_meeting) return false
        return Util.isUrl(this.appo.online_meeting.online_meeting_url)
    }

    get isToday() {
        if (!this.appo) return false

        let st = DateTime.fromSeconds(this.appo.start_time)
        let today = DateTime.local()
        return st.toISODate() == today.toISODate()
    }

    openOnlineUrl(url: string) {
        gtagClick(`確定 OnlineMeeting中央リンク`, `${url}`)
    }

    goGmap() {
        gtagClick(`アポ場所遷移 > Gmap`, `${this.appo.location_name}`)
        let address = LocationTag.getAddressFromCurrentAppo(this.appo, true)
        window.open(Util.gmapLinkUrl(address), `_blank`)
    }

    get localFormat() {
        if (!this.appo) return
        let lang = getLang()
        if (lang.includes(`ja`) && Util.isBlank(this.rm.userInfo.timezone_value)) {
            return this.appo.jp_format
        }
        Logger(`${funcName()} uf:${Util.output(this.rm.userInfo)}`)
        let st = DateTime.fromSeconds(this.appo.start_time)
        let et = DateTime.fromSeconds(this.appo.end_time)

        if (this.rm.userInfo && this.rm.userInfo.timezone_value) {
            let tzVal = this.rm.userInfo.timezone_value
            Logger(`${funcName()} Timezone情報を変更します. tzVal:${tzVal}`)
            if (this.timezones) {
                let tzDic = this.timezones.find(tz => tz.value == tzVal)
                if (tzDic) {
                    Logger(`${funcName()} tzDic:${tzDic.name}`)
                    let offsetStr = PossibleDate.toTimezoneUTCFormat(tzDic.diff)
                    let tz_st = st.setZone(`UTC${offsetStr}`)
                    let tz_et = et.setZone(`UTC${offsetStr}`)
                    return this.getLocalDateStr(tz_st, tz_et)
                }
            } else {
                return this.getLocalDateStr(st, et)
            }
            // Timezones.getTimezones().then(tzs => {
            //     if (tzs) {
            //         let tzDic = tzs.find(tz => tz.value == tzVal)
            //         if (tzDic) {
            //             Logger(`${funcName()} tzDic:${tzDic.name}`)
            //             let offsetStr = PossibleDate.toTimezoneUTCFormat(tzDic.diff)
            //             let tz_st = st.setZone(`UTC${offsetStr}`)
            //             let tz_et = et.setZone(`UTC${offsetStr}`)
            //             return this.getLocalDateStr(tz_st, tz_et)
            //         } else {
            //             return this.getLocalDateStr(st, et)
            //         }
            //     } else {
            //         return this.getLocalDateStr(st, et)
            //     }
            // })
        } else {
            return this.getLocalDateStr(st, et)
        }
    }

    get isInstantRoom() {
        if (!this.room) return
        let rs = this.room.room_setting
        if (!rs) return
        if (rs.is_instant_room) return true
        return false
    }

    /**
     * @param st [DateTime]
     * @param et [DateTime]
     */
    getLocalDateStr(st, et) {
        let weekArray = [
            this.$t(`calendar.sun`),
            this.$t(`calendar.mon`),
            this.$t(`calendar.tue`),
            this.$t(`calendar.wed`),
            this.$t(`calendar.thu`),
            this.$t(`calendar.fri`),
            this.$t(`calendar.sat`),
            this.$t(`calendar.sun`),
        ]
        let lang = getLang()
        let fmt = this.$t(`calendar.format_date`, { val1: weekArray[st.weekday] }) as string

        if (lang.includes(`ja`)) {
            let endTimeStr = et.toFormat(`HH:mm`)
            let dateStr = st.toFormat(`${fmt}HH:mm~${endTimeStr}`)
            return dateStr
        } else {
            let endTimeStr = et.toFormat(`HH:mm`)
            let dateStr = st.toFormat(`HH:mm - ${endTimeStr}, ${fmt}`)
            Logger(`${funcName()} dateStr:${dateStr}`)
            return dateStr
        }
    }

    get showTimezone() {
        if (!this.pdm.currentTZDic) return false
        let rs = this.room.room_setting
        if (rs && !rs.can_change_timezone) {
            // timezoneのDicをリセットします.
            return false
        }
        return true
    }
}
