
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, onlyUnique, copyToClipboard } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import Room from "packs/models/Room"
import axios from "types/axios"
import RoomManager from "packs/models/RoomManager"

@Options({
    components: { VueFinalModal },
})
export default class ConnectCalendarModal extends Vue {
    // data:
    @Prop()
    room: Room

    isSP = isMobile(window.navigator).phone
    showTip = false
    rm = RoomManager

    public hideTooltip(e) {
        this.showTip = false
        // this.showAppoTip = false;
    }

    public openModal() {
        gtagPage(`#/ConnectCalendarModal`)
    }

    closeAndNoDisp() {
        // 今後表示しない
        gtagClick(`外部カレンダー連携 今後表示しない`, ``)
        // 次回以降表示しないようにします。
        axios.put(`${Util.prefixUrl}/confirmed_connect_cal`).then(res => {
            if (res.data.result == `ok`) {
                this.rm.confirmed_connect_cal = true
            }
            this.$vfm.close("ConnectCalendarModal")
        })
    }
}
