
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UserIcon from "packs/components/icons/UserIcon.vue"
import MailPreviewPartsHeader from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsHeader.vue"
import MailPreviewPartsBlueButton from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsBlueButton.vue"
import MailPreviewSenderIconContent from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewSenderIconContent.vue"
import MailPreviewPartsEditContent from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsEditContent.vue"
import MailPreviewPartsPublicRoomTable from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsPublicRoomTable.vue"
import MailPreviewPartsSelectableMags from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsSelectableMags.vue"
import MailPreviewPartsOnlineTable from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsOnlineTable.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import MailFlow from "packs/models/MailFlow"
import TemplateUtil from "packs/utils/TemplateUtil"

@Options({
    components: {
        UserIcon,
        MailPreviewPartsHeader,
        MailPreviewPartsBlueButton,
        MailPreviewSenderIconContent,
        MailPreviewPartsEditContent,
        MailPreviewPartsPublicRoomTable,
        MailPreviewPartsSelectableMags,
        MailPreviewPartsOnlineTable,
    },
})
export default class MailPreviewCustom extends Vue {
    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    appos = null
    rm = RoomManager
    rcm = RoomCreateManager

    member: RoomMember = null

    selectable_mags = null
    Util = Util
    MailFlow = MailFlow

    contentArr = []
    blockVals = TemplateUtil.getBlockVariables()

    mounted() {
        this.updateMember()
        this.addMessage()
        this.updateContent()
    }

    addMessage() {
        this.$emit(`addMessage`)
    }

    @Watch("members", { deep: true })
    updateMember() {
        if (!this.rcm.members) return
        if (!this.rm.userInfo) return
        Logger(`updateMember 親から受け取ります. ${this.rcm.members.length}`)
        this.member = this.rcm.members.find(m => m.user_id != this.rm.userInfo.user_id)
    }

    @Watch(`rcm.selectedMailFlow`, { deep: true })
    updateContent() {
        if (!this.rcm.selectedMailFlow) return

        let mf = this.rcm.selectedMailFlow

        let content = ``
        if (Util.isPresent(mf.content)) {
            content = mf.content
        } else {
            let us = this.rm.user_setting
            let type = mf.def_type
            let simpleType = us.mail_display_type
            if (simpleType == `simple`) {
                content += `<<出席者-1-氏名>>様\n\nお世話になっております。\n`
                content += this.rcm.defaultSimpleText(type)
            } else {
                content = this.rcm.defaultSimpleText(type, false)
            }
        }

        let str = this.rcm.insertTemplateText(content, mf.def_type)

        // let blockVals = TemplateUtil.getBlockVariables()
        // let blockStr = blockVals.join(` `)
        // let modifiers = "gi"
        // let patt = new RegExp(blockStr, modifiers)

        let contentArr = str.split(`\n`)
        Logger(`${funcName()} contentArray:${Util.output(contentArr)}`)
        this.contentArr = contentArr
    }
}
