import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "centered w100",
  style: {"padding-top":"10px"}
}
const _hoisted_2 = {
  class: "mc",
  style: {"width":"450px"}
}
const _hoisted_3 = {
  class: "with_white_bg",
  style: {"padding":"30px 25px"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "fs13 fw400 text-left" }
const _hoisted_6 = {
  class: "fs18 fw600 text-left",
  style: {"color":"#222222","padding-bottom":"13px","line-height":"1.6"}
}
const _hoisted_7 = {
  class: "w100",
  style: {"background":"#fff","padding-top":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailPreviewPartsHeader = _resolveComponent("MailPreviewPartsHeader")!
  const _component_MailPreviewPartsEditContent = _resolveComponent("MailPreviewPartsEditContent")!
  const _component_MailPreviewPartsCandidateDates = _resolveComponent("MailPreviewPartsCandidateDates")!
  const _component_MailPreviewPartsSelectableMags = _resolveComponent("MailPreviewPartsSelectableMags")!
  const _component_MailPreviewPartsBlueButton = _resolveComponent("MailPreviewPartsBlueButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MailPreviewPartsHeader),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.member)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.member.company_name), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.member.name) + " 様 ", 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_MailPreviewPartsEditContent, { onAddMessage: _ctx.addMessage }, null, 8, ["onAddMessage"])
      ]),
      _createVNode(_component_MailPreviewPartsCandidateDates, { message: `以下の日程が提案されています\n「この日程で確定」を押すと確定ページに飛び、その日程で調整が完了します。` }),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MailPreviewPartsSelectableMags),
        _createVNode(_component_MailPreviewPartsBlueButton, { buttonName: `調整ページへ` })
      ])
    ])
  ]))
}