import { DateTime } from "luxon"
import RoomMember from "packs/models/RoomMember"
import axios from "axios"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import PossibleDate from "packs/models/PossibleDate"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import NotificationControl from "packs/utils/NotificationControl"
import CalendarTag from "packs/models/CalendarTag"

interface PossibleDatesConditions {
    room: Room
    userInfo: RoomMember
    appo: Appointment
    openDetailSchedules: boolean
    selectedMag: MeetingAttendeesGroup
    hideMemberName: boolean
    setNew(room: Room, userInfo: RoomMember, appo: Appointment)
}
let _PossibleDatesConditions: PossibleDatesConditions = {
    /**
     * 基本情報
     */
    room: null,
    userInfo: null,
    appo: null,
    openDetailSchedules: false,
    selectedMag: null,
    hideMemberName: false,

    setNew(room: Room, userInfo: RoomMember, appo: Appointment) {
        Logger(`PossibleDatesConditions.${funcName()} room:${room}, userInfo:${userInfo}, appo:${appo}`)
        this.room = room
        this.userInfo = userInfo
        this.appo = appo
        this.openDetailSchedules = true
        // let owner = this.room.owner
        // this.isSuspend = owner.group_status == 1100
        // if (this.appo.status == 10) {
        //     this.displayType = `fixed`
        // } else if (this.appo.status == -1) {
        //     this.displayType = `canceled`
        // } else if (this.appo.status == 1) {
        //     this.displayType = `possibles`
        // }
    },
}
export default _PossibleDatesConditions
