
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"

@Options({
    components: {},
})
export default class MailPreviewPartsHeader extends Vue {
    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    DateTime = DateTime
    Util = Util

    created() {}

    mounted() {}
}
