
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { funcName, Logger, sleep } from "packs/common"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import LinkHeaderRoomSettings from "packs/components/link/LinkHeaderRoomSettings.vue"
import GoAvailableScheduleModal from "packs/pages/link/modals/GoAvailableScheduleModal.vue"
import LeftNavPanel from "packs/pages/link/parts/room_settings/LeftNavPanel.vue"
import MailPreview from "packs/pages/link/parts/room_settings/MailPreview.vue"
import EditableScheduleView from "packs/pages/link/parts/room_settings/EditableScheduleView.vue"
import PublicSettings from "packs/pages/link/parts/room_settings/PublicSettings.vue"
import FormSettings from "packs/pages/link/parts/room_settings/FormSettings.vue"
import FixTypeButtons from "packs/pages/link/parts/room_settings/FixTypeButtons.vue"
import ParentSettings from "packs/pages/link/parts/room_settings/ParentSettings.vue"
import ConfirmChangeOwnerModal from "packs/pages/link/parts/room_settings/ConfirmChangeOwnerModal.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import ConnectedCalendar from "packs/pages/link/parts/available_schedule/ConnectedCalendar.vue"
import WeeklyDetailSchedule from "packs/pages/link/parts/available_schedule/WeeklyDetailSchedule.vue"
import AppointmentCardViewSmall from "packs/pages/link/parts/available_schedule/AppointmentCardViewSmall.vue"
import InputAstagNameModal from "packs/pages/link/modals/InputAstagNameModal.vue"
import LeftRoomSettingsTotalMenu from "packs/pages/link/parts/room_settings/LeftRoomSettingsTotalMenu.vue"
import InstantRoomContent from "packs/pages/schedule/InstantRoomContent.vue"
import EditableZoomSettings from "packs/pages/link/parts/room_settings/EditableZoomSettings.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import Room from "packs/models/Room"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"
import Appointment from "packs/models/Appointment"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import MessageTemplate from "packs/models/MessageTemplate"
import AppealContent from "packs/models/AppealContent"
import UserFile from "packs/models/UserFile"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import RoomSetting from "packs/models/RoomSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import CalendarManager from "packs/models/CalendarManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        FlashNotice,
        LinkHeaderRoomSettings,
        GoAvailableScheduleModal,
        MailPreview,
        LeftNavPanel,
        EditableScheduleView,
        PublicSettings,
        FormSettings,
        FixTypeButtons,
        ParentSettings,
        ConfirmChangeOwnerModal,
        OptionModal,
        ConnectedCalendar,
        WeeklyDetailSchedule,
        AppointmentCardViewSmall,
        InputAstagNameModal,
        LeftRoomSettingsTotalMenu,
        InstantRoomContent,
        EditableZoomSettings,
        VueFinalModal,
        MdProgressBar,
    },
    beforeRouteLeave(to, from, next) {
        if (this.rcm.hasUnsaved) {
            if (window.confirm(`編集した内容を保存しますか？`)) {
                // 保存処理.
                this.publishRoom()
                return
            } else {
                if (this.cm) this.cm.resetAstagInfo()
                if (this.rcm) this.rcm.reset()
                this.rm.astags = (this.rm.astags || []).filter(a => a.id != `newId`)
            }
        } else {
            if (this.cm) this.cm.resetAstagInfo()
            if (this.rcm) this.rcm.reset()
            this.rm.astags = (this.rm.astags || []).filter(a => a.id != `newId`)
        }

        next()
    },
})
export default class RoomSettings extends Vue {
    // data:
    notice = Notice

    $notify: any
    rm = RoomManager
    rcm = RoomCreateManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    Const = Const

    room: Room = null

    /**
     * new, copy, edit
     * editの場合、以前はアポの編集を意味していたが、現在は下書きを編集する場合に利用。
     * そのため、純粋に作成する手順と一緒（ただし、既にparentRoomは作成されているので、作成時にIDを引き渡すこと+下書きから削除すること.
     */
    action: string
    editOrNewForm = ``
    type = ``
    loading = false
    // appo: Appointment = null
    appoStatus: string = null
    publishing = false
    // locations: string[] = null
    // titles: string[] = null
    // astag: AvailableScheduleTag = null
    clickToClose = false
    panelType = null

    // members: RoomMember[] = null

    selectedTabName = `room`
    editingField: FormField = null
    formindex: number = -1 // フォームを追加する位置.
    sendAfterFixType = false // fixTypeを確定したら、送信するか.
    beforeAstag: AvailableScheduleTag = null
    pdm = PossibleDatesManager

    willCreateNewAstag = true // 新規で作成するか.（すでに作成済みのastagで投票作成する場合は、falseにします.）

    @Ref()
    leftPanel

    @Ref()
    PublicSettings

    @Ref()
    Header

    @Ref()
    WeeklyDetailSchedule

    public created() {
        $("body").addClass("lskyblueBg")
        this.action = roomStorage.fetchAction()
        // Logger(`fetchする前にrcmに入っているか確認: ${Util.output(this.rcm.room.members)}`)
        // let room = roomStorage.fetchRoomPreview()
        let room = Util.isPresent(this.rcm.room) ? { ...this.rcm.room } : roomStorage.fetchRoomPreview()
        if (Util.isBlank(room)) {
            room = Room.createDefaultRoom()
        }

        Logger(`${funcName()} roomを作成します:${Util.output(room)}`)

        let appo = room.current_appointment

        this.appoStatus = Appointment.typeNew
        gtagPage("#/room_settings")
        if (room) {
            if (room.invalid_messages && room.invalid_messages.length > 0) {
                this.showErrorMessages()
            }
        }
        let astagId = room.owner_avaialble_schedule_tag_id
        Logger(`${funcName()} astagId:${astagId} From RoomSettings.vue`)

        if (!this.rm.hasAsTag()) {
            Logger(`${funcName()} GET AvailableSchedule`)
            this.rm.startProgress()
            this.rm.getAvailableSchedule().then(res => {
                this.rm.getAstagDetail(astagId).then(res2 => {
                    this.rm.endProgress()

                    this.configureRoomFirst(room, appo, res2)
                    this.pdm.setNew(room, this.rm.userInfo, appo)
                    this.checkHasAstag()
                    Logger(`tutorial phase: ${this.rm.tutorial_phase}`)
                    this.checkCanCreateRoom()

                    this.setTemplateInMemo()
                })
            })
        } else {
            this.rm.startProgress()
            this.rm.getAstagDetail(astagId).then(res2 => {
                this.rm.endProgress()

                this.configureRoomFirst(room, appo, res2)
                this.pdm.setNew(room, this.rm.userInfo, appo)
                this.checkHasAstag()
                this.checkCanCreateRoom()
                this.setTemplateInMemo()
            })
        }
    }

    checkCanCreateRoom() {
        if (Util.isBlank(this.rm.userInfo)) return
        if (Const.suspend(this.rm)) {
            Notice.message = `利用停止中のため、新規作成できません。`
            this.goRooms()
        }
        if (!UserPermissionsOption.permitEdit(this.rm.userInfo, `room_settings`, `room_settings_create_room`)) {
            Notice.message = `作成権限がないため、新規作成できません。`
            this.goRooms()
        }
    }

    public configureRoomFirst(room: Room, appo: Appointment, astag: AvailableScheduleTag) {
        this.rcm.chukaiAstag = this.rm.chukaiAstag
        this.rcm.astag = astag
        this.rcm.setNew(room, appo, room.members, this.rcm.astag, this.rm.userInfo, this.rm.user_setting, this.action)

        Logger(
            `${funcName()} action:${this.action} appo:${this.rcm.appo?.id}, astag(${this.rcm.astag?.id}):${this.rcm.astag?.name}`
        )
    }

    public setTemplateInMemo() {
        Logger(`編集するルーム テンプレートID : ${Util.output(this.rcm.room.template_public_id)}`)
        if (this.rcm.room.template_public_id && !this.rcm.appo.memo) {
            // テンプレを取得し、自動で代入します。
            this.rm.getTemplates().then(tmps => {
                // let tmp: MessageTemplate = tmps.find(t => t.public_id == this.rcm.room.template_public_id)
                // if (tmp) {
                //     let owner = this.rcm.members.find(m => m.role == 100) || this.rm.userInfo
                //     let atts = this.rcm.members.filter(m => m.user_id != this.rm.userInfo.user_id)
                //     let str = TemplateUtil.insertTemplateText(tmp.content, atts, owner, this.rcm.appo, this.rm.userInfo)
                //     this.rcm.appo.memo = str
                //     this.rcm.updateAppo(this.rcm.appo)
                // }
            })
        }
    }

    public beforeUnmount() {
        Logger(`delete room: `)
        // this.rm.getAllAstags()
        $("body").removeClass("lskyblueBg")
        this.resetStorage()
        // this.$notify.dismissAll()
        this.dismissErrorMessages()
    }

    resetStorage() {
        Logger(`resetStorage::`)
        if (![`new`, `addNew`, `copy`].includes(this.action)) {
            roomStorage.deleteRoomInfo()
        }
        roomStorage.saveRoomPreview(null)
        roomStorage.saveAction(null)
        roomStorage.saveAppointmentPreview(null)
        roomStorage.deleteRoomInfo()
    }

    mounted() {
        this.checkHasAstag()
        this.checkCanCreateRoom()
        this.updateShowPlanOptionModalWithName()
    }

    closePanel() {
        gtagClick(`close LeftPanel`, ``)
        this.panelType = null
        $(".leftNavPanel").removeClass("open")
    }

    selectTab(tabname: string) {
        Logger(`${funcName()} tabname:${tabname}`)
        this.selectedTabName = tabname
        // if (this.selectedTabName == `public_settings`) {
        //     this.PublicSettings.checkGroupNamespace()
        // }
    }

    @Watch(`rcm.currentTab`, { deep: true })
    goSettingsWithScheduleTab() {
        Logger(`RoomSettings#goSettingsWithScheduleTab ${this.rcm.currentTab}`)
        if ([`schedule`, `public`].includes(this.rcm.currentTab)) {
            this.Header.showPublicRoomSettings()
            // this.selectedTabName = `settings`
        } else if (this.rcm.currentTab == `room`) {
            this.Header.showRoom()
        } else if (this.rcm.currentTab == `mail_flow`) {
            this.Header.showMailPreview()
        } else if (this.rcm.currentTab == `form`) {
            this.Header.showFormPreview()
        }
    }

    public goNotAttendCalendar() {
        gtagClick(`ルーム作成 → 不参加カレンダー`, `不参加カレンダー未作成`)
        this.$router.push(`/settings/not_attendees_calendar`)
    }

    public addAttendeeFromUnderBox(member) {
        this.leftPanel.addAttendee(member)
        gtagClick(`出席者下から出席者を追加`, `出席者:${this.rcm.members.length}`)
    }

    public fadeOutAttendeesModal() {
        // this.$vfm.close("AttendeesModal");
    }

    public isEditPage(): boolean {
        // 編集画面の場合、日程調整情報にデフォルト入力があります。
        if (this.action == "edit") {
            return true
        } else {
            return false
        }
    }

    /**
     * 仲介が参加する用の調整カレンダーを紐付けます。
     */
    public linkCal(e) {
        // Chukaiなら変更できます。
        Logger(`linkCal e: ${Util.output(e)}`)
        let _appo = { ...this.rcm.appo }

        if (!_appo.required_owner_participation) {
            Logger("checked true.")
            _appo.required_owner_participation = true
        } else {
            Logger("checked false.")
            _appo.required_owner_participation = false
            if (this.rm.chukaiAstag) {
                this.notice.message = `作成した不参加カレンダーを適用します。`
            }
        }
        this.updateAppo(_appo)
        this.checkHasAstag()
    }

    public saveDraft() {
        if (this.rcm.room.room_type == `public_room` && Util.isBlank(this.rm.namespace)) {
            this.notice.message = "企業URLを設定してください。"
            this.Header.selectedTabName = `settings`
            // this.Header.showPublicRoomSettings()
            this.selectTab(`public_settings`)
            if (this.PublicSettings) {
                this.PublicSettings.openNamespaceModal()
            }

            return
        }
        this.send(`draft`)
    }

    public publishRoom(): void {
        Logger(`送信 before validate  ${Util.output(this.rcm.room.members)}`)
        Logger(`appo : ${Util.output(this.rcm.appo)}`)
        this.rcm.appo = this.rcm.appo || Appointment.createDefault()
        if (this.rcm.room.room_type == `parent_room` && Util.isBlank(this.rcm.room.members)) {
            this.notice.message = "出席者を追加してください。"
            return
        }
        if (this.rcm.room.room_type == `public_room` && Util.isBlank(this.rm.namespace)) {
            this.notice.message = "企業URLを設定してください。"
            this.Header.selectedTabName = `settings`
            // this.Header.showPublicRoomSettings()
            this.selectTab(`public_settings`)
            if (this.PublicSettings) {
                this.PublicSettings.openNamespaceModal()
            }

            return
        }
        if (!this.rcm.appo.duration) {
            this.rcm.appo.duration = 60
        }
        let changedAppo = "アポ変更なし"
        const _appo = this.rcm.appo
        if (_appo.name != `打ち合わせ` || _appo.duration != 60 || (_appo.location_name && _appo.location_name.length > 0)) {
            changedAppo = "アポ変更あり"
        }
        gtagClick(`ルーム作成 送信 ${changedAppo}`, `出席者:${this.rcm.members.length}人 `)

        if (this.rcm.room.room_type == `public_room`) {
            if (!this.rcm.appo.fix_type || this.rcm.appo.fix_type == 99) {
                this.rcm.appo.fix_type = 100
            }
        }
        this.sendAfterFixType = true

        if (this.rcm.appo.fix_type) {
            // if (this.action == `edit` && this.rcm.room.originOwner.user_id != this.rm.userInfo.user_id) {
            //     // 自分がオーナーでないページを編集しようとしているためモーダルでアラートを入れいます.
            //     this.$vfm.open(`ConfirmChangeOwnerModal`)
            //     return
            // }
            this.send()
        } else {
            this.clickToClose = false
            this.$vfm.open("SelectFixUserModal")
        }
    }

    public fixBy(fixType: number) {
        // Logger(`日程を確定できるタイプ: ${typeString}`)
        this.rcm.appo.fix_type = fixType
        // this.send()
        if (this.sendAfterFixType) this.send()
    }

    public findOrSetAstagsAfterSave(ast: AvailableScheduleTag) {
        Logger(`${funcName()}`)
        let astags = this.rm.astags ? [...this.rm.astags] : []
        let _astag = astags.find(a => a.id == ast.id)
        if (_astag) {
            Object.assign(_astag, ast)
        } else {
            astags.push(this.rm.astag)
        }

        this.rm.astags = astags

        if (this.cm) {
            this.cm.resetAstagInfo()
        }
        if (this.rm.astag.id == ast.id) {
            this.rm.astag = ast
        }
    }

    public send(publicStatus: string = ``) {
        Logger(`送信 ${this.action} ${this.rcm.room.id}`)
        this.loading = true

        // 送信中の場合二度押し防止.
        if (this.publishing) return

        if (this.action == `copy`) {
            this.rcm.room.id = `newId`
            this.action = `new`
        }

        if (publicStatus == `draft`) {
            this.rcm.publicStatus = `draft`
        }

        Logger(
            `astagの状態を確認します: this.astag: ${Util.output(this.rcm.astag)}, this.rm.astag: ${Util.output(
                this.rm.astag
            )}, this.rm.astag: ${Util.output(this.rm.astag)}`
        )

        this.publishing = true
        this.rm.startProgress()
        this.rcm.saveRoom().then(result => {
            this.rm.endProgress()
            this.loading = false
            this.publishing = false
            this.clickToClose = true
            // 保存できた場合、ストレージの情報を削除しておきます。
            if (result.isSuccess) {
                // currentTabに追加
                this.rcm.hasUnsaved = false
                Logger(`キャッシュ情報を削除します。`)
                if (publicStatus == `draft`) {
                    // idだけ入れておく.
                    this.rcm.room.id = result.room.id
                    return
                }
                if (Util.isPresent(result.astag)) {
                    this.findOrSetAstagsAfterSave(result.astag)
                }

                this.rm.setNewRoom(result.room)
                this.resetStorage()
                this.rcm.reset()
                this.updateNewRoomIfDuringSearch(result)
                this.goRooms()
            } else {
                Logger(`送信が失敗したため、そのまま.`)
                if (result.room) this.rcm.room = result.room
                this.dismissErrorMessages()
                if (this.rcm.room.invalid_messages && this.rcm.room.invalid_messages.length > 0) {
                    this.showErrorMessages()
                }
            }
        })
    }

    // 保存した際に、調整ページ一覧で検索中の場合、キャッシュしているため、キャッシュしている公開ページ情報を更新し保存します。
    updateNewRoomIfDuringSearch(result) {
        let searchedDic = roomStorage.fetchSearchedRoomsInfo()
        if (Util.isPresent(searchedDic) && Util.isPresent(searchedDic.rooms)) {
            let rooms = searchedDic.rooms || []
            let room = rooms.find(m => m.id == result.room.id)
            if (Util.isPresent(room)) {
                Logger(`${funcName()} 検索中のタブにroomがあるため、新しいroomに変更します.`)
                let _rooms = rooms.filter(m => m.id != result.room.id)
                _rooms.unshift(result.room)
                searchedDic.rooms = _rooms
                roomStorage.saveSearchedRoomsInfo(searchedDic)
            }
        }
    }

    public goRooms() {
        this.$router.push("/")
    }

    public updateAppo(appo: Appointment) {
        Logger(`update appo: ${Util.output(appo)}`)
        // this.rcm.appo = appo
        this.rcm.updateAppo(appo)
        roomStorage.saveAppointmentPreview(this.rcm.appo)
        // this.rcm.room.current_appointment = appo
        // this.rcm.room.appointments = [appo]
    }

    @Watch("rcm.showLeftPanelContentWithType")
    updateEditEditableContent() {
        Logger(`${funcName()} type:${this.rcm.showLeftPanelContentWithType}`)
        if (Util.isBlank(this.rcm.showLeftPanelContentWithType)) return
        this.editEditableContent(this.rcm.showLeftPanelContentWithType)
        this.rcm.showLeftPanelContentWithType = null
    }

    /**
     * 子要素からどの要素を編集するかをはめて、パネルを表示します。
     * image: ヘッダー画像, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択, pptos変更, thanks_page 完了ページを編集
     * @param type [string]
     */
    public editEditableContent(type: string) {
        Logger(`${funcName()} type:${type}, panelType:${this.panelType}`)
        if (this.panelType == type) return

        this.panelType = type
        // if (this.leftPanel) this.leftPanel.openPanel()
        $(".leftNavPanel").addClass("open")
    }

    public checkHasAstag() {
        if (Util.isBlank(this.rm.astag) && Util.isBlank(this.rm.astag)) return
        if (this.rm.astag && this.rm.astag.id == "newId") {
            this.$vfm.open("GoAvailableScheduleModal")
        }
        // let _astag = this.rm.astag ? { ...this.rm.astag } : { ...this.rm.astag }
        if (this.rcm.attendees.length >= 2 && !this.rcm.appo.required_owner_participation) {
            if (this.rm.chukaiAstag) this.rcm.astag = this.rm.chukaiAstag
        } else {
            let astag_id = this.rcm.room.owner_avaialble_schedule_tag_id
            if (Util.isPresent(astag_id)) {
                let _astag = this.rm.astags.find(ast => ast.id == astag_id)

                if (Util.isBlank(_astag)) {
                    // 仲介から取得します.
                    _astag = (this.rm.astagsTab?.shared_chukai_astags || []).find(a => a.id == astag_id)
                    Logger(`${funcName()} shared chukai astag:${this.rcm.astag?.id}`)
                }

                if (Util.isBlank(_astag)) {
                    // 仲介から取得します.
                    this.rcm.astag ||= this.rm.defaultAstag || this.rm.astag
                }

                this.rcm.astag = _astag
            } else {
                let _astag = this.rm.defaultAstag || this.rm.astag
                if (_astag) {
                    Logger(`${funcName()} astagをセット. ${_astag?.name}`)
                    _astag.systemUpdatedAt = Util.getSec()
                    this.rcm.astag = _astag
                } else {
                    let _astags = this.rm.getAllAstags()
                    _astag = _astags.find(ast => ast.selected)
                    if (_astag) this.rcm.astag = _astag
                }
            }
        }
    }

    public dismissErrorMessages() {
        $(".alert .close").click()
    }

    public showErrorMessages() {
        this.$notify({
            placement: "top-right",
            type: "warning",
            title: "調整ページ作成エラー",
            content: this.rcm.room.invalid_messages.join("<br><br>"),
            duration: 0,
            html: true,
            customClass: `z10 mt20`,
        }).then(() => {
            // resolve after dismissed
            Logger("dismissed")
        })
    }

    /**
     * 調整ページプレビューを表示.
     */
    public showRoomPreview() {
        //
        this.rcm.updateRoom(this.rcm.room)

        // 遷移.

        if (this.rcm.room.room_type == `public_room`) {
            gtagClick(`公開ページプレビュー`, ``)
            window.open(`/link#/p/schedule_preview`, `_blank`)
        } else {
            gtagClick(`限定公開ページプレビュー`, `${this.rcm.room.members.length}`)
            window.open(`/link#/schedule_preview`, `_blank`)
        }

        // sleep(1000).then(e => {
        //     window.open(`/link#/schedule`, `_blank`)
        // })
    }

    public clickUseNoindex() {
        if (this.rcm.room.use_noindex) {
            this.rcm.room.use_noindex = false
        } else {
            this.rcm.room.use_noindex = true
        }
        this.rcm.updateRoom(this.rcm.room)
    }

    public clickFixType() {
        if (this.rcm.appo.fix_type == 1) {
            this.rcm.appo.fix_type = 100
        } else {
            this.rcm.appo.fix_type = 1
        }
        this.rcm.updateAppo(this.rcm.appo)
    }

    public selectTimingType(type: string) {
        this.rcm.room.display_form_timing_type = type
        this.rcm.updateRoom(this.rcm.room)
    }

    public selectDisplayScheduleType(type: string) {
        this.rcm.room.display_schedule_type = type
        this.rcm.updateRoom(this.rcm.room)

        // if (type == `rules_by_ai` && this.canRulesByAI()) {
        //     this.rcm.room.display_schedule_type = null
        //     sleep(500).then(_ => {
        //         this.rcm.room.display_schedule_type = `anytime`
        //         this.rcm.updateRoom(this.rcm.room)
        //     })
        // }
    }

    canRulesByAI() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_display_schedule_rules_by_ai`, true)
    }

    public selectShowVoteType(type: string) {
        this.rcm.room_setting.show_vote_type = type
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    public selectShowFilterVoteType(type: string) {
        Logger(`${funcName()} type:${type}`)
        this.rcm.room_setting.show_filter_vote_type = type
        this.rcm.updateRoomSetting(this.rcm.room_setting)
    }

    public inputPageKey(e) {
        this.rcm.room.page_key = e
        this.rcm.updateRoom(this.rcm.room)
    }

    public changeRoomType(roomType: string) {
        this.selectedTabName = `room`
        this.rcm.room.room_type = roomType
        if (roomType == `public_room`) {
            // 誰が決めるかの初期値を入れ直します.
            this.rcm.appo.fix_type = 100
            if (this.rcm.appeal_content.presetInfo) this.rcm.appeal_content = AppealContent.setRandomContent(this.rm.userInfo)

            if (Util.isBlank(this.rcm.fields)) {
                let ffs = FormField.createDefaultFields()
                this.rcm.updateFormFields(ffs)
            }

            this.closePanel()
        } else {
            // 誰が決めるかの初期値を入れ直します.
            this.rcm.appo.fix_type = null
            if (this.rcm.appeal_content.presetInfo) this.rcm.room.appeal_content = AppealContent.removeRandomContent()
            if (this.rcm.room && this.rcm.room.room_type == `parent_room` && (this.rcm.members || []).length == 0) {
                this.editEditableContent(`attendees`)
            }
        }
        this.updateAppo(this.rcm.appo)
    }

    public editField(field: FormField) {
        // 編集を開始します.
        this.editingField = field
        this.panelType = `field`
        $(".leftNavPanel").addClass("open")
    }

    /**
     * 「項目を追加」を押下したときにパネルを開くアクション.
     */
    public addNewField(formindex: number) {
        this.panelType = `add_field`
        this.formindex = formindex
        $(".leftNavPanel").addClass("open")
    }

    /**
     * add_fieldによって、fieldを選択した
     */
    public addField(newfield: FormField) {
        if (this.formindex > 0) {
            let _fields = this.rcm.fields ? [...this.rcm.fields] : []
            _fields.splice(this.formindex, 0, newfield)
            // 番号を振り直します.
            _fields = FormField.updateIndexNum(_fields)
            this.rcm.updateFormFields(_fields)
        }
        this.editField(newfield)
    }

    /**
     * フォームが先で日程調整画面を表示するかのルールを設定したときに保存します.
     */
    public saveRule(rule: FormField[]) {
        this.rcm.room.rule = Util.isPresent(rule) ? rule : null
        this.rcm.updateRoom(this.rcm.room)
    }

    changeOwnerAndUpdate(willChange: boolean) {
        this.rcm.changeOwner = willChange
        this.send()
    }

    @Watch(`rm.showPlanOptionModalWithName`, { deep: true })
    updateShowPlanOptionModalWithName() {
        Logger(`updateShowPlanOptionModalWithName: ${this.rm.showPlanOptionModalWithName}`)
        if (this.rm.showPlanOptionModalWithName) {
            this.$vfm.open(`OptionModal`)
        } else {
            this.$vfm.close(`OptionModal`)
        }
    }

    clickOK() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} 課金リクエスト`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.rm.showPlanOptionModalWithName} 課金リクエストがありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.rm.showPlanOptionModalWithName = null
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.rm.showPlanOptionModalWithName} キャンセル 課金リクエスト`, ``)
        this.rm.showPlanOptionModalWithName = null
    }

    closeOptionModal() {
        this.rm.showPlanOptionModalWithName = null
    }

    @Watch(`rcm.edittingAstag`)
    updateAstagView() {
        Logger(`RoomSetting#updateAstagView`)
        if (this.rcm.edittingAstag) {
            this.selectedTabName = `asview`
        } else {
            // this.selectedTabName = `room`
        }
    }

    clickChangeName() {
        this.willCreateNewAstag = false
        this.$vfm.open("InputAstagNameModal")
    }

    clickChangeAutoMode() {
        Logger(`${funcName()}`)
        this.WeeklyDetailSchedule.openResetOptionalSchedulesModal()
    }

    clickNewAstag() {
        Logger(`${funcName()}`)
        gtagClick(`Astag`, `新規作成`)
        this.cm.createType = `normal`
        this.cm.astag = null
        this.willCreateNewAstag = true

        this.$vfm.open("InputAstagNameModal")
    }

    createNewAstag() {
        Logger(`${funcName()} 新規取得.: name:${this.rm.astag.name}, rcm.room_setting:${this.rcm.room_setting}`)

        let _astag = AvailableScheduleTag.copy(this.rm.astag)
        this.cm.setNew(this.rm.userInfo, this.rm.user_setting, _astag, this.rcm.room_setting, true, this.rcm.appo)

        let selectMagName = `A`
        this.cm.updateMagName(selectMagName)
        // 表示を初期値に戻す.
        this.cm.getMagEvents(null, selectMagName).then(e => {
            this.cm.getPossibleDatesEvents()
        })
        this.closePanel()
        this.rcm.edittingAstag = _astag
        this.$vfm.close("InputAstagNameModal")
    }

    get showZoomSettings() {
        if (!this.rcm || !this.rcm.appo) return false
        if (this.rcm.appo.location_name == Const.zoomConnectAccount) {
            return true
        }
        let loctags = this.rcm.appo.selectable_locations || []
        let loctag = loctags.find(lt => lt.keyword == Const.zoomConnectAccount)
        Logger(`${funcName()} loctags:${Util.output(loctags)}`)
        if (loctag) {
            return true
        }
        return false
    }

    clickInstantButton() {}
}
