
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.

// モデルを読込.
import RoomMember from "packs/models/RoomMember"
import Util from "packs/utils/Util"
import RoomManager from "packs/models/RoomManager"
import UserFile from "packs/models/UserFile"
import Notice from "packs/models/Notice"
import LocationTag from "packs/models/LocationTag"

@Options({})
export default class LocationIcon extends Vue {
    // data:
    @Prop()
    loctag: LocationTag

    @Prop()
    width: number

    @Prop()
    totalHeight: number

    @Prop()
    canDelete: boolean

    @Prop()
    tooltipName: string

    @Prop()
    showTooltip: boolean

    @Prop()
    useLocationIcon: boolean

    mem: RoomMember = null

    loading = false
    isDrag: string = null
    fileForm: any = null

    Util = Util

    public created() {}

    public mounted() {}

    get tooltipString() {
        if (!this.showTooltip) return ``
        if (this.tooltipName) return this.tooltipName

        return `${this.mem && this.mem.company_name ? `${this.mem.company_name}<br>` : ``}${this.mem.name}`
    }

    public showChangeImage() {
        // $(".ChangeImageIcon").fadeIn(100).css("display", "inline-block")
        $(".ChangeImageIcon").fadeIn(100).css({ display: "block", top: "0" })
        this.$emit("mouseoverImage")
    }

    public hideChangeImage() {
        $(".ChangeImageIcon").fadeOut(60)
        this.$emit("mouseleaveImage")
    }

    public clickImage() {
        this.$emit("clickImage")
    }

    public deleteLocation() {
        this.$emit(`deleteLocation`, this.loctag)
    }

    public clickLocation() {
        this.$emit(`clickLocation`, this.loctag)
    }
}
