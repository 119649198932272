
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger, funcName } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import RadioButtonTag from "packs/components/forms/RadioButtonTag.vue"
import RoomLightOverViewContent from "packs/pages/link/parts/RoomLightOverViewContent.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Appointment from "packs/models/Appointment"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import FormField from "packs/models/FormField"
import CalendarManager from "packs/models/CalendarManager"
import RoomMember from "packs/models/RoomMember"
import ChangeableDateTimeContent from "packs/components/calendar/ChangeableDateTimeContent.vue"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        RadioButtonTag,
        RoomLightOverViewContent,
        VueFinalModal,
        MdProgressBar,
        ChangeableDateTimeContent,
    },
})
export default class ModalCancelAppointment extends Vue {
    @Prop()
    isDev

    @Prop()
    selectedMessageRoom: Room

    @Prop()
    appo: Appointment

    @Prop() // 主にリスケボタンが押されたのかどうかを確認.
    appoStatus: string

    @Prop() // rooms, schedule_view
    from: string

    // data:

    rm = RoomManager
    pdm = PossibleDatesManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    text = ``

    // 選択中のラジオボタンのこと
    action = `reschedule`
    loading = false
    Appointment = Appointment
    Room = Room
    Util = Util

    changeableEv: PossibleDate = null

    openModal() {
        gtagPage(`#/キャンセル・再調整モーダル`)
        Logger(`appoStatus: ${this.appoStatus}`)
    }

    public cancelOptions() {
        let buttons = [{ name: `キャンセルを通知`, id: `cancel` }]

        if (this.pdm.isSuspend) {
            this.action = `cancel`
        } else {
            if (this.showRescheduleButton()) {
                buttons = [{ name: `再調整依頼`, id: `reschedule` }, ...buttons]
            } else {
                this.action = `cancel`
            }

            if (this.showCustomFixButton()) {
                buttons = [...buttons, { name: `別日程で変更確定`, id: `force_fix` }]
            }
        }
        return buttons
    }

    public alreadyFinished(): boolean {
        if (!this.appo) return false
        if (this.appo.status != 10) return false

        let endTime = this.appo.start_time + this.appo.duration * 60
        const now = Util.getSec()
        if (endTime < now) {
            return true
        }
        return false
    }

    /**
     * votedで確定したアポで、owner_ugでない場合、キャンセルできない
     */
    public isVotedNotOwnerUg() {
        if (!this.selectedMessageRoom) return false
        if (!this.selectedMessageRoom.is_voted) return false
        if (this.selectedMessageRoom.is_owner_group) return false
        return true
    }

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("CancelAppointmentModal")
    }

    public selectAction(e) {
        Logger(`${funcName()} action:${e}, loading:${this.loading}`)
        if (this.loading) return
        this.action = e

        if (e == `force_fix`) {
            // force_fixの場合、強制確定モーダルを表示させます.
            let startTime = this.selectedMessageRoom.current_appointment.start_time
            let duration = this.selectedMessageRoom.current_appointment.duration
            this.changeableEv = PossibleDate.createFromStartTime(startTime, duration)
        }
    }

    fixVotedRoom() {
        Logger(`${funcName()} action:${this.action}`)
        if (!window.confirm(`強制確定しますが、よろしいですか？`)) {
            return
        }

        let room = this.selectedMessageRoom
        if (room.is_voted && this.changeableEv) {
            Logger(`${funcName()} room:${room.room_id}, changeableEv:${Util.output(this.changeableEv)}`)
            if (this.loading) return
            this.loading = true
            this.rm.startProgress()

            Appointment.fixVotedRoom(
                room,
                this.changeableEv,
                `attendees_only`,
                null,
                null,
                this.typedMessage,
                true,
                null,
                null,
                null,
                null,
                null,
                this.rm.userInfo,
                null,
                null,
                `fix_voted_rooms_with_force_fix`
            ).then(res => {
                this.rm.endProgress()
                this.loading = false
                Logger(`ModalCancelApointment.fixVotedRoom res:${Util.output(res)}`)
                if (res) {
                    this.$emit(`updatedVotedRooms`, true, res)
                } else {
                    this.$emit(`updatedVotedRooms`, false, null)
                }
            })
        }
    }

    send() {
        if (this.action == `force_fix`) {
            this.fixVotedRoom()
            return
        }
        if (this.loading) return
        gtagClick(`sendCancel ${this.action}`, `${this.typedMessage} ${this.appo.id}`)

        this.loading = true
        this.rm.startProgress()
        if (this.selectedMessageRoom.is_voted) {
            Appointment.cancelVotedRoom(this.selectedMessageRoom, this.action, this.typedMessage).then(dic => {
                this.rm.endProgress()
                this.loading = false
                if (dic) {
                    this.$emit(`updatedVotedRooms`, true, dic)
                } else {
                    this.$emit(`updatedVotedRooms`, false, null)
                }
            })
        } else if (this.appoStatus == Appointment.typeReschedule && (this.pdm.currentPd || this.pdm.currentPds)) {
            if (Util.isPresent(this.pdm.currentPds)) {
                gtagClick(`強制再調整 提案`, `${(this.pdm.currentPds || []).length}`)
            } else if (Util.isPresent(this.pdm.currentPd)) {
                gtagClick(`強制再調整 確定`, `${this.pdm.currentPd.jp_format}`)
            }

            FormField.send(
                this.rm.currentRoom,
                this.pdm.currentPd ? this.pdm.currentPd.start_time : null,
                (this.pdm.currentPds || []).map(pd => pd.start_time),
                this.pdm,
                `complete`,
                false,
                true,
                this.typedMessage
            ).then(res => {
                this.rm.endProgress()
                this.loading = false
                if (res) {
                    // this.rm.userInfo = res.userInfo
                    let userInfo = res.userInfo as RoomMember
                    this.rm.setUserInfo(userInfo)
                    let room = res.room as Room

                    // 確定できずにnextAction==`reload`だった場合、モーダルを表示し、日程候補をリロードします.
                    let nextAction: string = res.next_action
                    let type: string = res.type

                    // appoを入れ直すと、getPossiblesが走ってしまうので、Room情報のみ更新する.
                    this.rm.currentRoom = room
                    let _room = (this.rm.rooms || []).find(r => r.room_id == room.room_id)
                    if (_room) {
                        Object.assign(_room, room)
                    } else {
                        let _rooms = this.rm.rooms ? [...this.rm.rooms] : []
                        _rooms.push(room)
                        this.rm.rooms = _rooms
                    }
                    if (Util.isPresent(res.astag)) {
                        this.cm.astag = res.astag
                    }
                    if (Util.isPresent(res.event_invited_info)) {
                        this.cm.registered_attendees = res.registered_attendees
                    }
                    let _appo = room.current_appointment

                    if (_appo.status == 10) {
                        this.rm.currentAppointment = _appo
                    }
                    // this.pdm.resetPdm()
                    this.pdm.setNew(room, userInfo, _appo)

                    // 埋まっていた場合はモーダルを表示し、再調整を促します。
                    if (type == `fix_schedule_failed` && nextAction == `reload`) {
                        this.$emit(`rescheduledRoom`, _appo, true)
                        this.$vfm.close("CancelAppointmentModal")
                        return
                    }

                    if (_appo.status == 1) {
                        // 提案中です.
                        this.pdm.updateDisplayType(`suggested`)
                    } else if (_appo.status == 10) {
                        this.pdm.updateDisplayType(`fixed`)
                    }
                }

                this.$vfm.close("CancelAppointmentModal")
            })
        } else {
            if (this.appoStatus == Appointment.typeReschedule) {
                this.action = `reschedule`
            }
            Appointment.cancel(this.appo, this.action, this.typedMessage).then(_appo => {
                if (_appo) {
                    this.rm.currentRoom.appointments[this.rm.currentRoom.appointments.length - 1].status =
                        Appointment.statusCancel
                    this.pdm.resetPdm()
                    if (this.action == `reschedule`) {
                        this.rm.appointments.push(_appo)
                        this.rm.currentAppointment = _appo
                        this.rm.currentRoom.current_appointment = _appo

                        this.$emit(`rescheduledRoom`, _appo)
                    } else {
                        this.rm.currentAppointment.status = Appointment.statusCancel
                        this.pdm.appo = _appo
                        this.pdm.updateDisplayType(`canceled`)
                        this.$emit(`canceledRoom`, _appo)
                    }
                    this.cancelInput()
                    this.typedMessage = ``
                }
                this.rm.endProgress()
                this.loading = false
            })
        }
    }

    public inputMessage(e) {
        this.typedMessage = e
    }

    showRescheduleButton() {
        let room = this.rm.currentRoom
        if (room) {
            let [isOpen, msg] = Util.isOpenTime(room, true)
            if (isOpen) {
                return true
            } else {
                return false
            }
        }
        return true
    }

    /**
     * オーナーグループであれば、再調整から強制確定ボタンを表示します。
     */
    showCustomFixButton() {
        let room = this.selectedMessageRoom
        if (room) {
            if (room.is_owner_group && room.is_voted) {
                let [isOpen, msg] = Util.isOpenTime(room, true)
                return isOpen
            }
        }
        return false
    }

    updateAppoTime(startTime: DateTime, endTime: DateTime, duration: number) {
        Logger(`${funcName()} startTime:${Util.output(startTime)}, endTime:${Util.output(endTime)}, duration:${duration}`)
        this.changeableEv.start_time = startTime.toSeconds()
        this.changeableEv.duration = duration
    }
}
