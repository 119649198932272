
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"

// コンポーネントを読込.

// モデルを読込.

@Options({
    components: {},
})
export default class MailPreviewPartsBlueButton extends Vue {
    @Prop()
    buttonName: string

    // data
    isSP = isMobile(window.navigator).phone

    created() {}

    mounted() {}
}
