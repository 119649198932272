
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({
    components: {},
})
export default class IconLock extends Vue {
    @Prop()
    is_white: boolean

    @Prop()
    width: number
}
