
// モジュールを読込.
import { Vue, Options, Prop, Watch, Ref } from "vue-property-decorator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger, sleep } from "packs/common"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import LocationTag from "packs/models/LocationTag"
import UserFile from "packs/models/UserFile"

@Options({
    components: { MdTextfieldTag, VueFinalModal, MdProgressBar },
})
export default class InputFileNameModal extends Vue {
    @Prop()
    file: UserFile

    @Ref()
    InputField

    // data
    rm = RoomManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validLocation = true

    fileName = ``
    saving = false
    notice = Notice
    uf: UserFile = null

    openModal() {
        this.updateFile()

        sleep(200).then(_ => {
            if (this.InputField) {
                this.InputField.focusInput(true)
            }
        })
    }

    @Watch(`file`)
    updateFile() {
        if (Util.isBlank(this.file)) return

        this.uf = this.file
        this.fileName = this.uf.original_filename
    }

    enterFileName() {
        this.fixName()
    }

    public inputFileName(e) {
        this.fileName = e
    }

    public checkFileName() {
        this.validLocation = this.fileName && this.fileName.length > 0
    }

    public closeModal() {
        this.$vfm.close(`InputLocationModal`)
    }

    // public fadeOutModal() {
    //     // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
    //     Logger(`フリーフォームを閉じます`)
    //     this.$emit(`updatedLocation`, null)
    // }

    public fixName() {
        this.checkFileName()
        if (!this.validLocation) {
            this.notice.message = `ファイル名を正しく入力してください`
            return
        }

        // 場所情報を変更する処理.
        if (this.loading) return
        this.loading = true

        this.uf.original_filename = this.fileName

        UserFile.rename(this.uf).then(data => {
            this.loading = false
            if (data) {
                this.$emit(`renamed`, data.file)
            }
        })
    }
}
