
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, sleep, funcName } from "packs/common"
import { gtagClick, gtagHover } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import SelectableImage from "packs/components/images/SelectableImage.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import AppointmentInfoContent from "packs/pages/link/AppointmentInfoContent.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectLocationsList from "packs/pages/link/parts/room_settings/SelectLocationsList.vue"
import FormFieldEditContent from "packs/pages/link/parts/room_settings/FormFieldEditContent.vue"
import FormFieldsList from "packs/pages/link/parts/room_settings/FormFieldsList.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import ThanksPageEditContent from "packs/pages/link/parts/room_settings/ThanksPageEditContent.vue"
import AttendeesTable from "packs/pages/link/parts/room_settings/AttendeesTable.vue"
import MailEditContent from "packs/pages/link/parts/room_settings/MailEditContent.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import WeekdayButtons from "packs/components/buttons/WeekdayButtons.vue"
import AvailableDaysOfWeeksTableCell from "packs/pages/link/parts/available_schedule/AvailableDaysOfWeeksTableCell.vue"
import LeftNavPanelCalendarContent from "packs/pages/link/parts/room_settings/left_panel_parts/LeftNavPanelCalendarContent.vue"
import ImageBoardModal from "packs/pages/link/modals/ImageBoardModal.vue"
import UploadImageButton from "packs/components/buttons/UploadImageButton.vue"
import ProviderEventEditContent from "packs/pages/link/parts/room_settings/left_panel_parts/ProviderEventEditContent.vue"
import InstantRoomTextEditContent from "packs/pages/link/parts/room_settings/left_panel_parts/InstantRoomTextEditContent.vue"
import TemplateForAiEditContent from "packs/pages/link/parts/room_settings/left_panel_parts/TemplateForAiEditContent.vue"
import LocationConvertersList from "packs/pages/link/parts/room_settings/left_panel_parts/LocationConvertersList.vue"
import AvailableFieldsForAiEditContent from "packs/pages/link/parts/room_settings/left_panel_parts/AvailableFieldsForAiEditContent.vue"
import LeftNavPanelPPTosSettingsContent from "packs/pages/link/parts/room_settings/left_panel_parts/LeftNavPanelPPTosSettingsContent.vue"
import LeftNavPanelAppealContent from "packs/pages/link/parts/room_settings/left_panel_parts/LeftNavPanelAppealContent.vue"
import FieldConvertersList from "packs/pages/link/parts/room_settings/left_panel_parts/FieldConvertersList.vue"
import GoogleSpreadSheetFileSelector from "packs/pages/link/parts/room_settings/left_panel_parts/GoogleSpreadSheetFileSelector.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import UserFile from "packs/models/UserFile"
import Room from "packs/models/Room"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import MessageTemplate from "packs/models/MessageTemplate"
import RoomSetting from "packs/models/RoomSetting"
import UserSetting from "packs/models/UserSetting"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AppealContent from "packs/models/AppealContent"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import LocationConverter from "packs/models/LocationConverter"
import FormCreateManager from "packs/models/FormCreateManager"

@Options({
    components: {
        SelectableImage,
        AddressBookContent,
        Switcher,
        AppointmentInfoContent,
        TextareaTag,
        SelectLocationsList,
        FormFieldEditContent,
        FormFieldsList,
        TextfieldTag,
        LblueListContent,
        ThanksPageEditContent,
        AttendeesTable,
        MailEditContent,
        ModalTemplates,
        SelectTag,
        AvailableDaysOfWeeksTableCell,
        WeekdayButtons,
        LeftNavPanelCalendarContent,
        ImageBoardModal,
        UploadImageButton,
        ProviderEventEditContent,
        InstantRoomTextEditContent,
        TemplateForAiEditContent,
        LocationConvertersList,
        AvailableFieldsForAiEditContent,
        LeftNavPanelPPTosSettingsContent,
        LeftNavPanelAppealContent,
        FieldConvertersList,
        GoogleSpreadSheetFileSelector,
    },
})
export default class LeftNavPanel extends Vue {
    // data:

    // image: ヘッダー画像, headerContent: ヘッダーテキスト, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択
    // appo: タイトル/時間, message: オーナーからのメッセージ, pptos: pp/tosの変更
    @Prop()
    panelType: string

    @Prop()
    isPreview: boolean

    @Prop()
    isSuspend: boolean

    // @Prop() // func
    // inputMemo

    @Prop() // func
    linkCal

    @Prop()
    editingField: FormField

    // @Prop()
    // currentRoom: Room

    @Prop()
    astag: AvailableScheduleTag

    @Ref()
    MailEditContent

    @Ref()
    AppointmentInfo

    @Ref()
    FormFieldEditContent

    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager
    fcm = FormCreateManager

    groupTitle = ""
    isSP = isMobile(window.navigator).phone
    fixedTypes = [`fixed`, `already`]

    attendees: RoomMember[] = null // room.attendeesと同義.
    owner: RoomMember = null
    showTip = false

    // beforeAppo: Appointment = null;
    Appointment = Appointment

    appoStatus = Appointment.typeNew
    doesChangeAppo: boolean
    notice = Notice
    Util = Util
    UserPermissionsOption = UserPermissionsOption
    CalendarUtil = CalendarUtil
    saving = false
    // showShareButton: boolean;
    // astag: AvailableScheduleTag = null

    locationName = ``
    currentAppo: Appointment = null
    contentWidth = 350

    // 公開で保存している画像.（UGごと）
    userFiles: UserFile[] = null
    loading = false
    isDrag = false

    imageUrl: string = null
    beforeUrl: string = null
    currentImageUrl: string = null

    imageTab = `custom`
    calendarTab = `list` // list / settings
    sampleImages = UserFile.createSampleFiles()

    currentMember: RoomMember = null
    editOrNewForm = ``

    locationNames: string[] = null
    titles: string[] = null

    // room: Room = null

    loadingLocations = false
    loctags: LocationTag[] = null
    selectedLoctags = null

    systemUpdatedAt = Util.getSec()
    field: FormField = null
    tmp: MessageTemplate = null

    timeArray = SelectUtil.timeArray
    startTimeStr = `10:00`
    dayOfTheWeek = [1, 2, 3, 4, 5]
    defaultStepSizeTitle = ``

    mounted() {
        this.updateLocations()
        this.updateImages()
    }

    openPanel() {}

    updateImages() {
        if (this.rm.userFiles) {
            this.userFiles = this.rm.userFiles
            return
        }
        axios.get(`${Util.prefixUrl}/file_uploaders/index_public_images`).then(e => {
            let _images = e.data.images
            if (_images) {
                let images = UserFile.fetchFromJson(_images)
                this.userFiles = images
                this.rm.userFiles = images
                return this.userFiles
            }
        })
    }

    updateLocations() {
        this.rm.getLocations().then(e => {
            Logger(`登録済みのロケーション情報を取得してきました: ${Util.output(e)} `)
            this.loctags = e || []
        })
    }

    @Watch("rcm.room", { deep: true })
    public updateLocationsInfo() {
        if (!this.rcm.room) return
        if (!this.rm.userInfo) return
        if (this.locationNames) return

        if (this.rm.locationNames) {
            this.locationNames = this.rm.locationNames
            return
        }
        if (this.loadingLocations) return
        this.loadingLocations = true

        Logger(`場所情報を取得してきます。`)
        this.rm.getLocationNames().then(locationNames => {
            this.locationNames = locationNames || []

            Logger(`場所情報: ${locationNames}`)
            this.loadingLocations = false
        })
    }

    @Watch("rcm.room", { deep: true })
    public updateTitlesInfo() {
        if (!this.rcm.room) return
        if (!this.rm.userInfo) return
        if (this.titles) return

        Logger(`LeftNavPanel#タイトルを取得します.`)
        this.rm.getTitles().then(titles => {
            if (titles) {
                Logger(`タイトル: ${titles}`)
                this.titles = titles
            }
        })
    }

    public closePanel() {
        this.$emit(`closePanel`)
    }

    deleteHeaderImage() {
        let apc = { ...this.rcm.appeal_content }
        apc.image_url = null
        apc.presetInfo = false
        this.rcm.updateAppealContent(apc)
    }

    public changeImage(file) {
        Logger(`changeImage: ${file.image_url}`)
        // this.mag.profile_image_urls[0]
        this.imageUrl = file.image_url
        this.beforeUrl = file.image_url
        // this.imageUrl = file.image_url
        this.updateImage()
    }

    public mouseoverImage(file) {
        this.imageUrl = file.image_url
    }

    public mouseleaveImage() {
        this.imageUrl = this.beforeUrl
    }

    /**
     * リモートから取得したURLをはめます.
     */
    public uploadedUserImage(file: UserFile) {
        if (!file) return
        this.imageUrl = file.image_url
        this.beforeUrl = file.image_url
        // this.mag.profile_image_urls = [file.image_url]
        this.userFiles.push(file)
        this.rm.userFiles = this.userFiles
        // this.rm.userInfo.image_url = image_url
    }

    public updateImage() {
        let apc = this.rcm.appeal_content
        if (Util.isBlank(apc)) apc = AppealContent.createDefault()

        apc.image_url = this.imageUrl
        apc.presetInfo = false
        this.rcm.updateAppealContent(apc)
    }

    public deleteImage(file: UserFile) {
        if (confirm(`削除してよろしいですか？この操作は元に戻せません。`)) {
            UserFile.delete(file.file_id).then(e => {
                this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
                this.rm.userFiles = this.userFiles
            })
        }
    }

    public addAttendee(member: RoomMember) {
        Logger(`memberを追加します : ${Util.output(member)}`)
        if (member.email == this.rm.userInfo.email) return
        let members = this.rcm.members
        let _mem = members.find(m => m.email == member.email)
        // let mems = this.members.filter(m => m.email != member.email && m.email != this.rm.userInfo.email)
        if (Util.isBlank(_mem)) {
            members.push(member)
        } else {
            Object.assign(_mem, member)
        }
        Logger(`addAttendee roommeberを更新しました: ${this.rcm.members.length}`)
        this.updateMembers(members)
        this.currentMember = null
    }

    public editAttendee(member) {
        this.currentMember = member
        this.editOrNewForm = `edit`
        gtagClick(`出席者を編集`, `出席者:${this.rcm.members.length}`)
        // this.$vfm.open("AttendeesModal");
    }

    /**
     * 選択したメンバーを削除します。
     */
    public deleteMember(member) {
        let members = this.rcm.members.filter(mem => mem != member)
        this.updateMembers(members)
        gtagClick(`出席者を削除`, `出席者:${this.rcm.members.length}`)
    }

    /**
     * 出席するメンバー情報を更新します。
     */
    public updateMembers(members: RoomMember[]) {
        // let room = { ...this.rcm.room }
        // room.members = members
        // this.rcm.room = room
        // this.rcm.members = members
        this.rcm.updateMembers(members)
        //
        // if (this.tmp) {
        //     let atts = this.rcm.members.filter(m => m.user_id != this.rm.userInfo.user_id)
        //     let owner = this.rcm.members.find(m => m.role == 100) || this.rm.userInfo
        //     let str = TemplateUtil.insertTemplateText(this.tmp.content, atts, owner, this.rcm.appo, this.rm.userInfo)
        //     // this.inputMemo(str, this.tmp)
        // }
    }

    public selectLocations(loctags: LocationTag[]) {
        Logger(`selectLocations[${(loctags || []).length}]: ${Util.output(loctags)}`)
        let _appo = Appointment.setLocationSelectType(this.rcm.appo, loctags)

        if (Util.isBlank(loctags)) {
            _appo.location_name = null
            _appo.location_tag_id = ``
        }
        // selected_locationはloctagsの1つ目を設定しておきます.
        else {
            _appo.selected_location = loctags[0]
        }

        this.selectedLoctags = loctags
        _appo.selectable_locations = loctags
        this.updateAppo(_appo)
        // this.$emit(`selectLocations`, loctags)
    }

    updateAppo(appo: Appointment) {
        this.rcm.updateAppo(appo)
    }

    public selectField(field: FormField) {
        Logger(`fieldを追加します: ${Util.output(field)}`)
        // 特殊フィールドの場合、すでに追加済みか確認.
        if (FormFieldCreator.fieldTypeSpecialParamKeyArray(true).includes(field.param_key)) {
            let ffs = this.rcm.fields
            let paramKeys = ffs.map(ff => ff.param_key)
            if (paramKeys.includes(field.param_key)) {
                Notice.message = `すでに追加済みの特殊フィールドです。特殊フィールドはフォームに1つのみ追加可能です。`
                return
            }
        }

        // オプションを使えない場合、モーダルを表示します.
        this.field = field
        // パネルタイプを変更
        this.$emit(`addField`, this.field)
    }

    @Watch("editingField", { deep: true })
    updateField() {
        if (!this.editingField) return
        this.field = this.editingField
    }

    public updateFieldFromChild(field: FormField) {
        let fields = this.rcm.fields ? [...this.rcm.fields] : []
        let _f = fields.find(f => f.index_num == field.index_num)
        Object.assign(_f, field)
        this.rcm.updateFormFields(fields)
    }

    inputLocationName(e) {
        let _appo = Appointment.copy(this.rcm.appo)
        _appo.location_name = e
        _appo = LocationTag.searchAndSetLoctag(e, this.loctags, _appo)
        // let _appo = Appointment.setLocationSelectType(_appo, loctags)
        Logger(`inputLocationName appoを更新します: ${Util.output(_appo)}`)

        this.updateAppo(_appo)
    }

    public setLocationInfo(loc) {
        let _appo = Appointment.copy(this.rcm.appo)
        _appo.location_name = loc
        _appo = LocationTag.searchAndSetLoctag(loc, this.loctags, _appo)
        this.updateAppo(_appo)
    }

    decideTemplate(tmp: MessageTemplate) {
        this.tmp = tmp
        this.MailEditContent.decideTemplate(tmp)
    }

    changeImageTab(tabname: string) {
        // this.imageTab = tabname
        if (tabname == `custom`) {
            this.$vfm.open(`ImageBoardModal`)
        } else {
            this.imageTab = tabname
        }
    }

    inputThanksPageTitle(e) {
        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.thanks_page.title = e
        this.updateRoomSetting(setting)
    }

    inputThanksPageContent(e) {
        let setting = RoomSetting.findOrCreateSetting(this.rcm.room, this.rm.user_setting)
        setting.thanks_page.content = e
        this.updateRoomSetting(setting)
    }

    updateStepSize(time: string) {
        let rs = { ...this.rcm.room_setting }
        rs.step_size_type = time
        this.updateRoomSetting(rs)
    }

    updateRoomSetting(setting: RoomSetting) {
        this.rcm.updateRoomSetting(setting)
    }

    selectFile(image: UserFile) {
        Logger(`LeftNavPanel.selectFile: ${Util.output(image)}`)
        // 画像を選択しました.
        if (this.panelType == `image`) {
            this.changeImage(image)
        } else if (this.panelType == `field`) {
            this.FormFieldEditContent.selectFile(image)
        }
        this.$vfm.close(`ImageBoardModal`)
    }

    showImageBoardModal(currentImageUrl: string) {
        this.currentImageUrl = currentImageUrl
        this.$vfm.open(`ImageBoardModal`)
    }

    changeSendChannelMode() {
        if (this.rcm.showSMSField) {
            this.rcm.showSMSField = false
        } else {
            this.rcm.showSMSField = true
        }
    }

    get canSendSMS() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_send_sms`, false)
    }

    selectLocationConverter(conv: LocationConverter) {
        let rs = { ...this.rcm.room_setting }
        let convs = rs.location_converters || []
        let convsIds = convs.map(c => c.location_converter_id)
        if (convsIds.includes(conv.location_converter_id)) return

        convs.push(conv)
        rs.location_converters = convs
        this.rcm.updateRoomSetting(rs)
    }
}
