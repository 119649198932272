
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"

// コンポーネントを読込.

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import VotedRoomManager from "packs/models/VotedRoomManager"

@Options({
    components: {},
})
export default class SuggestedDateButtons extends Vue {
    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    // loading = false
    Util = Util
    CalendarUtil = CalendarUtil

    /**
     * 押下した日程の提案のみを表示します.
     * @param dateDic
     */
    showFilterSuggestedDate(dateDic: any) {
        this.$emit(`showFilterSuggestedDate`, dateDic)
    }
}
