
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"

// コンポーネントを読込.
import MemberDailyLine from "packs/pages/link/parts/available_schedule/MemberDailyLine.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import RoomStorage from "packs/models/RoomStorage"
import Event from "packs/models/Event"
import PossibleDate from "packs/models/PossibleDate"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import PossibleDatesConditions from "packs/models/PossibleDatesConditions"

const roomStorage = new RoomStorage()

@Options({
    components: {
        MemberDailyLine,
    },
})
export default class ScheduleSlideContent extends Vue {
    @Prop()
    buttonName

    @Prop()
    loading

    @Prop()
    from: string // asview / schedule / schedule_custom

    @Prop()
    dailyEvents

    hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    dailyMembersEvents = null

    pdm = PossibleDatesManager
    pdc = PossibleDatesConditions
    currentPd: PossibleDate = null

    currentTime?: DateTime = null
    currentHour?: number = null
    currentMin?: number = null
    barHeight = 0
    // openChildCals
    travelTimeBefore = 0 // minutes
    travelTimeBeforeStart: DateTime = null
    travelTimeAfter = 0 // minutes
    travelTimeAfterStart: DateTime = null
    rm = RoomManager
    myAttendee: RoomMember = null
    duration = 0
    magDic: MeetingAttendeesGroup = null

    mounted() {
        this.changePdFromParent()
        this.calcurateOrangeBar(null)
    }

    @Watch("pdm.currentPd", { deep: true })
    public changePdFromParent() {
        Logger(
            `${funcName()} 送られてきたpd: ${Util.output(this.pdm.currentPd)}\n sharedMembers: ${Util.output(
                this.pdm.sharedMembers
            )} \npdm: ${Util.output(this.pdm)} `
        )
        if (!this.pdm || !this.pdm.currentPd) return
        this.currentPd = this.pdm.currentPd
        this.magDic = null
        // let dailyPossibleDates = this.pdm.dailyPossibleDates
        this.currentTime = DateTime.fromSeconds(this.currentPd.start_time)
        this.duration = this.currentPd.duration
        Logger(`${funcName()} currentTime: ${this.currentTime.toFormat(`MM/dd HH:mm`)}`)
        this.dailyMembersEvents = this.pdm.dailyPossibleDates[this.currentPd.date_format]
        this.currentHour = this.currentTime.hour
        this.currentMin = this.currentTime.minute
        if (!this.myAttendee) {
            if (this.from == `schedule`) {
                let myAttendee = this.rm.currentRoom.attendees.find(m => m.user_id == this.rm.userInfo.user_id)
                if (myAttendee) this.myAttendee = myAttendee
            } else {
                this.myAttendee = this.rm.userInfo
            }
        }

        if (this.myAttendee) {
            Logger(`My Attendee: ${Util.output(this.myAttendee)}, dailyMembersEvents: ${Util.output(this.dailyMembersEvents)}`)
            this.calcurateTravelTime()
        }
    }

    resetTravelTime() {
        this.travelTimeBefore = 0 // minutes
        this.travelTimeBeforeStart = null
        this.travelTimeAfter = 0 // minutes
        this.travelTimeAfterStart = null
    }

    /**
     * いったん全体のバッファは表示させないように変更.
     */
    calcurateTravelTime() {
        this.resetTravelTime()
        // 選択中のmagがない場合、移動アイコン表示不要.
        if (!this.magDic) return

        let dailyMyEvents
        if (Util.isPresent(this.myAttendee)) {
            dailyMyEvents = this.dailyMembersEvents[this.myAttendee.user_id]
        } else {
            if (this.rm.astag && this.rm.astag.is_shared) {
                Logger(`${funcName()} 共有されている調整カレンダーです rm.astag:${Util.output(this.rm.astag)}`)
                dailyMyEvents = this.dailyMembersEvents[this.rm.astag.user_id]
            }
        }

        if (!dailyMyEvents) return

        // for (let [magId, _magDic] of Object.entries(dailyMyEvents.group_events_dic)) {
        //     let magDic = _magDic as any
        //     if (this.magDic.id != magId) continue

        //     Logger(`magId: ${magId}, name: ${magDic.name}`)
        //     // let ev = magDic.possible_events[0]
        //     if (!magDic.possible_events) continue

        //     // possible_eventsは幅のイベント.
        //     for (let _ev of magDic.possible_events) {
        //         let eventEndTime = _ev.start_time + _ev.duration * 60
        //         let pdEndTime = this.currentPd.start_time + this.currentPd.duration * 60
        //         if (_ev.start_time < this.currentPd.start_time && eventEndTime > pdEndTime) {
        //             this.travelTimeBefore = _ev.before_travel_time || 0 //min width
        //             this.travelTimeBeforeStart = this.currentTime.minus({
        //                 minutes: this.travelTimeBefore,
        //             })
        //             this.travelTimeAfter = _ev.after_travel_time || 0 //min width
        //             this.duration = this.currentPd.duration
        //             this.travelTimeAfterStart = this.currentTime.plus({
        //                 minutes: this.duration,
        //             })
        //             Logger(
        //                 `トラベルタイムをみます before: ${
        //                     this.travelTimeBefore
        //                 } ${this.travelTimeBeforeStart.toISO()}, after: ${
        //                     this.travelTimeAfter
        //                 } ${this.travelTimeAfterStart.toISO()}`
        //             )
        //             break
        //         }
        //     }
        //     // if (!ev) continue
        //     // 設定したバッファタイムを表示.（仮置き30分）
        // }
    }

    /**
     * 選択しているMeetingAttendeesGroupのIDも一緒に送ります。
     */
    public calcurateOrangeBar(magId: string) {
        Logger(`バーの高さを変更します。 `)
        const parentNum = $(".parentCalendarContent").length
        const childNum = $(".childCalendarContent").length

        // 開閉情報を記憶.
        if (childNum > 0) {
            this.pdc.openDetailSchedules = true
        } else {
            this.pdc.openDetailSchedules = false
        }
        this.barHeight = parentNum * 100 + childNum * 60 - 10
    }

    public selectMag(magId) {
        Logger(`${funcName()} magId:${magId}`)
        if (!magId) {
            this.magDic = null
            this.calcurateTravelTime()
            this.$emit(`select`, null)
            return
        }
        // let dailyMyEvents = this.dailyMembersEvents[this.myAttendee.user_id]
        let magDic = this.pdm.mag_dic[magId]
        if (!magDic) return

        this.magDic = magDic
        let name = this.magDic.name
        // let name = dailyMyEvents.group_events_dic[magId].name
        // this.magDic = { id: magId, name: name }

        if (!this.magDic && this.pdm.selectable_mag_dic) this.magDic = this.pdm.selectable_mag_dic[magId]
        this.calcurateTravelTime()
        this.$emit(`select`, this.magDic)
    }

    public goSummary() {
        const room = this.rm.currentRoom
        this.rm.updateCurrentInfo(room)
        if (!room.is_owner || room.status != 101) {
            return
        }
        roomStorage.save(room)
        roomStorage.saveAppointment(room.current_appointment)
        this.$router.push("/summary")
    }
}
