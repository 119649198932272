
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"
import draggable from "vuedraggable"

// コンポーネントを読込.
import MdRadioButtonTag from "packs/components/forms/MdRadioButtonTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import ChipButton from "packs/components/buttons/ChipButton.vue"
import ModalEditCalendarTag from "packs/pages/link/modals/ModalEditCalendarTag.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import AttendeesGroupField from "packs/pages/link/parts/available_schedule/AttendeesGroupField.vue"
import GroupProfileModal from "packs/pages/link/modals/GroupProfileModal.vue"
import ConnectZoomModal from "packs/pages/link/modals/ConnectZoomModal.vue"
import OptionModal from "packs/components/modals/OptionModal.vue"
import SearchCalendarsContent from "packs/pages/link/parts/available_schedule/SearchCalendarsContent.vue"
import CalendarTable from "packs/components/calendar/CalendarTable.vue"
import PossibleDatesContent from "packs/pages/link/parts/available_schedule/PossibleDatesContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import LocationTag from "packs/models/LocationTag"
import DateTag from "packs/models/DateTag"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import CalendarUtil from "packs/utils/CalendarUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextfieldTag,
        ChipButton,
        ModalEditCalendarTag,
        Switcher,
        AttendeesGroupField,
        MdRadioButtonTag,
        CheckboxTag,
        GroupProfileModal,
        ConnectZoomModal,
        draggable,
        OptionModal,
        SearchCalendarsContent,
        CalendarTable,
        PossibleDatesContent,
    },
})
export default class ConnectedCalendar extends Vue {
    // @Prop()
    // updateAttendees

    cm = CalendarManager
    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    SelectUtil = SelectUtil
    notice = Notice
    CalendarUtil = CalendarUtil

    editIndex = -1 // MeetingAttendeesGroupの何番目の情報を編集中か.
    editCtag = null

    // 自分がカレンダー連携していなくても、他の人のカレンダーを利用している場合に有効にするフラグ。
    showMagEvenIfNotConnectCal = false

    magForProfile: MeetingAttendeesGroup = null

    drag = false
    zoomLoctags: CalendarTag[] = null
    optionEventName = "調整カレンダーOR制限 課金リクエスト"

    // list ctagリストを表示
    // calendar CalendarTableを表示（）
    isOpenDates = false

    public mounted() {
        this.updateAstagData()

        this.cm.getOnlineLocations().then(_loctags => {
            this.zoomLoctags = _loctags
        })
    }

    updateAstagData() {
        if (!this.cm.astag) return

        if (Util.isBlank(this.cm.mags)) return

        if (this.cm.showAttendeesEdit()) {
            Logger(`${funcName()} リモートからカレンダーリストを取得します。`)

            this.rm.startProgress()
            this.cm.getCalList().then(res => {
                this.rm.endProgress()
            })
        }

        let mags: MeetingAttendeesGroup[] = [...(this.cm.mags || [])]
        Logger(`${funcName()} length:${mags.length} ${Util.output(mags)}`)
        if (mags.length == 0) {
            this.editIndex = -1
        } else {
            // mags.forEach((mag,index) => {

            // })

            if (mags.length == 1) {
                const mag = this.cm.mags[0] || MeetingAttendeesGroup.createDefault()
                if (mag.required_attendees.length == 0) {
                    // 開いておく.
                    this.editGroup(this.cm.mags.length - 1)
                } else {
                    this.editIndex = -1
                }
            } else {
                this.editIndex = -1
            }
        }
    }

    beforeUnmount() {
        if (this.editIndex != -1) {
            this.fixedAttendeesInGroup()
        }
    }

    public getDefaultMagTypeName() {
        const defaultValue = `display_none`
        const typeArray = SelectUtil.selectMagType
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.cm.astag) return defaultName
        const currentOpt = typeArray.find(t => t.type == this.cm.astag.mag_type)
        return currentOpt ? currentOpt.name : defaultName
    }

    public inputMagType(e) {
        let magTypeDic = SelectUtil.selectMagType.find(mt => mt.name == e)
        this.$emit(`changeMagType`, magTypeDic.type)
    }

    public clickRegisterCalPrivate(dic) {
        if (this.cm.astag.is_private) {
            this.$emit("changePrivate", false)
        } else {
            this.$emit("changePrivate", true)
        }
    }

    public openModalEditCalendarTag(ctag: CalendarTag) {
        this.editCtag = this.cm.editStartCtag(ctag)
        // this.selectedCtag = ctag;
        // this.selectedCtag.systemUpdatedAt = Util.getSec()
        this.$vfm.open(`EditCalendarTagModal`)
    }

    public closeModalEditCalendarTag() {
        this.$vfm.close(`EditCalendarTagModal`)
    }

    /**
     * カレンダー連携 ON/OFF
     * 変更した際に個別に登録したOptionalScheduleがある場合は、「削除しますか？」のモーダルを表示.
     */
    public changeConnectCalendar() {
        let canConnectCalendar = false
        if (!this.cm.astag.can_connect_calendar) {
            canConnectCalendar = true
        }
        Logger(`${funcName()} canConnectCalendar:${canConnectCalendar}`)

        this.cm.astag.can_connect_calendar = canConnectCalendar
        if (canConnectCalendar) {
            this.showMagEvenIfNotConnectCal = true
            if (Util.isBlank(this.cm.astag.available_days_of_weeks)) {
                this.cm.astag.available_days_of_weeks = [DateTag.createWeekDayMorning(), DateTag.createWeekDayAfternoon()]
            }
            if (Util.isBlank(this.cm.mags)) {
                this.addNextGroupField()
            } else {
                this.editGroup(this.cm.mags.length - 1)
            }
            this.cm.getCalList()
            // this.getEvents()
        } else {
            this.showMagEvenIfNotConnectCal = false
            if (Util.isBlank(this.cm.mags)) {
                // adowを空にします.
                this.cm.astag.available_days_of_weeks = []
            }
            this.cm.getPossibleDatesEvents(true, null)
        }

        // this.$emit("changeConnectCal", canConnectCalendar)
    }

    autoLoadWeeklyCalendar() {
        Logger(`${funcName()} cmのキャッシュクリアします.`)
        // ctagsを更新しているため、cm上のキャッシュをクリアして取得し直します.
        this.cm.resetSchedules()
        // autoloadのためのindexを作成.
        this.cm.autoloadWithIndexName = this.cm.magsToIndexName()
    }

    /**
     * モーダルの表示を保存して閉じます。
     */
    public editEnd(ctag) {
        Logger(`${funcName()} editIndex:${this.editIndex}`)
        this.updateAttendees(this.editIndex, false)
        this.closeModalEditCalendarTag()
    }

    /**
     * 同一グループでのメンバーを確定し、確定UIに変更します。
     */
    public fixedAttendeesInGroup() {
        Logger(`${funcName()} editIndex:${this.editIndex}`)
        this.updateAttendees(this.editIndex, true)
        Logger(`meetingAttendeesGroupsに保存しました。`)
    }

    /**
     * 編集が終わった時点で、ctagが1つも選択されていないAttendeeGroupFieldは削除します。
     */
    public editGroup(magindex: number) {
        Logger(`${funcName()} magindex:${magindex}`)
        // フィールドに選択されている出席者がいない場合はmagを削除します。
        let mags = [...this.cm.mags]
        if (mags.length > 1) {
            mags = mags.filter(mag => Util.isPresent(mag.required_attendees))
        }

        this.editIndex = magindex
        const mag = mags[magindex]
        mags = MeetingAttendeesGroup.addNames(mags)
        this.cm.mags = mags
        this.cm.editStart(mag)
        this.focusSearchFeild()
    }

    focusSearchFeild() {
        sleep(50).then(_ => {
            $(`.CalendarListSearchField`).click()
            $(`.CalendarListSearchField`).focus()
        })
    }

    /**
     * 「または」を追加 を押下して新規のORフィールドを追加します。
     */
    public addNextGroupField() {
        Logger(`${funcName()}`)

        let mag = MeetingAttendeesGroup.createDefault()
        let mags = [...(this.cm.mags || [])]
        mags.push(mag)
        mags = MeetingAttendeesGroup.addNames(mags)
        this.cm.updateMags(mags)
        this.editIndex = this.cm.mags.length - 1
        this.cm.editStart(mag)
        this.focusSearchFeild()
    }

    public deleteGroup(mag: MeetingAttendeesGroup) {
        Logger(`${funcName()} id:${mag.id}`)
        // this.$emit("deleteGroup", mag)
        Logger(`${funcName()} deleteGroup: name:${mag.name}`)
        if (!this.cm.mags) return
        let mags = [...this.cm.mags]
        Logger(`${funcName()} mags.length:${mags.length}`)
        mags = mags.filter(m => m.name != mag.name)
        mags = MeetingAttendeesGroup.addNames(mags)
        this.cm.updateMags(mags)
        Logger(`${funcName()} AFTER cm.mags.length:${this.cm.mags.length} `)
        if (Util.isBlank(mags)) {
            this.addNextGroupField()
        }
    }

    public addProfile(mag: MeetingAttendeesGroup, magindex: number) {
        Logger(`${funcName()} index:${magindex}`)
        this.magForProfile = mag
        this.editIndex = magindex
        this.$vfm.open("GroupProfileModal")
    }

    // プロフィールを設定した後に返ってきます。
    public saveProfile(mag: MeetingAttendeesGroup) {
        if (this.editIndex == -1) return
        Logger(`${funcName()} editIndex:${this.editIndex}, mag:${mag ? mag.name : null}`)
        if (mag) {
            this.cm.edittingMag = mag
            Logger(`${funcName()} updateしなくても言ってるのでは cm.mags.length:${(this.cm.mags || []).length}`)
            // Logger(`アップデートします edittingMag: ${Util.output(mag)}`)
        } else {
            this.cm.edittingMag = this.magForProfile
        }
        this.updateAttendees(this.editIndex, true)
        this.$vfm.close("GroupProfileModal")
    }

    public cancelEdit() {
        this.editIndex = -1
    }

    public addZoom(mag: MeetingAttendeesGroup, magindex: number) {
        this.magForProfile = mag
        this.cm.edittingMag = mag
        this.editIndex = magindex
        this.$vfm.open("ConnectZoomModal")
    }

    /**
     * 選択したzoom(選択順)で返します.
     */
    selectZoom(zoomAccounts: CalendarTag[]) {
        this.magForProfile.online_meeting_resources = zoomAccounts
        // if (Util.isBlank(zoomAccounts)) {
        //     this.magForProfile.online_meeting_resources = null
        // } else {
        // }
        this.cm.edittingMag = this.magForProfile
        this.updateAttendees(this.editIndex, true)
        this.$vfm.close(`ConnectZoomModal`)
    }

    // closeEdit==trueのとき、取得前に、editIndexに-1を入れます.
    updateAttendees(editIndex: number, closeEdit = true) {
        if (editIndex == -1) return

        let mags = [...(this.cm.mags || [])]
        Logger(`updateAttendeesFromChild: index: ${editIndex} ${Util.output(mags)}`)
        if (Util.isPresent(mags)) {
            mags[editIndex] = { ...this.cm.edittingMag }
        } else {
            mags = [{ ...this.cm.edittingMag }]
        }
        if (closeEdit) {
            this.cm.edittingAttendees = null
            this.cm.edittingMag = null
            Logger(`編集を終了します.closeEdit`)
            this.editIndex = -1
            this.magForProfile = null
            // this.cm.editEnd()
        }
        // magsにABCの番振りする.
        mags = MeetingAttendeesGroup.addNames(mags)
        this.cm.updateMags(mags)
        // this.cm.calendarRefreshText = `カレンダーを更新`
        this.autoLoadWeeklyCalendar()
    }

    sortEnd() {
        this.drag = false
        if (Util.isBlank(this.cm.mags)) return
        let mags = [...this.cm.mags]
        mags = MeetingAttendeesGroup.addNames(mags)
        this.cm.updateMags(mags)
        // 表示する週次のイベントを更新.
        this.cm.calendarRefreshText = `並び替えたため、カレンダーを更新してください`
        // this.cm.astag.systemUpdatedAt = Util.getSec()
    }

    clickOK() {
        gtagClick(`${this.optionEventName}`, ``)
        // 送信
        this.rm.startProgress()
        Util.sendInquiry(`${this.optionEventName}がありました`, -1, 16).then(e => {
            if (e.data.result == "ok") {
                this.notice.message = e.data.message
                this.$vfm.close(`OptionModal`)
            } else {
                this.notice.message = "うまく送信できませんでした。後ほどお試しください。"
            }
            // モーダル消す.
            this.rm.endProgress()
        })
    }

    clickCancel() {
        gtagClick(`${this.optionEventName} キャンセル`, ``)
        this.$vfm.close(`OptionModal`)
    }

    showOptionModal() {
        this.$vfm.open(`OptionModal`)
    }

    changeNeedVote(ctag: CalendarTag) {
        Logger(`${funcName()} ${ctag.name}`)
        if (ctag.needVote) {
            ctag.needVote = false
        } else {
            ctag.needVote = true
        }
    }

    /**
     * 選択したctagを削除します。
     */
    public deleteSelectCtag(ctag: CalendarTag) {
        Logger(`${funcName()} ctag: ${Util.output(ctag.name)}`)
        this.cm.deleteSelectedCtag(ctag)
        this.autoLoadWeeklyCalendar()
        // this.searchCalnedarValue(this.searchText)
    }

    showInstantRoomFields() {
        Logger(`${funcName()} rcm.room_setting:${Util.output(this.rcm.room_setting)}`)
        if (!this.rcm.room_setting) return false
        if (!this.rcm.room_setting.is_instant_room) return false
        Logger(`${funcName()} cm.mags:${Util.output(this.cm.mags)}`)

        let mags = this.cm.mags.filter(m => m.purpose_type == `instant_room`)
        return Util.isPresent(mags)
    }
}
