
// モジュールを読込.
import { Options, Prop, Vue, Provide, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard } from "packs/common"
import { gtagLog, gtagClick, gtagHover } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import SelectableImage from "packs/components/images/SelectableImage.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import AppointmentInfoContent from "packs/pages/link/AppointmentInfoContent.vue"
import AddCommentToMail from "packs/pages/link/parts/room_settings/AddCommentToMail.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import SelectLocationsList from "packs/pages/link/parts/room_settings/SelectLocationsList.vue"
import FormFieldEditContent from "packs/pages/link/parts/room_settings/FormFieldEditContent.vue"
import FormFieldsList from "packs/pages/link/parts/room_settings/FormFieldsList.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import ThanksPageEditContent from "packs/pages/link/parts/room_settings/ThanksPageEditContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import PossibleDate from "packs/models/PossibleDate"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import UserFile from "packs/models/UserFile"
import Room from "packs/models/Room"
import LocationTag from "packs/models/LocationTag"
import FormField from "packs/models/FormField"
import MessageTemplate from "packs/models/MessageTemplate"
import RoomSetting from "packs/models/RoomSetting"

@Options({
    components: {
        SelectableImage,
        CalendarIcon,
        Switcher,
        AppointmentInfoContent,
        AddCommentToMail,
        TextareaTag,
        SelectLocationsList,
        FormFieldEditContent,
        FormFieldsList,
        ModalTemplates,
        TextfieldTag,
        LblueListContent,
        ThanksPageEditContent,
    },
})
export default class AttendeesTable extends Vue {
    // data:

    @Prop()
    room: Room

    // image: ヘッダー画像, headerContent: ヘッダーテキスト, attendees: 出席者, locations: appoの場所選択, astag: 調整カレンダー選択
    // appo: タイトル/時間, message: オーナーからのメッセージ, pptos: pp/tosの変更
    @Prop()
    members: RoomMember[]

    // room: Room = null
    Util = Util
    isSP = isMobile(window.navigator).phone
    invitedUsers: RoomMember[] = null

    astags: AvailableScheduleTag[] = null
    loctags: LocationTag[] = null
    selectedLoctags = null

    systemUpdatedAt = Util.getSec()
    field: FormField = null
    truncate = truncate

    public mounted() {
        // this.updateRoom()
    }

    // @Watch("room", { deep: true })
    // updateRoom() {
    //     if (!this.room) return

    //     this.members = Util.isPresent(this.room.members) ? this.room.members : []
    //     Logger(`updateRoom roommeberを更新しました: ${this.members.length}`)
    //     // this.room = Room.copy(this.currentRoom)
    // }

    public editAttendee(member) {
        this.$emit(`editAttendee`, member)
        // this.$vfm.open("AttendeesModal");
    }

    /**
     * 選択したメンバーを削除します。
     */
    public deleteMember(member) {
        this.$emit(`deleteMember`, member)
    }

    /**
     * 出席するメンバー情報を更新します。
     */
    public updateMembers() {
        this.$emit(`updateMembers`, this.members)
    }
}
