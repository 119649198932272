
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import CalendarUtil from "packs/utils/CalendarUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Const from "packs/utils/Const"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import FixVotedRoomsModal from "packs/pages/link/parts/room_summary/FixVotedRoomsModal.vue"
import AnsweredFormModal from "packs/pages/link/parts/room_summary/AnsweredFormModal.vue"
import SuggestedRoomsMembersHeader from "packs/pages/link/parts/room_summary/SuggestedRoomsMembersHeader.vue"
import SuggestedRoomsMembersTable from "packs/pages/link/parts/room_summary/SuggestedRoomsMembersTable.vue"
import SuggestedDateButtons from "packs/pages/link/parts/room_summary/SuggestedDateButtons.vue"

// モデルを読込.
import Room from "packs/models/Room"
import RoomManager from "packs/models/RoomManager"
import VotedRoomManager from "packs/models/VotedRoomManager"
import RoomMember from "packs/models/RoomMember"
import Notice from "packs/models/Notice"
import RoomStorage from "packs/models/RoomStorage"
import RoomHistoryManager from "packs/models/RoomHistoryManager"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Appointment from "packs/models/Appointment"
import FileUtil from "packs/utils/FileUtil"
import LocationTag from "packs/models/LocationTag"

const roomStorage = new RoomStorage()

@Options({
    components: {
        UserIcon,
        FixVotedRoomsModal,
        AnsweredFormModal,
        SuggestedRoomsMembersHeader,
        SuggestedRoomsMembersTable,
        SuggestedDateButtons,
    },
})
export default class SuggestedRoomsForAll extends Vue {
    // data:
    @Prop()
    rooms: Room[] // ParentRoom[]

    @Prop()
    room: Room // PublicRoom

    @Prop() // func
    showRoomModal

    @Ref()
    FixVotedRoomsModal

    @Prop() // 日程だけ表示するか.（公開ページで全員に表示する場合には確定権限はなしのためtrueになります.）
    onlyShowDates: boolean

    notice = Notice

    rm = RoomManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    // loading = false
    Util = Util
    CalendarUtil = CalendarUtil

    pdm = PossibleDatesManager
    member: RoomMember = null
    showMyVoteView = true

    // w30で指定. 左側
    columnWidthClass: string = `w40`

    // w70で指定. 右側
    cellWidthClass: string = `w60`

    public mounted() {
        // gtagPage(`#/summary`)
        // this.room = this.rm.currentRoom;
        this.updateRooms()

        this.updateScrollContentRule()
        this.updatePossibleDatesManagerDisplayTypeIfReschedule()
        this.updatepossibleDatesManagerIfSelectedPds()
        this.updateWidthClass()
        window.addEventListener("resize", this.adjustRowHeights)
        this.updateTableStyle()
    }

    @Watch("vrm.suggestedMembers", { deep: true })
    updateTableStyle() {
        this.$nextTick(() => {
            this.adjustRowHeights()
        })
    }

    beforeUnmount() {
        window.removeEventListener("resize", this.adjustRowHeights)
    }

    updateWidthClass() {
        // columnWidthClass: 日付記載部分
        // cellWidthClass: メンバー◯x記載部分
        if (this.showMyVoteView) {
            // 自分の投票◯xを表示する場合
            this.columnWidthClass = this.isSP ? `w70` : `w60`
            this.cellWidthClass = this.isSP ? `w30` : `w40`
        } else {
            this.columnWidthClass = this.isSP ? `w40` : `w30`
            this.cellWidthClass = this.isSP ? `w60` : `w70`
        }
    }

    suggestDates() {
        // 別ページにある提案処理を呼び出します.
        this.pdm.suggestNow = `true`
    }

    goRoom(room: Room) {
        this.$emit(`goRoom`, room)
    }

    @Watch(`room`, { deep: true })
    updateRoom() {
        this.updateRooms()
    }

    @Watch(`rooms`, { deep: true })
    updateRooms() {
        if (!this.rooms) return
        if (!this.vrm) return

        // 既にメンバーがセットされている場合、リフレッシュしません.
        // リフレッシュしてしまうと並べ替えた情報が戻ってしまうため.
        // 再調整した場合などリフレッシュさせたい場合は、publicRoomをnullにしてください。
        if (this.vrm.publicRoom) return

        this.vrm.createNew(this.room, this.rooms, this.rm.userInfo)

        if (this.vrm?.myParentRoom) {
            let myMem = (this.vrm?.myParentRoom?.members || []).find(m => m.user_id == this.rm.userInfo.user_id)
            Logger(
                `${funcName()} myMem:${myMem?.user_id}, assign_type:${myMem?.assign_type}, this.rm.userInfo.user_id:${
                    this.rm.userInfo?.user_id
                }`
            )
            if (myMem && [`fix`, `watch`].includes(myMem.assign_type)) {
                this.showMyVoteView = false
            }
        }
        this.updateWidthClass()
    }

    @Watch(`pdm.displayType`)
    updatePossibleDatesManagerDisplayTypeIfReschedule() {
        Logger(`${funcName()} pdm.displayType:${this.pdm.displayType}`)
        if (this.pdm.displayType == `reschedule` && this.pdm.selectedPds?.length > 0) {
            this.showMyVoteView = true
            this.updateWidthClass()
        }
    }

    @Watch(`pdm.selectedPds`)
    updatepossibleDatesManagerIfSelectedPds() {
        Logger(`${funcName()} pdm.displayType:${this.pdm.displayType}`)
        if (this.pdm.displayType == `reschedule` && this.pdm.selectedPds?.length > 0) {
            this.showMyVoteView = true
            this.updateWidthClass()
        }
    }

    updateScrollContentRule() {
        Logger(`${funcName()}`)

        $(".MembersHeader").on("scroll", function () {
            const _left = $(this).scrollLeft()
            $(".MembersVotedInfo")[0].scrollLeft = _left
            // Logger(`scrollHeader: ${_left}`)
        })

        $("#MembersVotedInfo").on("scroll", function () {
            const _left = $(this).scrollLeft()
            $(".MembersHeader")[0].scrollLeft = _left
            // Logger(`scrollContent: ${_left}`)
        })
    }

    confirmDate(pd: PossibleDate) {
        Logger(`${funcName()} pdを確認 ${pd?.jp_format}`)
        gtagClick(`この日程を確認ボタンvote`, `${pd?.jp_format}`)
        this.pdm.possibleDates = [...this.vrm.suggestedDates]
        this.pdm.selectCurrentPd(pd)
        this.$vfm.open(`FixVotedRoomsModal`)
    }

    clickCancel() {
        this.$vfm.close(`FixVotedRoomsModal`)
    }

    // 確定後、モーダルを閉じます.
    fixedAppo(fixedRooms: Room[], notFixedRooms: Room[]) {
        this.$vfm.close(`FixVotedRoomsModal`)
        this.$emit(`fixedAppo`, fixedRooms, notFixedRooms)
    }

    clickFormMember(mem: RoomMember, room: Room) {
        this.member = mem
        // フォーム確認
        this.$nextTick(() => {
            this.$vfm.open(`AnsweredFormModal`)
        })
    }

    clickCloseRoom(mem: RoomMember) {
        this.showRoomModal(this.room, "delete")
    }

    downloadCSV() {
        let csvHeader = [null, null]
        // let csvForm = [null, null]
        let csvContent: any[] = [csvHeader]
        let arrayLength = Object.values(this.vrm.suggestedMembers[0].answer_results || {}).length
        // let csvForm = new Array(arrayLength).fill(new Array(this.vrm.suggestedMembers.length + 2))
        let csvForm = []
        // Logger(`csvForm: ${csvForm}`)
        // for (let row of csvForm) {
        //     row =
        // }
        let names = [null, null]
        let companies = [null, null]
        Logger(`arrayLength: ${arrayLength}, csvForm: ${Util.output(csvForm)}`)
        for (let _mem of this.vrm.suggestedMembers) {
            names.push(_mem.name)
            companies.push(_mem.company_name)
        }
        csvForm.push(names)
        csvForm.push(companies)

        // let memIndex = 0
        // for (let _mem of this.vrm.suggestedMembers) {
        //     csvHeader.push(`${_mem.name}(${_mem.company_name})`)

        //     // let values = []
        //     let ansIndex = 0
        //     // if (Util.isBlank(_mem.answer_results || {})) {
        //     //     memIndex++
        //     //     continue
        //     // }
        //     let arr = csvForm[ansIndex]
        //     arr[memIndex + 2] = _mem.name
        //     csvForm[ansIndex] = [...arr]

        //     Logger(`csvForm: ${Util.output(csvForm)}`)
        //     memIndex++
        // }
        Array.prototype.push.apply(csvContent, csvForm)

        this.vrm.suggestedDates.forEach((_pd, pdindex) => {
            let _num = this.vrm.numberList[_pd.start_time]
            let arr = [_pd.jp_format, `${_num}人`]
            for (let _mem of this.vrm.suggestedMembers) {
                let findPd = _mem.suggested_dates.find(sd => sd.start_time == _pd.start_time)
                if (findPd) {
                    arr.push(`○`)
                } else {
                    arr.push(``)
                }
            }
            csvContent.push(arr)
        })
        let bom = new Uint8Array([0xef, 0xbb, 0xbf])
        let blobdata = csvContent.map(c => c.join(`,`)).join(`\r\n`)
        // .join(`\r\n`)
        let blob = new Blob([bom, blobdata], { type: "text/csv" })
        FileUtil.download(blob, `waaq_vote${Util.getSec() * 1000}.csv`)
    }

    showSuggestedView() {
        if (!this.room) return false
        if (this.room.is_owner) return false
        if (this.pdm.canFix) return false
        if (this.pdm.displayType != `suggested`) return false

        this.showMyVoteView = false
        this.updateWidthClass()
        return true
    }

    /**
     * 押下した日程の提案のみを表示します.
     * @param dateDic
     */
    showFilterSuggestedDate(dateDic: any) {
        Logger(`${funcName()} ${dateDic?.start_time}`)
        let pds = []
        for (let pd of this.vrm.suggestedDates) {
            if (dateDic.timeArr.includes(pd.start_time)) {
                pds.push(pd)
            }
        }
        this.vrm.filteredDates = pds
        this.vrm.isFiltered = true

        this.$nextTick(() => {
            this.updateScrollContentRule() // この行を$nextTick内に移動
            this.adjustRowHeights()
        })
    }

    showSuggestedDateButtons() {
        if (!this.vrm.dateListDic) return false
        if (Util.isPreview()) return false
        if (!this.room) return false

        let rs = this.room.room_setting
        if (!rs) return false

        return !this.vrm.isFiltered && rs.show_filter_vote_type == `filter_by_date`
    }

    adjustRowHeights() {
        const titleRows = document.querySelectorAll(".timespanTitle table tr")
        const infoRows = document.querySelectorAll(".MembersVotedInfo table tr")

        if (!titleRows.length || !infoRows.length) return

        titleRows.forEach((row, index) => {
            const height = Math.ceil(row.getBoundingClientRect().height)
            Logger(`${funcName()} titleRows[${index}].height: ${height}`)
            if (infoRows[index]) {
                ;(row as HTMLElement).style.height = `${height}px`
                ;(infoRows[index] as HTMLElement).style.height = `${height}px`
            }
        })
    }
}
