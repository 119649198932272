
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import { gtagClick } from "packs/GoogleTagManager"

// モデルを読込.
import RoomMessage from "packs/models/RoomMessage"

@Options({})
export default class OneMessageInnerContent extends Vue {
    // data:
    @Prop()
    isMe: { type: boolean; require: false }
    @Prop()
    message: RoomMessage // this.message.messageの中身

    // regex = /http(:\/\/[-_.!~*¥'()a-zA-Z0-9;\/?:¥@&=+¥$,%#]+)/;
    regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

    //
    // computed:
    //

    // 文中にあるURLを分割して抽出します。
    get autoLink(): string[] {
        let processText = this.message.message

        let match
        let matchObj = {}
        let count = 0

        while ((match = this.regex.exec(processText)) != null) {
            count += 1
            matchObj[`[${count}]`] = match[0]
            processText = processText.replace(match[0], `[${count}]`)
        }

        const keys = Object.keys(matchObj)

        keys.forEach(key => {
            processText = processText.replace(key, `,${matchObj[key]},`)
        })
        return processText.split(",")
    }

    clickInnerMessage(text: string) {
        gtagClick(`チャット リンク`, `${text}`)
    }
}
