
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import SelectUtil from "packs/utils/SelectUtil"
import Const from "packs/utils/Const"
import Notice from "packs/models/Notice"
import TemplateUtil from "packs/utils/TemplateUtil"
import draggable from "vuedraggable"
import { VueFinalModal } from "vue-final-modal"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import PossibleDateControl from "packs/pages/link/parts/PossibleDateControl.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import LocationSelectContent from "packs/components/calendar/LocationSelectContent.vue"
import FixVotedRoomsMailContent from "packs/pages/link/parts/room_summary/FixVotedRoomsMailContent.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIconSendMenu from "packs/components/icons/UserIconSendMenu.vue"
import SelectAttendeesWithAddressbook from "packs/pages/link/parts/available_schedule/SelectAttendeesWithAddressbook.vue"
import MeetingAttendeesGroupAssignContent from "packs/pages/schedule/parts/MeetingAttendeesGroupAssignContent.vue"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"
import FixAppoInfoContent from "packs/pages/schedule/parts/FixAppoInfoContent.vue"
import ChangeablePossibleDateControl from "packs/components/calendar/ChangeablePossibleDateControl.vue"
import Switcher from "packs/components/forms/Switcher.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import MessageTemplate from "packs/models/MessageTemplate"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomMember from "packs/models/RoomMember"
import VotedRoomManager from "packs/models/VotedRoomManager"
import LocationTag from "packs/models/LocationTag"
import CalendarTag from "packs/models/CalendarTag"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomCreateManager from "packs/models/RoomCreateManager"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import CalendarManager from "packs/models/CalendarManager"
import ChangeableDateTimeContent from "packs/components/calendar/ChangeableDateTimeContent.vue"

@Options({
    components: {
        TextareaTag,
        ModalTemplates,
        PossibleDateControl,
        SelectTag,
        UserIcon,
        draggable,
        CheckboxTag,
        LocationSelectContent,
        FixVotedRoomsMailContent,
        AddressBookContent,
        UserIconSendMenu,
        SelectAttendeesWithAddressbook,
        MeetingAttendeesGroupAssignContent,
        VueFinalModal,
        MdProgressBar,
        FixAppoInfoContent,
        Switcher,
        ChangeableDateTimeContent,
    },
})
export default class FixVotedRoomsModal extends Vue {
    @Prop()
    room: Room

    // data:

    rm = RoomManager
    pdm = PossibleDatesManager
    vrm = VotedRoomManager
    isSP = isMobile(window.navigator).phone

    // メールに付随する内容
    typedMessage = ""

    // 調整ページ内のメモに入力しておく内容
    memo = ""

    SelectUtil = SelectUtil

    tmp: MessageTemplate = null
    currentPd?: PossibleDate = null
    Const = Const

    attendees: RoomMember[] = null
    notAttendees: RoomMember[] = null
    drag: boolean = false
    systemUpdatedAt: number = Util.getSec()

    // メモとメールを同じ内容のものを入れるか
    sameMailAndMemo = false

    // 場所名.（LocationTagではない.）
    location: string = null
    currentOnlines: CalendarTag[] = null
    defaultZoomEmail: string = null
    astag: AvailableScheduleTag = null
    forceType: string = null // filled: 既に埋まっているが強制確定させるか.
    isForce = false
    changeableEv = null

    openCustomizeMailContent = false
    viewType = `appo` // appo 確定するための情報を入力 / attendees 出席者をアドレス帳から選択

    // 選択しているMAG
    selectedMag: MeetingAttendeesGroup = null

    // もともと割り当てられていたMAG
    beforeSelectedMag: MeetingAttendeesGroup = null

    // 外部カレンダーもしくはメールアドレスから割り当てた場合にこちらにセットして送ります.
    tmpMagFromCals: MeetingAttendeesGroup = null
    tmpMagFromMail: MeetingAttendeesGroup = null
    selectedTabName = `astag` // astag, ctags, emails

    // 最終的に利用するMAG MAG選択画面から、もとのviewに戻ってきた際に確定します.
    fixMag: MeetingAttendeesGroup = null

    // 確定時に変更するタイトルおよびメンバー名
    fixedAppoDic = { f_appo: null, f_members: [], update_member_ids: [] }

    cm = CalendarManager

    public openModal() {
        Logger(`時間が更新されたため、pdをアップデートします.`)
        gtagPage(`#/投票確定確認モーダル`)
        this.resetTmpMags()
        this.updateCurrentPd()
        this.updateAstag()
    }

    updateAstag() {
        // let room = this.vrm.publicRoom
        let room = this.vrm.rooms[0]
        let astagId = room.owner_avaialble_schedule_tag_id
        let _astag = (this.rm.astags || []).find(a => a.id == astagId)
        if (_astag) {
            this.astag = _astag
        }
        this.rm.updateCurrentInfo(room, astagId)

        this.resetFixedAppoDic()
    }

    resetTmpMags() {
        this.tmpMagFromCals = MeetingAttendeesGroup.createDefault()
        this.tmpMagFromMail = MeetingAttendeesGroup.createDefault()
        this.tmpMagFromCals.purpose_type = `temporary`
        this.tmpMagFromMail.purpose_type = `temporary`

        // 画面を開いたときに、情報をリセットします. #5439
        this.fixMag = null
        this.selectedMag = null
        this.viewType = `appo`
        this.isForce = false
        this.forceType = null
        this.currentOnlines = null
    }

    public get getLocationName() {
        if (this.location) {
            return this.location
        }

        let room = this.vrm.publicRoom
        if (Util.isBlank(room)) return ""

        let _appo = room.current_appointment
        if (Util.isBlank(_appo)) return ""

        if (_appo.selected_location) {
            this.location = _appo.selected_location.keyword
            return _appo.selected_location.keyword
        }

        return ""
    }

    public inputMessage(mes: string, memo: string) {
        this.typedMessage = mes
        this.memo = memo
    }

    public clickOK() {
        this.currentPd = this.pdm.currentPd
        Logger(
            `clickOK: pd:${Util.output(this.currentPd)}, voteType:${this.vrm.fixVoteType}, text: ${this.typedMessage}, memo: ${
                this.memo
            }, location:${this.location}`
        )
        if (this.isForce) {
            this.currentPd = this.changeableEv as PossibleDate
        }

        if (Util.isPresent(this.fixMag)) {
            this.isForce = true
        }

        // if (!this.currentPd) this.currentPd = this.pdm.currentPd
        this.vrm
            .saveVotedRooms(
                this.currentPd,
                this.typedMessage,
                this.attendees,
                this.memo,
                this.sameMailAndMemo,
                this.location,
                this.currentOnlines,
                this.forceType,
                this.isForce,
                this.fixMag,
                this.fixedAppoDic
            )
            .then(res => {
                if (res) {
                    let fixedRooms = res[0]
                    let notFixedRooms = res[1]
                    this.forceType = null

                    this.$emit(`fixedAppo`, fixedRooms, notFixedRooms)
                    gtagClick(`確定vote`, `${this.currentPd.jp_format}, ${this.vrm.fixVoteType}`)
                } else {
                    // 確定に失敗:
                    // this.forceType = `filled`
                    // this.clickOK()
                }
            })
    }

    public clickCancel() {
        this.$emit(`clickCancel`)
    }

    public selectVoteFixType(e) {
        let typeDic = SelectUtil.votedRoomFixTypes.find(t => t.name == e)
        if (typeDic) {
            this.vrm.fixVoteType = typeDic.type
        }
    }

    defaultVotedOption(): string {
        Logger(`fixVoteType: ${this.vrm.fixVoteType}`)
        if (this.vrm.fixVoteType) {
            let typeDic = SelectUtil.votedRoomFixTypes.find(t => t.type == this.vrm.fixVoteType)
            if (typeDic) {
                return typeDic.name
            }
        }
        return SelectUtil.votedRoomFixTypes[0].name
    }

    @Watch(`pdm.currentPd`, { deep: true })
    updateCurrentPd() {
        if (!this.pdm.currentPd) return
        if (!this.vrm.suggestedMembers) return

        let _pd = this.pdm.currentPd
        if (this.currentPd && _pd.start_time == this.currentPd.start_time) return

        let [atts, notAtts] = this.vrm.getMembersAttendeeOrNot(_pd, true)

        this.attendees = atts
        this.notAttendees = notAtts
        this.currentPd = _pd
        Logger(`${funcName()} _pd:${Util.output(_pd)}`)
        this.fixedAppoDic.f_members = [...(this.attendees || [])]
    }

    sortEnd() {
        this.drag = false
    }

    clickSameWithMail() {
        if (this.sameMailAndMemo) {
            this.sameMailAndMemo = false
        } else {
            this.sameMailAndMemo = true
        }
    }

    updateLocation(location: string, onlineAccs: CalendarTag[]) {
        Logger(`${funcName()} location:${location}, onlineAccs:${Util.output(onlineAccs)}`)
        this.location = location
        this.currentOnlines = onlineAccs
    }

    toggleCustomizeMailContent() {
        if (this.openCustomizeMailContent) {
            this.openCustomizeMailContent = false
        } else {
            this.openCustomizeMailContent = true
        }
    }

    addAttendeeFromAddressBook() {
        this.viewType = `attendees`
    }

    clickPreStep() {
        this.viewType = `appo`
        this.attendees = this.vrm.attendees
        this.notAttendees = this.vrm.notAttendees
        this.fixedAppoDic.f_members = [...(this.attendees || [])]
    }

    clickPreStepFromMags() {
        let selectedMag = this.selectedMag
        if (this.selectedTabName == `ctags`) {
            selectedMag = this.tmpMagFromCals
        } else if (this.selectedTabName == `emails`) {
            selectedMag = this.tmpMagFromMail
        }
        this.fixMag = selectedMag
        this.viewType = `appo`
    }

    clickPreStepFromFixAppoInfo() {
        this.viewType = `appo`
    }

    selectMag(mag: MeetingAttendeesGroup) {
        this.selectedMag = mag
    }

    selectCategoryTab(tabName: string) {
        this.selectedTabName = tabName
    }

    updateTmpMag(mag: MeetingAttendeesGroup) {
        Logger(`${funcName()} selectedTabName:${this.selectedTabName}`)
        if (this.selectedTabName == `emails`) {
            this.tmpMagFromMail = mag
        } else if (this.selectedTabName == `ctags`) {
            this.tmpMagFromCals.required_attendees = this.cm.edittingAttendees
        }
    }

    clickShowMeetingAttendeesGroupAssignContent() {
        this.viewType = `mags`
    }

    resetMeetingAttendeesGroup() {
        this.fixMag = null
    }

    clickEditFixInfo() {
        this.viewType = `fix_appo_info`
    }

    updateFixedAppo(fappoDic: any = null) {
        this.fixedAppoDic = { ...fappoDic }
    }

    resetFixedAppoDic() {
        let pubRoom = this.vrm.publicRoom
        let appo = { ...pubRoom.current_appointment }
        let apc = pubRoom.appeal_content
        if (Util.isPresent(apc?.title)) {
            appo.name = apc.title
        }
        this.fixedAppoDic.f_appo = appo
    }

    showFixAppoMenu() {
        if (this.canUseChukaiMenu()) return true
        return false
    }

    canUseChukaiMenu() {
        if (!this.rm) return false
        if (!this.rm.astag) return false
        return this.rm.canUseOption(`room_chukai_menu`, false)
    }

    changeForce() {
        this.changeableEv = PossibleDate.toEvent(this.pdm.currentPd)
        Logger(`${funcName()} isForce:${this.isForce}, changeableEv:${Util.output(this.changeableEv)}`)
        if (this.isForce) {
            this.isForce = false
        } else {
            this.isForce = true
        }
    }

    updateAppoTime(startTime: DateTime, endTime: DateTime, duration: number) {
        Logger(`${funcName()} startTime:${Util.output(startTime)}, endTime:${Util.output(endTime)}, duration:${duration}`)
        this.changeableEv.start_time = startTime.toSeconds()
        // this.changeableEv.end_time = endTime.toSeconds()
        this.changeableEv.duration = duration
        // this.duration = duration
    }
}
