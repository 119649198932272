import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w100 skyblueBg centered fw600 fs12",
  style: {"height":"5px","padding-top":"0px","letter-spacing":"0.3px"}
}
const _hoisted_2 = {
  key: 1,
  class: "w100 with_white_bg"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "text-center mt15 fs18 fw600"
}
const _hoisted_7 = { class: "pad20 with_white_bg mt30" }
const _hoisted_8 = {
  key: 0,
  style: {"color":"#222222","padding-bottom":"13px"},
  class: "fw600 fs18",
  align: "left"
}
const _hoisted_9 = {
  key: 1,
  style: {"border-top-width":"1px","border-top-color":"#cccccc","margin":"0","border-style":"solid none none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailPreviewPartsAppoTable = _resolveComponent("MailPreviewPartsAppoTable")!
  const _component_MailPreviewPartsAnswerResults = _resolveComponent("MailPreviewPartsAnswerResults")!

  return (_ctx.rcm.room.room_type == `parent_room` && _ctx.rcm.selectedMailFlow.content && _ctx.rcm.selectedMailFlow.content.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.rcm.room && _ctx.rcm.appeal_content)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.rcm.appeal_content.image_url)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: _ctx.rcm.appeal_content.image_url,
                      style: {"width":"100%"}
                    }, null, 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.rcm.appeal_content.image_url && _ctx.rcm.appeal_content.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.rcm.appeal_content.title), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.rcm.selectedMailFlow.show_qa)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, " お申し込み内容 "))
            : _createCommentVNode("", true),
          (_ctx.rcm.selectedMailFlow.show_qa)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_9))
            : _createCommentVNode("", true),
          (_ctx.showFixedAppo)
            ? (_openBlock(), _createBlock(_component_MailPreviewPartsAppoTable, {
                key: 2,
                hideTitle: _ctx.rcm.appeal_content && _ctx.rcm.appeal_content.title
              }, null, 8, ["hideTitle"]))
            : _createCommentVNode("", true),
          (_ctx.showAnswerResults)
            ? (_openBlock(), _createBlock(_component_MailPreviewPartsAnswerResults, { key: 3 }))
            : _createCommentVNode("", true)
        ])
      ]))
}