
// モジュールを読込.
import { Options, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import Util from "packs/utils/Util"
import RoomCreator from "packs/utils/RoomCreator"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import AttendeesTable from "packs/pages/link/parts/room_settings/AttendeesTable.vue"
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import MeetingTimeContent from "packs/pages/link/parts/room_settings/MeetingTimeContent.vue"
import LocationSelectContent from "packs/components/calendar/LocationSelectContent.vue"
import SelectAttendeesWithAddressbook from "packs/pages/link/parts/available_schedule/SelectAttendeesWithAddressbook.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"
import AstagLists from "packs/pages/link/parts/available_schedule/AstagLists.vue"
import { VueFinalModal } from "vue-final-modal"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import AppealContent from "packs/models/AppealContent"
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import RoomStorage from "packs/models/RoomStorage"
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserSetting from "packs/models/UserSetting"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import RoomSetting from "packs/models/RoomSetting"
import ContactListGroup from "packs/models/ContactListGroup"
import Notice from "packs/models/Notice"
import CalendarManager from "packs/models/CalendarManager"
import CalendarTag from "packs/models/CalendarTag"
import DateTag from "packs/models/DateTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AttendeesTable,
        AddressBookContent,
        UserIcon,
        IconHelp,
        MdTextfieldTag,
        MeetingTimeContent,
        LocationSelectContent,
        SelectAttendeesWithAddressbook,
        CalendarIcon,
        AstagLists,
        VueFinalModal,
    },
})
export default class ChooseRoomTypeModal extends Vue {
    @Prop() // func
    goRoomSettings

    @Ref()
    AstagInputField

    rm = RoomManager
    rcm = RoomCreateManager
    cm = CalendarManager
    isSP = isMobile(window.navigator).phone

    // parents = RoomCreator.getParentDicRooms()
    // hidePublics = null
    // hideParents = RoomCreator.getParentHideDicRooms()
    // Array.prototype.push.apply(parents, hide)
    publics = RoomCreator.getPublicDicRooms()
    // hidePublics = RoomCreator.getPublicHideDicRooms()
    Util = Util
    truncate = truncate
    UserPermissionsOption = UserPermissionsOption

    // 他の使い方は開閉処理で表示.
    // showHideParents = false
    showHidePublics = false

    // data

    appos = null
    room: Room = null
    appo: Appointment = null
    selectedRoomDic: any = null // 選択したルームタイプ.RoomCreatorにあるHash上のもの

    astags: AvailableScheduleTag[] = null
    astag: AvailableScheduleTag = null

    loading = false
    systemUpdatedAt = Util.getSec()
    step = 0

    currentMember: RoomMember = null
    editOrNewForm = ``
    members: RoomMember[] = []

    selectedLoctags = null
    loctags: LocationTag[] = null
    locations: string[] = null

    openHeaderAttendees = true
    openHeaderCalendar = true
    openHeaderLocation = true
    openHeaderTime = true
    enabledSave = false

    openAstags = false

    public dismissModal() {
        this.$vfm.close("SelectAstagsModal")
    }

    openModal() {
        gtagPage("#/ページ作成_モーダル")
        if (this.step == 0) {
            // stepが0の場合のみ利用するAstagを初期化します.
            this.astag = this.rm.defaultAstag

            this.rm.getAstagDetail(this.astag?.id).then(res => {
                if (res) {
                    this.astag = res
                }
                if (!this.astag) {
                    this.astag = this.rm.astag
                }
            })
            // this.astag = this.rm.defaultAstag || this.rm.astag
        }
    }

    resetRoomInfo() {
        this.room = null
        this.appo = null
        this.members = []
    }

    public copyRoom(_room: Room) {
        this.resetRoomInfo()
        gtagClick(`複製 ${_room.room_type}`, `${_room.public_id}`)
        let room = Room.copy(_room)
        roomStorage.saveAction(`copy`)

        this.room = room
        this.appo = room.current_appointment
        this.rcm.setNew(this.room, this.appo, this.members, this.astag, this.rm.userInfo, this.rm.user_setting, `copy`)

        if (room.room_type == `parent_room`) {
            // this.members = RoomMember.removeOwner(room.members)
            this.members = room.attendees
            this.rcm.members = room.attendees
            this.saveForPreview()
            this.step = 1
        } else {
            this.saveForPreview()
            this.goRoomSettings()
        }
    }

    public createRoom(roomType: string, roomDic: any, createType: string = null) {
        this.resetRoomInfo()
        gtagClick(`テンプレ選択 ${roomType}`, roomDic.typeName)
        if ([`csv`, `excel`].includes(roomDic.typeName)) {
            this.$vfm.close(`ChooseRoomTypeModal`)
            this.$vfm.open(`UploadModal`)
            return
        }

        let room = Room.createDefaultRoom()
        room.room_type = roomType
        this.selectedRoomDic = roomDic

        if (roomType == `public_room`) {
            room = RoomCreator.configurePublicRoom(room, roomDic, this.rm)
        } else {
            room.current_appointment = RoomCreator.setLocation(room.current_appointment, this.rm)
        }

        this.room = room
        this.appo = room.current_appointment
        this.rcm.setNew(this.room, this.appo, this.members, this.astag, this.rm.userInfo, this.rm.user_setting, `new`)
        this.saveForPreview()
        Logger(`作成されたfieldを確認します: ${Util.output(room.form_fields)}`)

        if (roomType == `public_room`) {
            roomStorage.saveAction(`copy`)
            if (createType == `vote`) {
                return
            }
            this.goRoomSettings()
            return
        }

        if (roomType == `parent_room`) {
            if (roomDic.typeName == `no_owner`) {
                room.current_appointment.required_owner_participation = false
                if (Util.isBlank(this.rm.chukaiAstag)) {
                    if (
                        confirm(
                            `不参加カレンダーを作成していないため、日程候補が0件と表示されてしまいます。不参加カレンダーを作成しに行きますか？`
                        )
                    ) {
                        this.$router.push(`/settings/not_attendees_calendar`)
                    }
                }
            }
        }
        this.room = room
        this.appo = room.current_appointment
        this.step = 1
    }

    public addAttendee(member: RoomMember) {
        Logger(`memberを追加します : ${Util.output(member)}`)
        gtagClick(`出席者追加`, `${member.name}`)
        if (member.email == this.rm.userInfo.email) return
        let _mem = null
        if (member.user_id) {
            _mem = this.members.find(m => m.user_id == member.user_id)
        } else {
            _mem = this.members.find(m => m.email == member.email)
        }

        // let mems = this.members.filter(m => m.email != member.email && m.email != this.rm.userInfo.email)
        if (Util.isBlank(_mem)) {
            this.members.push(member)
        } else {
            Object.assign(_mem, member)
        }
        Logger(`addAttendee roommeberを更新しました: ${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        this.editOrNewForm = ``
        this.saveForPreview()
    }

    selectContactListGroup(clg: ContactListGroup) {
        let cls = [...clg.contact_lists]
        // 必須出席者、任意出席者、日程決定者に分類します.

        let mems = cls.filter(m => m.role == RoomMember.MEMBER)
        let opmems = cls.filter(m => m.role == RoomMember.MEMBER_OPTIONAL)
        Array.prototype.push.apply(mems, opmems)
        this.members = mems

        Logger(`selectContactListGroup roommeberを更新しました: ${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        this.editOrNewForm = ``
        this.saveForPreview()
    }

    public editAttendee(member) {
        this.currentMember = member
        this.editOrNewForm = `edit`
        gtagClick(`出席者を編集`, `出席者:${this.members.length}`)
        // this.$vfm.open("AttendeesModal");
        // this.saveForPreview()
    }

    /**
     * 選択したメンバーを削除します。
     */
    public deleteMember(member) {
        this.members = this.members.filter(mem => mem != member)
        gtagClick(`出席者を削除`, `出席者:${this.members.length}`)
        this.saveForPreview()
    }

    @Watch("members", { deep: true })
    buttonEnabled() {
        if (this.room && this.room.room_type == `public_room`) {
            this.enabledSave = true
            return
        }
        if (Util.isBlank(this.room) || Util.isBlank(this.members)) {
            this.enabledSave = false
            return
        }
        let enabled = false
        if (this.room.room_type == `parent_room`) {
            Logger(`buttonEnabled: members: ${Util.output(this.members)}`)
            let _mems = RoomMember.removeOwner(this.members, this.rm.userInfo)
            enabled = _mems.length > 0
        }

        this.enabledSave = enabled
    }

    public clickPreStep() {
        if (this.step >= 1) {
            this.step -= 1
        } else {
            this.step = 0
            this.selectedRoomDic = null
        }
    }

    public clickNextStep() {
        this.saveForPreview()
        roomStorage.saveAction(`copy`)
        if (Util.isPresent(this.selectedRoomDic)) {
            if ([`2`, `no_owner`].includes(this.selectedRoomDic.typeName)) {
                let _mems = RoomMember.removeOwner(this.members, this.rm.userInfo)
                if (_mems.length < 2) {
                    if (!confirm(`自分を除く出席者が2人以上追加されていませんがよろしいですか？`)) {
                        return
                    }
                }

                if (this.selectedRoomDic.typeName == `no_owner`) {
                    this.room.current_appointment.required_owner_participation = false
                    this.room.attendees = _mems
                }
            }
        }

        this.goRoomSettings()
    }

    inputMeetingName(e) {
        this.appo.name = e
    }

    // 通常の日程調整なしのアンケートフォームを作成します.
    clickFeedbackForm() {
        Logger(`${funcName()}`)
        let createDic = RoomCreator.getFeedbackRoomDic()
        createDic.room.rule = []
        createDic.room.display_schedule_type = `never`
        createDic.room.form_fields = [`satisfaction`, `satisfactionReason`, `useRepeat`, `request`]
        createDic.typeName = `open_page`

        this.createRoom(`public_room`, createDic)
        // this.createRoom(`public_room`, RoomCreator.getVoteRoomDic(), `vote`)
    }

    clickQuickVote() {
        Logger(`clickQuickVote`)
        this.createRoom(`public_room`, RoomCreator.getVoteRoomDic(), `vote`)
        this.appo.name = `会議`
        this.astag = this.rm.getAllAstags().find(ast => ast.is_only_use_optional_schedules)

        this.step = 1
        let field = this.rcm.fields[3]
        // クイック投票の場合、コメント欄を必須にし、表示するようにする
        if (field) {
            field.is_required = true
            field.field_name = `コメント`
            field.description = `候補提案時に、伝えたいことをコメントできます。`
            field.value_attr = `よろしくお願いします。`
        }
        sleep(500).then(_ => {
            if (this.AstagInputField) {
                this.AstagInputField.focusInput()
            }
        })
    }

    public enterName() {
        this.step = 2
    }

    selectAttendees() {
        this.step = 2
    }

    goVotePage() {
        if (Util.isBlank(this.appo.name)) {
            Notice.message = `会議名を入力してください。`
            return
        }

        this.rcm.updateAppo(this.appo)

        // let us = { ...this.rm.user_setting }

        let [astag, us] = this.configureQuickVoteAstag()

        us.default_duration = this.appo.duration
        this.cm.setNew(this.rm.userInfo, us, astag, null, true, this.appo)
        this.cm.createType = `vote`
        this.cm.hasUnsaved = true

        this.rcm.updateAstag(this.cm.astag)
        this.rcm.updateRoomSetting(this.cm.rs)
        this.rcm.appeal_content.image_url = null
        this.rcm.appeal_content.title = `${this.appo.name}`
        this.rcm.appeal_content.content = `${this.appo.name}の投票ページです。\n以下から、可能な日時を選択してください。`
        this.rcm.updateAppealContent(this.rcm.appeal_content)
        this.$router.push("/available_schedule")
    }

    // クイック投票用の調整カレンダーを作成します.
    configureQuickVoteAstag(): any[] {
        let us = { ...this.rm.user_setting }
        let astag = this.astag

        let ctags = []
        let mems = [...(this.rcm.members || [])]
        let opmems = [...(this.rcm.opMembers || [])]
        Array.prototype.push.apply(mems, opmems)
        for (let _mem of mems) {
            if (Util.isPresent(_mem.calendar_tag)) {
                ctags.push(_mem.calendar_tag)
            }
        }

        if (Util.isBlank(this.astag)) {
            astag = this.rm.addNewAstag()
            astag.name = this.appo.name
            // calendar_tagをcontact_listに持っている場合は自動で追加.
            let mag: MeetingAttendeesGroup = null
            if (Util.isPresent(ctags)) {
                let _mag = astag.meeting_attendees_groups[0]
                _mag.required_attendees = ctags

                // ADOWに平日午前・午後を自動追加
                if (Util.isBlank(us.available_days_of_weeks)) {
                    us.available_days_of_weeks = [DateTag.createWeekDayMorning(), DateTag.createWeekDayAfternoon()]
                }
                astag.can_connect_calendar = true
                mag = _mag
            }
            Logger(`magに追加したユーザー: ${Util.output(ctags)}`)
            if (Util.isPresent(mag)) this.cm.editStart(mag)
        } else {
            // ADOWに平日午前・午後を自動追加
            if (Util.isBlank(us.available_days_of_weeks)) {
                us.available_days_of_weeks = [DateTag.createWeekDayMorning(), DateTag.createWeekDayAfternoon()]
            }
        }
        return [astag, us]
    }

    public saveForPreview() {
        Logger(`ChooseRoomTypeModal#saveForPreview1`)
        if (!this.rm.userInfo) return
        Logger(`ChooseRoomTypeModal#saveForPreview2`)
        if (!this.astag) return
        Logger(`ChooseRoomTypeModal#saveForPreview3`)

        this.rcm.members = this.members
        this.rcm.room.members = this.members
        this.rcm.room.attendees = this.members

        let dic = this.rcm.saveForPreview(true)
        // if (Util.isPresent(dic)) {
        //     this.room = dic.room
        //     this.appo = this.room.current_appointment
        //     this.members = this.room.attendees
        // }
    }

    toggleHidePublics() {
        if (this.showHidePublics) {
            this.showHidePublics = false
        } else {
            this.showHidePublics = true
        }
    }

    // toggleHideParents() {
    //     if (this.showHideParents) {
    //         this.showHideParents = false
    //     } else {
    //         this.showHideParents = true
    //     }
    // }

    createVotedRoomFromAlert() {
        gtagClick(`限定公開人数アラート 投票ページ変更`, `${(this.members || []).length}人`)
        let pub = RoomCreator.getVoteRoomDic()
        // let pub = this.publics.find(p => p.typeName == `voted`)
        this.createRoom(`public_room`, pub)
    }

    get hidePublics() {
        let rooms = RoomCreator.getPublicHideDicRooms()

        return rooms
    }

    get getParents() {
        let parents = RoomCreator.getParentDicRooms()
        let hideParents = RoomCreator.getParentHideDicRooms()
        Array.prototype.push.apply(parents, hideParents)
        return parents
    }

    updateFromChild(appo: Appointment) {
        this.appo = Appointment.copy(appo)
    }

    public get getLocationName() {
        let room = this.rcm.room
        if (Util.isBlank(room)) return ""

        let _appo = room.current_appointment
        if (Util.isBlank(_appo)) return ""

        if (_appo.selected_location) {
            return _appo.selected_location.keyword
        }

        return ""
    }

    updateLocation(location: string, zoomAccs: CalendarTag[]) {
        // this.location = location
        this.appo.location_name = location
    }

    toggleSelectAstag() {
        if (this.openAstags) {
            this.openAstags = false
        } else {
            this.openAstags = true
        }
    }

    selectAstag() {
        this.astag = this.cm.astag
        this.openAstags = false
    }

    clickCreateNewAstag() {
        this.cm.astag = null
        this.astag = null
    }
}
