import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.display)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`pointer fw600 fs10 ${_ctx.type == `circle` ? `lblueBg defaultColor emadd atndCircle rad6 pad2` : `atndUnderline`}`),
        value: _ctx.value,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickContent && _ctx.clickContent(...args)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
        (_ctx.showDeleteButton)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: "/images/service/close_dark_grey.png",
              width: "16",
              height: "16",
              class: "fr",
              style: `margin-top:-1px;`,
              value: _ctx.value,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteContent && _ctx.deleteContent(...args)))
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}