
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import qs from "qs"
import { Logger, copyToClipboard, round } from "packs/common"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import IconStar from "packs/components/icons/IconStar.vue"
import UserStatusCell from "./UserStatusCell.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import ReportStat from "packs/models/ReportStat"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AlertBadge,
        IconStar,
        UserStatusCell,
    },
})
export default class RrogressCell extends Vue {
    @Prop()
    room: Room

    // data:

    isSP = isMobile(window.navigator).phone

    user: RoomMember = null
    attendees: RoomMember[] = null

    rm = RoomManager
    showTip = false
    showAppoTip = false

    created() {
        // this.setCompanyName();
    }

    mounted() {
        this.updateRoom()
    }

    /**
     * 前月との比較した数字を算出します。
     */
    @Watch("room", { deep: true })
    public updateRoom() {
        if (!this.room) return
        this.attendees = this.room.attendees.filter(att => att.user_id != this.room.owner.user_id)
    }

    sum(arr) {
        return arr.reduce(function (prev, current, i, arr) {
            return prev + current
        })
    }

    public roomLabels(): string {
        if (Room.isParentFixed(this.room.status)) {
            return "label label-green"
        } else if (this.room.status == 101) {
            return "fw600 ddgrey"
        }
    }

    public bgColor(): string {
        if (!this.room) return this.isSP ? `skyblueBg` : `lskyblueBg`

        if (Room.isParentFixed(this.room.status)) return this.isSP ? `greenBg` : `lgreenBg`
        if (Room.isParentCanceled(this.room.status)) return this.isSP ? `alertRedBg` : `lalertRedBg`
        return this.isSP ? `skyblueBg` : `lskyblueBg`
    }

    public copyPublicId() {
        $(this).select()
        copyToClipboard(this.room.public_id)
        this.showTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`publicIDをコピー`, `rid:${this.room.room_id}`)
    }

    public copyAppoText() {
        $(this).select()
        copyToClipboard(this.room.current_appointment.jp_format)
        this.showAppoTip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`確定したアポ時間をコピー`, `rid:${this.room.room_id}`)
    }

    public hideTooltip(e) {
        this.showTip = false
        this.showAppoTip = false
    }

    public personInCharge() {
        if (!this.room || !this.rm.userInfo) return ``
        const owner = this.room.owner
        if (owner.user_group_id == this.rm.userInfo.user_group_id) return owner.name.split(" ")[0]
        return ``
    }

    // 調整ページへ遷移.
    public clickRoom() {
        this.rm.updateCurrentInfo(this.room)
        roomStorage.saveSelectedRoomKey(this.room.keyId)
        roomStorage.saveSelectedParentRoomId(this.room.id)
        this.$router.push("/schedule")
    }

    public showHistory() {
        this.$emit(`showHistory`, this.room)
    }

    // public hideTooltip(e) {
    //     this.showTip = false;
    //     this.showAppoTip = false;
    // }
}
