
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import { Logger, funcName } from "packs/common"
import FlashNotice from "packs/components/common/FlashNotice.vue"
import Notice from "packs/models/Notice"
import { gtagClick, gtagEvent } from "packs/GoogleTagManager"
import { DateTime } from "luxon"

// コンポーネントを読込.
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"

// モデルを読込.
import CalendarManager from "packs/models/CalendarManager"
import RoomManager from "packs/models/RoomManager"
import Event from "packs/models/Event"
import SelectUtil from "packs/utils/SelectUtil"
import DateTag from "packs/models/DateTag"
import Room from "packs/models/Room"
import NotificationControl from "packs/utils/NotificationControl"
import Appointment from "packs/models/Appointment"

@Options({
    components: {
        CheckboxTag,
        SelectTag,
    },
})
export default class ChangeableDateTimeContent extends Vue {
    // data:
    @Prop()
    changeableEv: Event

    notice = Notice

    cm = CalendarManager
    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil

    needNotify = false

    startTime: DateTime = null
    endTime: DateTime = null
    isPrivate = false

    ev: Event = null

    date = ``
    startTimeStr = ``
    endTimeStr = ``

    loading = false
    timeArray = SelectUtil.timeArray

    // カスタム確定時にroomを利用します.

    duration = null
    originDuration = null

    mounted() {
        this.updateEventInfo()
    }

    updateEventInfo() {
        if (Util.isBlank(this.changeableEv)) return
        let ev = Event.copy(this.changeableEv)
        let startTime = DateTime.fromSeconds(ev.start_time)
        let starth = `${startTime.hour}`.padStart(2, "0")
        let startm = `${startTime.minute}`.padStart(2, "0")
        this.startTimeStr = `${starth}:${startm}`
        let endTime = DateTime.fromSeconds(ev.start_time + ev.duration * 60)
        let endt = `${endTime.hour}`.padStart(2, "0")
        let endm = `${endTime.minute}`.padStart(2, "0")
        this.endTimeStr = `${endt}:${endm}`
        this.date = startTime.toFormat(`yyyy-MM-dd`)
        this.duration = ev.duration
        this.startTime = startTime
        this.endTime = endTime

        this.ev = this.changeableEv
        if (!this.originDuration) {
            this.originDuration = this.ev.duration
        }
        Logger(`CalendarEventModal.updateEventInfo ${Util.output(this.ev)}`)
    }

    get getWdayText() {
        // if (Util.isBlank(this.cm.selectedEvent)) return
        if (Util.isBlank(this.ev)) return

        const wArray = DateTag.weekNames()

        // const dateFormat = this.cm.currentCandidateTime.date_format;
        // const [year, month, day, textArr] = dateSpliter(dateFormat);
        // var date = year + "-" + month + "-" + day;
        // var wDay = new Date(date).getDay();
        // return `(${wArray[wDay]})`;
        let wday = DateTime.fromSeconds(this.ev.start_time).weekday
        if (wday >= 7) wday -= 7
        return wArray[wday]
    }

    public inputDate(e) {
        Logger(`${funcName()} date:${e.target.value}`)
        this.date = e.target.value
        this.getWdayText
        this.updateStartTime()
    }

    public selectMeetingStartTime(e) {
        Logger(`${funcName()} sttime: ${e}`)
        this.startTimeStr = e
        this.updateStartTime()
    }

    // dateと選択日時から変更します.
    updateStartTime() {
        let time = DateTime.fromISO(this.date)
        let [hour, min] = this.startTimeStr.split(`:`)
        let startTime = time.plus({ hour: Number(hour), minutes: Number(min) })
        this.ev.start_time = startTime.toSeconds()

        let duration = this.ev.duration
        let endTime = startTime.plus({ minutes: duration })
        let endh = `${endTime.hour}`.padStart(2, "0")
        let endm = `${endTime.minute}`.padStart(2, "0")
        this.endTimeStr = `${endh}:${endm}`
        this.duration = duration
        this.startTime = startTime
        this.endTime = endTime
        this.updateAppoTime()
    }

    public selectMeetingEndTime(e) {
        Logger(`ettime: ${e}`)
        this.endTimeStr = e

        let time = DateTime.fromISO(this.date)
        let [shour, smin] = this.startTimeStr.split(`:`)
        let startTime = time.plus({ hour: Number(shour), minutes: Number(smin) })

        let [hour, min] = this.endTimeStr.split(`:`)
        let endTime = time.plus({ hour: Number(hour), minutes: Number(min) })
        let duration = endTime.diff(startTime, `minutes`).minutes
        Logger(`diff duration:${duration}`)
        this.endTime = endTime
        this.duration = duration
        this.updateAppoTime()
    }

    updateAppoTime() {
        this.$emit("updateAppoTime", this.startTime, this.endTime, this.duration)
    }
}
