
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"

// コンポーネントを読込.

// モデルを読込.
import RoomCreateManager from "packs/models/RoomCreateManager"
import MailFlow from "packs/models/MailFlow"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

@Options({
    components: {},
})
export default class MailPreviewPartsEditContent extends Vue {
    // data
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    rcm = RoomCreateManager
    pdm = PossibleDatesManager
    Util = Util

    created() {}

    mounted() {
        this.addMessage()
        this.getDefaultContent()
    }

    addMessage() {
        // this.editEditableContent(`message`)
        this.$emit(`addMessage`)
    }

    getDefaultContent() {
        if (!this.rcm.selectedMailFlow) return

        let mf = this.rcm.selectedMailFlow

        let content = ``
        if (this.rcm && this.rcm.showSMSField) {
            content = mf.sms_content
        } else if (Util.isPresent(mf.content)) {
            content = mf.content
        } else {
            let us = this.rm.user_setting
            let type = mf.def_type
            let simpleType = us.mail_display_type
            if (simpleType == `simple`) {
                content += `<<出席者-1-氏名>>様\n\nお世話になっております。\n`
                content += this.rcm.defaultSimpleText(type)
            } else {
                if (type == `inquire_on_public_room`) {
                    content += `ありがとうございます、受付完了しました。`
                } else if (type == `the_day_before`) {
                } else if ([`fixed_appointment`, `fix`].includes(type)) {
                    content += `日程が確定しました、当日はよろしくお願いいたします。`
                } else if (type == `one_day_before_deadline`) {
                    content += `作成した調整ページの有効期限が残り1日となりました。`
                } else if ([`feedback`, `notify_message`].includes(type)) {
                    // シンプルの場合と同じテキストを入れます.
                    content = this.rcm.defaultSimpleText(type, false)
                } else if ([`custom`].includes(type)) {
                    content = this.rcm.defaultSimpleText(type, false)
                } else if ([`auto_remind`].includes(type)) {
                    content += this.rcm.defaultSimpleText(type, false)
                }
            }
        }

        let str = this.rcm.insertTemplateText(content, mf.def_type)
        return str
    }
}
