import { DateTime, Interval } from "luxon"
import { zeroPadding, Logger } from "packs/common"
import RoomMember from "./RoomMember"
import DateTag from "./DateTag"
import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"

export default class ContactListGroup {
    constructor(
        public id: string,

        // アドレス帳
        public name: string,
        public private_memo: string,
        public total_num: number,

        // waaqで招待しているUGメンバー
        public contact_lists: RoomMember[],

        // contact_listsを取得済みか.（詳細が必要な場合にのみ取得します.）
        public is_fetched: boolean
    ) {}

    static createDefault(name = ``) {
        return new ContactListGroup(null, name, ``, 0, [], true)
    }

    static fetch(groupId: string) {
        return axios
            .get(`${Util.prefixUrl}/address_books/contact_list_group/${groupId}`)
            .then(res => {
                const clg = res.data.contact_list_group as ContactListGroup[]

                return { contact_list_group: clg }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static save(clg: ContactListGroup) {
        return axios
            .put(`${Util.prefixUrl}/address_books/contact_list_group`, {
                contact_list_group: clg,
            })
            .then(res => {
                // const users = res.data.invited_users
                let msg = res.data.message
                Notice.message = msg
                const clg = res.data.contact_list_group as ContactListGroup[]
                // let mems = []
                // for (const user of users) {
                //     let member: RoomMember = user
                //     if (member.email == "service@demo.waaq.jp") {
                //         continue
                //     }
                //     mems.push(member)
                // }
                return { contact_list_group: clg }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static delete(clg: ContactListGroup) {
        return axios
            .delete(`${Util.prefixUrl}/address_books/contact_list_group`, {
                params: { contact_list_group_id: clg.id },
            })
            .then(res => {
                // const users = res.data.invited_users
                let msg = res.data.message
                Notice.message = msg
                const _clg_id = res.data.deleted_contact_list_group_id

                return { contact_list_group_id: _clg_id }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    // memsの中にclg.contact_listのメンバーがいるかを返します.（検索で利用）
    static isInclude(clg: ContactListGroup, mems: RoomMember[]) {
        let cls = clg.contact_lists || []
        let emails = (mems || []).map(m => m.email)
        return cls.some(cl => emails.includes(cl.email))
    }
}
