import Util from "packs/utils/Util"
import { funcName, Logger } from "packs/common"

export default class FormUtil {
    static getParams(): any {
        let params = {}
        let _params = window.location.search.substr(1).split("&")
        for (let _param of _params) {
            let key = _param.split("=")
            params[key[0]] = key[1]
        }
        // this.additionalParams = params
        Logger(`パラメータを取得しました: string:${_params} ${Util.output(params)}`)
        return params
    }

    static validateTel(tel): boolean {
        if (Util.isBlank(tel)) return false

        Logger(`${funcName()} tel:${tel}`)
        let telStr = tel.toString()

        telStr = telStr.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248)
        })
        let isValid = false

        if (telStr.length > 5 && telStr.match(/^(?:\+?\d+-)?\d+(?:-\d+){2}$|^\+?\d+$/)) {
            isValid = true
        }
        return isValid
    }

    /**
     * 6文字の乱数を自動で生成します.
     * @param len
     * @returns
     */
    static generateRamdom(len = 6) {
        return Array(len)
            .fill(null)
            .map(() => Math.random().toString(36)[2])
            .join("")
            .toUpperCase()
    }
}
