
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch, Ref } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import axios from "axios"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import SelectableImage from "packs/components/images/SelectableImage.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import Switcher from "packs/components/forms/Switcher.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import RoomMember from "packs/models/RoomMember"
import TemplateUtil from "packs/utils/TemplateUtil"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import UserFile from "packs/models/UserFile"
import RoomCreateManager from "packs/models/RoomCreateManager"

const UNSET_EVENT_OWNER = "出席者から自動選択"

@Options({
    components: {
        Switcher,
        MdTextfieldTag,
        TextareaTag,
        UserIcon,
        SelectableImage,
        SelectTag,
        IconHelp,
        CheckboxTag,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class GroupProfileModal extends Vue {
    // @Prop()
    // room: Room; // addnewAppo, editAppo
    @Prop()
    magForProfile: MeetingAttendeesGroup

    // data
    isSP = isMobile(window.navigator).phone
    cm = CalendarManager
    rm = RoomManager
    rcm = RoomCreateManager
    loading = false
    // astags: AvailableScheduleTag[] = null

    mag: MeetingAttendeesGroup = null
    imageUrl: string = null
    beforeUrl: string = null

    // 公開で保存している画像.（UGごと）
    userFiles: UserFile[] = null

    text = `<<出席者-1-氏名>>様にあったご案内をさせていただきます。下記日程よりご確定ください。`

    validTeamName = true
    validDescription = true
    validImage = true
    selectedTab = `group`

    @Ref()
    UserIcon

    public dismissModal() {
        this.$vfm.close("GroupProfileModal")
    }

    cancelInput() {
        this.dismissModal()
    }

    openModal() {
        Logger(`GroupProfileModal.openModal`)
        this.updateMag()
        this.updateImages()
    }

    updateImages() {
        if (this.rm.userFiles) {
            this.userFiles = this.rm.userFiles
        } else {
            axios.get(`${Util.prefixUrl}/file_uploaders/index_public_images`).then(e => {
                let _images = e.data.images
                if (_images) {
                    let images = UserFile.fetchFromJson(_images)
                    this.userFiles = images
                    this.rm.userFiles = images
                    return this.userFiles
                }
            })
        }
    }

    @Watch("magForProfile", { deep: true })
    updateMag() {
        if (!this.magForProfile) return
        Logger(`GroupProfileModal#updateMag mag: ${Util.output(this.magForProfile)}`)
        this.mag = { ...this.magForProfile } as MeetingAttendeesGroup
        this.imageUrl = this.getMagImage()
        this.beforeUrl = this.getMagImage()
    }

    getMagImage() {
        return this.mag.profile_image_urls && this.mag.profile_image_urls.length > 0 ? this.mag.profile_image_urls[0] : null
    }

    public inputTeamName(e) {
        Logger(`inputTeamName: ${e}`)
        this.mag.team_name = e
    }

    public checkTeamName() {
        this.validTeamName = this.mag.team_name && this.mag.team_name.length > 0
    }

    public inputDescription(e) {
        Logger(`inputDescription: ${e}`)
        this.mag.description = e
    }

    public checkDescription() {
        this.validDescription = this.mag.description && this.mag.description.length > 0
    }

    public inputEmails(e) {
        Logger(`inputEmails: ${e}`)
        this.mag.emails = e
    }

    public inputReplyEmails(e) {
        Logger(`inputReplyEmails: ${e}`)
        this.mag.reply_emails = e
    }

    public inputPriority(e) {
        Logger(`inputPriority: ${e}`)
        this.mag.priority = e
    }

    inputVariable1(e) {
        Logger(`inputVariable1: ${e}`)
        this.mag.variable_1 = e
    }

    inputVariable2(e) {
        Logger(`inputVariable2: ${e}`)
        this.mag.variable_2 = e
    }

    public create() {
        // this.checkDescription()
        // if (!this.mag.profile_image_urls || this.mag.profile_image_urls.length == 0) {
        //     Logger(`画像は必須項目です`)
        //     Notice.message = `画像は必須項目です`
        //     return false
        // }
        // if (!this.mag.team_name || this.mag.team_name.length == 0) {
        //     Logger(`担当者名は必須項目です`)
        //     Notice.message = `担当者名は必須項目です`
        //     return false
        // }
        // if (!this.validDescription) {
        //     Logger(`プロフィール説明欄は必須項目です`)
        //     Notice.message = `プロフィール説明欄は必須項目です`
        //     return false
        // }
        Logger(`save前の状態: ${Util.output(this.mag)}`)

        this.$emit("saveProfile", this.mag)
    }

    closeModal() {
        this.$emit("saveProfile", null)
    }

    /**
     * リモートから取得したURLをはめます.
     */
    public uploadedUserImage(file: UserFile) {
        if (!file) return
        this.imageUrl = file.image_url
        this.beforeUrl = file.image_url
        this.mag.profile_image_urls = [file.image_url]
        let files = [...(this.rm.userFiles || [])]
        files.push(file)
        this.userFiles = files
        this.rm.userFiles = files

        // this.rm.userInfo.image_url = image_url
    }

    public hideTooltip(index) {
        // this.showTip = false
        // let tmp = this.tmps[index]
        // tmp.showTooltip = false
        // this.showTips[index] = false
    }

    public changeImage(file) {
        Logger(`changeImage: ${file.image_url}`)
        // this.mag.profile_image_urls[0]
        this.mag.profile_image_urls = [file.image_url]
        this.imageUrl = file.image_url
        this.beforeUrl = file.image_url
        // this.imageUrl = file.image_url
    }

    public mouseoverImage(file) {
        this.imageUrl = file.image_url
    }

    public mouseleaveImage() {
        this.imageUrl = this.beforeUrl
    }

    public deleteImage(file: UserFile) {
        if (confirm(`削除してよろしいですか？この操作は元に戻せません。`)) {
            UserFile.delete(file.file_id).then(e => {
                this.userFiles = this.userFiles.filter(uf => uf.file_id != file.file_id)
            })
        }
    }

    public onDrop(event, key = "", image = {}) {
        this.UserIcon.onDrop(event, key, image)
    }

    public selectEventOwner(e) {
        if (e === UNSET_EVENT_OWNER) {
            this.mag.event_owner = null
            return
        }
        const selectedCal = this.cm.getWritableCalendars().find(cal => cal.name === e)
        if (!selectedCal) {
            this.mag.event_owner = null
        } else {
            this.mag.event_owner = selectedCal
        }
    }

    @Watch("cm.ctList", { deep: true })
    public selectableCalendars() {
        if (!this.cm.ctList) return
        const selectable_cals = this.cm.getWritableCalendars().map(cal => cal.name)
        selectable_cals.unshift(UNSET_EVENT_OWNER)
        Logger(`selectableCalendars: ${Util.output(selectable_cals)}`)
        return selectable_cals
    }

    public defaultEventOwnerOption() {
        if (!this.mag.event_owner) return UNSET_EVENT_OWNER
        if (!this.mag.event_owner.name) return UNSET_EVENT_OWNER
        const selectedCal = this.cm.getWritableCalendars().find(cal => cal.name === this.mag.event_owner.name)
        if (!selectedCal) return UNSET_EVENT_OWNER
        return selectedCal.name
    }

    selectTab(tabName: string) {
        this.selectedTab = tabName
    }

    clickAddEmailsAuto() {
        if (this.mag.can_add_emails_auto) {
            this.mag.can_add_emails_auto = false
        } else {
            this.mag.can_add_emails_auto = true
        }
    }

    get isActive() {
        if (!this.mag) return false

        if (this.mag.is_active_meeting_attendees_group_option) {
            return true
        }
        return false
    }

    public changeActive() {
        if (this.mag.is_active_meeting_attendees_group_option) {
            this.mag.is_active_meeting_attendees_group_option = false
        } else {
            this.mag.is_active_meeting_attendees_group_option = true
        }
    }

    selectDailyMeetingMaxNum(e) {
        Logger(`${funcName()} e:${e}`)
        if (e == `無制限`) {
            this.mag.daily_meeting_max_num = -1
        } else {
            this.mag.daily_meeting_max_num = e as number
        }
    }

    inputDailyCountableEventKeyword(e) {
        Logger(`${funcName()} e:${e}`)
        this.mag.daily_countable_event_keyword = e
    }

    insertLinkButton() {
        Logger(`${funcName()}`)
        let desc = this.mag.description
        let button = `[外部リンクボタン](https://waaq.jp/p/waaq/XXXXXXXXX)`
        this.mag.description = `${desc}\n\n${button}`
    }
}
