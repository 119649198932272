
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({
    components: {},
})
export default class TextfieldTag extends Vue {
    @Prop()
    value: string
    @Prop()
    type: string
    @Prop()
    name: string
    @Prop()
    placeholder: string
    @Prop()
    isDisabled: boolean
    @Prop()
    required: boolean

    @Prop()
    returnVal: string

    public updateValue(e) {
        if (this.returnVal) {
            this.$emit("inputValue", e.target.value, this.returnVal)
        } else {
            this.$emit("inputValue", e.target.value)
        }
    }

    public click(e) {
        this.$emit("clickTextfield", e.target)
    }

    emitEnter(e) {
        this.$emit("enterPressed", e.target.value)
    }

    mounted() {}

    // public focusout() {
    //     this.$emit("blur")
    // }
}
