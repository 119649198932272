
// モジュールを読込.
import { Vue, Options, Prop } from "vue-property-decorator"
import { gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.

@Options({
    components: { VueFinalModal, MdProgressBar },
})
export default class OptionModal extends Vue {
    @Prop() // gtagPageで送る名前で利用
    eventName: string

    @Prop()
    hideCancelButton: boolean

    @Prop()
    hideDismissButton: boolean

    @Prop()
    okButtonName: string

    // data
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    saving = false
    Util = Util

    public closeModal() {
        this.$emit(`closeModal`)
    }

    public openModal() {
        if (this.eventName) gtagPage(`${this.eventName}`)
    }

    public clickOK() {
        this.$emit(`clickOK`)
    }

    public clickCancel() {
        this.$emit(`clickCancel`)
    }
}
