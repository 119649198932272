
// モジュールを読込.
import { Options, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import axios from "axios"
import isMobile from "ismobilejs"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import qs from "qs"
import { Logger, copyToClipboard, round } from "packs/common"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import IconStar from "packs/components/icons/IconStar.vue"
import CalendarIcon from "packs/components/icons/CalendarIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Util from "packs/utils/Util"
import RoomMember from "packs/models/RoomMember"
import ReportStat from "packs/models/ReportStat"

const roomStorage = new RoomStorage()

@Options({
    components: {
        AlertBadge,
        IconStar,
        CalendarIcon,
    },
})
export default class ReportStatCell extends Vue {
    @Prop()
    stat: ReportStat

    @Prop()
    type: string // all/user

    @Prop()
    openReportUserIds: string[]

    // data:

    isSP = isMobile(window.navigator).phone

    user: RoomMember = null

    rm = RoomManager

    startNum: number = null

    parentsMom: number = null
    timeSpansMom: number = null
    fixRatio: number = null
    fixRatioMom: number = null
    fixRatioLast: number = null
    aveTimeSpan: number = null
    aveTimeSpanLast: number = null
    aveTimeSpanMom: number = null
    messagesMom: number = null

    isOpenDetail = false

    // selectedRoom = this.rm.currentRoom;
    // roomKeyId = null;
    // companyName = null;
    // displayMember: RoomMember;
    // showTip = false;
    // showAppoTip = false;
    // Room = Room;
    // members_with_links: RoomMember[] = [];
    // selectedTabStatus = this.roomStatusesHash.categoryProgress;

    created() {
        // this.setCompanyName();
    }

    mounted() {
        this.updateStat()
        this.calcurateMom()
        this.updateOpenReportUserIds()
    }

    /**
     * 前月との比較した数字を算出します。
     */
    @Watch("stat", { deep: true })
    public calcurateMom() {
        let startNum = this.stat.parents.length
        let startNumLast = this.stat.parents_last.length
        let fixNum = this.stat.time_spans.length
        let fixNumLast = this.stat.time_spans_last.length

        // 表示上の日程調整開始数 （前月から開始している日程調整の確定の場合）
        if (this.stat.parents.length < this.stat.time_spans.length) {
            this.startNum = this.stat.time_spans.length
        } else {
            this.startNum = this.stat.parents.length
        }

        this.parentsMom = round((startNum * 100) / startNumLast, 2) - 100
        try {
            this.timeSpansMom = round((fixNum * 100) / fixNumLast, 2) - 100
        } catch (e) {}

        try {
            this.fixRatio = (fixNum * 100) / startNum
            if (startNum < fixNum) {
                this.fixRatio = 100 //前月の開始で今月確定した場合.
            }
        } catch (e) {}

        try {
            this.fixRatioLast = (fixNumLast * 100) / startNumLast
        } catch (e) {}

        try {
            this.fixRatioMom = round((this.fixRatio * 100) / this.fixRatioLast, 2) - 100
        } catch (e) {}

        try {
            this.aveTimeSpan = round(this.sum(this.stat.time_spans) / fixNum / 60 / 60 / 24, 2)
            // Logger(`aveTimeSpan: ${this.aveTimeSpan}`);
        } catch (e) {}

        try {
            this.aveTimeSpanLast = round(this.sum(this.stat.time_spans_last) / fixNumLast / 60 / 60 / 24, 2)
        } catch (e) {}

        try {
            this.aveTimeSpanMom = round((this.aveTimeSpan * 100) / this.aveTimeSpanLast, 2) - 100
        } catch (e) {}

        try {
            this.messagesMom = round((this.stat.messages.length * 100) / this.stat.messages_last.length, 2) - 100
        } catch (e) {}
    }

    sum(arr) {
        return arr.reduce(function (prev, current, i, arr) {
            return prev + current
        })
    }

    openFixedPerAstag() {
        if (this.openReportUserIds.includes(this.user.user_id)) {
            this.$emit(`toggleDetail`, `close`, this.user)
        } else {
            // 開いているUserIDを覚えておきます.
            this.$emit(`toggleDetail`, `open`, this.user)
        }
    }

    @Watch("openReportUserIds", { deep: true })
    updateOpenReportUserIds() {
        if (Util.isPresent(this.user)) {
            this.isOpenDetail = (this.openReportUserIds || []).includes(this.user.user_id)
        }
    }

    // public hideTooltip(e) {
    //     this.showTip = false;
    //     this.showAppoTip = false;
    // }

    // 担当者
    @Watch("stat", { deep: true })
    public updateStat() {
        if (!this.stat) return
        this.user = this.stat.user
        if (Util.isPresent(this.user)) {
            this.isOpenDetail = (this.openReportUserIds || []).includes(this.user.user_id)
        }

        Logger(`userをセットしました。 ${Util.output(this.user)}`)
    }

    // public personInCharge() {
    //     if (!this.stat || !this.rm.userInfo) return ``;
    //     const owner = this.stat.user;
    //     this.user = owner;
    //     if (owner.user_group_id == this.rm.userInfo.user_group_id)
    //         return owner.name.split(" ")[0];
    //     return ``;
    // }

    public copyPage(room) {
        // 複製してセッティングページに遷移します。
        // Logger(`copyPage`);
        // gtagClick(`ルーム複製`, `rid:${room.room_id} ${room.members.length}人`);
        // this.rm.updateCurrentInfo(room);
        // roomStorage.saveAction("copy");
        // roomStorage.save(room);
        // roomStorage.saveAppointment(room.current_appointment);
        // this.goRoomSettings();
    }
}
