
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import { Dropdown } from "uiv"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Const from "packs/utils/Const"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import { funcName, Logger } from "packs/common"
import RoomStorage from "packs/models/RoomStorage"

const roomStorage = new RoomStorage()

@Options({ components: { UserIcon, Dropdown } })
export default class UserIconMenu extends Vue {
    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    changeableUserGroups = []

    // type: string;

    public async mounted() {
        // this.type = this.selectedTab;
        // targets.addEventListener("click", this.clickAvailableTimeEvent, false);
        this.updateUgs()
    }

    @Watch(`rm.changeable_user_groups`)
    updateUgs() {
        if (this.rm.changeable_user_groups) {
            this.changeableUserGroups = this.rm.changeable_user_groups
        }
    }

    changeUg(ug: any) {
        Logger(`${funcName()} ug:${ug.user_group_id}`)
        roomStorage.resetAll()
        location.href = `/change_user_group/${ug.user_group_id}`
    }

    getCurretPath() {
        return this.$route.path
    }

    goUserSettings() {
        const path = this.getCurretPath()
        Logger(`${funcName()} path:${path}`)
        if (path == `/settings`) return

        this.$router.push(`/settings`)
    }

    goShareCalendar() {
        const path = this.getCurretPath()
        Logger(`${funcName()} path:${path}`)
        if (path == `/settings/share_calendars`) return

        this.$router.push(`/settings/share_calendars`)
    }

    goMembersSettings() {
        const path = this.getCurretPath()
        Logger(`${funcName()} path:${path}`)
        if (path == `/account`) return

        this.$router.push(`/account`)
    }

    goAccountSettings() {
        const path = this.getCurretPath()
        Logger(`${funcName()} path:${path}`)
        if (path == `/account_settings`) return

        this.$router.push(`/account_settings`)
    }

    showShareCalPage() {
        if (this.isSP) return false
        if (Const.chukaiFreePlan(this.rm)) return false
        return true
    }

    showRoomMembers() {
        if (this.isSP) return false
        if (Util.isBlank(this.rm.userInfo)) return
        const uf = this.rm.userInfo
        if (uf.user_group_role == 100) return true
        const cate = `account_settings`
        if (UserPermissionsOption.permitDisplay(uf, cate, `account_settings_members`)) {
            return true
        }

        return false
    }

    showAccountPage() {
        if (this.isSP) return false
        if (Util.isBlank(this.rm.userInfo)) return
        if (Const.chukaiFreePlan(this.rm)) return false
        const uf = this.rm.userInfo
        if (uf.user_group_role == 100) return true
        const cate = `account_settings`
        if (
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_notifications`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_organization`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `account_settings_security`)
        ) {
            return true
        }

        return false
    }

    showReportsPage() {
        if (Util.isBlank(this.rm.userInfo)) return
        const uf = this.rm.userInfo
        if (uf.user_group_role == 100) return true

        const cate = `reports`
        if (
            UserPermissionsOption.permitDisplay(uf, cate, `reports`) ||
            UserPermissionsOption.permitDisplay(uf, cate, `progresses`)
        ) {
            return true
        }

        return false
    }

    clickLoggedOutButton() {
        Logger(`ログアウト処理時にキャッシュを削除します.`)
        roomStorage.resetAll()
    }
}
