
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, copyToClipboard, funcName } from "packs/common"
import { gtagLog, gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import IconLock from "packs/components/icons/IconLock.vue"

// モデルを読込.
import FormField from "packs/models/FormField"
import FormFieldCreator from "packs/utils/FormFieldCreator"
import RoomManager from "packs/models/RoomManager"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextareaTag,
        IconLock,
    },
})
export default class AvailableFieldsForAiEditContent extends Vue {
    // data:

    optionEventName = `フォームファイルアップロード`

    Util = Util
    rm = RoomManager
    rcm = RoomCreateManager
    typeArray = null
    selectedFields = []

    mounted() {
        let ffs = this.rcm.room.form_fields || []
        this.typeArray = { ...ffs }

        // 選択中の質問がある場合は、選択中に表示します.
        if (this.rcm.room.available_field_public_ids) {
            let pubIds = this.rcm.room.available_field_public_ids
            let selectedFields = []
            for (let pubId of pubIds) {
                let ff = ffs.find(f => f.public_id == pubId)
                if (ff) {
                    selectedFields.push(ff)
                }
            }
            this.selectedFields = selectedFields
        }
    }

    public closePanel() {
        this.$emit(`closePanel`)
    }

    clickField(ff: FormField) {
        Logger(`${funcName()} ff:${Util.output(ff)}`)

        // let _f = FormFieldCreator.getFieldFromFieldType(fieldDic)
        let pubIds = this.selectedFields.map(f => f.public_id)
        if (pubIds.includes(ff.public_id)) return

        pubIds.push(ff.public_id)
        this.selectedFields.push(ff)
        this.rcm.room.available_field_public_ids = pubIds

        this.rcm.updateRoom(this.rcm.room)
    }

    deleteLabel(ff: FormField) {
        Logger(`${funcName()} ff:${Util.output(ff)}`)

        let pubIds = this.selectedFields.map(f => f.public_id)
        if (!pubIds.includes(ff.public_id)) return

        pubIds = pubIds.filter(id => id != ff.public_id)
        this.selectedFields = this.selectedFields.filter(f => f.public_id != ff.public_id)
        this.rcm.room.available_field_public_ids = pubIds

        this.rcm.updateRoom(this.rcm.room)
    }
}
