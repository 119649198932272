
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger, funcName, onlyUnique } from "packs/common"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import FlashNotice from "packs/components/common/FlashNotice.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LocationIcon from "packs/components/icons/LocationIcon.vue"
import ScheduleViewLoader from "packs/components/loader/ScheduleViewLoader.vue"
import MdProgressSpinner from "packs/components/loader/MdProgressSpinner.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
// import RoomStorage from "packs/models/RoomStorage";
import RoomMember from "packs/models/RoomMember"
import LocationTag from "packs/models/LocationTag"
import LocationConverter from "packs/models/LocationConverter"

// const roomStorage = new RoomStorage();

@Options({
    components: { CheckboxTag, MdTextfieldTag },
})
export default class LocationConvertersList extends Vue {
    @Prop()
    canEdit: boolean

    // data:
    notice = Notice

    rm = RoomManager
    util = Util
    selectedTab = `locations`

    isSP = isMobile(window.navigator).phone
    failedKeyword = false
    loading = false

    myUser: RoomMember = null //コンタクトリストにある情報を使うため.

    newconv: LocationConverter = LocationConverter.createDefault()
    convs: LocationConverter[] = []
    failedConvBeforeKeyword = false
    failedConvAfterKeyword = false

    Util = Util

    created() {
        this.getLocationConverters()
    }

    mounted() {}

    checkLocationKeyword() {}

    getLocationConverters() {
        if (Util.isPresent(this.convs)) return
        if (this.loading) return
        this.loading = true

        LocationConverter.getLocations().then(res => {
            this.loading = false
            if (res) {
                this.convs = res
            }
        })
    }

    inputConvBeforeKeyword(e) {
        this.newconv.before_keyword = e
    }

    inputConvAfterKeyword(e) {
        this.newconv.after_keyword = e
    }

    clickKeywordToBlank() {
        if (this.newconv.convert_type == `default`) {
            this.newconv.convert_type = `keyword_to_blank`
        } else {
            this.newconv.convert_type = `default`
        }
    }

    saveConv() {
        Logger(`${funcName()}`)
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        LocationConverter.save(this.newconv, `post`).then(res => {
            this.loading = false
            this.rm.endProgress()

            if (res) {
                let convId = res.location_converter_id
                let _conv = (this.convs || []).find(conv => conv.location_converter_id == convId)
                if (_conv) {
                    _conv.before_keyword = res.before_keyword
                    _conv.after_keyword = res.after_keyword
                    _conv.convert_type = res.convert_type
                    _conv.lat = res.lat
                    _conv.lon = res.lon
                } else {
                    this.convs.push(res)
                }

                this.newconv = LocationConverter.createDefault()
            }
        })
    }

    selectConv(conv: LocationConverter) {
        this.$emit(`select`, conv)
    }

    removeConv(conv: LocationConverter) {
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        LocationConverter.delete(conv).then(res => {
            this.loading = false
            this.rm.endProgress()

            if (res) {
                let convId = res.location_converter_id
                let _conv = (this.convs || []).find(conv => conv.location_converter_id == convId)
                if (_conv) {
                    this.convs = this.convs.filter(conv => conv.location_converter_id != convId)
                }
            }
        })
    }
}
