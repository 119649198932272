
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import SelectTag from "packs/components/forms/SelectTag.vue"
import CheckboxTag from "packs/components/forms/CheckboxTag.vue"

// モデルを読込.
import CalendarManager from "packs/models/CalendarManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import MeetingAttendeesGroup from "packs/models/MeetingAttendeesGroup"
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        SelectTag,
        CheckboxTag,
    },
})
export default class AssignMagContent extends Vue {
    cm = CalendarManager
    rm = RoomManager
    rcm = RoomCreateManager
    isSP = isMobile(window.navigator).phone
    Util = Util
    SelectUtil = SelectUtil

    magRuleOption = null

    mounted() {
        // this.updateAlertText()
    }

    /**
     * Mag選択ルールにおけるアサインルールの集計期間を設計します。
     * @param choice [string]
     */
    selectPeriodDaysForAssign(e) {
        let choice = SelectUtil.periodDaysForAssignOptions.find(mt => mt.name == e)
        this.rcm.room_setting.period_days_for_assign = choice.type
        this.updateSettings()
    }

    /**
     * Mag選択ルールを更新します。
     * @param choice [string]
     */
    public selectAssignMagRule(e) {
        let choice = SelectUtil.assignMagRuleOptions.find(mt => mt.name == e)
        if (choice) this.magRuleOption = choice.type
        this.rcm.room_setting.assign_mag_rule = choice.type
        this.updateSettings()
    }

    public getDefaultAssignMagRuleName() {
        const defaultValue = `order`
        const typeArray = SelectUtil.assignMagRuleOptions
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.rcm.room_setting) return defaultName
        const currentOpt = typeArray.find(t => t.type == this.rcm.room_setting.assign_mag_rule)
        if (currentOpt) this.magRuleOption = currentOpt.type
        return currentOpt ? currentOpt.name : defaultName
    }

    public getDefaultPeriodDaysForAssignName() {
        const defaultValue = 7
        const typeArray = SelectUtil.periodDaysForAssignOptions
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.rcm.room_setting) return defaultName
        const currentOpt = typeArray.find(t => t.type == this.rcm.room_setting.period_days_for_assign)
        return currentOpt ? currentOpt.name : defaultName
    }

    public getDefaultMagTypeName() {
        const defaultValue = `display_none`
        const typeArray = SelectUtil.selectMagType
        const defaultName = typeArray.find(t => t.type == defaultValue).name
        if (!this.rcm.room_setting) return defaultName
        const currentOpt = typeArray.find(t => t.type == this.rcm.room_setting.mag_display_type)
        return currentOpt ? currentOpt.name : defaultName
    }

    /**
     * プロフィールを公開、非公開、確定時公開を選択します。
     */
    public inputMagType(e) {
        let magTypeDic = SelectUtil.selectMagType.find(mt => mt.name == e)
        this.rcm.room_setting.mag_display_type = magTypeDic.type
        // this.$emit(`changeMagType`, magTypeDic.type)
        // this.updateAlertText()
        this.updateSettings()
    }

    public updateSettings() {
        this.rcm.updateRoomSetting(this.rcm.room_setting)
        // this.$emit("updateAstagFromChild", this.rs)
    }
}
