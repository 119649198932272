
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import { Logger, funcName, sleep } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import AppointmentInfoContent from "./../AppointmentInfoContent.vue"
import Notice from "packs/models/Notice"
import AdLimitFree from "packs/components/advertise/AdLimitFree.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AppealContent from "packs/models/AppealContent"
import UserFile from "packs/models/UserFile"
import RoomMember from "packs/models/RoomMember"

@Options({
    components: {
        AppointmentInfoContent,
        AdLimitFree,
        TextfieldTag,
        TextareaTag,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class ModalEditAppointment extends Vue {
    // static readonly typeEdit: string = "editAppo"
    // static readonly typeEditAfterFixed: string = "editAppoAfterFixed"
    // static readonly typeAddNew: string = "addnewAppo"
    @Prop()
    appoStatus: string // addnewAppo: 新規で日程調整開始, editAppo:既存のアポの変更

    // data
    isSP = isMobile(window.navigator).phone
    Util = Util

    changedSomeStatus = false
    isActive = true

    notice = Notice
    rm = RoomManager
    appo: Appointment = null
    apc: AppealContent = null

    subm = SubscriptionManager
    showAds = false
    locations: string[] = null
    titles: string[] = null
    saving = false
    doesChangeAppo = false
    beforeAppo: Appointment = null

    contentHeight = 480

    loading = false
    isDrag: string = null
    fileForm: any = null

    hide(didChange: boolean, appo: Appointment) {
        this.$emit(`hide`, didChange, appo)
    }

    public created() {
        this.beforeAppo = Object.assign({}, this.rm.currentAppointment)
    }

    public mounted() {
        this.updateAppo()
        this.updateLocations()
        this.updateTitles()
    }

    public cancelInput() {
        this.hide(false, null)
    }

    @Watch("rm.currentAppointment", { deep: true })
    updateAppo() {
        let _appo = { ...this.rm.currentAppointment } as Appointment
        // if (!Util.isBlank(_appo.selectable_locations) && Util.isBlank(_appo.selectable_locations)) {
        //     _appo.selectable_locations = _appo.selectable_locations
        // }
        this.appo = _appo

        if (this.rm.currentRoom && Util.isPresent(this.rm.currentRoom.appeal_content)) {
            this.apc = { ...this.rm.currentRoom.appeal_content }
        } else {
            this.apc = AppealContent.createDefault()
        }
    }

    hasImage(): boolean {
        return this.apc && this.apc.image_url ? true : false
    }

    addImage() {
        // this.$emit(`editHeaderImage`)
        // $(".leftNavPanel").addClass("open")
    }

    deleteImage() {
        // this.$emit(`deleteHeaderImage`)
        this.apc.image_url = null
    }

    inputApcTitle(e) {
        this.apc.title = e
    }

    inputApcContent(e) {
        this.apc.content = e
    }

    // public fadeOutEditAppointmentModal() {
    //     if (!this.doesChangeAppo) {
    //         Logger(`変更内容を戻します。}`);
    //         this.rm.currentAppointment = this.beforeAppo;
    //     }
    //     this.hide(false, null);
    // }

    public headerTitle(): string {
        let title = ``
        if (this.appoStatus == Appointment.typeEdit) {
            title = `日程調整内容の変更`
        } else if (this.appoStatus == Appointment.typeEditAfterFixed) {
            title = `日程調整内容の変更`
        } else if (this.appoStatus == Appointment.typeEditAfterCanceled) {
            title = `日程調整内容の変更`
        } else if (this.appoStatus == Appointment.typeAddNew) {
            title = `日程調整を新規で作成`
        }
        return title
    }

    public headerDescription(): string {
        let text = ``
        if (this.appoStatus == Appointment.typeEdit) {
            text = ``
        } else if (this.appoStatus == Appointment.typeEditAfterFixed) {
            text = `確定したあとで所要時間は変更できません。`
        } else if (this.appoStatus == Appointment.typeEditAfterCanceled) {
            text = `キャンセルしたあとで所要時間は変更できません。`
        } else if (this.appoStatus == Appointment.typeAddNew) {
            text = `新規で追加する場合、過去の調整は終了して変更できません。`
        }
        return text
    }

    public buttonName() {
        let text = ``
        if (this.appoStatus == Appointment.typeEdit) {
            text = `保存`
        } else if (this.appoStatus == Appointment.typeEditAfterFixed) {
            text = `保存`
        } else if (this.appoStatus == Appointment.typeEditAfterCanceled) {
            text = `保存`
        } else if (this.appoStatus == Appointment.typeAddNew) {
            text = `作成`
        }
        return text
    }

    public saveInfo() {
        // this.currentDateTag = currentDateTag;
        // if (this.rm.fixed_owner_this_month.length >= 5 && this.subm.currentPlan && this.subm.currentPlan.amount == 0) {
        //     Logger(`無料プランで6回目以降のアポ確定です。`)
        //     this.showAds = true
        //     return false
        // }

        if (this.saving) return

        this.saving = true
        this.rm.startProgress()
        let appo = this.appo
        let room = { ...this.rm.currentRoom }
        if (this.apc) {
            room.appeal_content = this.apc
        }
        if (
            this.appoStatus &&
            [Appointment.typeEdit, Appointment.typeEditAfterFixed, Appointment.typeEditAfterCanceled].includes(this.appoStatus)
        ) {
            // 編集 または 確定後の編集の場合、PUTでリクエスト.
            Appointment.update(appo, "put", room).then(dic => {
                if (dic && dic.appo) {
                    const _appo = dic.appo as Appointment
                    Logger(`更新したroomの情報を確認します: ${Util.output(room)}`)
                    this.rm.currentRoom.appeal_content = { ...this.apc }
                    this.rm.setAppo(_appo)
                    this.appo = _appo
                    this.hide(true, this.appo)
                    this.doesChangeAppo = false
                }
                this.rm.endProgress()
                this.saving = false
            })
        } else {
            // 新規で追加
            Appointment.update(appo, "post", room).then(dic => {
                if (dic && dic.appo) {
                    const _appo = dic.appo
                    this.rm.currentRoom.appeal_content = { ...this.apc }
                    Notice.message = `新規で日程調整を開始しました。`
                    this.rm.setAppo(_appo)
                    this.appo = _appo
                    this.hide(true, this.appo)
                    this.doesChangeAppo = false
                }
                this.rm.endProgress()
                this.saving = false
            })
        }
    }

    public updateAppoFromChild(appo) {
        Logger(`変更したappoを取得.`)
        this.appo = appo
        this.doesChangeAppo = true
    }

    // public hideAvailableTimeModal(dtag) {
    //     // this.$vfm.close("AvailableTimeModal", dtag);
    //     this.hideAvailableTimeModal(this.changedSomeStatus, dtag);
    //     // this.$emit("hideAvailableTimeModal", this.changedSomeStatus, dtag);
    // }
    public fadeOutAdModal() {
        this.showAds = false
    }

    @Watch("rm.currentAppointment", { deep: true })
    public updateLocations() {
        if (!this.permitToAccess()) return
        if (this.locations) return false
        Logger(`${funcName()} 場所情報を取得してきます。`)
        this.rm.getLocationNames().then(e => {
            const locations = e
            this.locations = locations || []
            Logger(`ModalEditAppo.updateLocations 場所情報: ${e}`)
        })
    }

    /**
     * オーナーのみ取得を許可します。
     */
    public permitToAccess(): boolean {
        if (!this.rm.currentRoom || !this.rm.currentRoom.owner) return false
        if (!this.rm.userInfo) return false
        // if (this.titles) return false;
        if (this.rm.currentRoom.owner.user_group_id != this.rm.userInfo.user_group_id) return false
        return true
    }

    @Watch("rm.currentAppointment", { deep: true })
    public updateTitles() {
        if (!this.permitToAccess()) return
        if (this.titles) return false

        Logger(`${funcName()} タイトルを取得してきます。`)
        this.rm.getTitles().then(titles => {
            if (titles) {
                Logger(`タイトル: ${titles}`)
                this.titles = titles
            }
        })
    }

    onDrop(event, key = "", image = {}) {
        Logger(`ドロップしました。`)
        if (this.loading) {
            Notice.message = `アップロード中です。`
            return false
        }
        this.isDrag = null

        let params = UserFile.createFileParams(event, `public_image`)[0]
        if (!params) return

        params.append(`update_self`, `${false}`)

        this.loading = true
        this.rm.startProgress()

        RoomMember.uplaodUserImage(params).then(data => {
            event.target.value = null
            event = null
            this.loading = false
            this.rm.endProgress()
            if (data.result == `ok`) {
                Logger(`イメージを置き換えます : ${data.file.image_url}`)
                let apc = this.apc
                if (!apc) apc = AppealContent.createDefault()

                apc.image_url = data.file.image_url
                apc.presetInfo = false
                this.apc = apc
            }
        })
    }
}
