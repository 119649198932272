
import { Options, Prop, Vue } from "vue-property-decorator"

@Options({})
export default class IconStar extends Vue {
    @Prop()
    starred: boolean

    public pushStar() {
        this.$emit(`pushStar`)
    }
}
