
// モジュールを読込.
import { Options, Prop, Vue, Watch, Ref } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import CopyButton from "packs/components/buttons/CopyButton.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Notice from "packs/models/Notice"
import RoomMember from "packs/models/RoomMember"
import NotificationControl from "packs/utils/NotificationControl"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import RoomStorage from "packs/models/RoomStorage"
import Const from "packs/utils/Const"

const roomStorage = new RoomStorage()

@Options({
    components: { CopyButton, TextfieldTag },
})
export default class NotInvited extends Vue {
    // data:

    notice = Notice

    rm = RoomManager
    Util = Util
    SelectUtil = SelectUtil

    isSP = isMobile(window.navigator).phone
    users: any[] = []
    loading = false

    mounted() {
        gtagPage("#/not_invited")

        if (!this.rm.userInfo) {
            this.rm.startProgress()
            this.rm.getAvailableSchedule().then(res => {
                this.rm.endProgress()
                if (!Const.chukaiFreePlan(this.rm)) {
                    this.$router.push("/")
                    return
                }
                if ((this.rm.invited_user_groups || []).length > 0) {
                    this.$router.push("/")
                    return
                }
                this.generateUUID()
            })
        } else {
            if (!Const.chukaiFreePlan(this.rm)) {
                this.$router.push("/")
                return
            }
            if ((this.rm.invited_user_groups || []).length > 0) {
                this.$router.push("/")
                return
            }
            this.generateUUID()
        }
    }

    generateUUID() {
        // if (this.rm.invite_connect_key) {
        //     return
        // }
        // axios.post(`${Util.prefixUrl}/user_groups/generate_invite_connect_key`).then(res => {
        //     this.rm.invite_connect_key = res.data.invite_connect_key
        // })
    }

    clickLoggedOutButton() {
        Logger(`ログアウト処理時にキャッシュを削除します.`)
        roomStorage.resetAll()
    }

    public clickTextfield() {
        Logger(`clickTextfield `)
        $(`input.copyInviteConnectKey`).select()
    }
}
