import { DateTime } from "luxon"
import { zeroPadding, Logger } from "../common"
import axios from "types/axios"
import Util from "packs/utils/Util"
import Notice from "./Notice"
import NotificationControl from "packs/utils/NotificationControl"

interface SSOSettings {
    can_use_sso: boolean
    is_force_sso_when_sign_in: boolean
    sso_domain: string

    sso_sp_entity_id: string
    sso_sp_acs_url: string
    sso_sp_acs_url_re: string
    sso_sp_sign_in_url: string
    sso_sp_sign_out_url: string

    sso_idp_cert: string
    sso_idp_entity_id: string
    sso_idp_slo_target_url: string
    sso_idp_sso_target_url: string
}

interface UserGroupSetting {
    can_attach_ics_file: boolean
    can_change_timezone: boolean
    google_analytics_id: string
    admin_emails: string
}

interface UserGroup {
    assigned_email_domains: string[]
    google_analytics_id: string
    id: string
    name: string
    user_group_setting: UserGroupSetting
}

export default class Organization {
    constructor(
        public id: string,

        public email_domains: string[],
        public sso_settings: SSOSettings,
        public user_groups: UserGroup[]
    ) {}

    static fetchFromJson(orgJson: any): Organization {
        let org = orgJson as Organization
        return org
    }

    static updateSSO(org: Organization) {
        return axios
            .put(`${Util.prefixUrl}/organizations/sso_settings`, { sso_settings: org.sso_settings })
            .then(res => {
                Notice.message = res.data.message
                const _sso: SSOSettings = res.data.sso_settings
                return { sso_settings: _sso }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static checkSSOEmail(email: string) {
        return axios
            .get(`/sso_saml/check_email?email=${email}`)
            .then(res => {
                // Notice.message = res.data.message
                const url: string = res.data.url
                return { url: url }
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    /**
     * カード情報を更新します。
     * @param token
     */
    static updatePaymentMethod(token: {}): Promise<boolean> {
        return axios
            .put(`${Util.prefixUrl}/subscriptions/update_payment_method`, {
                token: token,
            })
            .then(e => {
                return true
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    /**
     * カード情報が同じか
     * @param card
     * @param newCard
     */
    static isDiff(card, newCard): boolean {
        return (
            newCard.last4 != card.last4 ||
            newCard.brand != card.brand ||
            newCard.exp_month != card.exp_month ||
            newCard.exp_year != card.exp_year
        )
    }

    static updateUserGroupSettings(ugs: UserGroupSetting): Promise<UserGroupSetting> {
        return axios
            .put(`${Util.prefixUrl}/user_groups/user_group_settings`, ugs)
            .then(res => {
                Notice.message = res.data.message
                return res.data.user_group_settings
            })
            .catch(err => {
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static deleteAllDependent() {
        let params = {}
        return axios
            .delete(`${Util.prefixUrl}/user_groups/delete_all_dependent`, { data: params })
            .then(res => {
                Logger(`res: ${res.data.message} ${Util.output(res.data)}`)
                if (res.data.message) {
                    Notice.message = `${res.data.message}`
                }
                return
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return
            })
    }
}
