
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { Logger } from "packs/common"
import { gtagClick } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import TextfieldTag from "packs/components/forms/TextfieldTag.vue"
import SelectTag from "packs/components/forms/SelectTag.vue"
import LblueListContent from "packs/components/calendar/LblueListContent.vue"
import VariableListsContent from "packs/pages/link/parts/room_settings/VariableListsContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import RoomSetting from "packs/models/RoomSetting"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomCreateManager from "packs/models/RoomCreateManager"

@Options({
    components: {
        TextareaTag,
        TextfieldTag,
        SelectTag,
        LblueListContent,
        VariableListsContent,
    },
})
export default class ProviderEventEditContent extends Vue {
    // data:

    rm = RoomManager
    rcm = RoomCreateManager

    isSP = isMobile(window.navigator).phone

    Util = Util
    SelectUtil = SelectUtil
    RoomSetting = RoomSetting
    rs: RoomSetting = null

    contentWidth = 350

    systemUpdatedAt = Util.getSec()

    currentType = null

    openDescriptionVariables = false
    openTitleVariables = false

    public mounted() {
        this.rs = { ...this.rcm.room_setting }
    }

    addVal(type) {
        if (type == `title`) {
            if (this.openTitleVariables) {
                this.openTitleVariables = false
            } else {
                this.openTitleVariables = true
            }
        } else if (type == `desc`) {
            if (this.openDescriptionVariables) {
                this.openDescriptionVariables = false
            } else {
                this.openDescriptionVariables = true
            }
        }
    }

    inputEventTitle(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)
        Logger(`settingを見ます: ${Util.output(this.rs)}`)
        this.rs.provider_event_title = e
        this.rcm.updateRoomSetting(this.rs)
    }

    inputEventDescription(e) {
        // let setting = RoomSetting.findOrCreateSetting(this.room)

        this.rs.provider_event_description = e
        this.rcm.updateRoomSetting(this.rs)
    }

    /**
     * 選択した変数をカーソルがある位置に挿入します。
     */
    public selectVariable(e, type) {
        Logger(`select ${e}`)
        if (type == `title`) {
            this.rs.provider_event_title = `${this.rs.provider_event_title || ``}${e}`
        } else if (type == `desc`) {
            this.rs.provider_event_description = `${this.rs.provider_event_description || ``}${e}`
        }

        this.rcm.updateRoomSetting(this.rs)
        // let textarea = this.TemplateTextarea
        // const pos = textarea.selectionStart
        // let sentence = textarea.value
        // const len = sentence.length

        // const before = sentence.substr(0, pos)
        // const after = sentence.substr(pos, len)
        // sentence = before + e + after
        // this.memo = sentence

        // // プレビューを更新.
        // // this.updateAfterText()

        // Logger(`テキストを更新したあと、フォーカスを移動します。 ${(before + e).length}`)

        // // カーソルを元の位置（挿入した変数の後ろ）に変更.
        // setTimeout(() => {
        //     textarea.focus()
        //     textarea = this.TemplateTextarea
        //     textarea.selectionStart = textarea.selectionEnd = (before + e).length
        // }, 10)
    }
}
