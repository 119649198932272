
// モジュールを読込.
import { Options, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import { DateTime, Interval, DateTimeFormatOptions } from "luxon"
import isMobile from "ismobilejs"
import { truncate } from "packs/models/TruncateUtil"

// コンポーネントを読込.
import Notice from "packs/models/Notice"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import { Logger, copyToClipboard, funcName } from "packs/common"
import Util from "packs/utils/Util"
import MessageTemplate from "packs/models/MessageTemplate"
import { gtagClick } from "packs/GoogleTagManager"

@Options({
    components: {},
})
export default class TemplateCell extends Vue {
    @Prop()
    tmp: MessageTemplate

    @Prop()
    showDeleteButton: boolean

    @Prop()
    showEditButton: boolean

    // data
    isSP = isMobile(window.navigator).phone
    appos = null
    rm = RoomManager
    truncate = truncate

    afterText = ``
    showTooltip = false

    text = `<<出席者-1-氏名>>様にあったご案内をさせていただきます。下記日程よりご確定ください。`

    // showTip = false
    showTips = []

    created() {}

    mounted() {}

    editTmp() {
        // 編集画面に移行.
        this.$emit("editTmp", this.tmp)
    }

    deleteTmp() {
        // 削除していいかのアラートを表示します。
        this.$emit("deleteTmp", this.tmp)
    }

    public selectTmp() {
        Logger(`${funcName()} tmp.public_id:${this.tmp.public_id}`)
        this.$emit("select", this.tmp)
    }

    public copyPublicId() {
        copyToClipboard(this.tmp.public_id)
        this.showTooltip = true
        setTimeout(this.hideTooltip, 2000)
        gtagClick(`テンプレートIDをコピー`, `rid:${this.tmp.public_id}`)
    }

    public hideTooltip(index) {
        // this.showTip = false
        // let tmp = this.tmps[index]
        this.showTooltip = false
        // this.showTips[index] = false
    }
}
