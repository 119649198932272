
// モジュールを読込.
import { Vue, Options, Prop, Emit, Watch } from "vue-property-decorator"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"
import isMobile from "ismobilejs"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"

// コンポーネントを読込.
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Room from "packs/models/Room"
import Appointment from "packs/models/Appointment"
import RoomStorage from "packs/models/RoomStorage"
import Notice from "packs/models/Notice"
import axios from "types/axios"
import CalendarTag from "packs/models/CalendarTag"
import CalendarManager from "packs/models/CalendarManager"

const roomStorage = new RoomStorage()

@Options({
    components: { MdTextfieldTag, VueFinalModal, MdProgressBar },
})
export default class RefreshCalendarModal extends Vue {
    @Prop()
    appo: Appointment

    // data
    rm = RoomManager
    cm = CalendarManager
    loading = false
    isSP = isMobile(window.navigator).phone

    contentHeight = 300
    validName = true

    astagName = ``
    saving = false
    notice = Notice

    public created() {}

    public updated() {}

    public closeModal() {
        this.$vfm.close(`RefreshCalendarModal`)
    }

    public fadeOutModal() {
        // gtagClick(`フリーフォーム場所変更 変更せず閉じる`, ``)
        // Logger(`フリーフォームを閉じます`)
        // this.$emit(`updatedLocation`, false)
    }

    public refreshCal() {
        if (this.loading) return
        this.loading = true
        if (this.cm) {
            this.cm.getCalList(true).then(res => {
                this.loading = false

                if (res) {
                    this.$emit(`refreshedCalInfo`)
                }
            })
        }
    }
}
