
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { funcName, Logger, sleep } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import Notice from "packs/models/Notice"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"
import { VueFinalModal } from "vue-final-modal"
import MdTextfieldTag from "packs/components/forms/MdTextfieldTag.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import AssignForm from "packs/models/AssignForm"
import RoomCreateManager from "packs/models/RoomCreateManager"
import FormCreateManager from "packs/models/FormCreateManager"

@Options({
    components: { UserIcon, VueFinalModal, MdTextfieldTag },
})
export default class SearchRoomModal extends Vue {
    // data:

    members: RoomMember[] = []
    loading = false
    currentMember = null

    Util = Util

    rm = RoomManager

    selectedRoom: Room = null
    publicId = ``
    rcm = RoomCreateManager
    fcm = FormCreateManager

    openModal() {
        gtagPage(`#/フォーム読み込みモーダル`)
    }

    hide() {
        this.$vfm.close(`SearchRoomModal`)
    }

    public addAttendee(member: RoomMember) {
        Logger(`memberを追加します : ${Util.output(member)}`)
        // gtagClick(`出席者追加`, `${member.name}`)
        // if (member.email == this.rm.userInfo.email) return

        let _mem = this.members.find(m => m.email == member.email)

        if (Util.isBlank(_mem)) {
            // answer_resultsの内容を作成します.
            member = RoomMember.setAnswerResultsFromMember(member)
            Logger(`addAttendee:member: ${Util.output(member)}`)
            this.members.push(member)
        } else {
            Object.assign(_mem, member)
        }

        // if (this.members.length == 1) {
        //     this.selectSendUser(member)
        // }
        Logger(`addAttendee roommeberを更新しました: ${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        // this.editOrNewForm = ``
    }

    inputPublicID(val) {
        this.publicId = val
        this.selectedRoom = null
    }

    deleteUser(mem: RoomMember) {
        this.members = this.members.filter(m => m != mem)
    }

    clickSearch() {
        Logger(`${funcName()} `)
        if (this.loading) return
        this.loading = true

        this.rm.startProgress()
        Room.search([this.publicId], this.rm.userInfo.user_id, null, null, null, null, null).then(rooms => {
            if (Util.isPresent(rooms)) {
                let selectedRoom = rooms[0]
                this.selectedRoom = selectedRoom
            } else {
                Notice.message = `公開ページが見つかりませんでした。`
            }
            this.rm.endProgress()
            this.loading = false
        })
    }

    clickCancel() {
        this.hide()
    }

    selectForm() {
        if (Util.isBlank(this.selectedRoom)) return
        let dic = AssignForm.copyFields(this.selectedRoom.form_fields, {})
        let fields = dic._fields
        this.fcm.updateFormFields(fields)
        this.rcm.updateFormFields(fields)
        this.fcm.updateConditions([])
        this.$emit(`selectCopiedForm`)
        this.hide()
    }
}
