
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"
import { sleep, Logger, funcName } from "packs/common"

// コンポーネントを読込.
import AlertBadge from "packs/components/icons/AlertBadge.vue"
import CalendarThumbnailContent from "packs/components/link/CalendarThumbnailContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomMember from "packs/models/RoomMember"
import Room from "packs/models/Room"
import RoomCreateManager from "packs/models/RoomCreateManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import CalendarManager from "packs/models/CalendarManager"
import CalendarUtil from "packs/utils/CalendarUtil"
import PossibleDatesManager from "packs/models/PossibleDatesManager"

@Options({
    components: { AlertBadge, CalendarThumbnailContent },
})
export default class LinkHeaderRoomSettings extends Vue {
    @Prop()
    pageTitle: string

    @Prop()
    buttonTitle: string

    // @Prop()
    // buttonEnabled: boolean

    @Prop()
    showChangeIcon: boolean

    @Prop()
    showWhatCal: boolean

    @Prop()
    showBackButton: boolean

    @Prop()
    isSuspend: boolean

    @Prop() // func
    clickChangeName

    @Prop() // func
    clickChangeAutoMode

    // @Prop()
    // members: RoomMember[]

    // @Prop()
    // room: Room

    @Prop() // func
    editEditableContent

    isSP = isMobile(window.navigator).phone
    href = ``
    rm = RoomManager
    rcm = RoomCreateManager
    cm = CalendarManager
    pdm = PossibleDatesManager
    Util = Util
    selectedTabName = `room`
    selectedFormTabName = `commonForm`
    enabledSave = false

    loading = false
    periodText = ``

    // 公開ページ public_room / 限定公開ページ parent_room
    roomType = `parent_room`

    mounted() {
        this.href = location.href
        this.buttonEnabled()
        this.spanText()
    }

    clickBack() {
        this.$router.back()
    }

    @Watch("rcm.members", { deep: true })
    buttonEnabled() {
        if (this.rcm.room && this.rcm.room.room_type == `public_room`) {
            this.enabledSave = true
            return
        }
        if (Util.isBlank(this.rcm.room) || Util.isBlank(this.rcm.members)) {
            this.enabledSave = false
            return
        }
        let enabled = false
        if (this.rcm.room.room_type == `parent_room`) {
            Logger(`buttonEnabled: members: ${Util.output(this.rcm.members)}`)
            let _mems = RoomMember.removeOwner(this.rcm.members, this.rm.userInfo)
            enabled = _mems.length > 0
        } else {
            enabled = true
        }

        this.enabledSave = enabled
    }

    clickPinkButton() {
        this.$emit(`clickPinkButton`)
    }

    saveDraft() {
        this.$emit(`saveDraft`)
    }

    clickNew() {
        this.$emit(`clickNew`)
    }

    showRoomPreview() {
        this.$emit(`showRoomPreview`)
    }

    showRoom() {
        this.selectedTabName = `room`
        this.$emit(`select`, `room`)
    }

    showMailPreview() {
        this.selectedTabName = `mail`
        this.$emit(`select`, `mail`)
    }

    showFormPreview() {
        this.selectedTabName = `form`
        this.$emit(`select`, `form`)
    }

    showParentRoomSettings() {
        this.selectedTabName = `settings`
        this.$emit(`select`, `parent_settings`)
    }

    showPublicRoomSettings() {
        this.selectedTabName = `settings`
        this.$emit(`select`, `public_settings`)
    }

    showAlertBudge() {
        if (!this.rcm.room || !this.rcm.members) return false
        // let showBudge = !this.buttonEnabled()
        let showBudge = !this.enabledSave
        return showBudge
    }

    clickBudge() {
        Logger(`clickbudge`)
        this.editEditableContent(`attendees`)
    }

    get showDraftButton(): boolean {
        if (!this.rcm.room) return
        // 限定公開の場合、作成時のみしか調整ページ作成しにこれないので。
        if (this.rcm.room.room_type == `parent_room`) return true
        // すでに公開している場合、表示不要.
        if (this.rcm.room.public_status == `publishing`) return false
        if (this.rcm.room.public_status == `suspended`) return false
        return true
    }

    // 調整カレンダーの編集を終了したときにもともと開いていたページに戻ります。
    openBeforeTab() {
        let tabname = this.selectedTabName
        if (tabname == `settings`) {
            if (this.rcm.room.room_type == `public_room`) {
                tabname = `public_settings`
            } else {
                tabname = `parent_settings`
            }
        }
        sleep(200).then(_ => {
            this.$emit(`select`, tabname)
        })
    }

    editEndAstag(save = false) {
        Logger(`astag編集を終了: save: ${save}`)
        if (!save) {
            Logger(`${funcName()} beforeAstag:${Util.output(this.cm.beforeAstag)}`)
            this.rcm.astag = this.cm.beforeAstag
            this.cm.astag = null
            this.rcm.edittingAstag = null
            this.openBeforeTab()
            return
        }

        let sendType = `put`
        if (this.cm.astag.id == `newId`) {
            sendType = `post`
        }
        if (this.loading) return
        this.loading = true
        this.rm.startProgress()

        AvailableScheduleTag.saveAvailableSchedule(this.cm.astag, sendType, null).then(ast => {
            this.rm.endProgress()
            this.loading = false

            if (ast) {
                // ast.selected = true
                let astags = this.rm.astags ? [...this.rm.astags] : []
                let _astag = astags.find(a => a.id == ast.id)
                if (_astag) {
                    // _astag.selected = true
                    Object.assign(_astag, ast)
                } else {
                    astags.push(ast)
                }
                if (
                    this.rcm.room_setting.is_instant_room &&
                    Util.isPresent(ast.meeting_attendees_groups) &&
                    ast.can_connect_calendar &&
                    Util.isPresent(this.rcm.room.instant_room_assigned_users)
                ) {
                    // すでに紐付いていてmagsの数に変更がある場合、再度連携内容を確認し、magが削除されていたら
                    let l_assigned_members = []
                    // let mag_ids = this.rcm.room.instant_room_assigned_users.map(m => m.meeting_attendees_group_id)
                    let mag_ids = ast.meeting_attendees_groups.map(m => m.id)
                    for (let lau of this.rcm.room.instant_room_assigned_users) {
                        if (mag_ids.includes(lau.meeting_attendees_group_id)) {
                            l_assigned_members.push(lau)
                        }
                    }
                    this.rcm.room.instant_room_assigned_users = l_assigned_members
                }

                this.rm.astags = astags
                this.rcm.room.owner_avaialble_schedule_tag_id = ast.id
                this.rcm.updateAstag(ast)
                this.rcm.updateRoom(this.rcm.room)
                this.cm.resetAstagInfo()
                this.cm.astag = null
                this.rm.astag = ast
                // this.pdm.setNew(this.rcm.room, this.rm.userInfo, this.rcm.appo)
                this.rcm.edittingAstag = null
                this.openBeforeTab()
            }
        })
    }

    clickEditTimeSpan() {
        this.editEditableContent(`appo`)
    }

    @Watch(`rcm.periodText`, { deep: true })
    spanText() {
        let ugSetting = this.rm.findUserGroupSetting()

        // 手動モードで期間無視設定の場合、日程は表示しない.
        if (
            this.rcm.astag &&
            this.rcm.astag.is_only_use_optional_schedules &&
            ugSetting &&
            ugSetting.can_ignore_filters_if_manual_mode
        ) {
            this.periodText = `手動モードでは期間設定が無効になっています（「企業設定」で変更可能）`
            return
        }

        this.periodText = this.rcm.periodText
    }

    selectSmallCategory(type: string) {
        this.selectedFormTabName = type
    }
}
