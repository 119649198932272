
// モジュールを読込.
import { Options, Prop, Vue, Ref } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { DateTime } from "luxon"
import { funcName, Logger } from "packs/common"
import { gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import AddressBookContent from "packs/pages/link/parts/AddressBookContent.vue"
import UserIcon from "packs/components/icons/UserIcon.vue"
import SendMailContent from "packs/pages/link/parts/available_schedule/SendMailContent.vue"
import IconHelp from "packs/components/icons/IconHelp.vue"
import UserIconSendMenu from "packs/components/icons/UserIconSendMenu.vue"
import AnsweredFormTable from "packs/pages/link/parts/room_summary/AnsweredFormTable.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import CalendarManager from "packs/models/CalendarManager"
import Notice from "packs/models/Notice"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Const from "packs/utils/Const"
import CalendarTag from "packs/models/CalendarTag"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import RoomCreator from "packs/utils/RoomCreator"
import Room from "packs/models/Room"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import MailFlow from "packs/models/MailFlow"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"
import RoomMember from "packs/models/RoomMember"
import RoomTab from "packs/models/RoomTab"
import AddressBook from "packs/models/AddressBook"
import ContactListGroup from "packs/models/ContactListGroup"
import VotedRoomManager from "packs/models/VotedRoomManager"

@Options({ components: { AddressBookContent, UserIcon, SendMailContent, IconHelp, UserIconSendMenu, AnsweredFormTable } })
export default class SelectAttendeesWithAddressbook extends Vue {
    @Prop()
    height: string

    @Prop() // mail / attendees
    viewType: string

    cm = CalendarManager

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    Util = Util

    pdm = PossibleDatesManager
    rcm = RoomCreateManager
    vrm = VotedRoomManager

    currentMember: RoomMember = null

    editOrNewForm = ``
    members: RoomMember[] = []
    opMembers: RoomMember[] = []
    fixMembers: RoomMember[] = []
    UserPermissionsOption = UserPermissionsOption
    titles = TemplateUtil.getTitles()
    mailsDic: { [key: string]: MailFlow } = {} // {key: user.email, value: {subject:, content:, cc_emails:, use_template: false}}
    currentMail: MailFlow = null
    // 選択中のメンバーのフォーム
    currentAnsweredFormMem: RoomMember = null
    currentAnsweredParentRoom: Room = null

    tmp: MessageTemplate = null
    loading = false

    // 作成されたルーム情報 Array<{room: Room, room_link: string}>
    generatedRoomsDic: any[] = null

    public mounted() {
        if (this.viewType == `mail`) {
            // rcmからロード.
            this.currentMail = MailFlow.createDefaultForUser(null)
            this.rcm.currentMail = this.currentMail
            this.fixMembers = Util.isPresent(this.rcm.fixMembers) ? this.rcm.fixMembers : [this.rm.userInfo]
            this.members = Util.isPresent(this.rcm.members) ? this.rcm.members : []
            this.opMembers = Util.isPresent(this.rcm.opMembers) ? this.rcm.opMembers : []
        } else if (this.viewType == `attendees`) {
            // vrmからロード.
            this.members = this.vrm.attendees || []
        }
    }

    public addAttendee(member: RoomMember) {
        Logger(`memberを追加します : ${Util.output(member)}`)
        gtagClick(`出席者追加`, `${member.name}`)
        // if (member.email == this.rm.userInfo.email) return

        let _mem = this.members.find(m => m.email == member.email)

        if (Util.isBlank(_mem)) {
            // answer_resultsの内容を作成します.
            member = RoomMember.setAnswerResultsFromMember(member)
            Logger(`addAttendee:member: ${Util.output(member)}`)
            this.members.push(member)
        } else {
            Object.assign(_mem, member)
        }

        Logger(`addAttendee roommeberを更新しました: ${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        this.editOrNewForm = ``
        this.rcm.members = this.members
    }

    selectContactListGroup(clg: ContactListGroup) {
        let cls = [...clg.contact_lists]
        // 必須出席者、任意出席者、日程決定者に分類します.
        let fixMems = cls.filter(m => m.assign_type == `fix`)
        let mems = cls.filter(m => m.role == RoomMember.MEMBER)
        let opmems = cls.filter(m => m.role == RoomMember.MEMBER_OPTIONAL)
        fixMems.unshift(this.rm.userInfo)

        let beforeMems = [...(this.members || [])]
        let beforeOpMems = [...(this.opMembers || [])]
        let beforeFixMems = [...(this.fixMembers || [])]
        Logger(`${funcName()} beforeMems:${Util.output(beforeMems)}, mems:${Util.output(mems)}`)
        for (let _mem of mems) {
            let mem = beforeMems.find(_m => _m.email == _mem.email)
            if (Util.isBlank(mem)) {
                beforeMems.push(_mem)
            }
        }

        for (let _mem of opmems) {
            let _m = beforeOpMems.find(_m => _m.email == _mem.email)
            if (Util.isBlank(_m)) {
                beforeOpMems.push(_mem)
            }
        }

        for (let _mem of fixMems) {
            let _m = beforeFixMems.find(_m => _m.email == _mem.email)
            if (Util.isBlank(_m)) {
                beforeFixMems.push(_mem)
            }
        }

        // Array.prototype.push.apply(this.members, mems)
        // Array.prototype.push.apply(this.opMembers, opmems)
        // Array.prototype.push.apply(this.fixMembers, fixMems)
        this.members = beforeMems
        this.opMembers = beforeOpMems
        this.fixMembers = beforeFixMems
        if (this.viewType == `attendees`) {
            this.vrm.attendees = this.members
        }

        Logger(`selectContactListGroup roommeberを更新しました: ${this.members.length}`)
        this.currentMember = null
        // this.rcm.updateMembers(this.members)
        this.editOrNewForm = ``
        this.rcm.members = this.members
        this.rcm.clg = { ...clg }
    }

    deleteSendUser(mem: RoomMember, memType: string) {
        if (memType == `require`) {
            this.members = this.members.filter(m => m != mem)
            this.rcm.members = this.members
        } else if (memType == `option`) {
            this.opMembers = this.opMembers.filter(m => m != mem)
            this.rcm.opMembers = this.opMembers
        } else if (memType == `fix`) {
            this.fixMembers = this.fixMembers.filter(m => m != mem)
            this.rcm.fixMembers = this.fixMembers
        }
    }

    selectSendUser(mem: RoomMember) {
        let mailDic: MailFlow = { ...this.mailsDic[mem.email] }
        Logger(`切り替え後: mailDic[${mem.email}]: ${Util.output(mailDic)}`)
        this.currentMail = mailDic
    }

    /**
     * ユーザーアイコンをクリックすると、もう片方のリストに入れ替えます.
     * @param mem [RoomMember]
     * @param memType [string] require, option
     */
    changeList(mem: RoomMember, memType: string) {
        Logger(`${funcName()} mem:${mem.name} ${mem.email} mem.id:${mem.id}, mem.user_id:${mem.user_id}, memType:${memType}`)
        if (memType == `require`) {
            let newMems = []
            for (let _mem of this.members) {
                if (mem.user_id == _mem.user_id && mem.id == _mem.id) {
                } else {
                    newMems.push(_mem)
                }
            }
            this.members = newMems
            // this.members = this.members.filter(_mem => _mem.user_id != mem.user_id && _mem.id != mem.id)
            if (this.viewType == `mail`) {
                this.opMembers.unshift(mem)
            } else {
                this.vrm.notAttendees.push(mem)
                this.vrm.attendees = this.members
            }
        } else if (memType == `option`) {
            this.opMembers = this.opMembers.filter(_mem => _mem.user_id != mem.user_id && _mem.id != mem.id)
            this.members.unshift(mem)
        } else if (memType == `notAttendee`) {
            let newMems = []
            for (let _mem of this.vrm.notAttendees) {
                if (mem.user_id == _mem.user_id && mem.id == _mem.id) {
                } else {
                    newMems.push(_mem)
                }
            }
            // this.opMembers = this.opMembers.filter(_mem => _mem.user_id != mem.user_id)
            this.vrm.notAttendees = newMems
            this.members.push(mem)
            this.vrm.attendees = this.members
        }
    }

    onlyAttend(mem: RoomMember, memType: string) {
        Logger(`${funcName()} mem:${mem.name} ${mem.email}`)
        let beforeMems = [...(this.members || [])]
        let beforeOpMems = [...(this.vrm.notAttendees || [])]

        Array.prototype.push.apply(beforeMems, beforeOpMems)
        // let currentMem = beforeMems.find(m => m.user_id == mem.user_id)
        this.members = [mem]
        let mems = beforeMems.filter(m => m.user_id != mem.user_id)
        this.vrm.attendees = this.members
        this.vrm.notAttendees = mems
    }

    /**
     * ユーザーアイコンをクリックすると、もう片方のリストに入れ替えます.
     * @param mem [RoomMember]
     */
    addFixMember(mem: RoomMember) {
        Logger(`addFixMember: ${mem.name}`)
        if (this.fixMembers.find(m => m.email == mem.email)) {
        } else {
            this.fixMembers.push(mem)
        }
        this.rcm.fixMembers = this.fixMembers
    }

    // 個別に送るメール内容を編集した場合に通知がきます.
    updateMail(mf: MailFlow) {
        this.currentMail = mf
        this.rcm.currentMail = mf
    }

    showAnswerResults(mem: RoomMember) {
        let parent = this.vrm.getRoomFromMember(this.vrm.rooms, mem)
        if (Util.isPresent(parent)) {
            this.currentAnsweredParentRoom = parent
        }
        this.currentAnsweredFormMem = mem
    }

    closeForm() {
        this.currentAnsweredFormMem = null
    }
}
