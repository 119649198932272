
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import axios from "axios"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import { DateTime } from "luxon"
import { gtagLog, gtagClick, gtagPage } from "packs/GoogleTagManager"

// コンポーネントを読込.
import TextareaTag from "packs/components/forms/TextareaTag.vue"
import ModalTemplates from "packs/pages/link/modals/ModalTemplates.vue"
import { VueFinalModal } from "vue-final-modal"
import MdProgressBar from "packs/components/loader/MdProgressBar.vue"

// モデルを読込.
import PropertyStore from "packs/models/PropertyStore"
import RoomManager from "packs/models/RoomManager"
import RoomMessage from "packs/models/RoomMessage"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomStorage from "packs/models/RoomStorage"
import Appointment from "packs/models/Appointment"
import Const from "packs/utils/Const"
import MessageTemplate from "packs/models/MessageTemplate"
import TemplateUtil from "packs/utils/TemplateUtil"

const roomStorage = new RoomStorage()

@Options({
    components: {
        TextareaTag,
        ModalTemplates,
        VueFinalModal,
        MdProgressBar,
    },
})
export default class AlertWithTextareaModal extends Vue {
    @Prop()
    title: string

    @Prop()
    text: string

    @Prop()
    okButtonName: string

    @Prop()
    cancelButtonName: string

    @Prop()
    room

    // data:

    rm = RoomManager
    isSP = isMobile(window.navigator).phone
    typedMessage = ""
    loading = false

    tmp: MessageTemplate = null
    Const = Const

    public created() {}

    public updated() {}

    public async mounted() {}

    public cancelInput() {
        // モーダルを閉じます。
        this.$vfm.close("CancelAppointmentModal")
    }

    public destroyed() {}

    public inputMessage(e) {
        this.typedMessage = e
    }

    /**
     * テンプレモーダルを表示.
     */
    public useTemplate() {
        if (Const.started(this.rm)) {
            // 使うテンプレを表示します。 編集ボタンを押下したら、そのまま編集画面へ.
            this.$vfm.open("ModalTemplates")
        } else {
            this.$vfm.open("AdRegistrationModal")
        }
    }

    public decideTemplate(tmp: MessageTemplate) {
        //
        this.tmp = tmp
        this.updateTmp()
    }

    public updateTmp() {
        // テンプレを最新のユーザー/アポの内容をはめて更新します。
        if (!this.tmp && !this.room) return
        let mems = this.room.members
        let owner = mems.find(m => m.role == 100) || this.rm.userInfo
        let atts = mems.filter(m => m.user_id != this.rm.userInfo.user_id)
        let str = TemplateUtil.insertTemplateText(this.tmp.content, atts, owner, this.room.current_appointment, this.rm.userInfo)
        this.typedMessage = str
        this.$vfm.close("ModalTemplates")
    }

    public clickOK() {
        this.$emit(`clickOK`, this.typedMessage)
    }

    public clickCancel() {
        this.$emit(`clickCancel`)
    }
}
