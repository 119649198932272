
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import FormField from "packs/models/FormField"

@Options({
    components: {},
})
export default class MailPreviewPartsAnswerResults extends Vue {
    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    rm = RoomManager
    rcm = RoomCreateManager
    DateTime = DateTime

    fields: FormField[] = null

    mounted() {
        this.updateFields()
    }

    @Watch(`rcm.fields`)
    updateFields() {
        if (!this.rcm.fields) return

        this.fields = this.rcm.realFields()
    }
}
