
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import Util from "packs/utils/Util"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Const from "packs/utils/Const"
import { truncate } from "packs/models/TruncateUtil"
import { Dropdown } from "uiv"

// コンポーネントを読込.
import UserIcon from "packs/components/icons/UserIcon.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import RoomMember from "packs/models/RoomMember"

@Options({ components: { UserIcon, Dropdown } })
export default class UserIconSendMenu extends Vue {
    @Prop()
    mem: RoomMember

    @Prop() // require, option, notAttendee(投票したが日程が合わなくて非出席者になっているメンバー)
    memType: string

    @Prop() // mail（クイック投票時）, attendees（投票確定時）
    viewType: string

    isSP = isMobile(window.navigator).phone
    rm = RoomManager
    Util = Util
    truncate = truncate
    // type: string;

    public async mounted() {
        // this.type = this.selectedTab;
        // targets.addEventListener("click", this.clickAvailableTimeEvent, false);
    }

    deleteSendUser() {
        this.$emit(`deleteSendUser`, this.mem, this.memType)
    }

    changeList() {
        this.$emit(`changeList`, this.mem, this.memType)
    }

    addFixMember() {
        this.$emit(`addFixMember`, this.mem)
    }

    clickMember() {
        if (!this.isViewTypeMail) {
            this.$emit(`showAnswerResults`, this.mem)
        }
    }

    showAnswerResults() {
        this.$emit(`showAnswerResults`, this.mem)
    }

    get isViewTypeMail() {
        if (this.viewType == `mail`) return true
        return false
    }

    get hasAnswerResults() {
        if (!this.mem) return false
        if (this.isViewTypeMail) return false
        let results = this.mem.answer_results
        if (Util.isPresent(results) && Object.keys(results).length > 0) {
            return true
        }
        return true
    }

    // この出席者のみを出席者にして、他をはずします
    onlyAttend() {
        this.$emit(`onlyAttend`, this.mem, this.memType)
    }
}
