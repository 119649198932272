import RoomMember from "./RoomMember"
import axios from "axios"
import Util from "packs/utils/Util"
import { Logger } from "packs/common"
import Notice from "./Notice"
import { DateTime } from "luxon"
import NotificationControl from "packs/utils/NotificationControl"
import Appointment from "./Appointment"
import CalendarTag from "./CalendarTag"
import Room from "./Room"

interface UserMinimum {
    id: string
    email: string
    name: string
    image_url: string
}

export default class InstantRoomAssignedUser {
    constructor(
        public line_instant_room_assigned_user_id: string,
        public meeting_attendees_group_id: string,
        public is_active: boolean,
        public index_num: number,
        public user: UserMinimum
    ) {}

    static createDefault(mem, index, mag_id): InstantRoomAssignedUser {
        let userDic = { id: mem.user_id, email: mem.email, name: mem.name, image_url: mem.image_url }
        return new InstantRoomAssignedUser(null, mag_id, false, index, userDic)
    }

    static getMyRooms() {
        return axios.get(`${Util.prefixUrl}/instant_rooms/assigned_instant_rooms`).then(res => {
            return res.data
        })
    }

    static getActiveStatusesInRoom(roomId: string) {
        return axios.get(`${Util.prefixUrl}/instant_rooms/check_statuses/${roomId}`).then(res => {
            return res.data
        })
    }

    static willChangeStatus(switchType: string, pub_id: string = null, is_active: boolean) {
        let params = { switch_type: switchType, id: pub_id, is_active: is_active }

        return axios
            .put(`${Util.prefixUrl}/instant_rooms/change_active`, params)
            .then(res => {
                let msg = res.data.message
                Notice.message = msg
                return res.data
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static willChangeStatusForOwner(
        switchType: string,
        pub_id: string = null,
        is_active: boolean,
        switchMembers: InstantRoomAssignedUser[] = null
    ) {
        let params = { switch_type: switchType, id: pub_id, is_active: is_active, switch_members: switchMembers }

        return axios
            .put(`${Util.prefixUrl}/instant_rooms/change_active_for_owner`, params)
            .then(res => {
                let msg = res.data.message
                Notice.message = msg
                return res.data
            })
            .catch(err => {
                Logger(`err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static findFromRoom(room: Room, assignedUserId: string): InstantRoomAssignedUser {
        let users = room.instant_room_assigned_users || []
        let user = users.find(u => u.line_instant_room_assigned_user_id == assignedUserId)
        return user
    }

    /**
     *
     * @param rooms
     * @param assignedUserId line_instant_room_assigned_user_id
     * @returns
     */
    static findFromRooms(rooms: Room[], assignedUserId: string): InstantRoomAssignedUser {
        for (let room of rooms) {
            let user = this.findFromRoom(room, assignedUserId)
            if (user) {
                return user
            }
        }
        return
    }
}
