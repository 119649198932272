
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"

// コンポーネントを読込.
import LblueListContent from "packs/components/calendar/LblueListContent.vue"

// モデルを読込.
import TemplateUtil from "packs/utils/TemplateUtil"

@Options({
    components: {
        LblueListContent,
    },
})
export default class VariableListsContent extends Vue {
    // data:
    @Prop()
    type: string

    // アポ変数
    appoVariables = TemplateUtil.getTitles()
    // フォーム変数
    formVariables = TemplateUtil.getFormVariables()
    // ブロック変数
    blockVariables = TemplateUtil.getBlockVariables()

    /**
     * 選択した変数をカーソルがある位置に挿入します。
     */
    public selectVariable(e) {
        Logger(`select ${e}`)
        this.$emit(`selectVariable`, e, this.type)
    }
}
