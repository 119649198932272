
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { Logger } from "packs/common"
import Util from "packs/utils/Util"

// コンポーネントを読込.
import Notice from "packs/models/Notice"
import UserIcon from "packs/components/icons/UserIcon.vue"
import MailPreviewPartsHeader from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsHeader.vue"
import MailPreviewPartsBlueButton from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsBlueButton.vue"
import MailPreviewSenderIconContent from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewSenderIconContent.vue"
import MailPreviewPartsEditContent from "packs/pages/link/parts/room_settings/mail_parts/MailPreviewPartsEditContent.vue"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import SubscriptionManager from "packs/models/SubscriptionManager"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomMember from "packs/models/RoomMember"
import PossibleDate from "packs/models/PossibleDate"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import RoomCreateManager from "packs/models/RoomCreateManager"
import MailFlow from "packs/models/MailFlow"

@Options({
    components: {
        UserIcon,
        MailPreviewPartsHeader,
        MailPreviewPartsBlueButton,
        MailPreviewSenderIconContent,
        MailPreviewPartsEditContent,
    },
})
export default class MailPreviewFeedback extends Vue {
    @Prop() // func
    editEditableContent

    // data
    isSP = isMobile(window.navigator).phone

    loading = false

    appos = null
    rm = RoomManager
    rcm = RoomCreateManager

    member: RoomMember = null

    selectable_mags = null
    Util = Util
    MailFlow = MailFlow

    created() {}

    mounted() {
        this.updateMember()
        this.addMessage()
    }

    addMessage() {
        // this.editEditableContent(`message`)
        this.$emit(`addMessage`)
    }

    @Watch("members", { deep: true })
    updateMember() {
        if (!this.rcm.members) return
        if (!this.rm.userInfo) return
        Logger(`updateMember 親から受け取ります. ${this.rcm.members.length}`)
        this.member = this.rcm.members.find(m => m.user_id != this.rm.userInfo.user_id)
    }
}
