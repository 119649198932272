
// モジュールを読込.
import { Options, Prop, Vue } from "vue-property-decorator"

import UserIcon from "packs/components/icons/UserIcon.vue"

@Options({
    components: { UserIcon },
})
export default class ChipButton extends Vue {
    @Prop()
    name: string

    @Prop()
    returnVal: any

    @Prop()
    imageUrl: string

    @Prop()
    color: string

    public deleteContent() {
        this.$emit("delete", this.returnVal)
    }

    public selectContent() {
        this.$emit("select", this.returnVal)
    }
}
