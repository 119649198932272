
// モジュールを読込.
import { Options, Prop, Vue, Watch } from "vue-property-decorator"
import { DateTime } from "luxon"
import isMobile from "ismobilejs"
import { funcName, Logger } from "packs/common"
import Util from "packs/utils/Util"
import SelectUtil from "packs/utils/SelectUtil"

// コンポーネントを読込.
import Notice from "packs/models/Notice"

// モデルを読込.
import RoomManager from "packs/models/RoomManager"
import Appointment from "packs/models/Appointment"
import Room from "packs/models/Room"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import RoomSetting from "packs/models/RoomSetting"
import RoomCreateManager from "packs/models/RoomCreateManager"
import UserPermissionsOption from "packs/models/UserPermissionsOption"
import CalendarManager from "packs/models/CalendarManager"

@Options({
    components: {},
})
export default class SettingsTabContent extends Vue {
    @Prop() // func
    selectTab

    @Prop()
    selectedTabName: string

    // data
    isSP = isMobile(window.navigator).phone

    rm = RoomManager
    rcm = RoomCreateManager

    cm = CalendarManager
    notice = Notice

    // showDetail = false

    created() {}

    mounted() {
        this.updateCurrentTab()
    }

    @Watch(`rcm.currentTab`)
    updateCurrentTab() {
        Logger(`${funcName()} this.rcm.currentTab:${this.rcm.currentTab}`)
        if (!this.rcm.currentTab) return

        if (this.rcm.currentTab == `schedule`) {
            this.selectTab(`schedule`)
        } else if (this.rcm.currentTab == `public`) {
            this.selectTab(`public`)
        }
    }

    get showInstantRoomTab() {
        if (!this.rcm) return
        if (!this.rcm.room_setting) return
        return this.rm.canUseOption(`room_instant_room`, false) && this.rcm.room_setting.is_instant_room
    }
}
